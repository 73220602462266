import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const Introduction = ({ language,brand, bookedBy, accountManager, companyNameAndId, addOns, clients }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={{ ...styles.header, marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
          <Text style={{...styles.headerItemText, fontWeight:"bold", textTransform:"uppercase"}}>{language === 'English' ? `Introductory Letter` : `Lettera Introduttiva`}</Text>
        </View>
         <View>
{brand && brand?.rafiky ? <Image style={styles.headerRightImg} src="/rafiky/logo.png" />:
  <Image style={styles.headerRightImg} src="/assets/desktop6/group-1000001719.png" />
}        </View>
        <View style={styles.headerItem} />
      </View>
     {addOns?.friendly ? <View style={styles.introductionBody}>
        <Text style={styles.introductionParaBody}>
       {language === "English" ? `Dear ${bookedBy ? bookedBy?.firstName : '__'},` : 
       `Gentile ${bookedBy ? bookedBy?.firstName : '__'},`}
        </Text>
        <Text style={styles.introductionParaBody}>
       {language === "English" ? `We thank you for choosing to collaborate with ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"}. For more information about our company, please browse the pages of this presentation dedicated to your company.` : 
       `Ti ringraziamo per aver scelto di collaborare con ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"}. Per avere maggiori informazioni sulla nostra realtà, ti preghiamo di sfogliare le pagine di questa presentazione dedicata alla vostra azienda.`}
        </Text>
        <Text style={styles.introductionParaBody}>
       {language === "English" ? `As requested, we will send you our quote below.` : 
       `Come richiesto, ti inviamo di seguito il nostro preventivo.`}
        </Text>
        <Text style={{...styles.introductionParaBody}}>
       {language === "English" ? `Our considerable experience in the sector, combined with flexibility and precision, makes ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} the best choice for companies of the caliber of ` : 
       `La nostra notevole esperienza nel settore, unita a flessibilità e precisione, rende ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} la scelta migliore per aziende del calibro di `}
       <Text style={{...styles.introductionParaBody, fontWeight:"bold"}}>
       {language === "English" ? `Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda, Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini. ` : `Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda, Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini.`}
        </Text>
        </Text>
        <Text style={styles.introductionParaBody}>
       {language === "English" ? `For ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} each Customer is unique and special, and we would particularly like to start a long and fruitful collaboration with ${companyNameAndId?.name}.` : 
       `Per ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} ogni Cliente è unico e speciale, e ci terremmo particolarmente ad iniziare una lunga e proficua collaborazione con ${companyNameAndId?.name}.`}
        </Text>
        <Text style={styles.introductionParaBody}>
       {language === "English" ? `Quality has always been our strong point: for this reason we only select interpreters with at least five years of work experience, registered with the major trade associations (AITI, AssoInterpreti, AIIC, etc.), specialized in your sector.` : 
       `La qualità è da sempre il nostro punto di forza: per questo selezioniamo soltanto interpreti con almeno cinque anni di esperienza lavorativa, iscritti alle maggiori associazioni di categoria (AITI, AssoInterpreti, AIIC, ecc.), specializzati nel Vostro settore.`}
        </Text>
        <Text style={styles.introductionParaBody}>
       {language === "English" ? `We will contact you by phone to agree on the next steps together.` : 
       `Ti contatteremo telefonicamente per concordare insieme i prossimi step.`}
        </Text>
        <Text style={styles.introductionParaBody}>
       {language === "English" ? `See you soon,` : 
       `A presto,`}
        </Text>
      </View> : <View style={styles.introductionBody}>
        <Text style={styles.introductionParaBody}>
       {language === "English" ? `Dear ${bookedBy ? bookedBy?.firstName : '__'},` : 
       `Gentile ${bookedBy ? bookedBy?.firstName : '__'},`}
        </Text>
        <Text style={styles.introductionParaBody}>
       {language === "English" ? `Thank you for choosing to collaborate with ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"}. To get more information about our reality, please browse the pages of this dedicated presentation to your company.` : 
       `La ringraziamo per aver scelto di collaborare con ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"}. Per avere maggiori informazioni sulla nostra realtà, La preghiamo di sfogliare le pagine di questa presentazione dedicata alla vostra azienda.`}
        </Text>
        <Text style={styles.introductionParaBody}>
       {language === "English" ? `As requested, please find our quote below.` : `Come richiesto, Le inviamo di seguito la nostra brochure`}
        </Text>
        <Text style={{...styles.introductionParaBody}}>
       {language === "English" ? `Our considerable experience in the sector, combined with flexibility and precision, makes ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} the best choice for companies of the caliber of ` : `La nostra notevole esperienza nel settore, unita a flessibilità e precisione, rende ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} la scelta migliore per aziende del calibro di `}
       <Text style={{...styles.introductionParaBody, fontWeight:"bold"}}>
       
       {addOns?.sector ? clients?.map((client)=> client?.name).join(", "): 
             `Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda, Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini
             `
        }
        </Text>
        </Text>
        <Text style={{...styles.introductionParaBody, marginTop:"-10px"}}>
       {language === "English" ? `For ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} each Client is unique and special, and we would particularly like to get started a long and fruitful collaboration with ${companyNameAndId?.name}.` : 
       `Per ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} ogni Cliente è unico e speciale, e ci terremmo particolarmente ad iniziare una lunga e proficua collaborazione con ${companyNameAndId?.name}.`}
        </Text>
        <Text style={styles.introductionParaBody}>
       {language === "English" ? `Quality has always been our strong point: for this reason, we only select interpreters with at least five years of work experience, members of the major linguist associations (AITI, AssoInterpreti, AIIC, etc.), specialized in your sector.` : 
       `La qualità è da sempre il nostro punto di forza: per questo selezioniamo soltanto interpreti con almeno cinque anni di esperienza lavorativa, iscritti alle maggiori associazioni di categoria (AITI, AssoInterpreti, AIIC, ecc.), specializzati nel Vostro settore.`}
        </Text>
        <Text style={styles.introductionParaBody}>
       {language === "English" ? `We will contact you by phone to agree on the next steps together.` : 
       `Vi contatteremo telefonicamente per concordare insieme i prossimi step.`}
        </Text>
        <Text style={styles.introductionParaBody}>
       {language === "English" ? `Best regards,` : 
       `Cordiali saluti,`}
        </Text>
      </View>
      }
      <View style={brand && brand?.rafiky ? styles.introductionFooter1 : styles.introductionFooter}>
      <View style={styles.introductionFooterImages1}>
      {accountManager && accountManager.name === 'Giuseppe Gagliano' && (
      <Image style={styles.avatarAM}  src="/assets/desktop7/GiuseppeGagliano.png" />
      )}
{accountManager && accountManager.name === 'Alessia Tortorici' && (

<Image style={styles.avatarAM} src="/assets/desktop7/AlessiaTortorici.png" />

)}
      {accountManager && accountManager.name === 'Chiara Moschitta' && (
      <Image style={styles.avatarAM} src="/assets/desktop7/ChiaraMoschitta.png" />
      )}
      {accountManager && accountManager.name === "Giulia Caravasso" && <Image style={styles.avatarAM}
                src="/assets/desktop7/GiuliaCaravasso.png"
              />
     }
       </View>
       <Image style={styles.sign} src="/assets/desktop6/sign-1@2x.png" />

       <View style={styles.introductionFooterNames1}>
        <Text style={styles.introductionFooterName}>
        {accountManager ? accountManager.name : '__'}
        </Text>
       </View>
       <View style={styles.introductionFooterJobs1}>
        <Text style={{textAlign:"center",
  fontSize:"9px",
  fontStyle:"italic", marginInline:"auto"}}>
        {accountManager?.jobTitle} {brand && brand?.rafiky ? " Rafiky" : 
                " Congressi Internazionali"}
        </Text>
        <View style={{...styles.introductionFooterJob}}>
        <Text style={{fontSize:"10px",
  fontStyle:"italic"}}>
        Languages 
        </Text>
        {accountManager &&
                    accountManager?.language &&
                    accountManager?.language?.map((language) => {
                      console.log(language);
                      if (language.name === 'Chinese') {
                        return (
                            <Image style={{...styles.artworkIcon}}  src="/assets/desktop6/artwork1.png" />
                        );
                      } else if (language.name === 'Italian') {
                        return (
                           <Image style={{...styles.artworkIcon}} src="/assets/desktop6/artwork2.png" />
                        );
                      } else if (language.name === 'English') {
                        return (
                            <Image style={{...styles.artworkIcon}} src="/assets/desktop6/artwork3.png" />
                        );
                      } else if (language.name === 'Spanish') {
                        return (
                            <Image style={{...styles.artworkIcon}}
                              src="/assets/desktop6/artwork.png"
                            />
                        );
                      }
                    })}
        </View>
               </View>
      </View>
    </Page>
  );
};

export default Introduction;
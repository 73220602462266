import React from 'react';
import styles from '../styles/SectorClients.module.css';
import { useSelector } from 'react-redux';
import Slider from 'react-slick';
import { SectorsContent } from '../sectorsText';

export default function SectorClients() {
  const { language, details} = useSelector(state => state.proposal);
  const {clients, industry} = details;
 
 console.log("Clients", clients);
  const settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2000,
    pauseOnHover: false,
  };
  return (
    <div className={styles.desktop16}>
      <div className={styles.mainVector}>
        <div className={styles.vector1}></div>
        <div className={styles.vector2}></div>
        <div className={styles.vector3}></div>
        <div className={styles.vector4}></div>
        <div className={styles.vector5}></div>
        <div className={styles.vector6}></div>
      </div>
      <div className={styles.desktop5Child} style={{ background: `url(${SectorsContent[industry?.name]?.sector}) no-repeat right / cover`, backgroundPositionX:'right' }}>
      {/* <img src={`${SectorsContent[industry?.name]?.sector}`} /> */}
      </div>
      <div className={styles.text3543Parent}>
        <img alt="" src="/assets/desktop5/group-1000001730.svg" />{' '}
      </div>
      <div className={styles.laptop}>
        <h2> 
           {language === "English" ? `${SectorsContent[industry?.name]?.en.title}`: 
           `${SectorsContent[industry?.name]?.it.title}`}
        </h2>

        <p style={{lineHeight:'29px'}}>
        {language === "English" ? `${SectorsContent[industry?.name]?.en.para1}`: 
        `${SectorsContent[industry?.name]?.it.para1}`}

            <br /><br/>
          <ol>
            <li>
                <b>
                {language === "English" ? `${SectorsContent[industry?.name]?.en.listItem1Title}`: 
                `${SectorsContent[industry?.name]?.it.listItem1Title}`}

                </b>
                {language === "English" ? `${SectorsContent[industry?.name]?.en.listItem1Para}`: 
                `${SectorsContent[industry?.name]?.it.listItem1Para}`}
 
            </li>
            <li>
                <b>
                {language === "English" ? `${SectorsContent[industry?.name]?.en.listItem2Title}`: 
                `${SectorsContent[industry?.name]?.it.listItem2Title}`}

                </b>
                {language === "English" ? `${SectorsContent[industry?.name]?.en.listItem2Para}`: 
                `${SectorsContent[industry?.name]?.it.listItem2Para}`}

            </li>
            <li>
                <b>
                {language === "English" ? `${SectorsContent[industry?.name]?.en.listItem3Title}`: 
                `${SectorsContent[industry?.name]?.it.listItem3Title}`}

                </b>
                {language === "English" ? `${SectorsContent[industry?.name]?.en.listItem3Para}`: 
                `${SectorsContent[industry?.name]?.it.listItem3Para}`}

            </li>
            <li>
                <b>
                {language === "English" ? `${SectorsContent[industry?.name]?.en.listItem4Title}`: 
                `${SectorsContent[industry?.name]?.it?.listItem4Title}`}

                </b>
                {language === "English" ? `${SectorsContent[industry?.name]?.en.listItem4Para}`: 
                `${SectorsContent[industry?.name]?.it?.listItem4Para}`}

            </li>
            
          </ol>
          <br />
          {language === "English" ? `${SectorsContent[industry?.name]?.en.para2}`: 
                `${SectorsContent[industry?.name]?.it.para2}`}

        </p>
      </div>
      {/* <div className={styles.globe}>
      <img src={`${SectorsContent[industry?.name]?.sector}`} />
      </div> */}
      <div className={styles.text} id="sector_logos">
     {(clients?.length > 0 && industry?.name !== "Religious") &&  <Slider {...settings}>
         
         {clients?.map((client)=> <div className={styles.slider_images}>
            <img alt='office' src={client?.logo}/>
          </div> 
          )}
          {/* <div className={styles.slider_images}>
            <img alt='office'  src="/translationAssets/clients/image2.png"/>
          </div>
          <div className={styles.slider_images}>
            <img alt='office'  src="/translationAssets/clients/image3.png"/>
          </div>
          <div className={styles.slider_images}>
            <img alt='office'  src="/translationAssets/clients/image4.png"/>
          </div>
          */}
        </Slider>}
        </div>
    </div>
  );
}

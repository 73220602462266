import React from 'react';
import styles from '../styles/T&CsWeb.module.css';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FaRegHandPointLeft } from 'react-icons/fa';
import { BsVectorPen } from 'react-icons/bs';
import { addName } from 'src/redux/slices/proposal';
import styles1 from '../styles/AboutUs2.module.css';

function formatDate(param) {
  // Extracting date components
  const date = new Date(param);
  var day = date.getDate();
  var month = date.getMonth() + 1; // Months are zero indexed
  var year = date.getFullYear() % 100; // Getting last two digits of the year
  var hours = date.getHours();
  var minutes = date.getMinutes();

  // Adding leading zeros if necessary
  day = (day < 10 ? '0' : '') + day;
  month = (month < 10 ? '0' : '') + month;
  year = (year < 10 ? '0' : '') + year;
  hours = (hours < 10 ? '0' : '') + hours;
  minutes = (minutes < 10 ? '0' : '') + minutes;

  // Concatenating date components
  var formattedDate = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;

  return formattedDate;
}

export default function TermsAndCondition({
  handleAcceptance
}) {
  const {language, name, details:data, isBtn } = useSelector((state)=>state.proposal);
 const dispatch = useDispatch()
 const {brand} = data;
 const {companyNameAndId} = data;
 const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [openTerms, setOpenTerms] = React.useState(false);
  const handleClickOpenTerms = () => {
    setError('');
    setOpenTerms(true);
  };
  const handleClickCloseTerms = () => {
    setOpenTerms(false);
    handleAcceptance();
  };
  const handleCloseTerms = () => {
    setOpenTerms(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
    handleAcceptance();
  };
  const handleClose = () => {
    setOpen(false);
  };
  let previousDateKey = [] // Define previousDateKey variable

  return (
    <div className={styles.desktop7}>
     <div className={styles1.desktop8Item} />
        {/* <div className={styles.div}>17</div> */}
        <div className={styles1.aboutUs}>{language === "English" ? 'Terms and conditions' : "Termini e Condizioni"}</div>
        <div className={styles1.text3543Parent} style={{top:'0.37%'}}>
        {brand && brand?.rafiky ? <img  style={{marginTop:"-25px"}}
        alt=""
        src="/rafiky/logo-w.png"
      /> :
      <img style={{ top: '0.17%' }} alt="" src="/assets/desktop6/group-1000001719.svg" />
        }         </div>
      <Grid container spacing={3} sx={{ marginTop: '70px' }}  className={styles.sections}>
        <Grid item xs={4}>
        <p className={styles.titles}><b>
          {language === "English" ? `Quote expiry period` : `Scadenza del preventivo`}
          </b></p>
          <p> 
          {language === 'English'
                ? `- It is necessary to send confirmation of the quote by `
                : `- È necessario inviare conferma del preventivo entro il `}
            <b style={{fontSize:"15px"}}>
            {formatDate(data?.quoteDeadline)}.
            </b>
             
          </p>
          <p>
          
            {language === "English" ? `- After this date, the quote may be subject to change` : 
            `-	Oltre tale data il preventivo potrebbe subire delle variazioni`}.
          </p>
          
          <p className={styles.titles}><b>
          {language === "English" ? `Payment terms` : `Termini di pagamento`} 
          </b></p>
          <p>
            - {' '}
          <b style={{fontSize:"15px"}}>
                     {companyNameAndId?.paymentTerms === 'Immediate'
                        ? `${language === "English" ? "Prepayment" : "Pagamento Anticipato" }`
                        : `${language === "English" ? companyNameAndId?.paymentTerms : `${String(companyNameAndId?.paymentTerms).includes("days") ? 
                        String(companyNameAndId?.paymentTerms).replace("days", "giorni") : 
                        String(companyNameAndId?.paymentTerms)}`}`
                      }.
         </b>
          </p>
          <p>
          
            {language === "English" ? `- Date and time of the event` : 
            `-	Data e orario dell’evento`}.
          </p>
          <p>
          {data?.interpretationItem2?.length > 0 || data?.interpretationItem?.length > 0 ? (
 [data?.interpretationItem, ...data?.interpretationItem2]?.map((items, option)=>{
  previousDateKey = []
  return(
 <div>
  {data?.interpretationItem2?.length > 0 && <b style={{fontWeight:"bold", marginTop:"5px"}}>
  {language === "English" ? "Option " :  "Opzione "} {option + 1}:<br/>
  </b>}
 {
  items?.length > 0 ? items?.map((item, index) => {
    const startDate = new Date(item?.startDateTime);
    const endDate = new Date(item?.endDateTime);
    const dateKey = `${startDate.getDate()}${startDate.getMonth()}${startDate.getFullYear()}`;

    if (index === 0 || !previousDateKey.includes(dateKey)) {
      const startDateDay = String(startDate.getDate()).padStart(2, '0');
      const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const startDateYear = String(startDate.getFullYear()).slice(-2);
      let startDateHrs = String(startDate.getHours()).padStart(2, '0');
      let startDatemins = String(startDate.getMinutes()).padStart(2, '0');
      const startingDate =
        startDateDay +
        '/' +
        startDateMonth +
        '/' +
        startDateYear +
        ' ' +
        startDateHrs +
        ':' +
        startDatemins;
      const endDateDay = String(endDate.getDate()).padStart(2, '0');
      const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const endDateYear = String(endDate.getFullYear()).slice(-2);
      let endDateHrs = String(endDate.getHours()).padStart(2, '0');
      let endDateMins = String(endDate.getMinutes()).padStart(2, '0');
      const endingDate =
        endDateDay +
        '/' +
        endDateMonth +
        '/' +
        endDateYear +
        ' ' +
        endDateHrs +
        ':' +
        endDateMins; 

      previousDateKey.push(dateKey);
      return (
        <div key={item?.startDateTime}>
          {language === "English" ? " From" :  "Da"}: <b style={{fontSize:"15px"}}>
          {startingDate}
            </b> &nbsp; {language === "English" ? " To" :  "A"}: <b style={{fontSize:"15px"}}>
            {endingDate}
              </b>
          <br/>
        </div>
      );
    }
  }) : ( 
    [data?.additionalService, ...data?.additionalService2]?.map((services, index)=>(
      
     option === index && services?.map((item, index) => {
    const startDate = new Date(item?.startDateTime);
    const endDate = new Date(item?.endDateTime);
    const dateKey = `${startDate.getDate()}${startDate.getMonth()}${startDate.getFullYear()}`;

    if (index === 0 || !previousDateKey.includes(dateKey)) {
      const startDateDay = String(startDate.getDate()).padStart(2, '0');
      const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const startDateYear = String(startDate.getFullYear()).slice(-2);
      let startDateHrs = String(startDate.getHours()).padStart(2, '0');
      let startDatemins = String(startDate.getMinutes()).padStart(2, '0');
      const startingDate =
        startDateDay +
        '/' +
        startDateMonth +
        '/' +
        startDateYear +
        ' ' +
        startDateHrs +
        ':' +
        startDatemins;
      const endDateDay = String(endDate.getDate()).padStart(2, '0');
      const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const endDateYear = String(endDate.getFullYear()).slice(-2);
      let endDateHrs = String(endDate.getHours()).padStart(2, '0');
      let endDateMins = String(endDate.getMinutes()).padStart(2, '0');
      const endingDate =
        endDateDay +
        '/' +
        endDateMonth +
        '/' +
        endDateYear +
        ' ' +
        endDateHrs +
        ':' +
        endDateMins;

        previousDateKey.push(dateKey);

      return (
        <div key={item?.startDateTime}>
          {language === "English" ? " From" :  "Da"}: {startingDate} &nbsp; {language === "English" ? " To" :  "A"}: {endingDate}
        <br/>
      </div>
      );
    }
  })))
  )
 }
 </div>
 )}) 
) : (
  data?.additionalService.map((item, index) => {
    const startDate = new Date(item?.startDateTime);
    const endDate = new Date(item?.endDateTime);
    const dateKey = `${startDate.getDate()}${startDate.getMonth()}${startDate.getFullYear()}`;

    if (index === 0 || !previousDateKey.includes(dateKey)) {
      const startDateDay = String(startDate.getDate()).padStart(2, '0');
      const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const startDateYear = String(startDate.getFullYear()).slice(-2);
      let startDateHrs = String(startDate.getHours()).padStart(2, '0');
      let startDatemins = String(startDate.getMinutes()).padStart(2, '0');
      const startingDate =
        startDateDay +
        '/' +
        startDateMonth +
        '/' +
        startDateYear +
        ' , ' +
        startDateHrs +
        ':' +
        startDatemins;
      const endDateDay = String(endDate.getDate()).padStart(2, '0');
      const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const endDateYear = String(endDate.getFullYear()).slice(-2);
      let endDateHrs = String(endDate.getHours()).padStart(2, '0');
      let endDateMins = String(endDate.getMinutes()).padStart(2, '0');
      const endingDate =
        endDateDay +
        '/' +
        endDateMonth +
        '/' +
        endDateYear +
        ' , ' +
        endDateHrs +
        ':' +
        endDateMins;

        previousDateKey.push(dateKey);

      return (
        <div key={item?.startDateTime}>
          {language === "English" ? " From" :  "Da"}: {startingDate} &nbsp; {language === "English" ? " To" :  "A"}: {endingDate}
          <br />
        </div>
      );
    }
  })
)}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Assembly/disassembly of technical equipment` : `Montaggio/Smontaggio attrezzatura tecnica`}
          </b></p>
          <p>
         
            {language === "English" ? `- To be defined` : 
            `- Da definire`}.
          </p>
          <p className={styles.titles}><b>
          Location
          </b></p>
          <p>
            <b style={{fontSize:"15px"}}>
            - {data?.address1}.
            </b>
          
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Additional working hours` : `Ore lavorative aggiuntive`}
          </b></p>
          <p>
        
            {language === "English" ? `- The daily working hours for technicians in the control room shall not exceed ` : 
            `-	Gli orari di lavoro giornalieri per i Tecnici in regia non devono superare le `}
             <b style={{fontSize:"15px"}}>
           {language === "English" ? `8 hours. ` : 
            `8 ore. `}
          </b>
          {language === "English" ? `Beyond that amount of time, appropriate increments to the hourly rates, set at EUR 65.00 per hour for each technician, shall apply.` : 
            `Oltre tale limite, si applicano gli adeguati incrementi alle tariffe orarie, stabiliti a 65,00 €/ora per ciascun tecnico. `}
          </p>
         
          <p>
            {language === "English" ? `-	The interpreting service times are as indicated during the quotation stage. For the extra-time, the appropriate increments to the hourly rates, set at ` : 
            `-	Gli orari del servizio di interpretariato sono quelli indicati in fase di quotazione. Per l’extratime, si applicano gli adeguati incrementi alle tariffe orarie, stabiliti a `}
           <b style={{fontSize:"15px"}}>
           {language === "English" ? `EUR 125.00 per hour ` : 
            `125,00 €/ora `}
          </b>
          {language === "English" ? `for each interpreter, shall apply. ` : 
            `per ciascun interprete.`}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Travel, board and lodging expenses` : `Spese di trasferta, vitto e alloggio`}
          </b></p>
          <p>
        
        {language === "English" ? `-	During the service provided, the cost of any coffee breaks, lunches and dinners for all staff employed by LingoYou Group Srl will be borne by the Client. Lodging expenses for technicians and interpreters will be agreed between the parties and are included only if mentioned in the quote.` : 
        `-	Durante il servizio fornito, i costi relativi a eventuali pause caffè, pranzi e cene per tutto il personale impiegato da LingoYou Group Srl saranno a carico del Committente. Le spese di alloggio dei tecnici e degli interpreti saranno concordate tra le Parti e sono incluse solo se citate nel preventivo.`}
         </p>
        
         <p className={styles.titles}><b>
          {language === "English" ? `Car parks, limited traffic zones (ZTLs) & access to the town centers` : `Parcheggi, ZTL & accesso ai centri storici`}
          </b></p>
         <p>
        
        {language === "English" ? `-	For conference venues located in the center of the cities of interest, the Client is responsible for any access to the limited traffic zones (ZTL) and for parking the vehicle used by LingoYou Group Srl.` : 
        `-	Per le sedi congressuali situate nel centro storico delle città di interesse, il Committente è responsabile dell'eventuale accesso ai varchi ZTL e del parcheggio per il veicolo utilizzato da LingoYou Group Srl.`}
         </p>
         <p className={styles.titles}><b>
          {language === "English" ? `Event date modification and/or cancellation` : 
          `Modifiche date e/o cancellazione evento`}
          </b></p>
         <p>
        
        {language === "English" ? `-	In case of modification/cancellation within ten (10) working days before the event, the customer is required to pay ` : 
        `-	In caso di modifica/cancellazione entro dici (10) giorni lavorativi prima dell'evento il cliente è tenuto al `}
        <b>
        {language === "English" ? `the total ` : 
          `saldo totale `}
        </b>
        {language === "English" ? `of the confirmed amount. If the modification/cancellation of the event occurs previous to ten (10) working days before the event, ` : 
          `dell'importo confermato. Se la modifica/cancellazione dell'evento avviene antecedentemente ai dieci (10) giorni lavorativi prima dell'evento, è richiesto il `}
          <b>
          50%{' '}
        </b>
        {language === "English" ? `of the amount confirmed in the quote is required. In addition, in case of urgency (confirmation of the quote after the deadline), additional costs may be charged .` : 
          `del saldo confermato nel preventivo. Inoltre, in caso di urgenza, o conferma del preventivo dopo la scadenza, potrebbero essere addebitati dei costi aggiuntivi.`}
         </p>
         <p className={styles.titles}><b>
          {language === "English" ? `Technical equipment - Increasing the number of receivers/headsets` : 
          `Attrezzatura Tecnica - Incremento del numero di ricevitori/cuffie`}
          </b></p>
          <p>
          {language === "English" ? `-	Any increase in the number of receivers/headsets outside this economic offer shall be made only by multiples of 50 units contained in an appropriate suitcase. The cost of daily rental for No. 01 suitcase containing 50 headset/receiver unit is ` : 
          `- L’eventuale incremento del numero di ricevitori-cuffie al di fuori della presente offerta economica, avviene solo a multipli di n. 50 unità contenuti in idonea valigia. Il costo di noleggio giornaliero per n. 01 valigia contenente n. 50 unita cuffia-ricevitore è `}
           <b>
           {language === "English" ? "EUR 200.00 excl. VAT. " : "€ 200,00 + IVA. "}
           </b>
           {language === "English" ? `The increase request must be received by e-mail in advance and then confirmed. Increases communicated with less than three (3) days’ notice from the start of the event are not guaranteed.` : 
          `La richiesta di incremento dovrà pervenire via e-mail con congruo anticipo e successivamente confermata, non si garantiscono gli incrementi comunicati con un preavviso minore a tre (3) giorni dall'inizio dell'evento.`}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Technical equipment - Receivers not delivered` : 
          `Attrezzatura Tecnica - Ricevitori non consegnati`} 
          </b></p>
          <p>
          {language === "English" ? `-	Infrared/radio receivers will be delivered upon temporary collection of an identification document (driver’s license, ID card or passport - other identification documents will not be accepted).` : 
          `-	I ricevitori infrarossi/via radio saranno consegnati dietro temporaneo ritiro di un documento di riconoscimento. (patente di guida, carta identità o passaporto: non verranno accettate altre tipologie identificative).`} 
          </p>
          <p>
          {language === "English" ? `-	In the event of receivers not delivered at the end of the event, they will be charged for: Receiver EUR 245.00 each excl. VAT - High Impedance Monophonic Headset EUR 55.00 excl. VAT.` : 
          `-	Nell'eventualità di ricevitori non consegnati al termine dell’evento, verranno addebitati in ragione di: ricevitore 245,00 € cad. + I.V.A. - cuffia monofonica alta impedenza € 55,00 € + I.V.A.`} 
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Technical equipment – Amplification system and other equipment` : 
          `Attrezzatura tecnica – Sistema di amplificazione ed altra attrezzatura`} 
          </b></p>
          <p>
          {language === "English" ? `-	Not included in quote unless otherwise stated.` : 
          `-	Non incluso nel preventivo se non indicato diversamente.`} 
          </p>
          <p>
          {language === "English" ? `-	In case of using amplification systems or other equipment supplied by the location or by another company, LingoYou Group Srl assumes no liability for the management and functionality of audio-microphone equipment not under its ownership.` : 
          `-	Nel caso di utilizzo di sistemi di amplificazione o altra attrezzatura in dotazione della location utilizzata o fornita da altra ditta, la LingoYou Group Srl non si assume nessuna responsabilità sulla gestione e funzionalità degli apparati audio-microfonici non di sua proprietà.`} 
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Technical equipment – Receivers/headsets distribution` : 
          `Attrezzatura tecnica – Distribuzione dei ricevitori/cuffie`} 
          </b></p>
          <p>
          {language === "English" ? `-	When the Client distributes receivers and headsets, the delivery of the suitcases containing the receivers/headsets will be by subscription by the Client of a suitable document of assumption of liability.` : 
          `-	Nel caso di distribuzione di ricevitori & cuffie a cura del Committente, la consegna delle valigie contenenti i ricevitori-cuffie avverrà mediante sottoscrizione da parte del Committente di idoneo documento di assunzione di responsabilità. `} 
          </p>
          <p>
          {language === "English" ? `-	If the Customer wishes to be exempted from this liability, one of our Hostesses could take care of the assistance and delivery of the receivers/headsets to the users, at a cost of ` : 
          `-	Qualora il Cliente voglia essere esonerato da tale responsabilità, una nostra Hostess potrebbe occuparsi dell'assistenza e della consegna dei ricevitori-cuffie agli utenti, al costo di `} 
          <b>
         {language === "English" ? "EUR 150.00" : "150,00 €" }  {' '}
          </b>
          {language === "English" ? `(up to 100 users), after temporary collection of the appropriate identification document (driving license, ID card or passport - other identification documents will not be accepted.).` : 
          `(fino a 100 utenti), previo ritiro temporaneo di idoneo documento di identità (patente di guida, carta identità o passaporto: non verranno accettate altre tipologie identificative).`} 
          </p>
          <p>
          {language === "English" ? `-	The document delivered will be kept in the appropriate cabinet and returned to the user upon return of the receiver/headset. ` : 
            `-	Il documento consegnato sarà custodito nell’apposito casellario e restituito all’utenza alla riconsegna del ricevitore/cuffia.`}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Copyright for event recording and/or streaming` : 
          `Copyright per registrazioni e/o streaming evento`} 
          </b></p>
          <p>
          {language === "English" ? `-	Copyright fees for recording interpreters and/or streaming of the event are included only if mentioned in the quote.` : 
          `-	Costi di Copyright per registrazione interpreti e/o streaming dell'evento inclusi solo se citati nel preventivo.`} 
          </p>
          <p>
          {language === "English" ? `-	Any recordings and/or streaming can be made only and exclusively with the informed consent of ` : 
          `-	Eventuali registrazioni e/o streaming possono essere effettuate solo ed esclusivamente previo consenso informato di `}
          <b>
          Congressi Internazionali {' '}
          </b> 
          {language === "English" ? `(LingoYou Group Srl brand).` : 
          `(brand di LingoYou Group Srl).`} 
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Code of Ethics & confidentiality` : 
          `Codice etico & di riservatezza`} 
          </b></p>
          <p>
          {language === "English" ? `- The staff of ` : 
          `-	Il personale di `} 
          <b>
          Congressi Internazionali {' '}
          </b> 
          {language === "English" ? `(LingoYou Group Srl brand) have acknowledged and signed the Code of Ethics and the Code of confidentiality of the Company.` : 
          `(brand di LingoYou Group Srl) ha preso atto e controfirmato il codice etico e il codice di riservatezza della società.`} 

          </p>
          
          
        </Grid>
        <Grid item xs={4} sx={{marginTop:"5px"}}>
       <p className={styles.titles}><b>
          {language === "English" ? `Invoicing` : 
          `Fatturazione`} 
          </b></p>
          <p>
          {language === "English" ? `-	The order for the event will be invoiced by the company ` : 
          `-	La commessa dell’evento verrà fatturata dalla società `} 
          <b>
          {language === "English" ? `LingoYou Group Srl.` : 
          `LingoYou Group Srl.`}  
          </b>
          </p>       
       <p className={styles.titles}><b>
          {language === "English" ? `Third Party Invoicing` : 
          `Fatturazione a Terzi`} 
          </b></p>
          <p>
          {language === "English" ? `-	In the case of invoicing to a legal entity other than the Client, the latter shall be deemed to be jointly and severally liable, unless expressly accepted by the new entity indicated.` : 
          `-	In caso di fatturazione ad entità giuridica differente dal Committente, quest’ultimo verrà considerato obbligato in solido, a meno di una espressa accettazione da parte del nuovo soggetto indicato.`} 
          
          </p>
          <p>
          {language === "English" ? `-	In the case of arbitrations invoiced by the parties: The performance of the service is subject to the receipt of this quote signed for acceptance by the individual parties` : 
          `-	Caso di arbitrati con fatturazione suddivisa tra le Parti: l’esecuzione del servizio è subordinata alla ricezione del presente preventivo firmato per accettazione dalle singole Parti.`}  
          </p>
          <p>
          {language === "English" ? `-	Where there is no express acceptance or where separate invoicing is indicated after the service has already taken place, the client shall be deemed to be jointly and severally liable.` : 
          `-	In caso di mancanza di espressa accettazione o in caso di indicazione di fatturazione separata a servizio già avvenuto, il committente viene considerato obbligato in solido.`}  
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Services` : 
          `Servizi`} 
          </b></p>
          <p>
          {language === "English" ? `- The entity LingoYou Group Srl (through the brand ` : 
          `-	Il soggetto LingoYou Group Srl (tramite il brand `} 
          <b>
          Congressi Internazionali{') '}
          </b>
          {language === "English" ? `indicated in the above Quote undertakes to provide the Customer with translation, interpretation and other services through its project management system. ` : 
          `indicato nel suddetto Preventivo si impegna a fornire al Cliente servizi di traduzione e interpretariato e di altro tipo per mezzo del proprio sistema di gestione dei progetti. `} 
          </p>
          <p>
          {language === "English" ? `-	LingoYou Group Srl will make every commercially reasonable effort to provide translation, interpreting or other services within the time frame estimated in writing by LingoYou Group Srl or, if no written estimate has been provided by LingoYou Group Srl, the latter will perform its obligations in accordance with its standard parameters, subject to the circumstances that may arise from time to time. ` : 
          `-	LingoYou Group Srl compirà ogni sforzo commercialmente ragionevole al fine di fornire i servizi di traduzione e interpretariato o di altro tipo rispettando le tempistiche stimate per iscritto da LingoYou Group Srl o, qualora non sia stata fornita alcuna stima scritta da parte di LingoYou Group Srl, quest'ultimo adempierà ai propri obblighi in conformità con i propri consueti parametri standard, soggetti alle circostanze esistenti di volta in volta. `} 
          </p>
          <p>
          {language === "English" ? `- "Urgent" projects that require service delivery within shorter time frames than the standard will be accepted on the assumption that, in certain cases, the usual quality and project management level of LingoYou Group Srl may not be respected and LingoYou Group Srl may charge additional fees due to the complexity and costs necessary to meet such requests.` : 
          `-	I progetti "urgenti" che richiedono la fornitura del servizio entro tempistiche più brevi rispetto allo standard verranno accettati sull'assunto che, in determinati casi, il livello consueto di qualità e di gestione dei progetti di LingoYou Group Srl potrebbe non essere rispettato e che LingoYou Group Srl possa applicare tariffe aggiuntive a causa della complessità e dei costi necessari a soddisfare tali richieste.`} 
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Quote` : 
          `Preventivo`} 
          </b></p>
          <p>
          {language === "English" ? `-	This Quote is strictly bound by the Terms & Conditions set forth herein and is based on the information and files made available to LingoYou Group Srl at the time of quote creation. ` : 
          `-	Il presente Preventivo è strettamente vincolato ai Termini & Condizioni qui indicati e si basa sulle informazioni e sui files resi disponibili a LingoYou Group Srl alla data del Preventivo. `} 
          </p>
          <p>
          {language === "English" ? `-	Please note that any changes to the project scope (change, add or remove one or more language pairs, add or remove equipment, change of date and time, etc.) will result in a project change order being issued. ` : 
          `-	Si tenga presente che qualsiasi modifica all'ambito del progetto (cambio, aggiunta o rimozione di una o più combinazioni linguistiche, aggiunta o rimozione di attrezzatura, cambio di data e orario ecc.) comporterà l'emissione di un ordine di modifica del progetto. `} 
          </p>
          <p>
          {language === "English" ? `-	The specific terms in the above Quote will supersede any conflicting provisions in these ` : 
          `-	I termini specifici nel Preventivo di cui sopra sostituiranno qualsiasi disposizione contrastante nei presenti `} 
          <i>
          {language === "English" ? `Terms and conditions.` : 
          `Termini & Condizioni.`} 
          </i>
          </p>
          <p>
          {language === "English" ? `-	This Quote expires on the date and time stated above. If this deadline is exceeded without the Quote being approved, the Quote will expire and must be resubmitted.` : 
          `-	Il presente Preventivo scade nella data e nell’orario sopra indicati. Qualora questo termine venga superato senza che il Preventivo sia stato approvato, il Preventivo scadrà e dovrà essere effettuato nuovamente.`} 
          </p>
          <p>
          {language === "English" ? `-	In order to ensure that the line of work is followed, LingoYou Group Srl requires timely receipt of all materials and information for the performance of the desired services and clarification of the requirements. Any delay due to failure to receive the above shall relieve LingoYou Group Srl of the failure to provide the service in proportion to the time taken by the customer to clarify the requirements and for the sections of the work affected by the requirements.` : 
          `-	Al fine di garantire il rispetto della linea di lavoro, LingoYou Group Srl necessita della ricezione tempestiva di tutti i materiali e di tutte le informazioni per l'esecuzione dei servizi desiderati e di un chiarimento dei requisiti. Eventuali ritardi dovuti alla mancata ricezione di quanto sopra esposto esonerano LingoYou Group Srl dalla mancata fornitura del servizio in proporzione al tempo impiegato dal cliente per chiarire i requisiti e per le sezioni del lavoro interessate dai requisiti.`} 
          </p>
          
          <p className={styles.titles}><b>
          {language === "English" ? `Fees and Payments` : 
          `Commissioni e pagamenti`} 
          </b></p>
          <p>
          {language === "English" ? `-	LingoYou Group Srl agrees to charge, and the Client agrees to pay, fees for services provided by LingoYou Group Srl or its subsidiaries at the current rates of LingoYou Group Srl or, where applicable, according to rates negotiated separately and agreed in writing by LingoYou Group Srl and the Client before the start of the provision of the services.` : 
          `-	LingoYou Group Srl accetta di addebitare, e il Committente accetta di pagare, le commissioni per i servizi forniti da LingoYou Group Srl o dalle sue consociate secondo le tariffe correnti di LingoYou Group Srl o, qualora applicabile, secondo tariffe negoziate separatamente e concordate per iscritto da LingoYou Group Srl e dal Committente prima dell'inizio della prestazione dei servizi.`} 
          </p>
          <p>
          {language === "English" ? `-	All payments must be made ` : 
          `-	Tutti i pagamenti devono essere effettuati `} 
          <b>
          {language === "English" ? `within thirty (30) days of the date of issue of the invoice.` : 
          `entro trenta (30) giorni dalla data di emissione della fattura.`} 
          </b>
          </p>
          
          <p style={{lineHeight:`${language === "English" ? "normal" : "25px"}`}}>
          {language === "English" ? `-	In the event of a dispute in good faith concerning an invoice item, the Client undertakes to inform LingoYou Group Srl promptly of the nature of the dispute in writing (including a description of the reason for the dispute) within the above thirty (30) day period and agrees to pay the undisputed portion as set forth herein.` : 
          `-	In caso di eventuale controversia in buona fede riguardante una voce relativa alla fattura, il Committente si impegna a informare tempestivamente LingoYou Group Srl circa la natura della controversia per iscritto (includendo una descrizione della motivazione della controversia) entro il suddetto periodo di trenta (30) giorni e si impegna a pagare la porzione non contestata come stabilito nel presente documento.`} 
          </p>
          <p>
          {language === "English" ? `-	Any undisputed amount not paid within thirty (30) days of the date of invoice issued by LingoYou Group Srl will accrue interest at the rate of ` : 
          `-	Qualsiasi importo non contestato non pagato entro trenta (30) giorni dalla data di emissione della fattura di LingoYou Group Srl maturerà interessi al tasso di `}
           <b>
           1,5% 
           </b>
           {language === "English" ? ` until full payment, or at the highest legal rate of interest, whichever is lower (interest will be charged daily).` :
          ` fino al completo pagamento, ovvero al massimo tasso di interesse legale, qualora inferiore (gli interessi verranno addebitati quotidianamente).`}

          </p>
          {/* <p>
          {language === "English" ? `- In the event of any dispute in good faith regarding an item relating to the invoice, the Client undertakes to promptly inform LingoYou Group Srl of the nature of the dispute in writing (including a description of the reason for the dispute) within the aforementioned period of thirty (30 ) days and agrees to pay the undisputed portion as set forth herein.` : 
          `-	In caso di eventuale controversia in buona fede riguardante una voce relativa alla fattura, il Committente si impegna a informare tempestivamente LingoYou Group Srl circa la natura della controversia per iscritto (includendo una descrizione della motivazione della controversia) entro il suddetto periodo di trenta (30) giorni e si impegna a pagare la porzione non contestata come stabilito nel presente documento.`} 
          </p> */}
          <p style={{lineHeight:`${language === "English" ? "normal" : "25px"}`}}>
          {language === "English" ? `-	All amounts due by the Client under this Agreement shall be paid to LingoYou Group Srl without compensation or counterclaim and without deduction or withholding. ` : 
          `-	Tutti gli importi dovuti dal Committente in virtù del presente Accordo saranno corrisposti a LingoYou Group Srl senza compensazione o domanda riconvenzionale e senza detrazione o ritenuta. `}
           
          </p>
          <p style={{lineHeight:`${language === "English" ? "normal" : "25px"}`}}>
          {language === "English" ? `-	The acceptance by LingoYou Group Srl of the partial payment or any payment less than the total amount due at any time does not constitute a release or waiving of LingoYou Group Srl’s rights to unpaid amounts.` : 
          `-	L'accettazione da parte di LingoYou Group Srl del pagamento parziale o di qualsiasi pagamento inferiore all'importo totale dovuto in qualsiasi momento non costituisce una liberatoria o una rinuncia ai diritti di LingoYou Group Srl agli importi non corrisposti.`}
           
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Confidentiality` : `Riservatezza`}
          </b></p>
          <p style={{lineHeight:`${language === "English" ? "25px" : "25px"}`}}>
          {language === "English" ? `-	Both parties agree that any information or documents provided by either party to the other (including the Terms & Conditions of this Agreement and details of the applicable fee structure) will remain strictly confidential, except to the extent that a party is required by law or a court order to disclose them.` : 
          `-	Entrambe le Parti concordano che qualsiasi informazione o documento fornito da una Parte all'altra (inclusi Termini & Condizioni del presente accordo e i dettagli della struttura tariffaria applicabile) rimarranno strettamente riservati, salvo nella misura in cui una Parte è obbligata dalla legge o da un'ordinanza del tribunale a rivelarli.`}

          </p>
          {language === "English" ?  <>
       <p className={styles.titles}><b>
          {language === "English" ? `Non-solicitation` : `Non sollecitazione`}: 
          </b></p>
          <p style={{lineHeight:`${language === "English" ? "normal" : "20px"}`}}>
          {language === "English" ? `- At any time when LingoYou Group Srl is providing the Service to the Client under this document and for two (2) years thereafter, the Client will not solicit or induce any employee, translator, interpreter or consultant of LingoYou Group Srl engaged in the provision of services to the client to resign or to end the engagement with LingoYou Group Srl.` : 
          `-	In qualsiasi momento in cui LingoYou Group Srl stia fornendo il servizio al Committente ai sensi del presente documento e per due (2) anni a seguire, il Committente non solleciterà né indurrà alcun dipendente, traduttore, interprete o consulente di LingoYou Group Srl impegnato nella fornitura dei servizi al Committente a licenziarsi o a terminare l'impegno con LingoYou Group Srl . `}
          </p>
       </> : '' }
          
         
        </Grid>
        <Grid item xs={4}>
       {language === "English" ? '' : <>
       <p className={styles.titles}><b>
          {language === "English" ? `Non-solicitation` : `Non sollecitazione`}: 
          </b></p>
          <p style={{lineHeight:`${language === "English" ? "normal" : "20px"}`}}>
          {language === "English" ? `- At any time when LingoYou Group Srl is providing the Service to the Client under this document and for two (2) years thereafter, the Client will not solicit or induce any employee, translator, interpreter or consultant of LingoYou Group Srl engaged in the provision of services to the client to resign or to end the engagement with LingoYou Group Srl.` : 
          `-	In qualsiasi momento in cui LingoYou Group Srl stia fornendo il servizio al Committente ai sensi del presente documento e per due (2) anni a seguire, il Committente non solleciterà né indurrà alcun dipendente, traduttore, interprete o consulente di LingoYou Group Srl impegnato nella fornitura dei servizi al Committente a licenziarsi o a terminare l'impegno con LingoYou Group Srl . `}
          </p>
       </> }
        <p>
          {language === "English" ? `-	The Client acknowledges to LingoYou Group Srl the right of execution in a specific form or of injunctive or other equitable provisional measure in order to enforce this provision.` : 
          `-	Il Committente riconosce a LingoYou Group Srl il diritto di esecuzione in forma specifica o di un provvedimento ingiuntivo o altro provvedimento equo al fine di applicare la presente disposizione. `}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Warranties` : `Garanzie`}
          </b></p>
          <p>
          {language === "English" ? `-	LingoYou Group Srl guarantees to the Client that all services provided will comply in all relevant respects with the specifications set out in the applicable Quote. In the event of failure to promptly contest the claim, nothing can be claimed by way of compensation. The Supplier shall also not be liable for defects attributable to the user or to a third party unrelated to the provision of the services.` : 
          `-	LingoYou Group Srl garantisce al Committente che tutti i servizi effettuati saranno conformi in tutti gli aspetti rilevanti alle specifiche stabilite nel Preventivo applicabile. In caso di mancata tempestiva contestazione nulla potrà essere richiesto a titolo risarcitorio. Il Fornitore non potrà altresì essere ritenuto responsabile per difetti imputabili all’utente o ad un terzo estraneo alla fornitura dei servizi.`}

          </p>
          <p>
          {language === "English" ? `-	Except as provided in this section, LingoYou Group Srl makes no warranties or representations, whether express, implied by law or otherwise, with respect to the services or any final products provided hereunder and specifically denies any implied warranties of merchantability, title, non-infringement and fitness for a particular purpose.` : 
          `-	Salvo quanto stabilito nella presente sezione, Lingoyou Group Srl non fornisce alcuna garanzia o dichiarazione, sia essa esplicita, implicitamente prevista dalla legge o altrimenti, in relazione ai servizi o a eventuali prodotti finali forniti ai sensi del presente documento e, nello specifico, esclude qualsiasi garanzia implicita di commerciabilità, titolo, non-violazione e idoneità a un particolare scopo.`}

          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Remedies` : `Rimedi`}
          </b></p>
          <p>
          {language === "English" ? `-	The Supplier may remedy the identified defect upon prompt written objection (within five (5) working days from the provision of the Service) of any lack of conformity during the performance of the Service provided by LingoYou Group Srl.` : 
          `-	Il Fornitore può porre rimedio al difetto rilevato previa tempestiva contestazione scritta (entro cinque (5) giorni lavorativi dalla fornitura del servizio) di eventuali difetti di conformità durante l’esecuzione del servizio fornito da LingoYou Group Srl.`}
          </p>

          <p className={styles.titles}><b>
          {language === "English" ? `Limitation of liability ` : `Limitazione di responsabilità `} 
          </b></p>
          <p>
          {language === "English" ? `-	The liability of each party (whether defined by contract, whether arising out of wrongful act, negligence, direct liability, regulatory provision or otherwise) to the other Party and any third party in respect of or concerning this Agreement shall be cumulatively limited to direct and actual damages equal to or less than the amount payable to LingoYou Group Srl under this Agreement for the portion of the services it has given the source of that complaint.` : 
          `-	La responsabilità di ciascuna Parte (sia definita da un contratto, che derivante da atto illecito, negligenza, diretta responsabilità, disposizione normativa o altro) nei confronti dell'altra Parte e di eventuali terze parti riguardante o relativa al presente Accordo sarà limitata cumulativamente ai danni diretti ed effettivi pari a un ammontare non superiore a quello da corrispondere a LingoYou Group Srl ai sensi del presente Accordo per la porzione dei servizi che ha dato origine a tale reclamo.`}
          </p>
          <p>
          {language === "English" ? `-	Without prejudice to gross negligence or misconduct, in no event shall either Party be liable for the following types of damage and each Party hereby waives its right in respect of the following types of damage: consequential; incidental; punitive; special; exemplary; indirect; loss of profits; loss of refunds; loss of data; or loss of savings. Each Party waives its right to such damage, even if that party has been informed of the possibility of such damage occurring.` : 
          `-	Escludendo colpa grave o condotta scorretta, in nessun caso alcuna delle Parti sarà responsabile per i seguenti tipi di danno e ciascuna Parte rinuncia con la presente al proprio diritto in relazione ai seguenti tipi di danno: conseguente; accidentale; punitivo; speciale; esemplare; indiretto; perdita di profitti; perdita di rimborsi; perdita di dati; o perdita di risparmi. Ciascuna Parte rinuncia al diritto relativo ai suddetti danni, anche se tale Parte è stata informata della possibilità di avvenimento di tali danni.`}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Applicable Law and Competent Court` : `Legge applicabile e Foro Competente`}
          </b></p>
          <p>
          {language === "English" ? `-	Except as otherwise provided in the Statement of work, this Agreement shall be governed by Italian law without application of its principles of conflict of laws.` : 
          `-	Salvo quanto diversamente previsto nella Dichiarazione di lavoro, il presente Accordo sarà regolato dalla Legge Italiana senza applicazione dei suoi principi di conflitto di leggi.`} 
          </p>
          <p>
          {language === "English" ? `-	Any action in connection with this Agreement shall be brought before the State or Federal Court of Milan and the Parties consent exclusively to the seat and personal authority provided for therein.` : 
          `-	Qualsiasi causa connessa al presente Accordo dovrà essere intentata presso il Tribunale Statale o Federale di Milano e le Parti acconsentono esclusivamente alla sede e alla giurisdizione personale ivi previste.`} 
         
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Agreement` : `Accordo`}
          </b></p>
          <p>
          {language === "English" ? `-	Unless expressly agreed otherwise by both Parties in writing, as in a Framework Agreement (FA), if any, this Quote together with these Terms & Conditions constitutes the Parties’ entire Agreement and understanding with respect to the subject matter hereof and supersedes all prior agreements (oral or written) and intended in this regard.` : 
          `-	Salvo quanto diversamente concordato espressamente da entrambe le Parti per iscritto, come in un Accordo Quadro (AQ), se presente, questo Preventivo insieme a questi Termini & Condizioni, costituisce l'intero Accordo e comprensione delle Parti in relazione all'oggetto del presente documento e sostituisce tutti i precedenti accordi (orali o scritti) e intese al riguardo. `}
          </p>
          <p>
          {language === "English" ? `-	No representations, promises, inducements or intentions have been made by any Party not incorporated herein, and no Party shall be bound by or liable for any alleged representations, promises or inducements not so established herein, including, but not limited to, the Terms & Conditions of the Customer’s pre-printed documents (e.g. purchase orders, order confirmations, etc.).` : 
          `-	Nessuna dichiarazione, promessa, incentivo, dichiarazione o intenzione è stata fatta da alcuna Parte che non sia incorporata nel presente documento, e nessuna Parte sarà vincolata o responsabile per qualsiasi presunta dichiarazione, promessa, incentivo o dichiarazione non così stabilita nel presente documento, inclusi - senza limitazioni - i Termini & Condizioni dei documenti prestampati del Cliente (ad esempio, ordini di acquisto, conferme d'ordine e simili). `}
          </p>
          <p>
          {language === "English" ? `-	This Agreement may be modified, amended, superseded or canceled only by a written instrument signed by each Party, and all terms, understandings, representations, warranties or conditions herein may be waived only by a written instrument executed by the Party to be bound by such waiver.` : 
          `-	Il presente Contratto può essere modificato, emendato, sostituito o annullato solo da uno strumento scritto firmato da ciascuna delle Parti e tutti i termini, patti, dichiarazioni, garanzie o condizioni del presente documento possono essere derogati solo da uno strumento scritto eseguito dalla Parte per essere vincolato da tale rinuncia.`}
          </p>
          <div style={{marginTop:`${language === "English" ? "20px": "15px"}`}} className={styles.sign}>
          <div>
                <p>
                  <b>
                  {
                language === 'English' ? `Signature for acceptance of quote CI/${data?.orderNumber}:` : 
                `Firma per accettazione Preventivo CI/${data?.orderNumber}:`
              }                
              </b>
                </p>
               {(data?.status !== 'Quote-Won' && data?.status !== "Sent To PM") && isBtn ? (
                  <span onClick={handleClickOpen}>
                    <Button sx={{ width: "150px", fontSize: "20px", color:"white", backgroundColor:"#2da0f0" }} onClick={handleClickOpen}>
                    {language === 'English' ? "SIGN" : "FIRMA"}
                    </Button>
                  </span>
                ) : <span style={{ fontFamily: "Allura", fontSize: "26px" }}>{name || data?.quoteSignature}</span>
                }
               </div>
          </div>
          <div style={{marginTop:"10px"}} className={styles.sign}>
          <div>
                <p>
                  <b>
                  {
                language === 'English' ? 'Signature for acceptance of the Terms & Conditions:' : 'Firma per accettazione Termini & Condizioni:'
              }                  
              </b>
                </p>
               {(data?.status !== 'Quote-Won' && data?.status !== "Sent To PM") && isBtn ? (
                  <span onClick={handleClickOpen}>
                    <Button sx={{ width: "150px", fontSize: "20px", color:"white", backgroundColor:"#2da0f0" }} onClick={handleClickOpen}>
                    {language === 'English' ? "SIGN" : "FIRMA"}

                    </Button>
                  </span>
                ) : <span style={{ fontFamily: "Allura", fontSize: "26px" }}>{name || data?.quoteSignature}</span>
                }
               </div>
          </div>
          <div style={{marginTop:"10px"}} className={styles.sign}>
          <div>
                <p>
                  <b>
                  {
                language === 'English' ? 'Signature for acceptance Terms and conditions of the site' : 'Firma per accettazione Termini & condizioni del sito '
              } <a href='http://www.lingoyou.com/terminicondizioni'>(http://www.lingoyou.com/terminicondizioni)</a>                 
              </b>
                </p>
               {(data?.status !== 'Quote-Won' && data?.status !== "Sent To PM") && isBtn ? (
                  <span onClick={handleClickOpen}>
                    <Button sx={{ width: "150px", fontSize: "20px", color:"white", backgroundColor:"#2da0f0" }} onClick={handleClickOpen}>
                    {language === 'English' ? "SIGN" : "FIRMA"}

                    </Button>
                  </span>
                ) : <span style={{ fontFamily: "Allura", fontSize: "26px" }}>{name || data?.quoteSignature}</span>
                }
               </div>
          </div>
        </Grid>
       
       
      </Grid>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          sx={{
            '& .css-y4brsv-MuiPaper-root-MuiDialog-paper.MuiPaper-rounded': { borderRadius: '5px' },
            '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '500px', textAlign: 'center', borderRadius: '5px' },
            borderRadius: '5px',
          }}
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
              color: 'rgba(0,0,0,.65)',
              fontWeight: '600',
              fontSize: '25px',
            }}
          >

            {
              language === 'English' ? 'Accept the quote' : 'Accetta il preventivo'
            }
          </DialogTitle>
          <DialogContent
            id="dialog_content"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
            <span style={{ display: "inline-flex", marginTop: "30px" }}>
              <BsVectorPen style={{ fontSize: "25px", marginTop: "5px" }} />  <input placeholder={`${language === "English" ? "Sign here":"Firma qui"}`} value={name} onChange={(e) => dispatch(addName(e.target.value))} />{' '}
              <FaRegHandPointLeft style={{ fontSize: "25px", marginTop: "5px" }} />

            </span>
            {(error && !name && isBtn && data?.status !== 'Quote-Won') && <span className={styles.error}>{error} <br /></span>}
            <DialogContentText id="alert-dialog-description">{name}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button

              sx={{
                background: '#7cd1f9',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
                fontWeight: '600',
                fontSize: '14px',
                padding: '10px 10px',
                cursor: 'pointer',
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
                  color: 'white',
                  background: 'grey',
                },
              }}
              disabled={!name && true}
              onClick={handleClickClose}
              autoFocus
            >
              Conferma preventivo
              {
                language === 'English' ? 'Quote confirmation                ' : 'Accetta il preventivo'
              }
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openTerms}
          onClose={handleCloseTerms}
          aria-labelledby="alert-dialog-title"
          sx={{ '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '500px', textAlign: 'center' } }}
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
            {
              language === 'English' ? 'Accept the Terms & Conditions' : 'Accetta i Termini e Condizioni'
            }
          </DialogTitle>
          <DialogContent
            id="dialog_content"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
            <span style={{ display: "inline-flex", marginTop: "30px" }}>
              <BsVectorPen style={{ fontSize: "25px", marginTop: "5px" }} />  <input placeholder={`${language === "English" ? "Sign here":"Firma qui"}`} value={name} onChange={(e) => dispatch(addName(e.target.value))} />{' '}
              <FaRegHandPointLeft style={{ fontSize: "25px", marginTop: "5px" }} />
            </span>
            <DialogContentText id="alert-dialog-description">{name}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: '#7cd1f9',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
                fontWeight: '600',
                fontSize: '14px',
                padding: '10px 10px',
                cursor: 'pointer',
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
                  color: 'white',
                  background: 'grey',
                },
              }}
              disabled={!name && true}
              onClick={handleClickCloseTerms}
              autoFocus
            >


              {
                language === 'English' ? 'Confirm Quote                ' : 'Conferma preventivo'
              }            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}

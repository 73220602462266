import React from 'react';
import { styles } from '../globalStyles';
import { Image, Text, View } from '@react-pdf/renderer';
import { icons } from '../icons';

const Header = ({ language = "Italian", accountManager, companyNameAndId, bookedBy }) => {
  return ( 
   <>

      <View style={{ ...styles.header,alignItems:"justify", marginLeft:"10px", width: "99%", marginHorizontal: "auto" }}>
        <View style={{ display:"flex", flexDirection:"row", width:"100%" }}>
        <Text style={{...styles.headerItemText,textAlign:"justify",width:"100%",marginTop:"15px", fontSize:"11px",}}>
          {language === 'English' ? `Quote` : `Preventivo`}        
        </Text>
        <Image style={{width:"60px", height:"25px", marginTop:"8px", }} src="/translationAssets/desktop6/lingoyou.png" />
        </View>
         {/* <View style={{alignItems:"right", alignContent:"right", float:"right"}}>
          <Image style={{...styles.headerRightImg, alignItems:"right", alignContent:"right", float:"right" }} src="/translationAssets/desktop6/lingoyou.png" />
        </View> */} 
        <View style={styles.headerItem} />
      </View>
            <View style={styles.tableCustomer}>
              <View style={styles.tableCustomerHeader}>
                <View style={styles.tableCustomerItem}>
                  <Text style={styles.tableCustomerText}>
                    {language === 'English' ? `Customer Billing Data` : `Dati Fatturazione Cliente`}
                  </Text>
                </View>
                <View style={styles.tableCustomerItem}>
                  <Text style={styles.tableCustomerText}>{accountManager?.jobTitle}</Text>
                </View>
              </View>
              <View style={styles.tableCustomerBody}>
                <View style={styles.tableCustomerItem}>
                  <Text>{companyNameAndId?.name}</Text>
                  <Text>
                    {bookedBy?.firstName} {bookedBy?.lastName}
                  </Text>
                  <View style={{  gap: '5px' }}>
                      {companyNameAndId?.address1 && <Text style={{marginBottom:"-5px"}}>{companyNameAndId?.address1}</Text>}
                      {companyNameAndId?.address2 && <Text style={{marginBottom:"-5px"}}>{companyNameAndId?.address2}</Text>}
                      <Text>
                        {companyNameAndId?.postcode} {companyNameAndId?.city}
                      </Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                    <Text style={styles.bold}>P. IVA:</Text>
                    <Text>{companyNameAndId?.VAT}</Text>
                  </View>
                </View>
                <View style={styles.tableCustomerItem}>
                  <Text>{accountManager?.name}</Text>
                  <Text>E-mail: info@lingoyou.com</Text>
                  <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                    <Text>Telefono:(+39)</Text>
                    <Text style={styles.bold}>0282957101</Text>
                  </View>
                  <View style={{ display: 'flex', flexDirection: 'row', gap: '5px' }}>
                    <Text>(+39)</Text>
                    <Text style={styles.bold}>3757441330</Text>
                  </View>
                </View>
              </View>
            </View>
   </>
  );
};

export default Header;
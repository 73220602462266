import { Grid } from '@mui/material';
import React from 'react';
import styles from '../styles/OurClients.module.css';
import { useSelector } from 'react-redux';

export default function OurClients() {

  const { language, details} = useSelector(state => state.proposal)

  return (
    <div className={styles.desktop14}>
     <div className={styles.mainVector1}>
        <div className={styles.vector1}></div>
        <div className={styles.vector2}></div>
        <div className={styles.vector3}></div>
        <div className={styles.vector4}></div>
        <div className={styles.vector5}></div>
        <div className={styles.vector6}></div>
      </div> 

      <div className={styles.desktop14Item} />
      {/* <div className={styles.div}>11</div> */}
      <div className={styles.aboutUs}>OUR CLIENTS</div>
      <div className={styles.text3543Parent}>
       <img  style={{marginTop:"-25px"}}
        alt=""
        src="/rafiky/logo-w.png"
      />
      </div>
      <Grid container spacing={3} sx={{ marginTop: '105px', zIndex: '99', width: 'auto' }}>
        <Grid
          item
          xs={6}
          sm={4}
          lg={3}
          sx={{
            borderRight: '2px solid white',
            opacity: 1,
            zIndex: 99,
            borderBottom: '2px solid white',
            textAlign: '-webkit-center',
          }}
        >
          <div className={styles.logos}>
            <img src="/assets/images/clients/image1.png" alt="logo" />
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          lg={3}
          sx={{
            textAlign: '-webkit-center',
            borderRight: '2px solid white',
            opacity: 1,
            zIndex: 99,
            borderBottom: '2px solid white',
          }}
        >
          <div className={styles.logos}>
            <img src="/assets/images/clients/image2.png" alt="logo" />
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          lg={3}
          sx={{
            textAlign: '-webkit-center',
            borderRight: '2px solid white',
            opacity: 1,
            zIndex: 99,
            borderBottom: '2px solid white',
          }}
        >
          <div className={styles.logos}>
            <img src="/assets/images/clients/image3.png" alt="logo" />
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          lg={3}
          sx={{
            textAlign: '-webkit-center',
            borderRight: '2px solid white',
            opacity: 1,
            zIndex: 99,
            borderBottom: '2px solid white',
          }}
        >
          <div className={styles.logos}>
            <img src="/assets/images/clients/image4.png" alt="logo" />
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          lg={3}
          sx={{
            textAlign: '-webkit-center',
            borderRight: '2px solid white',
            opacity: 1,
            zIndex: 99,
            borderBottom: '2px solid white',
          }}
        >
          <div className={styles.logos}>
            <img src="/assets/images/clients/image5.png" alt="logo" />
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          lg={3}
          sx={{
            textAlign: '-webkit-center',
            borderRight: '2px solid white',
            opacity: 1,
            zIndex: 99,
            borderBottom: '2px solid white',
          }}
        >
          <div className={styles.logos}>
            <img src="/assets/images/clients/image6.png" alt="logo" />
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          lg={3}
          className={styles.bottom_logos}
          sx={{
            textAlign: '-webkit-center',
            borderRight: '2px solid white',
            opacity: 1,
            zIndex: 99,
            borderBottom: '2px solid white',
          }}
        >
          <div className={styles.logos}>
            <img src="/assets/images/clients/image7.png" alt="logo" />
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          lg={3}
          className={styles.bottom_logos}
          sx={{
            textAlign: '-webkit-center',
            marginTop: '40px',
            borderRight: '2px solid white',
            opacity: 1,
            zIndex: 99,
            borderBottom: '2px solid white',
          }}
        >
          <div className={styles.logos}>
            <img src="/assets/images/clients/image8.png" alt="logo" />
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          lg={3}
          className={styles.bottom_logos}
          sx={{ textAlign: '-webkit-center', borderRight: '2px solid white', opacity: 1, zIndex: 99 }}
        >
          <div className={styles.logos}>
            <img src="/assets/images/clients/image9.png" alt="logo" />
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          lg={3}
          className={styles.bottom_logos}
          sx={{ textAlign: '-webkit-center', borderRight: '2px solid white', opacity: 1, zIndex: 99 }}
        >
          <div className={styles.logos}>
            <img src="/assets/images/clients/image10.png" alt="logo" />
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          lg={3}
          className={styles.bottom_logos}
          sx={{ textAlign: '-webkit-center', borderRight: '2px solid white', opacity: 1, zIndex: 99 }}
        >
          <div className={styles.logos}>
            <img style={{marginTop:"-20px"}} src="/assets/images/clients/image11.png" alt="logo" />
          </div>
        </Grid>
        <Grid
          item
          xs={6}
          sm={4}
          lg={3}
          className={styles.bottom_logos}
          sx={{ textAlign: '-webkit-center', borderRight: '2px solid white', opacity: 1, zIndex: 99 }}
        >
          <div className={styles.logos}>
            <img style={{marginTop:"20px"}} src="/assets/images/clients/image12.png" alt="logo" />
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

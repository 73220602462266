import { Button, Card, Checkbox, Grid, Stack, Typography } from "@mui/material";
import React from "react";
import styles from "../styles/Packages.module.css";
import { useDispatch, useSelector } from "react-redux";
import { selectedDeliveryDate, selectPackageOptions } from "src/redux/slices/proposal";
import Iconify from "src/components/Iconify";
import { fCurrency } from "src/utils/formatNumber";
import Label from "src/components/Label";

function formatDate(dat) {
  const date = new Date(dat)
  // Get day, month, year, hours, and minutes
  var day = date.getDate();
  var month = date.getMonth() + 1; // Month is zero-based
  var year = date.getFullYear();
  var hours = date.getHours();
  var minutes = date.getMinutes();

  // Add leading zero if necessary
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  // Return formatted date
  return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
}
function subtractDays (pkg, dateStr, days, orgPkgsOption) {
  var date = new Date(dateStr);
  if(pkg === "Premium"){
    date.setDate(date.getDate() + days);
    if(new Date(date).getDay() === 0){
      if(orgPkgsOption === 0 && (new Date(dateStr).getDay() !== 4)){
        date.setDate(date.getDate() + 2);
      }else{
        date.setDate(date.getDate() + 1);
      }
    }else if( new Date(date).getDay() === 6 ){
      if(orgPkgsOption === 0 && (new Date(dateStr).getDay() !== 4)){
        date.setDate(date.getDate() + 3);
      }else{
        date.setDate(date.getDate() + 2);
      }
    }
  }else if(pkg === "Professional"){
    if(orgPkgsOption === 0){
      date.setDate(date.getDate() + 1);
      if(new Date(date).getDay() === 0){
        date.setDate(date.getDate() + 1);
      }else if( new Date(date).getDay() === 6 ){
        date.setDate(date.getDate() + 2);
      }
    }else if(orgPkgsOption === 2){
      date.setDate(date.getDate() - 1);
      if(new Date(date).getDay() === 0){
        date.setDate(date.getDate() - 2);
      }else if( new Date(date).getDay() === 6 ){
        date.setDate(date.getDate() - 1);
      }
    }else{
      date.setDate(date.getDate());
    }
   
  }else if(pkg === "Economy"){
    date.setDate(date.getDate() - days);
    if(new Date(date).getDay() === 0){
      if(orgPkgsOption === 2 && (new Date(dateStr).getDay() === 1)){
        date.setDate(date.getDate() - 3);
      }else{
        date.setDate(date.getDate() - 2);
      }
    }else if( new Date(date).getDay() === 6 ){
      if(orgPkgsOption === 2 && (new Date(dateStr).getDay() === 1)){
        date.setDate(date.getDate() - 2);
      }else{
        date.setDate(date.getDate() - 1);
      }
    }
  }
  return date;
}


export default function Packages() {

  const {language, selectedPackageOption, packagesNetTotals, details, packagesPrices, orgPkgsOption} = useSelector((state)=>state.proposal);
  const dispatch = useDispatch()
  const {deliveryDateTime, translationItem, status} = details;

  return (
    <div>
      <div className={styles.desktop7Border} />
      <div className={styles.desktop23} style={{backgroundImage:`url('/translationAssets/packages/footer_cards.jpg')`, backgroundRepeat:"no-repeat"}}>
        {/* <div className={styles.div}>19</div> */}
       
        <Grid container spacing={1} className={styles.cards}>
          <Grid item xs={12} xl={1.5}></Grid>
          <Grid item xs={4} sm={12} md={4} xl={3} sx={{ textAlign: "-webkit-center" }}>
            <div className={styles.card1}>
              <img   src="/translationAssets/packages/logo1.png" />
              <div
                className={styles.cardData}
               
              >
                <div>
                  <h1>Economy</h1>
                 {language === "English" ? <p>
                 Automatic translation through one of our software. Quick review of the document by an expert native translator. Translations suitable for understanding a text or for private use, not suitable for publication.

                 </p> : <p>
                    Traduzione automatica attraverso uno dei nostri software.
                    Revisione veloce del documento da parte di un traduttore
                    madrelingua esperto. Traduzioni adatte alla comprensione di un
                    testo o per uso privato, non adatto alla pubblicazione.
                  </p>}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4} sm={12} md={4} xl={3} sx={{ textAlign: "-webkit-center" }}>
            <div className={styles.card1}>
              <img   src="/translationAssets/packages/logo2.png" />
              <div
                className={styles.cardData}
              >
                <div>
                  <h1>Professional</h1>
                 {language === "English" ? <p>
                 Translations carried out by a native speaker expert. Review by one of our specialized Project Managers. Use of the Translation Memory upon request included in the price. High quality translations for standard documents.
                 </p> : <p>
                    Traduzioni effettuate da un esperto madrelingua. Revisione da
                    parte di un nostro Project Manager specializzato. Utilizzo
                    della Translation Memory su richiesta inclusa nel prezzo.
                    Traduzioni di ottima qualità per documenti standard.
                  </p>}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={4} sm={12} md={4} xl={3} sx={{ textAlign: "-webkit-center" }}>
            <div className={styles.card1}>
              <img style={{border:"1px solid white", borderRadius:"50%"}}  src="/translationAssets/packages/logo3.png" />
              <div
                className={styles.cardData}
              >
                <div>
                  <h1>Premium</h1>
                 {language === "English" ? <p>
                 Translations carried out by an expert native translator. Quality control by a second native translator. Review by one of our specialized Project Managers. Translations of high-quality content intended for rapid publication.

                 </p> : <p>
                    Traduzioni effettuate da un traduttore madrelingua esperto.
                    Controllo qualità da parte di un secondo traduttore
                    madrelingua. Revisione da parte un nostro Project Manager
                    specializzato. Traduzioni di contenuti di alta qualità
                    destinati alla pubblicazione in tempi rapidi.
                  </p>}
                </div>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} xl={1.5}></Grid>

        </Grid>
        <div className={styles.footer}>
          <div  className={styles.footerCards}>
     {(status !== "Quote-Won" && status !== "Sent To PM") ? <Grid container spacing={1} sx={{marginInline:"auto"}}>
      <Grid item xs={12} xl={1.5}>

      </Grid>
      <Grid item xs={4} sm={12} md={4} xl={3} sx={{marginInline:"auto"}}>
      <Card
      sx={{border:"0.1px solid #666667", height: "550px", pt:6,pb:3, px: 2,  boxShadow: "0 10px 18px 0 #6766664d" }}
    >
      <Typography variant="h3" align="center"className={styles.heading}  >
        Economy 
      </Typography>

      <Stack component="ul" spacing={2} style={{
        height: "110px",
        marginLeft: "0px",
        marginTop: "30px",
        marginBottom: '20px',
        alignItems: "center"
      }} sx={{ p: 0, my: 5 }}>
        {[
              { text: language === "English" ? 'Automatic translation' : 'Traduzione automatica', isAvailable: true, icon: "mdi:automatic" },
              { text: language === "English" ?  'Light revision' : 'Revisione leggera', isAvailable: true, icon: "octicon:person-24" },
            ].map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              typography: 'body1',
              color: item.isAvailable ? 'text.primary' : 'text.disabled',
              fontSize: language === "English" ? "large" : "medium"
            }}
          >
            <Iconify
              icon={item.icon ? item.icon : 'eva:close-fill'}
              width={30}
              height={25}
              sx={{
                color: item.isAvailable ? 'primary.main' : 'inherit',
              }}
            />
            <Typography variant="body">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>
      <div className={styles.btmDiv}>
      <Stack spacing={1} align="center" sx={{ my: 2,  mt:8  }} className={styles.btmSection}>
        <Typography variant="body" sx={{ color: 'text.secondary' }}>
        {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
        </Typography>
        <Typography variant="subtitle1" style={{fontWeight:"bold"}} >
          {formatDate(subtractDays("Economy", deliveryDateTime,  orgPkgsOption === 0 ? 0 : orgPkgsOption === 2 ? 2 : 1, orgPkgsOption))}
        </Typography>
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2 }}>
        <Typography variant="h3" className={styles.heading}>
        {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[0]))}
        </Typography>
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2 }} className={styles.btmSection}>
       {packagesPrices?.economy?.length === 1 && <Typography variant="body" sx={{ color: 'text.secondary' }}>
          {language === "English" ? `about ${fCurrency(packagesPrices?.economy[0])}/word` : `${fCurrency(packagesPrices?.economy[0])}/parola`}
        </Typography>}
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2, alignItems: "center" }}>
      <Button disabled={selectedPackageOption === 0 ? true : false} type='submit' fullWidth size="large" variant="contained"
       onClick={() =>{ 
        dispatch(selectPackageOptions(0))
        dispatch(selectedDeliveryDate(subtractDays("Economy", deliveryDateTime,  orgPkgsOption === 0 ? 0 : orgPkgsOption === 2 ? 2 : 1, orgPkgsOption)))
        }} >
          {selectedPackageOption === 0 ? `${language === "English" ? "SELECTED PACKAGE" : "PACCHETTO SELEZIONATO"}` : `${language === "English" ? "SELECT":"SELEZIONA"}`}
        </Button>
      {/* <Checkbox
                      sx={{ width: 'fit-content',color:"black", '&.Mui-checked': {
                        color:"blue",
                      }, }}
                      checked={selectedPackageOption === 0 ? true : false} onClick={() => dispatch(selectPackageOptions(0))}
                      inputProps={{ 'aria-label': 'controlled' }}
                    /> */}
      </Stack>
      </div>
     

    </Card>
      </Grid>
      <Grid item xs={4} sm={12} md={4} xl={3} sx={{marginInline:"auto"}}>
      <Card
      sx={{border:"0.1px solid #a631dc", height: "550px", pt:6,pb:3, px: 2, boxShadow: "0 10px 18px 0 #a631dc40" }}
    >
       <Label color="info" sx={{ top: 16, right: 16, position: 'absolute' }}>
         {language === "English" ?  "BEST SELLER" : "PIÙ VENDUTO"}
        </Label>
      <Typography variant="h3" align="center" className={styles.heading} >
      Professional 
      </Typography>

      <Stack component="ul" spacing={2} style={{
        height: "110px",
        marginLeft: "0px",
        marginTop: "30px",
        marginBottom: '20px',
        alignItems: "center"
      }} sx={{ p: 0, my: 5 }}>
        {[
              { text: language === "English" ?  'Specialized Native Translator': 'Traduttore madrelingua specializzato', isAvailable: true, icon: "ion:language" },
              { text: language === "English" ?  'Quality Control Expert': 'Esperto di controllo qualità', isAvailable: true, icon: "charm:clipboard-tick" },
        
            ].map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              typography: 'body1',
              color: item.isAvailable ? 'text.primary' : 'text.disabled',
              fontSize: language === "English" ? "large" : "medium"
            }}
          >
            <Iconify
              icon={item.icon ? item.icon : 'eva:close-fill'}
              width={30}
              height={25}
              sx={{
                color: item.isAvailable ? 'primary.main' : 'inherit',
              }}
            />
            <Typography variant="body">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>
      <div className={styles.btmDiv}>
      <Stack spacing={1} align="center" sx={{ my: 2,  mt:8  }} className={styles.btmSection}>
        <Typography variant="body" sx={{ color: 'text.secondary' }}>
        {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
        </Typography>
        <Typography variant="subtitle1"style={{fontWeight:"bold"}}  >
          {formatDate(subtractDays("Professional", deliveryDateTime, 0, orgPkgsOption  ))}
          </Typography>
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2 }}>
        <Typography variant="h3" className={styles.heading}>
        {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[1]))}
        </Typography>
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2 }} className={styles.btmSection}>
       {packagesPrices?.professional?.length === 1 && <Typography variant="body" sx={{ color: 'text.secondary' }}>
        {language === "English" ? `about ${fCurrency(packagesPrices?.professional[0])}/word` : `${fCurrency(packagesPrices?.professional[0])}/parola`}
        </Typography>}
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2, alignItems:"center" }}>
      <Button disabled={selectedPackageOption === 1 ? true : false} type='submit' fullWidth size="large" variant="contained" onClick={() =>{ 
        dispatch(selectPackageOptions(1))
        dispatch(selectedDeliveryDate(subtractDays("Professional", deliveryDateTime, 0, orgPkgsOption  )))
        }} >
      {selectedPackageOption === 1 ? `${language === "English" ? "SELECTED PACKAGE" : "PACCHETTO SELEZIONATO"}` : `${language === "English" ? "SELECT":"SELEZIONA"}`}
        </Button>
      {/* <Checkbox
                      sx={{ width: 'fit-content', color:"black",'&.Mui-checked': {
                        color:"blue",
                      },}}
                      checked={selectedPackageOption === 1 ? true : false} onClick={() => dispatch(selectPackageOptions(1))}
                      inputProps={{ 'aria-label': 'controlled' }}
                    /> */}
      </Stack>
      </div>
    

    </Card>
      </Grid>
      <Grid item xs={4} sm={12} md={4} xl={3} sx={{marginInline:"auto"}}>
      <Card
      sx={{border:"0.1px solid #2da0f0",height: "550px", pt:6,pb:3, px: 2,  boxShadow: "0 10px 18px 0 #2da0f036" }}
    >
      <Typography variant="h3" align="center"className={styles.heading} >
      Premium 
      </Typography>

      <Stack component="ul" spacing={2} style={{
        height: "110px",
        marginLeft: "0px",
        marginTop: "30px",
        marginBottom: '20px',
        alignItems: "center"
      }} sx={{ p: 0, my: 5 }}>
        {[
              { text: language === "English" ?  'Specialized Native Translator' : 'Traduttore madrelingua specializzato', isAvailable: true, icon: "ion:language" },
              { text: language === "English" ?  'Specialized Reviser':'Revisore specializzato', isAvailable: true, icon: "mingcute:pencil-3-line" },
              { text: language === "English" ?  'Quality Control Expert':'Esperto di controllo qualità', isAvailable: true, icon: "charm:clipboard-tick" },
            ].map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              typography: 'body1',
              color: item.isAvailable ? 'text.primary' : 'text.disabled',
              fontSize: language === "English" ? "large" : "medium"
            }}
          >
            <Iconify
              icon={item.icon ? item.icon : 'eva:close-fill'}
              width={30}
              height={25}
              sx={{
                color: item.isAvailable ? 'primary.main' : 'inherit',
              }}
            />
            <Typography variant="body">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>
      <div className={styles.btmDiv}>
      <Stack spacing={1} align="center" sx={{ my: 2, mt:8 }} className={styles.btmSection}>
        <Typography variant="body" sx={{ color: 'text.secondary' }}>
          {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
        </Typography>
        <Typography variant="subtitle1" style={{fontWeight:"bold"}} >
        {formatDate(subtractDays("Premium", deliveryDateTime, orgPkgsOption === 2 ? 0 : orgPkgsOption === 1 ? 1 : 2, orgPkgsOption))}

        </Typography>
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2 }}>
        <Typography variant="h3" className={styles.heading}>
        {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[2]))}
        </Typography>
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2 }} className={styles.btmSection}>
       {packagesPrices?.premium?.length === 1 && <Typography variant="body" sx={{ color: 'text.secondary' }}>
        {language === "English" ? `about ${fCurrency(packagesPrices?.premium[0])}/word` : 
        `${fCurrency(packagesPrices?.premium[0])}/parola`}
      </Typography>}
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2, alignItems:"center" }}>
      <Button disabled={selectedPackageOption === 2 ? true : false} type='submit' fullWidth size="large" variant="contained" 
      onClick={() => {
        dispatch(selectPackageOptions(2))
        dispatch(selectedDeliveryDate(subtractDays("Premium", deliveryDateTime, orgPkgsOption === 2 ? 0 : orgPkgsOption === 1 ? 1 : 2, orgPkgsOption)))
        }} >
          {selectedPackageOption === 2 ? `${language === "English" ? "SELECTED PACKAGE" : "PACCHETTO SELEZIONATO"}` : `${language === "English" ? "SELECT":"SELEZIONA"}`}
        </Button>
      {/* <Checkbox
                      sx={{ width: 'fit-content', color:"black", '&.Mui-checked': {
                        color:"blue",
                      }, }}
                      checked={selectedPackageOption === 2 ? true : false} onClick={() => dispatch(selectPackageOptions(2))}
                      inputProps={{ 'aria-label': 'controlled' }}
                    /> */}
      </Stack>
      </div>
   

    </Card>
      </Grid>
      <Grid item xs={12} xl={1.5}>

</Grid>
     </Grid>: <Grid container spacing={1} sx={{marginInline:"auto"}}>
      <Grid item xs={12} xl={1.5}>

      </Grid>
     {selectedPackageOption === 0 && <Grid item xs={4} sm={12} md={4} xl={3} sx={{marginInline:"auto"}}>
      <Card
      sx={{border:"0.1px solid #666667", height: "550px", pt:6,pb:3, px: 2,  boxShadow: "0 10px 18px 0 #6766664d" }}
    >
      <Typography variant="h3" align="center"className={styles.heading}  >
        Economy 
      </Typography>

      <Stack component="ul" spacing={2} style={{
        height: "110px",
        marginLeft: "0px",
        marginTop: "30px",
        marginBottom: '20px',
        alignItems: "center"
      }} sx={{ p: 0, my: 5 }}>
        {[
              { text: language === "English" ? 'Automatic translation' : 'Traduzione automatica', isAvailable: true, icon: "mdi:automatic" },
              { text: language === "English" ?  'Light revision' : 'Revisione leggera', isAvailable: true, icon: "octicon:person-24" },
            ].map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              typography: 'body1',
              color: item.isAvailable ? 'text.primary' : 'text.disabled',
              fontSize: language === "English" ? "large" : "medium"
            }}
          >
            <Iconify
              icon={item.icon ? item.icon : 'eva:close-fill'}
              width={30}
              height={25}
              sx={{
                color: item.isAvailable ? 'primary.main' : 'inherit',
              }}
            />
            <Typography variant="body">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>
      <div className={styles.btmDiv}>
      <Stack spacing={1} align="center" sx={{ my: 2,  mt:8  }} className={styles.btmSection}>
        <Typography variant="body" sx={{ color: 'text.secondary' }}>
        {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
        </Typography>
        <Typography variant="subtitle1"style={{fontWeight:"bold"}} >
          {formatDate(new Date(deliveryDateTime))}
        </Typography>
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2 }}>
        <Typography variant="h3" className={styles.heading}>
        {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[0]))}
        </Typography>
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2 }} className={styles.btmSection}>
        {packagesPrices?.economy?.length === 1 &&<Typography variant="body" sx={{ color: 'text.secondary' }}>
          {language === "English" ? `about ${fCurrency(packagesPrices?.economy[0])}/word` : 
          `${fCurrency(packagesPrices?.economy[0])}/parola`}
        </Typography>}
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2, alignItems: "center" }}>
      <Button disabled={selectedPackageOption === 0 ? true : false} type='submit' fullWidth size="large" variant="contained"
       onClick={() => dispatch(selectPackageOptions(0))} >
          {selectedPackageOption === 0 ? `${language === "English" ? "SELECTED PACKAGE" : "PACCHETTO SELEZIONATO"}` : `${language === "English" ? "SELECT":"SELEZIONA"}`}
        </Button>
      {/* <Checkbox
                      sx={{ width: 'fit-content',color:"black", '&.Mui-checked': {
                        color:"blue",
                      }, }}
                      checked={selectedPackageOption === 0 ? true : false} onClick={() => dispatch(selectPackageOptions(0))}
                      inputProps={{ 'aria-label': 'controlled' }}
                    /> */}
      </Stack>
      </div>
     

    </Card>
      </Grid>}
      {selectedPackageOption === 1 &&<Grid item xs={4} sm={12} md={4} xl={3} sx={{marginInline:"auto"}}>
      <Card
      sx={{border:"0.1px solid #a631dc", height: "550px", pt:6,pb:3, px: 2, boxShadow: "0 10px 18px 0 #a631dc40" }}
    >
       <Label color="info" sx={{ top: 16, right: 16, position: 'absolute' }}>
         {language === "English" ?  "BEST SELLER" : "PIÙ VENDUTO"}
        </Label>
      <Typography variant="h3" align="center" className={styles.heading} >
      Professional 
      </Typography>

      <Stack component="ul" spacing={2} style={{
        height: "110px",
        marginLeft: "0px",
        marginTop: "30px",
        marginBottom: '20px',
        alignItems: "center"
      }} sx={{ p: 0, my: 5 }}>
        {[
              { text: language === "English" ?  'Specialized Native Translator': 'Traduttore madrelingua specializzato', isAvailable: true, icon: "ion:language" },
              { text: language === "English" ?  'Quality Control Expert': 'Esperto di controllo qualità', isAvailable: true, icon: "charm:clipboard-tick" },
        
            ].map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              typography: 'body1',
              color: item.isAvailable ? 'text.primary' : 'text.disabled',
              fontSize: language === "English" ? "large" : "medium"
            }}
          >
            <Iconify
              icon={item.icon ? item.icon : 'eva:close-fill'}
              width={30}
              height={25}
              sx={{
                color: item.isAvailable ? 'primary.main' : 'inherit',
              }}
            />
            <Typography variant="body">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>
      <div className={styles.btmDiv}>
      <Stack spacing={1} align="center" sx={{ my: 2,  mt:8  }} className={styles.btmSection}>
        <Typography variant="body" sx={{ color: 'text.secondary' }}>
        {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
        </Typography>
        <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
        {formatDate(new Date(deliveryDateTime))}
        </Typography>
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2 }}>
        <Typography variant="h3" className={styles.heading}>
        {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[1]))}
        </Typography>
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2 }} className={styles.btmSection}>
        {packagesPrices?.professional?.length === 1 &&<Typography variant="body" sx={{ color: 'text.secondary' }}>
        {language === "English" ? `about ${fCurrency(packagesPrices?.professional)}/word` : 
        `${fCurrency(packagesPrices?.professional)}/parola`}
        </Typography>}
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2, alignItems:"center" }}>
      <Button disabled={selectedPackageOption === 1 ? true : false} type='submit' fullWidth size="large" variant="contained" onClick={() => dispatch(selectPackageOptions(1))} >
      {selectedPackageOption === 1 ? `${language === "English" ? "SELECTED PACKAGE" : "PACCHETTO SELEZIONATO"}` : `${language === "English" ? "SELECT":"SELEZIONA"}`}
        </Button>
      {/* <Checkbox
                      sx={{ width: 'fit-content', color:"black",'&.Mui-checked': {
                        color:"blue",
                      },}}
                      checked={selectedPackageOption === 1 ? true : false} onClick={() => dispatch(selectPackageOptions(1))}
                      inputProps={{ 'aria-label': 'controlled' }}
                    /> */}
      </Stack>
      </div>
    

    </Card>
      </Grid>}
     {selectedPackageOption === 2 && <Grid item xs={4} sm={12} md={4} xl={3} sx={{marginInline:"auto"}}>
      <Card
      sx={{border:"0.1px solid #2da0f0",height: "550px", pt:6,pb:3, px: 2,  boxShadow: "0 10px 18px 0 #2da0f036" }}
    >
      <Typography variant="h3" align="center"className={styles.heading} >
      Premium 
      </Typography>

      <Stack component="ul" spacing={2} style={{
        height: "110px",
        marginLeft: "0px",
        marginTop: "30px",
        marginBottom: '20px',
        alignItems: "center"
      }} sx={{ p: 0, my: 5 }}>
        {[
              { text: language === "English" ?  'Specialized Native Translator' : 'Traduttore madrelingua specializzato', isAvailable: true, icon: "ion:language" },
              { text: language === "English" ?  'Specialized Reviser':'Revisore specializzato', isAvailable: true, icon: "mingcute:pencil-3-line" },
              { text: language === "English" ?  'Quality Control Expert':'Esperto di controllo qualità', isAvailable: true, icon: "charm:clipboard-tick" },
            ].map((item) => (
          <Stack
            key={item.text}
            component="li"
            direction="row"
            alignItems="center"
            spacing={1}
            sx={{
              typography: 'body1',
              color: item.isAvailable ? 'text.primary' : 'text.disabled',
              fontSize: language === "English" ? "large" : "medium"
            }}
          >
            <Iconify
              icon={item.icon ? item.icon : 'eva:close-fill'}
              width={30}
              height={25}
              sx={{
                color: item.isAvailable ? 'primary.main' : 'inherit',
              }}
            />
            <Typography variant="body">{item.text}</Typography>
          </Stack>
        ))}
      </Stack>
      <div className={styles.btmDiv}>
      <Stack spacing={1} align="center" sx={{ my: 2, mt:8 }} className={styles.btmSection}>
        <Typography variant="body" sx={{ color: 'text.secondary' }}>
          {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
        </Typography>
        <Typography variant="subtitle1" style={{fontWeight:"bold"}}>
        {formatDate(new Date(deliveryDateTime))}
        </Typography>
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2 }}>
        <Typography variant="h3" className={styles.heading}>
        {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[2]))}
        </Typography>
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2 }} className={styles.btmSection}>
        {packagesPrices?.premium?.length === 1 &&<Typography variant="body" sx={{ color: 'text.secondary' }}>
        {language === "English" ? `about ${fCurrency(packagesPrices?.premium[0])}/word` : 
        `${fCurrency(packagesPrices?.premium[0])}/parola`}
      </Typography>}
      </Stack>
      <Stack spacing={1} align="center" sx={{ my: 2, alignItems:"center" }}>
      <Button disabled={selectedPackageOption === 2 ? true : false} type='submit' fullWidth size="large" variant="contained" 
      onClick={() => dispatch(selectPackageOptions(2))} >
          {selectedPackageOption === 2 ? `${language === "English" ? "SELECTED PACKAGE" : "PACCHETTO SELEZIONATO"}` : `${language === "English" ? "SELECT":"SELEZIONA"}`}
        </Button>
      {/* <Checkbox
                      sx={{ width: 'fit-content', color:"black", '&.Mui-checked': {
                        color:"blue",
                      }, }}
                      checked={selectedPackageOption === 2 ? true : false} onClick={() => dispatch(selectPackageOptions(2))}
                      inputProps={{ 'aria-label': 'controlled' }}
                    /> */}
      </Stack>
      </div>
   

    </Card>
      </Grid>}
      <Grid item xs={12} xl={1.5}>

</Grid>
     </Grid>}
         
          </div>
        </div>
      </div>
    </div>
  );
}
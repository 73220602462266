import React from "react";
import styles from '../styles/Connect.module.css'
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { useSelector } from "react-redux";

export default function Connect() {

  const {language} = useSelector((state)=>state.proposal);

  return (
    <div style={{boxShadow:"0 0 5px 3px rgb(0 0 0 / 40%)"}}>
      <div className={styles.desktop8Border} />
      <div className={styles.desktop8}>
        <div className={styles.desktop8Child} />
        <img
          className={styles.unionIcon}
          alt=""
          src="/translationAssets/desktop7/union9.svg"
        />
        <div className={styles.desktop8Item} />
        {/* <div className={styles.div}>08</div> */}
        <div className={styles.aboutUs} style={{textTransform:"uppercase"}}>
          {language === "English" ? "THE SOFTWARE – Rafiky Connect 1.4" : 
        "IL SOFTWARE – Rafiky Connect 1.4"}
        </div>
        <div className={styles.text3543Parent} style={{marginTop:"-15px"}} >
          <img alt="" src="/rafiky/logo.webp" />{" "}
        </div>
        <p className={styles.list} style={{top:"80px"}}>
            {language === "English" ? "With Rafiky you can transform any place into a conference room! We combine the power of cloud technology with modern devices to facilitate the interpretation service." : `Con Rafiky puoi  trasformare qualsiasi luogo in una sala conferenze! Uniamo la potenza della tecnologia cloud con i moderni dispositivi per agevolare il servizio d’interpretariato.`}
        </p>
        <div className={styles.list}>
          <ul>
            <li>
                {language === "English" ? "Any language in real time" : "Qualsiasi lingua in tempo reale"}
            </li>
            <li>
            {language === "English" ? "Highest Quality Video & Crystal Clear Audio" : "Video di altissima qualità & Audio cristallino"}
            </li>
            <li>
            {language === "English" ? "Branding customization" : "Personalizzazione branding"}
            </li>
            <li>
            {language === "English" ? "Compatibility with all Web Conferencing software" : "Compatibilità con tutti i software di Web Conferencing"}
            </li>
            <li>
            {language === "English" ? "Upload PPT & Video" : "Upload PPT & Video"}
            </li>
            <li>
            {language === "English" ? "Chat Translation in +60 languages ​​- NEW!" : "Traduzione Chat in + di 60 lingue - NEW!"}
            </li>
            <li>
            {language === "English" ? "Speaker's girth" : "Sottopancia Relatore"}
            </li>
            <li>
            {language === "English" ? "Video Box with sign language" : "Video Box con lingua dei segni"}
            </li>
            <li>
            {language === "English" ? "Virtual Voting System" : "Sistema di votazione virtuale"}
            </li>
            <li>
            {language === "English" ? "Post-event reporting" : "Reportistica post-evento"}
            </li>
          </ul>
        </div>
        <div className={styles.desktop8Item}>
                <div style={{width:"720px", paddingTop:"50px",marginInline:"auto"}}>
    <LiteYouTubeEmbed 
        id="KCgEhNeVhUc"
        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
    />
          </div>
        </div>
      </div>
    </div>
  );
}









import React from 'react';
import { styles } from '../globalStyles';
import { Image, Text, View } from '@react-pdf/renderer';
import { icons } from '../icons';

const Preventivo = ({ language = "Italian", addOns, selectedPackageOption }) => {
 if(addOns?.urgency){

  return (
    <View style={styles.preventivo}>
    <View style={styles.preventivoHeaderUrgency}>
      <Text>
      {language === 'English'
                            ? `We confirm your request for an urgent translation service and are pleased to provide the following quote. However, we would like to emphasize that the urgent nature of this service may impact the quality of the translation provided. The client is requested to carefully read the following clause and confirm their consent before proceeding with the acceptance of the quote:
                            The Client understands and agrees that the request for an urgent translation service involves significant time constraints, which may limit the time available for translator selection, translation completion, text revision, and translation quality control. Consequently, the quality of the translation may be lower compared to services performed within standard timeframes.
                            The Client acknowledges that, despite our best efforts, the required speed may affect the accuracy and completeness of the provided translation. This limitation is unavoidable given the time constraints imposed by the urgent nature of the service.
                            The Client waives any claims or liabilities against the service provider for any spelling, grammatical, lexical, or syntactical errors (there is a high probability of style and form discrepancies, with textual inconsistencies due to the division of texts among different translators), omissions, and/or reduction in the quality of the translation caused by the urgent nature of the request.
                            The Client is aware that any requests for revision or correction may be limited due to time constraints, and the service provider will not guarantee in any way the complete elimination of any defects.
                            The Client confirms having read and understood this clause, expressly acknowledging the nature of the urgent service and accepting the possible effects on the quality of the provided translation.
                            By accepting this quote, the Client confirms their consent to the specified terms and conditions, including the above-mentioned clause.
                            If it is possible to extend the delivery date, please inform us so that we can review the quote. LingoYou will make every effort to avoid the aforementioned issues, but cannot guarantee the same quality as a translation completed within standard timeframes and, therefore, assumes no responsibility for the final result.`   : `
                            Confermiamo la vostra richiesta di servizio di traduzione urgente e siamo lieti di fornire il seguente preventivo. Tuttavia, desideriamo sottolineare che la natura urgente di questo servizio potrebbe influire sulla qualità della traduzione fornita. Il cliente è pregato di leggere attentamente la seguente clausola e confermare il proprio consenso prima di procedere con l'accettazione del preventivo:
                            Il Cliente comprende e accetta che la richiesta di un servizio di traduzione urgente comporta restrizioni temporali significative, il che potrebbe limitare il tempo disponibile per la selezione del traduttore, la realizzazione della traduzione, la revisione dei testi e il controllo di qualità della traduzione. Di conseguenza, la qualità della traduzione potrebbe essere inferiore rispetto ai servizi effettuati in tempi standard.
                            Il Cliente riconosce che, nonostante i nostri migliori sforzi, la rapidità richiesta potrebbe influire sulla precisione e sulla completezza della traduzione fornita. Tale limitazione è inevitabile date le restrizioni temporali imposte dalla natura urgente del servizio.
                            Il Cliente rinuncia a qualsiasi pretesa o responsabilità nei confronti del fornitore di servizi per eventuali errori ortografici, grammaticali, lessicali o sintattici (vi è un’alta probabilità che si verifichino discrepanze a livello di stile e di forma, con incongruenze testuali dovute alla divisione dei testi su diversi traduttori), omissioni e/o riduzione della qualità della traduzione causati dalla natura urgente della richiesta.
                            Il Cliente è consapevole che eventuali richieste di revisione o correzione potrebbero essere limitate a causa delle restrizioni temporali e che il fornitore di servizi non garantirà, in alcun modo, la completa eliminazione di eventuali difetti.
                            Il Cliente conferma di aver letto e compreso la presente clausola, riconoscendo espressamente la natura del servizio urgente e accettando i possibili effetti sulla qualità della traduzione fornita.
                            Con l'accettazione del presente preventivo, il Cliente conferma il suo consenso ai termini e alle condizioni specificati, inclusa la clausola sopraesposta.
                            Qualora fosse possibile estendere la data di consegna, vi preghiamo di comunicarcelo così da poter rivedere il preventivo.  LingoYou cercherà di avvalersi di ogni strumento per evitare quanto sopra citato, ma non potrà garantire la stessa qualità di una traduzione svolta entro le tempistiche standard e, pertanto, non si assume la responsabilità del risultato finale.   `}
      </Text>
    </View>
  </View>
  )
 }
  return (
   
   <View style={styles.preventivo}>
      <View style={styles.preventivoHeader}>
        <Text>
        {language === 'English' ? 'The quote includes:' : 'Il preventivo include:'}
        </Text>
      </View>
      <View style={styles.preventivoBody}>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.customerService} />
          {language === 'English'
                              ? 'Customer Service available 24/7;'
                              : 'Servizio Clienti disponibile 24/7;'}
                                      </Text>
       {selectedPackageOption === 0 ? '' : 
       <>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.translation} />
          {language === 'English'
                              ? 'Professional translation service carried out by a native linguist with at least 5 years of experience in the requested sector;'
                              : 'Servizio di traduzione professionale effettuato da linguista madrelingua con almeno 5 anni di esperienza nel settore richiesto;'}
                        </Text>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.qualityControl} />
          {language === 'English'
                              ? 'Quality Control carried out by a second professional mother tongue translator (reserved for the Premium Service);'
                              : 'Controllo Qualità effettuato da parte di un secondo traduttore madrelingua professionista (riservato al Servizio Premium);'}
         </Text>
         </>
         }
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.qualityControl} />
          {language === 'English'
                              ? 'Final Quality Control carried out by the Project Manager;'
                              : 'Controllo Qualità Finale effettuato da parte del Project Manager;'}
                         </Text>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.formatting} />
          {language === 'English'
                              ? 'Formatting & Graphic Setting;'
                              : 'Formatting & Impostazione Grafica;'}
                                 </Text>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.memory} />
          {language === 'English'
                              ? 'Creation of Translation Memories;'
                              : 'Creazione Memorie di traduzione;'}
                              </Text>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.audio} />
          {language === 'English'
                              ? 'Development of specific Glossaries on translated texts;'
                              : 'Sviluppo di Glossari specifici sui testi tradotti;'} </Text>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.videoCall} />
          {language === 'English'
                              ? 'Final feedback request via Call & Email.'
                              : 'Richiesta feedback finale tramite Call & Email.'}
        </Text>
      </View>
    </View>
  );
};

export default Preventivo;
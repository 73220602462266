import React from 'react';
import styles from '../styles/T&CsWeb.module.css';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FaRegHandPointLeft } from 'react-icons/fa';
import { BsVectorPen } from 'react-icons/bs';
import { addName } from 'src/redux/slices/proposal';
import styles1 from '../styles/AboutUs2.module.css';

function formatDate(param) {
  // Extracting date components
  const date = new Date(param);
  var day = date.getDate();
  var month = date.getMonth() + 1; // Months are zero indexed
  var year = date.getFullYear() % 100; // Getting last two digits of the year
  var hours = date.getHours();
  var minutes = date.getMinutes();

  // Adding leading zeros if necessary
  day = (day < 10 ? '0' : '') + day;
  month = (month < 10 ? '0' : '') + month;
  year = (year < 10 ? '0' : '') + year;
  hours = (hours < 10 ? '0' : '') + hours;
  minutes = (minutes < 10 ? '0' : '') + minutes;

  // Concatenating date components
  var formattedDate = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;

  return formattedDate;
}
function subtractDays (dateStr, days) {
  console.log("Option", days)
  var date = new Date(dateStr);
  if(days === 2){
    date.setDate(date.getDate() + 1);
  }else if(days === 1){
    date.setDate(date.getDate());
  }else if(days === 0){
    date.setDate(date.getDate() - 1 );

  }else{
    date.setDate(date.getDate());
  }
  return date;
}
export default function TermsAndCondition({
  handleAcceptance
}) {
  const {language, name, details:data, isBtn, selectedPackageOption, packagesNetTotals } = useSelector((state)=>state.proposal);
 const dispatch = useDispatch()
 const {workingDaysToDeadline, companyNameAndId, additionalService, additionalService2, status} = data;

  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [openTerms, setOpenTerms] = React.useState(false);
  const endDate = (packagesNetTotals?.length > 0 && (status !== "Quote-Won" && status !== "Sent To PM")) ? new Date(subtractDays(data?.deliveryDateTime, selectedPackageOption)) : new Date(data?.deliveryDateTime)
  const endDateDay = String(endDate.getDate()).padStart(2, '0');
  const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); //January is 0!
  const endDateYear = String(endDate.getFullYear()).slice(-2);
  const endingDate = data?.deliveryDateTime && `${endDateDay +  '/' +  endDateMonth +  '/' +  endDateYear}`
  
  // Format the hours and minutes as needed
  const endDateHours = endDate.getHours() < 10 ? '0' + endDate.getHours() : endDate.getHours();
  const endDateMins = endDate.getMinutes() < 10 ? '0' + endDate.getMinutes() : endDate.getMinutes();
  const swornDeliveryDateServices = additionalService.length > 0 && additionalService?.filter(((service)=>service?.swornDeliveryDateTime))
  const swornDeliveryDateServicesOptions = additionalService2
  ?.map(subArray =>
    subArray.filter(item =>
      item.swornDeliveryDateTime !== "" && item.swornDeliveryDateTime !== null
    )
  )
  // .filter(subArray => subArray.length > 0);
  const swornDeliveryDateServicesOptionsLength = swornDeliveryDateServicesOptions?.length > 0 && swornDeliveryDateServicesOptions?.filter(subArray => subArray.length > 0);
 
  const handleClickOpenTerms = () => {
    setError('');
    setOpenTerms(true);
  };
  const handleClickCloseTerms = () => {
    setOpenTerms(false);
    handleAcceptance();
  };
  const handleCloseTerms = () => {
    setOpenTerms(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
    handleAcceptance();
  };
  const handleClose = () => {
    setOpen(false);
  };
  return (
    <div className={styles.desktop7}>
     <div className={styles1.desktop8Item} />
        {/* <div className={styles.div}>17</div> */}
        <div className={styles1.aboutUs}>{language === "English" ? 'Terms and conditions' : "Termini e Condizioni"}</div>
        <div className={styles1.text3543Parent} style={{top:'0.37%'}}>
          <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{' '}
        </div>
      <Grid container spacing={3} sx={{ marginTop: '70px' }}  className={styles.sections}>
        <Grid item xs={4}>
        <p className={styles.titles}><b>
          {language === "English" ? `Quote expiry period` : `Scadenza`}: 
          </b></p>
          <p>
          {language === 'English'
                ? `-	It is necessary to send confirmation of the quote by `
                : `- È necessario inviare conferma del preventivo entro il `}
            <b style={{fontSize:"15px"}}>
            {formatDate(data?.quoteDeadline)}.
            </b>
             
          </p>
          <p>
          {language === "English" ? `-	After this date, the quote may be subject to change.` : 
          `-	Oltre tale data il preventivo potrebbe subire delle variazioni.`}

          </p>
        <p className={styles.titles}><b>
          {language === "English" ? `Delivery time` : `Tempi di consegna`}: 
          </b></p>
          <p>
          - <b style={{fontSize:"15px"}}>
          {workingDaysToDeadline} {' '}
            </b>
            {language === "English" ? `working days - the company will not be held liable for any delay in delivery caused by third parties (express courier/postal service).` : 
            `giorni lavorativi - l'azienda non si assume la responsabilità di eventuali ritardi sulla consegna causati da terzi (corriere espresso/servizio postale).`}
          </p>
           
          <p>
        
            {language === "English" ? `- The translation will be delivered by ` : 
            `-	La traduzione verrà consegnata entro le ore `}
            <b style={{fontSize:"15px"}}>
            {endDateHours}:{endDateMins}{' '}
            </b>
          {language === "English" ? `on ` : 
            `del giorno `}
            <b style={{fontSize:"15px"}}>
            {endingDate}.
            </b>
          </p>
          {(swornDeliveryDateServices.length > 0 || swornDeliveryDateServicesOptionsLength) && <>
          {(additionalService.length > 0 && !swornDeliveryDateServicesOptionsLength) && additionalService.map((service, index) => {
  if (service?.swornDeliveryDateTime) {
    const endDate = new Date(service?.swornDeliveryDateTime);
    const endDateDay = String(endDate.getDate()).padStart(2, '0');
    const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
    const endDateYear = String(endDate.getFullYear()).slice(-2);
    const endDateHours = endDate.getHours() < 10 ? '0' + endDate.getHours() : endDate.getHours();
    const endDateMins = endDate.getMinutes() < 10 ? '0' + endDate.getMinutes() : endDate.getMinutes();
    const endingDate =
      endDateDay +
      '/' +
      endDateMonth +
      '/' +
      endDateYear;

    if ((index === additionalService.length - 1)) {
      // Last item in the array
      return <> 
      {language === "English" ? <p>
      - The sworn translation will be delivered by <b style={{fontSize:"15px"}}>
        {endDateHours}:{endDateMins}
        </b> on  <b style={{fontSize:"15px"}}>
      {endingDate}
        </b>.
      </p> :<p>
      - La traduzione asseverata verrà consegnata entro le ore  <b style={{fontSize:"15px"}}>
        {endDateHours}:{endDateMins}
        </b> del giorno <b style={{fontSize:"15px"}}>
      {endingDate}
        </b>.
      </p>  }
      </>;
    } else {
      // Not the last item, add a comma
      if( new Date(additionalService[index+1]?.swornDeliveryDateTime).toDateString() !== new Date(service?.swornDeliveryDateTime).toDateString()){
        return <> 
        {language === "English" ? <p>
        -	The sworn translation will be delivered by  <b style={{fontSize:"15px"}}>
          {endDateHours}:{endDateMins}
          </b> on  <b style={{fontSize:"15px"}}>
        {endingDate + `${additionalService[index+1]?.swornDeliveryDateTime ? ', ': ''}`}
          </b>.
        </p> : <p>
        - La traduzione asseverata verrà consegnata entro le ore  <b style={{fontSize:"15px"}}>
          {endDateHours}:{endDateMins}
          </b> del giorno <b style={{fontSize:"15px"}}>
        {endingDate + `${additionalService[index+1]?.swornDeliveryDateTime ? ', ': ''}`}
          </b>.
        </p>}
        </>

      }
    }
  } else {
    return '';
  }
})}

{swornDeliveryDateServicesOptions.length > 0 &&  [swornDeliveryDateServices, ...swornDeliveryDateServicesOptions]?.map((item, ind)=>(
     <>
      {item.length > 0 && <>{language === "English" ? " Option " : " Opzione "} {ind+1}:  {item.map((service, index) => {
     console.log("Service", service);
     if (service?.swornDeliveryDateTime) {
        const endDate = new Date(service?.swornDeliveryDateTime);
        const endDateDay = String(endDate.getDate()).padStart(2, '0');
        const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
        const endDateYear = String(endDate.getFullYear()).slice(-2);
        const endDateHours = endDate.getHours() < 10 ? '0' + endDate.getHours() : endDate.getHours();
    const endDateMins = endDate.getMinutes() < 10 ? '0' + endDate.getMinutes() : endDate.getMinutes();
        const endingDate =
          endDateDay +
          '/' +
          endDateMonth +
          '/' +
          endDateYear;
    
        if (index === item.length - 1) {
          // Last item in the array
          return <>
          {language === "English" ? <p>
          -	The sworn translation will be delivered by  <b style={{fontSize:"15px"}}>
            {endDateHours}:{endDateMins}
            </b> on  <b style={{fontSize:"15px"}}>
          {endingDate}.
            </b>
          </p>  : <p>
          - La traduzione asseverata verrà consegnata entro le ore  <b style={{fontSize:"15px"}}>
            {endDateHours}:{endDateMins}
            </b> del giorno <b style={{fontSize:"15px"}}>
          {endingDate}.
            </b>
          </p>}
          </>;
        } else {
          if( new Date(item[index+1]?.swornDeliveryDateTime).toDateString() !== new Date(service?.swornDeliveryDateTime).toDateString()){
            return <>
            {language !== "English" ? <p>
            - La traduzione asseverata verrà consegnata entro le ore  <b style={{fontSize:"15px"}}>
              {endDateHours}:{endDateMins}
              </b> del giorno <b style={{fontSize:"15px"}}>
            {endingDate}.
              </b>
            </p> : <p>
            - The sworn translation will be delivered by <b style={{fontSize:"15px"}}>
              {endDateHours}:{endDateMins}
              </b> on  <b style={{fontSize:"15px"}}>
            {endingDate}.
              </b>
            
            </p>}
            </>;
          }
          // Not the last item, add a comma
        }
      } else {
        return '';
      }
    })}</>}
     </>
    
     ))}
         </>}
          <p className={styles.titles}><b>
          {language === "English" ? `Format of documents` : `Formato dei documenti`}:
          </b></p>
          <p>
           <b>
            {language === "English" ? `-	PDF` : `-	PDF`}: 
            </b>
            {language === "English" ? `All translated documents will be delivered to the Customer in non-editable PDF format. This Quote does not include the DTP service as the original document.` : 
            `tutti i documenti tradotti verranno consegnati al Cliente in formato PDF non editabile. Il presente Preventivo non include il servizio di impaginazione grafica come da documento originale.`}
          </p>
          <p>
           <b>
            {language === "English" ? `- Word` : `-	Word`}: 
            </b>
            {language === "English" ? `Only if explicitly requested by the Customer, translated documents will be delivered in a Word editable file with simple scrolling text. This quote does not include the DTP service as the original document.` : 
            `solo se esplicitamente richiesto dal Cliente, i documenti tradotti verranno consegnati su file editabile Word con testo semplice a scorrere. Il presente preventivo non include il servizio di impaginazione grafica come da documento originale.`}
          </p>
          <p>
           <b>
            {language === "English" ? `- Paper package` : `-	Plico cartaceo`}: 
            </b>
            {language === "English" ? `In the case of sworn/legalized translations - subject to customer privacy and data protection laws - a paper package consisting of the original document, the translated document and a statement of asseveration, legalization or apostille will be sent by express courier/postal service.` : 
            `nel caso di traduzioni giurate/legalizzate - in base alle leggi che regolano la privacy del cliente e la protezione dei dati - verrà spedito via corriere espresso/servizio postale un plico cartaceo composto dal documento originale, dal documento tradotto e da un verbale di attestazione di asseverazione, legalizzazione o apostille.`}
          </p>
          <p>
           <b>
            {language === "English" ? `- Partial scan` : `- Scansione parziale`}: 
            </b>
            {language === "English" ? `In the case of sworn/legalized translations, depending on the number of pages of the translated documents, the digital delivery of the document may not be complete with all pages unless the office expenses are agreed at the time of quote creation.` : 
            `nel caso di traduzioni giurate/legalizzate, a seconda del numero delle pagine dei documenti tradotti, la consegna digitale del documento potrebbe non avvenire completa di tutte le pagine a meno che non vengano concordati i costi di cancelleria in fase di preventivo.`}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Delivery mode` : `Modalità di consegna`}: 
          </b></p>
          <p>
           <b>
            {language === "English" ? `- Translations of texts` : `-	Traduzioni di testi`}: 
            </b>
            {language === "English" ? `Document in digital format sent by e-mail to the address provided at the time of quote creation.` : 
            `documento in formato digitale inviato via e-mail all’indirizzo fornito in fase di preventivo.`}
          </p>
          <p>
           <b>
            {language === "English" ? `- Sworn/legalized translations` : `-	Traduzioni giurate/legalizzate`}: 
            </b>
            {language === "English" ? `Paper document sent to the Customer at the address provided at the time of quote creation by express courier or postal service.` : 
            `documento in formato cartaceo spedito al Cliente all’indirizzo di recapito fornito in fase di preventivo tramite corriere espresso o servizio postale.`}
          </p>
        
          <p className={styles.titles}><b>
          {language === "English" ? `Exclusion of liability - Sworn/legalized translations:` : `Esclusione di responsabilità - Traduzioni giurate/legalizzate`}: 
          </b></p>
          <p>
        
            {language === "English" ? `- Each translated document will be sent to the Customer for review and confirmation of personal information before being sworn at the appropriate locations (court). The company will not be responsible for any delay in delivery if the customer does not confirm the accuracy of the data in the required time.` : 
            `-	Ogni documento tradotto, prima di essere asseverato presso le opportune sedi (tribunale), verrà inviato al Cliente per la revisione e conferma dei dati personali. L'azienda non si assume la responsabilità di eventuali ritardi sulla consegna qualora il cliente non confermasse la correttezza dei dati nei tempi richiesti.`}
          </p>
          <p>
        
            {language === "English" ? `- The company does not accept responsibility for any delays in delivery caused by third parties (express courier/postal service).` : 
            `-	L’azienda non si assume la responsabilità di eventuali ritardi sulla consegna causati da terzi (corriere espresso/servizio postale). `}
          </p>
          <p>
        
        {language === "English" ? `- The number of revenue stamps will be agreed at the time of quote creation.` : 
        `-	Il numero delle marche da bollo verrà concordato in fase di preventivo.`}
         </p>
         <p>
        
        {language === "English" ? `- The actual date of delivery of the asseveration will be reconfirmed on the day of the asseveration as it is at the discretion of the court.` : 
        `-	La data effettiva di consegna dell’asseverazione verrà riconfermata il giorno stesso dell’asseverazione poiché a discrezione del tribunale.`}
         </p>
         <p className={styles.titles}><b>
          {language === "English" ? `Services` : `Servizi`}: 
          </b></p>
          <p>
        
        {language === "English" ? `- The subject LingoYou Group Srl indicated in the above quote ("LingoYou Group Srl") undertakes to provide the Customer with translation and other services through its project management system. ` : 
        `-	Il soggetto LingoYou Group Srl  indicato nel suddetto Preventivo ("LingoYou Group Srl ") si impegna a fornire al Cliente servizi di traduzione e di altro tipo per mezzo del proprio sistema di gestione dei progetti.`}
         </p>
         <p>
        
        {language === "English" ? `- LingoYou Group Srl will make all commercially reasonable efforts to provide the translation or other services within the timelines estimated in writing by LingoYou Group Srl or, if no written estimate has been provided by LingoYou Group Srl, the latter will perform its obligations in accordance with its usual "standard delivery" parameters, subject to the circumstances prevailing from time to time. ` : 
        `-	LingoYou Group Srl  compirà ogni sforzo commercialmente ragionevole al fine di fornire i servizi di traduzione o di altro tipo rispettando le tempistiche stimate per iscritto da LingoYou Group Srl  o, qualora non sia stata fornita alcuna stima scritta da parte di LingoYou Group Srl , quest'ultimo adempierà ai propri obblighi in conformità con i propri consueti parametri di "consegna standard", soggetti alle circostanze esistenti di volta in volta. `}
         </p>
         <p>
        
        {language === "English" ? `- "Urgent" projects that require service delivery within shorter time frames than the standard will be accepted on the assumption that, in certain cases, the usual quality and project management level of LingoYou Group Srl may not be respected and LingoYou Group Srl may charge additional fees due to the complexity and costs necessary to meet such requests.` : 
        `- I progetti "urgenti" che richiedono il completamento entro tempistiche più brevi rispetto alla consegna standard verranno accettati sull'assunto che in determinati casi il livello consueto di qualità e di gestione dei progetti di LingoYou Group Srl  potrebbe non essere rispettato e che LingoYou Group Srl  possa applicare tariffe aggiuntive a causa della complessità e dei costi necessari a soddisfare tali richieste.`}
         </p>
         <p className={styles.titles}><b>
          {language === "English" ? `Quote` : `Preventivo`}: 
          </b></p>
         <p style={{lineHeight:`${language === "English" ? "25px" : "normal"}`}}>
        
        {language === "English" ? `- This Quote is strictly bound by the Terms & Conditions set forth herein and is based on the information and files made available to LingoYou Group Srl on the date of the Quote creation.` : 
        `-	Il presente Preventivo è strettamente vincolato ai Termini & Condizioni qui indicati e si basa sulle informazioni e sui files resi disponibili a LingoYou Group Srl alla data del Preventivo.  `}
         </p>
         <p style={{lineHeight:`${language === "English" ? "25px" : "normal"}`}}>
        
        {language === "English" ? `- In order to ensure that the line of work is respected, LingoYou Group Srl requires the timely receipt of all materials for the execution of the desired services and clarification of the requirements.` : 
        `-	Al fine di garantire il rispetto della linea di lavoro, LingoYou Group Srl  necessita della ricezione tempestiva di tutti i materiali per l'esecuzione dei servizi desiderati e di un chiarimento dei requisiti.`}
         </p>
         {/* <p>
        
        {language === "English" ? `- Any delays due to failure to receive the above exonerate LingoYou Group Srl from non-delivery in proportion to the time taken by the customer to clarify the requirements and for the sections of the work affected by the requirements.` : 
        `-	Eventuali ritardi dovuti alla mancata ricezione di quanto sopra esposto esonerano LingoYou Group Srl  dalla mancata consegna in proporzione al tempo impiegato dal cliente per chiarire i requisiti e per le sezioni del lavoro interessate dai requisiti.`}
         </p> */}
    
         {language === "English" ? "" : <>
         <p>
        
        {language === "English" ? `` : 
        `-	Eventuali ritardi dovuti alla mancata ricezione di quanto sopra esposto esonerano LingoYou Group Srl  dalla mancata consegna in proporzione al tempo impiegato dal cliente per chiarire i requisiti e per le sezioni del lavoro interessate dai requisiti.`}
         </p>
         {/* <p >
        
        {language === "English" ? `` : 
        ``}
         </p>    */}
         </> 
        }
        </Grid>
        <Grid item xs={4} sx={{marginTop:"3px"}}>
          {language === "English" && <>
          <p>
        
        {language === "English" ? `- Any delay due to failure to receive the above shall relieve LingoYou Group Srl of the failure to deliver in proportion to the time taken by the customer to clarify the requirements and for the sections of the work affected by the requirements.` : 
        `-	Eventuali ritardi dovuti alla mancata ricezione di quanto sopra esposto esonerano LingoYou Group Srl  dalla mancata consegna in proporzione al tempo impiegato dal cliente per chiarire i requisiti e per le sezioni del lavoro interessate dai requisiti.`}
         </p> 
          
          </> 
        }
         <p style={{lineHeight:`${language === "English" ? "normal" : "26px"}`}}>
        
        {language === "English" ? `- Any changes to the project scope (revision, addition, deletion of text, languages, etc.) will result in a project change order being issued.` : 
        `-	Qualsiasi modifica all'ambito del progetto (revisione, aggiunta, eliminazione di testo, lingue, ecc.) comporterà l'emissione di un ordine di modifica del progetto. `}
         </p>  
        <p style={{lineHeight:`${language === "English" ? "normal" : "26px"}`}}>
        
        {language === "English" ? `- The specific terms in the above quote will supersede any conflicting provisions in these Terms & Conditions.` : 
        `-	I termini specifici nel Preventivo di cui sopra sostituiranno qualsiasi disposizione contrastante nei presenti Termini & Condizioni.`}
         </p> 
       
       
          <p className={styles.titles}><b>
          {language === "English" ? `Fees and payments` : `Commissioni e pagamenti`}: 
          </b></p>
          <p>
          {language === 'English' ? `- Payment terms`: `- Termini di pagamento`}: {' '}
         <b style={{fontSize:"15px"}}>
         {companyNameAndId?.paymentTerms === 'Immediate'
                        ? `${language === "English" ? "Prepayment" : "Pagamento Anticipato" }`
                        : `${language === "English" ? companyNameAndId?.paymentTerms : `${String(companyNameAndId?.paymentTerms).includes("days") ? 
                        String(companyNameAndId?.paymentTerms).replace("days", "giorni") : 
                        String(companyNameAndId?.paymentTerms)}`}`
                        }.
         </b>
          
          </p>
          <p style={{lineHeight:`${language === "English" ? "normal" : "26px"}`}}>
          {language === "English" ? `- LingoYou Group Srl agrees to charge, and Customer agrees to pay, fees for services provided by LingoYou Group Srl or its subsidiaries at the current rates of LingoYou Group Srl or, if applicable, according to rates negotiated separately and agreed in writing by LingoYou Group Srl and the Customer before the beginning of the provision of the services.` : 
          `-	LingoYou Group Srl accetta di addebitare, e il Cliente accetta di pagare, le commissioni per i servizi forniti da LingoYou Group Srl o dalle sue consociate secondo le tariffe correnti di LingoYou Group Srl o, qualora applicabile, secondo tariffe negoziate separatamente e concordate per iscritto da LingoYou Group Srl e dal Cliente prima dell'inizio della prestazione dei servizi.`}
 
          </p>
          
          <p >
          {language === "English" ? `- All payments must be made within thirty (30) days of the invoice date (or as stipulated in the contract).` : 
          `-	Tutti i pagamenti devono essere effettuati entro le tempistiche concordate (o in base a quanto previsto dal contratto).`}
         
          </p>
          <p>
          {language === "English" ? `- In the event of a dispute in good faith concerning an invoice entry, the Customer undertakes to promptly inform LingoYou Group Srl of the nature of the dispute in writing (including a description of the reason for the dispute) within the above thirty (30) day period and agrees to pay the undisputed portion as set forth herein.` : 
          `-	In caso di eventuale controversia in buona fede riguardante una voce relativa alla fattura, il Cliente si impegna a informare tempestivamente LingoYou Group Srl circa la natura della controversia per iscritto (includendo una descrizione della motivazione della controversia) entro il suddetto periodo di trenta (30) giorni e si impegna a pagare la porzione non contestata come stabilito nel presente documento.`}
          </p>
          <p>
          {language === "English" ? `- Any undisputed amount not paid within thirty (30) days of the date of invoice issued by LingoYou Group Srl will accrue interest at the rate of ` : 
          `-	Qualsiasi importo non contestato non pagato entro trenta (30) giorni dalla data di emissione della fattura di LingoYou Group Srl maturerà interessi al tasso di `}
           <b>
           1,5% 
           </b>
           {language === "English" ? ` until full payment, or at the highest legal interest rate, whichever is lower (interest will be charged daily).` :
          ` fino al completo pagamento, ovvero al massimo tasso di interesse legale, qualora inferiore (gli interessi verranno addebitati quotidianamente).`}

          </p>
          <p>
          {language === "English" ? `- All amounts due by the Client under this Agreement shall be paid to LingoYou Group Srl without compensation or counterclaim and without deduction or withholding.  ` : 
          `-	Tutti gli importi dovuti dal Committente in virtù del presente Accordo saranno corrisposti a LingoYou Group Srl senza compensazione o domanda riconvenzionale e senza detrazione o ritenuta. `}
           
          </p>
          <p>
          {language === "English" ? `- The acceptance by LingoYou Group Srl of the partial payment or any payment less than the total amount due at any time does not constitute a release or waiving of LingoYou Group Srl's rights to unpaid amounts.` : 
          `-	L'accettazione da parte di LingoYou Group Srl del pagamento parziale o di qualsiasi pagamento inferiore all'importo totale dovuto in qualsiasi momento non costituisce una liberatoria o una rinuncia ai diritti di LingoYou Group Srl agli importi non corrisposti.`}
           
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Confidentiality` : `Riservatezza`}: 
          </b></p>
          <p style={{lineHeight: `${language === "English"? "normal": "23px"}`}}>
          {language === "English" ? `- Both parties agree that any information or documents provided by either Party to the other (including the Terms & Conditions of this agreement and details of the applicable fee structure) will remain strictly confidential, except to the extent that a Party is required by law or a court order to disclose them.` : 
          `-	Entrambe le Parti concordano che qualsiasi informazione o documento fornito da una Parte all'altra (inclusi Termini & Condizioni del presente accordo e i dettagli della struttura tariffaria applicabile) rimarranno strettamente riservati, salvo nella misura in cui una Parte è obbligata dalla legge o da un'ordinanza del tribunale a rivelarli.`}

          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Non-solicitation` : `Non sollecitazione`}: 
          </b></p>
          <p style={{lineHeight: `${language === "English"? "normal": "24px"}`}}>
          {language === "English" ? `- At any time when LingoYou Group Srl is providing the Service to the Client under this document and for two (2) years thereafter, the Client will not solicit or induce any employee, translator, interpreter or consultant of LingoYou Group Srl engaged in the provision of services to the client to resign or to end the engagement with LingoYou Group Srl.` : 
          `-	In qualsiasi momento in cui LingoYou Group Srl stia fornendo il servizio al Committente ai sensi del presente documento e per due (2) anni a seguire, il Committente non solleciterà né indurrà alcun dipendente, traduttore, interprete o consulente di LingoYou Group Srl impegnato nella fornitura dei servizi al Committente a licenziarsi o a terminare l'impegno con LingoYou Group Srl . `}
          </p>
          <p>
          {language === "English" ? `- The Client acknowledges to LingoYou Group Srl the right of execution in a specific form or of injunctive or other equitable relief in order to enforce this provision.` : 
          `-	Il Committente riconosce a LingoYou Group Srl il diritto di esecuzione in forma specifica o di un provvedimento ingiuntivo o altro provvedimento equo al fine di applicare la presente disposizione. `}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Warranties` : `Garanzie`}: 
          </b></p>
          <p>
          {language === "English" ? `- LingoYou Group Srl guarantees to the Client that all services provided will comply in all relevant respects with the specifications set out in the applicable Quote. In the event of failure to promptly contest the claim, nothing can be claimed by way of compensation. The Supplier shall also not be liable for defects attributable to the user or to a third party unrelated to the provision of the services.` : 
          `-	LingoYou Group Srl garantisce al Committente che tutti i servizi effettuati saranno conformi in tutti gli aspetti rilevanti alle specifiche stabilite nel Preventivo applicabile. In caso di mancata tempestiva contestazione nulla potrà essere richiesto a titolo risarcitorio. Il Fornitore non potrà altresì essere ritenuto responsabile per difetti imputabili all’utente o ad un terzo estraneo alla fornitura dei servizi.`}

          </p>
          <p>
          {language === "English" ? `- Except as provided in this section, LingoYou Group Srl makes no warranties or representations, whether express, implied by law or otherwise, with respect to the services or any final products provided hereunder and specifically denies any implied warranties of merchantability, title, non-infringement and fitness for a particular purpose.` : 
          `-	Salvo quanto stabilito nella presente sezione, Lingoyou Group Srl non fornisce alcuna garanzia o dichiarazione, sia essa esplicita, implicitamente prevista dalla legge o altrimenti, in relazione ai servizi o a eventuali prodotti finali forniti ai sensi del presente documento e, nello specifico, esclude qualsiasi garanzia implicita di commerciabilità, titolo, non-violazione e idoneità a un particolare scopo.`}

          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Remedies` : `Rimedi`}: 
          </b></p>
          <p>
          {language === "English" ? `- The Supplier may remedy the identified defect upon prompt written objection (within five (5) working days from the provision of the Service) of any lack of conformity during the performance of the Service provided by LingoYou Group Srl.` : 
          `-	Il Fornitore può porre rimedio al difetto rilevato previa tempestiva contestazione scritta (entro cinque (5) giorni lavorativi dalla fornitura del servizio) di eventuali difetti di conformità durante l’esecuzione del servizio fornito da LingoYou Group Srl.`}
          </p>
        </Grid>
        <Grid item xs={4}>
       

        <p className={styles.titles}><b>
          {language === "English" ? `Limitation of liability` : `Limitazione di responsabilità `}: 
          </b></p>
          <p>
          {language === "English" ? `- The liability of each party (whether defined by contract, whether arising out of wrongful act, negligence, direct liability, regulatory provision or otherwise) to the other Party and any third party in respect of or concerning this Agreement shall be cumulatively limited to direct and actual damages equal to or less than the amount payable to LingoYou Group Srl under this Agreement for the portion of the services it has given the source of that complaint.` : 
          `-	La responsabilità di ciascuna Parte (sia definita da un contratto, che derivante da atto illecito, negligenza, diretta responsabilità, disposizione normativa o altro) nei confronti dell'altra Parte e di eventuali terze parti riguardante o relativa al presente Accordo sarà limitata cumulativamente ai danni diretti ed effettivi pari a un ammontare non superiore a quello da corrispondere a LingoYou Group Srl ai sensi del presente Accordo per la porzione dei servizi che ha dato origine a tale reclamo.`}
          </p>
          <p>
          {language === "English" ? `- Without prejudice to gross negligence or misconduct, in no event shall either Party be liable for the following types of damage and each Party hereby waives its right in respect of the following types of damage: consequential; incidental; punitive; special; exemplary; indirect; loss of profits; loss of refunds; loss of data; or loss of savings. Each Party waives its right to such damage, even if that party has been informed of the possibility of such damage occurring.` : 
          `-	Escludendo colpa grave o condotta scorretta, in nessun caso alcuna delle Parti sarà responsabile per i seguenti tipi di danno e ciascuna Parte rinuncia con la presente al proprio diritto in relazione ai seguenti tipi di danno: conseguente; accidentale; punitivo; speciale; esemplare; indiretto; perdita di profitti; perdita di rimborsi; perdita di dati; o perdita di risparmi. Ciascuna Parte rinuncia al diritto relativo ai suddetti danni, anche se tale Parte è stata informata della possibilità di avvenimento di tali danni.`}
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Applicable Law and Competent Court` : `Legge applicabile e Foro Competente`}: 
          </b></p>
          <p>
          {language === "English" ? `- Except as otherwise provided in the Statement of work, this Agreement shall be governed by Italian law without application of its principles of conflict of laws.` : 
          `-	Salvo quanto diversamente previsto nella Dichiarazione di lavoro, il presente Accordo sarà regolato dalla Legge Italiana senza applicazione dei suoi principi di conflitto di leggi.`} 
          </p>
          <p>
          {language === "English" ? `- Any action in connection with this Agreement shall be brought before the State or Federal Court of Milan and the Parties consent exclusively to the seat and personal authority provided for therein.` : 
          `-	Qualsiasi causa connessa al presente Accordo dovrà essere intentata presso il Tribunale Statale o Federale di Milano e le Parti acconsentono esclusivamente alla sede e alla giurisdizione personale ivi previste.`} 
         
          </p>
          <p className={styles.titles}><b>
          {language === "English" ? `Agreement` : `Accordo`}: 
          </b></p>
          <p>
          {language === "English" ? `- Unless expressly agreed otherwise by both Parties in writing, as in a Framework Agreement (FA), if any, this Quote together with these Terms & Conditions constitutes the Parties’ entire Agreement and understanding with respect to the subject matter hereof and supersedes all prior agreements (oral or written) and intended in this regard.` : 
          `-	Salvo quanto diversamente concordato espressamente da entrambe le Parti per iscritto, come in un Accordo Quadro (AQ), se presente, questo Preventivo insieme a questi Termini & Condizioni, costituisce l'intero Accordo e comprensione delle Parti in relazione all'oggetto del presente documento e sostituisce tutti i precedenti accordi (orali o scritti) e intese al riguardo. `}
          </p>
          <p>
          {language === "English" ? `- No representations, promises, inducements or intentions have been made by any Party not incorporated herein, and no Party shall be bound by or liable for any alleged representations, promises or inducements not so established herein, including, but not limited to, the Terms & Conditions of the Customer’s pre-printed documents (e.g. purchase orders, order confirmations, etc.).` : 
          `-	Nessuna dichiarazione, promessa, incentivo, dichiarazione o intenzione è stata fatta da alcuna Parte che non sia incorporata nel presente documento, e nessuna Parte sarà vincolata o responsabile per qualsiasi presunta dichiarazione, promessa, incentivo o dichiarazione non così stabilita nel presente documento, inclusi - senza limitazioni - i Termini & Condizioni dei documenti prestampati del Cliente (ad esempio, ordini di acquisto, conferme d'ordine e simili). `}
          </p>
          <p>
          {language === "English" ? `- This Agreement may be modified, amended, superseded or canceled only by a written instrument signed by each Party, and all terms, understandings, representations, warranties or conditions herein may be waived only by a written instrument executed by the Party to be bound by such waiver.` : 
          `-	Il presente Contratto può essere modificato, emendato, sostituito o annullato solo da uno strumento scritto firmato da ciascuna delle Parti e tutti i termini, patti, dichiarazioni, garanzie o condizioni del presente documento possono essere derogati solo da uno strumento scritto eseguito dalla Parte per essere vincolato da tale rinuncia.`}
          </p>
          <div style={{marginTop:"30px"}} className={styles.sign}>
          <div>
                <p>
                  <b>
                  {
                language === 'English' ? `Signature for acceptance of quote LY/${data?.orderNumber}:` : 
                `Firma per accettazione Preventivo LY/${data?.orderNumber}:`
              }               
              </b>
                </p>
               {(data?.status !== 'Quote-Won' && data?.status !== "Sent To PM") && isBtn ? (
                  <span onClick={handleClickOpen}>
                    <Button sx={{ width: "180px", fontSize: "30px", color:"white", backgroundColor:"#2da0f0" }} onClick={handleClickOpen}>
                   {language === 'English' ? "SIGN" : "FIRMA"}
                    </Button>
                  </span>
                ) : <span style={{ fontFamily: "Allura", fontSize: "30px" }}>{name || data?.quoteSignature}</span>
                }
               </div>
          </div>
          <div style={{marginTop:"10px"}} className={styles.sign}>
          <div>
                <p>
                  <b>
                  {
                language === 'English' ? 'Signature for acceptance of the Terms & Conditions:' : 'Firma per accettazione Termini & Condizioni:'
              }                  
              </b>
                </p>
               {(data?.status !== 'Quote-Won' && data?.status !== "Sent To PM") && isBtn ? (
                  <span onClick={handleClickOpen}>
                    <Button sx={{ width: "180px", fontSize: "30px", color:"white", backgroundColor:"#2da0f0" }} onClick={handleClickOpen}>
                    {language === 'English' ? "SIGN" : "FIRMA"}
                    </Button>
                  </span>
                ) : <span style={{ fontFamily: "Allura", fontSize: "30px" }}>{name || data?.quoteSignature}</span>
                }
               </div>
          </div>
          <div style={{marginTop:"10px"}} className={styles.sign}>
          <div>
                <p>
                
              {
                language === 'English' ?
                  <b>
                   Signature for acceptance Terms and conditions of the site {' '}
               <a href='https://www.lingoyou.com/en/terms-conditions/'>(https://www.lingoyou.com/en/terms-conditions/)</a>                 
              </b>
              : <b>
                  Firma per accettazione Termini & condizioni del sito {' '}
               <a href='http://www.lingoyou.com/terminicondizioni'>(http://www.lingoyou.com/terminicondizioni)</a>                 
              </b>
              }
                </p>
               {(data?.status !== 'Quote-Won' && data?.status !== "Sent To PM") && isBtn ? (
                  <span onClick={handleClickOpen}>
                    <Button sx={{ width: "180px", fontSize: "30px", color:"white", backgroundColor:"#2da0f0" }} onClick={handleClickOpen}>
                    {language === 'English' ? "SIGN" : "FIRMA"}
                    </Button>
                  </span>
                ) : <span style={{ fontFamily: "Allura", fontSize: "30px" }}>{name || data?.quoteSignature}</span>
                }
               </div>
          </div>
        </Grid>
       
        <Grid item xs={4}>
          
          </Grid>
          <Grid item xs={4}>
          
          </Grid>
        <Grid item xs={4} >
             
        </Grid>
      </Grid>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          sx={{
            '& .css-y4brsv-MuiPaper-root-MuiDialog-paper.MuiPaper-rounded': { borderRadius: '5px' },
            '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '500px', textAlign: 'center', borderRadius: '5px' },
            borderRadius: '5px',
          }}
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
              color: 'rgba(0,0,0,.65)',
              fontWeight: '600',
              fontSize: '25px',
            }}
          >

            {
              language === 'English' ? 'Accept the quote' : 'Accetta il preventivo'
            }
          </DialogTitle>
          <DialogContent
            id="dialog_content"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
            <span style={{ display: "inline-flex", marginTop: "30px" }}>
              <BsVectorPen style={{ fontSize: "25px", marginTop: "5px" }} />  <input placeholder={`${language === "English" ? "Sign here":"Firma qui"}`} value={name} onChange={(e) => dispatch(addName(e.target.value))} />{' '}
              <FaRegHandPointLeft style={{ fontSize: "25px", marginTop: "5px" }} />

            </span>
            {(error && !name && isBtn && data?.status !== 'Quote-Won') && <span className={styles.error}>{error} <br /></span>}
            <DialogContentText id="alert-dialog-description">{name}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button

              sx={{
                background: '#7cd1f9',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
                fontWeight: '600',
                fontSize: '14px',
                padding: '10px 10px',
                cursor: 'pointer',
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
                  color: 'white',
                  background: 'grey',
                },
              }}
              disabled={!name && true}
              onClick={handleClickClose}
              autoFocus
            >
              Conferma preventivo
              {
                language === 'English' ? 'Quote confirmation                ' : 'Accetta il preventivo'
              }
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openTerms}
          onClose={handleCloseTerms}
          aria-labelledby="alert-dialog-title"
          sx={{ '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '500px', textAlign: 'center' } }}
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
            {
              language === 'English' ? 'Accept the Terms & Conditions' : 'Accetta i Termini e Condizioni'
            }
          </DialogTitle>
          <DialogContent
            id="dialog_content"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
            <span style={{ display: "inline-flex", marginTop: "30px" }}>
              <BsVectorPen style={{ fontSize: "25px", marginTop: "5px" }} />  <input placeholder={`${language === "English" ? "Sign here":"Firma qui"}`} value={name} onChange={(e) => dispatch(addName(e.target.value))} />{' '}
              <FaRegHandPointLeft style={{ fontSize: "25px", marginTop: "5px" }} />
            </span>
            <DialogContentText id="alert-dialog-description">{name}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: '#7cd1f9',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
                fontWeight: '600',
                fontSize: '14px',
                padding: '10px 10px',
                cursor: 'pointer',
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
                  color: 'white',
                  background: 'grey',
                },
              }}
              disabled={!name && true}
              onClick={handleClickCloseTerms}
              autoFocus
            >


              {
                language === 'English' ? 'Confirm Quote                ' : 'Conferma preventivo'
              }            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}

import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';
import { SectorsContent } from '../../sectorsText';


const Home = ({ data, language }) => {
  const {companyNameAndId, bookedBy, brand, accountManager,subject, addOns, orderNumber, industry} = data
  console.log(data) 
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
    
     <View style={styles.greenImage}>
     
     {brand && brand?.rafiky ? <Image 
     style={{...styles.greenImages,objectFit:"contain", width:"20%", marginLeft:"10px", marginTop:"10px" }}  
     src="/rafiky/logo-w.png"/> 
    :  <Image 
    style={{...styles.greenImages,objectFit:"contain", width:"20%", marginLeft:"10px", marginTop:"10px" }}  
    src="/assets/desktop16/logocongress.png"/>
}
     <View style={{marginLeft:"11px", marginTop:"80px"}}>
          <Text style={{ marginTop:"80px", fontSize:"15px",fontStyle:"normal", fontWeight:"bold", color:"white", width:"50%", textTransform:"uppercase"}}>
          {language === "English" ? `PROPOSAL DEDICATED TO`:`PROPOSAL DEDICATO A`}            
          </Text>
          <Text style={{ fontSize:"15px",fontStyle:"normal", fontWeight:"bold", color:"white", width:"50%", textTransform:"uppercase"}}>
          {companyNameAndId ? companyNameAndId.name : '___'}
            
          </Text>
     </View>
   {brand && brand?.rafiky ? <Image 
      style={{width:"100%", objectFit:"none", marginTop:"-450px", zIndex:"-99", height:"780px"}}  
      src={addOns?.sector ? `${SectorsContent[industry?.name]?.coverPdf}` : 
      '/rafiky/cover.png'}/> :  <Image 
      style={{width:"100%", objectFit:"none", marginTop:"-450px", zIndex:"-99", height:"780px"}}  
      src={addOns?.sector ? `${SectorsContent[industry?.name]?.coverPdf}` : 
      '/clients/EventOrganiser/covereventorganiser1.png'}/>}       
      <View style={styles.greenTextSection}>
       </View>
       <View style={{...styles.discountText}}>
       <Text style={{...styles.discountList, marginBottom:"5px", marginLeft:"11px", fontSize:"14px",fontStyle:"normal", fontWeight:"bold",}}>
          {language === "English" ? `Quote: CI/${orderNumber}`:`Preventivo: CI/${orderNumber}`}
        </Text>
        <Text style={{...styles.discountList, marginBottom:"5px", marginLeft:"11px", fontSize:"14px",fontStyle:"normal", fontWeight:"bold",}}>

          {language === "English" ? `Project: ${subject}`:`Progetto: ${subject}`}

        </Text>
        <Text style={{...styles.discountList, marginBottom:"5px", marginLeft:"11px"}}>
          {language === "English" ? `Kindly Addressed to: ${bookedBy ? `${bookedBy?.firstName} ${bookedBy?.lastName}` : '___'}.`:`All'attenzione di ${bookedBy ? `${bookedBy?.firstName} ${bookedBy?.lastName}` : '___'}`}
        </Text>
        <Text style={{...styles.discountList, marginBottom:"25px", fontSize:'8px', marginLeft:"11px"}}>
          {language === "English" ? `Document Created by ${accountManager ? accountManager.name : "__"}.`:`Documento Realizzato da ${accountManager ? accountManager.name : "__"}.`}
        </Text>
       </View>
       </View>
    </Page>
  );
};

export default Home;




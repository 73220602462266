export const languages = [
  { code: '1', name: 'Abkhaz', nativename: 'аҧсуа' },
  { code: '2', name: 'Afar', nativename: 'Afaraf' },
  { code: '3', name: 'Afrikaans', nativename: 'Afrikaans' },
  { code: '4', name: 'Akan', nativename: 'Akan' },
  { code: '5', name: 'Albanian', nativename: 'Shqip' },
  { code: '6', name: 'Amharic', nativename: 'አማርኛ' },
  { code: '7', name: 'Arabic', nativename: 'العربية' },
  { code: '8', name: 'Aragonese', nativename: 'Aragonés' },
  { code: '9', name: 'Armenian', nativename: 'Հայերեն' },
  { code: '10', name: 'Assamese', nativename: 'অসমীয়া' },
  { code: '11', name: 'Avaric', nativename: 'авар мацӀ, магӀарул мацӀ' },
  { code: '12', name: 'Avestan', nativename: 'avesta' },
  { code: '13', name: 'Aymara', nativename: 'aymar aru' },
  { code: '14', name: 'Azerbaijani', nativename: 'azərbaycan dili' },
  { code: '15', name: 'Bambara', nativename: 'bamanankan' },
  { code: '16', name: 'Bashkir', nativename: 'башҡорт теле' },
  { code: '17', name: 'Basque', nativename: 'euskara, euskera' },
  { code: '18', name: 'Belarusian', nativename: 'Беларуская' },
  { code: '19', name: 'Bengali', nativename: 'বাংলা' },
  { code: '20', name: 'Bihari', nativename: 'भोजपुरी' },
  { code: '21', name: 'Bislama', nativename: 'Bislama' },
  { code: '22', name: 'Bosnian', nativename: 'bosanski jezik' },
  { code: '23', name: 'Breton', nativename: 'brezhoneg' },
  { code: '24', name: 'Bulgarian', nativename: 'български език' },
  { code: '25', name: 'Burmese', nativename: 'ဗမာစာ' },
  { code: '26', name: 'Catalan; Valencian', nativename: 'Català' },
  { code: '27', name: 'Chamorro', nativename: 'Chamoru' },
  { code: '28', name: 'Chechen', nativename: 'нохчийн мотт' },
  { code: '29', name: 'Chichewa; Chewa; Nyanja', nativename: 'chiCheŵa, chinyanja' },
  { code: '30', name: 'Chinese', nativename: '中文 (Zhōngwén), 汉语, 漢語' },
  { code: '31', name: 'Chuvash', nativename: 'чӑваш чӗлхи' },
  { code: '32', name: 'Cornish', nativename: 'Kernewek' },
  { code: '33', name: 'Corsican', nativename: 'corsu, lingua corsa' },
  { code: '34', name: 'Cree', nativename: 'ᓀᐦᐃᔭᐍᐏᐣ' },
  { code: '35', name: 'Croatian', nativename: 'hrvatski' },
  { code: '36', name: 'Czech', nativename: 'česky, čeština' },
  { code: '37', name: 'Danish', nativename: 'dansk' },
  { code: '38', name: 'Divehi; Dhivehi; Maldivian;', nativename: 'ދިވެހި' },
  { code: '39', name: 'Dutch', nativename: 'Nederlands, Vlaams' },
  { code: '40', name: 'English', nativename: 'English' },
  { code: '41', name: 'Esperanto', nativename: 'Esperanto' },
  { code: '42', name: 'Estonian', nativename: 'eesti, eesti keel' },
  { code: '43', name: 'Ewe', nativename: 'Eʋegbe' },
  { code: '44', name: 'Faroese', nativename: 'føroyskt' },
  { code: '45', name: 'Fijian', nativename: 'vosa Vakaviti' },
  { code: '45.5', name: 'Filipino', nativename: 'filipino' },
  { code: '46', name: 'Finnish', nativename: 'suomi, suomen kieli' },
  { code: '186', name: 'Flemish', nativename: 'Flemish' },
  { code: '47', name: 'French', nativename: 'français, langue française' },
  { code: '48', name: 'Fula', nativename: 'Fulfulde' },
  { code: '49', name: 'Galician', nativename: 'Galego' },
  { code: '50', name: 'Georgian', nativename: 'ქართული' },
  { code: '51', name: 'German', nativename: 'Deutsch' },
  { code: '52', name: 'Greek, Modern', nativename: 'Ελληνικά' },
  { code: '53', name: 'Guaraní', nativename: 'Avañeẽ' },
  { code: '54', name: 'Gujarati', nativename: 'ગુજરાતી' },
  { code: '55', name: 'Haitian; Haitian Creole', nativename: 'Kreyòl ayisyen' },
  { code: '56', name: 'Hausa', nativename: 'Hausa, هَوُسَ' },
  { code: '57', name: 'Hebrew (modern)', nativename: 'עברית' },
  { code: '58', name: 'Herero', nativename: 'Otjiherero' },
  { code: '59', name: 'Hindi', nativename: 'हिन्दी, हिंदी' },
  { code: '60', name: 'Hiri Motu', nativename: 'Hiri Motu' },
  { code: '61', name: 'Hungarian', nativename: 'Magyar' },
  { code: '62', name: 'Interlingua', nativename: 'Interlingua' },
  { code: '63', name: 'Indonesian', nativename: 'Bahasa Indonesia' },
  { code: '64', name: 'Interlingue', nativename: 'Originally called Occidental; then Interlingue after WWII' },
  { code: '65', name: 'Irish', nativename: 'Gaeilge' },
  { code: '66', name: 'Igbo', nativename: 'Asụsụ Igbo' },
  { code: '67', name: 'Inupiaq', nativename: 'Iñupiaq, Iñupiatun' },
  { code: '68', name: 'Ido', nativename: 'Ido' },
  { code: '69', name: 'Icelandic', nativename: 'Íslenska' },
  { code: '70', name: 'Italian', nativename: 'Italiano' },
  { code: '71', name: 'Inuktitut', nativename: 'ᐃᓄᒃᑎᑐᑦ' },
  { code: '72', name: 'Japanese', nativename: '日本語 (にほんご／にっぽんご)' },
  { code: '73', name: 'Javanese', nativename: 'basa Jawa' },
  { code: '74', name: 'Kalaallisut, Greenlandic', nativename: 'kalaallisut, kalaallit oqaasii' },
  { code: '75', name: 'Kannada', nativename: 'ಕನ್ನಡ' },
  { code: '76', name: 'Kanuri', nativename: 'Kanuri' },
  { code: '77', name: 'Kashmiri', nativename: 'कश्मीरी, كشميري‎' },
  { code: '78', name: 'Kazakh', nativename: 'Қазақ тілі' },
  { code: '79', name: 'Khmer', nativename: 'ភាសាខ្មែរ' },
  { code: '80', name: 'Kikuyu, Gikuyu', nativename: 'Gĩkũyũ' },
  { code: '81', name: 'Kinyarwanda', nativename: 'Ikinyarwanda' },
  { code: '82', name: 'Kirghiz, Kyrgyz', nativename: 'кыргыз тили' },
  { code: '83', name: 'Komi', nativename: 'коми кыв' },
  { code: '84', name: 'Kongo', nativename: 'KiKongo' },
  { code: '85', name: 'Korean', nativename: '한국어 (韓國語), 조선말 (朝鮮語)' },
  { code: '86', name: 'Kurdish', nativename: 'Kurdî, كوردی‎' },
  { code: '87', name: 'Kwanyama, Kuanyama', nativename: 'Kuanyama' },
  { code: '88', name: 'Latin', nativename: 'latine, lingua latina' },
  { code: '183', name: 'LIS', nativename: 'Sign Language' },
  { code: '89', name: 'Luxembourgish, Letzeburgesch', nativename: 'Lëtzebuergesch' },
  { code: '90', name: 'Luganda', nativename: 'Luganda' },
  { code: '91', name: 'Limburgish, Limburgan, Limburger', nativename: 'Limburgs' },
  { code: '92', name: 'Lingala', nativename: 'Lingála' },
  { code: '93', name: 'Lao', nativename: 'ພາສາລາວ' },
  { code: '94', name: 'Lithuanian', nativename: 'lietuvių kalba' },
  { code: '95', name: 'Luba-Katanga', nativename: '' },
  { code: '96', name: 'Latvian', nativename: 'latviešu valoda' },
  { code: '97', name: 'Manx', nativename: 'Gaelg, Gailck' },
  { code: '98', name: 'Macedonian', nativename: 'македонски јазик' },
  { code: '99', name: 'Malagasy', nativename: 'Malagasy fiteny' },
  { code: '100', name: 'Malay', nativename: 'bahasa Melayu, بهاس ملايو‎' },
  { code: '101', name: 'Malayalam', nativename: 'മലയാളം' },
  { code: '102', name: 'Maltese', nativename: 'Malti' },
  { code: '103', name: 'Māori', nativename: 'te reo Māori' },
  { code: '104', name: 'Marathi (Marāṭhī)', nativename: 'मराठी' },
  { code: '105', name: 'Marshallese', nativename: 'Kajin M̧ajeļ' },
  { code: '106', name: 'Mongolian', nativename: 'монгол' },
  { code: '184', name: 'Montenegrin', nativename: 'Crnogorski'},
  { code: '107', name: 'Nauru', nativename: 'Ekakairũ Naoero' },
  { code: '108', name: 'Navajo, Navaho', nativename: 'Diné bizaad, Dinékʼehǰí' },
  { code: '109', name: 'Norwegian Bokmål', nativename: 'Norsk bokmål' },
  { code: '110', name: 'North Ndebele', nativename: 'isiNdebele' },
  { code: '111', name: 'Nepali', nativename: 'नेपाली' },
  { code: '112', name: 'Ndonga', nativename: 'Owambo' },
  { code: '113', name: 'Norwegian Nynorsk', nativename: 'Norsk nynorsk' },
  { code: '114', name: 'Norwegian', nativename: 'Norsk' },
  { code: '115', name: 'Nuosu', nativename: 'ꆈꌠ꒿ Nuosuhxop' },
  { code: '116', name: 'South Ndebele', nativename: 'isiNdebele' },
  { code: '117', name: 'Occitan', nativename: 'Occitan' },
  { code: '118', name: 'Ojibwe, Ojibwa', nativename: 'ᐊᓂᔑᓈᐯᒧᐎᓐ' },
  { code: '120', name: 'Oromo', nativename: 'Afaan Oromoo' },
  { code: '121', name: 'Oriya', nativename: 'ଓଡ଼ିଆ' },
  { code: '122', name: 'Ossetian, Ossetic', nativename: 'ирон æвзаг' },
  { code: '123', name: 'Panjabi, Punjabi', nativename: 'ਪੰਜਾਬੀ, پنجابی‎' },
  { code: '124', name: 'Pāli', nativename: 'पाऴि' },
  { code: '125', name: 'Persian', nativename: 'فارسی' },
  { code: '126', name: 'Polish', nativename: 'polski' },
  { code: '127', name: 'Pashto, Pushto', nativename: 'پښتو' },
  { code: '128', name: 'Portuguese', nativename: 'Português' },
  { code: '129', name: 'Quechua', nativename: 'Runa Simi, Kichwa' },
  { code: '130', name: 'Romansh', nativename: 'rumantsch grischun' },
  { code: '131', name: 'Kirundi', nativename: 'kiRundi' },
  { code: '132', name: 'Romanian', nativename: 'română' },
  { code: '133', name: 'Russian', nativename: 'русский язык' },
  { code: '134', name: 'Sanskrit (Saṁskṛta)', nativename: 'संस्कृतम्' },
  { code: '135', name: 'Sardinian', nativename: 'sardu' },
  { code: '136', name: 'Sindhi', nativename: 'सिन्धी, سنڌي، سندھی‎' },
  { code: '137', name: 'Northern Sami', nativename: 'Davvisámegiella' },
  { code: '138', name: 'Samoan', nativename: 'gagana faa Samoa' },
  { code: '139', name: 'Sango', nativename: 'yângâ tî sängö' },
  { code: '140', name: 'Serbian', nativename: 'српски језик' },
  { code: '141', name: 'Scottish Gaelic; Gaelic', nativename: 'Gàidhlig' },
  { code: '142', name: 'Shona', nativename: 'chiShona' },
  { code: '143', name: 'Sinhala, Sinhalese', nativename: 'සිංහල' },
  { code: '144', name: 'Slovak', nativename: 'slovenčina' },
  { code: '145', name: 'Slovene', nativename: 'slovenščina' },
  { code: '146', name: 'Somali', nativename: 'Soomaaliga, af Soomaali' },
  { code: '147', name: 'Southern Sotho', nativename: 'Sesotho' },
  { code: '148', name: 'Spanish', nativename: 'español, castellano' },
  { code: '149', name: 'Sundanese', nativename: 'Basa Sunda' },
  { code: '150', name: 'Swahili', nativename: 'Kiswahili' },
  { code: '151', name: 'Swati', nativename: 'SiSwati' },
  { code: '152', name: 'Swedish', nativename: 'svenska' },
  { code: '153', name: 'Tamil', nativename: 'தமிழ்' },
  { code: '154', name: 'Telugu', nativename: 'తెలుగు' },
  { code: '155', name: 'Tajik', nativename: 'тоҷикӣ, toğikī, تاجیکی‎' },
  { code: '156', name: 'Thai', nativename: 'ไทย' },
  { code: '157', name: 'Tigrinya', nativename: 'ትግርኛ' },
  { code: '158', name: 'Tibetan Standard, Tibetan, Central', nativename: 'བོད་ཡིག' },
  { code: '159', name: 'Turkmen', nativename: 'Türkmen, Түркмен' },
  { code: '160', name: 'Tagalog', nativename: 'Wikang Tagalog, ᜏᜒᜃᜅ᜔ ᜆᜄᜎᜓᜄ᜔' },
  { code: '161', name: 'Tswana', nativename: 'Setswana' },
  { code: '162', name: 'Tonga (Tonga Islands)', nativename: 'faka Tonga' },
  { code: '163', name: 'Turkish', nativename: 'Türkçe' },
  { code: '164', name: 'Tsonga', nativename: 'Xitsonga' },
  { code: '165', name: 'Tatar', nativename: 'татарча, tatarça, تاتارچا‎' },
  { code: '166', name: 'Twi', nativename: 'Twi' },
  { code: '167', name: 'Tahitian', nativename: 'Reo Tahiti' },
  { code: '168', name: 'Uighur, Uyghur', nativename: 'Uyƣurqə, ئۇيغۇرچە‎' },
  { code: '169', name: 'Ukrainian', nativename: 'українська' },
  { code: '170', name: 'Urdu', nativename: 'اردو' },
  { code: '171', name: 'Uzbek', nativename: 'zbek, Ўзбек, أۇزبېك‎' },
  { code: '172', name: 'Venda', nativename: 'Tshivenḓa' },
  { code: '173', name: 'Vietnamese', nativename: 'Tiếng Việt' },
  { code: '174', name: 'Volapük', nativename: 'Volapük' },
  { code: '175', name: 'Walloon', nativename: 'Walon' },
  { code: '176', name: 'Welsh', nativename: 'Cymraeg' },
  { code: '177', name: 'Wolof', nativename: 'Wollof' },
  { code: '178', name: 'Western Frisian', nativename: 'Frysk' },
  { code: '179', name: 'Xhosa', nativename: 'isiXhosa' },
  { code: '180', name: 'Yiddish', nativename: 'ייִדיש' },
  { code: '181', name: 'Yoruba', nativename: 'Yorùbá' },
  { code: '182', name: 'Zhuang, Chuang', nativename: 'Saɯ cueŋƅ, Saw cuengh' },
  { code: '185', name: 'Moldovan', nativename: 'Moldovan' },
];

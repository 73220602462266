import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './styles.js';

const ContactUs = ({ language }) => {
 
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
    
    <View style={styles.greenImage}>
     <Image style={{...styles.greenImages, width:"30%", marginLeft:"20px", marginBottom:"-160px", marginTop:"30" }}  
     src="/rafiky/logo-w.png"/>
     <View style={{ paddingTop:"300px", display:"inline-flex", flexDirection:"row", }}>
          <View style={{display:"inline-flex", flexDirection:"column", marginLeft:"30px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              {language === "English" ?"Address" : "Indirizzo"}
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"column", marginLeft:"75px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/rafiky/email.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              {language === "English" ? "Contacts" : "Contatti"}
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"column", marginLeft:"75px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/rafiky/support.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              {language === "English" ? "Availability" : "Disponibilità"}
              </Text>
              </View>
          </View>
          <View style={{marginTop:"5px", display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"8px", color:"white", width:"100px", marginLeft:"33px"}}>
          Viale Fulvio Testi, 59 Cinisello
            </Text>
            <Text style={{fontSize:"8px", color:"white",width:"100px", marginLeft:"15px"}}>
            info@rafiky.net
            </Text>     
            <Text style={{fontSize:"9px", color:"white", width:"120px", marginLeft:"10px"}}>
           {language === "English" ?  "Mon-Fri: 8:00-18:00" : "Lun-Ven: 8:00-18:00"}
            </Text>
          </View>
          <View style={{display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"8px", color:"white", width:"100px", marginLeft:"33px"}}>
          Balsamo - 20092 {language === "English" ? "Italy" : "Italia"}
            </Text>
            <Text style={{fontSize:"8px", color:"white",width:"100px", marginLeft:"15px"}}>
            +39 0282950332
            </Text>     
            <Text style={{fontSize:"9px", color:"white", width:"100px", marginLeft:"10px"}}>
            {language === "English" ?  "Sat-Sun: 8:00-13:00" :"Sab-Dom: 8:00-13:00"}
            </Text>
          </View>
         
      <Image style={{width:"100%", objectFit:"cover", marginTop:"-405px", zIndex:"-99", height:"790px"}} 
       src="/rafiky/background.png"/>      
       <View style={styles.greenTextSection}>
       <Image style={{...styles.homeImage, width:"15%",objectFit:'contain', height:"200px", alignItems:"right", marginRight:"0px", marginLeft:"520px"}}  src="/assets/desktop5/group-1000001731.png"/>
       </View>
       <View style={{...styles.discountText, marginTop:"-220px", marginBottom:"30px"}}>
        <Text style={{...styles.discountList, marginBottom:"1px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px", height:"15px", marginLeft:"5px"}}  src="/assets/icons/Phone.png"/>      
 +39 3755806530, +39 0282957099
        </Text>
        <Text style={{...styles.discountList, marginBottom:"1px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px", height:"15px"}}  src="/assets/icons/facebook.png"/>      
         <Link style={{color:"white",paddingBottom:"40px", marginTop:"-20px", marginLeft:"5px"}} 
         href="https://www.facebook.com/Rafiky.net/">https://www.facebook.com/Rafiky.net/</Link>
        </Text>
        <Text style={{...styles.discountList, marginBottom:"10px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px", height:"15px"}}  src="/assets/icons/instagram.png"/>      
        <Link style={{color:"white",paddingBottom:"40px", marginTop:"-20px", marginLeft:"5px"}} 
        href="https://www.instagram.com/rafikynet/">
        https://www.instagram.com/rafikynet/
        </Link>
        </Text>
       </View>
       </View> 
       
    </Page>
  );
};

export default ContactUs;
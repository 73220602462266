import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './globalStyles';
 
const Discount = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
    
     <View style={styles.discountImage}>
      <Image style={{width:"100%", objectFit:"cover", zIndex:"-99", height:"600px"}}  
      src="/rafiky/background.png"/>
       <View style={styles.discountTextSection}>
       <Image style={{...styles.discountImages, marginLeft:"20px" }}  src="/assets/desktop13/discount.png"/>
       <Link href="https://www.youtube.com/embed/ZvecBiunr5I" style={{ marginTop:"-500px", marginLeft:"70px"}}>
       <Image style={{height:"auto",
objectFit:"contain", width:"300px", alignItems:"right", marginRight:"0px"}}  src="/assets/desktop13/laptop.png"/>
        </Link>
       </View> 
       <View style={styles.discountText}>
        <Text style={{...styles.discountList, marginBottom:"15px"}}>
          {language === "English" ? `You can save up to 60% using the most advanced technology in the world of interpreting. The integration of our hardware with the most modern software and latest generation technologies can promote an important reduction in costs for the organization of your event. How?`:
          `Potrete risparmiare fino al 60% utilizzando la tecnologia più avanzata nel mondo dell'interpretariato. L'integrazione del nostro hardware con i software più moderni e con tecnologie di ultima generazione può favorire un'importante riduzione dei costi per l'organizzazione del Vostro evento. Come?`}
        </Text>
        <Text style={{...styles.discountList, marginLeft:"20px"}}>
          {language === "English" ? `Interpreters connected remotely via a virtual interpreting console (web-based). It won't be anymore necessary to provide for the time-consuming and expensive installation of cabins in your location.`:`Interpreti collegati da remoto tramite consolle di interpretariato virtuale (web-based). Non sarà più necessario provvedere alla lunga e dispendiosa installazione di cabine nella Vostra location.`}
        </Text>
        <Text style={{...styles.discountList, marginLeft:"20px"}}>
          {language === "English" ? `No more commuting: no more extra expenses for travel/accommodation will be needed interpreters.`:`Niente più spostamenti: non sarà più necessario sostenere spese extra per viaggi/alloggio degli interpreti.`}
        </Text>
        <Text style={{...styles.discountList, marginLeft:"20px"}}>
          {language === "English" ? `Possibility for all participants to listen to the interpretation via App and/or receiver present in room.`: `Possibilità per tutti i partecipanti di ascoltare l'interpretariato tramite App e/o ricevitore presente in sala.`}
        </Text>
       </View>
       </View>
    </Page>
  );
};

export default Discount;
import styles from "../styles/Home.module.css";
import { useSelector } from "react-redux";
import { SectorsContent } from "../../sectorsText";


const Home = () => {

  const {details, language} = useSelector(state => state.proposal)

  const {companyNameAndId, bookedBy, accountManager, subject, orderNumber, companyLogo, addOns, industry} = details

  return (
    <div className={styles.desktop5}>
      <div className={styles.mainVector}>
       <div className={styles.vector1}></div>
       <div className={styles.vector2}></div>
       <div className={styles.vector3}></div>
       <div className={styles.vector4}></div>
       <div className={styles.vector5}></div>
       <div className={styles.vector6}></div>

      </div>
      <div className={styles.desktop5Child} 
      style={{background: `url(${addOns?.sector ? SectorsContent[industry?.name].cover : 
        '/rafiky/cover.webp'}) no-repeat right/cover`, backgroundPositionX:'right', backgroundSize:"cover" }}
      >

      </div>
      {/* <img
        className={styles.desktop5Child}
        alt=""
        src={addOns?.sector ? SectorsContent[industry?.name].cover : '/clients/EventOrganiser/covereventorganiser.webp'}
      /> */}
      <div className={styles.text3543Parent}>
      <img
        alt=""
        src="/rafiky/logo-w.png"
      /> </div>
      
       {language === 'English' ? (
        <>
      <div className={styles.projectOfficialProEngParent}>
      <b className={styles.projectOfficial}>Quote: CI/{orderNumber}</b>
        {subject && <b className={styles.projectOfficial}>Project: {subject}</b>}
        <i className={styles.projectOfficial}>
        Kindly Addressed To {bookedBy ? `${bookedBy?.firstName} ${bookedBy?.lastName}` : '___'}.
        </i>
        <i className={styles.editedByManuel}>Document Created By {accountManager ? accountManager.name : "__"}.</i>
      </div>
      <div className={styles.proposalDedicatedTo}>
      PROPOSAL DEDICATED TO <br/>{companyNameAndId ? companyNameAndId.name : '___'}<br/>
      {companyLogo &&  <img src={companyLogo}/>}
      </div>
       </>
       )
       :
       <>
       <div className={styles.projectOfficialProEngParent}>
       <b className={styles.projectOfficial}>Preventivo: CI/{orderNumber}</b>
         {subject && <b className={styles.projectOfficial}>Progetto: {subject}</b>}
         <i className={styles.projectOfficial}>
           All'attenzione di {bookedBy ? `${bookedBy?.firstName} ${bookedBy?.lastName}` : '___'}.
         </i>
         <i className={styles.editedByManuel}>Documento Realizzato da {accountManager ? accountManager.name : "__"}.</i>
       </div>
       <div className={styles.proposalDedicatedTo}>
         Proposal dedicato a <br/>{companyNameAndId ? companyNameAndId.name : '___'}<br/>
         {companyLogo &&  <img src={companyLogo}/>}

       </div>
        </>
       }
    </div>
  );
};

export default Home;

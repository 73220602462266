import React from 'react';
import { styles } from '../globalStyles';
import { Image, Text, View } from '@react-pdf/renderer';
import { icons } from '../icons';

const TableHeader = ({ language = "Italian", option }) => {
  return (
   <>
   {option > 0 && <Text style={{marginBottom:'5px', marginTop:'5px', textAlign:'left', fontSize:'12px', fontWeight:'bold'}}>
      {language === "English" ? "OPTION" : "OPZIONE"} {option}
      </Text>}
      <View style={styles.tableItemsHeader}>
      <View style={styles.tableItemsItemLeft}>
        <Text>{language === "English" ? "Description" : "Descrizione"}</Text>
      </View>
      <View style={styles.tableItemsItemRight}>
        <View style={styles.tableItemsItemCol}><Text>{language === "English" ? "PRICE" : "TARIFFA"}</Text></View>
        <View style={styles.tableItemsItemCol}><Text>{language === "English" ? "QUANTITY" : "QUANTITÀ"}</Text></View>
        <View style={styles.tableItemsItemCol}><Text>{language === "English" ? "DISCOUNT" : "SCONTO"}</Text></View>
        <View style={styles.tableItemsItemCol}><Text>{language === "English" ? "TOTAL" : "TOTALE"}</Text></View>
      </View>
    </View>
   </>
  
  );
};

export default TableHeader;
import { Button } from '@mui/material';
import React from 'react';
import styles from '../styles/Contact.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDoc from '../PDFDoc/PDFDoc';
import { useSelector } from 'react-redux';


export default function Contact() {
  const {details, language} = useSelector(state => state.proposal)

  return (
    <div className={styles.desktop19}>
      <div className={styles.mainVector}>
        <div className={styles.vector1}></div>
        <div className={styles.vector2}></div>
        <div className={styles.vector3}></div>
        <div className={styles.vector4}></div>
        <div className={styles.vector5}></div>
        <div className={styles.vector6}></div>
      </div>
      <img className={styles.desktop19Child} alt="" src="/assets/desktop5/group-1000001731.svg" />
      <div className={styles.text3543Parent}>
        <img alt="" src="/rafiky/logo-w.png" />{' '}
      </div>
  <div className={styles.addresses}>
        <div className={styles.address1}>
          <b>
            <img src="/assets/icons/Location.svg" /> <br/> 
             {language === "English" ?"Address" : "Indirizzo"}
          </b>
          <p>Viale Fulvio Testi, 59 Cinisello
           <br/> Balsamo - 20092 {language === "English" ? "Italy" : "Italia"}</p>
        </div>
        <div className={styles.address2}>
          <b>
            <img src="/rafiky/email.svg" /> <br/>
            {language === "English" ? "Contacts" : "Contatti"}
          </b>
          <p>info@rafiky.net<br/> +39 0282950332 </p>
        </div>
        <div className={styles.address3}>
          <b>
            <img src="/rafiky/support.svg" /> <br/> 
            {language === "English" ? "Availability" : "Disponibilità"}
          </b>
          {language === "English" ?  <p>Mon-Fri: 8:00-18:00<br/> Sat-Sun: 8:00-13:00</p> :
           <p>Lun-Ven: 8:00-18:00<br/> Sab-Dom: 8:00-13:00</p>}
        </div>{' '}
       
      </div> 
      <div className={styles.contact}>
        <img style={{marginTop:"-20px",  width:"50px"}} src="/assets/icons/Phone.svg" />{' '}
        <p className={styles.number}> +39 3755806530, +39 0282957099</p>
        <img style={{marginTop:"-10px", width:"50px"}} src="/assets/icons/Facebook.svg" />{' '}
        <a href="https://www.facebook.com/Rafiky.net/" target="_blank"style={{bottom:"-5px"}} className={styles.fb}>
        https://www.facebook.com/Rafiky.net/
        </a>
        <br />
        <br />
        <img style={{width:"50px"}} src="/assets/icons/Instagram.svg" />{' '}
        <a href="https://www.instagram.com/rafikynet/" target="_blank" style={{bottom:"-20px"}} className={styles.insta}>
        https://www.instagram.com/rafikynet/
        </a>
      </div>
      <PDFDownloadLink fileName={language==="English" ? `QUOTE CI-${details?.orderNumber}`:`PREVENTIVO CI-${details?.orderNumber}`}  style={{
              position: 'absolute',
              bottom: '4%',
              borderRadius:"5px",
              right: '10%',
              opacity: '1',
              zIndex: 99,
              background: '#5D00A2',
              color: 'white',
            }} document={<PDFDoc language={language} details={details} />}>
          {({ loading }) => (loading ? <CircularProgress

            className={styles.loadingQuoteBtn} color="secondary" /> : <Button
            sx={{
              background: '#5D00A2',
              color: 'white',
            }}
              className={styles.quotebutton} size="medium" >
            Download Quote as Pdf
          </Button>)}
        </PDFDownloadLink>
      
    </div>
  );
}

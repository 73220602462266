import React from 'react'
import { Table, TableRow, TableBody, TableHead, TableCell, TableContainer } from '@mui/material';
import Scrollbar from '../Scrollbar';
import styles1 from '../../styles/TablesItems.module.css';
import { RiCustomerService2Line } from 'react-icons/ri';
import { AiOutlineTranslation, AiOutlineAudio } from 'react-icons/ai';
import { MdOutlineHighQuality, MdOutlineEventAvailable, MdContactSupport } from 'react-icons/md';
import {  BsTelephonePlus, BsPersonVideo3 } from 'react-icons/bs';
import { VscFeedback } from 'react-icons/vsc';
import { useSelector } from 'react-redux';



function Table3() {

    const {language} = useSelector((state)=>state.proposal);
  return (
    <div>
 <div className={styles1.table1}>
            {/* <Card sx={{ display: 'contents' }}> */}
            <Scrollbar>
              <TableContainer sx={{ width: '98%', marginTop: '20px', marginInline: 'auto' }}>
                <Table sx={{ width: '100%', borderCollapse: 'inherit' }}>
                  <TableHead
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                      '& th': { backgroundColor: '#2fcbe9' },
                      '& .css-1k91p2d-MuiTableCell-root:last-of-type, & .css-1k91p2d-MuiTableCell-root:first-of-type': {
                        boxShadow: 'none',
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          width: '50%',
                          color: 'white',
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: 'large',
                          fontWeight: '500',
                        }}
                        align="left"
                      >
                        {language === 'English' ? "The quote includes:":"Il preventivo include:"}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          '.css-4dwhzq-MuiTableCell-root': {
                            marginBottom: 'none',
                          },
                          borderBottom: 'none',
                          maxWidth: '200px',
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: 'large',
                          fontWeight: '500',
                        }}
                        align="left"
                      >
                        {language === 'English' ? (
                          <ul>
                            <li>
                              <RiCustomerService2Line /> Customer Service available 24/7;
                            </li>
                            <li>
                              <AiOutlineTranslation />
                              &nbsp;Professional interpreting service performed by linguists with at least 5 years of
                              experience in the requested sector;{' '}
                            </li>
                            <li>
                              <MdContactSupport /> Preparation of the interpreters based on the support material
                              provided by the client;{' '}
                            </li>
                            <li>
                              <BsTelephonePlus /> 15-minute pre-event telephone briefing with the interpreter;
                            </li>
                            <li>
                              <BsTelephonePlus />
                             &nbsp;15-minute pre-event telephone briefing with the audio technician (in case of simultaneous
                              technical equipment rental);{' '}
                            </li>
                            <li>
                              <MdOutlineEventAvailable /> Interpreters available 30 minutes before the start of the
                              event for a Final briefing;
                            </li>
                            <li>
                              <AiOutlineAudio /> Equipment assembly and Audio Test finalized at least 30 minutes before
                              the start of the event/meeting;
                            </li>
                            <li>
                              <BsPersonVideo3 /> Virtual inspection via video call or on site;
                            </li>
                            <li>
                              <VscFeedback /> Final feedback request via Call & Email.
                            </li>
                          </ul>
                        ) : (
                          <ul>
                            <li>
                              <RiCustomerService2Line />
                              &nbsp;Servizio Clienti disponibile 24/7;
                            </li>
                            <li>
                              <AiOutlineTranslation />
                              &nbsp;Servizio di interpretariato professionale effettuato da linguisti con almeno 5 anni di
                              esperienza nel settore richiesto;
                            </li>
                            <li>
                              <MdContactSupport /> Preparazione degli interpreti sulla base del materiale di supporto
                              fornito dal cliente;
                            </li>
                            <li>
                              <BsTelephonePlus /> Briefing Telefonico pre-evento di 15 minuti con l’interprete;
                            </li>
                            <li>
                              <BsTelephonePlus />
                              &nbsp;Briefing Telefonico pre-evento di 15 minuti con il tecnico audio (In caso di noleggio
                              attrezzatura tecnica per simultanea);{' '}
                            </li>
                            <li>
                              <MdOutlineEventAvailable /> Interpreti disponibili 30 minuti prima dell’inizio dell’evento
                              per un briefing Finale;
                            </li>
                            <li>
                              <AiOutlineAudio /> Montaggio Attrezzatura e Test Audio finalizzato almeno 30 minuti prima
                              dell’inizio dell’evento/meeting;
                            </li>
                            <li>
                              <BsPersonVideo3 /> Sopralluogo Virtuale tramite Video Call o in loco;
                            </li>
                            <li>
                              <VscFeedback />
                              &nbsp;Richiesta feedback finale tramite Call & Email.
                            </li>
                          </ul>
                        )}
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            {/* </Card> */}
          </div>

    </div>
  )
}

export default Table3
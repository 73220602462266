import { Grid } from '@mui/material';
import React from 'react';
import styles from '../styles/MiniRafiky.module.css';
import { useSelector } from 'react-redux';

export default function MiniRafiky() {
  const { language } = useSelector((state) => state.proposal);

  return (
    <div
    className={styles.pageImg}
      style={{
        backgroundImage: `url('/MiniBrochureImages/bg_rafiky.webp')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
      }}
    >
      <div className={styles.desktop17}>
        {/* <div className={styles.desktop17Child} />
        <div className={styles.interpreting}>INTERPRETATION</div>
        <div className={styles.text3543Parent}>
          <img alt="" src="/assets/desktop6/group-1000001719.svg" />{' '}
        </div> */}
        <div className={styles.header}>
        <h1>
          {language === "English"?"Redefine Multilingual Communication: Discover Rafiky's Remote Interpreting Hub" : 
          "Ridefinisce la Comunicazione Multilingue: Scopri il Centro di Interpretariato Remoto di Rafiky"
          }
          </h1>
          <p>
          {language === "English" ? "Empowering Real-Time Language Solutions for Global Connectivity and Understanding":
          "Potenzia Soluzioni Linguistiche in Tempo Reale per la Connessione e la Comprensione Globale"}
          </p>
        </div>
        <div className={styles.divider}>
        </div>
        <div className={styles.image}>
          <img src="/MiniBrochureImages/rafiky.webp"/>
          </div>
          <div className={styles.btn}>
          <a href='https://rafiky.net' target='_blank'>
            Visit the site
          </a>
          </div>
      </div>
    </div>
  );
}

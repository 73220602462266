import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';
import { PATH_AUTH } from 'src/routes/paths';

const AboutUs2 = ({ language, quoteId, brand }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
       <View style={{ ...styles.header,alignItems:"justify", marginLeft:"10px", marginBottom: 0, width: "99%", marginHorizontal: "auto" }}>
        <View style={{ display:"flex", flexDirection:"row", width:"100%" }}>
        <Text style={{...styles.headerItemText,textAlign:"justify",width:"100%",marginTop:"10px", fontSize:"11px",}}>{language === 'English' ? 
        `A SOLID GROUP FOR AN ALL-INCLUSIVE LANGUAGE COVERAGE` : 
        `UN GRUPPO SOLIDO PER UNA COPERTURA LINGUISTICA A 360 GRADI`}
        </Text>
        {brand && brand?.rafiky ?<Image style={{width:"90px", height:"25px", marginTop:"10px",objectFit:"contain" }} 
        src="/rafiky/logo.png" />:<Image style={{width:"90px", height:"25px", marginTop:"10px",objectFit:"contain" }} 
        src="/assets/desktop6/group-1000001719.png" />
      }
        </View>
         {/* <View style={{alignItems:"right", alignContent:"right", float:"right"}}>
          <Image style={{...styles.headerRightImg, alignItems:"right", alignContent:"right", float:"right" }} src="/translationAssets/desktop6/lingoyou.png" />
        </View> */} 
        <View style={styles.headerItem} />
      </View>
      <View style={{...styles.aboutUs2Cards, marginBottom:"5px"}}>
      <View style={{...styles.aboutUs2Card, marginInline:"auto", placeContent:"center", alignContent:"center", alignItems:"center"}}>
      <Image style={{ width:"auto", height:"50px", objectFit:"contain", marginTop:"6px"}} src="/translationAssets/desktop8/lingoyou.png" />
      <Text style={{...styles.aboutUs2CardText, paddingTop:"12px"}}>{language === 'English' ? 
      `Global vision, local service. 20,000+ translators for complete language solutions. ` : 
      `Traduzione globale, servizio clienti locale. Oltre 20.000 traduttori professionisti per soluzioni linguistiche complete. `}
       
       </Text>
        <Text style={{ textAlign: 'center',
            color: '#FFFFFF',
            fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',
            fontWeight: 'normal',
            backgroundColor:"#4ed5ee", height:"30px", 
            borderRadius: '7px', fontSize:"9px", paddingTop:"7px",
            width:"100px", marginTop:"10px"}}>
        <Link style={{color:"white"}} 
        href={`${process.env.REACT_APP_URL}${PATH_AUTH.quoteLingoyouClientView(
          quoteId,
          `${language === "English" ? "English": "Italian"}`
        )}`}
        >
              {language === "English" ? "Find out more" : "Scopri di più"}
       </Link>
        </Text>
        </View>
        <View style={{...styles.aboutUs2Card, marginInline:"auto", placeContent:"center", alignContent:"center", alignItems:"center"}}>
      <Image style={{ width:"150", height:"50px", objectFit:"contain"}} src="/translationAssets/desktop8/congressi.png" />
      <Text style={{...styles.aboutUs2CardText,padding:'3px', paddingTop:"14px",  fontSize:"8px"}}>{language === 'English' ? 
      `Exceptional interpreting agency: selected experts, advanced technology, full coverage. Chosen by companies in Italy and Europe.` : 
      `Interpretariato eccellente: esperti selezionati, tecnologie avanzate, copertura completa. Scelta da aziende in Italia ed Europa.`}
       
       </Text>
       
        <Text style={{ textAlign: 'center',
            color: '#FFFFFF',
            fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',
            fontWeight: 'normal',
            backgroundColor:"#4ed5ee", height:"30px", 
            borderRadius: '7px', fontSize:"9px", paddingTop:"7px",
            width:"100px", marginTop:"10px"}}>
        <Link style={{color:"white"}} 
        href="#map"
        >
              {language === "English" ? "Find out more" : "Scopri di più"}
       </Link>
        </Text>
        </View>
      </View>
       <View style={{...styles.aboutUs2Cards, marginTop:"2px", marginBottom:"10px"}}>
      <View style={{...styles.aboutUs2Card, marginInline:"auto", placeContent:"center", alignContent:"center", alignItems:"center"}}>
      <Image style={{ width:"auto", height:"50px", objectFit:"contain", marginTop:"6px"}} src="/translationAssets/desktop8/lingoyou_academy.png" />
      <Text style={{...styles.aboutUs2CardText, paddingTop:"12px"}}>{language === 'English' ? 
      `Quality professional training. Language courses, certifications, recovery, exam preparation.` : 
      `Formazione professionale di qualità. Corsi di lingue, certificazioni, recupero, preparazione esami.`}
       
       </Text>
        <Text style={{ textAlign: 'center',
            color: '#FFFFFF',
            fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',
            fontWeight: 'normal',
            backgroundColor:"#4ed5ee", height:"30px", 
            borderRadius: '7px', fontSize:"9px", paddingTop:"7px",
            width:"100px", marginTop:"10px"}}>
<Link style={{color:"white"}} 
        href={`${process.env.REACT_APP_URL}${PATH_AUTH.quoteLingoyouAcademyClientView(
          quoteId,
          `${language === "English" ? "English": "Italian"}`
        )}`}
        >
              {language === "English" ? "Find out more" : "Scopri di più"}
       </Link>        </Text>

        </View>
        <View style={{...styles.aboutUs2Card, marginInline:"auto", placeContent:"center", alignContent:"center", alignItems:"center"}}>
      <Image style={{ width:"120", height:"40px", objectFit:"contain", marginTop:"10px"}} src="/translationAssets/desktop8/rafiky.png" />
      <Text style={{...styles.aboutUs2CardText, paddingTop:"17px"}}>{language === 'English' ? 
      `Our cutting-edge remote interpreting platform offers innovative communication solutions.` : 
      `Piattaforma di interpretariato da remoto, all'avanguardia nel settore, offre soluzioni di comunicazione innovative.`}
       
       </Text>
        <Text style={{ textAlign: 'center',
            color: '#FFFFFF',
            fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',
            fontWeight: 'normal',
            backgroundColor:"#4ed5ee", height:"30px", 
            borderRadius: '7px', fontSize:"9px", paddingTop:"7px",
            width:"100px", marginTop:"10px"}}>
<Link style={{color:"white"}} 
        href={`${process.env.REACT_APP_URL}${PATH_AUTH.quoteRafikyClientView(
          quoteId,
          `${language === "English" ? "English": "Italian"}`
        )}`}
        >
              {language === "English" ? "Find out more" : "Scopri di più"}
       </Link>        </Text>
        </View>
      </View>
      <View>
        <View style={{display:"flex", flexDirection:"row", marginTop:"39px"}}>
           <Text style={{fontSize:"7px", color:"#2A0058",fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',
            fontWeight: 'bold', marginLeft:"18px", textTransform:"uppercase"}}>
              {language === "English" ? `Translation Service`: 
              `SERVIZIO DI TRADUZIONE`
              }
           </Text>
           <Text style={{fontSize:"7px", color:"#2A0058",fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal', marginLeft:`${language === "English" ? "55%" : "53.5%"}`,
            fontWeight: 'bold',  textTransform:"uppercase"}}>
              {language === "English" ? `On-Site Interpreting Services`: 
              `SERVIZI DI INTERPRETARIATO IN LOCO`
              }
           </Text>
        </View>
        <View style={{display:"flex", flexDirection:"row", marginTop:"46px"}}>
           <Text style={{fontSize:"7px", color:"#2A0058",fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',
            fontWeight: 'bold', marginLeft:"18px",  textTransform:"uppercase"}}>
              {language === "English" ? `Localization Service`: 
              `SERVIZIO DI LOCALIZZAZIONE` 
              }
           </Text>
           <Text style={{fontSize:"7px", color:"#2A0058",fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal', marginLeft:`${language === "English" ? "55%" : "51%"}`,
            fontWeight: 'bold',  textTransform:"uppercase"}}>
              {language === "English" ? `Interpreter Equipment Rental`: 
              `NOLEGGIO ATTREZZATURA PER INTERPRETI`
              }
           </Text>
        </View>
        <View style={{display:"flex", flexDirection:"row", marginTop:"47px"}}>
           <Text style={{fontSize:"7px", color:"#2A0058",fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',
            fontWeight: 'bold', marginLeft:"18px",  textTransform:"uppercase"}}>
              {language === "English" ? `Audiovisual Linguistic Services`: 
              `SERVIZI LINGUISTICI AUDIOVISIVI`
              }
           </Text>
           <Text style={{fontSize:"7px", color:"#2A0058",fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal', marginLeft:`${language === "English" ? "48.3%" : "49%"}`,
            fontWeight: 'bold',  textTransform:"uppercase"}}>
              {language === "English" ? `Hostesses & Stewards`: 
              `HOSTESS & STEWARD`
              }
           </Text>
        </View>
        <View style={{display:"flex", flexDirection:"row", marginTop:"53px"}}>
           <Text style={{fontSize:"7px", color:"#2A0058",fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',
            fontWeight: 'bold', marginLeft:"18px",  textTransform:"uppercase"}}>
              {language === "English" ? `Certified Translation`: 
              `TRADUZIONE CERTIFICATA`
              }
           </Text>
           <Text style={{fontSize:"7px", color:"#2A0058",fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',marginLeft:`${language === "English" ? "54.5%" : "52.5%"}`,
            fontWeight: 'bold',  textTransform:"uppercase"}}>
              {language === "English" ? `Remote Interpreting Services`: 
              `SERVIZI DI INTERPRETARIATO DA REMOTO`
              }
           </Text>
        </View>
        <View style={{display:"flex", flexDirection:"row", marginTop:"43px"}}>
           <Text style={{fontSize:"7px", color:"#2A0058",fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',
            fontWeight: 'bold', marginLeft:"18px",  textTransform:"uppercase"}}>
              {language === "English" ? `LANGUAGE SCHOOL`: 
              `LANGUAGE SCHOOL`
              }
           </Text>
           <Text style={{fontSize:"7px", color:"#2A0058",fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal', marginLeft:"57%",
            fontWeight: 'bold',  textTransform:"uppercase"}}>
              {language === "English" ? `INTEGRATION WITH MICROSOFT TEAMS`: 
              `INTEGRAZIONE CON MICROSOFT TEAMS`
              }
           </Text>
        </View>
        <Image 
        style={{width:"100%",objectFit:"contain",  margin:"10px", marginTop:"-288px", marginLeft:"-4px", zIndex:"-99"}}  
        src="/assets/desktop8/pdf_chart.png"/>      
      </View>

    </Page>
  );
};

export default AboutUs2;

import React, { useEffect, useState } from 'react';

import './index.css';
import MuiAlert from '@mui/material/Alert';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BottomNavigation from '@mui/material/BottomNavigation';
import { RiFileDownloadFill } from 'react-icons/ri';
import { FaFileSignature } from 'react-icons/fa';
import Stack from '@mui/material/Stack';
import { FaRegHandPointLeft } from 'react-icons/fa';
import {BsVectorPen} from 'react-icons/bs';
import {BsFileEarmarkCheckFill} from 'react-icons/bs';
import { Box, Button, CircularProgress, Paper, Typography } from '@mui/material';
// import { PDFDownloadLink } from '@react-pdf/renderer';
// import PDFDoc from '../PDFDoc/PDFDoc';
import { useSnackbar } from 'notistack';
import { fCurrency } from 'src/utils/formatNumber';
import { useDispatch, useSelector } from 'react-redux';
import { addBtnDisplay, addDefaultItemsTranslation, addItems2Translation, addName, addServices2Translation } from 'src/redux/slices/proposal';
import swal from 'sweetalert';
import { useMutation } from '@apollo/client';
import { QUOTE_ACCEPT_INTERPRETATION } from 'src/graphQL/mutations';
import Home from './components/Home';
import Introduction from '../components/Introduction';
import SectorClients from '../components/SectorClients';
import AboutUs1 from './components/AboutUs1';
import AboutUs2 from './components/AboutUs2';
import OurClients from '../components/OurClients';
import Testimonials from '../components/Testimonials';
import TablesItems from '../components/TablesItems';
import TermsAndCondition from './components/Terms&Condition';
import Contact from './components/ContactUs';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDoc from './PDFDoc/PDFDoc';
import WhyRafiky from './components/WhyRafiky';
import Process from './components/Process';
import Discount from './components/Discount';
import UseCases from './components/UseCases';
import Connect from './components/Connect';
import CustomerGuide from './components/CustomerGuide';
import CustomerGuide1 from './components/CustomerGuide1';
import OnSiteInterpreting from './components/OnSiteInterpreting';
import WebConferencing from './components/WebConferencing';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function RafikyClient() {


  const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState(false); 
  const [TranslationLoading, setLoadingTranslation] = useState(false);
  const targetRef = React.useRef(null);
  const {details, name, option, language, quoteId, addOns, isBtn, items1Translation, service1Translation, items2Translation, service2Translation} = useSelector(state => state.proposal)
  const dispatch = useDispatch()
  const { enqueueSnackbar } = useSnackbar();
  const [QuoteAccept] = useMutation(QUOTE_ACCEPT_INTERPRETATION)

  const {
    grandTotal,
    grandTotalOptions,
    additionalService,
    additionalService2,
    interpretationItem,
    interpretationItem2,
    brand
  } = details ? details : {}; 

  const handleClickOpen = () => {
    if(option > 0 ){
      setOpen(true);
      
      setError(false)
    }
    else if(option === 0 && interpretationItem2?.length > 0){
      enqueueSnackbar(`${language === "English" ? "Please Choose any Option": "Si prega di selezionare l'opzione desiderata"}`, { variant: 'error' });
      setError(true)
      if (targetRef.current) {
        targetRef.current.scrollIntoView({ behavior: 'smooth' });
      }
    }else{
      setOpen(true);
      
      setError(false)
    }
  };
  const handleClickClose = () => {
    setOpen(false);
    handleAcceptance();
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(()=>{
    async function fetchTranslation(){
      if(language === "English"&& details){
        setLoadingTranslation(true);
        const body1 = [...interpretationItem, ...additionalService]?.map((item)=>item.service?.Description['italian']);
        const requestData1 = body1?.map((text)=>{
          return {text}
        });
      const headers = {
          'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_TEXT_TRANSLATION_KEY}`,
          'Ocp-Apim-Subscription-Region': "global",
          'Content-Type': 'application/json',
      };
     await fetch(`https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=it&to=en`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestData1),
      })
      .then(response => response.json())
      .then(data => {
        // const results = data;
        const textArray = data?.map(item => item.translations[0].text);
        dispatch(addDefaultItemsTranslation({items:textArray?.slice(0, interpretationItem?.length), services:textArray?.slice(interpretationItem?.length)}))
        // setItems1Translation(textArray?.slice(0, interpretationItem?.length));
        // setService1Translation(textArray?.slice(interpretationItem?.length))
      })
      .catch(error => {
        console.error('Error:', error);
      });
      
      if(interpretationItem2?.length > 0){
       
      const optionItems = [];
      
      for(var i = 0; i < interpretationItem2?.length; i++){
        const body2 = [...interpretationItem2[i]]?.map(item =>item.service.Description["italian"])
        const requestData2 = body2?.map((text)=>{
          return {text}
        });
       await fetch(`https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=it&to=en`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(requestData2),
        })
        .then(response => response.json())
        .then(data => {
          // const results = data;
          const textArray = data?.map(item => item.translations[0].text);
          optionItems?.push(textArray);
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }
      //  setItems2Translation(optionItems)
       dispatch(addItems2Translation(optionItems))
      }
      if(additionalService2?.length > 0){
       
        const optionServices = [];
        for(var i = 0; i < additionalService2?.length; i++){
          const body2 = [...additionalService2[i]]?.map(item =>item.service.Description["italian"])
          const requestData2 = body2?.map((text)=>{
            return {text}
          });
         await fetch(`https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=it&to=en`, {
            method: 'POST',
            headers: headers,
            body: JSON.stringify(requestData2),
          })
          .then(response => response.json())
          .then(data => {
            // const results = data;
            const textArray = data?.map(item => item.translations[0].text);
            optionServices?.push(textArray);
          })
          .catch(error => {
            console.error('Error:', error);
          });
        }
        //  setService2Translation(optionServices)
         dispatch(addServices2Translation(optionServices))
        }
        setLoadingTranslation(false);
      }
    }
   fetchTranslation()
    
   },[details])
   const handleAcceptance = async () => {
    await QuoteAccept({variables:{quoteAcceptId:quoteId, name, optionSelected:option === 0 ? 0 : option-1}})
    .then((res)=>{
        if(res.data.quoteAccept){
          if(language === "English"){
            swal("Quote accepted!", "Thank you for accepting the quote!", "success");
            }else{
              swal("Preventivo Accettato!", "Grazie per aver accettato il preventivo!", "success");
            }
          // setIsBtn(false)
          dispatch(addBtnDisplay(false))
        }
    })
    .catch((err)=>console.log(err))
  
  }
  return (
    <>
    {error && (
        <Alert sx={{marginInline:"auto", width: "400px",
        textAlignLast: "center",
        textAlign: "-webkit-center",
        justifyContent: "center"}} severity="error">Please Choose any Option</Alert>
      )}
      <div id="translation-proposal" style={{ marginInline: 'auto', boxShadow: '0px 0px 5px 3px rgb(0 0 0 / 40%)' }}>
        {addOns && !addOns?.cover && !addOns?.companyInfo && !addOns?.quoteInfo && (
          <Stack sx={{ width: '600px', marginInline: 'auto' }} spacing={2}>
            
          </Stack>
        )}
        {addOns && addOns?.cover && (
                     
          <div id="home">
          <Home/>
          </div>
           
        )}
        {addOns && addOns?.companyInfo && (
          <div className='desktop-pages'>
            
            <div id="intro">
             <Introduction/>              
            </div>
            </div>
        )}
        {addOns?.sector && (
          <div className='desktop-pages'>
          <SectorClients/>
          </div>
        )}
        {addOns && addOns?.companyInfo && (
          <div className='desktop-pages'>
            <div>
             <AboutUs1/>
            </div>
            <div id="aboutus">
               <AboutUs2/>
            </div>
            {/* <div>
              <Solution/>
            </div> */}
            <WhyRafiky/>
      <Process/>
         <UseCases/>
         <Connect/>
         <CustomerGuide />
         <CustomerGuide1 />
         <WebConferencing />
         <OnSiteInterpreting />
         <Discount />
            
            {/* <div>
              <TechnologyPlateform/>
           </div> */}
            {/* <div>
              <CoPerformance/>
           </div> */}
           
           {/* <div>
            <Skills/>
           </div> */}

           <div>
            
             <OurClients />
              
           </div>
           <div>
             <Testimonials/>
           </div>
          </div>
        )}
        
        {addOns && addOns?.quoteInfo && (
          <>
            <div id="tables">
             
              <TablesItems />
               
            </div>
            <div id="terms">
             
              <TermsAndCondition
             
                handleAcceptance={handleAcceptance}
              />
               
            </div>
            
          </>
        )}
        {((addOns && addOns?.companyInfo) || addOns?.quoteInfo || addOns?.cover) && (
          <div className='desktop-pages' id="contact">
                       
            <Contact />
             
          </div>
        )}
      </div>
      {addOns && addOns?.quoteInfo && <Paper
        id={brand && brand?.rafiky ? "bottom_bar_interpret1":"bottom_bar_interpret"}
        sx={{ position: 'fixed',zIndex:99999999999, height: '90px', bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
        id={brand && brand?.rafiky ? 'bottom_nav_interpret1':'bottom_nav_interpret'}
          sx={{ justifyContent: 'end', margin: '10px' }}
          showLabels
        >
          <Box>
          {((option > 0 && grandTotalOptions?.length > 0) || grandTotalOptions?.length === 0) && (
            <Typography variant="h5" sx={{ color: 'white', marginTop: '3px', marginRight: '20px' }}>
        {language === "English" ? 'Total Estimate' : 'Totale Preventivo' }
            </Typography>
          )}
            {(grandTotalOptions?.length === 0 || (grandTotalOptions?.length > 0 && option === 1)) && (
            <Typography variant="h3" sx={{ color: 'white', marginTop: '1px', marginRight: '20px' }}>
               &nbsp;{ fCurrency(grandTotal)} <span style={{fontSize:'11px',verticalAlign:'middle'}}>
               {language === "English" ? '(VAT included)': '(IVA Inclusa)'}
               </span>
            </Typography>
          )}
          {(grandTotalOptions?.length > 0 && option > 1) && (
            <Typography variant="h3" sx={{ color: 'white', marginTop: '1px', marginRight: '20px' }}>
               &nbsp;{ fCurrency(grandTotalOptions[option-2])} <span style={{fontSize:'11px',verticalAlign:'middle'}}>
               {language === "English" ? '(VAT included)': '(IVA Inclusa)'}
               </span>
            </Typography>
          )}

          {(grandTotalOptions?.length > 0 && option === 0) && (
<Typography variant="h5" sx={{ color: 'white', paddingTop: '20px', marginRight: '20px' }}>
{language==="English" ? `Select an Option`:`Seleziona un'opzione`}
            </Typography>
          )}
          </Box>
          <PDFDownloadLink className='pdf-btn-desktop' fileName={language==="English" ? `QUOTE CI-${details?.orderNumber}`:`PREVENTIVO CI-${details?.orderNumber}`} style={{fontSize:"17px", pointerEvents:"cursor", borderRadius:"5px", backgroundColor: 'white', color: 'grey', width: '210px', height: '70px', marginRight: '20px' }}
   document={<PDFDoc language={language} details={details} name={name}  items1Translation={items1Translation}
   service1Translation={service1Translation}
   items2Translation={items2Translation}
   service2Translation={service2Translation} quoteId={quoteId} />}>
          {({ loading }) => (
            <>
            {loading || TranslationLoading ? <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: "10px" }}>
        <CircularProgress />
      </Box> :  <Button disabled={loading && true} sx={{ fontSize: "unset", width:'210px' }}>
              <RiFileDownloadFill style={{ color:`${loading ? 'grey': '#ff0c00b3'}`, marginRight: '2px', height: '30px', width: '45px' }} />{' '}
              {language === "English" ? 'DOWNLOAD PDF' : ' SCARICA PDF'}
            </Button> 
            }
            </>  
          )}
        </PDFDownloadLink>
        <PDFDownloadLink className='pdf-btn-mbl' fileName={language==="English" ? `QUOTE CI-${details?.orderNumber}`:`PREVENTIVO CI-${details?.orderNumber}`} style={{fontSize:"17px", pointerEvents:"cursor", backgroundColor: 'white', color: 'grey',borderRadius:'50%', width: '60px', height: '60px', marginLeft: '20px' }}
   document={<PDFDoc language={language} details={details} name={name}  items1Translation={items1Translation}
   service1Translation={service1Translation}
   items2Translation={items2Translation}
   service2Translation={service2Translation} quoteId={quoteId}/>}>
          {({ loading }) => (
            <>
            {loading || TranslationLoading ? <Box className='download' 
            sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box> : 
            <img className='download'  alt='download' src={'/rafiky/carbon--generate-pdf.svg'}/>
            }
            </>  
          )}
        </PDFDownloadLink>
       
          {(details?.status === "Quote-Won" || details?.status === "Sent To PM" || !isBtn)  ? 
          <>
          <Button className='pdf-btn-desktop' disabled={true}
            sx={{"&:disabled":{fontSize:"18px", backgroundColor: 'white', color: 'rgb(61 191 83 / 97%)', width: '150px', height: '70px' }}}
          >
            <BsFileEarmarkCheckFill style={{ color: 'rgb(61 191 83 / 97%)', marginRight: '2px', height: '30px', width: '40px' }} />
               {language === "English" ? 'SIGNED' : 'FIRMATO' }

          </Button>
          <Button className='pdf-btn-mbl' disabled={true}
            sx={{"&:disabled":{fontSize:"18px", backgroundColor: 'white', color: 'rgb(61 191 83 / 97%)', borderRadius:'50%', marginLeft:'15px', width: '60', height: '60px' }}}
          >
          <BsFileEarmarkCheckFill  style={{ color: '#8316d3', height: '60px', width: '40px', }} /> 
          </Button>
          </>
          
                    : <>
                    <Button
                    className='pdf-btn-desktop'
                    sx={{fontSize:"17px", backgroundColor: 'white', color: `${(option === 0 && interpretationItem2?.length > 0) ? "grey":'#8316d3'}`, width: '150px', height: '70px' }}
                    onClick={handleClickOpen}
                  >
                    <FaFileSignature style={{ color: '#4BD5F1', 
                      marginRight: '2px', height: '30px', width: '40px' }} /> 
                     {language === "English" ? 'SIGN' : 'FIRMA' }
                  </Button>
                  <Button
                    className='pdf-btn-mbl'
                    sx={{fontSize:"17px", backgroundColor: 'white', color: `${(option === 0 && interpretationItem2?.length > 0) ? "grey":'#8316d3'}`, borderRadius:'50%', marginLeft:'15px' , width: '60px', height: '60px' }}
                    onClick={handleClickOpen}
                  >
                     <img className='download'  alt='download' src={'/rafiky/fluent--signature-24-regular.png'}/>
                  </Button>
                    </>
                    
                    }

        </BottomNavigation>
      </Paper>
      }
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          sx={{
            '& .css-y4brsv-MuiPaper-root-MuiDialog-paper.MuiPaper-rounded': { borderRadius: '5px' },
            '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '470px', textAlign: 'center', borderRadius: '5px' },
            borderRadius: '5px',
          }}
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
              color: 'rgba(0,0,0,.65)',
              fontWeight: '600',
              fontSize: '25px',
            }}
          >
             {language === "English" ? 'Accept the quote' : 'Accetta il preventivo' }
          </DialogTitle>
          <DialogContent
           id="dialog_content"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
              <span style={{display:"inline-flex", marginTop:"30px"}}>
          <BsVectorPen style={{fontSize:"25px", marginTop:"5px"}} />  <input placeholder={`${language === "English" ? "Sign here":"Firma qui"}`} value={name} onChange={(e) => dispatch(addName(e.target.value))} />{' '}
          <FaRegHandPointLeft style={{fontSize:"25px", marginTop:"5px"}} />
            </span>
             <DialogContentText id="alert-dialog-description">
              
              {name} 
              
              </DialogContentText>
          </DialogContent>
          <DialogActions id="alert-dialog-btn">
            <Button
              sx={{
                background: '#7cd1f9',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
                fontWeight: '600',
                fontSize: '14px',
                padding: '10px 10px',
                cursor: 'pointer',
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
                  color: 'white',
                  background: 'grey',
                },
              }}
              disabled={!name && true}
              onClick={handleClickClose}
              autoFocus
            >
                             {language === "English" ? 'Quote confirmation' : 'Conferma preventivo' }

            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
}

import React, { useEffect, useState } from 'react';
import 'src/sections/interpretClientOutput/index.css';
import MuiAlert from '@mui/material/Alert';
import { addLanguageAndId } from 'src/redux/slices/proposal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import MiniRafiky from 'src/sections/interpretClientOutput/components/MiniRafiky';
import WhyRafiky from 'src/sections/interpretClientOutput/rafiky/components/WhyRafiky';
import Process from 'src/sections/interpretClientOutput/rafiky/components/Process';
import UseCases from 'src/sections/interpretClientOutput/rafiky/components/UseCases';
import CustomerGuide from 'src/sections/interpretClientOutput/rafiky/components/CustomerGuide';
import CustomerGuide1 from 'src/sections/interpretClientOutput/rafiky/components/CustomerGuide1';
import WebConferencing from 'src/sections/interpretClientOutput/rafiky/components/WebConferencing';
import OnSiteInterpreting from 'src/sections/interpretClientOutput/rafiky/components/OnSiteInterpreting';
import Connect from 'src/sections/interpretClientOutput/rafiky/components/Connect';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function Rafiky() {
    const {lang} = useParams()

    const dispatch = useDispatch()
    
    useEffect(()=>{
        dispatch(addLanguageAndId({language: lang, id:""}))
    
    }, [1])


  return (
    <>
    
      <div id="translation-proposal" style={{ marginInline: 'auto', boxShadow: '0px 0px 5px 3px rgb(0 0 0 / 40%)' }}>
      <WhyRafiky language={lang}/>
      <Process language={lang}/>
         <UseCases language={lang}/>
         <Connect language={lang}/>
         {/* <CustomerGuide2 language={language}/> */}
         <CustomerGuide language={lang}/>
         <CustomerGuide1 language={lang}/>
         {/* <Technical language={language}/> */}
         <WebConferencing language={lang}/>
         <OnSiteInterpreting language={lang}/>
         <MiniRafiky/>
      </div>
     
    </>
  );
}

// import { Grid } from "@mui/material";
// import React from "react";
// import styles from "../styles/Packages.module.css";
import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

import { icons } from '../icons';
import { fDateTime } from 'src/utils/formatTime';
import { fCurrency } from 'src/utils/formatNumber';

function formatDate(dat) {
  const date = new Date(dat)
  // Get day, month, year, hours, and minutes
  var day = date.getDate();
  var month = date.getMonth() + 1; // Month is zero-based
  var year = date.getFullYear();
  var hours = date.getHours();
  var minutes = date.getMinutes();

  // Add leading zero if necessary
  if (day < 10) {
    day = '0' + day;
  }
  if (month < 10) {
    month = '0' + month;
  }
  if (hours < 10) {
    hours = '0' + hours;
  }
  if (minutes < 10) {
    minutes = '0' + minutes;
  }

  // Return formatted date
  return day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;
}
function subtractDays (pkg, dateStr, days, orgPkgsOption) {
  var date = new Date(dateStr);
  if(pkg === "Premium"){
    date.setDate(date.getDate() + days);
    if(new Date(date).getDay() === 0){
      if(orgPkgsOption === 0 && (new Date(dateStr).getDay() !== 4)){
        date.setDate(date.getDate() + 2);
      }else{
        date.setDate(date.getDate() + 1);
      }
    }else if( new Date(date).getDay() === 6 ){
      if(orgPkgsOption === 0 && (new Date(dateStr).getDay() !== 4)){
        date.setDate(date.getDate() + 3);
      }else{
        date.setDate(date.getDate() + 2);
      }
    }
  }else if(pkg === "Professional"){
    if(orgPkgsOption === 0){
      date.setDate(date.getDate() + 1);
      if(new Date(date).getDay() === 0){
        date.setDate(date.getDate() + 1);
      }else if( new Date(date).getDay() === 6 ){
        date.setDate(date.getDate() + 2);
      }
    }else if(orgPkgsOption === 2){
      date.setDate(date.getDate() - 1);
      if(new Date(date).getDay() === 0){
        date.setDate(date.getDate() - 2);
      }else if( new Date(date).getDay() === 6 ){
        date.setDate(date.getDate() - 1);
      }
    }else{
      date.setDate(date.getDate());
    }
   
  }else if(pkg === "Economy"){
    date.setDate(date.getDate() - days);
    if(new Date(date).getDay() === 0){
      if(orgPkgsOption === 2 && (new Date(dateStr).getDay() === 1)){
        date.setDate(date.getDate() - 3);
      }else{
        date.setDate(date.getDate() - 2);
      }
    }else if( new Date(date).getDay() === 6 ){
      if(orgPkgsOption === 2 && (new Date(dateStr).getDay() === 1)){
        date.setDate(date.getDate() - 2);
      }else{
        date.setDate(date.getDate() - 1);
      }
    }
  }
  return formatDate(date);
}
export default function Packages({ language, packagesNetTotals, deliveryDateTime, packagesPrices, selectedPackageOption, status="", orgPkgsOption }) {

    return (
      <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
        <View style={{ ...styles.cards, marginTop:"20px", display: 'flex', flexDirection: 'row' }}>
          <View style={{ width: '250px', margin: '10px', display: 'flex' }}>
            <View style={styles.card1}>
              <Image
                style={{ width: '50px', height: '50px', marginLeft: '60px' }}
                src="/translationAssets/packages/logo1.png"
              />
              <View style={{ ...styles.cardData, height: '120px', alignItems:"center", textAlign:"center", border: '1px solid grey',  backgroundColor: 'white'}}>
                <Text style={{...styles.heading,borderBottom:"1px solid black",width:"100%", color:"black"}}>
                 {language === "English" ? `Economy` : `Economy`}
                  </Text>
                <Text style={{...styles.paragraph,  color:"black"}}>
                {language === "English" ? `Automatic translation through one of our software. Quick review of the document by an expert native translator. Translations suitable for understanding a text or for private use, not suitable for publication.` : 
                `Traduzione automatica attraverso uno dei nostri software. Revisione veloce del documento da parte di un traduttore madrelingua esperto. Traduzioni adatte alla comprensione di un testo o per uso privato, non adatto alla pubblicazione.`}

                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: '250px', margin: '10px', display: 'flex' }}>
            <View style={styles.card1}>
              <Image
                style={{ width: '50px', height: '50px', marginLeft: '60px' }}
                src="/translationAssets/packages/logo2.png"
              />
              <View style={{ ...styles.cardData,height: '120px',alignItems:"center", textAlign:"center",  border: '1px solid grey', backgroundColor: 'white' }}>
                <Text style={{...styles.heading, borderBottom:"1px solid black",width:"100%", color:"black"}}>
                  {language === "English" ? `Professional` : `Professional`}

                  </Text>
                <Text style={{...styles.paragraph, fontSize:"7.5px",  color:"black"}}>
                {language === "English" ? `Translations carried out by a native speaker expert. Review by one of our specialized Project Managers. Use of the Translation Memory upon request included in the price. High quality translations for standard documents.` :
                 `Traduzioni effettuate da un esperto madrelingua. Revisione da parte di un nostro Project Manager specializzato. Utilizzo della Translation Memory su richiesta inclusa nel prezzo. Traduzioni di ottima qualità per documenti standard.`}

                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: '250px', margin: '10px', display: 'flex' }}>
            <View style={styles.card1}>
              <Image
                style={{ width: '50px', height: '50px', marginLeft: '60px', border:"2px solid white", borderRadius:"50%" }}
                src="/translationAssets/packages/logo3.png"
              />
              <View style={{ ...styles.cardData,height: '120px', alignItems:"center", textAlign:"center",  border: '1px solid grey',   backgroundColor: 'white' }}>
                <Text style={{...styles.heading,borderBottom:"1px solid black",width:"100%",  color:"black"}}>
                  {language === "English" ? `Premium` : `Premium`}

                  </Text>
                <Text style={{...styles.paragraph, fontSize:"7.5px", color:"black"}}>
                {language === "English" ? `Translations carried out by an expert native translator. Quality control by a second native translator. Review by one of our specialized Project Managers. Translations of high-quality content intended for rapid publication.` : 
                `Traduzioni effettuate da un traduttore madrelingua esperto. Controllo qualità da parte di un secondo traduttore madrelingua. Revisione da parte un nostro Project Manager specializzato. Traduzioni di contenuti di alta qualità destinati alla pubblicazione in tempi rapidi.`}

                </Text>
              </View>
            </View>
          </View>

        </View>
          {(status !== "Quote-Won" && status !== "Sent To PM") ? <View style={{...styles.footer, backgroundColor:"white"}}>
          <View style={{...styles.packagesFooterCards, display:"inline-flex", flexDirection:"row"}}>
          <View style={{...styles.packagesFooterCard1,  marginTop:"110px"}}>
               
                <View style={{...styles.offer.div, marginLeft: language === "English" ?"15px" :"20px"}}>
                  <Text style={{ fontSize:"10px", fontWeight:"normal", fontStyle:"normal", marginLeft:"35px", color:"grey"}}>
                  {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
                    </Text>
                    <Text style={{ color:"black", fontSize:"9px", fontWeight:"bold", fontStyle:"normal", marginLeft:"45px"}}>
                    {subtractDays("Economy", deliveryDateTime,  orgPkgsOption === 0 ? 0 : orgPkgsOption === 2 ? 2 : 1, orgPkgsOption)}
                    </Text>
                  <Text style={{ color:"black", marginTop:"5px",fontWeight:"bold", fontStyle:"normal", fontSize:"12px", marginLeft:"51px"}}>
                  {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[0]))}
                    </Text>
                    {packagesPrices?.economy?.length === 1 &&<Text style={{ fontSize:"10px", fontWeight:"normal", fontStyle:"normal", marginLeft:language === "English" ? "40px":"45px", color:"grey"}}>
                  {language === "English" ? `about ${fCurrency(packagesPrices?.economy[0])}/word` : `${fCurrency(packagesPrices?.economy[0])}/parola`}

                  </Text>}
                  <Text style={{borderRadius:"3px", color:selectedPackageOption === 0 ? "grey":"white", width:"140px",padding:"3px", alignItems:"center", textAlign:"center",
                   backgroundColor:selectedPackageOption === 0 ?"lightgrey" :"#103996", fontSize:"10px", fontWeight:"normal", fontStyle:"normal",
                   marginLeft:"20px"}}>                   
                  {selectedPackageOption === 0 ? `${language === "English" ? "SELECTED PACKAGE" : "PACCHETTO SELEZIONATO"}` : `${language === "English" ? "SELECT":"SELEZIONA"}`}

                   </Text>
                </View>
</View>
<View style={{...styles.packagesFooterCard1,  marginTop:"110px"}}>
                
                <View style={{...styles.offer.div, marginLeft: language === "English" ?"6px" :"10px"}}>
                  <Text style={{ color:"grey", fontSize:"10px", fontWeight:"normal", fontStyle:"normal", 
                  marginLeft: language === "English" ? "51px": "55px"}}>
                  {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
                    </Text>
                    <Text style={{ color:"black", fontSize:"9px", fontWeight:"bold", fontStyle:"normal", marginLeft:"65px"}}>
                    {subtractDays("Professional", deliveryDateTime, 0, orgPkgsOption  )}
                    </Text>
                  <Text style={{ color:"black", marginTop:"5px",fontWeight:"bold", fontStyle:"normal", fontSize:"12px", 
                  marginLeft:"76px"}}>
                  {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[1]))}
                  </Text>
                  {packagesPrices?.professional?.length === 1 &&<Text style={{ color:"grey", fontSize:"10px", fontWeight:"normal", fontStyle:"normal",
                   marginLeft:language === "English" ? "58px" : "65px"}}>
                  {language === "English" ? `about ${fCurrency(packagesPrices?.professional[0])}/word` : `${fCurrency(packagesPrices?.professional[0])}/parola`}

                  </Text>}
                  <Text style={{borderRadius:"3px",  color:selectedPackageOption === 1 ? "grey":"white", width:"140px",padding:"3px", alignItems:"center", textAlign:"center",
                   backgroundColor:selectedPackageOption === 1 ?"lightgrey" :"#103996", fontSize:"10px", fontWeight:"normal", fontStyle:"normal",
                   marginLeft:"36px"}}>                   
                  {selectedPackageOption === 1 ? `${language === "English" ? "SELECTED PACKAGE" : "PACCHETTO SELEZIONATO"}` : `${language === "English" ? "SELECT":"SELEZIONA"}`}

                   </Text>
                </View>
</View>
<View style={{...styles.packagesFooterCard1,  marginTop:"110px"}}>
                
                <View style={{...styles.offer.div, marginLeft: language === "English" ?"20px" :"12px"}}>
                  <Text style={{ color:"grey", fontSize:"10px", fontWeight:"normal", fontStyle:"normal",
                   marginLeft:language === "English" ? "47px" : "53px"}}>
                  {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
                    </Text>
                    <Text style={{ color:"black", fontSize:"9px", fontWeight:"bold", fontStyle:"normal",
                     marginLeft:language === "English" ? "60px" : "65px"}}>
        {subtractDays("Premium", deliveryDateTime, orgPkgsOption === 2 ? 0 : orgPkgsOption === 1 ? 1 : 2, orgPkgsOption)}
                    </Text>
                  <Text style={{ color:"black", marginTop:"5px",fontWeight:"bold", fontStyle:"normal", fontSize:"12px", 
                  marginLeft:"74px"}}>
                  {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[2]))}
                    </Text>
                {packagesPrices?.premium?.length === 1 &&<Text style={{ color:"grey", fontSize:"10px", fontWeight:"normal", fontStyle:"normal",
                  marginLeft:language === "English" ? "55px":"63px"}}>
                {language === "English" ? `about ${fCurrency(packagesPrices?.premium[0])}/word` : `${fCurrency(packagesPrices?.premium[0])}/parola`}

                </Text>}
                   <Text style={{borderRadius:"3px",  color:selectedPackageOption === 2 ? "grey":"white", width:"140px",padding:"3px", alignItems:"center", textAlign:"center",
                   backgroundColor:selectedPackageOption === 2 ?"lightgrey" :"#103996", fontSize:"10px", fontWeight:"normal", fontStyle:"normal",
                   marginLeft:"35px"}}>                   
                  {selectedPackageOption === 2 ? `${language === "English" ? "SELECTED PACKAGE" : "PACCHETTO SELEZIONATO"}` : `${language === "English" ? "SELECT":"SELEZIONA"}`}

                   </Text>
                </View>
</View>
          </View>
{language === "English" ?<Image style={{width:"100%", marginTop:"-275px", zIndex:"-99"}} src="/translationAssets/packages/footer-cards.png"/> :
<Image style={{width:"100%", marginTop:"-270px", zIndex:"-99"}} src="/translationAssets/packages/footer-cards1.png"/>
}        </View> : <View style={{...styles.footer, backgroundColor:"white"}}>
<View style={{...styles.packagesFooterCards, display:"inline-flex", flexDirection:"row"}}>
         {selectedPackageOption === 0 && <View style={{...styles.packagesFooterCard1,  marginTop:"110px"}}>
                
                <View style={{...styles.offer.div, marginLeft: language === "English" ?"190px" :"190px"}}>
                  <Text style={{ color:"grey", fontSize:"10px", fontWeight:"normal", fontStyle:"normal", 
                  marginLeft: language === "English" ? "51px": "55px"}}>
                  {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
                    </Text>
                    <Text style={{ color:"black", fontSize:"9px", fontWeight:"bold", fontStyle:"normal", marginLeft:"65px"}}>
                    {formatDate(new Date(deliveryDateTime))}
                    </Text>
                  <Text style={{ color:"black", marginTop:"5px",fontWeight:"bold", fontStyle:"normal", fontSize:"12px", 
                  marginLeft:"76px"}}>
                  {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[0]))}
                  </Text>
                  {packagesPrices?.economy?.length ===1 &&<Text style={{ color:"grey", fontSize:"10px", fontWeight:"normal", fontStyle:"normal",
                   marginLeft:language === "English" ? "58px" : "65px"}}>
                  {language === "English" ? `about ${fCurrency(packagesPrices?.economy[0])}/word` : 
                  `${fCurrency(packagesPrices?.economy[0])}/parola`}

                  </Text>}
                  <Text style={{borderRadius:"3px",  color:"grey", width:"140px",padding:"3px", alignItems:"center", textAlign:"center",
                   backgroundColor:"lightgrey", fontSize:"10px", fontWeight:"normal", fontStyle:"normal",
                   marginLeft:"36px"}}>                   
                  {selectedPackageOption === 0 ? `${language === "English" ? "SELECTED PACKAGE" : "PACCHETTO SELEZIONATO"}` : `${language === "English" ? "SELECT":"SELEZIONA"}`}

                   </Text>
                </View>
</View>}
{selectedPackageOption === 1 &&<View style={{...styles.packagesFooterCard1,  marginTop:"110px"}}>
                
                <View style={{...styles.offer.div, marginLeft: language === "English" ?"190px" :"190px"}}>
                  <Text style={{ color:"grey", fontSize:"10px", fontWeight:"normal", fontStyle:"normal", 
                  marginLeft: language === "English" ? "51px": "55px"}}>
                  {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
                    </Text>
                    <Text style={{ color:"black", fontSize:"9px", fontWeight:"bold", fontStyle:"normal", marginLeft:"65px"}}>
                    {formatDate(new Date(deliveryDateTime))}
                    </Text>
                  <Text style={{ color:"black", marginTop:"5px",fontWeight:"bold", fontStyle:"normal", fontSize:"12px", 
                  marginLeft:"76px"}}>
                  {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[1]))}
                  </Text>
                  {packagesPrices?.professional?.length === 1 &&<Text style={{ color:"grey", fontSize:"10px", fontWeight:"normal", fontStyle:"normal",
                   marginLeft:language === "English" ? "58px" : "65px"}}>
                  {language === "English" ? `about ${fCurrency(packagesPrices?.professional[0])}/word` : 
                  `${fCurrency(packagesPrices?.professional[0])}/parola`}

                  </Text>}
                  <Text style={{borderRadius:"3px",  color:"grey", width:"140px",padding:"3px", alignItems:"center", textAlign:"center",
                   backgroundColor:"lightgrey", fontSize:"10px", fontWeight:"normal", fontStyle:"normal",
                   marginLeft:"36px"}}>                   
                  {selectedPackageOption === 1 ? `${language === "English" ? "SELECTED PACKAGE" : "PACCHETTO SELEZIONATO"}` : `${language === "English" ? "SELECT":"SELEZIONA"}`}

                   </Text>
                </View>
</View>}
{selectedPackageOption === 2 &&<View style={{...styles.packagesFooterCard1,  marginTop:"110px"}}>
                
                <View style={{...styles.offer.div, marginLeft: language === "English" ?"190px" :"190px"}}>
                  <Text style={{ color:"grey", fontSize:"10px", fontWeight:"normal", fontStyle:"normal", 
                  marginLeft: language === "English" ? "51px": "55px"}}>
                  {language === "English" ? "Delivery guaranteed by" : "Consegna garantita"}
                    </Text>
                    <Text style={{ color:"black", fontSize:"9px", fontWeight:"bold", fontStyle:"normal", marginLeft:"65px"}}>
                    {formatDate(new Date(deliveryDateTime))}
                    </Text>
                  <Text style={{ color:"black", marginTop:"5px",fontWeight:"bold", fontStyle:"normal", fontSize:"12px", 
                  marginLeft:"76px"}}>
                  {packagesNetTotals?.length > 0 && fCurrency(Number(packagesNetTotals[2]))}
                  </Text>
                 {packagesPrices?.premium?.length === 1 && <Text style={{ color:"grey", fontSize:"10px", fontWeight:"normal", fontStyle:"normal",
                   marginLeft:language === "English" ? "58px" : "65px"}}>
                  {language === "English" ? `about ${fCurrency(packagesPrices?.premium[0])}/word` : 
                  `${fCurrency(packagesPrices?.premium[0])}/parola`}

                  </Text>}
                  <Text style={{borderRadius:"3px",  color:"grey", width:"140px",padding:"3px", alignItems:"center", textAlign:"center",
                   backgroundColor:"lightgrey", fontSize:"10px", fontWeight:"normal", fontStyle:"normal",
                   marginLeft:"40px"}}>                   
                  {selectedPackageOption === 2 ? `${language === "English" ? "SELECTED PACKAGE" : "PACCHETTO SELEZIONATO"}` : 
                  `${language === "English" ? "SELECT":"SELEZIONA"}`}

                   </Text>
                </View>
</View>}
          </View>
          {selectedPackageOption === 0 && (<>
            {language === "English" ?<Image style={{width:"100%", marginTop:"-275px", zIndex:"-99"}} src="/translationAssets/packages/economy1.png"/> :
<Image style={{width:"100%", marginTop:"-270px", marginLeft:"-10px", zIndex:"-99"}} src="/translationAssets/packages/economy.png"/>
}
          </>)}
          {selectedPackageOption === 1 && (<>
            {language === "English" ?<Image style={{width:"100%", marginTop:"-275px", zIndex:"-99"}} src="/translationAssets/packages/professional1.png"/> :
<Image style={{width:"100%", marginTop:"-270px",marginLeft:"10px", zIndex:"-99"}} src="/translationAssets/packages/professional.png"/>
}
          </>)}
          {selectedPackageOption === 2  && (<>
            {language === "English" ?<Image style={{width:"100%", marginTop:"-275px", zIndex:"-99"}} src="/translationAssets/packages/premium1.png"/> :
<Image style={{width:"100%", marginTop:"-270px", zIndex:"-99"}} src="/translationAssets/packages/premium.png"/>
}
          </>)}

  </View>}
        <Image 
      style={{width:"100%", objectFit:"cover", zIndex:"-99",marginTop:"-585px",marginBottom:"-150px", height:"740px"}}  
      src={'/translationAssets/packages/footer_cards.jpg'}/>     
      </Page>
    );
}

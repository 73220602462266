import React from 'react';
import { styles } from '../globalStyles';
import { Image, Text, View } from '@react-pdf/renderer';
import { icons } from '../icons';

const Header = ({ language = "Italian", accountManager, companyNameAndId, bookedBy }) => {
  return ( 
   <>
   <View style={styles.header}>
          <View style={styles.headerItem}>
            <Text style={styles.headerItemText}>{language === 'English' ? `Quote` : `Preventivo`}</Text>
          </View>
          <View>
            <Image style={styles.headerRightImg} src="/assets/desktop6/group-1000001719.png" />
          </View>
          <View style={styles.headerItem} />
        </View>
        <View style={styles.tableCustomer}>
          <View style={styles.tableCustomerHeader}>
            <View style={styles.tableCustomerItem}>
              <Text style={styles.tableCustomerText}>{language === 'English' ? `Customer Billing Data` : `Dati Fatturazione Cliente`}</Text>
            </View>
            <View style={styles.tableCustomerItem}>
              <Text style={styles.tableCustomerText}>{accountManager?.jobTitle}</Text>
            </View>
          </View>
          <View style={styles.tableCustomerBody}>
            <View style={styles.tableCustomerItem}>
              <Text>{companyNameAndId?.name}</Text>
              <Text>{bookedBy?.firstName} {bookedBy?.lastName}</Text>
              <View style={{ display: "flex", flexDirection: "row", gap: '5px', width:"170px" }}>
                <Text style={{...styles.bold}}>{language === 'English' ? `Address:` : `Indirizzo:`}</Text>
                <View style={{marginLeft:"5px"}}>
                  {companyNameAndId?.address1 && (
                    <Text>{companyNameAndId?.address1}</Text>
                  )}
                  {companyNameAndId?.address2 && (
                    <Text>{companyNameAndId?.address2}</Text>
                  )}
                  <Text>{companyNameAndId?.postcode} {companyNameAndId?.city}</Text>
                </View>
              </View>
              <View style={{ display: "flex", flexDirection: "row", gap: '5px' }}>
                <Text style={styles.bold}>P. IVA:</Text>
                <Text>{companyNameAndId?.VAT}</Text>

              </View>
            </View>
            <View style={{...styles.tableCustomerItem, marginLeft:"10px"}}>
              <Text>{accountManager?.name}</Text>
              <Text>E-mail: info@lingoyou.com</Text>
              <View style={{ display: "flex", flexDirection: "row", gap: '5px' }}>
                <Text>Telefono:(+39)</Text>
                <Text style={styles.bold}>0282957101</Text>
              </View>
              <View style={{ display: "flex", flexDirection: "row", gap: '5px' }}>
                <Text>(+39)</Text>
                <Text style={styles.bold}>3757441330</Text>
              </View>
            </View>
          </View>
        </View>
   </>
  );
};

export default Header;
import React from 'react'
import styles from '../styles/UseCases.module.css'
import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

const usecases = [
    {
        img:'/rafiky/brochure/case1.jpg',
        it:{
            heading:'Business Meetings',
            list:[
                'Meeting multilingue con partner stranieri – interpretariato simultaneo da remoto.',
                'Possibilità di avere fino a 30 partecipanti attivi collegati contemporaneamente.',
                'Possibilità di registrare le riunioni.',
                'Supporto tecnico incluso nel pacchetto.',
            ]
        },
        en:{
            heading:'Business Meetings',
            list:[
                'Multilingual meeting with foreign partners – remote simultaneous interpretation.',
                'Possibility of having up to 30 participants connected at the same time.',
                'Possibility of recording meetings.',
                'Technical support included in the package.',
                ]
        }
    },
    {
        img:'/rafiky/brochure/case2.jpeg',
        it:{
            heading:'Webinars',
            list:[
                'Semplicità di presentazione di contenuti sul proprio schermo.',
                'Streaming video.',
                'Diverse rooms per ogni conferenza con frammentazione delle tematiche.',
                'Comunicazione testuale via chat.',
                'Creazione e presentazione di quiz e questionari.'
            ]
        },
        en:{
            heading:'Webinars',
            list:[
                'Ease of presentation of content on your screen.',
                'Video streaming.',
                'Different rooms for each conference with fragmentation of the themes.',
                'Textual communication via chat.',
                'Creation and presentation of quizzes and questionnaires.'
                ]
        }
    },
    {
        img:'/rafiky/brochure/case3.jpg',
        it:{
            heading:'On-Site Conferences',
            list:[
                'Risparmio del 50% sull’attrezzatura tecnica (nessuna cabina necessaria).',
                'Ideale per sale in cui l’installazione delle cabine non è possibile/richiede sforzi e costi maggiori.',
                'Regia audio-video ed assistenza tecnica in loco.',
            ]
        },
        en:{
            heading:'On-Site Conferences',
            list:[
                '50% savings on technical equipment (no booths required).',
                'Ideal for rooms where installing booths is not possible/requires greater effort and cost.',
                'Audio-video direction and technical assistance on site.',
                ]
        }
    },
    {
        img:'/rafiky/brochure/case4.jpeg',
        it:{
            heading:'Press Conferences',
            list:[
                'Ampia scelta della posizione per i relatori ed i partecipanti.',
                'Partecipazione globale.',
                'Numero flessibile di partecipanti.',
                'Moderazione delle domande dei giornalisti.',
                'Registrazione della conferenza.',
                'Nessun requisito tecnico particolare.'
            ]
        },
        en:{
            heading:'Press Conferences',
            list:[
                'Wide choice of positions for speakers and participants.',
                'Global participation.',
                'Flexible number of participants',
                `Moderation of journalists' questions.`,
                'Conference recording.',
                'No special technical requirements.'
                ]
        }
    }
]


export default function UseCases() {
    const { language} = useSelector(state => state.proposal)

  return (
    <div>
        <section className={styles.header}>
            <span>USE CASES</span>
            <img src='/rafiky/logo.webp' alt='logo'/>
        </section>
        <Grid container spacing={3} sx={{margin:"25px", paddingBottom:"30px"}}>
           {usecases.map((item)=><Grid className={styles.card} item xs={12} md={6}>
           <div>
           <section>
            <Typography variant='h3' sx={{marginBottom:"10px", textUnderlinePosition:"under", textDecoration:"underline", textDecorationColor:"#8316d3"}}>
            {language === 'English' ? item?.en.heading : item?.it.heading}
            </Typography>
            <ul>
            {language === 'English'
              ? item.en.list.map((listItem) => (
                <li>{listItem}</li>
                ))
              : item.it.list.map((listItem) => (
                <li>{listItem}</li>
                ))}
            </ul>
            </section>
           
            <img alt='usecase1' src={item.img}/>
           </div>
          
           </Grid>)}
           
        </Grid>
    </div>
  )
}

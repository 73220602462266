
  export const optionsArea = [
    'Advertising',
    'Aerospace engineering',
    'Agricultural engineering',
    'Agriculture',
    'Anthropology',
    'Archaeology',
    'Architectural engineering',
    'Architecture',
    'Automotive engineering',
    'Biomechanical engineering',
    'Biomedical engineering',
    'Broadcast journalism',
    'Business',
    'Ceramic engineering',
    'Chemical engineering',
    'Chemistry',
    'Civil engineering',
    'Computer engineering',
    'Computer science',
    'Control systems engineering',
    'Cultural and ethnic studies',
    'Distributed computing',
    'Divinity',
    'Family and consumer science',
    'Food engineering',
    'Geography',
    'Hardware Design',
    'Health science',
    'History',
    'Industrial engineering',
    'Information retrieval',
    'Instrumentation engineering',
    'Interaction Design',
    'Journalism, media studies and communication',
    'Language studies',
    'Law',
    'Library and museum studies',
    'Life sciences',
    'Linguistics',
    'Literary journalism',
    'Literature',
    'Machine Learning',
    'Manufacturing engineering',
    'Marine engineering',
    'Marketing',
    'Materials engineering',
    'Mathematics',
    'Mechanical engineering',
    'Medicine',
    'Nanoengineering',
    'Newspaper journalism',
    'Nuclear engineering',
    'Nursing',
    'Ocean engineering',
    'Optical engineering',
    'Performing arts',
    'Petroleum engineering',
    'Philosophy',
    'Physics',
    'Political science',
    'Polymer engineering',
    'Print journalism',
    'Product Design',
    'Psychology',
    'Quality assurance engineering',
    'Religion',
    'Technical writing',
    'Telecommunications engineering',
    'Translation and Interpreting',
    'Translation',
    'Transportation',
    'Transportation engineering',
    'Vehicle engineering',
    'Visual Arts',
    'Visual Design',
    'Wireless Communications'
  ];
import { Grid } from '@mui/material';
import styles from '../styles/Quality.module.css';
import { useSelector } from 'react-redux';
import { PATH_AUTH } from 'src/routes/paths';
import { FaLock } from "react-icons/fa";

const Quality = () => {
  const { language, quoteId } = useSelector((state) => state.proposal);

  if (language === 'English') {
    return (
      <div className={styles.desktop10}>
        <div className={styles.desktop10Child} />
        {/* <div className={styles.div}>06</div> */}
        <div className={styles.quality}>QUALITY</div>
        <div className={styles.text3543Parent}>
          <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{' '}
        </div>
        <div className={styles.detail1}>
          By setting ourselves the goal of achieving a high degree of customer satisfaction, in LingoYou we are
          committed to the highest quality standards in the provision of services translation and interpreting.
          <p>
            <ul>
              <li>
                We comply and respond to the needs and times of the Customer through clear and transparent
                communication.
              </li>
              <li>We respond to each request within a maximum of 8 working hours.</li>
              <li>We ensure consistency and accuracy at every stage of the project.</li>
              <li>
                We consider feedback and suggestions to monitor the level of customer satisfaction and improve every
                day.
              </li>
              <li>We guarantee maximum professionalism and high-quality standards.</li>
              <li>We ensure that quality objectives are compatible with the company's strategic direction.</li>
              <li>
                We constantly evaluate risks and opportunities in order to improve our procedures and consequently
                customer satisfaction.
              </li>
            </ul>
          </p>
          <p>
            Our quality policy is periodically reviewed to ensure compliance with the context and with a view to
            continuous improvement of our actions, procedures, and operations through the implementation of
            administration systems consistent with ISO 9001 requirements.
          </p>
        </div>
        <div className={styles.privacyBtn}>
          <a
            href={`${process.env.REACT_APP_URL}${PATH_AUTH.quotePrivacyClientView(quoteId, 'English')}`}
            target="_blank"
          >
          <FaLock style={{color: "white", marginRight: "10px", marginTop: "25px"}} />
 Privacy & Cyber Security
          </a>
        </div>
        <Grid container spacing={3} className={styles.logos}>
          <Grid item xs={12} sm={6} md={4}>
            <img src="/translationAssets/Quality/iso.webp" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <img src="/translationAssets/Quality/iti.webp" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <img src="/translationAssets/Quality/aiti.webp" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <img src="/translationAssets/Quality/atc.webp" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <img src="/translationAssets/Quality/euatc.webp" />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <img src="/translationAssets/Quality/aisl.webp" />
          </Grid>
          <Grid item md={1.3}></Grid>
        </Grid>
        
      </div>
    );
  }
  return (
    <div className={styles.desktop10}>
      <div className={styles.desktop10Child} />
      {/* <div className={styles.div}>06</div> */}
      <div className={styles.quality}>QUALITY</div>
      <div className={styles.text3543Parent}>
        <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{' '}
      </div>
      <div className={styles.detail1}>
        Ponendoci come obiettivo il raggiungimento di un elevato grado di soddisfazione del Cliente, in LingoYou ci
        impegniamo a garantire i più alti standard di qualità nella fornitura dei servizi di traduzione ed
        interpretariato.
        <p>
          <ul>
            <li>
              Ci conformeremo e risponderemo alle esigenze e ai tempi del Cliente attraverso una comunicazione chiara e
              trasparente.
            </li>
            <li>Risponderemo ad ogni richiesta entro massimo 8 ore lavorative.</li>
            <li>Garantiremo coerenza e accuratezza in ogni fase del progetto.</li>
            <li>
              Terremo in considerazione feedback e suggerimenti per monitorare il livello di soddisfazione del Cliente e
              migliorarci ogni giorno.
            </li>
            <li>Garantiremo massima professionalità e alti standard di qualità.</li>
            <li>
              Faremo in modo che gli obiettivi di qualità siano compatibili con la direzione strategica dell'azienda.
            </li>
            <li>
              Valuteremo costantemente rischi e opportunità al fine di migliorare le nostre procedure e di conseguenza
              la soddisfazione dei clienti.
            </li>
          </ul>
        </p>
        <p>
          La nostra politica della qualità viene rivista periodicamente per garantirne l’adeguamento con il contesto e
          in direzione del miglioramento continuo delle nostre azioni, procedure ed operazioni attraverso
          l'implementazione di sistemi di amministrazione coerenti con i requisiti ISO 9001.
        </p>
      </div>
      <div className={styles.privacyBtn}>
          <a
            href={`${process.env.REACT_APP_URL}${PATH_AUTH.quotePrivacyClientView(quoteId, 'English')}`}
            target="_blank"
          >
             <FaLock style={{color: "white", marginRight: "10px", marginTop: "25px"}} />
 Privacy & Cyber Security
          </a>
        </div>
      <Grid container spacing={3} className={styles.logos}>
        <Grid item xs={12} sm={6} md={4}>
          <img src="/translationAssets/Quality/iso.webp" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <img src="/translationAssets/Quality/iti.webp" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <img src="/translationAssets/Quality/aiti.webp" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <img src="/translationAssets/Quality/atc.webp"  />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <img src="/translationAssets/Quality/euatc.webp" />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <img src="/translationAssets/Quality/aisl.webp" />
        </Grid>
        <Grid item md={1.3}></Grid>
      </Grid>
      
    </div>
  );
};

export default Quality;

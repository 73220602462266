import React from 'react';
import styles from '../styles/Discount.module.css';
import { useSelector } from 'react-redux';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';

export default function Discount() {

  const { language} = useSelector(state => state.proposal)

  return (
    <div className={styles.desktop13}>
     <div className={styles.mainVector1}>
        <div className={styles.vector1}></div>
        <div className={styles.vector2}></div>
        <div className={styles.vector3}></div>
        <div className={styles.vector4}></div>
        <div className={styles.vector5}></div>
        <div className={styles.vector6}></div>
      </div> 
      <div className={styles.discount}>
        <img src="/assets/desktop13/discount.png" />
      </div>
      <div
            className={styles.laptop}
            style={{backgroundImage:"url('/assets/desktop9/17908319-2@2x.webp')",
              width: "700px",
              height: "40%",
              backgroundRepeat: "no-repeat",
              backgroundSize: "contain",
              top: "20%"
            }}
          >
              <div style={{ 
              position: "relative",
              display: "block",
              contain: "inherit",
              backgroundPosition: "center",
              backgroundSize: "cover",
              width: "490px",
              left: "70px",
              top: "40px",
              height: "320px"}}>
            <LiteYouTubeEmbed 
            aspectHeight={10}
        id="ZvecBiunr5I"
        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
    />
            </div>
          
         </div>
      {language === 'English' ? (
        <div className={styles.text}>
          <p>
          You can save up to 60% using the most advanced technology in the world of interpreting. The integration of our hardware with the most modern software and latest generation technologies can promote an important reduction in costs for the organization of your event. How?
          </p>{' '}
          <ul>
            <li>
            	Interpreters connected remotely via a virtual interpreting console (web-based). It won't be anymore necessary to provide for the time-consuming and expensive installation of cabins in your location.
            </li>
            <li>
            	No more commuting: no more extra expenses for travel/accommodation will be needed interpreters.
            </li>{' '}
            <li>
            	Possibility for all participants to listen to the interpretation via App and/or receiver present in room.
            </li>
          </ul>
        </div>
      ) : (
        <div className={styles.text}>
          <p>
            Potrete risparmiare fino al 60% utilizzando la tecnologia più avanzata nel mondo dell'interpretariato.
            L'integrazione del nostro hardware con i software più moderni e con tecnologie di ultima generazione può
            favorire un'importante riduzione dei costi per l'organizzazione del Vostro evento. Come?
          </p>{' '}
          <ul>
            <li>
              Interpreti collegati da remoto tramite consolle di interpretariato virtuale (web-based). Non sarà più
              necessario provvedere alla lunga e dispendiosa installazione di cabine nella Vostra location.
            </li>
            <li>
              Niente più spostamenti: non sarà più necessario sostenere spese extra per viaggi/alloggio degli
              interpreti.
            </li>{' '}
            <li>
              Possibilità per tutti i partecipanti di ascoltare l'interpretariato tramite App e/o ricevitore presente in
              sala.
            </li>
          </ul>
        </div>
      )}
    </div>
  );
}

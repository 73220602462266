import { Avatar, Box,  FormControlLabel, Radio, Typography } from '@mui/material';
import React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Table, TableRow, TableBody, TableHead, TableCell, TableContainer } from '@mui/material';
import Scrollbar from './Scrollbar';
import styles from '../styles/AboutUs2.module.css';
import styles1 from '../styles/TablesItems.module.css';
import { fCurrency } from 'src/utils/formatNumber';
import { useDispatch, useSelector } from 'react-redux';
import { addOption } from 'src/redux/slices/proposal';
import Table3 from './Table/Table3';
import TableHeader from './Table/TableHeader';
import Table1 from './Table/Table1';
import Table2Items from './Table/Table2Items';

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));

function TablesItems() {
  const { language, option, details, items1Translation, service1Translation, items2Translation, service2Translation } = useSelector(state => state.proposal)
const {
  interpretationItem:items,
  netTotal,
  additionalService:services,
  subtotal:subTotal,
  taxes,
  totalDiscount,
  grandTotal,
  interpretationItem2:items2,
  additionalService2:services2,
  netTotalOptions: netTotalOption2,
  subtotalOptions:subTotalOption2,
  taxesOptions:taxesOption2,
  totalDiscountOptions:totalDiscountOption2,
  grandTotalOptions: grandTotalOption2,
  notes,
  notesOptions,
  brand
} = details

  const dispatch = useDispatch()
  const totalLength = items.length + services.length;
  const totalLength2 = items2.length + services2.length;

  return (
    <>
      <Box
        sx={{
          display: 'flow-root',
          height: `${totalLength > 1 ? 'auto' : 'auto'}`,
          position: 'relative',
          paddingBottom: '20px',
          backgroundColor: 'white',
        }}
      >
        <div className={styles.desktop8Item} />
        {/* <div className={styles.div}>17</div> */}
        <div className={styles.aboutUs} style={{top:"15px", textTransform:"uppercase", fontWeight:"bold"}}>{language === 'English' ? `Quote` : `Preventivo`}</div>
        <div className={styles.text3543Parent}>
        {brand && brand?.rafiky ? <img className={styles1.rafikyImg}
        alt=""
        src="/rafiky/logo.webp"
      /> :  <img alt="" src="/assets/desktop6/group-1000001719.svg" />}
        </div>
        <div className={styles1.tables}>
         <Table1/>
          <div
            className={styles1.table2}
            style={{
              marginBottom: '4em',
              padding: '0px',
              marginLeft: '0px',
              marginInline:"auto",
             marginTop:"3em",
             boxShadow: `${totalLength2 > 0 ?"0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)":"none"}`,           
             border:`${option === 1 ?`2px solid #2da0f0`: totalLength2 > 0 ?`2px solid #e5e8eb`:"none"}`,       
             borderRadius: "15px",
            }}
          >
           {totalLength2 > 0 && (
             <FormControlLabel sx={{marginBottom:"20px",marginLeft:"30px", "&.span": {fontSize:"large"},
              borderRadius: "10px",
               padding: "10px", marginRight:"10px", 
               width:"-webkit-fill-available", justifyContent:"space-between"}}
          value={`${language === "English" ? "Select Option":"Seleziona Opzione"} 01`}
          control={<Radio  checked={option === 1 ? true : false} onClick={()=>dispatch(addOption(1))} />}
          label={<span style={{fontSize:"large",  color:`${option === 1 ?`#2da0f0`:`none`}`}}>{`${language === "English" ? "Select Option":"Seleziona Opzione"}`} 01</span>}
          labelPlacement="start"
        />
           )}
            {/* <Card sx={{ display: 'contents' }}> */}
              <Scrollbar>
                <TableContainer sx={{ marginLeft: '2%', width: '97%' }}>
                  <Table sx={{ width: '100%', borderCollapse: 'inherit' }}>
                   <TableHeader/>

                    <TableBody>
                      {items &&
                        items.map((row, index) => (
                          <Table2Items row={row} index={index} itemsTranslation={items1Translation ? items1Translation[index]: ''} />
                        ))}
                      {services &&
                        services.map((row, index) => (
                          <Table2Items row={row} index={index} itemsTranslation={service1Translation ? service1Translation[index]: ''} />
                        ))}

                      <RowResultStyle sx={{ borderBottom: 'none' }}>
                        <TableCell
                          colSpan={5}
                          sx={{
                            borderBottom: 'none',
                            borderTop: '1.29922px solid #2DA0F0',
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 'large',
                          }}
                          align="left"
                        >
                          {notes && notes !== '' && notes !== '<p><br></p>' && (
                            <>
                              <Box sx={{ mt: 2 }} />
                              <Typography
                                sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                              >
                                {language === 'English' ? "Notes" : "Note"}
                              </Typography>
                              <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '15px' }}>
                              <div dangerouslySetInnerHTML={{ __html: notes }}></div>
                              </Typography>
                            </>
                          )}
                        </TableCell>
                      </RowResultStyle>
                      <RowResultStyle sx={{ borderBottom: 'none' }}>
                        <TableCell sx={{ borderBottom: 'none' }} align="left">
                          <Box sx={{ mt: 2 }} />
                          <Typography
                            sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                          >
                                      {language === 'English' ? 'Subtotal' : 'Subtotale'}
 
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }} />
                        <TableCell sx={{ borderBottom: 'none' }} />

                        <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                          <Typography
                            sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                          >
                            &nbsp;{fCurrency(Number(subTotal))}
                          </Typography>
                        </TableCell>
                      </RowResultStyle>
                      {totalDiscount ? (
                        <RowResultStyle>
                          <TableCell sx={{ borderBottom: 'none' }} align="left">
                            <Typography
                              sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                            >
                              {language === 'English' ? 'Discount' : 'Sconto'}  ({totalDiscount ? totalDiscount : 0}%)
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ borderBottom: 'none' }} />
                          <TableCell sx={{ borderBottom: 'none' }} />
                          <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                            <Typography
                              sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                            >
                               &nbsp;{fCurrency(Number((Number(subTotal) * Number(totalDiscount)) / 100))}
                            </Typography>
                          </TableCell>
                        </RowResultStyle>
                      ) : (
                        ''
                      )}
                      <RowResultStyle>
                        <TableCell sx={{ borderBottom: 'none' }} align="left">
                          <Typography
                            sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                          >
{language === 'English' ? 'Net Total' : 'Totale netto'}                          
</Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }} />
                        <TableCell sx={{ borderBottom: 'none' }} />
                        <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                          <Typography
                            sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                          >
                             &nbsp;{fCurrency(netTotal)}
                          </Typography>
                        </TableCell>
                      </RowResultStyle>
                      <RowResultStyle>
                        <TableCell sx={{ borderBottom: 'none' }} align="left">
                          <Typography
                            sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                          >
                            IVA ({taxes}%)
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }} />
                        <TableCell sx={{ borderBottom: 'none' }} />

                        <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                          <Typography
                            sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                          >
                             &nbsp;{fCurrency(Number((Number(netTotal) * Number(taxes)) / 100))}
                          </Typography>
                        </TableCell>
                      </RowResultStyle>

                      <RowResultStyle>
                        <TableCell sx={{ borderBottom: 'none' }} align="left">
                          <Typography
                            sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                          >
                          {language === "English" ? "Total Gross" : "Totale Lordo"}
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }} />
                        <TableCell sx={{ borderBottom: 'none' }} />
                        {grandTotal ? (
                          <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                            <Box sx={{ mt: 2 }} />
                            <Typography
                              sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                            >
                               &nbsp;{fCurrency(Number(grandTotal))}
                            </Typography>
                          </TableCell>
                        ) : (
                          <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                            <Typography
                              sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                            >
                               &nbsp;{fCurrency((Number(subTotal) + (Number(subTotal) * Number(taxes)) / 100))}
                            </Typography>
                          </TableCell>
                        )}
                      </RowResultStyle>
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
           
            {/* </Card> */}
          </div>
          {(items2?.length + services2?.length) > 0 && items2?.length > 0 && items2?.map((item, index)=>(  
             <div
             className={styles1.table2}
             style={{
               marginBottom: '4em',
               padding: '0px',
               marginLeft: '0px',
               marginInline:"auto",
               marginTop:"3em",
               boxShadow: `${option === index+2 ?"0 0 2px 0 rgba(145, 158, 171, 0.2), 0 12px 24px -4px rgba(145, 158, 171, 0.12)":"none"}`,           
               border:`${option === index+2 ?`2px solid #2da0f0`:`2px solid #e5e8eb`}`,     
               borderRadius: "15px",
            }}
           >
            <FormControlLabel sx={{marginBottom:"20px",marginLeft:"30px", "&.span": {fontSize:"large"},
              borderRadius: "10px",
               padding: "10px", marginRight:"10px", 
               width:"-webkit-fill-available", justifyContent:"space-between"}}
               value={`${language === "English" ? "Select Option":"Seleziona Opzione"} 0${index+2}`}
               control={<Radio checked={option === index+2 ? true : false} onClick={()=>dispatch(addOption(index+2))} />}
               label={<span style={{fontSize:"large", color:`${option === index+2 ?`#2da0f0`:`none`}` }}>{`${language === "English" ? "Select Option":"Seleziona Opzione"}`} 0{index+2}</span>}
               labelPlacement="start"
        />
   
             {/* <Card sx={{ display: 'contents' }}> */}
               <Scrollbar>
                 <TableContainer sx={{ marginLeft: '2%', width: '97%' }}>
                   <Table sx={{ width: '100%', borderCollapse: 'inherit' }}>
                     <TableHeader/>
                     <TableBody>
                       {items2[index]?.length > 0 &&
                         items2[index].map((row, ind) => (
                          <Table2Items row={row} index={ind} itemsTranslation={items2Translation?.length > 0 ? items2Translation[index][ind]: ''} />
                         ))}
                       {services2[index]?.length > 0 &&
                         services2[index]?.map((row, ind) => (
                          <Table2Items row={row} index={ind} itemsTranslation={service2Translation?.length > 0 ? service2Translation[index][ind]: ''} />
                         ))}
 
                       <RowResultStyle sx={{ borderBottom: 'none' }}>
                         <TableCell
                           colSpan={5}
                           sx={{
                             borderBottom: 'none',
                             borderTop: '1.29922px solid #2DA0F0',
                             fontFamily: "'Poppins', sans-serif",
                             fontSize: 'large',
                           }}
                           align="left"
                         >
                           {notesOptions[index] && notesOptions[index] !== '' && notesOptions[index] !== '<p><br></p>' && (
                             <>
                               <Box sx={{ mt: 2 }} />
                               <Typography
                                 sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                               >
                                 {language === 'English' ? "Notes" : "Note"}
                               </Typography>
                               <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '15px' }}>
                               <div dangerouslySetInnerHTML={{ __html: notesOptions[index] || '' }}></div>
                               </Typography>
                             </>
                           )}
                         </TableCell>
                       </RowResultStyle>
                       <RowResultStyle sx={{ borderBottom: 'none' }}>
                         <TableCell sx={{ borderBottom: 'none' }} align="left">
                           <Box sx={{ mt: 2 }} />
                           <Typography
                             sx={{color:`${option === index+2 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                           >
                             {language === "English" ? "Subtotal" : "Subtotale"}
                           </Typography>
                         </TableCell>
                         <TableCell sx={{ borderBottom: 'none' }} />
                         <TableCell sx={{ borderBottom: 'none' }} />
 
                         <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                           <Typography
                             sx={{color:`${option === index+2 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                           >
                              &nbsp;{fCurrency((Number(subTotalOption2[index])))}
                           </Typography>
                         </TableCell>
                       </RowResultStyle>
                       {totalDiscountOption2[index] ? (
                         <RowResultStyle>
                           <TableCell sx={{ borderBottom: 'none' }} align="left">
                             <Typography
                               sx={{color:`${option === index+2 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                             >
                               {language === "English" ? "Discount " : "Sconto "} ({totalDiscountOption2[index] ? totalDiscountOption2[index] : 0}%)
                             </Typography>
                           </TableCell>
                           <TableCell sx={{ borderBottom: 'none' }} />
                           <TableCell sx={{ borderBottom: 'none' }} />
                           <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                             <Typography
                               sx={{color:`${option === index+2 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                             >
                                &nbsp;{fCurrency((Number(subTotalOption2[index]) * Number(totalDiscountOption2[index])) / 100)}
                             </Typography>
                           </TableCell>
                         </RowResultStyle>
                       ) : (
                         ''
                       )}
                       <RowResultStyle>
                         <TableCell sx={{ borderBottom: 'none' }} align="left">
                           <Typography
                             sx={{color:`${option === index+2 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                           >
                             {language === "English" ? "Net Total" : "Totale netto"}
                           </Typography>
                         </TableCell>
                         <TableCell sx={{ borderBottom: 'none' }} />
                         <TableCell sx={{ borderBottom: 'none' }} />
                         <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                           <Typography
                             sx={{color:`${option === index+2 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                           >
                              &nbsp;{fCurrency(netTotalOption2[index])}
                           </Typography>
                         </TableCell>
                       </RowResultStyle>
                       <RowResultStyle>
                         <TableCell sx={{ borderBottom: 'none' }} align="left">
                           <Typography
                             sx={{color:`${option === index+2 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                           >
                             IVA ({taxesOption2[index]}%)
                           </Typography>
                         </TableCell>
                         <TableCell sx={{ borderBottom: 'none' }} />
                         <TableCell sx={{ borderBottom: 'none' }} />
 
                         <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                           <Typography
                             sx={{color:`${option === index+2 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                           >
                              &nbsp;{fCurrency((Number(netTotalOption2[index]) * Number(taxesOption2[index])) / 100)}
                           </Typography>
                         </TableCell>
                       </RowResultStyle>
 
                       <RowResultStyle>
                         <TableCell sx={{ borderBottom: 'none' }} align="left">
                           <Typography
                             sx={{color:`${option === index+2 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                           >
                             {language === "English" ? "Total Gross" : "Totale Lordo"}
                           </Typography>
                         </TableCell>
                         <TableCell sx={{ borderBottom: 'none' }} />
                         <TableCell sx={{ borderBottom: 'none' }} />
                         {grandTotalOption2[index] ? (
                           <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                             <Box sx={{ mt: 2 }} />
                             <Typography
                               sx={{color:`${option === index+2 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                             >
                                &nbsp;{fCurrency(grandTotalOption2[index])}
                             </Typography>
                           </TableCell>
                         ) : (
                           <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                             <Typography
                               sx={{color:`${option === index+2 ?`#2da0f0`:`black`}`, fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                             >
                                &nbsp;{(fCurrency(subTotalOption2[index]) + (Number(subTotalOption2[index]) * Number(taxesOption2[index])) / 100)}
                             </Typography>
                           </TableCell>
                         )}
                       </RowResultStyle>
                     </TableBody>
                   </Table>
                 </TableContainer>
               </Scrollbar>
            
             {/* </Card> */}
           </div>
          ))}
         <Table3/>
        </div>
      </Box>
    </>
  );
}

export default TablesItems;

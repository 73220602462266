export const icons = {
  customerService: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAClUlEQVR4nO2ZS2sUQRSFv4Q4igYlBkUl4PgbFFz4IGShohJcCupGXKpZulRcZOIyYjau9CcIkux84ztGdKfgAzOj7jSokERtueEMNGMPPZPurn4wBwqmuqvOPbd6qm7VLeggEJuBE8B14BHwDvipYr8fAteA42qbKXQBh4A7gNdmuQ0cFEeq2A289gmbA24AZ4CdQBlYrVLWs7NqM+fr9wrYlYYDK4ALwG8JeQ+cA9a1wdELjAAfxPEHGAdWJqj7PwG3ZHwRGANKEfh6NAjz4rwPrCVh9APPZPAjsD1G7h3AJ3E/AfpICDZyD2ToDbA1ARtl4K1s3JPN2HFJBmaBTSSHLUBNtkbjJh8C/gILjlaXvZp/tgAMxkVqa/xTjdB53OGibD6Oi/CICO1zr8EdeoEvsn04DsK7IrMg5xojsm3LfSRsVNCzubEe9+hTfDENG6IQndKITJIepqThZBSSHo3EAOlhQBoSiSm5QUkB0Faqagz7qdR0jAWcHSrJ6U1ORy2AwJ65Rm05Ouz4WUezE51reCE6/JqXMKi9TasEruCF6FjUvmwJ3cB0Q4O8OOIBL+UDB5o0yIsjHrDPKhMFcOSyVWYK4MgLq3wtgCOf8WUv8uzIPL7DS54dqRVljkxb5UoBHBm3yv6ixJFu4HmOHZmpR3a0X1log8AVvBAdpnlPY6djbRC4gheiw685ENWAzpYqdY1qVB2VAILY87AudJREUtUIjKZ4Zq9kQEcHjdnOfl335RqTmhunyTGG5cS3NHLP/qRaUAxoNdE2DPxQH7vSzkRSzWsh0bYK2AYc9f2drFwlJQQFsWZfpn6hE/T+u+5HurLuyGyDI790PX1Tl0pp3MeERmMvIzuFZUfjZl+iE6ELj39a7dESp7S2JgAAAABJRU5ErkJggg==",
  translation: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAACXBIWXMAAAsTAAALEwEAmpwYAAABwElEQVR4nO2Yv0vDUBDHP4oOSlsEN3ESnHQRndrRf8FWEXF001mK/4CDWN38CwpFrYIIgi4i1eIiDq6Ck4OTvzpJJHCFxyNNNe9qwOYLB8m9x90n7yV3SSBRokSRNQnsAJWIVgaKQKpTcA3AU7AboE8bcFcJzhPLagNWjOBRlTdi+Meq+heA+R+OJ4BBKxJmsa3gqCQKslvg0gL5c8ARSdQbMP8KKFk+E3Ar5OJyUepkEOCMnE9ZcweAT2A5BLCd1YG0K2APcA9cAP2GfwN4BYYNXwao/bKYF10BfU0Db8AZMA6sA1/AqiOcJ73b+SFZAY6swHcy5gLnSU4nwLxs5RNwCmwC58CzjLnAqQCGKQNcWwkb4m/qOC7ATACcb1Vr3pImYNkqsmFWa5Fw0QBrXkhDC7DocC95ApKW0uTfs0MSt6oFmJI34aiAh0ZZau4CsqoqgEj7yYZsrd++WiVbkBizwB5QkPO0dB0VwHZq1co+2nwoHcQNuG88FOZX3pj45+MGLMj4nOVfE/8g8B4n4Ikke7D8j8ZqvsQJ6EW07gPMKQNuawP6dbKuBOfXxgk6oJS0xbLDT6ZSp+ASJeoKfQNpMdou8r/nawAAAABJRU5ErkJggg==",
  contactSupport: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADC0lEQVR4nO2aS2gUQRCGv10xCG48igoiiBdFvagxPlAk6EFEwYt6EYIPcCWJYmBXcAUPelGPekg0B28imGsQH5ig4COCD3yuKCqCGD0I2eBqEmmohabJzPbM9OxD9odiCdtTVd92T3dNTaChhupKh4EiMGnYGLCHOtLgFBAl66eO9N0HJE+daLYPhLJxIFXNBJPASeAs0OwzbmMZEGWrfa5vlhg5IOEaQjm8qCXyEVjvMTZtAbLP49qVwFtt3GX5AZ1IOeqdIpk/wAlgmoxTn1uA+xYggzJzpSTVZ9Zjp+txAeMFYSbVBXywADDtFXAQuFVmXG9UmGyI5OKyTBSQzP8CMh14WAMQT4AmImoxUKgixBiwDEfqDBj8pmzDa4ElQAuwF7jqsTP5WSeOz5EbFkFHgM1lfCmwF5YQA3EcikfLBFXnSmuAMuarBUi7awh1038qE/SScc0OORy/yFJbZ3x/yALkmesZ2WYRVF9Samb+Gt//AuZpY+ZbLq/WqMnPAdqADuCRRUBVey0U6/EYs1PzP8MS5K7M3iZZklZKS8nxw9HWadoKLdaikD5GJEeVq6d+xwSgrM+I1R3RX9EPZCIGgIIUlXrRtwD45sB3xUDeyLmhS/392pH/ioCMyn1QUlJO6VGHMSoCcsXw3RfDsvWUy+LwuOZ3aUzFpKd2S98p72B2jmh+tztKflxy6w/S6EvJqXoAeBAi6GfgsVg+QvK3gf1SQc8kolz9omGshQoXjab9BM5I1XwnJMTTOMr4YwGT0Is9lcy9ECDtriFUz2ooQAKq0jV1KuT9kXQJkguYwISU6bquhVxeWVcQa+TpL2gCw8BWYBVwOsJ2XnRxw6eMXmy17D0wKwrI+RqAmBQ7V8l7I07LRQFJ+jy26i2bjpBN7Jdyag/E3cT2gykIQMJ4rWBTzqhH1Q3atQnxVYgLAi3QBc35c2C5x9guCxA1C17t2eG4XvSUlJSOeKZMQ7ktYounSWJk44AIorm1/jI0iPzaSe+oIw35gFynjpT2+ReOXdVOrqGGsNc/97dRVtzg6ooAAAAASUVORK5CYII=",
  phonePlus: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAACbElEQVR4nLWWW4hNYRTHf8Y1lzFDkcIDD/JAagwmZ5QpSVJKg6TEy5jSNJ48mVcPXqQopRTJJQ8yD65JeZLkEg9yKxmK5JbrHI7+0//U7szeZ5/vzN7/2p2z1/rW91/f2mutb0E+KAADQEeIUQMwY4TEvUDJv6mYDRwDvtroDbAhb+I1wGcv/gbcBf4BRWBTXsTzge8mOgA0Wr4F+GNdax7EJ7yoL0bXbZ0SpTlr4nfAL2BSimPHE7J3X8zTb5v+BH27jH8Db6s4NhV47U8xZBDBgAlCn/cyfukkaqpCvs0GlyrkHXWeeCUuIS3qqkI8ARhMiUzwN27xomfA6Bi9ZIe95nqWxHhDLexkOM5Z9xFYTA4NpAQ8rDj1KsufAvNqJA0iFm57sWq3jC7LlBDkRbzE2a2QTrdsrTe4HEhccMkMZW8tOGKiU34fC7ywTGHPDc1uFiLaYVmn3x9V6W6ZoOCa1S21ABjlUIv8YkLJZYb9JnoOzHTbfGzZFWBajM164I6/7VVgXT3EDcB5E90DpgBzgPuWvQI2Oxr4/1/ripGefDalFcdiPHDTG9zyqScCJyMby6lDwA8TyoExwEY7V3ayjUA0ehop+bSzLG93WMsOFCvqHzt6xvpBoCeUXGG+5g10ky2yXGFe5ptLSZiEXY6I7PeEko8DTttYm+wOtF9uuw/uDUHQCfd6cJADFyKhrwUPbKcqqQtLXWba5IudSTtFmycY2Y0Ik4GDnkLlwBNga0JzmRtpu5pcM8FC4EYku3V97nRr1dSy3VNLycNiue4zw+oKBzS1/oy8H3VTyg0rfLMpez95OJRTw/AfyCDS9mhCQ0MAAAAASUVORK5CYII=",
  eventAvailable: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABgAAAAYCAYAAADgdz34AAAACXBIWXMAAAsTAAALEwEAmpwYAAAAg0lEQVR4nGNgGM7gPxRTSx19LPhPJYwTjFrwf9AEEbng/5CyIJaBgUGBWhZwofHTGRgY/jEwMNxDkyPLAjkGBoY7DAwM2VB+CgMDw1+oBVnU8EEs1DAQXobETidSP1EKkqGu/o/D5RRbAAuaP0hBRZNUpDms8gE2MHjKov+0soBhyAEAk+7DbveKe98AAAAASUVORK5CYII=",
  audio: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAB4AAAAeCAYAAAA7MK6iAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA6UlEQVR4nO2WOwrCQBRFT5VK7dTliLoBP5sRK8VCXY6fFZhChCAuwmjlB7EURgJXkBAj4iSC5MIr3od74CXMDGR6LwcYAzvAB0aqJa4RYEIR1BLXLgK8TwNsXkQGti6TrZp/+7lWwPIDsKv4WiZk/A5sbQMmA5PSqq8yyin3I6Bb9QrKLzbAG5lVYq7FoXpV5Wsb4IHMJsodwf2Ih8BMs30b4DJwlmEnZq6rmRNQxJLawE3GU6AG5BV1YK5eMNPEslrAMeYAOQANElIJ6AHeE9BTLeilIpPWrfQzsBvzfR/h/gq8SALMX+gOKBWyfJizyGUAAAAASUVORK5CYII=",
  videoCall: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAA5UlEQVR4nO2WSw7CMAxEZ0/Llfq5FBLiUFXLCWg5l9lkgTARgaZJXOZJs8oifrEVGSCEEELicAcgxrK8ExGjUeQuiCLsCMLGYADQADi4tABGa6N1gp+zFZEBn5ksiDQBIp0FkSpApN6LyNGCSBsg0lsQGQNErhZExH2xPi6R71JI5Ezud6pc+sidkFQiqaLIXVBRIs/7lY/Ye5diy/1qy71LsbYTvzKVJNKsEOlKEqlWiNQliUjGKP5GxEeqc6EI2JGyR0sKjSJ3QRR5ZSngdeXLzHqwiH3mvYzKvIHILYcIIYQgOQ8T1fI4vUDiiAAAAABJRU5ErkJggg==",
  feedback: "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAADkklEQVR4nO2aWUhUURjHf6mZ7UVIPbQXBQlBEGUUPZT10Eu0ZxZFUFBEFLRvFNH61EYEvlkRBdEO0UJ7tEdF0KKSZVSkbRZt6sSB/5XDNM6Mc9VzBf/wwcw95/z9Ps+533YGGtGIQCMJGAQsA3YAhyQ7gWlAawKOZsBS4D0QiiIlQDYBRVfgmaXsc2A3sBCYCkyWkZc0XgGc0PMmBASdgAIp+BjIijF/TdgOXQDaEgAckUI3gTZxzDc70AOYB7zT2pM4Rl+gEvgOdE5gfTfgo4yJtZN1ilVSItcHx0px7MchTkmJ8T44MsRRiEM8kRL9fHC0FMdfxSAneColzFlPFCmWB0ujAe9IshzGX312ggcyZKAPjh7iKMIhDkuJST44RovjOg6xTkps8cGxWhwmwXSGCVLirA+Oq7Xgwn1jlpQoTTD56wj8Ecc4HOKOlJib4HrjpTaI4ygOUSQlevrgGCqOazjEWSkx0wfH3CDkWvOlxCtgRQLr1wPF4sjBIdKA21LEVIg1gXEOn7X2MtAUx2gN/FL52raGtUxIxVUqAcFFKTWnBmuWa00eAUKOdbziOSK9gddaYxLGfGAEAUCK1UXJixEc+1jlrS1vgeYEAIMtpaIZfF9zTgPtwgLrbAKCUAxDpmg8X5Whhxl6boxsEIacqsYpmC7lB40NoQEYUqDx7hHGNmnsAI7R3zIkvZo5vzQe6aXuApQDZS6DY6bSFM8Q41oXqdXTCmihz2Ua7xWBI8tnSeALw5R+l8fowIdLdhSXvIR6QiowHbhbQ+XDG9c2ruj5HzmCOo0nHVQExbr/iFdGWtz3wsbM8dpoxZlagen+LQa+1JIB9h2K15CbWM2cUr1rvjuQJps9X8sG2LLL+luHosw75+cOpZWVOtSlmGiP7la8FmwkuWW9O5lqVsR1H7m3HowIySWby1PUQy6MMneP8rFKff+kdyk91gVOqJ6kxOofm/vIh9XMq7CCqpd8GvmhO8v/munb6tEIT4otY0wAzY3yz9yqeSYvO27N+w0ssA256cAQI1+BMZYew60Y48mZCF4sQ3WQd9yqUOrIkJCyBPNjg6SwDGIfsFm7FQnttd40NKpS6lAA5AYwgPgx1nLVgcAoq4NZCRzTs+QYdywvtcb8MCEwMDFls5Ute85gu15wL6M2TYy1VtZxN8rRc4p0KfoijqN4MKx8DizMTpiU5hHwDfgJvFFFaSJ9Ff4BRw5ElqN712oAAAAASUVORK5CYII=",
  translationService:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAEuElEQVR4nO1aW4scRRTuiIobBBVBIgYvD94QWVRCJEhA8FFFQYywGyUQXQ2oKEIU9AcEBRXxEjT6JMgmRtEgYoKKCjFZH0LEINlEIbrKGpzu7+s+Z8ZLKDk91es42zPTMzszPeAeqJeeqlPnqzq3OmeCYJmWaZmWTG5ubqUC9yrwrpCzAqiSbhBDALU9FNilwEbn3NjSEQRBoMB9Cvw8KME7DuAnA9T7LTh3hgLbG5geEHKqBlzet1PK33fM9rC9lDzYAOg1k6lrhhkIIRONognn3IpgyOScW2EqLaRkYLpXJw9ComhNUDIJsLYBzMZuDLtuE1E0EYwIqT9cs5lCqp16J28TZahTWzUjZwrfirlYr1ZTwYBJgcMKvAzg/CLzhXzIA9lVZPIxm2yeo6UA5DNdypy/VxTdIMAp27PI/BpwpT/k2c7MvVGZrbSa41XvqcZvzrmzlLxJyLsEuL1GXpWzbr2IXPSfb8DOQiccePutA5GOkzO/XWSOAk+IyGoh30zddHOUJmcTcnO2ToAjQj4bRdF5BtSAaRzfLeQD6QAeU3KrAtNK7s9TuSLydQ+knlJUc9KMvxR43VQncxjVOL65MQ1R4KSQx22YrXiQx5sO5MDQgOTcwg9xHF+7aA0w52/x8Xa8E3KzAF8kSTLeq3xLBwL8kiTJqlwBk2RVHMfXCHmHqZeQO1IVAnYLsFeBbxU4lHcIQwciwLZO/FPbAHYr8J6STwvwiJKhDSF3dMokhgXklAXUjptkfIBpIWMl91QqlXMKrRmajQB/t0tt3Pz82VqpXJKE4XUC7PPr9vtsd6uSH7U7jKEBWQATxxua1swI8EeL+d8J8LkC73u7mW4VkPsKpBdSYJ2SHyr5vMbxPTXyaomih22vomo1EkAaKUmSC51zZwrwqMWTbtbqKAER8hMB/vSe6u1SgWilcnGqJuTX9n4Rkgr8KsA7Zszt1qa3QT5nQbIGXFEqEImiNVmKkqYZZNRgwL+3CoxLJe03EDPQahzfkhUE/MNnfUPF5cU+yV6OjVSBTf6mPl3EO443pK65g/sOygBi7xDLj5IwvD6O4wsS4FYP5LNc/lE00QlMMEwg1TC81B5F3vs0Jo6/eSD7Wu5hJR5LZ8oGIiKrFZj3gp9U8gUht9hDK00x6jw+bssD2FY+EOAND+Jwc1S2GpTnsaddQDRXXT4Qctarz205Qo57Hh+0ADEu5I8jYSOSPW+Bdc2/WYDzIPdm38w1W9wR8q08Q29+LgdDA+JLRpatNlb9qmF4mQKveuG+yr4n5P1CHl0EoJ4NbLfKircv1zcgBctBTzZGcQG+FPJ7D+CIF/JYXl3K1FHIO+02LVXJ4xv0oxxUpEDnnDvNZ66HjKmdrAczFYbhub6S/1LQA2mb4l9XBbq0U1Sf/GAwYiTm4usqvbPjZCsQe5U5OIJF7G88kMkiC8bSdld9QeFCwqCp+m8Od6Jwxyy7lVT/gbVBySTAjQsN2G57NtbmysBYk6Ws1lsV2LQAAnil12ZoCsaPGXMA5jnauealkpubW5m66XovpG4THoRz7vSeGadqltlMCUOAE31rAXoHMGmu2SL0QP8wwDQuHfV9k8lBtsKXaZn+T/QPjFJXFBhV9KkAAAAASUVORK5CYII=",
  person:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB2UlEQVR4nO2aTStEURjHb6PUyFKJhbKwkO9gQYkFSxsr+RJ2XlKWFqx8AiXFwoIkGy8rC1khRDZ2w3ht+Ok0T810u3Pn3DvPYXB+m6tm+v/mf+7j3OkQBB6PJzVAEzALXABvcp0Bsr/GRzH0kGgOtMs481FcmTimlYu48cltjeNcuYgbn8xoHK/KRdz4LFboTLmIG5/sFnFMKRdx4zO7hOwWUew72LXc+SR82vyiyQyb65TD50j2O32/E6AfOAFegCNgDMg4cvUAK8C9jFROxmqiJifQC3xEzOyxKahcYkQWqxI7QHPa8L0qO8mmWUWFEp3AI9VZSyt4sAgvAMtAWw1FlrBnII2gVm6BIQvPZYLM1Z8oYrix8LwnyLvTLjIMnCoVqUZX2c8F1SLyegMwbj5spRLAYK1FgtB71Ito4YvY4u9IQvxo/cfR+vwrRa7qoQjQWPb0z6URTNZJkT5gAXgG5tIIMsD8NxfpitBduxA5LRK49Pkilvg7khQ/Wpb40UqKHy1L/GglxY+WJaHj0kzUebOW6CmUm1cJLuVvlGW3ygml/t8rQyLDukpwKd98481L9iiwGPJta4na5eQ9J9fUB9Yxjm5gV/5poMUslvi2gA5tn8cT2PEFJEgP+XeWLOMAAAAASUVORK5CYII=",
  quality:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAACDklEQVR4nO2XzU4TURTHj7bKgo/ENWsXIEvXJiTCiggL6gp4gqa26o6QuGJjfAPjAkh8DN/BUGgIyAuQkPAVYeHPnHgmTqad26l89FDvL5lkeufce89//vfcuRWJ3BNwikQhzpDoiDMkOuIMiY4MsiNyyxCFdACnSK/gFIlCnCHREWdIdMQZEh1xhkRHBtkRCcT2PNE15+7KfyGkn0gU4gwZFEeAxzcmJBTb8xv7O8YC8KOAEI2ZdycEeAh8TA2xDdSBZ8CwXXrfAJqpuE/Ag2sJuSUugSpQCuRUAmrAVSLGmxAV8bIHF2dSYhY8CakWFDAEvLb7N6mayd8A7lDEdmg5JWg9AJvWZxUoAzv2uyJ5hGYOxeYO2N5ny7rUC8Z/sPhjLX5re2ttX/sppGVdJgvELgG/rJamU+1TNsbBPwm5YUYzb30ik8cL4KcJWc48G7Uxzt0I0SLmD2fqgLU9BY6sfa1DjomQEw9CkvWuH76kbpTPwJ7df8nJMVlaLQ9CGpl5V4CL1PNvedsr8N5iNjwIaWa3X+A5cAh8B8Zy8tPtd9fGWPQgRKl1mP8JMB7IT89e2PJ75EWIHjdmcpNpz23W+uhO9qpb8F2jiemxoxzIqWxOJOes9SKq+0XTvti6I43YpffvUkcSdWJd/wKIN4A5K/BuaE2El1O/saVTse/Jvn6xgVNzQg+Mi3mF/RsXnXj6MwODKQAAAABJRU5ErkJggg==",
  behavior:"data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAACXBIWXMAAAsTAAALEwEAmpwYAAABPUlEQVR4nO3Xu0oEMRSA4VOI+zq2KiK+ht0+jBd8HV9iO620ERGZUbCwFWx+CZxpwrImJHM2Gc7XzZKT7D8DexFxbn+YmYfkWuwTkV7RYwiNEA9RNEJK0QjxEJV6h+Zcs21dNpYeYk08RNEIKZW68ZxrAg+Z0AgpRSPEQ1TqHZpzzbZ12Vh6iDXxEEUjpFTqxrZZjMANcNh7yOS6OCTTcfKBae/pRPcdTUNqRkyy97YIAS6AJ+AHuAeOzEIkIzjhjMdoZOw15Dsa+TULyZFwxkM08txryHs08tZryDoaWZuFSEZwwhkr/bYOPsN1ryEHwKDLP8K1WUiOf57EJbCJRjb6+qr5EOBc7/4uI3DWeshr4vhL0yGl2EdI+LVaOeJU9x1q7rvrwFvmdWUVEj5W74CvygFDiMj6h+icVPEHG6bfgeqCT8wAAAAASUVORK5CYII="

}
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import React from 'react';
import { useSelector } from 'react-redux';
import { languageMappings } from '../../languages';
import styles1 from '../../styles/TablesItems.module.css';
import { fCurrency } from 'src/utils/formatNumber';

function Table2Items({row, index, itemTranslation}) {

    const {language} = useSelector((state)=>state.proposal);
  return (
    <TableRow key={index}>
      <TableCell
        sx={{
          '.css-4dwhzq-MuiTableCell-root': {
            marginBottom: 'none',
          },
          borderBottom: 'none',
          maxWidth: '400px',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
        }}
        align="left"
      >
        {language === 'English' ? (
          <Box>
            <Typography variant="body" sx={{ color: 'black', fontSize: '19px' }}>
              <span>
                <b style={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}>File Name: </b>
                <span style={{ fontSize: '17px' }}>{row.title}</span>
              </span>{' '}
              <br />
            </Typography>

            <Typography sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}>
              {row?.service?.Item['english']}
            </Typography>
            <Typography variant="body" sx={{ color: 'black' }}>
              <span>
                <b style={{ fontSize: '15.5px' }}>from: </b>{' '}
                <span style={{ fontSize: '15.5px' }}>{row.sourceLanguage?.name}</span>{' '}
                <b style={{ fontSize: '15.5px' }}>to: </b>
                <span style={{ fontSize: '15.5px' }}>
                  {row?.targetLanguage?.map((language) => language.name).join(', ')}
                </span>
              </span>
            </Typography>
            <Typography
              className={styles1.description}
              variant="body2"
              sx={{ color: 'text.secondary', fontSize: '15px' }}
            >
              {itemTranslation ? itemTranslation : ''}
            </Typography>
          </Box>
        ) : (
          <Box>
            <Typography variant="body" sx={{ color: 'black', fontSize: '19px' }}>
              <span>
                <b style={{ fontWeight: '700' }}>Nome File: </b>
                <span style={{ fontSize: '17px' }}>{row.title}</span>
              </span>{' '}
              <br />
            </Typography>

            <Typography sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}>
              {row?.service?.Item['italian']}
            </Typography>
            <Typography variant="body" sx={{ color: 'black' }}>
              <span>
                <b style={{ fontSize: '15px' }}>da: </b>{' '}
                <span style={{ fontSize: '16px' }}>{languageMappings[`${row.sourceLanguage?.name}`]}</span>{' '}
                <b style={{ fontSize: '15px' }}>a: </b>
                <span style={{ fontSize: '16px' }}>
                  {row?.targetLanguage?.map((language) => languageMappings[`${language.name}`]).join(', ')}
                </span>
              </span>
            </Typography>
            <Typography
              className={styles1.description}
              variant="body2"
              sx={{ color: 'text.secondary', fontSize: '15px' }}
            >
              {row?.service?.Description['italian']}
            </Typography>
          </Box>
        )}
      </TableCell>
      <TableCell
        sx={{
          '.css-4dwhzq-MuiTableCell-root': {
            marginBottom: 'none',
          },
          borderBottom: 'none',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
        }}
        align="center"
      >
        &nbsp; € {Number(row.pricePerWord).toFixed(2)}
      </TableCell>
      <TableCell
        sx={{
          '.css-4dwhzq-MuiTableCell-root': {
            marginBottom: 'none',
          },
          borderBottom: 'none',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
        }}
        align="center"
      >
        {row.totalWords}
      </TableCell>
      <TableCell
        sx={{
          '.css-4dwhzq-MuiTableCell-root': {
            marginBottom: 'none',
          },
          borderBottom: 'none',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
        }}
        align="center"
      >
        {row.discount ? row.discount + '%' : ''}
      </TableCell>
      <TableCell
        sx={{
          '.css-4dwhzq-MuiTableCell-root': {
            marginBottom: 'none',
          },
          borderBottom: 'none',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
          width: '100%',
        }}
        align="center"
      >
        &nbsp;
        {row?.total
          ? fCurrency(Number(row.total))
          : fCurrency(
              Number(row?.totalWords) * Number(row?.pricePerWord) -
                (Number(row?.pricePerWord) * Number(row?.totalWords) * Number(row.discount)) / 100
            )}{' '}
        {row.discount > 0 && row.discount && (
          <>
            <br />
            <del>&nbsp;{fCurrency(Number(row?.pricePerWord) * Number(row?.totalWords))}</del>
          </>
        )}
      </TableCell>
    </TableRow>
  );
}

export default Table2Items;

import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const CompanyPerformance = ({ language, brand }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={{ ...styles.header, borderBottom: "1px solid white", marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
          <Text style={{...styles.headerItemText, color:"white"}}>{language === 'English' ? `ABOUT US` : `CHI SIAMO`}</Text>
        </View> 
         <View>
{brand && brand?.rafiky ? <Image style={styles.headerRightImg} src="/rafiky/logo-w.png" />   
    :   <Image style={styles.headerRightImg} src="/assets/desktop16/logocongress.png" />
}          
        </View>
        <View style={{...styles.headerItem, color:"white"}} />
      </View>
     <View style={styles.coImages}>
     <View style={styles.coImage1}>
      <Image style={{height:"500px"}} src="/assets/desktop12/Dark02.png"/>
      </View>
      <View style={styles.coImage2}>
      <Image style={{height:"500px"}}  src="/assets/desktop12/Dark03.png"/>
      </View>
       </View>
       <View style={styles.coStats}>
       <View style={styles.coStats1}>
        <Text style={styles.coTitle}>
              COMPANY PERFORMANCE
        </Text>
        <Text style={styles.coYear}>
              2023
        </Text>
       </View>
       <View style={styles.coStats2}>
        <View style={styles.coStats21}>
        <View style={styles.coStats211}>
          <Text style={styles.coStatsData}>
          Top 1
          </Text>
          <Text style={styles.coStatsDesc}>
          {language === "English" ? `Revenue growth leader in 2022 (373%)`:`Leader di crescita del fatturato nel 2022 (373%)`}

          </Text>
        </View>
        <View style={styles.coStats212}>
          <Text style={styles.coStatsData}>
          Top 5
          </Text>
          <Text style={styles.coStatsDesc}>
          {language === "English" ? `Efficiency in the field`:`Efficienza nel settore`}

          </Text>
        </View>
        </View>
        <View style={styles.coStats22}>
        <View style={styles.coStats221}>
          <Text style={styles.coStatsData}>
          Top 30
          </Text>
          <Text style={styles.coStatsDesc}>
          {language === "English" ? `Market value in the industry`:`Valore di mercato nel settore`}

          </Text>
        </View>
        <View style={styles.coStats222}>
          <Text style={styles.coStatsData}>
          +500
          </Text>
          <Text style={styles.coStatsDesc}>
          {language === "English" ? `Events in 2023`:`Eventi svolti nel 2023`}

          </Text>
        </View>
        </View>
        <View style={styles.coStats22}>
        <View style={styles.coStats221}>
          <Text style={styles.coStatsData}>
          99.1%
          </Text>
          <Text style={styles.coStatsDesc}>
          {language === "English" ? `Client Retention`:`Fidelizzazione del cliente`}

          </Text>
        </View>
        <View style={styles.coStats222}>
          <Text style={styles.coStatsData}>
          +200
          </Text>
          <Text style={styles.coStatsDesc}>
          {language === "English" ? `Languages`:`Lingue`}

          </Text>
        </View>
        </View>
        <View style={styles.coStats22}>
        <View style={styles.coStats221}>
          <Text style={styles.coStatsData}>
          4.75
          </Text>
          <Text style={styles.coStatsDesc}>
          {language === "English" ? `Reviews.io`:`Reviews.io`}

          </Text>
        </View>
        <View style={styles.coStats222}>
          <Text style={styles.coStatsData}>
          50
          </Text>
          <Text style={styles.coStatsDesc}>
          {language === "English" ? `Italian cities`:`Città italiane`}

          </Text>
        </View>
        </View>
       </View>
       </View>
       
    </Page>
  );
};

export default CompanyPerformance;
import React from "react";
import styles from "../styles/Privacy.module.css";
import { useSelector } from "react-redux";

export default function Privacy() {

  const {language} = useSelector((state)=>state.proposal);

  if (language === 'English') {
    return (  <div className={styles.desktop26}>
      <div className={styles.desktop26Child} />
      {/* <div className={styles.div}>12</div> */}
      <div className={styles.interpreting}>
      Privacy & Cyber Security
      </div>
      <div className={styles.text3543Parent}>
        <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{" "}
      </div>
      <div className={styles.tree}>
        <div>
          <div
            className={styles.frame}
            style={{ background: "url(/translationAssets/privacy/Vector.webp) no-repeat" }}
          >
        
          </div>
        </div>
        <div className={styles.lines}>
          <img  alt=""  className={styles.line1} src="/translationAssets/privacy/icons/line1.png" />
          <img alt=""  className={styles.line2} src="/translationAssets/privacy/icons/line2.png" />
          <img alt=""  className={styles.line3} src="/translationAssets/privacy/icons/line3.png" />
          <img alt=""  className={styles.line4} src="/translationAssets/privacy/icons/line4.png" />
          <img alt=""  className={styles.line5} src="/translationAssets/privacy/icons/line5.png" />
          <img alt=""  className={styles.line6} src="/translationAssets/privacy/icons/line6.png" />
          
        </div>
        <div className={styles.rectangles}>
          <div className={styles.rectangle1}>
            <span> Non Disclosure Agreement form - On Demand</span>
            <img alt=""  src="/translationAssets/privacy/icons/tick-circle.png" />
          </div>
          <div className={styles.rectangle2}>
            <span> Penetration Test – half yearly</span>
            <img alt=""  src="/translationAssets/privacy/icons/stickynote.png" />
          </div>
          <div className={styles.rectangle3}>
            <span> Cyber Essential Certificate</span>
            <img alt=""  src="/translationAssets/privacy/icons/certificate-medal.png" />
          </div>
          <div className={styles.rectangle4}>
            <span>Customer portal developed on AWS server (S3) - Maximum data security</span>
            <img alt=""  src="/translationAssets/privacy/icons/server-database.png" />
          </div>
          <div className={styles.rectangle5}>
            <span>Translations in line with GDPR requirements </span>
            <img alt=""  src="/translationAssets/privacy/icons/translate.png" />
          </div>
          <div className={styles.rectangle6}>
            <span>Translations in line with CCPA requirements</span>
            <img alt=""  src="/translationAssets/privacy/icons/checkbox-translate.png" />
          </div>
          <div className={styles.rectangle7}>
            <span>Maximum guarantee of user privacy and cyber security & Documentation provided</span>
            <img alt=""  src="/translationAssets/privacy/icons/security-user.png" />
          </div>
        </div>
      </div>
      
      <div className={styles.footer}>
        <div>
          <img alt=""  src="/translationAssets/privacy/footer1.png"/>
        <img alt=""  src="/translationAssets/privacy/footer2.webp"/>
        
        </div>
      </div>
    </div>)
  }
  return (
    <div className={styles.desktop26}>
      <div className={styles.desktop26Child} />
      {/* <div className={styles.div}>12</div> */}
      <div className={styles.interpreting}>Privacy & Cyber Security</div>
      <div className={styles.text3543Parent}>
        <img  alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{" "}
      </div>
      <div className={styles.tree}>
        <div>
          <div
            className={styles.frame}
            style={{ background: "url(/translationAssets/privacy/Vector.webp) no-repeat" }}
          >
        
          </div>
        </div>
        <div className={styles.lines}>
          <img alt=""  className={styles.line1} src="/translationAssets/privacy/icons/line1.png" />
          <img alt=""  className={styles.line2} src="/translationAssets/privacy/icons/line2.png" />
          <img alt=""  className={styles.line3} src="/translationAssets/privacy/icons/line3.png" />
          <img alt=""  className={styles.line4} src="/translationAssets/privacy/icons/line4.png" />
          <img alt=""  className={styles.line5} src="/translationAssets/privacy/icons/line5.png" />
          <img alt=""  className={styles.line6} src="/translationAssets/privacy/icons/line6.png" />
          
        </div>
        <div className={styles.rectangles}>
          <div className={styles.rectangle1}>
            <span> Non Disclosure Agreement form - On Demand</span>
            <img alt=""  src="/translationAssets/privacy/icons/tick-circle.png" />
          </div>
          <div className={styles.rectangle2}>
            <span> Penetration Test – semestrale</span>
            <img alt=""  src="/translationAssets/privacy/icons/stickynote.png" />
          </div>
          <div className={styles.rectangle3}>
            <span> Cyber Essential Certificate</span>
            <img alt=""  src="/translationAssets/privacy/icons/certificate-medal.png" />
          </div>
          <div className={styles.rectangle4}>
            <span>Portale cliente sviluppato su server AWS (S3) - Massima Sicurezza dei dati</span>
            <img alt=""  src="/translationAssets/privacy/icons/server-database.png" />
          </div>
          <div className={styles.rectangle5}>
            <span> Traduzioni in linea con quanto richiesto dal GDPR</span>
            <img alt=""  src="/translationAssets/privacy/icons/translate.png" />
          </div>
          <div className={styles.rectangle6}>
            <span>Traduzioni in linea con quanto richiesto dal CCPA</span>
            <img alt=""  src="/translationAssets/privacy/icons/checkbox-translate.png" />
          </div>
          <div className={styles.rectangle7}>
            <span>Massima garanzia privacy e cyber security degli utenti & Documentazione fornita</span>
            <img alt=""  src="/translationAssets/privacy/icons/security-user.png" />
          </div>
        </div>
      </div>
      
      <div className={styles.footer}>
        <div>
          <img alt=""  src="/translationAssets/privacy/footer1.png"/>
        <img alt=""  src="/translationAssets/privacy/footer2.webp"/>       
        </div>
      </div>
    </div>
  );
}

import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const Academy = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
       <View style={{ ...styles.header, marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
        <Text style={{...styles.headerItemText, fontSize:"7px"}}>
            {language === 'English' ? `LingoYou Academy, language courses for companies and professionals` : 
          `LingoYou Academy, corsi di lingue per aziende e professionisti`}</Text>    
              </View>
         <View>
          <Image style={{...styles.headerRightImg }} src="/translationAssets/desktop6/lingoyou.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={{...styles.techBody, display:"inline-flex", flexDirection:"row"}}>
      <View style={{...styles.techBody, marginLeft:"0px", marginTop:"0px", width:"70%", paddingRight:"5px", borderRight:"1px solid grey"}}>
      <Text style={styles.techParaBody}>
       {language === "English" ? `Professional training on various levels.`:`Formazione professionale e a vari livelli.`}
        </Text>
        <Text style={{...styles.techParaBody, fontWeight:"normal", fontStyle:"normal"}}>
       {language === "English" ? `Qualified teachers, innovative teaching methods and cutting-edge technologies for a service of high-quality standards.`:
       `Docenti qualificati, metodologie didattiche innovative e tecnologie all’avanguardia per un servizio su standard di alta qualità.`}
        </Text>
        <View style={{...styles.academyCards}}>
             <View style={{...styles.academyCard, color:"white",marginLeft:"0px"}}>
               <Text style={{...styles.techParaBody,color:"white", fontSize:"9px", fontWeight:"normal", fontStyle:"normal"}}>
               {language === "English" ? `Tailored courses`:`Corsi su misura`}
                </Text> 
                <Text style={{...styles.techParaBody,color:"white", fontSize:"8px", fontWeight:"normal", fontStyle:"normal"}}>
                {language === "English" ? `Our corporate language courses are adaptable and customized according to company requirements and goals.`:
                `I nostri corsi di lingua per aziende sono adattabili e vengono personalizzati in funzione delle esigenze e degli obiettivi aziendali.`}
                </Text>  
             </View>
             <View style={{...styles.academyCard, color:"white"}}>
               <Text style={{...styles.techParaBody, color:"white", fontSize:"9px", fontWeight:"normal", fontStyle:"normal"}}>
               {language === "English" ? `Mother tongue teachers`:`Docenti Madrelingua`}
                </Text> 
                <Text style={{...styles.techParaBody, color:"white",fontSize:"8px", fontWeight:"normal", fontStyle:"normal"}}>
                {language === "English" ? `Qualified native speaking professionals specialized in teaching.`:`Professionisti madrelingua qualificati e specializzati nell’insegnamento.`}
                </Text>  
             </View>
             <View style={{...styles.academyCard, color:"white"}}>
               <Text style={{...styles.techParaBody, color:"white",fontSize:"9px", fontWeight:"normal", fontStyle:"normal"}}>
               {language === "English" ? `Quality`:`Qualità`}
                </Text> 
                <Text style={{...styles.techParaBody,color:"white", fontSize:"8px", fontWeight:"normal", fontStyle:"normal"}}>
                {language === "English" ? `Many years of experience in the field and cutting-edge technologies for a high-quality service.`:
                `Esperienza pluriennale sul campo e tecnologie all’avanguardia per un servizio di alta qualità.`}
                </Text>  
             </View>
        </View>
        </View>
        <View style={{...styles.techBody, width:"30%"}}>
        <Link  href="https://www.youtube.com/embed/VRIr22lCZ80">
      <Image style={styles.techFooterImage} src="/translationAssets/desktop9/academy.png"/>
        </Link>
        </View>
              </View>
              <Text style={{...styles.dividerText, backgroundColor:"#2DA0F0", marginTop:"30px"}}>{language === 'English' ? 
      `OUR COURSES` :
       `I nostri corsi`}
       </Text>
       <View style={{...styles.techBody, marginTop:"20px",marginBottom:"20px", display:"inline-flex", flexDirection:"row"}}>
           <View style={{width:"300px", textAlign:"center", padding:"5px", borderRight:"1px solid grey", placeContent:"center", justifyContent:"center"}}>
              <Image style={{marginBottom:"5px", marginLeft:"80px", width:"20px", height:"20px"}} src="/translationAssets/icons/single-user-black.png"/>
              <Text style={{...styles.techParaBody,marginBottom:"10px", fontSize:"8px", fontWeight:"normal", fontStyle:"normal"}}>
              {language === 'English' ? `Individual courses` : 
          `Corsi individuali`}
              </Text>
              <Text style={{...styles.techParaBody, marginBottom:"10px", fontSize:"9px", fontWeight:"normal", fontStyle:"normal"}}>
              {language === 'English' ? `INDIVIDUAL STUDY` : 
          `STUDIA INDIVIDUALMENTE`}
              </Text>
              <Text style={{...styles.techParaBody, fontSize:"7px", fontWeight:"normal", fontStyle:"normal"}}>
              {language === 'English' ? `By choosing our one-to-one courses, you access a completely personalized path, defined according to specific needs and objectives in order to develop Business Skills and Professional English topics most relevant to your field of reference.` : 
          `Scegliendo i nostri corsi individuali, accedi a un percorso completamente personalizzato, adatto alle esigenze e a obiettivi specifici al fine di sviluppare le Business Skills e le tematiche di Professional English più rilevanti per il tuo settore di riferimento.`}
              </Text>
           </View>
           <View style={{width:"300px", textAlign:"center", padding:"5px", borderRight:"1px solid grey", placeContent:"center", justifyContent:"center"}}>
              <Image style={{marginBottom:"5px", width:"20px",marginLeft:"75px", height:"20px"}} src="/translationAssets/icons/contacts-black.png"/>
              <Text style={{...styles.techParaBody,marginBottom:"10px", fontSize:"8px", fontWeight:"normal", fontStyle:"normal"}}>
              {language === 'English' ? `Semi-individual courses` : 
          `Corsi semi-individuali`}
              </Text>
              <Text style={{...styles.techParaBody, marginBottom:"10px", fontSize:"9px", fontWeight:"normal", fontStyle:"normal"}}>
              {language === 'English' ? `SMALL GROUPS OF 2-3 PEOPLE` : 
          `MINI-GRUPPI DI 2-3 PERSONE`}
              </Text>
              <Text style={{...styles.techParaBody, fontSize:"7px", fontWeight:"normal", fontStyle:"normal"}}>
              {language === 'English' ? `Our semi-individual courses combine the advantages of a personalized path with those of group lessons, also representing a real team building experience with the aim of developing the ability to work in synergy.` : 
          `I nostri corsi semi-individuali uniscono i vantaggi di un percorso personalizzato a quelli delle lezioni di gruppo, costituendo nell’insieme anche una vera e propria esperienza di{" "} team building, con l’obiettivo di sviluppare la capacità di lavorare in sinergia.`}
              </Text>
           </View>
           <View style={{width:"300px", textAlign:"center", padding:"5px", placeContent:"center", justifyContent:"center"}}>
             <View style={{display:"inline-flex",marginLeft:"60px", flexDirection:"row"}}>
             <Image style={{marginBottom:"5px", width:"20px", height:"20px"}} src="/translationAssets/icons/black-contacts.png"/>
             <Image style={{marginBottom:"5px", width:"20px", height:"20px"}} src="/translationAssets/icons/black-contacts.png"/>
             <Image style={{marginBottom:"5px", width:"20px", height:"20px"}} src="/translationAssets/icons/black-contacts.png"/>

             </View>
              <Text style={{...styles.techParaBody,marginBottom:"10px", fontSize:"8px", fontWeight:"normal", fontStyle:"normal"}}>
              {language === 'English' ? `Group courses` : 
          `Corsi collettivi`}
              </Text>
              <Text style={{...styles.techParaBody, marginBottom:"10px", fontSize:"9px", fontWeight:"normal", fontStyle:"normal"}}>
              {language === 'English' ? `GROUP LESSONS` : 
          `LEZIONI DI GRUPPO`}
              </Text>
              <Text style={{...styles.techParaBody, fontSize:"7px", fontWeight:"normal", fontStyle:"normal"}}>
              {language === 'English' ? `Group courses are the cheapest, but no less efficient, alternative to a lot of courses offered by LingoYou Academy. Group lessons are designed to develop the linguistic, working, and relational skills of your company's employees.` : 
          ` I corsi collettivi sono l’alternativa più economica, ma non per questo meno efficiente, del lotto di corsi proposti da LingoYou Academy. Le lezioni di gruppo sono pensate per sviluppare le skill linguistiche, lavorative e relazionali dei dipendenti della tua azienda.`}
              </Text>
           </View>
       </View>
    </Page>
  );
};

export default Academy;
// @mui
import {
  Card,
  Table,
  TableRow,
  TableBody,
  TableCell,
  CardHeader,
  TableContainer,
  FormControl,
  Select,
  MenuItem, Skeleton
} from '@mui/material';
import { TableHeadCustom } from 'src/components/table';
import { fNumber } from 'src/utils/formatNumber';

// ----------------------------------------------------------------------


export default function ProjectsIssuesCount({
  loading, title, subheader, tableData, tableLabels, salesFilterYear, hadleFilterYear, ...other
}) {

  return (
    <>
      <Card {...other} style={{
        height: "100%"
      }}
      >
        <CardHeader
          title={title}
          sx={{ mb: 3 }}
        />       
         {loading ? <Skeleton height={300} /> :

          <TableContainer>
            <Table >
              <TableHeadCustom headLabel={tableLabels}            
                sx={{
                  '& .MuiTableCell-head': {
                    bgcolor: '#3be058',
                    color: '#ffff',
                },
                '&.MuiTableHead-root': {
                  padding: 0,
                  },
                }}

              />
              <TableBody>
                {tableData?.map((row) => (
                  <MonthlyRate key={row.id} row={row} />
                ))}
              </TableBody>
            </Table>
          </TableContainer>}
      </Card>
    </>
  );
}

// ----------------------------------------------------------------------


function MonthlyRate({ row }) {
  return (
    <TableRow>
      <TableCell align='center'>{row?.month}</TableCell>
      <TableCell align='center'>{fNumber(row?.projects)}</TableCell>
      <TableCell align='center'>{fNumber(row?.issues)}</TableCell>
      <TableCell align='center'>{Number((Number(row?.issues)/ Number(row?.projects))*100).toFixed(2)}%</TableCell>

      {/* <TableCell>{Number(row.convertedTotal).toFixed(2)}%</TableCell> */}
    </TableRow>
  );
}

import { Grid, Typography } from '@mui/material';
import React from 'react';
import Iconify from 'src/components/Iconify';
import styles from "../styles/WhyRafiky.module.css"
import { useSelector } from 'react-redux';
export default function Process() {

  const { language} = useSelector(state => state.proposal)

  return (
    <div style={{boxShadow: 'rgba(0, 0, 0, 0.4) 0px 0px 5px 3px',}}>
      <section className={styles.header}>
            <span>
            {language === "English" ? "OPERATIONAL PROCESS" : "PROCESSO OPERATIVO"}

            </span>
            <img src='/rafiky/logo.webp' alt='logo'/>
        </section>
    <div  style={{
        marginTop:"-30px",
         paddingBottom: "60px",
        backgroundImage: "url('/rafiky/brochure/background.png')",
        backgroundPosition: 'right',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'auto',
        // boxShadow: 'rgba(0, 0, 0, 0.4) 0px 0px 5px 3px',
      }}>
   {/* <div style={{textAlign:"-webkit-center"}}>
   <Typography variant="h1" sx={{ textAlign:"center", my:3, width:"50%", 
    borderBottom: "3px solid #8316d3" }}></Typography>
   </div> */}
      <Grid container sx={{margin:"30px",paddingTop:"20px", width:"90%"}} spacing={3}>
        
        <Grid item xs={12} sm={6} md={4} sx={{display:"flex"}}>
          <div style={{textAlign: "center",
    width: "80%",
    padding: "20px",
    borderRadius: "10px",
    border: "3px solid #8316d3" ,
    boxShadow: '0 0 15px rgb(0 0 0 / 20%)'
    }}>
            <Iconify icon={'mdi:deal-outline'} style={{ color: '#8316d3', height: '60px', width:"auto" }} />
            <br />
            <Typography variant="h4" sx={{textAlign:"center"}}>
                 {language === "English" ? "CUSTOMER REQUEST" : "RICHIESTA CLIENTE"}
            </Typography>
          </div>
          <Iconify icon={'maki:arrow'} 
          style={{ color: '#8316d3',marginTop:"70px", 
          marginLeft:"20px", height: '60px', width:"auto" }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{display:"flex"}}>
        <div style={{textAlign: "center",
    width: "80%",
    padding: "20px",
    borderRadius: "10px",
    border: "3px solid #8316d3" ,
    boxShadow: '0 0 15px rgb(0 0 0 / 20%)'
    }}>
            <Iconify icon={'tabler:progress'} style={{ color: '#8316d3', height: '60px', width:"auto" }} />
            <br />
            <Typography variant="h4" sx={{textAlign:"center"}}>
                 DEMO
            </Typography>
          </div>
          
          <Iconify icon={'maki:arrow'} 
          style={{ color: '#8316d3',marginTop:"70px", 
          marginLeft:"20px", height: '60px', width:"auto" }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
        <div style={{textAlign: "center",
    width: "80%",
    padding: "20px",
    borderRadius: "10px",
    border: "3px solid #8316d3" ,
    boxShadow: '0 0 15px rgb(0 0 0 / 20%)'
    }}>
            <Iconify icon={'carbon:mobile-audio'} style={{ color: '#8316d3', height: '60px', width:"auto" }} />
            <br />
            <Typography variant="h4" sx={{textAlign:"center"}}>
            {language === "English" ? "QUOTE REQUEST/ACCEPTANCE" : "RICHIESTA/ACCETTAZIONE PREVENTIVO"}

            </Typography>
          </div>
        
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{margin:"30px", width:"90%"}}>
        <Grid item xs={12} sm={6} md={4} sx={{display:"flex"}}>
        <div style={{textAlign: "center",
    width: "80%",
    padding: "20px",
    borderRadius: "10px",
    border: "3px solid #8316d3" ,
    boxShadow: '0 0 15px rgb(0 0 0 / 20%)'
    }}>
            <Iconify icon={'ci:list-checklist'} style={{ color: '#8316d3', height: '60px', width:"auto" }} />
            <br />
            <Typography variant="h4" sx={{textAlign:"center"}}>
                 {language === "English" ? "INTERPRETERS SELECTION" : "SELEZIONE INTERPRETI"}
            </Typography>
          </div>
         
          <Iconify icon={'maki:arrow'} 
          style={{ color: '#8316d3',marginTop:"70px", 
          marginLeft:"20px", height: '60px', width:"auto" }} />
        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{display:"flex"}}>
        <div style={{textAlign: "center",
    width: "80%",
    padding: "20px",
    borderRadius: "10px",
    border: "3px solid #8316d3" ,
    boxShadow: '0 0 15px rgb(0 0 0 / 20%)'
    }}>
            <Iconify icon={'mdi:settings-sync-outline'} style={{ color: '#8316d3', height: '60px', width:"auto" }} />
            <br />
            <Typography variant="h4" sx={{textAlign:"center"}}>
                 {language === "English" ? "TRAINING WITH THE CLIENT/INTERPRETER" : "TRAINING CON IL CLIENTE/INTERPRETE"}
            </Typography>
          </div>
       
          <Iconify icon={'maki:arrow'} 
          style={{ color: '#8316d3',marginTop:"70px", 
          marginLeft:"20px", height: '60px', width:"auto" }} />
        </Grid>
       
        <Grid item xs={12} sm={6} md={4}>
        <div style={{textAlign: "center",
    width: "80%",
    padding: "50px",
    borderRadius: "10px",
    border: "3px solid #8316d3" ,
    boxShadow: '0 0 15px rgb(0 0 0 / 20%)'
    }}>
            <Iconify icon={'entypo:back-in-time'} style={{ color: '#8316d3', height: '60px', width:"auto" }} />
            <br />
            <Typography variant="h4" sx={{textAlign:"center"}}>
                 {language === "English" ? "PRE-EVENT TEST" : "TEST PRE-EVENTO"}
            </Typography>
          </div>
        
        </Grid>
      </Grid>
      <Grid container spacing={3} sx={{margin:"30px", width:"90%"}}>
       
        <Grid item xs={12} md={2}>

        </Grid>
        <Grid item xs={12} sm={6} md={4} sx={{display:"flex"}}>
        <div style={{textAlign: "center",
    width: "80%",
    padding: "20px",
    borderRadius: "10px",
    border: "3px solid #8316d3" ,
    boxShadow: '0 0 15px rgb(0 0 0 / 20%)'
    }}>
            <Iconify icon={'carbon:event-schedule'} style={{ color: '#8316d3', height: '60px', width:"auto" }} />
            <br />
            <Typography variant="h4" sx={{textAlign:"center"}}>
                 {language === "English" ? "Event":"EVENTO"}
            </Typography>
          </div>
       
          <Iconify icon={'maki:arrow'} 
          style={{ color: '#8316d3',marginTop:"50px", 
          marginLeft:"20px", height: '60px', width:"auto" }} />
        </Grid>
     
        <Grid item xs={12} sm={6} md={4}>
        <div style={{textAlign: "center",
    width: "80%",
    padding: "20px",
    borderRadius: "10px",
    border: "3px solid #8316d3" ,
    boxShadow: '0 0 15px rgb(0 0 0 / 20%)'
    }}>
            <Iconify icon={'fluent:person-feedback-16-regular'} style={{ color: '#8316d3', height: '60px', width:"auto" }} />
            <br />
            <Typography variant="h4" sx={{textAlign:"center"}}>
                 {language === "English" ? "CUSTOMER FEEDBACK" : "FEEDBACK DEL CLIENTE"}
            </Typography>
          </div>   
        </Grid>
        <Grid item xs={12} md={2}>
            
        </Grid>
      </Grid>
    </div>
     
    </div>
  );
}

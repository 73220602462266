import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const OurClients = ({ language, brand }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
     <View style={styles.testimonial}>
     <View style={{ display: "flex",
    flexDirection: "row",
    alignItems: "center",
     width: "94%", marginBottom:"10px" }}>
          <Text style={{...styles.headerItemText, color:"white", marginLeft:"10px"}}>{language === 'English' ? `Our Clients` : `CHI SIAMO`}</Text>
          <Image style={{...styles.headerRightImg, marginLeft:"30%"}} src="/assets/desktop6/group-1000001719.png" />
      </View>

       <View style={{...styles.clientsImages, marginTop:"30px"}}>
          <Image style={styles.clientsImage} src="/assets/images/clients/image1.png"/>
          <Image style={styles.clientsImage} src="/assets/images/clients/image2.png"/>
          <Image style={styles.clientsImage} src="/assets/images/clients/image3.png"/>
          <Image style={styles.clientsImage} src="/assets/images/clients/image4.png"/>

       </View>
       <View style={styles.clientsImages}>
          <Image style={styles.clientsImage} src="/assets/images/clients/image5.png"/>
          <Image style={styles.clientsImage} src="/assets/images/clients/image6.png"/>
          <Image style={styles.clientsImage} src="/assets/images/clients/image7.png"/>
          <Image style={styles.clientsImage} src="/assets/images/clients/image8.png"/>

       </View>
       <View style={{...styles.clientsImages, borderBottom:"none"}}>
          <Image style={styles.clientsImage} src="/assets/images/clients/image9.png"/>
          <Image style={styles.clientsImage} src="/assets/images/clients/image10.png"/>
          <Image style={styles.clientsImage} src="/assets/images/clients/image11.png"/>
          <Image style={styles.clientsImage} src="/assets/images/clients/image12.png"/>

       </View>
     <Image style={{width:"100%", objectFit:"cover", marginTop:"-550px", zIndex:"-99", height:"630px"}}  
     src={brand && brand?.rafiky ? "/rafiky/background.png":"/assets/desktop13/Dark Gradient 01.png"}/>      
      
       </View>
    </Page>
  );
};

export default OurClients;
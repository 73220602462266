import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const GreenWay = ({ language }) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={styles.greenImage}>
        <View style={{ marginLeft: '10px' }}>
          <Image
            style={{ ...styles.greenImages, width: '20%', height: 'auto', marginTop: '15px', marginBottom: '10px' }}
            src="/assets/desktop16/logocongress.png"
          />
          <View style={{ marginTop: '10px', fontSize: '9px', color: 'white', width: '50%' }}>
            <Text style={{ fontSize: '11px', marginBottom: '10px', fontWeight: 'normal' }}>
              {language === 'English' ? 'For every event we plant a tree - Sustainable Events' : 'Per ogni evento piantiamo un albero - Eventi Sostenibili'}
            </Text>
            <Text style={{ fontSize: '8px', fontWeight: 'normal' }}>
              {language === 'English'
                ? 'We are an interpreting company committed to promoting environmental sustainability through concrete actions and meaningful collaborations. We actively support charitable platforms such as Treevotion, which allows us to contribute to the planting of trees in regions affected by deforestation.'
                : "Siamo un'azienda di interpretariato impegnata nella promozione della sostenibilità ambientale attraverso azioni concrete e collaborazioni significative. Supportiamo attivamente piattaforme benefiche come Treevotion, che ci consente di contribuire alla piantumazione di alberi nelle regioni colpite dalla deforestazione. "}
            </Text>
            <Text style={{ fontSize: '8px', fontWeight: 'normal' }}>
              {language === 'English'
                ? 'Additionally, we promote hybrid events that minimize the use of traditional interpreting equipment. This approach brings numerous environmental advantages:'
                : `Inoltre, promuoviamo eventi ibridi che minimizzano l'uso di attrezzature di interpretariato tradizionali. Questo approccio comporta numerosi vantaggi ambientali:`}
            </Text>
            <Text style={{ marginBottom: '3px', fontSize: '8px', fontWeight: 'normal', flexDirection:"row", display:"flex" }}>
            <Text style={{fontWeight:"bold"}}>
            {
            language === 'English'
            ? '1. Lower resource consumption: '
            : '1. Minore consumo di risorse: '
            }
            </Text>
            <Text style={{ fontSize: '8px', fontWeight: 'normal' }}>
            {language === 'English'
            ? 'By reducing the need for interpreting booths, audio equipment and specialized transport for interpreters, we decrease overall resource consumption and waste production related to traditional equipment.'
            : 'Riducendo la necessità di cabine di interpretariato, apparecchiature audio e trasporti specializzati per gli interpreti, diminuiamo il consumo complessivo di risorse e la produzione di rifiuti legati alle attrezzature tradizionali.'}
          </Text>
            </Text>
            <Text style={{ marginBottom: '3px', fontSize: '8px', fontWeight: 'normal', flexDirection:"row", display:"flex" }}>
            <Text style={{fontWeight:"bold"}}>
            {language === 'English'
                ? '2. Reduction of emissions: '
                : '2. Riduzione delle emissioni: '}
         
            </Text>
            <Text style={{ fontSize: '8px', fontWeight: 'normal' }}>
            {language === 'English'
                ? 'we help reduce greenhouse gas emissions related to travel and industrial activities.'
                : 'contribuiamo a ridurre le emissioni di gas serra legate ai viaggi e alle attività industriali.'}
         </Text>
            </Text>
            <Text style={{ marginBottom: '3px', fontSize: '8px', fontWeight: 'normal', flexDirection:"row", display:"flex" }}>
            <Text style={{fontWeight:"bold"}}>
            {language === 'English'
                ? '3. Global accessibility: '
                : '3. Accessibilità globale: '}{' '}
            
            </Text>
            <Text style={{ fontSize: '8px', fontWeight: 'normal' }}>
            {language === 'English'
                ? 'Interpreting apps allow interpreters to work from any location with an internet connection, reducing the need for international air travel.'
                : 'Le app di interpretariato consentono agli interpreti di lavorare da qualsiasi luogo con connessione internet, riducendo la necessità di viaggi aerei internazionali.'}{' '}
             </Text>
            </Text>
            <Text style={{ marginBottom: '3px', fontSize: '8px', fontWeight: 'normal', flexDirection:"row", display:"flex" }}>
            <Text style={{fontWeight:"bold"}}>
            {language === 'English'
                ? '4. Promoting sustainable technology: '
                : "4. Promozione della tecnologia sostenibile: "}
           
            </Text>
            <Text style={{ fontSize: '8px', fontWeight: 'normal' }}>
            {language === 'English'
                ? 'We promote sustainable technological innovation in the interpreting industry and inspire others to follow our example.'
                : "promuoviamo l'innovazione tecnologica sostenibile nel settore dell'interpretariato e ispiriamo altri a seguire i nostro esempio."}
            </Text>
            </Text>
            <Text style={{ fontSize: '8px', fontWeight: 'normal' }}>
              {language === 'English'
                ? 'We are also committed to educating our clients and partners about the importance of environmental sustainability in the interpreting industry. Through workshops, webinars and information materials, we spread awareness about sustainable practices and encourage others to join us in adopting more eco-friendly approaches.'
                : "Ci impegniamo inoltre a educare i nostri clienti e partner sull'importanza della sostenibilità ambientale nell'industria dell'interpretariato. Attraverso workshop, webinar e materiali informativi, diffondiamo la consapevolezza sulle pratiche sostenibili e incoraggiamo altri a unirsi a noi nell'adozione di approcci più eco-compatibili."}{' '}
            </Text>
          </View>
          <Text
            style={{ paddingTop: '25px', fontSize: '13px', color: 'white', width: '50%', textTransform: 'uppercase' }}
          >
            {language === 'English' ? `official partners` : `partner ufficiali`}
          </Text>
          <Image
            style={{
              ...styles.greenImages,
              marginLeft: '4px',
              width: '25%',
              marginTop: '10px',
            }}
            src="/assets/desktop16/logo.png"
          />
        </View>
        <Image
          style={{ width: '100%', objectFit: 'fill', marginTop: '-520px', zIndex: '-99', height: '540px' }}
          src="/assets/desktop16/cover.png"
        />
      </View>
    </Page>
  );
};

export default GreenWay;

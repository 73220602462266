import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './styles';

const AboutUs1 = ({ language }) => {
  return (
    <Page id='mission' wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={{ ...styles.header, marginBottom: 0, width: '94%', marginHorizontal: 'auto' }}>
        <View style={{ ...styles.headerItem }}>
          <Text style={styles.headerItemText}>
          {language === 'English' ? `OUR MISSION` : `LA NOSTRA MISSION`}
          </Text>
        </View>
        <View>
          <Image style={styles.headerRightImg} src="/rafiky/logo.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={styles.aboutUs1Body}>
        <Text style={styles.aboutUs1ParaBody}>
          {language === 'English'
            ? `Are you ready to create prestigious and successful events? Welcome to Rafiky!`
            : `Siete pronti a realizzare eventi prestigiosi e di successo? Benvenuti in Rafiky!`}
        </Text>
        <Text style={styles.aboutUs1ParaBody}>
          {language === 'English'
            ? `The past is behind us! We are changing the way we see the interpreting.`
            : `Il passato è alle spalle! Stiamo cambiando il modo in cui vediamo l'interpretariato.`}
        </Text>
        <Text style={styles.aboutUs1ParaBody}>
          {language === 'English'
            ? `What sets us apart is the ability to offer tailor-made solutions for our clients. We are passionate about knowledge, innovation and collaboration, and that is why we are committed to providing cutting-edge solutions, quality customer service and availability throughout Italy and abroad. Whether you want to organize medium or large-scale events, our mission is the success of our clients.`
            : `Ciò che ci contraddistingue è l’abilità di offrire soluzioni su misura per i nostri clienti. Siamo appassionati di conoscenza, innovazione e collaborazione, ed è per questo che ci impegniamo a fornire soluzioni all’avanguardia, un customer service di qualità e disponibilità in tutta Italia e all’estero. Che vogliate organizzare eventi di media o di grande portata, la nostra missione è il successo dei nostri clienti.`}
        </Text>
        <Text style={{ ...styles.aboutUs1ParaBody }}>
          {language === 'English'
            ? `We have broken down all language barriers thanks to a software created by us. Rafiky and our team represent the innovation of a service sector that allows you to communicate with people from all over the world: remote simultaneous translation.`
            : `Abbiamo abbattuto ogni barriera linguistica grazie ad un software creato da noi. Rafiky e il nostro team rappresentano l'innovazione di un settore dei servizi che permette di comunicare con persone di tutto il mondo: la traduzione simultanea a distanza.`}
        </Text>
        <Text style={{ ...styles.aboutUs1ParaBody }}>
          {language === 'English'
            ? `Our mission? Only 17% of the world speaks English as a native or second language. However, in professional meetings and events, English is often the default language. We want to change the narrative. Our mission is to connect the world in all its languages.`
            : `La nostra missione? Solo il 17% del mondo parla inglese come madrelingua o seconda lingua. Tuttavia, nelle riunioni ed eventi professionali, l'inglese è spesso la lingua predefinita. Vogliamo cambiare la narrativa. La nostra missione è connettere il mondo in tutte le sue lingue.`}
        </Text>
        <Text style={{ ...styles.aboutUs1ParaBody }}>
          {language === 'English'
            ? `We imagine a future where everyone can express themselves in their native language, be it Spanish, Mandarin, Arabic, French or any other language. We overcome language barriers, valuing every voice and culture. We connect the world in their language.`
            : `Immaginiamo un futuro dove ognuno può esprimersi nella propria lingua madre, che sia spagnolo, mandarino, arabo, francese o qualsiasi altra lingua. Superiamo le barriere linguistiche, valorizzando ogni voce e cultura. Connettiamo il mondo nella sua lingua.`}
        </Text>
      </View>
      <View style={styles.aboutUs1Footer}>
        <View style={styles.aboutUs1FooterImages1}>
        <Image style={styles.avatars} src="/assets/desktop7/ChiaraMoschitta.png" />
          <Image style={styles.avatars} src="/assets/desktop7/GiuliaCaravasso.png" />
          <Image style={styles.avatars} src="/assets/desktop7/GiuseppeGagliano.png" />
          <Image style={styles.avatars} src="/assets/desktop7/MariaSegreto.png" />
        </View>
        <View style={styles.aboutUs1FooterNames1}>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '42px' }}>
            Chiara Moschitta
            </Text>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '48px' }}>Giulia Caravasso</Text>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '43px' }}>Giuseppe Gagliano</Text>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '43px' }}>
            Maria Segreto
            </Text>
        </View>
        <View style={{...styles.aboutUs1FooterJobs1, marginBottom:"8px"}}>
          <Text style={{ ...styles.aboutUs1FooterJob, marginLeft: '47px', width: '65px' }}>Account Manager</Text>
          <Text style={{ ...styles.aboutUs1FooterJob, marginLeft: '72px', width: '65px' }}>Account Manager</Text>
          <Text style={{ ...styles.aboutUs1FooterJob, marginLeft: '75px', width: '65px' }}>Account Manager</Text>
          <Text style={{ ...styles.aboutUs1FooterJob, marginLeft: '65px'}}>
            {language === "English" ? 'Client Success Manager' : 'Client Success Manager'}
          </Text>
        </View>
        <View style={styles.aboutUs1FooterImages2}>
          <Image style={styles.avatars2} src="/assets/desktop7/MiriamFratacci.png" />
          <Image style={styles.avatars2} src="/assets/desktop7/GiuseppePinelli.png" />
          <Image style={styles.avatars2} src="/assets/desktop7/JimPuccio.png" />
          <Image style={styles.avatars2} src="/assets/desktop7/VeronicaGaudesi.png" />
        </View>
        <View style={styles.aboutUs1FooterNames2}>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '46px' }}>Miriam Fratacci</Text>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '55px' }}>Giuseppe Pinelli</Text>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '67px' }}>Jim Puccio</Text>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '67px' }}>
          Veronica Gaudesi
          </Text>
        </View>
        <View style={styles.aboutUs1FooterJobs2}>
          <Text style={{ ...styles.aboutUs1FooterJob, marginLeft: '47px', width: '65px' }}>Event Planner</Text>
          <Text style={{ ...styles.aboutUs1FooterJob, marginLeft: '67px', width: '75px' }}>Accountant</Text>
          <View style={{ display:"block", flexDirection:"column",  marginLeft:"10px" }}>
          <Text style={{...styles.aboutUs1FooterJob,marginLeft:"57",  width: '75px'}}>
            Interpretation
            </Text>
            <Text style={{...styles.aboutUs1FooterJob, marginLeft:"57",  width: '75px'}}>
            Project Manager
            </Text>
          </View>
          <View style={{ display:"block", flexDirection:"column" }}>
          <Text style={{...styles.aboutUs1FooterJob,marginLeft:"62",  width: '75px'}}>
            Interpretation
            </Text>
            <Text style={{...styles.aboutUs1FooterJob,marginLeft:"62",  width: '75px'}}>
            Project Manager
            </Text>
            </View>
        </View>
      </View>
    </Page>
  );
};

export default AboutUs1;

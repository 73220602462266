export const italianCities = [
    {
      city: "Rome",
      admin_name: "Lazio"
    },
    {
      city: "Milan",
      admin_name: "Lombardy"
    },
    {
      city: "Naples",
      admin_name: "Campania"
    },
    {
      city: "Turin",
      admin_name: "Piedmont"
    },
    {
      city: "Palermo",
      admin_name: "Sicilia"
    },
    {
      city: "Genoa",
      admin_name: "Liguria"
    },
    {
      city: "Bologna",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Florence",
      admin_name: "Tuscany"
    },
    {
      city: "Bari",
      admin_name: "Puglia"
    },
    {
      city: "Catania",
      admin_name: "Sicilia"
    },
    {
      city: "Venice",
      admin_name: "Veneto"
    },
    {
      city: "Verona",
      admin_name: "Veneto"
    },
    {
      city: "Messina",
      admin_name: "Sicilia"
    },
    {
      city: "Padova",
      admin_name: "Veneto"
    },
    {
      city: "Trieste",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Taranto",
      admin_name: "Puglia"
    },
    {
      city: "Brescia",
      admin_name: "Lombardy"
    },
    {
      city: "Parma",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Prato",
      admin_name: "Tuscany"
    },
    {
      city: "Modena",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Reggio di Calabria",
      admin_name: "Calabria"
    },
    {
      city: "Reggio Emilia",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Perugia",
      admin_name: "Umbria"
    },
    {
      city: "Livorno",
      admin_name: "Tuscany"
    },
    {
      city: "Ravenna",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Cagliari",
      admin_name: "Sardegna"
    },
    {
      city: "Foggia",
      admin_name: "Puglia"
    },
    {
      city: "Rimini",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Salerno",
      admin_name: "Campania"
    },
    {
      city: "Ferrara",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Monza",
      admin_name: "Lombardy"
    },
    {
      city: "Latina",
      admin_name: "Lazio"
    },
    {
      city: "Giugliano in Campania",
      admin_name: "Campania"
    },
    {
      city: "Bergamo",
      admin_name: "Lombardy"
    },
    {
      city: "Pescara",
      admin_name: "Abruzzo"
    },
    {
      city: "Siracusa",
      admin_name: "Sicilia"
    },
    {
      city: "Trento",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Forlì",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Sassari",
      admin_name: "Sardegna"
    },
    {
      city: "Vicenza",
      admin_name: "Veneto"
    },
    {
      city: "Terni",
      admin_name: "Umbria"
    },
    {
      city: "Bolzano",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Novara",
      admin_name: "Piedmont"
    },
    {
      city: "Piacenza",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Mestre",
      admin_name: "Veneto"
    },
    {
      city: "Ancona",
      admin_name: "Marche"
    },
    {
      city: "Catanzaro",
      admin_name: "Calabria"
    },
    {
      city: "Sesto San Giovanni",
      admin_name: "Lombardy"
    },
    {
      city: "Torre del Greco",
      admin_name: "Campania"
    },
    {
      city: "Busto Arsizio",
      admin_name: "Lombardy"
    },
    {
      city: "Como",
      admin_name: "Lombardy"
    },
    {
      city: "Casoria",
      admin_name: "Campania"
    },
    {
      city: "Cinisello Balsamo",
      admin_name: "Lombardy"
    },
    {
      city: "Ragusa",
      admin_name: "Sicilia"
    },
    {
      city: "Castellammare di Stabia",
      admin_name: "Campania"
    },
    {
      city: "L’Aquila",
      admin_name: "Abruzzo"
    },
    {
      city: "Portici",
      admin_name: "Campania"
    },
    {
      city: "Afragola",
      admin_name: "Campania"
    },
    {
      city: "Potenza",
      admin_name: "Basilicata"
    },
    {
      city: "Marano di Napoli",
      admin_name: "Campania"
    },
    {
      city: "Legnano",
      admin_name: "Lombardy"
    },
    {
      city: "Aversa",
      admin_name: "Campania"
    },
    {
      city: "San Giorgio a Cremano",
      admin_name: "Campania"
    },
    {
      city: "Gallarate",
      admin_name: "Lombardy"
    },
    {
      city: "Ercolano",
      admin_name: "Campania"
    },
    {
      city: "Cologno Monzese",
      admin_name: "Lombardy"
    },
    {
      city: "Scafati",
      admin_name: "Campania"
    },
    {
      city: "Rho",
      admin_name: "Lombardy"
    },
    {
      city: "Collegno",
      admin_name: "Piedmont"
    },
    {
      city: "Lissone",
      admin_name: "Lombardy"
    },
    {
      city: "Campobasso",
      admin_name: "Molise"
    },
    {
      city: "Nichelino",
      admin_name: "Piedmont"
    },
    {
      city: "Paderno Dugnano",
      admin_name: "Lombardy"
    },
    {
      city: "Seregno",
      admin_name: "Lombardy"
    },
    {
      city: "Torre Annunziata",
      admin_name: "Campania"
    },
    {
      city: "Melito di Napoli",
      admin_name: "Campania"
    },
    {
      city: "Rozzano",
      admin_name: "Lombardy"
    },
    {
      city: "Desio",
      admin_name: "Lombardy"
    },
    {
      city: "Pomigliano d’Arco",
      admin_name: "Campania"
    },
    {
      city: "Saronno",
      admin_name: "Lombardy"
    },
    {
      city: "Cesano Maderno",
      admin_name: "Lombardy"
    },
    {
      city: "Mugnano di Napoli",
      admin_name: "Campania"
    },
    {
      city: "Ciampino",
      admin_name: "Lazio"
    },
    {
      city: "Arzano",
      admin_name: "Campania"
    },
    {
      city: "Corsico",
      admin_name: "Lombardy"
    },
    {
      city: "Grugliasco",
      admin_name: "Piedmont"
    },
    {
      city: "Pioltello",
      admin_name: "Lombardy"
    },
    {
      city: "Sant’Antimo",
      admin_name: "Campania"
    },
    {
      city: "Bollate",
      admin_name: "Lombardy"
    },
    {
      city: "Casalecchio di Reno",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Brugherio",
      admin_name: "Lombardy"
    },
    {
      city: "Limbiate",
      admin_name: "Lombardy"
    },
    {
      city: "Riccione Marina",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Cernusco sul Naviglio",
      admin_name: "Lombardy"
    },
    {
      city: "Angri",
      admin_name: "Campania"
    },
    {
      city: "Villaricca",
      admin_name: "Campania"
    },
    {
      city: "Aosta",
      admin_name: "Valle d’Aosta"
    },
    {
      city: "Frattamaggiore",
      admin_name: "Campania"
    },
    {
      city: "San Donato Milanese",
      admin_name: "Lombardy"
    },
    {
      city: "Santa Maria Capua Vetere",
      admin_name: "Campania"
    },
    {
      city: "Bresso",
      admin_name: "Lombardy"
    },
    {
      city: "San Giuseppe Vesuviano",
      admin_name: "Campania"
    },
    {
      city: "Aci Catena",
      admin_name: "Sicilia"
    },
    {
      city: "Boscoreale",
      admin_name: "Campania"
    },
    {
      city: "Garbagnate Milanese",
      admin_name: "Lombardy"
    },
    {
      city: "Chiavari",
      admin_name: "Liguria"
    },
    {
      city: "Cardito",
      admin_name: "Campania"
    },
    {
      city: "Qualiano",
      admin_name: "Campania"
    },
    {
      city: "Muggiò",
      admin_name: "Lombardy"
    },
    {
      city: "Seriate",
      admin_name: "Lombardy"
    },
    {
      city: "Pompei",
      admin_name: "Campania"
    },
    {
      city: "Seveso",
      admin_name: "Lombardy"
    },
    {
      city: "Meda",
      admin_name: "Lombardy"
    },
    {
      city: "San Giovanni la Punta",
      admin_name: "Sicilia"
    },
    {
      city: "Cusano Milanino",
      admin_name: "Lombardy"
    },
    {
      city: "Cormano",
      admin_name: "Lombardy"
    },
    {
      city: "Frattaminore",
      admin_name: "Campania"
    },
    {
      city: "Senago",
      admin_name: "Lombardy"
    },
    {
      city: "Novate Milanese",
      admin_name: "Lombardy"
    },
    {
      city: "Monserrato",
      admin_name: "Sardegna"
    },
    {
      city: "Trentola",
      admin_name: "Campania"
    },
    {
      city: "Ischia",
      admin_name: "Campania"
    },
    {
      city: "Arese",
      admin_name: "Lombardy"
    },
    {
      city: "Cercola",
      admin_name: "Campania"
    },
    {
      city: "Sant’Antonio Abate",
      admin_name: "Campania"
    },
    {
      city: "Melegnano",
      admin_name: "Lombardy"
    },
    {
      city: "Beinasco",
      admin_name: "Piedmont"
    },
    {
      city: "Cattolica",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Brusciano",
      admin_name: "Campania"
    },
    {
      city: "Sant’Arpino",
      admin_name: "Campania"
    },
    {
      city: "Casandrino",
      admin_name: "Campania"
    },
    {
      city: "Castellanza",
      admin_name: "Lombardy"
    },
    {
      city: "Baranzate",
      admin_name: "Lombardy"
    },
    {
      city: "Solaro",
      admin_name: "Lombardy"
    },
    {
      city: "Varedo",
      admin_name: "Lombardy"
    },
    {
      city: "Monte di Procida",
      admin_name: "Campania"
    },
    {
      city: "Calvizzano",
      admin_name: "Campania"
    },
    {
      city: "Canegrate",
      admin_name: "Lombardy"
    },
    {
      city: "Biassono",
      admin_name: "Lombardy"
    },
    {
      city: "San Gregorio di Catania",
      admin_name: "Sicilia"
    },
    {
      city: "Capodrise",
      admin_name: "Campania"
    },
    {
      city: "Procida",
      admin_name: "Campania"
    },
    {
      city: "San Marzano sul Sarno",
      admin_name: "Campania"
    },
    {
      city: "Cesa",
      admin_name: "Campania"
    },
    {
      city: "Portico di Caserta",
      admin_name: "Campania"
    },
    {
      city: "Casapulla",
      admin_name: "Campania"
    },
    {
      city: "Sant’Agnello",
      admin_name: "Campania"
    },
    {
      city: "Torre Boldone",
      admin_name: "Lombardy"
    },
    {
      city: "Mariglianella",
      admin_name: "Campania"
    },
    {
      city: "Recale",
      admin_name: "Campania"
    },
    {
      city: "Rovellasca",
      admin_name: "Lombardy"
    },
    {
      city: "Cimitile",
      admin_name: "Campania"
    },
    {
      city: "Barlassina",
      admin_name: "Lombardy"
    },
    {
      city: "Carnate",
      admin_name: "Lombardy"
    },
    {
      city: "Isola delle Femmine",
      admin_name: "Sicilia"
    },
    {
      city: "Gorle",
      admin_name: "Lombardy"
    },
    {
      city: "Albiate",
      admin_name: "Lombardy"
    },
    {
      city: "Udine",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Andria",
      admin_name: "Puglia"
    },
    {
      city: "Arezzo",
      admin_name: "Tuscany"
    },
    {
      city: "Cesena",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Pesaro",
      admin_name: "Marche"
    },
    {
      city: "Lecce",
      admin_name: "Puglia"
    },
    {
      city: "Barletta",
      admin_name: "Puglia"
    },
    {
      city: "Alessandria",
      admin_name: "Piedmont"
    },
    {
      city: "La Spezia",
      admin_name: "Liguria"
    },
    {
      city: "Pisa",
      admin_name: "Tuscany"
    },
    {
      city: "Pistoia",
      admin_name: "Tuscany"
    },
    {
      city: "Lucca",
      admin_name: "Tuscany"
    },
    {
      city: "Brindisi",
      admin_name: "Puglia"
    },
    {
      city: "Treviso",
      admin_name: "Veneto"
    },
    {
      city: "Marsala",
      admin_name: "Sicilia"
    },
    {
      city: "Pozzuoli",
      admin_name: "Campania"
    },
    {
      city: "Grosseto",
      admin_name: "Tuscany"
    },
    {
      city: "Varese",
      admin_name: "Lombardy"
    },
    {
      city: "Fiumicino",
      admin_name: "Lazio"
    },
    {
      city: "Caserta",
      admin_name: "Campania"
    },
    {
      city: "Asti",
      admin_name: "Piedmont"
    },
    {
      city: "Gela",
      admin_name: "Sicilia"
    },
    {
      city: "Pavia",
      admin_name: "Lombardy"
    },
    {
      city: "Cremona",
      admin_name: "Lombardy"
    },
    {
      city: "Carpi",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Quartu Sant’Elena",
      admin_name: "Sardegna"
    },
    {
      city: "Altamura",
      admin_name: "Puglia"
    },
    {
      city: "Imola",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Massa",
      admin_name: "Tuscany"
    },
    {
      city: "Cosenza",
      admin_name: "Calabria"
    },
    {
      city: "Viterbo",
      admin_name: "Lazio"
    },
    {
      city: "Trapani",
      admin_name: "Sicilia"
    },
    {
      city: "Vittoria",
      admin_name: "Sicilia"
    },
    {
      city: "Pomezia",
      admin_name: "Lazio"
    },
    {
      city: "Crotone",
      admin_name: "Calabria"
    },
    {
      city: "Vigevano",
      admin_name: "Lombardy"
    },
    {
      city: "Carrara",
      admin_name: "Tuscany"
    },
    {
      city: "Caltanissetta",
      admin_name: "Sicilia"
    },
    {
      city: "Viareggio",
      admin_name: "Tuscany"
    },
    {
      city: "Fano",
      admin_name: "Marche"
    },
    {
      city: "Acerra",
      admin_name: "Campania"
    },
    {
      city: "Savona",
      admin_name: "Liguria"
    },
    {
      city: "Matera",
      admin_name: "Basilicata"
    },
    {
      city: "Molfetta",
      admin_name: "Puglia"
    },
    {
      city: "Olbia",
      admin_name: "Sardegna"
    },
    {
      city: "Benevento",
      admin_name: "Campania"
    },
    {
      city: "Agrigento",
      admin_name: "Sicilia"
    },
    {
      city: "Faenza",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Cerignola",
      admin_name: "Puglia"
    },
    {
      city: "Moncalieri",
      admin_name: "Piedmont"
    },
    {
      city: "Foligno",
      admin_name: "Umbria"
    },
    {
      city: "Manfredonia",
      admin_name: "Puglia"
    },
    {
      city: "Bagheria",
      admin_name: "Sicilia"
    },
    {
      city: "Cuneo",
      admin_name: "Piedmont"
    },
    {
      city: "Tivoli",
      admin_name: "Lazio"
    },
    {
      city: "Trani",
      admin_name: "Puglia"
    },
    {
      city: "Bisceglie",
      admin_name: "Puglia"
    },
    {
      city: "San Remo",
      admin_name: "Liguria"
    },
    {
      city: "Bitonto",
      admin_name: "Puglia"
    },
    {
      city: "Modica",
      admin_name: "Sicilia"
    },
    {
      city: "Teramo",
      admin_name: "Abruzzo"
    },
    {
      city: "Cava de’ Tirreni",
      admin_name: "Campania"
    },
    {
      city: "Montesilvano",
      admin_name: "Abruzzo"
    },
    {
      city: "Siena",
      admin_name: "Tuscany"
    },
    {
      city: "Avellino",
      admin_name: "Campania"
    },
    {
      city: "Velletri",
      admin_name: "Lazio"
    },
    {
      city: "Anzio",
      admin_name: "Lazio"
    },
    {
      city: "Acireale",
      admin_name: "Sicilia"
    },
    {
      city: "San Severo",
      admin_name: "Puglia"
    },
    {
      city: "Pordenone",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Civitavecchia",
      admin_name: "Lazio"
    },
    {
      city: "Battipaglia",
      admin_name: "Campania"
    },
    {
      city: "Mazara del Vallo",
      admin_name: "Sicilia"
    },
    {
      city: "Chieti",
      admin_name: "Abruzzo"
    },
    {
      city: "Rovigo",
      admin_name: "Veneto"
    },
    {
      city: "Scandicci",
      admin_name: "Tuscany"
    },
    {
      city: "Misterbianco",
      admin_name: "Sicilia"
    },
    {
      city: "Nettuno",
      admin_name: "Lazio"
    },
    {
      city: "Ardea",
      admin_name: "Lazio"
    },
    {
      city: "Casalnuovo di Napoli",
      admin_name: "Campania"
    },
    {
      city: "Rivoli",
      admin_name: "Piedmont"
    },
    {
      city: "Mantova",
      admin_name: "Lombardy"
    },
    {
      city: "Sesto Fiorentino",
      admin_name: "Tuscany"
    },
    {
      city: "Chioggia",
      admin_name: "Veneto"
    },
    {
      city: "Empoli",
      admin_name: "Tuscany"
    },
    {
      city: "Monopoli",
      admin_name: "Puglia"
    },
    {
      city: "Lecco",
      admin_name: "Lombardy"
    },
    {
      city: "San Benedetto del Tronto",
      admin_name: "Marche"
    },
    {
      city: "Ascoli Piceno",
      admin_name: "Marche"
    },
    {
      city: "Martina Franca",
      admin_name: "Puglia"
    },
    {
      city: "Settimo Torinese",
      admin_name: "Piedmont"
    },
    {
      city: "Corato",
      admin_name: "Puglia"
    },
    {
      city: "Campi Bisenzio",
      admin_name: "Tuscany"
    },
    {
      city: "Paterno",
      admin_name: "Sicilia"
    },
    {
      city: "Rieti",
      admin_name: "Lazio"
    },
    {
      city: "Frosinone",
      admin_name: "Lazio"
    },
    {
      city: "Vercelli",
      admin_name: "Piedmont"
    },
    {
      city: "Terracina",
      admin_name: "Lazio"
    },
    {
      city: "Capannori",
      admin_name: "Tuscany"
    },
    {
      city: "Lodi",
      admin_name: "Lombardy"
    },
    {
      city: "Marino",
      admin_name: "Lazio"
    },
    {
      city: "Nocera Inferiore",
      admin_name: "Campania"
    },
    {
      city: "Cascina",
      admin_name: "Tuscany"
    },
    {
      city: "Alcamo",
      admin_name: "Sicilia"
    },
    {
      city: "Biella",
      admin_name: "Piedmont"
    },
    {
      city: "Senigallia",
      admin_name: "Marche"
    },
    {
      city: "Bassano del Grappa",
      admin_name: "Veneto"
    },
    {
      city: "Alghero",
      admin_name: "Sardegna"
    },
    {
      city: "Gravina in Puglia",
      admin_name: "Puglia"
    },
    {
      city: "Civitanova Marche",
      admin_name: "Marche"
    },
    {
      city: "Albano Laziale",
      admin_name: "Lazio"
    },
    {
      city: "Avezzano",
      admin_name: "Abruzzo"
    },
    {
      city: "Imperia",
      admin_name: "Liguria"
    },
    {
      city: "San Donà di Piave",
      admin_name: "Veneto"
    },
    {
      city: "Macerata",
      admin_name: "Marche"
    },
    {
      city: "Monterotondo",
      admin_name: "Lazio"
    },
    {
      city: "Barcellona-Pozzo di Gotto",
      admin_name: "Sicilia"
    },
    {
      city: "Merano",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Vasto",
      admin_name: "Abruzzo"
    },
    {
      city: "Sassuolo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Cantù",
      admin_name: "Lombardy"
    },
    {
      city: "Ladispoli",
      admin_name: "Lazio"
    },
    {
      city: "Marcianise",
      admin_name: "Campania"
    },
    {
      city: "Sciacca",
      admin_name: "Sicilia"
    },
    {
      city: "Rovereto",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Iesi",
      admin_name: "Marche"
    },
    {
      city: "Corigliano Calabro",
      admin_name: "Calabria"
    },
    {
      city: "Eboli",
      admin_name: "Campania"
    },
    {
      city: "Maddaloni",
      admin_name: "Campania"
    },
    {
      city: "Fondi",
      admin_name: "Lazio"
    },
    {
      city: "Fasano",
      admin_name: "Puglia"
    },
    {
      city: "Voghera",
      admin_name: "Lombardy"
    },
    {
      city: "Città di Castello",
      admin_name: "Umbria"
    },
    {
      city: "Schio",
      admin_name: "Veneto"
    },
    {
      city: "Modugno",
      admin_name: "Puglia"
    },
    {
      city: "San Giuliano Milanese",
      admin_name: "Lombardy"
    },
    {
      city: "Carini",
      admin_name: "Sicilia"
    },
    {
      city: "Monreale",
      admin_name: "Sicilia"
    },
    {
      city: "Caivano",
      admin_name: "Campania"
    },
    {
      city: "Mira",
      admin_name: "Veneto"
    },
    {
      city: "Caltagirone",
      admin_name: "Sicilia"
    },
    {
      city: "Cerveteri",
      admin_name: "Lazio"
    },
    {
      city: "Spoleto",
      admin_name: "Umbria"
    },
    {
      city: "Formia",
      admin_name: "Lazio"
    },
    {
      city: "Chieri",
      admin_name: "Piedmont"
    },
    {
      city: "Fermo",
      admin_name: "Marche"
    },
    {
      city: "Cisterna di Latina",
      admin_name: "Lazio"
    },
    {
      city: "Licata",
      admin_name: "Sicilia"
    },
    {
      city: "Rossano",
      admin_name: "Calabria"
    },
    {
      city: "Pinerolo",
      admin_name: "Piedmont"
    },
    {
      city: "Nuoro",
      admin_name: "Sardegna"
    },
    {
      city: "Francavilla Fontana",
      admin_name: "Puglia"
    },
    {
      city: "Cassino",
      admin_name: "Lazio"
    },
    {
      city: "Rende",
      admin_name: "Calabria"
    },
    {
      city: "Augusta",
      admin_name: "Sicilia"
    },
    {
      city: "Somma Vesuviana",
      admin_name: "Campania"
    },
    {
      city: "Canicattì",
      admin_name: "Sicilia"
    },
    {
      city: "Cento",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Adrano",
      admin_name: "Sicilia"
    },
    {
      city: "Conegliano",
      admin_name: "Veneto"
    },
    {
      city: "Segrate",
      admin_name: "Lombardy"
    },
    {
      city: "Belluno",
      admin_name: "Veneto"
    },
    {
      city: "Lanciano",
      admin_name: "Abruzzo"
    },
    {
      city: "Osimo",
      admin_name: "Marche"
    },
    {
      city: "Crema",
      admin_name: "Lombardy"
    },
    {
      city: "Gorizia",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Venaria Reale",
      admin_name: "Piedmont"
    },
    {
      city: "Formigine",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Nola",
      admin_name: "Campania"
    },
    {
      city: "Casale Monferrato",
      admin_name: "Piedmont"
    },
    {
      city: "Vibo Valentia",
      admin_name: "Calabria"
    },
    {
      city: "Termoli",
      admin_name: "Molise"
    },
    {
      city: "Mascalucia",
      admin_name: "Sicilia"
    },
    {
      city: "Piombino",
      admin_name: "Tuscany"
    },
    {
      city: "Castelfranco Veneto",
      admin_name: "Veneto"
    },
    {
      city: "Villafranca di Verona",
      admin_name: "Veneto"
    },
    {
      city: "Abbiategrasso",
      admin_name: "Lombardy"
    },
    {
      city: "Castelfranco Emilia",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "San Lazzaro di Savena",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Lucera",
      admin_name: "Puglia"
    },
    {
      city: "Massafra",
      admin_name: "Puglia"
    },
    {
      city: "Camaiore",
      admin_name: "Tuscany"
    },
    {
      city: "Favara",
      admin_name: "Sicilia"
    },
    {
      city: "Lugo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Milazzo",
      admin_name: "Sicilia"
    },
    {
      city: "Grottaglie",
      admin_name: "Puglia"
    },
    {
      city: "Alba",
      admin_name: "Piedmont"
    },
    {
      city: "Partinico",
      admin_name: "Sicilia"
    },
    {
      city: "Oristano",
      admin_name: "Sardegna"
    },
    {
      city: "Montebelluna",
      admin_name: "Veneto"
    },
    {
      city: "Avola",
      admin_name: "Sicilia"
    },
    {
      city: "Gubbio",
      admin_name: "Umbria"
    },
    {
      city: "San Giuliano Terme",
      admin_name: "Tuscany"
    },
    {
      city: "Sarno",
      admin_name: "Campania"
    },
    {
      city: "Castelvetrano",
      admin_name: "Sicilia"
    },
    {
      city: "Nardò",
      admin_name: "Puglia"
    },
    {
      city: "Verbania",
      admin_name: "Piedmont"
    },
    {
      city: "Ostuni",
      admin_name: "Puglia"
    },
    {
      city: "Manduria",
      admin_name: "Puglia"
    },
    {
      city: "Rosignano Marittimo",
      admin_name: "Tuscany"
    },
    {
      city: "Marigliano",
      admin_name: "Campania"
    },
    {
      city: "Fabriano",
      admin_name: "Marche"
    },
    {
      city: "Treviglio",
      admin_name: "Lombardy"
    },
    {
      city: "Rapallo",
      admin_name: "Liguria"
    },
    {
      city: "Bra",
      admin_name: "Piedmont"
    },
    {
      city: "Selargius",
      admin_name: "Sardegna"
    },
    {
      city: "Canosa di Puglia",
      admin_name: "Puglia"
    },
    {
      city: "Comiso",
      admin_name: "Sicilia"
    },
    {
      city: "Misilmeri",
      admin_name: "Sicilia"
    },
    {
      city: "Parabiago",
      admin_name: "Lombardy"
    },
    {
      city: "Spinea",
      admin_name: "Veneto"
    },
    {
      city: "Monfalcone",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Desenzano del Garda",
      admin_name: "Lombardy"
    },
    {
      city: "Poggibonsi",
      admin_name: "Tuscany"
    },
    {
      city: "Gragnano",
      admin_name: "Campania"
    },
    {
      city: "Mondragone",
      admin_name: "Campania"
    },
    {
      city: "Carmagnola",
      admin_name: "Piedmont"
    },
    {
      city: "Pontedera",
      admin_name: "Tuscany"
    },
    {
      city: "Sant’Anastasia",
      admin_name: "Campania"
    },
    {
      city: "Alatri",
      admin_name: "Lazio"
    },
    {
      city: "Cervia",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Cecina",
      admin_name: "Tuscany"
    },
    {
      city: "Vittorio Veneto",
      admin_name: "Veneto"
    },
    {
      city: "Novi Ligure",
      admin_name: "Piedmont"
    },
    {
      city: "Giarre",
      admin_name: "Sicilia"
    },
    {
      city: "Triggiano",
      admin_name: "Puglia"
    },
    {
      city: "Assisi",
      admin_name: "Umbria"
    },
    {
      city: "Carbonia",
      admin_name: "Sardegna"
    },
    {
      city: "Mogliano Veneto",
      admin_name: "Veneto"
    },
    {
      city: "San Giovanni in Persiceto",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Bacoli",
      admin_name: "Campania"
    },
    {
      city: "Belpasso",
      admin_name: "Sicilia"
    },
    {
      city: "San Miniato",
      admin_name: "Tuscany"
    },
    {
      city: "Porto Sant’Elpidio",
      admin_name: "Marche"
    },
    {
      city: "Gioia del Colle",
      admin_name: "Puglia"
    },
    {
      city: "Mirano",
      admin_name: "Veneto"
    },
    {
      city: "Lainate",
      admin_name: "Lombardy"
    },
    {
      city: "Erice",
      admin_name: "Sicilia"
    },
    {
      city: "Tortona",
      admin_name: "Piedmont"
    },
    {
      city: "Terlizzi",
      admin_name: "Puglia"
    },
    {
      city: "Vimercate",
      admin_name: "Lombardy"
    },
    {
      city: "Enna",
      admin_name: "Sicilia"
    },
    {
      city: "Albignasego",
      admin_name: "Veneto"
    },
    {
      city: "San Giovanni Rotondo",
      admin_name: "Puglia"
    },
    {
      city: "Galatina",
      admin_name: "Puglia"
    },
    {
      city: "Fidenza",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Assèmini",
      admin_name: "Sardegna"
    },
    {
      city: "Scicli",
      admin_name: "Sicilia"
    },
    {
      city: "Falconara Marittima",
      admin_name: "Marche"
    },
    {
      city: "Mesagne",
      admin_name: "Puglia"
    },
    {
      city: "Quarrata",
      admin_name: "Tuscany"
    },
    {
      city: "Noicattaro",
      admin_name: "Puglia"
    },
    {
      city: "Niscemi",
      admin_name: "Sicilia"
    },
    {
      city: "Putignano",
      admin_name: "Puglia"
    },
    {
      city: "Iglesias",
      admin_name: "Sardegna"
    },
    {
      city: "Chivasso",
      admin_name: "Piedmont"
    },
    {
      city: "Santeramo in Colle",
      admin_name: "Puglia"
    },
    {
      city: "Francavilla al Mare",
      admin_name: "Abruzzo"
    },
    {
      city: "Mariano Comense",
      admin_name: "Lombardy"
    },
    {
      city: "San Giovanni Lupatoto",
      admin_name: "Veneto"
    },
    {
      city: "Giussano",
      admin_name: "Lombardy"
    },
    {
      city: "Cesenatico",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Valdagno",
      admin_name: "Veneto"
    },
    {
      city: "Iesolo",
      admin_name: "Veneto"
    },
    {
      city: "Vignola",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Conversano",
      admin_name: "Puglia"
    },
    {
      city: "Termini Imerese",
      admin_name: "Sicilia"
    },
    {
      city: "Arzignano",
      admin_name: "Veneto"
    },
    {
      city: "Sora",
      admin_name: "Lazio"
    },
    {
      city: "Castel Volturno",
      admin_name: "Campania"
    },
    {
      city: "Roseto degli Abruzzi",
      admin_name: "Abruzzo"
    },
    {
      city: "Montichiari",
      admin_name: "Lombardy"
    },
    {
      city: "Correggio",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Mola di Bari",
      admin_name: "Puglia"
    },
    {
      city: "Scandiano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Bagno a Ripoli",
      admin_name: "Tuscany"
    },
    {
      city: "Legnago",
      admin_name: "Veneto"
    },
    {
      city: "Thiene",
      admin_name: "Veneto"
    },
    {
      city: "Dalmine",
      admin_name: "Lombardy"
    },
    {
      city: "Ruvo di Puglia",
      admin_name: "Puglia"
    },
    {
      city: "Genzano di Roma",
      admin_name: "Lazio"
    },
    {
      city: "Portogruaro",
      admin_name: "Veneto"
    },
    {
      city: "Sezze",
      admin_name: "Lazio"
    },
    {
      city: "Magenta",
      admin_name: "Lombardy"
    },
    {
      city: "Ottaviano",
      admin_name: "Campania"
    },
    {
      city: "Montevarchi",
      admin_name: "Tuscany"
    },
    {
      city: "Giulianova",
      admin_name: "Abruzzo"
    },
    {
      city: "Albenga",
      admin_name: "Liguria"
    },
    {
      city: "Sulmona",
      admin_name: "Abruzzo"
    },
    {
      city: "Fossano",
      admin_name: "Piedmont"
    },
    {
      city: "Copertino",
      admin_name: "Puglia"
    },
    {
      city: "Orbassano",
      admin_name: "Piedmont"
    },
    {
      city: "Nocera Superiore",
      admin_name: "Campania"
    },
    {
      city: "Noto",
      admin_name: "Sicilia"
    },
    {
      city: "Pietrasanta",
      admin_name: "Tuscany"
    },
    {
      city: "Ivrea",
      admin_name: "Piedmont"
    },
    {
      city: "Castiglione delle Stiviere",
      admin_name: "Lombardy"
    },
    {
      city: "Montecchio Maggiore",
      admin_name: "Veneto"
    },
    {
      city: "Ventimiglia",
      admin_name: "Liguria"
    },
    {
      city: "Biancavilla",
      admin_name: "Sicilia"
    },
    {
      city: "Capoterra",
      admin_name: "Sardegna"
    },
    {
      city: "Mirandola",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Ceccano",
      admin_name: "Lazio"
    },
    {
      city: "Poggiomarino",
      admin_name: "Campania"
    },
    {
      city: "Lentini",
      admin_name: "Sicilia"
    },
    {
      city: "Fucecchio",
      admin_name: "Tuscany"
    },
    {
      city: "Nova Milanese",
      admin_name: "Lombardy"
    },
    {
      city: "Floridia",
      admin_name: "Sicilia"
    },
    {
      city: "Frascati",
      admin_name: "Lazio"
    },
    {
      city: "Ortona",
      admin_name: "Abruzzo"
    },
    {
      city: "Mentana",
      admin_name: "Lazio"
    },
    {
      city: "Mercato San Severino",
      admin_name: "Campania"
    },
    {
      city: "Vigonza",
      admin_name: "Veneto"
    },
    {
      city: "Palma di Montechiaro",
      admin_name: "Sicilia"
    },
    {
      city: "Selvazzano Dentro",
      admin_name: "Veneto"
    },
    {
      city: "Trezzano sul Naviglio",
      admin_name: "Lombardy"
    },
    {
      city: "Sarzana",
      admin_name: "Liguria"
    },
    {
      city: "Massarosa",
      admin_name: "Tuscany"
    },
    {
      city: "Mondovì",
      admin_name: "Piedmont"
    },
    {
      city: "Sondrio",
      admin_name: "Lombardy"
    },
    {
      city: "Pachino",
      admin_name: "Sicilia"
    },
    {
      city: "Sant’Arcangelo di Romagna",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Porto Torres",
      admin_name: "Sardegna"
    },
    {
      city: "Paese",
      admin_name: "Veneto"
    },
    {
      city: "Ariano Irpino",
      admin_name: "Campania"
    },
    {
      city: "Martellago",
      admin_name: "Veneto"
    },
    {
      city: "Ginosa",
      admin_name: "Puglia"
    },
    {
      city: "Agropoli",
      admin_name: "Campania"
    },
    {
      city: "Gorgonzola",
      admin_name: "Lombardy"
    },
    {
      city: "Casal di Principe",
      admin_name: "Campania"
    },
    {
      city: "Borgomanero",
      admin_name: "Piedmont"
    },
    {
      city: "Cornaredo",
      admin_name: "Lombardy"
    },
    {
      city: "Palestrina",
      admin_name: "Lazio"
    },
    {
      city: "Bressanone",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Comacchio",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Castrovillari",
      admin_name: "Calabria"
    },
    {
      city: "Cortona",
      admin_name: "Tuscany"
    },
    {
      city: "San Bonifacio",
      admin_name: "Veneto"
    },
    {
      city: "Isernia",
      admin_name: "Molise"
    },
    {
      city: "Colle di Val d’Elsa",
      admin_name: "Tuscany"
    },
    {
      city: "Piazza Armerina",
      admin_name: "Sicilia"
    },
    {
      city: "Monsummano",
      admin_name: "Tuscany"
    },
    {
      city: "Pergine Valsugana",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Corciano",
      admin_name: "Umbria"
    },
    {
      city: "Palo del Colle",
      admin_name: "Puglia"
    },
    {
      city: "Montecatini Terme",
      admin_name: "Tuscany"
    },
    {
      city: "Follonica",
      admin_name: "Tuscany"
    },
    {
      city: "Anagni",
      admin_name: "Lazio"
    },
    {
      city: "Argenta",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Grottaferrata",
      admin_name: "Lazio"
    },
    {
      city: "Savigliano",
      admin_name: "Piedmont"
    },
    {
      city: "Romano di Lombardia",
      admin_name: "Lombardy"
    },
    {
      city: "Suzzara",
      admin_name: "Lombardy"
    },
    {
      city: "Rosolini",
      admin_name: "Sicilia"
    },
    {
      city: "Colleferro",
      admin_name: "Lazio"
    },
    {
      city: "Sestu",
      admin_name: "Sardegna"
    },
    {
      city: "Recanati",
      admin_name: "Marche"
    },
    {
      city: "Sessa Aurunca",
      admin_name: "Campania"
    },
    {
      city: "Ferentino",
      admin_name: "Lazio"
    },
    {
      city: "San Salvo",
      admin_name: "Abruzzo"
    },
    {
      city: "Trecate",
      admin_name: "Piedmont"
    },
    {
      city: "Gallipoli",
      admin_name: "Puglia"
    },
    {
      city: "Abano Terme",
      admin_name: "Veneto"
    },
    {
      city: "Castel San Pietro Terme",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Rivalta di Torino",
      admin_name: "Piedmont"
    },
    {
      city: "Bussolengo",
      admin_name: "Veneto"
    },
    {
      city: "Oderzo",
      admin_name: "Veneto"
    },
    {
      city: "Vico Equense",
      admin_name: "Campania"
    },
    {
      city: "Pallazzolo sull’Oglio",
      admin_name: "Lombardy"
    },
    {
      city: "Feltre",
      admin_name: "Veneto"
    },
    {
      city: "Giovinazzo",
      admin_name: "Puglia"
    },
    {
      city: "Pontassieve",
      admin_name: "Tuscany"
    },
    {
      city: "Lastra a Signa",
      admin_name: "Tuscany"
    },
    {
      city: "Cittadella",
      admin_name: "Veneto"
    },
    {
      city: "Gaeta",
      admin_name: "Lazio"
    },
    {
      city: "Sabaudia",
      admin_name: "Lazio"
    },
    {
      city: "Acquaviva delle Fonti",
      admin_name: "Puglia"
    },
    {
      city: "Casarano",
      admin_name: "Puglia"
    },
    {
      city: "Veroli",
      admin_name: "Lazio"
    },
    {
      city: "Gioia Tauro",
      admin_name: "Calabria"
    },
    {
      city: "San Mauro Torinese",
      admin_name: "Piedmont"
    },
    {
      city: "Acri",
      admin_name: "Calabria"
    },
    {
      city: "Sacile",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Montalto Uffugo",
      admin_name: "Calabria"
    },
    {
      city: "Piove di Sacco",
      admin_name: "Veneto"
    },
    {
      city: "Melzo",
      admin_name: "Lombardy"
    },
    {
      city: "Orvieto",
      admin_name: "Umbria"
    },
    {
      city: "Minturno",
      admin_name: "Lazio"
    },
    {
      city: "Acqui Terme",
      admin_name: "Piedmont"
    },
    {
      city: "Viadana",
      admin_name: "Lombardy"
    },
    {
      city: "Villabate",
      admin_name: "Sicilia"
    },
    {
      city: "Cassano d’Adda",
      admin_name: "Lombardy"
    },
    {
      city: "Casamassima",
      admin_name: "Puglia"
    },
    {
      city: "Ceglie Messapico",
      admin_name: "Puglia"
    },
    {
      city: "Rovato",
      admin_name: "Lombardy"
    },
    {
      city: "Salsomaggiore Terme",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Signa",
      admin_name: "Tuscany"
    },
    {
      city: "Spoltore",
      admin_name: "Abruzzo"
    },
    {
      city: "Castellana Grotte",
      admin_name: "Puglia"
    },
    {
      city: "Ariccia",
      admin_name: "Lazio"
    },
    {
      city: "Arcore",
      admin_name: "Lombardy"
    },
    {
      city: "Pescia",
      admin_name: "Tuscany"
    },
    {
      city: "Grumo Nevano",
      admin_name: "Campania"
    },
    {
      city: "Carate Brianza",
      admin_name: "Lombardy"
    },
    {
      city: "Casalgrande",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Tradate",
      admin_name: "Lombardy"
    },
    {
      city: "Ciriè",
      admin_name: "Piedmont"
    },
    {
      city: "Anguillara Sabazia",
      admin_name: "Lazio"
    },
    {
      city: "Tolentino",
      admin_name: "Marche"
    },
    {
      city: "Adria",
      admin_name: "Veneto"
    },
    {
      city: "Pozzallo",
      admin_name: "Sicilia"
    },
    {
      city: "Chiari",
      admin_name: "Lombardy"
    },
    {
      city: "Scorzè",
      admin_name: "Veneto"
    },
    {
      city: "Montemurlo",
      admin_name: "Tuscany"
    },
    {
      city: "San Vito dei Normanni",
      admin_name: "Puglia"
    },
    {
      city: "Bracciano",
      admin_name: "Lazio"
    },
    {
      city: "Narni",
      admin_name: "Umbria"
    },
    {
      city: "Aci Sant’Antonio",
      admin_name: "Sicilia"
    },
    {
      city: "Agliana",
      admin_name: "Tuscany"
    },
    {
      city: "Santa Marinella",
      admin_name: "Lazio"
    },
    {
      city: "Corbetta",
      admin_name: "Lombardy"
    },
    {
      city: "Noci",
      admin_name: "Puglia"
    },
    {
      city: "Forio",
      admin_name: "Campania"
    },
    {
      city: "Valenza",
      admin_name: "Piedmont"
    },
    {
      city: "Palmi",
      admin_name: "Calabria"
    },
    {
      city: "Castelfidardo",
      admin_name: "Marche"
    },
    {
      city: "Palma Campania",
      admin_name: "Campania"
    },
    {
      city: "Valenzano",
      admin_name: "Puglia"
    },
    {
      city: "Bronte",
      admin_name: "Sicilia"
    },
    {
      city: "Ghedi",
      admin_name: "Lombardy"
    },
    {
      city: "Aci Castello",
      admin_name: "Sicilia"
    },
    {
      city: "Ribera",
      admin_name: "Sicilia"
    },
    {
      city: "Zola Predosa",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Castel Maggiore",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Sestri Levante",
      admin_name: "Liguria"
    },
    {
      city: "Marsciano",
      admin_name: "Umbria"
    },
    {
      city: "Siderno Marina",
      admin_name: "Calabria"
    },
    {
      city: "Domodossola",
      admin_name: "Piedmont"
    },
    {
      city: "Laives",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Rutigliano",
      admin_name: "Puglia"
    },
    {
      city: "Capua",
      admin_name: "Campania"
    },
    {
      city: "Terzigno",
      admin_name: "Campania"
    },
    {
      city: "Malnate",
      admin_name: "Lombardy"
    },
    {
      city: "Budrio",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Zagarolo",
      admin_name: "Lazio"
    },
    {
      city: "Borgo San Lorenzo",
      admin_name: "Tuscany"
    },
    {
      city: "Cordenons",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Somma Lombardo",
      admin_name: "Lombardy"
    },
    {
      city: "Savignano sul Rubicone",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Nerviano",
      admin_name: "Lombardy"
    },
    {
      city: "Albino",
      admin_name: "Lombardy"
    },
    {
      city: "Villorba",
      admin_name: "Veneto"
    },
    {
      city: "Santa Maria di Sala",
      admin_name: "Veneto"
    },
    {
      city: "Cassano al Ionio",
      admin_name: "Calabria"
    },
    {
      city: "Piossasco",
      admin_name: "Piedmont"
    },
    {
      city: "Alpignano",
      admin_name: "Piedmont"
    },
    {
      city: "Calenzano",
      admin_name: "Tuscany"
    },
    {
      city: "Marcon",
      admin_name: "Veneto"
    },
    {
      city: "Maranello",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Sona",
      admin_name: "Veneto"
    },
    {
      city: "Pescantina",
      admin_name: "Veneto"
    },
    {
      city: "Valguarnera Caropepe",
      admin_name: "Sicilia"
    },
    {
      city: "Sorrento",
      admin_name: "Campania"
    },
    {
      city: "Polignano a Mare",
      admin_name: "Puglia"
    },
    {
      city: "Baronissi",
      admin_name: "Campania"
    },
    {
      city: "Arco",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Melfi",
      admin_name: "Basilicata"
    },
    {
      city: "Policoro",
      admin_name: "Basilicata"
    },
    {
      city: "Monselice",
      admin_name: "Veneto"
    },
    {
      city: "Porto San Giorgio",
      admin_name: "Marche"
    },
    {
      city: "Carlentini",
      admin_name: "Sicilia"
    },
    {
      city: "Orta Nova",
      admin_name: "Puglia"
    },
    {
      city: "Isola Capo Rizzuto",
      admin_name: "Calabria"
    },
    {
      city: "Bovisio Masciago",
      admin_name: "Lombardy"
    },
    {
      city: "Preganziol",
      admin_name: "Veneto"
    },
    {
      city: "Pianoro",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Tricase",
      admin_name: "Puglia"
    },
    {
      city: "Sìnnai",
      admin_name: "Sardegna"
    },
    {
      city: "Rocca di Papa",
      admin_name: "Lazio"
    },
    {
      city: "Pisticci",
      admin_name: "Basilicata"
    },
    {
      city: "San Giovanni Valdarno",
      admin_name: "Tuscany"
    },
    {
      city: "Vimodrone",
      admin_name: "Lombardy"
    },
    {
      city: "Rubano",
      admin_name: "Veneto"
    },
    {
      city: "Castelfiorentino",
      admin_name: "Tuscany"
    },
    {
      city: "Adelfia",
      admin_name: "Puglia"
    },
    {
      city: "Sant’Elpidio a Mare",
      admin_name: "Marche"
    },
    {
      city: "Concorezzo",
      admin_name: "Lombardy"
    },
    {
      city: "Pavullo nel Frignano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Cadoneghe",
      admin_name: "Veneto"
    },
    {
      city: "Riva del Garda",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Saviano",
      admin_name: "Campania"
    },
    {
      city: "Gussago",
      admin_name: "Lombardy"
    },
    {
      city: "San Casciano in Val di Pesa",
      admin_name: "Tuscany"
    },
    {
      city: "Bareggio",
      admin_name: "Lombardy"
    },
    {
      city: "Carovigno",
      admin_name: "Puglia"
    },
    {
      city: "Erba",
      admin_name: "Lombardy"
    },
    {
      city: "Fiorano Modenese",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Campagna",
      admin_name: "Campania"
    },
    {
      city: "Saluzzo",
      admin_name: "Piedmont"
    },
    {
      city: "Castellaneta",
      admin_name: "Puglia"
    },
    {
      city: "San Giovanni in Fiore",
      admin_name: "Calabria"
    },
    {
      city: "Martinsicuro",
      admin_name: "Abruzzo"
    },
    {
      city: "Torre Maggiore",
      admin_name: "Puglia"
    },
    {
      city: "Grottammare",
      admin_name: "Marche"
    },
    {
      city: "Vedelago",
      admin_name: "Veneto"
    },
    {
      city: "Scordia",
      admin_name: "Sicilia"
    },
    {
      city: "Samarate",
      admin_name: "Lombardy"
    },
    {
      city: "Lentate sul Seveso",
      admin_name: "Lombardy"
    },
    {
      city: "Giaveno",
      admin_name: "Piedmont"
    },
    {
      city: "Agrate Brianza",
      admin_name: "Lombardy"
    },
    {
      city: "Leinì",
      admin_name: "Piedmont"
    },
    {
      city: "Medicina",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Este",
      admin_name: "Veneto"
    },
    {
      city: "Monesiglio",
      admin_name: "Piedmont"
    },
    {
      city: "Bagnacavallo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Lonigo",
      admin_name: "Veneto"
    },
    {
      city: "Cerea",
      admin_name: "Veneto"
    },
    {
      city: "Caravaggio",
      admin_name: "Lombardy"
    },
    {
      city: "Colle Salvetti",
      admin_name: "Tuscany"
    },
    {
      city: "Porto Empedocle",
      admin_name: "Sicilia"
    },
    {
      city: "Capurso",
      admin_name: "Puglia"
    },
    {
      city: "Todi",
      admin_name: "Umbria"
    },
    {
      city: "Palagonia",
      admin_name: "Sicilia"
    },
    {
      city: "Reggello",
      admin_name: "Tuscany"
    },
    {
      city: "Noale",
      admin_name: "Veneto"
    },
    {
      city: "Codogno",
      admin_name: "Lombardy"
    },
    {
      city: "Brunico",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Umbertide",
      admin_name: "Umbria"
    },
    {
      city: "Besana in Brianza",
      admin_name: "Lombardy"
    },
    {
      city: "Bovolone",
      admin_name: "Veneto"
    },
    {
      city: "Ispica",
      admin_name: "Sicilia"
    },
    {
      city: "Sava",
      admin_name: "Puglia"
    },
    {
      city: "Copparo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Codroipo",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Palagiano",
      admin_name: "Puglia"
    },
    {
      city: "Tarquinia",
      admin_name: "Lazio"
    },
    {
      city: "Ponsacco",
      admin_name: "Tuscany"
    },
    {
      city: "Ospitaletto",
      admin_name: "Lombardy"
    },
    {
      city: "Civita Castellana",
      admin_name: "Lazio"
    },
    {
      city: "Nonantola",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Merate",
      admin_name: "Lombardy"
    },
    {
      city: "Pianezza",
      admin_name: "Piedmont"
    },
    {
      city: "Certaldo",
      admin_name: "Tuscany"
    },
    {
      city: "Galliate",
      admin_name: "Piedmont"
    },
    {
      city: "San Cesareo",
      admin_name: "Lazio"
    },
    {
      city: "Potenza Picena",
      admin_name: "Marche"
    },
    {
      city: "Altopascio",
      admin_name: "Tuscany"
    },
    {
      city: "Valmontone",
      admin_name: "Lazio"
    },
    {
      city: "Paola",
      admin_name: "Calabria"
    },
    {
      city: "Azzano Decimo",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Sansepolcro",
      admin_name: "Tuscany"
    },
    {
      city: "Rescaldina",
      admin_name: "Lombardy"
    },
    {
      city: "Fiano Romano",
      admin_name: "Lazio"
    },
    {
      city: "Volpiano",
      admin_name: "Piedmont"
    },
    {
      city: "San Martino Buon Albergo",
      admin_name: "Veneto"
    },
    {
      city: "Mottola",
      admin_name: "Puglia"
    },
    {
      city: "Casalpusterlengo",
      admin_name: "Lombardy"
    },
    {
      city: "Galatone",
      admin_name: "Puglia"
    },
    {
      city: "Surbo",
      admin_name: "Puglia"
    },
    {
      city: "Carugate",
      admin_name: "Lombardy"
    },
    {
      city: "Taurianova",
      admin_name: "Calabria"
    },
    {
      city: "Castenaso",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Omegna",
      admin_name: "Piedmont"
    },
    {
      city: "Cassina de’ Pecchi",
      admin_name: "Lombardy"
    },
    {
      city: "Molinella",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Silvi Paese",
      admin_name: "Abruzzo"
    },
    {
      city: "San Giorgio Ionico",
      admin_name: "Puglia"
    },
    {
      city: "Finale Emilia",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Riposto",
      admin_name: "Sicilia"
    },
    {
      city: "Castelnuovo Rangone",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Opera",
      admin_name: "Lombardy"
    },
    {
      city: "Valeggio sul Mincio",
      admin_name: "Veneto"
    },
    {
      city: "Casalmaggiore",
      admin_name: "Lombardy"
    },
    {
      city: "Mortara",
      admin_name: "Lombardy"
    },
    {
      city: "Dolo",
      admin_name: "Veneto"
    },
    {
      city: "Corridonia",
      admin_name: "Marche"
    },
    {
      city: "Soliera",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Castellarano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Chiaravalle",
      admin_name: "Marche"
    },
    {
      city: "Castiglione del Lago",
      admin_name: "Umbria"
    },
    {
      city: "Fiorenzuola d’Arda",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Cerro Maggiore",
      admin_name: "Lombardy"
    },
    {
      city: "Santa Croce sull’ Arno",
      admin_name: "Tuscany"
    },
    {
      city: "Rubiera",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Calolziocorte",
      admin_name: "Lombardy"
    },
    {
      city: "Pedara",
      admin_name: "Sicilia"
    },
    {
      city: "Malo",
      admin_name: "Veneto"
    },
    {
      city: "Castellammare del Golfo",
      admin_name: "Sicilia"
    },
    {
      city: "Zevio",
      admin_name: "Veneto"
    },
    {
      city: "Guastalla",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Sannicandro Garganico",
      admin_name: "Puglia"
    },
    {
      city: "San Vito al Tagliamento",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Pineto",
      admin_name: "Abruzzo"
    },
    {
      city: "Santa Maria a Vico",
      admin_name: "Campania"
    },
    {
      city: "Città Sant’Angelo",
      admin_name: "Abruzzo"
    },
    {
      city: "Laterza",
      admin_name: "Puglia"
    },
    {
      city: "Campodarsego",
      admin_name: "Veneto"
    },
    {
      city: "Sommacampagna",
      admin_name: "Veneto"
    },
    {
      city: "Cossato",
      admin_name: "Piedmont"
    },
    {
      city: "Rosarno",
      admin_name: "Calabria"
    },
    {
      city: "Cirò Marina",
      admin_name: "Calabria"
    },
    {
      city: "Gualdo Tadino",
      admin_name: "Umbria"
    },
    {
      city: "Trepuzzi",
      admin_name: "Puglia"
    },
    {
      city: "Tavagnacco",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Rosà",
      admin_name: "Veneto"
    },
    {
      city: "Sasso Marconi",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Pontinia",
      admin_name: "Lazio"
    },
    {
      city: "Sorso",
      admin_name: "Sardegna"
    },
    {
      city: "Carmignano",
      admin_name: "Tuscany"
    },
    {
      city: "Massalubrense",
      admin_name: "Campania"
    },
    {
      city: "Magione",
      admin_name: "Umbria"
    },
    {
      city: "Vinci",
      admin_name: "Tuscany"
    },
    {
      city: "Impruneta",
      admin_name: "Tuscany"
    },
    {
      city: "Busto Garolfo",
      admin_name: "Lombardy"
    },
    {
      city: "Mondolfo",
      admin_name: "Marche"
    },
    {
      city: "Arona",
      admin_name: "Piedmont"
    },
    {
      city: "Collecchio",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Montelupo Fiorentino",
      admin_name: "Tuscany"
    },
    {
      city: "Maglie",
      admin_name: "Puglia"
    },
    {
      city: "Cassano delle Murge",
      admin_name: "Puglia"
    },
    {
      city: "Castel San Giorgio",
      admin_name: "Campania"
    },
    {
      city: "Roncade",
      admin_name: "Veneto"
    },
    {
      city: "Villa San Giovanni",
      admin_name: "Calabria"
    },
    {
      city: "Piano di Sorrento",
      admin_name: "Campania"
    },
    {
      city: "Monteroni di Lecce",
      admin_name: "Puglia"
    },
    {
      city: "Trinitapoli",
      admin_name: "Puglia"
    },
    {
      city: "Orbetello",
      admin_name: "Tuscany"
    },
    {
      city: "Travagliato",
      admin_name: "Lombardy"
    },
    {
      city: "Villasanta",
      admin_name: "Lombardy"
    },
    {
      city: "Mascali",
      admin_name: "Sicilia"
    },
    {
      city: "Latiano",
      admin_name: "Puglia"
    },
    {
      city: "Cicciano",
      admin_name: "Campania"
    },
    {
      city: "Alzano Lombardo",
      admin_name: "Lombardy"
    },
    {
      city: "Urbino",
      admin_name: "Marche"
    },
    {
      city: "Priverno",
      admin_name: "Lazio"
    },
    {
      city: "Leno",
      admin_name: "Lombardy"
    },
    {
      city: "Cefalù",
      admin_name: "Sicilia"
    },
    {
      city: "Oleggio",
      admin_name: "Piedmont"
    },
    {
      city: "Squinzano",
      admin_name: "Puglia"
    },
    {
      city: "Fiesole",
      admin_name: "Tuscany"
    },
    {
      city: "Locorotondo",
      admin_name: "Puglia"
    },
    {
      city: "Luino",
      admin_name: "Lombardy"
    },
    {
      city: "Taggia",
      admin_name: "Liguria"
    },
    {
      city: "Amantea",
      admin_name: "Calabria"
    },
    {
      city: "Caselle Torinese",
      admin_name: "Piedmont"
    },
    {
      city: "Marostica",
      admin_name: "Veneto"
    },
    {
      city: "Fisciano",
      admin_name: "Campania"
    },
    {
      city: "Taglio",
      admin_name: "Veneto"
    },
    {
      city: "Artena",
      admin_name: "Lazio"
    },
    {
      city: "Cervignano del Friuli",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Rezzato",
      admin_name: "Lombardy"
    },
    {
      city: "Bondeno",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Veglie",
      admin_name: "Puglia"
    },
    {
      city: "Olgiate Olona",
      admin_name: "Lombardy"
    },
    {
      city: "San Ferdinando di Puglia",
      admin_name: "Puglia"
    },
    {
      city: "Fara in Sabina",
      admin_name: "Lazio"
    },
    {
      city: "Leverano",
      admin_name: "Puglia"
    },
    {
      city: "Vetralla",
      admin_name: "Lazio"
    },
    {
      city: "Tempio Pausania",
      admin_name: "Sardegna"
    },
    {
      city: "Villacidro",
      admin_name: "Sardegna"
    },
    {
      city: "Sarezzo",
      admin_name: "Lombardy"
    },
    {
      city: "Casatenovo",
      admin_name: "Lombardy"
    },
    {
      city: "Montepulciano",
      admin_name: "Tuscany"
    },
    {
      city: "Múggia",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Lariano",
      admin_name: "Lazio"
    },
    {
      city: "Vieste",
      admin_name: "Puglia"
    },
    {
      city: "Montesarchio",
      admin_name: "Campania"
    },
    {
      city: "Statte",
      admin_name: "Puglia"
    },
    {
      city: "Misano Adriatico",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Dueville",
      admin_name: "Veneto"
    },
    {
      city: "Novellara",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Fagnano Olona",
      admin_name: "Lombardy"
    },
    {
      city: "San Prisco",
      admin_name: "Campania"
    },
    {
      city: "Lanuvio",
      admin_name: "Lazio"
    },
    {
      city: "Cavarzere",
      admin_name: "Veneto"
    },
    {
      city: "Sant’Angelo Lodigiano",
      admin_name: "Lombardy"
    },
    {
      city: "San Pietro Vernotico",
      admin_name: "Puglia"
    },
    {
      city: "Forlimpopoli",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Latisana",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Arzachena",
      admin_name: "Sardegna"
    },
    {
      city: "Crispiano",
      admin_name: "Puglia"
    },
    {
      city: "Castel San Giovanni",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Camponogara",
      admin_name: "Veneto"
    },
    {
      city: "Quartucciu",
      admin_name: "Sardegna"
    },
    {
      city: "Grammichele",
      admin_name: "Sicilia"
    },
    {
      city: "Castelfranco di Sotto",
      admin_name: "Tuscany"
    },
    {
      city: "Vigodarzere",
      admin_name: "Veneto"
    },
    {
      city: "Salzano",
      admin_name: "Veneto"
    },
    {
      city: "Nicosia",
      admin_name: "Sicilia"
    },
    {
      city: "San Martino di Lupari",
      admin_name: "Veneto"
    },
    {
      city: "Crevalcore",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Montefiascone",
      admin_name: "Lazio"
    },
    {
      city: "Melilli",
      admin_name: "Sicilia"
    },
    {
      city: "San Gennaro Vesuviano",
      admin_name: "Campania"
    },
    {
      city: "Montespertoli",
      admin_name: "Tuscany"
    },
    {
      city: "San Marco in Lamis",
      admin_name: "Puglia"
    },
    {
      city: "Manerbio",
      admin_name: "Lombardy"
    },
    {
      city: "Calderara di Reno",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Tezze sul Brenta",
      admin_name: "Veneto"
    },
    {
      city: "Patti",
      admin_name: "Sicilia"
    },
    {
      city: "Gricignano d’Aversa",
      admin_name: "Campania"
    },
    {
      city: "Lavagna",
      admin_name: "Liguria"
    },
    {
      city: "Loreto",
      admin_name: "Marche"
    },
    {
      city: "Casale sul Sile",
      admin_name: "Veneto"
    },
    {
      city: "Lavello",
      admin_name: "Basilicata"
    },
    {
      city: "Quattro Castella",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Formello",
      admin_name: "Lazio"
    },
    {
      city: "Rionero in Vulture",
      admin_name: "Basilicata"
    },
    {
      city: "Chiampo",
      admin_name: "Veneto"
    },
    {
      city: "Capaci",
      admin_name: "Sicilia"
    },
    {
      city: "Calcinaia",
      admin_name: "Tuscany"
    },
    {
      city: "Carpenedolo",
      admin_name: "Lombardy"
    },
    {
      city: "Varazze",
      admin_name: "Liguria"
    },
    {
      city: "Palombara Sabina",
      admin_name: "Lazio"
    },
    {
      city: "Cavallino",
      admin_name: "Puglia"
    },
    {
      city: "Castiglion Fiorentino",
      admin_name: "Tuscany"
    },
    {
      city: "Ficarazzi",
      admin_name: "Sicilia"
    },
    {
      city: "Porto Recanati",
      admin_name: "Marche"
    },
    {
      city: "Trebaseleghe",
      admin_name: "Veneto"
    },
    {
      city: "Capo d’Orlando",
      admin_name: "Sicilia"
    },
    {
      city: "Seravezza",
      admin_name: "Tuscany"
    },
    {
      city: "Montegranaro",
      admin_name: "Marche"
    },
    {
      city: "Calcinato",
      admin_name: "Lombardy"
    },
    {
      city: "Cairo Montenotte",
      admin_name: "Liguria"
    },
    {
      city: "Pontecorvo",
      admin_name: "Lazio"
    },
    {
      city: "San Biagio di Callalta",
      admin_name: "Veneto"
    },
    {
      city: "Apricena",
      admin_name: "Puglia"
    },
    {
      city: "Castelnuovo di Verona",
      admin_name: "Veneto"
    },
    {
      city: "Morbegno",
      admin_name: "Lombardy"
    },
    {
      city: "Raffadali",
      admin_name: "Sicilia"
    },
    {
      city: "Barrafranca",
      admin_name: "Sicilia"
    },
    {
      city: "Spilamberto",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Terrasini Favarotta",
      admin_name: "Sicilia"
    },
    {
      city: "Stezzano",
      admin_name: "Lombardy"
    },
    {
      city: "Monteprandone",
      admin_name: "Marche"
    },
    {
      city: "Santa Maria a Monte",
      admin_name: "Tuscany"
    },
    {
      city: "Leonforte",
      admin_name: "Sicilia"
    },
    {
      city: "Noceto",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Bagnolo Mella",
      admin_name: "Lombardy"
    },
    {
      city: "Campiglia Marittima",
      admin_name: "Tuscany"
    },
    {
      city: "Santo Stino di Livenza",
      admin_name: "Veneto"
    },
    {
      city: "Solofra",
      admin_name: "Campania"
    },
    {
      city: "Trezzo sull’Adda",
      admin_name: "Lombardy"
    },
    {
      city: "Mercogliano",
      admin_name: "Campania"
    },
    {
      city: "Borgosesia",
      admin_name: "Piedmont"
    },
    {
      city: "Montecorvino Rovella",
      admin_name: "Campania"
    },
    {
      city: "Pianiga",
      admin_name: "Veneto"
    },
    {
      city: "Arluno",
      admin_name: "Lombardy"
    },
    {
      city: "Monte San Giovanni Campano",
      admin_name: "Lazio"
    },
    {
      city: "Lipari",
      admin_name: "Sicilia"
    },
    {
      city: "Castel Goffredo",
      admin_name: "Lombardy"
    },
    {
      city: "Rivarolo Canavese",
      admin_name: "Piedmont"
    },
    {
      city: "Grumo Appula",
      admin_name: "Puglia"
    },
    {
      city: "Sala Consilina",
      admin_name: "Campania"
    },
    {
      city: "Locri",
      admin_name: "Calabria"
    },
    {
      city: "Francofonte",
      admin_name: "Sicilia"
    },
    {
      city: "Figline Valdarno",
      admin_name: "Tuscany"
    },
    {
      city: "Fontanafredda",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Sant’Agata di Militello",
      admin_name: "Sicilia"
    },
    {
      city: "Sinalunga",
      admin_name: "Tuscany"
    },
    {
      city: "Altavilla Vicentina",
      admin_name: "Veneto"
    },
    {
      city: "Paullo",
      admin_name: "Lombardy"
    },
    {
      city: "Olgiate Comasco",
      admin_name: "Lombardy"
    },
    {
      city: "Pieve di Soligo",
      admin_name: "Veneto"
    },
    {
      city: "Orzinuovi",
      admin_name: "Lombardy"
    },
    {
      city: "Ronchi dei Legionari",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Avigliana",
      admin_name: "Piedmont"
    },
    {
      city: "Anzola dell’Emilia",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Monte Compatri",
      admin_name: "Lazio"
    },
    {
      city: "Lana",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Cinisi",
      admin_name: "Sicilia"
    },
    {
      city: "San Mauro Pascoli",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Camposampiero",
      admin_name: "Veneto"
    },
    {
      city: "Spresiano",
      admin_name: "Veneto"
    },
    {
      city: "San Pietro in Casale",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Gavardo",
      admin_name: "Lombardy"
    },
    {
      city: "Casciana Terme",
      admin_name: "Tuscany"
    },
    {
      city: "Menfi",
      admin_name: "Sicilia"
    },
    {
      city: "Russi",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Borgo San Dalmazzo",
      admin_name: "Piedmont"
    },
    {
      city: "Ugento",
      admin_name: "Puglia"
    },
    {
      city: "Motta Sant’Anastasia",
      admin_name: "Sicilia"
    },
    {
      city: "Valmadrera",
      admin_name: "Lombardy"
    },
    {
      city: "San Severino Marche",
      admin_name: "Marche"
    },
    {
      city: "Taviano",
      admin_name: "Puglia"
    },
    {
      city: "Cornedo Vicentino",
      admin_name: "Veneto"
    },
    {
      city: "Bernalda",
      admin_name: "Basilicata"
    },
    {
      city: "Teano",
      admin_name: "Campania"
    },
    {
      city: "Terranuova Bracciolini",
      admin_name: "Tuscany"
    },
    {
      city: "Eraclea",
      admin_name: "Veneto"
    },
    {
      city: "Lizzanello",
      admin_name: "Puglia"
    },
    {
      city: "Monte Sant’Angelo",
      admin_name: "Puglia"
    },
    {
      city: "Granarolo del l’Emilia",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Villa Literno",
      admin_name: "Campania"
    },
    {
      city: "Monteforte Irpino",
      admin_name: "Campania"
    },
    {
      city: "Occhiobello",
      admin_name: "Rovigo"
    },
    {
      city: "Sedriano",
      admin_name: "Lombardy"
    },
    {
      city: "Sant’Ambrogio di Valpolicella",
      admin_name: "Veneto"
    },
    {
      city: "Spilimbergo",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Bibbiena",
      admin_name: "Tuscany"
    },
    {
      city: "Torri di Quartesolo",
      admin_name: "Veneto"
    },
    {
      city: "Stradella",
      admin_name: "Lombardy"
    },
    {
      city: "Rottofreno",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Tortoreto",
      admin_name: "Abruzzo"
    },
    {
      city: "Taurisano",
      admin_name: "Puglia"
    },
    {
      city: "Casièr",
      admin_name: "Veneto"
    },
    {
      city: "Vecchiano",
      admin_name: "Tuscany"
    },
    {
      city: "Atripalda",
      admin_name: "Campania"
    },
    {
      city: "Portoferraio",
      admin_name: "Tuscany"
    },
    {
      city: "Isola del Liri",
      admin_name: "Lazio"
    },
    {
      city: "Rocca Priora",
      admin_name: "Lazio"
    },
    {
      city: "Lonate Pozzolo",
      admin_name: "Lombardy"
    },
    {
      city: "Pulsano",
      admin_name: "Puglia"
    },
    {
      city: "Lauria Inferiore",
      admin_name: "Basilicata"
    },
    {
      city: "Carmiano",
      admin_name: "Puglia"
    },
    {
      city: "Margherita di Savoia",
      admin_name: "Puglia"
    },
    {
      city: "Susegana",
      admin_name: "Veneto"
    },
    {
      city: "Priolo Gargallo",
      admin_name: "Sicilia"
    },
    {
      city: "Gambettola",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Bitetto",
      admin_name: "Puglia"
    },
    {
      city: "Alfonsine",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Fiume Veneto",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Loano",
      admin_name: "Liguria"
    },
    {
      city: "Cave",
      admin_name: "Lazio"
    },
    {
      city: "Borgaro Torinese",
      admin_name: "Piedmont"
    },
    {
      city: "Casteldaccia",
      admin_name: "Sicilia"
    },
    {
      city: "Caldogno",
      admin_name: "Veneto"
    },
    {
      city: "Santa Flavia",
      admin_name: "Sicilia"
    },
    {
      city: "Gardone Val Trompia",
      admin_name: "Lombardy"
    },
    {
      city: "Serravalle Pistoiese",
      admin_name: "Tuscany"
    },
    {
      city: "Arenzano",
      admin_name: "Liguria"
    },
    {
      city: "Campobello di Mazara",
      admin_name: "Sicilia"
    },
    {
      city: "Finale Ligure",
      admin_name: "Liguria"
    },
    {
      city: "San Michele al Tagliamento",
      admin_name: "Veneto"
    },
    {
      city: "Castenedolo",
      admin_name: "Lombardy"
    },
    {
      city: "Amelia",
      admin_name: "Umbria"
    },
    {
      city: "Maniago",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Lendinara",
      admin_name: "Veneto"
    },
    {
      city: "Giffoni Valle Piana",
      admin_name: "Campania"
    },
    {
      city: "Pellezzano",
      admin_name: "Campania"
    },
    {
      city: "Macerata Campania",
      admin_name: "Campania"
    },
    {
      city: "Mazzarino",
      admin_name: "Sicilia"
    },
    {
      city: "Matino",
      admin_name: "Puglia"
    },
    {
      city: "Sant’Ilario d’Enza",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Castano Primo",
      admin_name: "Lombardy"
    },
    {
      city: "Codigoro",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Campagnano di Roma",
      admin_name: "Lazio"
    },
    {
      city: "Venosa",
      admin_name: "Basilicata"
    },
    {
      city: "Guspini",
      admin_name: "Sardegna"
    },
    {
      city: "Trofarello",
      admin_name: "Piedmont"
    },
    {
      city: "Cologno al Serio",
      admin_name: "Lombardy"
    },
    {
      city: "Ponte San Pietro",
      admin_name: "Lombardy"
    },
    {
      city: "Cisternino",
      admin_name: "Puglia"
    },
    {
      city: "Zero Branco",
      admin_name: "Veneto"
    },
    {
      city: "Carbonera",
      admin_name: "Veneto"
    },
    {
      city: "Poggio a Caiano",
      admin_name: "Tuscany"
    },
    {
      city: "Portomaggiore",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Musile di Piave",
      admin_name: "Veneto"
    },
    {
      city: "Isola della Scala",
      admin_name: "Veneto"
    },
    {
      city: "Ravanusa",
      admin_name: "Sicilia"
    },
    {
      city: "Taormina",
      admin_name: "Sicilia"
    },
    {
      city: "Ovada",
      admin_name: "Piedmont"
    },
    {
      city: "Caorle",
      admin_name: "Veneto"
    },
    {
      city: "Mestrino",
      admin_name: "Veneto"
    },
    {
      city: "Trecastagni",
      admin_name: "Sicilia"
    },
    {
      city: "Bonate di Sopra",
      admin_name: "Lombardy"
    },
    {
      city: "Montegrotto Terme",
      admin_name: "Veneto"
    },
    {
      city: "Camisano Vicentino",
      admin_name: "Veneto"
    },
    {
      city: "Boscotrecase",
      admin_name: "Campania"
    },
    {
      city: "Siniscola",
      admin_name: "Sardegna"
    },
    {
      city: "Sesto Calende",
      admin_name: "Lombardy"
    },
    {
      city: "Riesi",
      admin_name: "Sicilia"
    },
    {
      city: "Melito di Porto Salvo",
      admin_name: "Calabria"
    },
    {
      city: "Nembro",
      admin_name: "Lombardy"
    },
    {
      city: "Castelvetro di Modena",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Montopoli in Val d’Arno",
      admin_name: "Tuscany"
    },
    {
      city: "Scalea",
      admin_name: "Calabria"
    },
    {
      city: "Lurate Caccivio",
      admin_name: "Lombardy"
    },
    {
      city: "Paceco",
      admin_name: "Sicilia"
    },
    {
      city: "Cerese",
      admin_name: "Lombardy"
    },
    {
      city: "Cornate d’Adda",
      admin_name: "Lombardy"
    },
    {
      city: "Avigliano",
      admin_name: "Basilicata"
    },
    {
      city: "Piazzola sul Brenta",
      admin_name: "Veneto"
    },
    {
      city: "Venafro",
      admin_name: "Molise"
    },
    {
      city: "La Maddalena",
      admin_name: "Sardegna"
    },
    {
      city: "Botticino Sera",
      admin_name: "Lombardy"
    },
    {
      city: "Creazzo",
      admin_name: "Veneto"
    },
    {
      city: "Cazzago San Martino",
      admin_name: "Lombardy"
    },
    {
      city: "Alassio",
      admin_name: "Liguria"
    },
    {
      city: "Cividale del Friuli",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Bitritto",
      admin_name: "Puglia"
    },
    {
      city: "Cepagatti",
      admin_name: "Abruzzo"
    },
    {
      city: "Tortolì",
      admin_name: "Sardegna"
    },
    {
      city: "San Giustino",
      admin_name: "Umbria"
    },
    {
      city: "Usmate Velate",
      admin_name: "Lombardy"
    },
    {
      city: "Acate",
      admin_name: "Sicilia"
    },
    {
      city: "Cislago",
      admin_name: "Lombardy"
    },
    {
      city: "Sant’Agata de’ Goti",
      admin_name: "Campania"
    },
    {
      city: "Sant’Antìoco",
      admin_name: "Sardegna"
    },
    {
      city: "Aulla",
      admin_name: "Tuscany"
    },
    {
      city: "Belmonte Mezzagno",
      admin_name: "Sicilia"
    },
    {
      city: "Polistena",
      admin_name: "Calabria"
    },
    {
      city: "Cerreto Guidi",
      admin_name: "Tuscany"
    },
    {
      city: "Fino Mornasco",
      admin_name: "Lombardy"
    },
    {
      city: "Santa Croce Camerina",
      admin_name: "Sicilia"
    },
    {
      city: "Cameri",
      admin_name: "Piedmont"
    },
    {
      city: "Corleone",
      admin_name: "Sicilia"
    },
    {
      city: "Castel Mella",
      admin_name: "Lombardy"
    },
    {
      city: "San Valentino Torio",
      admin_name: "Campania"
    },
    {
      city: "Trevignano",
      admin_name: "Veneto"
    },
    {
      city: "Inzago",
      admin_name: "Lombardy"
    },
    {
      city: "Bertinoro",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Campolongo Maggiore",
      admin_name: "Veneto"
    },
    {
      city: "Montale",
      admin_name: "Tuscany"
    },
    {
      city: "Motta di Livenza",
      admin_name: "Veneto"
    },
    {
      city: "Gemona del Friuli",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Martinengo",
      admin_name: "Lombardy"
    },
    {
      city: "Montechiarugolo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Grezzana",
      admin_name: "Veneto"
    },
    {
      city: "Racale",
      admin_name: "Puglia"
    },
    {
      city: "Celano",
      admin_name: "Abruzzo"
    },
    {
      city: "Montecchio Emilia",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "San Felice sul Panaro",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Monte San Pietro",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Lomazzo",
      admin_name: "Lombardy"
    },
    {
      city: "Medesano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Salò",
      admin_name: "Lombardy"
    },
    {
      city: "Canelli",
      admin_name: "Piedmont"
    },
    {
      city: "Cadelbosco di Sopra",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Barberino di Mugello",
      admin_name: "Tuscany"
    },
    {
      city: "Massa Lombarda",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Casaluce",
      admin_name: "Campania"
    },
    {
      city: "Montignoso",
      admin_name: "Tuscany"
    },
    {
      city: "Barano d’Ischia",
      admin_name: "Campania"
    },
    {
      city: "San Maurizio Canavese",
      admin_name: "Piedmont"
    },
    {
      city: "Ramacca",
      admin_name: "Sicilia"
    },
    {
      city: "Cori",
      admin_name: "Lazio"
    },
    {
      city: "Itri",
      admin_name: "Lazio"
    },
    {
      city: "Capena",
      admin_name: "Lazio"
    },
    {
      city: "Fiuggi",
      admin_name: "Lazio"
    },
    {
      city: "Randazzo",
      admin_name: "Sicilia"
    },
    {
      city: "Vigonovo",
      admin_name: "Veneto"
    },
    {
      city: "Lerici",
      admin_name: "Liguria"
    },
    {
      city: "Nizza Monferrato",
      admin_name: "Piedmont"
    },
    {
      city: "Vanzago",
      admin_name: "Lombardy"
    },
    {
      city: "Alberobello",
      admin_name: "Puglia"
    },
    {
      city: "Coriano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Locate di Triulzi",
      admin_name: "Lombardy"
    },
    {
      city: "Poirino",
      admin_name: "Piedmont"
    },
    {
      city: "Salemi",
      admin_name: "Sicilia"
    },
    {
      city: "Silea",
      admin_name: "Veneto"
    },
    {
      city: "Torre Santa Susanna",
      admin_name: "Puglia"
    },
    {
      city: "Peschiera del Garda",
      admin_name: "Veneto"
    },
    {
      city: "Atessa",
      admin_name: "Abruzzo"
    },
    {
      city: "Recco",
      admin_name: "Liguria"
    },
    {
      city: "Roccapiemonte",
      admin_name: "Campania"
    },
    {
      city: "Castelli Calepio",
      admin_name: "Lombardy"
    },
    {
      city: "Castrolibero",
      admin_name: "Calabria"
    },
    {
      city: "Castelletto sopra Ticino",
      admin_name: "Piedmont"
    },
    {
      city: "Vittuone",
      admin_name: "Lombardy"
    },
    {
      city: "Conselve",
      admin_name: "Veneto"
    },
    {
      city: "Isola Vicentina",
      admin_name: "Veneto"
    },
    {
      city: "Mussomeli",
      admin_name: "Sicilia"
    },
    {
      city: "Ozieri",
      admin_name: "Sardegna"
    },
    {
      city: "Bibbiano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Rignano Flaminio",
      admin_name: "Lazio"
    },
    {
      city: "Castelnovo ne’ Monti",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Santa Teresa di Riva",
      admin_name: "Sicilia"
    },
    {
      city: "Partanna",
      admin_name: "Sicilia"
    },
    {
      city: "Campi Salentina",
      admin_name: "Puglia"
    },
    {
      city: "Trescore Balneario",
      admin_name: "Lombardy"
    },
    {
      city: "Cutro",
      admin_name: "Calabria"
    },
    {
      city: "Concordia Sagittaria",
      admin_name: "Veneto"
    },
    {
      city: "Bagnara Calabra",
      admin_name: "Calabria"
    },
    {
      city: "San Giorgio del Sannio",
      admin_name: "Campania"
    },
    {
      city: "Valdobbiadene",
      admin_name: "Veneto"
    },
    {
      city: "Altofonte",
      admin_name: "Sicilia"
    },
    {
      city: "San Vendemiano",
      admin_name: "Veneto"
    },
    {
      city: "Langhirano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Aradeo",
      admin_name: "Puglia"
    },
    {
      city: "Trabia",
      admin_name: "Sicilia"
    },
    {
      city: "Cittanova",
      admin_name: "Calabria"
    },
    {
      city: "Quinto di Treviso",
      admin_name: "Veneto"
    },
    {
      city: "Bomporto",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Volpago del Montello",
      admin_name: "Veneto"
    },
    {
      city: "Bordighera",
      admin_name: "Liguria"
    },
    {
      city: "Terralba",
      admin_name: "Sardegna"
    },
    {
      city: "Vaprio d’Adda",
      admin_name: "Lombardy"
    },
    {
      city: "Badia Polesine",
      admin_name: "Veneto"
    },
    {
      city: "Verucchio",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Induno Olona",
      admin_name: "Lombardy"
    },
    {
      city: "Vaiano",
      admin_name: "Tuscany"
    },
    {
      city: "Capriolo",
      admin_name: "Lombardy"
    },
    {
      city: "Goito",
      admin_name: "Lombardy"
    },
    {
      city: "Volterra",
      admin_name: "Tuscany"
    },
    {
      city: "Crosia",
      admin_name: "Calabria"
    },
    {
      city: "Montemarciano",
      admin_name: "Marche"
    },
    {
      city: "Mandello del Lario",
      admin_name: "Lombardy"
    },
    {
      city: "Cermenate",
      admin_name: "Lombardy"
    },
    {
      city: "Santo Stefano di Magra",
      admin_name: "Massa-Carrara"
    },
    {
      city: "Novi di Modena",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Sermoneta",
      admin_name: "Lazio"
    },
    {
      city: "Fiumefreddo di Sicilia",
      admin_name: "Sicilia"
    },
    {
      city: "Sant’Egidio alla Vibrata",
      admin_name: "Abruzzo"
    },
    {
      city: "Busca",
      admin_name: "Piedmont"
    },
    {
      city: "Soverato Marina",
      admin_name: "Calabria"
    },
    {
      city: "Elmas",
      admin_name: "Sardegna"
    },
    {
      city: "Bisignano",
      admin_name: "Calabria"
    },
    {
      city: "Cuorgnè",
      admin_name: "Piedmont"
    },
    {
      city: "Oppeano",
      admin_name: "Veneto"
    },
    {
      city: "Sedico",
      admin_name: "Veneto"
    },
    {
      city: "Tolmezzo",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Asola",
      admin_name: "Lombardy"
    },
    {
      city: "Castellamonte",
      admin_name: "Piedmont"
    },
    {
      city: "Cetraro",
      admin_name: "Calabria"
    },
    {
      city: "Vigasio",
      admin_name: "Veneto"
    },
    {
      city: "Cingoli",
      admin_name: "Marche"
    },
    {
      city: "San Sebastiano al Vesuvio",
      admin_name: "Campania"
    },
    {
      city: "Racconigi",
      admin_name: "Piedmont"
    },
    {
      city: "Pogliano Milanese",
      admin_name: "Lombardy"
    },
    {
      city: "Bucine",
      admin_name: "Tuscany"
    },
    {
      city: "Gavirate",
      admin_name: "Lombardy"
    },
    {
      city: "Bruino",
      admin_name: "Piedmont"
    },
    {
      city: "Morrovalle",
      admin_name: "Marche"
    },
    {
      city: "Sovicille",
      admin_name: "Tuscany"
    },
    {
      city: "Bagnolo in Piano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Gambolò",
      admin_name: "Lombardy"
    },
    {
      city: "Cavriago",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Melendugno",
      admin_name: "Puglia"
    },
    {
      city: "Meldola",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Macomer",
      admin_name: "Sardegna"
    },
    {
      city: "Brembate",
      admin_name: "Lombardy"
    },
    {
      city: "Mori",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "San Felice Circeo",
      admin_name: "Lazio"
    },
    {
      city: "Sannicandro di Bari",
      admin_name: "Puglia"
    },
    {
      city: "Inverigo",
      admin_name: "Lombardy"
    },
    {
      city: "San Pancrazio Salentino",
      admin_name: "Puglia"
    },
    {
      city: "Garlasco",
      admin_name: "Lombardy"
    },
    {
      city: "Barga",
      admin_name: "Tuscany"
    },
    {
      city: "Santa Margherita Ligure",
      admin_name: "Liguria"
    },
    {
      city: "Urgnano",
      admin_name: "Lombardy"
    },
    {
      city: "Gassino Torinese",
      admin_name: "Piedmont"
    },
    {
      city: "Lizzano",
      admin_name: "Puglia"
    },
    {
      city: "Ruffano",
      admin_name: "Puglia"
    },
    {
      city: "Magnago",
      admin_name: "Lombardy"
    },
    {
      city: "Campobello di Licata",
      admin_name: "Sicilia"
    },
    {
      city: "Boves",
      admin_name: "Piedmont"
    },
    {
      city: "Pasian di Prato",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Mareno di Piave",
      admin_name: "Veneto"
    },
    {
      city: "Gessate",
      admin_name: "Lombardy"
    },
    {
      city: "Monteriggioni",
      admin_name: "Tuscany"
    },
    {
      city: "Conselice",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Montescaglioso",
      admin_name: "Basilicata"
    },
    {
      city: "Deruta",
      admin_name: "Umbria"
    },
    {
      city: "Castel Bolognese",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Argelato",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Poggio Renatico",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Monticello Conte Otto",
      admin_name: "Veneto"
    },
    {
      city: "San Giovanni in Marignano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Bellinzago Novarese",
      admin_name: "Piedmont"
    },
    {
      city: "Resana",
      admin_name: "Veneto"
    },
    {
      city: "Siano",
      admin_name: "Campania"
    },
    {
      city: "Giardini",
      admin_name: "Sicilia"
    },
    {
      city: "Broni",
      admin_name: "Lombardy"
    },
    {
      city: "Piombino Dese",
      admin_name: "Veneto"
    },
    {
      city: "Triuggio",
      admin_name: "Lombardy"
    },
    {
      city: "Dolianova",
      admin_name: "Sardegna"
    },
    {
      city: "Mozzate",
      admin_name: "Lombardy"
    },
    {
      city: "Sorbolo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Matelica",
      admin_name: "Marche"
    },
    {
      city: "Flero",
      admin_name: "Lombardy"
    },
    {
      city: "Loria",
      admin_name: "Veneto"
    },
    {
      city: "Zafferana Etnea",
      admin_name: "Sicilia"
    },
    {
      city: "San Marzano di San Giuseppe",
      admin_name: "Puglia"
    },
    {
      city: "Lavis",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Foiano della Chiana",
      admin_name: "Tuscany"
    },
    {
      city: "Cavriglia",
      admin_name: "Tuscany"
    },
    {
      city: "Maserada sul Piave",
      admin_name: "Veneto"
    },
    {
      city: "Castelleone",
      admin_name: "Lombardy"
    },
    {
      city: "Monte Porzio Catone",
      admin_name: "Lazio"
    },
    {
      city: "Porto Tolle",
      admin_name: "Veneto"
    },
    {
      city: "Fiesso d’Artico",
      admin_name: "Veneto"
    },
    {
      city: "Besozzo",
      admin_name: "Lombardy"
    },
    {
      city: "Traversetolo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Maserà di Padova",
      admin_name: "Veneto"
    },
    {
      city: "Striano",
      admin_name: "Campania"
    },
    {
      city: "Gatteo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Santa Lucia di Piave",
      admin_name: "Veneto"
    },
    {
      city: "Brugnera",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Marano Vicentino",
      admin_name: "Veneto"
    },
    {
      city: "Capriate San Gervasio",
      admin_name: "Lombardy"
    },
    {
      city: "Nepi",
      admin_name: "Lazio"
    },
    {
      city: "Castel Gandolfo",
      admin_name: "Lazio"
    },
    {
      city: "Legnaro",
      admin_name: "Veneto"
    },
    {
      city: "Istrana",
      admin_name: "Veneto"
    },
    {
      city: "Fossombrone",
      admin_name: "Marche"
    },
    {
      city: "Mendicino",
      admin_name: "Calabria"
    },
    {
      city: "Viagrande",
      admin_name: "Sicilia"
    },
    {
      city: "Aragona",
      admin_name: "Sicilia"
    },
    {
      city: "Villa Castelli",
      admin_name: "Puglia"
    },
    {
      city: "Marnate",
      admin_name: "Lombardy"
    },
    {
      city: "La Loggia",
      admin_name: "Piedmont"
    },
    {
      city: "Mosciano Sant’Angelo",
      admin_name: "Abruzzo"
    },
    {
      city: "Savignano sul Panaro",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Martano",
      admin_name: "Puglia"
    },
    {
      city: "Gaggiano",
      admin_name: "Lombardy"
    },
    {
      city: "Iseo",
      admin_name: "Lombardy"
    },
    {
      city: "Belvedere Marittimo",
      admin_name: "Calabria"
    },
    {
      city: "Castellabate",
      admin_name: "Campania"
    },
    {
      city: "Parabita",
      admin_name: "Puglia"
    },
    {
      city: "Filottrano",
      admin_name: "Marche"
    },
    {
      city: "Missaglia",
      admin_name: "Lombardy"
    },
    {
      city: "Asolo",
      admin_name: "Veneto"
    },
    {
      city: "Pandino",
      admin_name: "Lombardy"
    },
    {
      city: "Pizzo",
      admin_name: "Calabria"
    },
    {
      city: "Trebisacce",
      admin_name: "Calabria"
    },
    {
      city: "Treia",
      admin_name: "Marche"
    },
    {
      city: "Porcari",
      admin_name: "Tuscany"
    },
    {
      city: "Bovalino Marina",
      admin_name: "Calabria"
    },
    {
      city: "Coccaglio",
      admin_name: "Lombardy"
    },
    {
      city: "Folignano",
      admin_name: "Marche"
    },
    {
      city: "Castegnato",
      admin_name: "Lombardy"
    },
    {
      city: "Monteforte d’Alpone",
      admin_name: "Veneto"
    },
    {
      city: "Podenzano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Segni",
      admin_name: "Lazio"
    },
    {
      city: "Pietra Ligure",
      admin_name: "Liguria"
    },
    {
      city: "Cherasco",
      admin_name: "Piedmont"
    },
    {
      city: "Lacchiarella",
      admin_name: "Lombardy"
    },
    {
      city: "Tirano",
      admin_name: "Lombardy"
    },
    {
      city: "Reggiolo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Borgo a Buggiano",
      admin_name: "Tuscany"
    },
    {
      city: "Inveruno",
      admin_name: "Lombardy"
    },
    {
      city: "Teolo",
      admin_name: "Veneto"
    },
    {
      city: "Borgosatollo",
      admin_name: "Lombardy"
    },
    {
      city: "Carignano",
      admin_name: "Piedmont"
    },
    {
      city: "Gonzaga",
      admin_name: "Lombardy"
    },
    {
      city: "Vignate",
      admin_name: "Lombardy"
    },
    {
      city: "Troina",
      admin_name: "Sicilia"
    },
    {
      city: "Malalbergo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Colorno",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Soresina",
      admin_name: "Lombardy"
    },
    {
      city: "Montagnana",
      admin_name: "Veneto"
    },
    {
      city: "Luzzi",
      admin_name: "Calabria"
    },
    {
      city: "Ponte Buggianese",
      admin_name: "Tuscany"
    },
    {
      city: "Serramanna",
      admin_name: "Sardegna"
    },
    {
      city: "Tarcento",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Borgoricco",
      admin_name: "Veneto"
    },
    {
      city: "Curno",
      admin_name: "Lombardy"
    },
    {
      city: "Oggiono",
      admin_name: "Lombardy"
    },
    {
      city: "Verdello",
      admin_name: "Lombardy"
    },
    {
      city: "Zogno",
      admin_name: "Lombardy"
    },
    {
      city: "Petilia Policastro",
      admin_name: "Calabria"
    },
    {
      city: "Aviano",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Monteroni d’Arbia",
      admin_name: "Tuscany"
    },
    {
      city: "Costa Volpino",
      admin_name: "Lombardy"
    },
    {
      city: "Castagneto Carducci",
      admin_name: "Tuscany"
    },
    {
      city: "Tombolo",
      admin_name: "Veneto"
    },
    {
      city: "Castelnuovo Berardenga",
      admin_name: "Tuscany"
    },
    {
      city: "Binasco",
      admin_name: "Lombardy"
    },
    {
      city: "Civitella in Val di Chiana",
      admin_name: "Tuscany"
    },
    {
      city: "Guardiagrele",
      admin_name: "Abruzzo"
    },
    {
      city: "Cogoleto",
      admin_name: "Liguria"
    },
    {
      city: "Cutrofiano",
      admin_name: "Puglia"
    },
    {
      city: "Vergiate",
      admin_name: "Lombardy"
    },
    {
      city: "Farra di Soligo",
      admin_name: "Veneto"
    },
    {
      city: "Roccastrada",
      admin_name: "Tuscany"
    },
    {
      city: "Trissino",
      admin_name: "Veneto"
    },
    {
      city: "Felino",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Galbiate",
      admin_name: "Lombardy"
    },
    {
      city: "Sorisole",
      admin_name: "Lombardy"
    },
    {
      city: "Noventa Vicentina",
      admin_name: "Veneto"
    },
    {
      city: "Villa Guardia",
      admin_name: "Lombardy"
    },
    {
      city: "Palazzolo Acreide",
      admin_name: "Sicilia"
    },
    {
      city: "Subiaco",
      admin_name: "Lazio"
    },
    {
      city: "Ceprano",
      admin_name: "Lazio"
    },
    {
      city: "Laveno-Mombello",
      admin_name: "Lombardy"
    },
    {
      city: "Morlupo",
      admin_name: "Lazio"
    },
    {
      city: "San Giorgio di Piano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Albinea",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Cavenago di Brianza",
      admin_name: "Lombardy"
    },
    {
      city: "Meta",
      admin_name: "Campania"
    },
    {
      city: "Santa Venerina",
      admin_name: "Sicilia"
    },
    {
      city: "Montalto di Castro",
      admin_name: "Lazio"
    },
    {
      city: "Breganze",
      admin_name: "Veneto"
    },
    {
      city: "Vicopisano",
      admin_name: "Tuscany"
    },
    {
      city: "Minerbio",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Luzzara",
      admin_name: "Reggio Emilia"
    },
    {
      city: "Ala",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Clusone",
      admin_name: "Lombardy"
    },
    {
      city: "Lequile",
      admin_name: "Puglia"
    },
    {
      city: "San Giuseppe Iato",
      admin_name: "Sicilia"
    },
    {
      city: "Orte",
      admin_name: "Lazio"
    },
    {
      city: "Castelnuovo di Magra",
      admin_name: "Liguria"
    },
    {
      city: "Minervino Murge",
      admin_name: "Puglia"
    },
    {
      city: "Rossano Veneto",
      admin_name: "Veneto"
    },
    {
      city: "Rignano sull’Arno",
      admin_name: "Tuscany"
    },
    {
      city: "Zanica",
      admin_name: "Lombardy"
    },
    {
      city: "Brandizzo",
      admin_name: "Piedmont"
    },
    {
      city: "Campogalliano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Erchie",
      admin_name: "Puglia"
    },
    {
      city: "Castelnuovo di Porto",
      admin_name: "Lazio"
    },
    {
      city: "Pianella",
      admin_name: "Abruzzo"
    },
    {
      city: "Castelbuono",
      admin_name: "Sicilia"
    },
    {
      city: "Boville Ernica",
      admin_name: "Lazio"
    },
    {
      city: "Origgio",
      admin_name: "Lombardy"
    },
    {
      city: "Casarsa della Delizia",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Vestignè",
      admin_name: "Piedmont"
    },
    {
      city: "Prata di Pordenone",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Monte San Savino",
      admin_name: "Tuscany"
    },
    {
      city: "Sandrigo",
      admin_name: "Veneto"
    },
    {
      city: "Solarino",
      admin_name: "Sicilia"
    },
    {
      city: "Roverbella",
      admin_name: "Lombardy"
    },
    {
      city: "Casorate Primo",
      admin_name: "Lombardy"
    },
    {
      city: "Monte Urano",
      admin_name: "Marche"
    },
    {
      city: "Chiusi",
      admin_name: "Tuscany"
    },
    {
      city: "Spello",
      admin_name: "Umbria"
    },
    {
      city: "Castelnovo di Sotto",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Ronciglione",
      admin_name: "Lazio"
    },
    {
      city: "San Gavino Monreale",
      admin_name: "Sardegna"
    },
    {
      city: "Vallo della Lucania",
      admin_name: "Campania"
    },
    {
      city: "Nogara",
      admin_name: "Veneto"
    },
    {
      city: "Zoppola",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Cologna Veneta",
      admin_name: "Veneto"
    },
    {
      city: "Volvera",
      admin_name: "Piedmont"
    },
    {
      city: "Caerano di San Marco",
      admin_name: "Veneto"
    },
    {
      city: "Bientina",
      admin_name: "Tuscany"
    },
    {
      city: "Vigliano Biellese",
      admin_name: "Piedmont"
    },
    {
      city: "Santhià",
      admin_name: "Piedmont"
    },
    {
      city: "Sortino",
      admin_name: "Sicilia"
    },
    {
      city: "Concordia sulla Secchia",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "San Sperate",
      admin_name: "Sardegna"
    },
    {
      city: "Dorgali",
      admin_name: "Sardegna"
    },
    {
      city: "Gavorrano",
      admin_name: "Tuscany"
    },
    {
      city: "Ittiri",
      admin_name: "Sardegna"
    },
    {
      city: "Leporano",
      admin_name: "Puglia"
    },
    {
      city: "Ponte di Piave",
      admin_name: "Veneto"
    },
    {
      city: "Ferrandina",
      admin_name: "Basilicata"
    },
    {
      city: "Calvisano",
      admin_name: "Lombardy"
    },
    {
      city: "Bovezzo",
      admin_name: "Lombardy"
    },
    {
      city: "Cagli",
      admin_name: "Marche"
    },
    {
      city: "Caprino Veronese",
      admin_name: "Veneto"
    },
    {
      city: "Sanluri",
      admin_name: "Sardegna"
    },
    {
      city: "Sirmione",
      admin_name: "Lombardy"
    },
    {
      city: "Sala Bolognese",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Albano Sant’Alessandro",
      admin_name: "Lombardy"
    },
    {
      city: "Vietri sul Mare",
      admin_name: "Campania"
    },
    {
      city: "Decimomannu",
      admin_name: "Sardegna"
    },
    {
      city: "Fermignano",
      admin_name: "Marche"
    },
    {
      city: "San Damiano d’Asti",
      admin_name: "Piedmont"
    },
    {
      city: "Airola",
      admin_name: "Campania"
    },
    {
      city: "Villafranca Tirrena",
      admin_name: "Sicilia"
    },
    {
      city: "Stra",
      admin_name: "Veneto"
    },
    {
      city: "Fusignano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Fabrica di Roma",
      admin_name: "Lazio"
    },
    {
      city: "Verolanuova",
      admin_name: "Lombardy"
    },
    {
      city: "San Cesario di Lecce",
      admin_name: "Puglia"
    },
    {
      city: "Vedano Olona",
      admin_name: "Lombardy"
    },
    {
      city: "Novoli",
      admin_name: "Puglia"
    },
    {
      city: "Toritto",
      admin_name: "Puglia"
    },
    {
      city: "Fontaniva",
      admin_name: "Veneto"
    },
    {
      city: "Gravellona Toce",
      admin_name: "Piedmont"
    },
    {
      city: "Luni",
      admin_name: "Liguria"
    },
    {
      city: "Massa",
      admin_name: "Tuscany"
    },
    {
      city: "Altavilla Milicia",
      admin_name: "Sicilia"
    },
    {
      city: "Tuscania",
      admin_name: "Lazio"
    },
    {
      city: "Rivolta d’Adda",
      admin_name: "Lombardy"
    },
    {
      city: "Pino Troinese",
      admin_name: "Piedmont"
    },
    {
      city: "Cairate",
      admin_name: "Lombardy"
    },
    {
      city: "Soriano nel Cimino",
      admin_name: "Lazio"
    },
    {
      city: "Calusco d’Adda",
      admin_name: "Lombardy"
    },
    {
      city: "Serramazzoni",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Ponte nell’Alpi",
      admin_name: "Veneto"
    },
    {
      city: "Salice Salentino",
      admin_name: "Puglia"
    },
    {
      city: "Trevi",
      admin_name: "Umbria"
    },
    {
      city: "Monte San Giusto",
      admin_name: "Marche"
    },
    {
      city: "Quarto d’Altino",
      admin_name: "Veneto"
    },
    {
      city: "Venegono Superiore",
      admin_name: "Lombardy"
    },
    {
      city: "Taglio di Po",
      admin_name: "Veneto"
    },
    {
      city: "Vado Ligure",
      admin_name: "Liguria"
    },
    {
      city: "Appiano Gentile",
      admin_name: "Lombardy"
    },
    {
      city: "Piovene Rocchette",
      admin_name: "Veneto"
    },
    {
      city: "None",
      admin_name: "Piedmont"
    },
    {
      city: "Boiano",
      admin_name: "Molise"
    },
    {
      city: "Massa Marittima",
      admin_name: "Tuscany"
    },
    {
      city: "Cervaro",
      admin_name: "Lazio"
    },
    {
      city: "Cuggiono",
      admin_name: "Lombardy"
    },
    {
      city: "Grottaminarda",
      admin_name: "Campania"
    },
    {
      city: "Paliano",
      admin_name: "Lazio"
    },
    {
      city: "Racalmuto",
      admin_name: "Sicilia"
    },
    {
      city: "Agira",
      admin_name: "Sicilia"
    },
    {
      city: "Campoformido",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Villa di Serio",
      admin_name: "Lombardy"
    },
    {
      city: "San Giovanni Gemini",
      admin_name: "Sicilia"
    },
    {
      city: "Vicchio",
      admin_name: "Tuscany"
    },
    {
      city: "Fuscaldo",
      admin_name: "Calabria"
    },
    {
      city: "Costabissara",
      admin_name: "Veneto"
    },
    {
      city: "Gattinara",
      admin_name: "Piedmont"
    },
    {
      city: "San Daniele del Friuli",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Petrosino",
      admin_name: "Sicilia"
    },
    {
      city: "Chiaramonte Gulfi",
      admin_name: "Sicilia"
    },
    {
      city: "Caccamo",
      admin_name: "Sicilia"
    },
    {
      city: "Campomarino",
      admin_name: "Molise"
    },
    {
      city: "San Martino in Rio",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Grumello del Monte",
      admin_name: "Lombardy"
    },
    {
      city: "Montorio al Vomano",
      admin_name: "Abruzzo"
    },
    {
      city: "Forte dei Marmi",
      admin_name: "Tuscany"
    },
    {
      city: "Cariati",
      admin_name: "Calabria"
    },
    {
      city: "Cellole",
      admin_name: "Campania"
    },
    {
      city: "Tavullia",
      admin_name: "Marche"
    },
    {
      city: "Vobarno",
      admin_name: "Lombardy"
    },
    {
      city: "Pisogne",
      admin_name: "Lombardy"
    },
    {
      city: "Breda di Piave",
      admin_name: "Veneto"
    },
    {
      city: "Levico Terme",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Serra Riccò",
      admin_name: "Liguria"
    },
    {
      city: "Tricesimo",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Loreggia",
      admin_name: "Veneto"
    },
    {
      city: "Turbigo",
      admin_name: "Lombardy"
    },
    {
      city: "Carmignano di Brenta",
      admin_name: "Veneto"
    },
    {
      city: "Fara Gera d’Adda",
      admin_name: "Lombardy"
    },
    {
      city: "Cologne",
      admin_name: "Lombardy"
    },
    {
      city: "Colico",
      admin_name: "Lombardy"
    },
    {
      city: "Sacrofano",
      admin_name: "Lazio"
    },
    {
      city: "Manziana",
      admin_name: "Lazio"
    },
    {
      city: "Maracalagonis",
      admin_name: "Sardegna"
    },
    {
      city: "Casteltermini",
      admin_name: "Sicilia"
    },
    {
      city: "Arcugnano",
      admin_name: "Veneto"
    },
    {
      city: "Anacapri",
      admin_name: "Campania"
    },
    {
      city: "Tropea",
      admin_name: "Calabria"
    },
    {
      city: "Cumiana",
      admin_name: "Piedmont"
    },
    {
      city: "Borgonovo Val Tidone",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Chiavenna",
      admin_name: "Lombardy"
    },
    {
      city: "Bosa",
      admin_name: "Sardegna"
    },
    {
      city: "Torrile",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Bolano",
      admin_name: "Liguria"
    },
    {
      city: "Limena",
      admin_name: "Veneto"
    },
    {
      city: "Motta Visconti",
      admin_name: "Lombardy"
    },
    {
      city: "Rizziconi",
      admin_name: "Calabria"
    },
    {
      city: "Crescentino",
      admin_name: "Piedmont"
    },
    {
      city: "Marmirolo",
      admin_name: "Lombardy"
    },
    {
      city: "Toscolano",
      admin_name: "Lombardy"
    },
    {
      city: "Mozzecane",
      admin_name: "Verona"
    },
    {
      city: "Cartoceto",
      admin_name: "Marche"
    },
    {
      city: "Galliera Veneta",
      admin_name: "Veneto"
    },
    {
      city: "Cambiago",
      admin_name: "Lombardy"
    },
    {
      city: "Teggiano",
      admin_name: "Campania"
    },
    {
      city: "Villa Cortese",
      admin_name: "Lombardy"
    },
    {
      city: "Pasiano",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Olginate",
      admin_name: "Lombardy"
    },
    {
      city: "Pelago",
      admin_name: "Tuscany"
    },
    {
      city: "Tavernelle in Val di Pesa",
      admin_name: "Tuscany"
    },
    {
      city: "Avella",
      admin_name: "Campania"
    },
    {
      city: "Carpaneto Piacentino",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Pantelleria",
      admin_name: "Trapani"
    },
    {
      city: "San Colombano al Lambro",
      admin_name: "Lombardy"
    },
    {
      city: "Palagianello",
      admin_name: "Puglia"
    },
    {
      city: "San Gimignano",
      admin_name: "Tuscany"
    },
    {
      city: "Lamporecchio",
      admin_name: "Tuscany"
    },
    {
      city: "Soncino",
      admin_name: "Lombardy"
    },
    {
      city: "Curtarolo",
      admin_name: "Veneto"
    },
    {
      city: "Carnago",
      admin_name: "Lombardy"
    },
    {
      city: "Marcellina",
      admin_name: "Lazio"
    },
    {
      city: "Castellalto",
      admin_name: "Abruzzo"
    },
    {
      city: "Mirabella Eclano",
      admin_name: "Campania"
    },
    {
      city: "Castiglione Olona",
      admin_name: "Lombardy"
    },
    {
      city: "Barge",
      admin_name: "Piedmont"
    },
    {
      city: "Pratola Peligna",
      admin_name: "Abruzzo"
    },
    {
      city: "Agerola",
      admin_name: "Campania"
    },
    {
      city: "Vigarano Mainarda",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Fivizzano",
      admin_name: "Tuscany"
    },
    {
      city: "San Giórgio di Nogaro",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Vitulazio",
      admin_name: "Campania"
    },
    {
      city: "Città della Pieve",
      admin_name: "Umbria"
    },
    {
      city: "Vico del Gargano",
      admin_name: "Puglia"
    },
    {
      city: "Fossò",
      admin_name: "Veneto"
    },
    {
      city: "Vezzano Ligure",
      admin_name: "Liguria"
    },
    {
      city: "Solesino",
      admin_name: "Veneto"
    },
    {
      city: "Luserna San Giovanni",
      admin_name: "Piedmont"
    },
    {
      city: "Nicolosi",
      admin_name: "Sicilia"
    },
    {
      city: "San Filippo della Mela",
      admin_name: "Sicilia"
    },
    {
      city: "Santa Maria di Licodia",
      admin_name: "Sicilia"
    },
    {
      city: "Villa d’Almè",
      admin_name: "Lombardy"
    },
    {
      city: "Spinetoli",
      admin_name: "Marche"
    },
    {
      city: "Melissano",
      admin_name: "Puglia"
    },
    {
      city: "Vergato",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Sarnico",
      admin_name: "Lombardy"
    },
    {
      city: "Cotignola",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Caluso",
      admin_name: "Piedmont"
    },
    {
      city: "Capri",
      admin_name: "Campania"
    },
    {
      city: "Sant’Angelo di Piove di Sacco",
      admin_name: "Veneto"
    },
    {
      city: "Sonnino",
      admin_name: "Lazio"
    },
    {
      city: "Santa Giustina in Colle",
      admin_name: "Veneto"
    },
    {
      city: "Carosino",
      admin_name: "Puglia"
    },
    {
      city: "Adro",
      admin_name: "Lombardy"
    },
    {
      city: "Staranzano",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Saltara",
      admin_name: "Marche"
    },
    {
      city: "Borgetto",
      admin_name: "Sicilia"
    },
    {
      city: "Roccasecca",
      admin_name: "Lazio"
    },
    {
      city: "Ferno",
      admin_name: "Lombardy"
    },
    {
      city: "Calimera",
      admin_name: "Puglia"
    },
    {
      city: "Mapello",
      admin_name: "Lombardy"
    },
    {
      city: "Candelo",
      admin_name: "Piedmont"
    },
    {
      city: "Brisighella",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Naro",
      admin_name: "Sicilia"
    },
    {
      city: "Cavaria",
      admin_name: "Lombardy"
    },
    {
      city: "Castello di Godego",
      admin_name: "Veneto"
    },
    {
      city: "Castel Madama",
      admin_name: "Lazio"
    },
    {
      city: "Passirano",
      admin_name: "Lombardy"
    },
    {
      city: "Camerano",
      admin_name: "Marche"
    },
    {
      city: "Lodi Vecchio",
      admin_name: "Lombardy"
    },
    {
      city: "Dairago",
      admin_name: "Lombardy"
    },
    {
      city: "Cormòns",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Sant’Agata Bolognese",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Vallecrosia",
      admin_name: "Liguria"
    },
    {
      city: "Zanè",
      admin_name: "Veneto"
    },
    {
      city: "Campofelice di Roccella",
      admin_name: "Sicilia"
    },
    {
      city: "Pieve di Cento",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Nole",
      admin_name: "Piedmont"
    },
    {
      city: "San Zenone",
      admin_name: "Veneto"
    },
    {
      city: "Canicattini Bagni",
      admin_name: "Sicilia"
    },
    {
      city: "Longiano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Loreto Aprutino",
      admin_name: "Abruzzo"
    },
    {
      city: "Volta Mantovana",
      admin_name: "Lombardy"
    },
    {
      city: "Brugine",
      admin_name: "Veneto"
    },
    {
      city: "San Marco Argentano",
      admin_name: "Calabria"
    },
    {
      city: "Buttapietra",
      admin_name: "Veneto"
    },
    {
      city: "Torregrotta",
      admin_name: "Sicilia"
    },
    {
      city: "Tito",
      admin_name: "Basilicata"
    },
    {
      city: "Poviglio",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Balestrate",
      admin_name: "Sicilia"
    },
    {
      city: "Ceriano Laghetto",
      admin_name: "Lombardy"
    },
    {
      city: "Arconate",
      admin_name: "Lombardy"
    },
    {
      city: "Rufina",
      admin_name: "Tuscany"
    },
    {
      city: "Soave",
      admin_name: "Veneto"
    },
    {
      city: "Sennori",
      admin_name: "Sardegna"
    },
    {
      city: "Sparanise",
      admin_name: "Campania"
    },
    {
      city: "Torrita di Siena",
      admin_name: "Tuscany"
    },
    {
      city: "Zelo Buon Persico",
      admin_name: "Lombardy"
    },
    {
      city: "Lignano Sabbiadoro",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Canova",
      admin_name: "Lombardy"
    },
    {
      city: "Pederobba",
      admin_name: "Veneto"
    },
    {
      city: "Roggiano Gravina",
      admin_name: "Calabria"
    },
    {
      city: "Noventa di Piave",
      admin_name: "Veneto"
    },
    {
      city: "San Fior di Sopra",
      admin_name: "Veneto"
    },
    {
      city: "Manciano",
      admin_name: "Tuscany"
    },
    {
      city: "Castiglione della Pescaia",
      admin_name: "Tuscany"
    },
    {
      city: "Campagna Lupia",
      admin_name: "Veneto"
    },
    {
      city: "Montecosaro",
      admin_name: "Marche"
    },
    {
      city: "Cavezzo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Montecassiano",
      admin_name: "Marche"
    },
    {
      city: "Cordignano",
      admin_name: "Veneto"
    },
    {
      city: "Novafeltria",
      admin_name: "Marche"
    },
    {
      city: "Arpino",
      admin_name: "Lazio"
    },
    {
      city: "Bardolino",
      admin_name: "Veneto"
    },
    {
      city: "Militello in Val di Catania",
      admin_name: "Sicilia"
    },
    {
      city: "Montelabbate",
      admin_name: "Marche"
    },
    {
      city: "Cernobbio",
      admin_name: "Lombardy"
    },
    {
      city: "Montalbano Ionico",
      admin_name: "Basilicata"
    },
    {
      city: "Gioiosa Ionica",
      admin_name: "Calabria"
    },
    {
      city: "Carinola",
      admin_name: "Campania"
    },
    {
      city: "Povegliano Veronese",
      admin_name: "Veneto"
    },
    {
      city: "Chianciano Terme",
      admin_name: "Tuscany"
    },
    {
      city: "Vazzola",
      admin_name: "Veneto"
    },
    {
      city: "Cagnano Varano",
      admin_name: "Puglia"
    },
    {
      city: "Corte Franca",
      admin_name: "Lombardy"
    },
    {
      city: "Pontremoli",
      admin_name: "Tuscany"
    },
    {
      city: "Regalbuto",
      admin_name: "Sicilia"
    },
    {
      city: "Rovello Porro",
      admin_name: "Lombardy"
    },
    {
      city: "Rivergaro",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Varallo",
      admin_name: "Piedmont"
    },
    {
      city: "Altavilla Silentina",
      admin_name: "Campania"
    },
    {
      city: "Spino d’Adda",
      admin_name: "Lombardy"
    },
    {
      city: "Camerota",
      admin_name: "Campania"
    },
    {
      city: "Campli",
      admin_name: "Abruzzo"
    },
    {
      city: "Gioiosa Marea",
      admin_name: "Sicilia"
    },
    {
      city: "Pegognaga",
      admin_name: "Lombardy"
    },
    {
      city: "Cassolnovo",
      admin_name: "Lombardy"
    },
    {
      city: "Spezzano Albanese",
      admin_name: "Calabria"
    },
    {
      city: "Santi Cosma e Damiano",
      admin_name: "Lazio"
    },
    {
      city: "Grazzanise",
      admin_name: "Campania"
    },
    {
      city: "Sapri",
      admin_name: "Campania"
    },
    {
      city: "Cles",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Trino",
      admin_name: "Piedmont"
    },
    {
      city: "Baricella",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Caulonia",
      admin_name: "Calabria"
    },
    {
      city: "Dronero",
      admin_name: "Piedmont"
    },
    {
      city: "Vernole",
      admin_name: "Puglia"
    },
    {
      city: "Scorrano",
      admin_name: "Puglia"
    },
    {
      city: "Pontevico",
      admin_name: "Lombardy"
    },
    {
      city: "Urbania",
      admin_name: "Marche"
    },
    {
      city: "Vipiteno",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Centallo",
      admin_name: "Piedmont"
    },
    {
      city: "Venegono Inferiore",
      admin_name: "Lombardy"
    },
    {
      city: "Fontanellato",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Mezzolombardo",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Cava Manara",
      admin_name: "Lombardy"
    },
    {
      city: "Sassoferrato",
      admin_name: "Marche"
    },
    {
      city: "Zibido San Giacomo",
      admin_name: "Lombardy"
    },
    {
      city: "Quiliano",
      admin_name: "Liguria"
    },
    {
      city: "Morciano di Romagna",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Buttigliera Alta",
      admin_name: "Piedmont"
    },
    {
      city: "Pozzuolo del Friuli",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Orosei",
      admin_name: "Sardegna"
    },
    {
      city: "Pantigliate",
      admin_name: "Lombardy"
    },
    {
      city: "Roccadaspide",
      admin_name: "Campania"
    },
    {
      city: "Campomorone",
      admin_name: "Liguria"
    },
    {
      city: "Asciano",
      admin_name: "Tuscany"
    },
    {
      city: "Troia",
      admin_name: "Puglia"
    },
    {
      city: "Serino",
      admin_name: "Campania"
    },
    {
      city: "Monte San Vito",
      admin_name: "Marche"
    },
    {
      city: "Manoppello",
      admin_name: "Abruzzo"
    },
    {
      city: "Roncoferraro",
      admin_name: "Lombardy"
    },
    {
      city: "Casteggio",
      admin_name: "Lombardy"
    },
    {
      city: "Borgo a Mozzano",
      admin_name: "Tuscany"
    },
    {
      city: "Borgo",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Lazise",
      admin_name: "Veneto"
    },
    {
      city: "Martignacco",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Senise",
      admin_name: "Basilicata"
    },
    {
      city: "San Benedetto Po",
      admin_name: "Lombardy"
    },
    {
      city: "Oliena",
      admin_name: "Sardegna"
    },
    {
      city: "Settimo San Pietro",
      admin_name: "Sardegna"
    },
    {
      city: "Albisola Marina",
      admin_name: "Liguria"
    },
    {
      city: "Pignola",
      admin_name: "Basilicata"
    },
    {
      city: "Busseto",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Bellante",
      admin_name: "Abruzzo"
    },
    {
      city: "Gradisca d’Isonzo",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Cerano",
      admin_name: "Piedmont"
    },
    {
      city: "Villasor",
      admin_name: "Sardegna"
    },
    {
      city: "Marina di Gioiosa Ionica",
      admin_name: "Calabria"
    },
    {
      city: "Marcallo con Casone",
      admin_name: "Lombardy"
    },
    {
      city: "Camerino",
      admin_name: "Marche"
    },
    {
      city: "Praia a Mare",
      admin_name: "Calabria"
    },
    {
      city: "Olevano sul Tusciano",
      admin_name: "Campania"
    },
    {
      city: "Alliste",
      admin_name: "Puglia"
    },
    {
      city: "Roccarainola",
      admin_name: "Campania"
    },
    {
      city: "Villadossola",
      admin_name: "Piedmont"
    },
    {
      city: "Santa Giustina",
      admin_name: "Veneto"
    },
    {
      city: "Arosio",
      admin_name: "Lombardy"
    },
    {
      city: "Borgo Val di Taro",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "San Cipriano Picentino",
      admin_name: "Campania"
    },
    {
      city: "Marzabotto",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Caraglio",
      admin_name: "Piedmont"
    },
    {
      city: "Lercara Friddi",
      admin_name: "Sicilia"
    },
    {
      city: "Mileto",
      admin_name: "Calabria"
    },
    {
      city: "Salgareda",
      admin_name: "Veneto"
    },
    {
      city: "Olevano Romano",
      admin_name: "Lazio"
    },
    {
      city: "Camposano",
      admin_name: "Campania"
    },
    {
      city: "Ostiglia",
      admin_name: "Lombardy"
    },
    {
      city: "Mercato Saraceno",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Brendola",
      admin_name: "Veneto"
    },
    {
      city: "Sommatino",
      admin_name: "Sicilia"
    },
    {
      city: "Almenno San Salvatore",
      admin_name: "Lombardy"
    },
    {
      city: "Pietraperzia",
      admin_name: "Sicilia"
    },
    {
      city: "Sant’Agostino",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Nervesa della Battaglia",
      admin_name: "Veneto"
    },
    {
      city: "Montegiorgio",
      admin_name: "Marche"
    },
    {
      city: "Mesola",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Montebello Vicentino",
      admin_name: "Veneto"
    },
    {
      city: "Piedimonte San Germano",
      admin_name: "Lazio"
    },
    {
      city: "Torgiano",
      admin_name: "Umbria"
    },
    {
      city: "Almenno San Bartolomeo",
      admin_name: "Lombardy"
    },
    {
      city: "San Vincenzo",
      admin_name: "Tuscany"
    },
    {
      city: "Nova Siri",
      admin_name: "Basilicata"
    },
    {
      city: "Cittaducale",
      admin_name: "Lazio"
    },
    {
      city: "Castello d’Argile",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Bolzano Vicentino",
      admin_name: "Veneto"
    },
    {
      city: "Robecco sul Naviglio",
      admin_name: "Lombardy"
    },
    {
      city: "Sermide",
      admin_name: "Lombardy"
    },
    {
      city: "Landriano",
      admin_name: "Lombardy"
    },
    {
      city: "Almese",
      admin_name: "Piedmont"
    },
    {
      city: "Castel di Sangro",
      admin_name: "Abruzzo"
    },
    {
      city: "Poggio Rusco",
      admin_name: "Lombardy"
    },
    {
      city: "Mede",
      admin_name: "Lombardy"
    },
    {
      city: "Cellamare",
      admin_name: "Puglia"
    },
    {
      city: "Ciserano",
      admin_name: "Lombardy"
    },
    {
      city: "Cornuda",
      admin_name: "Veneto"
    },
    {
      city: "Serra San Bruno",
      admin_name: "Calabria"
    },
    {
      city: "Montelepre",
      admin_name: "Sicilia"
    },
    {
      city: "San Donaci",
      admin_name: "Puglia"
    },
    {
      city: "Larino",
      admin_name: "Molise"
    },
    {
      city: "Tagliacozzo",
      admin_name: "Abruzzo"
    },
    {
      city: "Ghisalba",
      admin_name: "Lombardy"
    },
    {
      city: "Diano Marina",
      admin_name: "Liguria"
    },
    {
      city: "Buia",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Marineo",
      admin_name: "Sicilia"
    },
    {
      city: "Curinga",
      admin_name: "Calabria"
    },
    {
      city: "Verzuolo",
      admin_name: "Piedmont"
    },
    {
      city: "Notaresco",
      admin_name: "Abruzzo"
    },
    {
      city: "San Martino Siccomario",
      admin_name: "Lombardy"
    },
    {
      city: "Pollenza",
      admin_name: "Marche"
    },
    {
      city: "Robbiate",
      admin_name: "Lombardy"
    },
    {
      city: "Cellino San Marco",
      admin_name: "Puglia"
    },
    {
      city: "Cinquefrondi",
      admin_name: "Calabria"
    },
    {
      city: "Carapelle",
      admin_name: "Puglia"
    },
    {
      city: "Avetrana",
      admin_name: "Puglia"
    },
    {
      city: "Trezzano Rosa",
      admin_name: "Lombardy"
    },
    {
      city: "Montenero di Bisaccia",
      admin_name: "Molise"
    },
    {
      city: "Calci",
      admin_name: "Tuscany"
    },
    {
      city: "Misinto",
      admin_name: "Lombardy"
    },
    {
      city: "Pizzighettone",
      admin_name: "Lombardy"
    },
    {
      city: "Vairano Patenora",
      admin_name: "Campania"
    },
    {
      city: "Albizzate",
      admin_name: "Lombardy"
    },
    {
      city: "Marcaria",
      admin_name: "Lombardy"
    },
    {
      city: "Sutri",
      admin_name: "Lazio"
    },
    {
      city: "Dozza",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Gualtieri",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Castronno",
      admin_name: "Lombardy"
    },
    {
      city: "Sammichele di Bari",
      admin_name: "Puglia"
    },
    {
      city: "Capannoli",
      admin_name: "Tuscany"
    },
    {
      city: "Poggio Mirteto",
      admin_name: "Lazio"
    },
    {
      city: "Fabbrico",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Roccella Ionica",
      admin_name: "Calabria"
    },
    {
      city: "Montesano sulla Marcellana",
      admin_name: "Campania"
    },
    {
      city: "Strongoli",
      admin_name: "Calabria"
    },
    {
      city: "Gallicano nel Lazio",
      admin_name: "Lazio"
    },
    {
      city: "Capranica",
      admin_name: "Lazio"
    },
    {
      city: "San Marco Evangelista",
      admin_name: "Campania"
    },
    {
      city: "Meolo",
      admin_name: "Veneto"
    },
    {
      city: "Gonnosfanàdiga",
      admin_name: "Sardegna"
    },
    {
      city: "Calatafimi",
      admin_name: "Sicilia"
    },
    {
      city: "Arcole",
      admin_name: "Veneto"
    },
    {
      city: "Solbiate Olona",
      admin_name: "Lombardy"
    },
    {
      city: "San Giorgio in Bosco",
      admin_name: "Veneto"
    },
    {
      city: "Fossacesia",
      admin_name: "Abruzzo"
    },
    {
      city: "Quinzano d’Oglio",
      admin_name: "Lombardy"
    },
    {
      city: "Codevigo",
      admin_name: "Veneto"
    },
    {
      city: "Albanella",
      admin_name: "Campania"
    },
    {
      city: "Spinazzola",
      admin_name: "Puglia"
    },
    {
      city: "Livigno",
      admin_name: "Lombardy"
    },
    {
      city: "Castelgomberto",
      admin_name: "Veneto"
    },
    {
      city: "Rosolina",
      admin_name: "Veneto"
    },
    {
      city: "Lurago d’Erba",
      admin_name: "Lombardy"
    },
    {
      city: "Villaverla",
      admin_name: "Veneto"
    },
    {
      city: "Sesto al Reghena",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Sant’Arcangelo",
      admin_name: "Basilicata"
    },
    {
      city: "San Cesario sul Panaro",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Brolo",
      admin_name: "Sicilia"
    },
    {
      city: "Pontenure",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Offanengo",
      admin_name: "Lombardy"
    },
    {
      city: "Barzanò",
      admin_name: "Lombardy"
    },
    {
      city: "Casorate Sempione",
      admin_name: "Lombardy"
    },
    {
      city: "Sommariva del Bosco",
      admin_name: "Piedmont"
    },
    {
      city: "Labico",
      admin_name: "Lazio"
    },
    {
      city: "San Michele Salentino",
      admin_name: "Puglia"
    },
    {
      city: "Caneva",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Medolla",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Sernaglia della Battaglia",
      admin_name: "Veneto"
    },
    {
      city: "Pimonte",
      admin_name: "Campania"
    },
    {
      city: "Asiago",
      admin_name: "Veneto"
    },
    {
      city: "Monzuno",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Susa",
      admin_name: "Piedmont"
    },
    {
      city: "Villanuova sul clisi",
      admin_name: "Lombardy"
    },
    {
      city: "Fagagna",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Arquata Scrivia",
      admin_name: "Piedmont"
    },
    {
      city: "Subbiano",
      admin_name: "Tuscany"
    },
    {
      city: "Belgioioso",
      admin_name: "Lombardy"
    },
    {
      city: "Porto Cesareo",
      admin_name: "Puglia"
    },
    {
      city: "Collepasso",
      admin_name: "Puglia"
    },
    {
      city: "Strambino",
      admin_name: "Piedmont"
    },
    {
      city: "San Marcello Pistoiese",
      admin_name: "Tuscany"
    },
    {
      city: "Cambiano",
      admin_name: "Piedmont"
    },
    {
      city: "Abbadia San Salvatore",
      admin_name: "Tuscany"
    },
    {
      city: "Vidigulfo",
      admin_name: "Lombardy"
    },
    {
      city: "Recoaro Terme",
      admin_name: "Veneto"
    },
    {
      city: "San Canzian d’lsonzo",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Monte San Biagio",
      admin_name: "Lazio"
    },
    {
      city: "Ceggia",
      admin_name: "Veneto"
    },
    {
      city: "Rivignano",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "San Giovanni al Natisone",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Fossalta di Portogruaro",
      admin_name: "Veneto"
    },
    {
      city: "Lesina",
      admin_name: "Puglia"
    },
    {
      city: "Cavaion Veronese",
      admin_name: "Veneto"
    },
    {
      city: "Calcinate",
      admin_name: "Lombardy"
    },
    {
      city: "Cisano Bergamasco",
      admin_name: "Lombardy"
    },
    {
      city: "Alessano",
      admin_name: "Puglia"
    },
    {
      city: "Nicotera",
      admin_name: "Calabria"
    },
    {
      city: "Rudiano",
      admin_name: "Lombardy"
    },
    {
      city: "Serravalle Scrivia",
      admin_name: "Piedmont"
    },
    {
      city: "Mesoraca",
      admin_name: "Calabria"
    },
    {
      city: "Cogorno",
      admin_name: "Liguria"
    },
    {
      city: "Vanzaghello",
      admin_name: "Lombardy"
    },
    {
      city: "Predappio Alta",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Tortorici",
      admin_name: "Sicilia"
    },
    {
      city: "Ravarino",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "San Polo d’Enza in Caviano",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Palosco",
      admin_name: "Lombardy"
    },
    {
      city: "Mattinata",
      admin_name: "Puglia"
    },
    {
      city: "Santa Margherita di Belice",
      admin_name: "Sicilia"
    },
    {
      city: "Crocetta del Montello",
      admin_name: "Veneto"
    },
    {
      city: "Trasacco",
      admin_name: "Abruzzo"
    },
    {
      city: "San Benigno Canavese",
      admin_name: "Piedmont"
    },
    {
      city: "Roveredo in Piano",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Besnate",
      admin_name: "Lombardy"
    },
    {
      city: "Remanzacco",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Carloforte",
      admin_name: "Sardegna"
    },
    {
      city: "Poggiardo",
      admin_name: "Puglia"
    },
    {
      city: "Montebello Ionico",
      admin_name: "Calabria"
    },
    {
      city: "San Lucido",
      admin_name: "Calabria"
    },
    {
      city: "Godega di Sant’Urbano",
      admin_name: "Veneto"
    },
    {
      city: "Guidizzolo",
      admin_name: "Lombardy"
    },
    {
      city: "Gaiarine",
      admin_name: "Veneto"
    },
    {
      city: "Casorezzo",
      admin_name: "Lombardy"
    },
    {
      city: "Sumirago",
      admin_name: "Lombardy"
    },
    {
      city: "Collecorvino",
      admin_name: "Abruzzo"
    },
    {
      city: "Cadeo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Silandro",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Cammarata",
      admin_name: "Sicilia"
    },
    {
      city: "Casalbordino",
      admin_name: "Abruzzo"
    },
    {
      city: "Domusnovas",
      admin_name: "Sardegna"
    },
    {
      city: "Motta San Giovanni",
      admin_name: "Calabria"
    },
    {
      city: "Maiolati Spontini",
      admin_name: "Marche"
    },
    {
      city: "Poppi",
      admin_name: "Tuscany"
    },
    {
      city: "Luco dei Marsi",
      admin_name: "Abruzzo"
    },
    {
      city: "Lioni",
      admin_name: "Campania"
    },
    {
      city: "Sant’Elia Fiumerapido",
      admin_name: "Lazio"
    },
    {
      city: "Rio Saliceto",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Ascoli Satriano",
      admin_name: "Puglia"
    },
    {
      city: "Truccazzano",
      admin_name: "Lombardy"
    },
    {
      city: "Torreglia",
      admin_name: "Veneto"
    },
    {
      city: "Tortora",
      admin_name: "Calabria"
    },
    {
      city: "Piana degli Albanesi",
      admin_name: "Sicilia"
    },
    {
      city: "Monteiasi",
      admin_name: "Puglia"
    },
    {
      city: "Quinto Vicentino",
      admin_name: "Veneto"
    },
    {
      city: "Pignataro Maggiore",
      admin_name: "Campania"
    },
    {
      city: "Castelnuovo di Garfagnana",
      admin_name: "Tuscany"
    },
    {
      city: "Vernio",
      admin_name: "Tuscany"
    },
    {
      city: "Santorso",
      admin_name: "Veneto"
    },
    {
      city: "Maiano",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Borso del Grappa",
      admin_name: "Veneto"
    },
    {
      city: "Genazzano",
      admin_name: "Lazio"
    },
    {
      city: "Torrebelvicino",
      admin_name: "Veneto"
    },
    {
      city: "Castelsardo",
      admin_name: "Sardegna"
    },
    {
      city: "Bellona",
      admin_name: "Campania"
    },
    {
      city: "Candiolo",
      admin_name: "Piedmont"
    },
    {
      city: "Vizzini",
      admin_name: "Sicilia"
    },
    {
      city: "Siziano",
      admin_name: "Lombardy"
    },
    {
      city: "Monterenzio",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Fornovo di Taro",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Nereto",
      admin_name: "Abruzzo"
    },
    {
      city: "San Giovanni Suèrgiu",
      admin_name: "Sardegna"
    },
    {
      city: "Serradifalco",
      admin_name: "Sicilia"
    },
    {
      city: "Acque Dolci",
      admin_name: "Sicilia"
    },
    {
      city: "Rosate",
      admin_name: "Lombardy"
    },
    {
      city: "Brignano Gera d’Adda",
      admin_name: "Lombardy"
    },
    {
      city: "Alezio",
      admin_name: "Puglia"
    },
    {
      city: "Gozzano",
      admin_name: "Piedmont"
    },
    {
      city: "Ostellato",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Bagnolo Piemonte",
      admin_name: "Piedmont"
    },
    {
      city: "Bagnolo San Vito",
      admin_name: "Lombardy"
    },
    {
      city: "Lonate Ceppino",
      admin_name: "Lombardy"
    },
    {
      city: "Mel",
      admin_name: "Veneto"
    },
    {
      city: "Ronco all’Adige",
      admin_name: "Veneto"
    },
    {
      city: "Bagni di Lucca",
      admin_name: "Tuscany"
    },
    {
      city: "Gualdo Cattaneo",
      admin_name: "Umbria"
    },
    {
      city: "Villanova Mondovì",
      admin_name: "Piedmont"
    },
    {
      city: "Amalfi",
      admin_name: "Campania"
    },
    {
      city: "San Dorligo della Valle",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Villa Bartolomea",
      admin_name: "Veneto"
    },
    {
      city: "Bussoleno",
      admin_name: "Piedmont"
    },
    {
      city: "Costigliole d’Asti",
      admin_name: "Piedmont"
    },
    {
      city: "Romentino",
      admin_name: "Piedmont"
    },
    {
      city: "Stornara",
      admin_name: "Puglia"
    },
    {
      city: "Corigliano d’Otranto",
      admin_name: "Puglia"
    },
    {
      city: "Girifalco",
      admin_name: "Calabria"
    },
    {
      city: "Picerno",
      admin_name: "Basilicata"
    },
    {
      city: "Canale",
      admin_name: "Piedmont"
    },
    {
      city: "Ornago",
      admin_name: "Lombardy"
    },
    {
      city: "Montalcino",
      admin_name: "Tuscany"
    },
    {
      city: "Certosa di Pavia",
      admin_name: "Lombardy"
    },
    {
      city: "Longare",
      admin_name: "Veneto"
    },
    {
      city: "Loro Ciuffenna",
      admin_name: "Tuscany"
    },
    {
      city: "Ospedaletto Euganeo",
      admin_name: "Veneto"
    },
    {
      city: "Ceva",
      admin_name: "Piedmont"
    },
    {
      city: "Bracigliano",
      admin_name: "Campania"
    },
    {
      city: "Fontanelle",
      admin_name: "Veneto"
    },
    {
      city: "Maiori",
      admin_name: "Campania"
    },
    {
      city: "Bagno di Romagna",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Rogliano",
      admin_name: "Calabria"
    },
    {
      city: "Sambuca di Sicilia",
      admin_name: "Sicilia"
    },
    {
      city: "Viggiù",
      admin_name: "Lombardy"
    },
    {
      city: "Naturno",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Buti",
      admin_name: "Tuscany"
    },
    {
      city: "Lovere",
      admin_name: "Lombardy"
    },
    {
      city: "Sannicola",
      admin_name: "Puglia"
    },
    {
      city: "Ascea",
      admin_name: "Campania"
    },
    {
      city: "Busalla",
      admin_name: "Liguria"
    },
    {
      city: "Camporosso",
      admin_name: "Liguria"
    },
    {
      city: "Cortina d’Ampezzo",
      admin_name: "Veneto"
    },
    {
      city: "Angera",
      admin_name: "Lombardy"
    },
    {
      city: "Trevignano Romano",
      admin_name: "Lazio"
    },
    {
      city: "Campo San Martino",
      admin_name: "Veneto"
    },
    {
      city: "Dello",
      admin_name: "Lombardy"
    },
    {
      city: "Villa del Conte",
      admin_name: "Veneto"
    },
    {
      city: "Robbio",
      admin_name: "Lombardy"
    },
    {
      city: "San Secondo Parmense",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Brescello",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Grotte",
      admin_name: "Sicilia"
    },
    {
      city: "Fontevivo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Guagnano",
      admin_name: "Puglia"
    },
    {
      city: "Palmanova",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Camogli",
      admin_name: "Liguria"
    },
    {
      city: "Arce",
      admin_name: "Lazio"
    },
    {
      city: "Riolo Terme",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Rocca di Neto",
      admin_name: "Calabria"
    },
    {
      city: "Sala Baganza",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Otranto",
      admin_name: "Puglia"
    },
    {
      city: "Casaleone",
      admin_name: "Veneto"
    },
    {
      city: "San Giorgio Piacentino",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Uzzano",
      admin_name: "Tuscany"
    },
    {
      city: "Gattatico",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Trivero",
      admin_name: "Piedmont"
    },
    {
      city: "Chiaravalle Centrale",
      admin_name: "Calabria"
    },
    {
      city: "Gabicce",
      admin_name: "Marche"
    },
    {
      city: "Arienzo",
      admin_name: "Campania"
    },
    {
      city: "Nocera Umbra",
      admin_name: "Umbria"
    },
    {
      city: "San Paolo di Civitate",
      admin_name: "Puglia"
    },
    {
      city: "Pratovecchio",
      admin_name: "Tuscany"
    },
    {
      city: "Spirano",
      admin_name: "Lombardy"
    },
    {
      city: "Pavia di Udine",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Pomarance",
      admin_name: "Tuscany"
    },
    {
      city: "Diamante",
      admin_name: "Calabria"
    },
    {
      city: "Villanova d’Asti",
      admin_name: "Piedmont"
    },
    {
      city: "Cividate al Piano",
      admin_name: "Lombardy"
    },
    {
      city: "Cancello",
      admin_name: "Campania"
    },
    {
      city: "Genzano di Lucania",
      admin_name: "Basilicata"
    },
    {
      city: "Passignano sul Trasimeno",
      admin_name: "Umbria"
    },
    {
      city: "Casalserugo",
      admin_name: "Veneto"
    },
    {
      city: "Castelverde",
      admin_name: "Lombardy"
    },
    {
      city: "Mezzocorona",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Apice",
      admin_name: "Campania"
    },
    {
      city: "San Tammaro",
      admin_name: "Campania"
    },
    {
      city: "Panicale",
      admin_name: "Umbria"
    },
    {
      city: "Marsico Vetere",
      admin_name: "Basilicata"
    },
    {
      city: "Campagnola Emilia",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Quistello",
      admin_name: "Lombardy"
    },
    {
      city: "Casoli",
      admin_name: "Abruzzo"
    },
    {
      city: "Cupra Marittima",
      admin_name: "Marche"
    },
    {
      city: "Gossolengo",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Moglia",
      admin_name: "Lombardy"
    },
    {
      city: "San Donato di Lecce",
      admin_name: "Puglia"
    },
    {
      city: "Montefalco",
      admin_name: "Umbria"
    },
    {
      city: "Sarcedo",
      admin_name: "Veneto"
    },
    {
      city: "Sannazzaro de’ Burgondi",
      admin_name: "Lombardy"
    },
    {
      city: "Custonaci",
      admin_name: "Sicilia"
    },
    {
      city: "Povegliano",
      admin_name: "Veneto"
    },
    {
      city: "Telgate",
      admin_name: "Lombardy"
    },
    {
      city: "Peveragno",
      admin_name: "Piedmont"
    },
    {
      city: "Poncarale",
      admin_name: "Lombardy"
    },
    {
      city: "Boretto",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Borgo San Giacomo",
      admin_name: "Lombardy"
    },
    {
      city: "Caiazzo",
      admin_name: "Campania"
    },
    {
      city: "Bentivoglio",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Almè",
      admin_name: "Lombardy"
    },
    {
      city: "Botricello",
      admin_name: "Calabria"
    },
    {
      city: "Sovere",
      admin_name: "Lombardy"
    },
    {
      city: "Ceriale",
      admin_name: "Liguria"
    },
    {
      city: "Ispra",
      admin_name: "Lombardy"
    },
    {
      city: "Dicomano",
      admin_name: "Tuscany"
    },
    {
      city: "Cavour",
      admin_name: "Piedmont"
    },
    {
      city: "Soleto",
      admin_name: "Puglia"
    },
    {
      city: "Aquino",
      admin_name: "Lazio"
    },
    {
      city: "Torchiarolo",
      admin_name: "Puglia"
    },
    {
      city: "Codognè",
      admin_name: "Veneto"
    },
    {
      city: "San Vito Chietino",
      admin_name: "Abruzzo"
    },
    {
      city: "Anghiari",
      admin_name: "Tuscany"
    },
    {
      city: "Gandino",
      admin_name: "Lombardy"
    },
    {
      city: "Povoletto",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Cilavegna",
      admin_name: "Lombardy"
    },
    {
      city: "Levanto",
      admin_name: "Liguria"
    },
    {
      city: "Canzo",
      admin_name: "Lombardy"
    },
    {
      city: "Favria",
      admin_name: "Piedmont"
    },
    {
      city: "Carcare",
      admin_name: "Liguria"
    },
    {
      city: "Cotronei",
      admin_name: "Calabria"
    },
    {
      city: "Davoli",
      admin_name: "Calabria"
    },
    {
      city: "Castiglione dei Pepoli",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Rivanazzano",
      admin_name: "Lombardy"
    },
    {
      city: "Stornarella",
      admin_name: "Puglia"
    },
    {
      city: "Presicce",
      admin_name: "Puglia"
    },
    {
      city: "Lanzo Torinese",
      admin_name: "Piedmont"
    },
    {
      city: "Montepaone",
      admin_name: "Calabria"
    },
    {
      city: "Galliera",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Lagonegro",
      admin_name: "Basilicata"
    },
    {
      city: "Caprarola",
      admin_name: "Lazio"
    },
    {
      city: "Lanusei",
      admin_name: "Sardegna"
    },
    {
      city: "Calatabiano",
      admin_name: "Sicilia"
    },
    {
      city: "Sant’Omero",
      admin_name: "Abruzzo"
    },
    {
      city: "Limana",
      admin_name: "Veneto"
    },
    {
      city: "Borgo Ticino",
      admin_name: "Piedmont"
    },
    {
      city: "Basiliano",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Colle Umberto",
      admin_name: "Veneto"
    },
    {
      city: "Fragagnano",
      admin_name: "Puglia"
    },
    {
      city: "Cavallermaggiore",
      admin_name: "Piedmont"
    },
    {
      city: "Giavera del Montello",
      admin_name: "Veneto"
    },
    {
      city: "Padula",
      admin_name: "Campania"
    },
    {
      city: "Illasi",
      admin_name: "Veneto"
    },
    {
      city: "Acquapendente",
      admin_name: "Lazio"
    },
    {
      city: "Corsano",
      admin_name: "Puglia"
    },
    {
      city: "Montanaro",
      admin_name: "Piedmont"
    },
    {
      city: "Gizzeria",
      admin_name: "Calabria"
    },
    {
      city: "Esine",
      admin_name: "Lombardy"
    },
    {
      city: "Ripi",
      admin_name: "Lazio"
    },
    {
      city: "Gazzaniga",
      admin_name: "Lombardy"
    },
    {
      city: "Vitorchiano",
      admin_name: "Lazio"
    },
    {
      city: "Centuripe",
      admin_name: "Sicilia"
    },
    {
      city: "Monticelli d’Ongina",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Rodigo",
      admin_name: "Lombardy"
    },
    {
      city: "Pagnacco",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Santa Teresa Gallura",
      admin_name: "Sardegna"
    },
    {
      city: "Muro Lucano",
      admin_name: "Basilicata"
    },
    {
      city: "Oppido Mamertina",
      admin_name: "Calabria"
    },
    {
      city: "Villapiana",
      admin_name: "Calabria"
    },
    {
      city: "Forino",
      admin_name: "Campania"
    },
    {
      city: "Albaredo d’Adige",
      admin_name: "Veneto"
    },
    {
      city: "Carsoli",
      admin_name: "Abruzzo"
    },
    {
      city: "Rapolano Terme",
      admin_name: "Tuscany"
    },
    {
      city: "Veronella",
      admin_name: "Veneto"
    },
    {
      city: "Sarroch",
      admin_name: "Sardegna"
    },
    {
      city: "Casal Velino",
      admin_name: "Campania"
    },
    {
      city: "Gazzo Veronese",
      admin_name: "Veneto"
    },
    {
      city: "San Cipirello",
      admin_name: "Sicilia"
    },
    {
      city: "Castellucchio",
      admin_name: "Lombardy"
    },
    {
      city: "Maruggio",
      admin_name: "Puglia"
    },
    {
      city: "Campegine",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Linguaglossa",
      admin_name: "Sicilia"
    },
    {
      city: "Castelvetro Piacentino",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Montemiletto",
      admin_name: "Campania"
    },
    {
      city: "Chiusa",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Chions",
      admin_name: "Friuli-Venezia Giulia"
    },
    {
      city: "Bottanuco",
      admin_name: "Lombardy"
    },
    {
      city: "Muravera",
      admin_name: "Sardegna"
    },
    {
      city: "Canino",
      admin_name: "Lazio"
    },
    {
      city: "Monte Libretti",
      admin_name: "Lazio"
    },
    {
      city: "Coreglia Antelminelli",
      admin_name: "Tuscany"
    },
    {
      city: "Correzzola",
      admin_name: "Veneto"
    },
    {
      city: "San Pietro di Feletto",
      admin_name: "Veneto"
    },
    {
      city: "Castelnuovo Scrivia",
      admin_name: "Piedmont"
    },
    {
      city: "Monteveglio",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Guglionesi",
      admin_name: "Molise"
    },
    {
      city: "Longarone",
      admin_name: "Veneto"
    },
    {
      city: "Cerda",
      admin_name: "Sicilia"
    },
    {
      city: "Maser",
      admin_name: "Veneto"
    },
    {
      city: "Cannobio",
      admin_name: "Piedmont"
    },
    {
      city: "Vigone",
      admin_name: "Piedmont"
    },
    {
      city: "Malles Venosta",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "San Giovanni Ilarione",
      admin_name: "Veneto"
    },
    {
      city: "Corropoli",
      admin_name: "Abruzzo"
    },
    {
      city: "Marano sul Panaro",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Polla",
      admin_name: "Campania"
    },
    {
      city: "Mugnano del Cardinale",
      admin_name: "Campania"
    },
    {
      city: "Neviano",
      admin_name: "Puglia"
    },
    {
      city: "Santa Caterina Villarmosa",
      admin_name: "Sicilia"
    },
    {
      city: "Centola",
      admin_name: "Campania"
    },
    {
      city: "Bucchianico",
      admin_name: "Abruzzo"
    },
    {
      city: "Lagundo",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Mansuè",
      admin_name: "Veneto"
    },
    {
      city: "Portoscuso",
      admin_name: "Sardegna"
    },
    {
      city: "Laces",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Samassi",
      admin_name: "Sardegna"
    },
    {
      city: "Tricarico",
      admin_name: "Basilicata"
    },
    {
      city: "Tuglie",
      admin_name: "Puglia"
    },
    {
      city: "Vaglia",
      admin_name: "Tuscany"
    },
    {
      city: "Castrignano del Capo",
      admin_name: "Puglia"
    },
    {
      city: "Gottolengo",
      admin_name: "Lombardy"
    },
    {
      city: "Grassano",
      admin_name: "Basilicata"
    },
    {
      city: "Baselga di Pinè",
      admin_name: "Trentino-Alto Adige"
    },
    {
      city: "Giussago",
      admin_name: "Lombardy"
    },
    {
      city: "Celle Ligure",
      admin_name: "Liguria"
    },
    {
      city: "Melicucco",
      admin_name: "Calabria"
    },
    {
      city: "Villadose",
      admin_name: "Veneto"
    },
    {
      city: "Capistrello",
      admin_name: "Abruzzo"
    },
    {
      city: "Assoro",
      admin_name: "Sicilia"
    },
    {
      city: "Ardore",
      admin_name: "Calabria"
    },
    {
      city: "Montecastrilli",
      admin_name: "Umbria"
    },
    {
      city: "Lesignano de’ Bagni",
      admin_name: "Emilia-Romagna"
    },
    {
      city: "Gagliano del Capo",
      admin_name: "Puglia"
    },
    {
      city: "Mineo",
      admin_name: "Sicilia"
    },
    {
      city: "Bevagna",
      admin_name: "Umbria"
    },
    {
      city: "Maratea",
      admin_name: "Basilicata"
    },
    {
      city: "Laureana di Borrello",
      admin_name: "Calabria"
    },
    {
      city: "Condofuri",
      admin_name: "Calabria"
    },
    {
      city: "Castelbellino",
      admin_name: "Marche"
    },
    {
      city: "Bella",
      admin_name: "Basilicata"
    },
    {
      city: "Agnone",
      admin_name: "Molise"
    },
    {
      city: "Tolfa",
      admin_name: "Lazio"
    }
  ]
import React from 'react'
import styles from '../styles/CustomerGuide.module.css'
import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

export default function CustomerGuide() {
  const { language} = useSelector(state => state.proposal)

  return (
    <div>
      <section className={styles.header}>
            <span>
            {language === "English" ? "CUSTOMER GUIDE - SPEAKER ROLE" : "GUIDA PER IL CLIENTE - RUOLO SPEAKER"}
            </span>
            <img src='/rafiky/logo.webp' alt='logo'/>
        </section>
         <Grid container spacing={2} sx={{ marginTop:"-15px",
        backgroundImage:"url('/rafiky/brochure/background.png')", 
        backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    width:"100%",
    marginLeft:"0px",
    paddingBottom:"30px"
        }}>
        <Grid item xs={12} md={7} className={styles.section1}>
          
          <ol className={styles.list}>
            <li className={styles.items}>
            {language === "English" ? "Open the panel by entering the link that was sent into your browser (preferably Google Chrome)." : 
                "Aprire il pannello inserendo il link che è stato inviato nel browser (preferibilmente Google Chrome)."}
          </li>
            <li className={styles.items}>
            {language === "English" ? `Enter your login credentials (Username and Email) – Click on “Join Meeting".` : 
            `Inserire le credenziali di accesso (Nome Utente & Email) – Cliccare su “Join Meeting".`}

            </li>
            <li className={styles.items}>
            {language === "English" ? "Select audio input devices (headphones, speakers) and audio output devices (microphones). Allow the browser to use the camera and microphone." : 
            " Selezionare i dispositivi audio in entrata (cuffie, altoparlanti) e i dispositivi audio in uscita (microfoni). Consentire al browser di utilizzare la telecamera e il microfono."}
              </li>
            <li className={styles.items}>
            {language === "English" ? "Select the audio channel (language) you want to listen to. It is recommended to change the floor while listening to the translation." : 
"Selezionare il canale audio (lingua) che si desidera ascoltare. Si consiglia di mutare il floor mentre si è in ascolto della traduzione."}

            </li>
            <li className={styles.items}>
            {language === "English" ? "Press the “Raise Hand” button to ask for the floor and speak during the conference." : 
            "Premere il pulsante “Raise Hand” per chiedere la parola e parlare durante la conferenza."}

            </li>
            <li className={styles.items}>
            {language === "English" ? "Use the chat to request technical assistance or to communicate with other participants and interpreters." : 
            "Utilizzare la chat per richiedere assistenza tecnica o per comunicare con il resto dei partecipanti e degli interpreti."}
                       </li>
            <li className={styles.items}>
            {language === "English" ? "To share your screen during the presentation, please click the “Share Screen” button." : 
            " Per condividere lo schermo durante la presentazione, cliccare sul tasto “Share Screen”. "}
                       </li>
           
          </ol>
        </Grid>
        <Grid item xs={12} md={5}>
          <img className={styles.img} alt='question mark' src='/rafiky/brochure/customerGuide.png'/>

        </Grid>
      </Grid>
    </div>
  )
}

export const optionsMainLanguage = [
    {
        text: "Abkhaz",
        value: "Abkhaz",
    },
    {
        text: "Afar",
        value: "Afar",
    },
    {
        text: "Afrikaans",
        value: "Afrikaans",
    },
    {
        text: "Akan",
        value: "Akan",
    },
    {
        text: "Albanian",
        value: "Albanian",
    },
    {
        text: "Amharic",
        value: "Amharic",
    },
    {
        text: "Arabic",
        value: "Arabic",
    },
    {
        text: "Aragonese",
        value: "Aragonese",
    },
    {
        text: "Armenian",
        value: "Armenian",
    },
    {
        text: "Assamese",
        value: "Assamese",
    },
    {
        text: "Avaric",
        value: "Avaric",
    },
    {
        text: "Avestan",
        value: "Avestan",
    },
    {
        text: "Aymara",
        value: "Aymara",
    },
    {
        text: "Azerbaijani",
        value: "Azerbaijani",
    },
    {
        text: "Bambara",
        value: "Bambara",
    },
    {
        text: "Bashkir",
        value: "Bashkir",
    },
    {
        text: "Basque",
        value: "Basque",
    },
    {
        text: "Belarusian",
        value: "Belarusian",
    },
    {
        text: "Bengali",
        value: "Bengali",
    },
    {
        text: "Bihari",
        value: "Bihari",
    },
    {
        text: "Bislama",
        value: "Bislama",
    },
    {
        text: "Bosnian",
        value: "Bosnian",
    },
    {
        text: "Breton",
        value: "Breton",
    },
    {
        text: "Bulgarian",
        value: "Bulgarian",
    },
    {
        text: "Burmese",
        value: "Burmese",
    },
    {
        text: "Catalan; Valencian",
        value: "Catalan; Valencian",
    },
    {
        text: "Chamorro",
        value: "Chamorro",
    },
    {
        text: "Chechen",
        value: "Chechen",
    },
    {
        text: "Chichewa; Chewa; Nyanja",
        value: "Chichewa; Chewa; Nyanja",
    },
    {
        text: "Chinese",
        value: "Chinese",
    },
    {
        text: "Chuvash",
        value: "Chuvash",
    },
    {
        text: "Cornish",
        value: "Cornish",
    },
    {
        text: "Corsican",
        value: "Corsican",
    },
    {
        text: "Cree",
        value: "Cree",
    },
    {
        text: "Croatian",
        value: "Croatian",
    },
    {
        text: "Czech",
        value: "Czech",
    },
    {
        text: "Danish",
        value: "Danish",
    },
    {
        text: "Divehi; Dhivehi; Maldivian;",
        value: "Divehi; Dhivehi; Maldivian;",
    },
    {
        text: "Dutch",
        value: "Dutch",
    },
    {
        text: "English",
        value: "English",
    },
    {
        text: "Esperanto",
        value: "Esperanto",
    },
    {
        text: "Estonian",
        value: "Estonian",
    },
    {
        text: "Ewe",
        value: "Ewe",
    },
    {
        text: "Faroese",
        value: "Faroese",
    },
    {
        text: "Fijian",
        value: "Fijian",
    },
    {
        text: "Filipino",
        value: "Filipino",
    },
    {
        text: "Finnish",
        value: "Finnish",
    },
    {
        text: "Flemish",
        value: "Flemish",
    },
    {
        text: "French",
        value: "French",
    },
    {
        text: "Fula",
        value: "Fula",
    },
    {
        text: "Galician",
        value: "Galician",
    },
    {
        text: "Georgian",
        value: "Georgian",
    },
    {
        text: "German",
        value: "German",
    },
    {
        text: "Greek, Modern",
        value: "Greek, Modern",
    },
    {
        text: "Guaraní",
        value: "Guaraní",
    },
    {
        text: "Gujarati",
        value: "Gujarati",
    },
    {
        text: "Haitian; Haitian Creole",
        value: "Haitian; Haitian Creole",
    },
    {
        text: "Hausa",
        value: "Hausa",
    },
    {
        text: "Hebrew (modern)",
        value: "Hebrew (modern)",
    },
    {
        text: "Herero",
        value: "Herero",
    },
    {
        text: "Hindi",
        value: "Hindi",
    },
    {
        text: "Hiri Motu",
        value: "Hiri Motu",
    },
    {
        text: "Hungarian",
        value: "Hungarian",
    },
    {
        text: "Interlingua",
        value: "Interlingua",
    },
    {
        text: "Indonesian",
        value: "Indonesian",
    },
    {
        text: "Interlingue",
        value: "Interlingue",
    },
    {
        text: "Irish",
        value: "Irish",
    },
    {
        text: "Igbo",
        value: "Igbo",
    },
    {
        text: "Inupiaq",
        value: "Inupiaq",
    },
    {
        text: "Ido",
        value: "Ido",
    },
    {
        text: "Icelandic",
        value: "Icelandic",
    },
    {
        text: "Italian",
        value: "Italian",
    },
    {
        text: "Inuktitut",
        value: "Inuktitut",
    },
    {
        text: "Japanese",
        value: "Japanese",
    },
    {
        text: "Javanese",
        value: "Javanese",
    },
    {
        text: "Kalaallisut, Greenlandic",
        value: "Kalaallisut, Greenlandic",
    },
    {
        text: "Kannada",
        value: "Kannada",
    },
    {
        text: "Kanuri",
        value: "Kanuri",
    },
    {
        text: "Kashmiri",
        value: "Kashmiri",
    },
    {
        text: "Kazakh",
        value: "Kazakh",
    },
    {
        text: "Khmer",
        value: "Khmer",
    },
    {
        text: "Kikuyu, Gikuyu",
        value: "Kikuyu, Gikuyu",
    },
    {
        text: "Kinyarwanda",
        value: "Kinyarwanda",
    },
    {
        text: "Kirghiz, Kyrgyz",
        value: "Kirghiz, Kyrgyz",
    },
    {
        text: "Komi",
        value: "Komi",
    },
    {
        text: "Kongo",
        value: "Kongo",
    },
    {
        text: "Korean",
        value: "Korean",
    },
    {
        text: "Kurdish",
        value: "Kurdish",
    },
    {
        text: "Kwanyama, Kuanyama",
        value: "Kwanyama, Kuanyama",
    },
    {
        text: "Latin",
        value: "Latin",
    },
    {
        text: "LIS",
        value: "LIS",
    },
    {
        text: "Luxembourgish, Letzeburgesch",
        value: "Luxembourgish, Letzeburgesch",
    },
    {
        text: "Luganda",
        value: "Luganda",
    },
    {
        text: "Limburgish, Limburgan, Limburger",
        value: "Limburgish, Limburgan, Limburger",
    },
    {
        text: "Lingala",
        value: "Lingala",
    },
    {
        text: "Lao",
        value: "Lao",
    },
    {
        text: "Lithuanian",
        value: "Lithuanian",
    },
    {
        text: "Luba-Katanga",
        value: "Luba-Katanga",
    },
    {
        text: "Latvian",
        value: "Latvian",
    },
    {
        text: "Manx",
        value: "Manx",
    },
    {
        text: "Macedonian",
        value: "Macedonian",
    },
    {
        text: "Malagasy",
        value: "Malagasy",
    },
    {
        text: "Malay",
        value: "Malay",
    },
    {
        text: "Malayalam",
        value: "Malayalam",
    },
    {
        text: "Maltese",
        value: "Maltese",
    },
    {
        text: "Māori",
        value: "Māori",
    },
    {
        text: "Marathi (Marāṭhī)",
        value: "Marathi (Marāṭhī)",
    },
    {
        text: "Marshallese",
        value: "Marshallese",
    },
    {
        text: "Mongolian",
        value: "Mongolian",
    },
    {
        text: "Moldovan",
        value: "Moldovan",
    },
    {
        text: "Montenegrin",
        value: "Montenegrin",
    },
    {
        text: "Nauru",
        value: "Nauru",
    },
    {
        text: "Navajo, Navaho",
        value: "Navajo, Navaho",
    },
    {
        text: "Norwegian Bokmål",
        value: "Norwegian Bokmål",
    },
    {
        text: "North Ndebele",
        value: "North Ndebele",
    },
    {
        text: "Nepali",
        value: "Nepali",
    },
    {
        text: "Ndonga",
        value: "Ndonga",
    },
    {
        text: "Norwegian Nynorsk",
        value: "Norwegian Nynorsk",
    },
    {
        text: "Norwegian",
        value: "Norwegian",
    },
    {
        text: "Nuosu",
        value: "Nuosu",
    },
    {
        text: "South Ndebele",
        value: "South Ndebele",
    },
    {
        text: "Occitan",
        value: "Occitan",
    },
    {
        text: "Ojibwe, Ojibwa",
        value: "Ojibwe, Ojibwa",
    },
    {
        text: "Oromo",
        value: "Oromo",
    },
    {
        text: "Oriya",
        value: "Oriya",
    },
    {
        text: "Ossetian, Ossetic",
        value: "Ossetian, Ossetic",
    },
    {
        text: "Panjabi, Punjabi",
        value: "Panjabi, Punjabi",
    },
    {
        text: "Pāli",
        value: "Pāli",
    },
    {
        text: "Persian",
        value: "Persian",
    },
    {
        text: "Polish",
        value: "Polish",
    },
    {
        text: "Pashto, Pushto",
        value: "Pashto, Pushto",
    },
    {
        text: "Portuguese",
        value: "Portuguese",
    },
    {
        text: "Quechua",
        value: "Quechua",
    },
    {
        text: "Romansh",
        value: "Romansh",
    },
    {
        text: "Kirundi",
        value: "Kirundi",
    },
    {
        text: "Romanian",
        value: "Romanian",
    },
    {
        text: "Russian",
        value: "Russian",
    },
    {
        text: "Sanskrit (Saṁskṛta)",
        value: "Sanskrit (Saṁskṛta)",
    },
    {
        text: "Sardinian",
        value: "Sardinian",
    },
    {
        text: "Sindhi",
        value: "Sindhi",
    },
    {
        text: "Northern Sami",
        value: "Northern Sami",
    },
    {
        text: "Samoan",
        value: "Samoan",
    },
    {
        text: "Sango",
        value: "Sango",
    },
    {
        text: "Serbian",
        value: "Serbian",
    },
    {
        text: "Scottish Gaelic; Gaelic",
        value: "Scottish Gaelic; Gaelic",
    },
    {
        text: "Shona",
        value: "Shona",
    },
    {
        text: "Sinhala, Sinhalese",
        value: "Sinhala, Sinhalese",
    },
    {
        text: "Slovak",
        value: "Slovak",
    },
    {
        text: "Slovene",
        value: "Slovene",
    },
    {
        text: "Somali",
        value: "Somali",
    },
    {
        text: "Southern Sotho",
        value: "Southern Sotho",
    },
    {
        text: "Spanish",
        value: "Spanish",
    },
    {
        text: "Sundanese",
        value: "Sundanese",
    },
    {
        text: "Swahili",
        value: "Swahili",
    },
    {
        text: "Swati",
        value: "Swati",
    },
    {
        text: "Swedish",
        value: "Swedish",
    },
    {
        text: "Tamil",
        value: "Tamil",
    },
    {
        text: "Telugu",
        value: "Telugu",
    },
    {
        text: "Tajik",
        value: "Tajik",
    },
    {
        text: "Thai",
        value: "Thai",
    },
    {
        text: "Tigrinya",
        value: "Tigrinya",
    },
    {
        text: "Tibetan Standard, Tibetan, Central",
        value: "Tibetan Standard, Tibetan, Central",
    },
    {
        text: "Turkmen",
        value: "Turkmen",
    },
    {
        text: "Tagalog",
        value: "Tagalog",
    },
    {
        text: "Tswana",
        value: "Tswana",
    },
    {
        text: "Tonga (Tonga Islands)",
        value: "Tonga (Tonga Islands)",
    },
    {
        text: "Turkish",
        value: "Turkish",
    },
    {
        text: "Tsonga",
        value: "Tsonga",
    },
    {
        text: "Tatar",
        value: "Tatar",
    },
    {
        text: "Twi",
        value: "Twi",
    },
    {
        text: "Tahitian",
        value: "Tahitian",
    },
    {
        text: "Uighur, Uyghur",
        value: "Uighur, Uyghur",
    },
    {
        text: "Ukrainian",
        value: "Ukrainian",
    },
    {
        text: "Urdu",
        value: "Urdu",
    },
    {
        text: "Uzbek",
        value: "Uzbek",
    },
    {
        text: "Venda",
        value: "Venda",
    },
    {
        text: "Vietnamese",
        value: "Vietnamese",
    },
    {
        text: "Volapük",
        value: "Volapük",
    },
    {
        text: "Walloon",
        value: "Walloon",
    },
    {
        text: "Welsh",
        value: "Welsh",
    },
    {
        text: "Wolof",
        value: "Wolof",
    },
    {
        text: "Western Frisian",
        value: "Western Frisian",
    },
    {
        text: "Xhosa",
        value: "Xhosa",
    },
    {
        text: "Yiddish",
        value: "Yiddish",
    },
    {
        text: "Yoruba",
        value: "Yoruba",
    },
    {
        text: "Zhuang, Chuang",
        value: "Zhuang, Chuang",
    },
];

import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './globalStyles';

const Process = ({ language }) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={{ ...styles.header, marginBottom: 0, width: '96%', marginHorizontal: 'auto' }}>
        <View style={{ ...styles.headerItem }}>
          <Text style={{ ...styles.headerItemText, fontSize: '13px' }}>
            {language === 'English' ? 'OPERATIONAL PROCESS' : 'PROCESSO OPERATIVO'}
          </Text>
        </View>
        <View>
          <Image style={{ ...styles.headerRightImg }} src="/rafiky/logo.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', marginTop:"30px" }}>
        <View style={{ width: '94%', padding: '5px', marginLeft: '5px' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            <View
              style={{
                display: 'block',
                flexDirection: 'column',
                border: '2px solid #8316d3',
                padding: '3px',
                borderRadius: '6px',
                textAlign: 'center',
                width: '26%',
              }}
            >
              <Image
                style={{ width: '30px', zIndex: '-99', height: '30px', marginLeft:"50px" }}
                src="/rafiky/brochure/icons/mdi--deal-outline.png"
              />
              <Text style={{ fontSize: '11px', fontWeight: 'bold' }}>
                {language === 'English' ? 'CUSTOMER REQUEST' : 'RICHIESTA CLIENTE'}
              </Text>
            </View>
            <Image
                style={{ width: '20px', zIndex: '-99', height: '20px', 
                  marginTop:"21px", marginBottom:"5px", 
                  marginLeft:"5px", marginRight:"5px" }}
                src="/rafiky/brochure/icons/icomoon-free--arrow-right.png"
              />
            <View
              style={{
                display: 'block',
                flexDirection: 'column',
                border: '2px solid #8316d3',
                padding: '3px',
                borderRadius: '6px',
                textAlign: 'center',
                width: '26%',
              }}
            >
              <Image
                style={{ width: '30px', zIndex: '-99', height: '30px', marginLeft:"50px" }}
                src="/rafiky/brochure/icons/tabler--progress.png"
              />
              <Text style={{ fontSize: '11px', fontWeight: 'bold' }}>
              DEMO
              </Text>
            </View>
            <Image
                style={{ width: '20px', zIndex: '-99', height: '20px', 
                  marginTop:"21px", marginBottom:"5px", 
                  marginLeft:"5px", marginRight:"5px" }}
                src="/rafiky/brochure/icons/icomoon-free--arrow-right.png"
              />
            <View
              style={{
                display: 'block',
                flexDirection: 'column',
                border: '2px solid #8316d3',
                padding: '3px',
                borderRadius: '6px',
                textAlign: 'center',
                width: '26%',
                marginRight:"7px"
              }}
            >
              <Image
                style={{ width: '30px', zIndex: '-99', height: '30px', marginLeft:"50px" }}
                src="/rafiky/brochure/icons/carbon--mobile-audio.png"
              />
              <Text style={{ fontSize: '9px', fontWeight: 'bold' }}>
              {language === "English" ? "QUOTE REQUEST/ACCEPTANCE" : "RICHIESTA/ACCETTAZIONE PREVENTIVO"}
              </Text>
            </View>
          
          </View>
        </View>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', marginTop:"35px" }}>
        <View style={{ width: '94%', padding: '5px', marginLeft: '5px' }}>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
          <View
              style={{
                display: 'block',
                flexDirection: 'column',
                border: '2px solid #8316d3',
                padding: '3px',
                borderRadius: '6px',
                textAlign: 'center',
                width: '26%',
              }}
            >
              <Image
                style={{ width: '30px', zIndex: '-99', height: '30px', marginLeft:"50px" }}
                src="/rafiky/brochure/icons/ci--list-checklist.png"
              />
              <Text style={{ fontSize: '11px', fontWeight: 'bold' }}>
              {language === "English" ? "INTERPRETERS SELECTION" : "SELEZIONE INTERPRETI"}
              </Text>
            </View>
            <Image
                style={{ width: '20px', zIndex: '-99', height: '20px', 
                  marginTop:"21px", marginBottom:"5px", 
                  marginLeft:"5px", marginRight:"5px" }}
                src="/rafiky/brochure/icons/icomoon-free--arrow-right.png"
              />
            <View
              style={{
                display: 'block',
                flexDirection: 'column',
                border: '2px solid #8316d3',
                padding: '3px',
                borderRadius: '6px',
                textAlign: 'center',
                width: '26%',
              }}
            >
              <Image
                style={{ width: '30px', zIndex: '-99', height: '30px', marginLeft:"50px" }}
                src="/rafiky/brochure/icons/mdi--settings-sync-outline.png"
              />
              <Text style={{ fontSize: '10px', fontWeight: 'bold' }}>
              {language === "English" ? "TRAINING WITH THE CLIENT/INTERPRETER" : "TRAINING CON IL CLIENTE/INTERPRETE"}
              </Text>
            </View>
            <Image
                style={{ width: '20px', zIndex: '-99', height: '20px', 
                  marginTop:"21px", marginBottom:"5px", 
                  marginLeft:"5px", marginRight:"5px" }}
                src="/rafiky/brochure/icons/icomoon-free--arrow-right.png"
              />
            <View
              style={{
                display: 'block',
                flexDirection: 'column',
                border: '2px solid #8316d3',
                padding: '3px',
                borderRadius: '6px',
                textAlign: 'center',
                width: '26%',
                marginRight:"7px"
              }}
            >
              <Image
                style={{ width: '30px', zIndex: '-99', height: '30px', marginLeft:"40px" }}
                src="/rafiky/brochure/icons/entypo--back-in-time.png"
              />
              <Text style={{ fontSize: '11px', fontWeight: 'bold' }}>
              {language === "English" ? "PRE-EVENT TEST" : "TEST PRE-EVENTO"}
              </Text>
            </View>
            
          </View>
        </View>
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', marginTop:"35px" }}>
        <View style={{ width: '94%', padding: '5px', marginLeft: '5px' }}>
          <View style={{ display: 'flex', flexDirection: 'row', textAlign:"center", marginLeft:"7%" }}>
          <View
              style={{
                display: 'block',
                flexDirection: 'column',
                border: '2px solid #8316d3',
                padding: '3px',
                borderRadius: '6px',
                textAlign: 'center',
                width: '34%',
              }}
            >
              <Image
                style={{ width: '30px', zIndex: '-99', height: '30px', marginLeft:"60px" }}
                src="/rafiky/brochure/icons/carbon--event-schedule.png"
              />
              <Text style={{ fontSize: '11px', fontWeight: 'bold' }}>
              {language === "English" ? "Event":"EVENTO"}
              </Text>
            </View>
            <Image
                style={{ width: '20px', zIndex: '-99', height: '20px', 
                  marginTop:"18px", marginBottom:"5px", 
                  marginLeft:"5px", marginRight:"5px" }}
                src="/rafiky/brochure/icons/icomoon-free--arrow-right.png"
              />
            <View
              style={{
                display: 'block',
                flexDirection: 'column',
                border: '2px solid #8316d3',
                padding: '3px',
                borderRadius: '6px',
                textAlign: 'center',
                width: '34%',
                marginRight:"7px"
              }}
            >
              <Image
                style={{ width: '30px', zIndex: '-99', height: '30px', marginLeft:"60px" }}
                src="/rafiky/brochure/icons/fluent--person-feedback-16-regular.png"
              />
              <Text style={{ fontSize: '11px', fontWeight: 'bold' }}>
              {language === "English" ? "CUSTOMER FEEDBACK":"FEEDBACK DEL CLIENTE"}
              </Text>
            </View>
           
            
          </View>
        </View>
      </View>
      <Image
        style={{ width: '100%', zIndex: '-99', marginTop: '-324px', height: '390px' }}
        src="/rafiky/brochure/background.png"
      />
    </Page>
  );
};

export default Process;

import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';


function formatDate(param) {
  // Extracting date components
  const date = new Date(param);
  var day = date.getDate();
  var month = date.getMonth() + 1; // Months are zero indexed
  var year = date.getFullYear() % 100; // Getting last two digits of the year
  var hours = date.getHours();
  var minutes = date.getMinutes();

  // Adding leading zeros if necessary
  day = (day < 10 ? '0' : '') + day;
  month = (month < 10 ? '0' : '') + month;
  year = (year < 10 ? '0' : '') + year;
  hours = (hours < 10 ? '0' : '') + hours;
  minutes = (minutes < 10 ? '0' : '') + minutes;

  // Concatenating date components
  var formattedDate = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;

  return formattedDate;
}


export default function TermsAndCondition({data,brand, language,name,quoteSignature, interpretationItem, additionalService, address1, companyNameAndId, quoteDeadline, interpretationItem2, additionalService2 }) {

    let previousDateKey = [] 

  return (
    <>
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={{ ...styles.header,alignItems:"justify", marginLeft:"10px", marginBottom: 0, width: "99%", marginHorizontal: "auto" }}>
        <View style={{ display:"flex", flexDirection:"row", width:"100%" }}>
        <Text style={{...styles.headerItemText,textAlign:"justify",width:"100%",marginTop:"10px", fontSize:"11px",}}>
        {language === "English" ? 'Terms and conditions' : "Termini e Condizioni"}
        </Text>
        {/* <Image style={{width:"90px", height:"25px", marginTop:"10px", }} src="/assets/desktop6/group-1000001719.png" /> */}
        {brand && brand?.rafiky ? 
        <Image style={{width:"90px", height:"25px", marginTop:"10px", }} src="/rafiky/logo.png" />
        :   
    <Image style={{width:"90px", height:"25px", marginTop:"10px", }} src="/assets/desktop6/group-1000001719.png" />
}
        </View>
         {/* <View style={{alignItems:"right", alignContent:"right", float:"right"}}>
          <Image style={{...styles.headerRightImg, alignItems:"right", alignContent:"right", float:"right" }} src="/translationAssets/desktop6/lingoyou.png" />
        </View> */} 
        <View style={styles.headerItem} />
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', marginLeft: '10px', marginBottom:"8px" }}>
        <View style={{ width: '32%', marginRight: '5px' }}>
          <Text style={{...styles.newTermsHeadings, fontSize:"7px"}}>
          {language === "English" ? `Quote expiry period` : `Scadenza del preventivo`}:{' '}
          </Text>
          <Text style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
            {language === 'English'
                ? `It is necessary to send confirmation of the quote by `
                : `È necessario inviare conferma del preventivo entro il `}
            </Text>
            <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
               {formatDate(quoteDeadline)}.           
             </Text>
            
          </Text>
         
            <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal',  lineHeight:`${language === "English" ? '1.7px' : ''}` }}>
            {language === "English" ? `After this date, the quote may be subject to change` : 
            `Oltre tale data il preventivo potrebbe subire delle variazioni`}.  
            </Text>
            <Text style={{...styles.newTermsHeadings,  fontSize:"8px", marginTop:"3px"}}>
            {language === "English" ? `Payment terms` : `Termini di pagamento`}:{' '}    
             </Text>
            <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
                     {companyNameAndId?.paymentTerms === 'Immediate'
                        ? `${language === "English" ? "Prepayment" : "Pagamento Anticipato" }`
                        : `${language === "English" ? companyNameAndId?.paymentTerms : `${String(companyNameAndId?.paymentTerms).includes("days") ? 
                        String(companyNameAndId?.paymentTerms).replace("days", "giorni") : 
                        String(companyNameAndId?.paymentTerms)}`}`
                      }.            
            </Text>
            <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal',  lineHeight:`${language === "English" ? '1.7px' : ''}` }}>
            {language === "English" ? `Date and time of the event` : 
            `Data e orario dell’evento`}.    
            </Text>
            {interpretationItem2?.length > 0 || interpretationItem?.length > 0 ? (
 [interpretationItem, ...interpretationItem2]?.map((items, option)=>{
  previousDateKey = []
  return(
 <div>
  {interpretationItem2?.length > 0 &&
    <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px'}}>
    {language === "English" ? "Option" : "Opzione"} {option + 1}:
  </Text>
  }
 {
  items?.length > 0 ? items?.map((item, index) => {
    const startDate = new Date(item?.startDateTime);
    const endDate = new Date(item?.endDateTime);
    const dateKey = `${startDate.getDate()}${startDate.getMonth()}${startDate.getFullYear()}`;

    if (index === 0 || !previousDateKey.includes(dateKey)) {
      const startDateDay = String(startDate.getDate()).padStart(2, '0');
      const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const startDateYear = String(startDate.getFullYear()).slice(-2);
      let startDateHrs = String(startDate.getHours()).padStart(2, '0');
      let startDatemins = String(startDate.getMinutes()).padStart(2, '0');
      const startingDate =
        startDateDay +
        '/' +
        startDateMonth +
        '/' +
        startDateYear +
        ' ' +
        startDateHrs +
        ':' +
        startDatemins;
      const endDateDay = String(endDate.getDate()).padStart(2, '0');
      const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const endDateYear = String(endDate.getFullYear()).slice(-2);
      let endDateHrs = String(endDate.getHours()).padStart(2, '0');
      let endDateMins = String(endDate.getMinutes()).padStart(2, '0');
      const endingDate =
        endDateDay +
        '/' +
        endDateMonth +
        '/' +
        endDateYear +
        ' ' +
        endDateHrs +
        ':' +
        endDateMins;

      previousDateKey.push(dateKey);
      return (
       <>
       {language === "English" ?  <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal',  }}>
        From: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
        {startingDate}
            </Text>  &nbsp; To: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
            {endingDate}
                </Text>
        </Text>
       : <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
       Da: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
       {startingDate}
           </Text>  &nbsp; A: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
           {endingDate}
               </Text>
       </Text>
      }
       </>   
      );
    }
  }) : ( 
    [additionalService, ...additionalService2]?.map((services, index)=>(
      
     option === index && services?.map((item, index) => {
    const startDate = new Date(item?.startDateTime);
    const endDate = new Date(item?.endDateTime);
    const dateKey = `${startDate.getDate()}${startDate.getMonth()}${startDate.getFullYear()}`;

    if (index === 0 || !previousDateKey.includes(dateKey)) {
      const startDateDay = String(startDate.getDate()).padStart(2, '0');
      const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const startDateYear = String(startDate.getFullYear()).slice(-2);
      let startDateHrs = String(startDate.getHours()).padStart(2, '0');
      let startDatemins = String(startDate.getMinutes()).padStart(2, '0');
      const startingDate =
        startDateDay +
        '/' +
        startDateMonth +
        '/' +
        startDateYear +
        ' ' +
        startDateHrs +
        ':' +
        startDatemins;
      const endDateDay = String(endDate.getDate()).padStart(2, '0');
      const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const endDateYear = String(endDate.getFullYear()).slice(-2);
      let endDateHrs = String(endDate.getHours()).padStart(2, '0');
      let endDateMins = String(endDate.getMinutes()).padStart(2, '0');
      const endingDate =
        endDateDay +
        '/' +
        endDateMonth +
        '/' +
        endDateYear +
        ' ' +
        endDateHrs +
        ':' +
        endDateMins;

        previousDateKey.push(dateKey);

      return (
        <>
       {language === "English" ? <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        From: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
        {startingDate}
            </Text>  &nbsp; To: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
            {endingDate}
                </Text>
        </Text>
       : <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
       From: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
       {startingDate}
           </Text>  &nbsp; To: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
           {endingDate}
               </Text>
       </Text>
      }
       </>   
      );
    }
  })))
  )
 }
 </div>
 )}) 
) : (
  additionalService.map((item, index) => {
    const startDate = new Date(item?.startDateTime);
    const endDate = new Date(item?.endDateTime);
    const dateKey = `${startDate.getDate()}${startDate.getMonth()}${startDate.getFullYear()}`;

    if (index === 0 || !previousDateKey.includes(dateKey)) {
      const startDateDay = String(startDate.getDate()).padStart(2, '0');
      const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const startDateYear = String(startDate.getFullYear()).slice(-2);
      let startDateHrs = String(startDate.getHours()).padStart(2, '0');
      let startDatemins = String(startDate.getMinutes()).padStart(2, '0');
      const startingDate =
        startDateDay +
        '/' +
        startDateMonth +
        '/' +
        startDateYear +
        ' , ' +
        startDateHrs +
        ':' +
        startDatemins;
      const endDateDay = String(endDate.getDate()).padStart(2, '0');
      const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const endDateYear = String(endDate.getFullYear()).slice(-2);
      let endDateHrs = String(endDate.getHours()).padStart(2, '0');
      let endDateMins = String(endDate.getMinutes()).padStart(2, '0');
      const endingDate =
        endDateDay +
        '/' +
        endDateMonth +
        '/' +
        endDateYear +
        ' , ' +
        endDateHrs +
        ':' +
        endDateMins;

        previousDateKey.push(dateKey);

      return (
        <>
        {language === "English" ? <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        From: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
        {startingDate}
            </Text>  &nbsp; To: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
            {endingDate}
                </Text>
        </Text>
        : <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        From: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
        {startingDate}
            </Text>  &nbsp; To: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
            {endingDate}
                </Text>
        </Text>
       }
        </>   
      );
    }
  })
)}
          <Text style={{ ...styles.newTermsHeadings, fontSize:"7px", marginTop:  `${language === "English" ? '4px' : '3px'}`  }}>
          {language === "English" ? `Assembly/disassembly of technical equipment` : `Montaggio/Smontaggio attrezzatura tecnica`}:{' '}
          </Text>
          
            <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
            {language === "English" ? `To be defined` : 
            `Da definire`}.
            </Text>
          
          <Text style={{ ...styles.newTermsHeadings, fontSize:"7px", marginTop: `${language === "English" ? '4px' : '3px'}` }}>
          Location:{' '}          
          </Text>
            <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
             <Text style={{fontWeight:"bold", fontSize:"7px"}}>
             {address1}.
              </Text> 
             
          </Text>
         
          <Text style={{ ...styles.newTermsHeadings, fontSize:"7px", marginTop:  `${language === "English" ? '4px' : '3px'}`  }}>
          {language === "English" ? `Additional working hours` : `Ore lavorative aggiuntive`}:{' '}
          </Text>
          <Text style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal', lineHeight:"1.7px" }}>
            {language === "English" ? `The daily working hours for technicians in the control room shall not exceed ` : 
            `Gli orari di lavoro giornalieri per i Tecnici in regia non devono superare le `}      
            </Text>
            <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px' }}>
            {language === "English" ? `8 hours. ` : 
            `8 ore. `}              
            </Text>
            <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
            {language === "English" ? `Beyond that amount of time, appropriate increments to the hourly rates, set at EUR 65.00 per hour for each technician, shall apply.` : 
            `Oltre tale limite, si applicano gli adeguati incrementi alle tariffe orarie, stabiliti a 65,00 €/ora per ciascun tecnico. `}
            
            </Text>
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
          {language === "English" ? `The interpreting service times are as indicated during the quotation stage. For the extra-time, the appropriate increments to the hourly rates, set at  ` : 
            `Gli orari del servizio di interpretariato sono quelli indicati in fase di quotazione. Per l’extratime, si applicano gli adeguati incrementi alle tariffe orarie, stabiliti a `}
            <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px'}}>
            {language === "English" ? `EUR 125.00 per hour ` : 
            `125,00 €/ora `}
            </Text>
            {language === "English" ? `for each interpreter, shall apply.` : 
            `per ciascun interprete.`}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize:"7px", marginTop:  `${language === "English" ? '4px' : '3px'}`  }}>
          {language === "English" ? `Travel, board and lodging expenses` : `Spese di trasferta, vitto e alloggio`}:{' '}
       
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `During the service provided, the cost of any coffee breaks, lunches and dinners for all staff employed by LingoYou Group Srl will be borne by the Client. Lodging expenses for technicians and interpreters will be agreed between the parties and are included only if mentioned in the quote.` : 
        `Durante il servizio fornito, i costi relativi a eventuali pause caffè, pranzi e cene per tutto il personale impiegato da LingoYou Group Srl saranno a carico del Committente. Le spese di alloggio dei tecnici e degli interpreti saranno concordate tra le Parti e sono incluse solo se citate nel preventivo.`}
        </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize:"7px", marginTop: `${language === "English" ? '4px' : '3px'}` }}>
        {language === "English" ? `Car parks, limited traffic zones (ZTLs) & access to the town centers` : `Parcheggi, ZTL & accesso ai centri storici`}:{' '}
       
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `For conference venues located in the center of the cities of interest, the Client is responsible for any access to the limited traffic zones (ZTL) and for parking the vehicle used by LingoYou Group Srl.` : 
        `Per le sedi congressuali situate nel centro storico delle città di interesse, il Committente è responsabile dell'eventuale accesso ai varchi ZTL e del parcheggio per il veicolo utilizzato da LingoYou Group Srl.`}
         
        </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize:"7px", marginTop: `${language === "English" ? '4px' : '3px'}` }}>
        {language === "English" ? `Event date modification and/or cancellation` : 
          `Modifiche date e/o cancellazione evento`}:{' '}       
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `In case of modification/cancellation within ten (10) working days before the event, the customer is required to pay ` : 
        `In caso di modifica/cancellazione entro dici (10) giorni lavorativi prima dell'evento il cliente è tenuto al `} 
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px'}}>
        {language === "English" ? `the total ` : 
          `saldo totale `}
        </Text>
        {language === "English" ? `of the confirmed amount. If the modification/cancellation of the event occurs previous to ten (10) working days before the event, ` : 
          `dell'importo confermato. Se la modifica/cancellazione dell'evento avviene antecedentemente ai dieci (10) giorni lavorativi prima dell'evento, è richiesto il `}
     <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px'}}>
     50%{' '}
     </Text>
     {language === "English" ? `of the amount confirmed in the quote is required. In addition, in case of urgency (confirmation of the quote after the deadline), additional costs may be charged.` : 
          `del saldo confermato nel preventivo. Inoltre, in caso di urgenza, o conferma del preventivo dopo la scadenza, potrebbero essere addebitati dei costi aggiuntivi.`}
       
        </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize:"7px", marginTop: "2px"  }}>
        {language === "English" ? `Technical equipment - Increasing the number of receivers/headsets` : 
          `Attrezzatura Tecnica - Incremento del numero di ricevitori/cuffie`}:{' '}  
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `Any increase in the number of receivers/headsets outside this economic offer shall be made only by multiples of 50 units contained in an appropriate suitcase. The cost of daily rental for No. 01 suitcase containing 50 headset/receiver unit is ` : 
          `L’eventuale incremento del numero di ricevitori-cuffie al di fuori della presente offerta economica, avviene solo a multipli di n. 50 unità contenuti in idonea valigia. Il costo di noleggio giornaliero per n. 01 valigia contenente n. 50 unita cuffia-ricevitore è `}
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px' }}>
          {language === "English" ? "EUR 200.00 excl. VAT. " : "€ 200,00 + IVA. "}
          </Text>
          {language === "English" ? `The increase request must be received by e-mail in advance and then confirmed. Increases communicated with less than three (3) days’ notice from the start of the event are not guaranteed.` : 
          `La richiesta di incremento dovrà pervenire via e-mail con congruo anticipo e successivamente confermata, non si garantiscono gli incrementi comunicati con un preavviso minore a tre (3) giorni dall'inizio dell'evento.`}
         
          </Text>
          <Text style={{ ...styles.newTermsHeadings,fontSize:"7px", marginTop: "2px" }}>
          {language === "English" ? `Technical equipment - Receivers not delivered` : 
          `Attrezzatura Tecnica - Ricevitori non consegnati`}:{' '}         
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `Infrared/radio receivers will be delivered upon temporary collection of an identification document (driver’s license, ID card or passport - other identification documents will not be accepted).` : 
          `I ricevitori infrarossi/via radio saranno consegnati dietro temporaneo ritiro di un documento di riconoscimento. (patente di guida, carta identità o passaporto: non verranno accettate altre tipologie identificative).`} 
         
         </Text>
         <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
         {language === "English" ? `In the event of receivers not delivered at the end of the event, they will be charged for: Receiver EUR 245.00 each excl. VAT - High Impedance Monophonic Headset EUR 55.00 excl. VAT.` : 
          `Nell'eventualità di ricevitori non consegnati al termine dell’evento, verranno addebitati in ragione di: ricevitore 245,00 € cad. + I.V.A. - cuffia monofonica alta impedenza € 55,00 € + I.V.A.`} 
           
         </Text>
         <Text style={{ ...styles.newTermsHeadings,fontSize:"7px", marginTop: "2px"  }}>
         {language === "English" ? `Technical equipment – Amplification system and other equipment` : 
          `Attrezzatura tecnica – Sistema di amplificazione ed altra attrezzatura`}:{' '}   
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `Not included in quote unless otherwise stated.` : 
          `Non incluso nel preventivo se non indicato diversamente.`}  
         </Text>
         <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
         {language === "English" ? `In case of using amplification systems or other equipment supplied by the location or by another company, LingoYou Group Srl assumes no liability for the management and functionality of audio-microphone equipment not under its ownership.` : 
          `Nel caso di utilizzo di sistemi di amplificazione o altra attrezzatura in dotazione della location utilizzata o fornita da altra ditta, la LingoYou Group Srl non si assume nessuna responsabilità sulla gestione e funzionalità degli apparati audio-microfonici non di sua proprietà.`} 
        
         </Text>
         <Text style={{ ...styles.newTermsHeadings,fontSize:"7px", marginTop:  "2px" }}>
         {language === "English" ? `Technical equipment – Receivers/headsets distribution` : 
          `Attrezzatura tecnica – Distribuzione dei ricevitori/cuffie`}:{' '}
         </Text>
         <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
         {language === "English" ? `When the Client distributes receivers and headsets, the delivery of the suitcases containing the receivers/headsets will be by subscription by the Client of a suitable document of assumption of liability.` : 
          `Nel caso di distribuzione di ricevitori & cuffie a cura del Committente, la consegna delle valigie contenenti i ricevitori-cuffie avverrà mediante sottoscrizione da parte del Committente di idoneo documento di assunzione di responsabilità. `} 
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
          {language === "English" ? `If the Customer wishes to be exempted from this liability, one of our Hostesses could take care of the assistance and delivery of the receivers/headsets to the users, at a cost of ` : 
          `Qualora il Cliente voglia essere esonerato da tale responsabilità, una nostra Hostess potrebbe occuparsi dell'assistenza e della consegna dei ricevitori-cuffie agli utenti, al costo di `} 
         <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px' }}>
         {language === "English" ? "EUR 150.00" : "150,00 €" }  {' '}
         </Text>
         {language === "English" ? `(up to 100 users), after temporary collection of the appropriate identification document (driving license, ID card or passport - other identification documents will not be accepted).` : 
          `(fino a 100 utenti), previo ritiro temporaneo di idoneo documento di identità (patente di guida, carta identità o passaporto: non verranno accettate altre tipologie identificative).`} 
        
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal', lineHeight:`${language === "English" ? '' : '1.7px'}` }}>
          {language === "English" ? `The document delivered will be kept in the appropriate cabinet and returned to the user upon return of the receiver/headset. ` : 
            `Il documento consegnato sarà custodito nell’apposito casellario e restituito all’utenza alla riconsegna del ricevitore/cuffia.`}
      </Text>
      
        {language === "English" ? '' : <>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal',  lineHeight:`${language === "English" ? '' : '1.7px'}` }}>
         {language === "English" ? `Any recordings and/or streaming can be made only and exclusively with the informed consent of ` : 
          `Eventuali registrazioni e/o streaming possono essere effettuate solo ed esclusivamente previo consenso informato di `}
<Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px'}}>
Congressi Internazionali {' '}
</Text>
{language === "English" ? `(LingoYou Group Srl brand).` : 
          `(brand di LingoYou Group Srl).`} 
</Text>
        </>}
        
          
        </View>
        <View style={{ width: '32%', marginRight: '5px', marginTop:"2px" }}>
        {language === "English" ?  <>
        <Text style={{ ...styles.newTermsHeadings,fontSize:"7px", marginTop: '3px' }}>
      {language === "English" ? `Copyright for event recording and/or streaming` : 
          `Copyright per registrazioni e/o streaming evento`}:{' '}      
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal',   lineHeight:`${language === "English" ? '1.7px' : '1.7px'}` }}>
        {language === "English" ? `Copyright fees for recording interpreters and/or streaming of the event are included only if mentioned in the quote. ` : 
          `Costi di Copyright per registrazione interpreti e/o streaming dell'evento inclusi solo se citati nel preventivo.`} 
         </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal',  lineHeight:`${language === "English" ? '1.7px' : ''}` }}>
        {language === "English" ? `Any recordings and/or streaming can be made only and exclusively with the informed consent of ` : 
          `Eventuali registrazioni e/o streaming possono essere effettuate solo ed esclusivamente previo consenso informato di `}
<Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px'}}>
Congressi Internazionali {' '}
</Text>
{language === "English" ? `(LingoYou Group Srl brand).` : 
          `(brand di LingoYou Group Srl).`} 
</Text>
        </> : ''}
        <Text style={{ ...styles.newTermsHeadings, marginTop:  `${language === "English" ? '4px' : '3px'}` }}>
        {language === "English" ? `Code of Ethics & confidentiality` : 
          `Codice etico & di riservatezza`}:{' '}       
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal'}}>
        {language === "English" ? `The staff of ` : 
          `Il personale di `} 
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px'}}>
          Congressi Internazionali. {' '}
          </Text>
          {language === "English" ? `(LingoYou Group Srl brand) have acknowledged and signed the Code of Ethics and the Code of confidentiality of the Company.` : 
          `(brand di LingoYou Group Srl) ha preso atto e controfirmato il codice etico e il codice di riservatezza della società.`}
         </Text>  
        
        <Text style={{ ...styles.newTermsHeadings, marginTop:  `${language === "English" ? '4px' : '3px'}` }}>
         {language === "English" ? `Invoicing` : 
          `Fatturazione`}:{' '}   
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal',  lineHeight:`${language === "English" ? '1.7px' : '1.7px'}` }}>
        {language === "English" ? `The order for the event will be invoiced by the company ` : 
          `La commessa dell’evento verrà fatturata dalla società `}   
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px'}}>
          {language === "English" ? `LingoYou Group Srl.` : 
          `LingoYou Group Srl.`} 
          </Text>
         </Text>  
<Text style={{ ...styles.newTermsHeadings, marginTop: `${language === "English" ? '4px' : '3px'}` }}>
          {language === "English" ? `Third Party Invoicing` : 
          `Fatturazione a Terzi`}:{' '}         
                  
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `In the case of invoicing to a legal entity other than the Client, the latter shall be deemed to be jointly and severally liable, unless expressly accepted by the new entity indicated.` : 
          `In caso di fatturazione ad entità giuridica differente dal Committente, quest’ultimo verrà considerato obbligato in solido, a meno di una espressa accettazione da parte del nuovo soggetto indicato.`} 
         
         </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
         {language === "English" ? `In the case of arbitrations invoiced by the parties: The performance of the service is subject to the receipt of this quote signed for acceptance by the individual parties.` : 
          `Caso di arbitrati con fatturazione suddivisa tra le Parti: l’esecuzione del servizio è subordinata alla ricezione del presente preventivo firmato per accettazione dalle singole Parti.`}  
         </Text>
        {/* <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
         {language === "English" ? `` : 
          `Caso di arbitrati con fatturazione suddivisa tra le Parti: l’esecuzione del servizio è subordinata alla ricezione del presente preventivo firmato per accettazione dalle singole Parti.`}  
         
         </Text> */}
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
         {language === "English" ? `Where there is no express acceptance or where separate invoicing is indicated after the service has already taken place, the client shall be deemed to be jointly and severally liable.` : 
          `In caso di mancanza di espressa accettazione o in caso di indicazione di fatturazione separata a servizio già avvenuto, il committente viene considerato obbligato in solido.`}  
         
         </Text>
          <Text style={{ ...styles.newTermsHeadings, marginTop: '3px' }}>
          {language === "English" ? `Services` : 
          `Servizi`}:{' '}          
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal',  lineHeight:`${language === "English" ? '' : '1.7px'}` }}>
          {language === "English" ? `The entity LingoYou Group Srl (through the brand ` : 
          `Il soggetto LingoYou Group Srl (tramite il brand `} 
          <Text style={{ ...styles.newTermsHeadings, fontWeight: 'bold', fontSize:"6.5px" }}>
          Congressi Internazionali
          </Text>
          {') '}
          {language === "English" ? `indicated in the above Quote undertakes to provide the Customer with translation, interpretation and other services through its project management system. ` : 
          `indicato nel suddetto Preventivo si impegna a fornire al Cliente servizi di traduzione e interpretariato e di altro tipo per mezzo del proprio sistema di gestione dei progetti. `} 
       
        </Text>
         
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal',  lineHeight: `${language === "English"? "": "1.6px"}` }}>
          {language === "English" ? `LingoYou Group Srl will make every commercially reasonable effort to provide translation, interpreting or other services within the time frame estimated in writing by LingoYou Group Srl or, if no written estimate has been provided by LingoYou Group Srl, the latter will perform its obligations in accordance with its standard parameters, subject to the circumstances that may arise from time to time.` : 
          `LingoYou Group Srl compirà ogni sforzo commercialmente ragionevole al fine di fornire i servizi di traduzione e interpretariato o di altro tipo rispettando le tempistiche stimate per iscritto da LingoYou Group Srl o, qualora non sia stata fornita alcuna stima scritta da parte di LingoYou Group Srl, quest'ultimo adempierà ai propri obblighi in conformità con i propri consueti parametri standard, soggetti alle circostanze esistenti di volta in volta. `} 
           </Text>
           <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal',  lineHeight: `${language === "English"? "": "1.7px"}` }}>
           {language === "English" ? `"Urgent" projects that require service delivery within shorter time frames than the standard will be accepted on the assumption that, in certain cases, the usual quality and project management level of LingoYou Group Srl may not be respected and LingoYou Group Srl may charge additional fees due to the complexity and costs necessary to meet such requests.` : 
          `I progetti "urgenti" che richiedono la fornitura del servizio entro tempistiche più brevi rispetto allo standard verranno accettati sull'assunto che, in determinati casi, il livello consueto di qualità e di gestione dei progetti di LingoYou Group Srl potrebbe non essere rispettato e che LingoYou Group Srl possa applicare tariffe aggiuntive a causa della complessità e dei costi necessari a soddisfare tali richieste.`} 
        
           </Text>
          <Text style={{ ...styles.newTermsHeadings, marginTop: '3px' }}>
          {language === "English" ? `Quote` : 
          `Preventivo`}:{' '}           
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
          {language === "English" ? `This Quote is strictly bound by the Terms & Conditions set forth herein and is based on the information and files made available to LingoYou Group Srl at the time of quote creation. ` : 
          `Il presente Preventivo è strettamente vincolato ai Termini & Condizioni qui indicati e si basa sulle informazioni e sui files resi disponibili a LingoYou Group Srl alla data del Preventivo. `} 
                
        </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal',  lineHeight: `${language === "English"? "": "1.6px"}` }}>
          {language === "English" ? `Please note that any changes to the project scope (change, add or remove one or more language pairs, add or remove equipment, change of date and time, etc.) will result in a project change order being issued. ` : 
          `Si tenga presente che qualsiasi modifica all'ambito del progetto (cambio, aggiunta o rimozione di una o più combinazioni linguistiche, aggiunta o rimozione di attrezzatura, cambio di data e orario ecc.) comporterà l'emissione di un ordine di modifica del progetto.`} 
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal'}}>
          {language === "English" ? `The specific terms in the above Quote will supersede any conflicting provisions in these ` : 
          `I termini specifici nel Preventivo di cui sopra sostituiranno qualsiasi disposizione contrastante nei presenti `}  
          {language === "English" ? `Terms and conditions.` : 
          `Termini & Condizioni.`}   
             </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `This Quote expires on the date and time stated above. If this deadline is exceeded without the Quote being approved, the Quote will expire and must be resubmitted.` : 
          `Il presente Preventivo scade nella data e nell’orario sopra indicati. Qualora questo termine venga superato senza che il Preventivo sia stato approvato, il Preventivo scadrà e dovrà essere effettuato nuovamente.`} 
         
        </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `In order to ensure that the line of work is followed, LingoYou Group Srl requires timely receipt of all materials and information for the performance of the desired services and clarification of the requirements. Any delay due to failure to receive the above shall relieve LingoYou Group Srl of the failure to provide the service in proportion to the time taken by the customer to clarify the requirements and for the sections of the work affected by the requirements.` : 
          `Al fine di garantire il rispetto della linea di lavoro, LingoYou Group Srl necessita della ricezione tempestiva di tutti i materiali e di tutte le informazioni per l'esecuzione dei servizi desiderati e di un chiarimento dei requisiti. Eventuali ritardi dovuti alla mancata ricezione di quanto sopra esposto esonerano LingoYou Group Srl dalla mancata fornitura del servizio in proporzione al tempo impiegato dal cliente per chiarire i requisiti e per le sezioni del lavoro interessate dai requisiti.`} 
     
        </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px', marginTop:"3px",  }}>
        {language === "English" ? `Fees and Payments` : 
          `Commissioni e pagamenti`}:{' '} 
        </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `LingoYou Group Srl agrees to charge, and the Client agrees to pay, fees for services provided by LingoYou Group Srl or its subsidiaries at the current rates of LingoYou Group Srl or, where applicable, according to rates negotiated separately and agreed in writing by LingoYou Group Srl and the Client before the start of the provision of the services.` : 
          `LingoYou Group Srl accetta di addebitare, e il Committente accetta di pagare, le commissioni per i servizi forniti da LingoYou Group Srl o dalle sue consociate secondo le tariffe correnti di LingoYou Group Srl o, qualora applicabile, secondo tariffe negoziate separatamente e concordate per iscritto da LingoYou Group Srl e dal Committente prima dell'inizio della prestazione dei servizi.`} 
        
        </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `All payments must be made ` : 
          `Tutti i pagamenti devono essere effettuati `} 
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px' }}>
        {language === "English" ? `within thirty (30) days of the date of issue of the invoice.` : 
          `entro trenta (30) giorni dalla data di emissione della fattura.`} 
        </Text>

        </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `In the event of a dispute in good faith concerning an invoice item, the Client undertakes to inform LingoYou Group Srl promptly of the nature of the dispute in writing (including a description of the reason for the dispute) within the above thirty (30) day period and agrees to pay the undisputed portion as set forth herein.` : 
          `In caso di eventuale controversia in buona fede riguardante una voce relativa alla fattura, il Committente si impegna a informare tempestivamente LingoYou Group Srl circa la natura della controversia per iscritto (includendo una descrizione della motivazione della controversia) entro il suddetto periodo di trenta (30) giorni e si impegna a pagare la porzione non contestata come stabilito nel presente documento.`} 
        
        </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `Any undisputed amount not paid within thirty (30) days of the date of invoice issued by LingoYou Group Srl will accrue interest at the rate of ` : 
          `Qualsiasi importo non contestato non pagato entro trenta (30) giorni dalla data di emissione della fattura di LingoYou Group Srl maturerà interessi al tasso di `}
         <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px'}}>
            1,5%
         </Text>
         {language === "English" ? ` until full payment, or at the highest legal rate of interest, whichever is lower (interest will be charged daily). ` :
          ` fino al completo pagamento, ovvero al massimo tasso di interesse legale, qualora inferiore (gli interessi verranno addebitati quotidianamente).`}

        </Text>
        {/* <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `In the event of any dispute in good faith regarding an item relating to the invoice, the Client undertakes to promptly inform LingoYou Group Srl of the nature of the dispute in writing (including a description of the reason for the dispute) within the aforementioned period of thirty (30 ) days and agrees to pay the undisputed portion as set forth herein.` : 
          `In caso di eventuale controversia in buona fede riguardante una voce relativa alla fattura, il Committente si impegna a informare tempestivamente LingoYou Group Srl circa la natura della controversia per iscritto (includendo una descrizione della motivazione della controversia) entro il suddetto periodo di trenta (30) giorni e si impegna a pagare la porzione non contestata come stabilito nel presente documento.`} 
           
        </Text> */}
        {language === "English" ? '' : <>
         <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal'}}>
        {language === "English" ? `All amounts due by the Client under this Agreement shall be paid to LingoYou Group Srl without compensation or counterclaim and without deduction or withholding. ` : 
          `Tutti gli importi dovuti dal Committente in virtù del presente Accordo saranno corrisposti a LingoYou Group Srl senza compensazione o domanda riconvenzionale e senza detrazione o ritenuta. `}
         
        </Text>
        </> }
        </View>
        <View style={{ width: '32%', marginRight: '5px' }}>
        {language === "English" ? <>
         <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `All amounts due by the Client under this Agreement shall be paid to LingoYou Group Srl without compensation or counterclaim and without deduction or withholding.` : 
          `Tutti gli importi dovuti dal Committente in virtù del presente Accordo saranno corrisposti a LingoYou Group Srl senza compensazione o domanda riconvenzionale e senza detrazione o ritenuta. `}
         
        </Text>
        </> : ''}
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal'}}>
        {language === "English" ? `The acceptance by LingoYou Group Srl of the partial payment or any payment less than the total amount due at any time does not constitute a release or waiving of LingoYou Group Srl’s rights to unpaid amounts.` : 
          `L'accettazione da parte di LingoYou Group Srl del pagamento parziale o di qualsiasi pagamento inferiore all'importo totale dovuto in qualsiasi momento non costituisce una liberatoria o una rinuncia ai diritti di LingoYou Group Srl agli importi non corrisposti.`}
          
        </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px'}}>
        {language === "English" ? `Confidentiality` : `Riservatezza`}:{' '} 
  
        </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
        {language === "English" ? `Both parties agree that any information or documents provided by either party to the other (including the Terms & Conditions of this Agreement and details of the applicable fee structure) will remain strictly confidential, except to the extent that a party is required by law or a court order to disclose them.` : 
          `Entrambe le Parti concordano che qualsiasi informazione o documento fornito da una Parte all'altra (inclusi Termini & Condizioni del presente accordo e i dettagli della struttura tariffaria applicabile) rimarranno strettamente riservati, salvo nella misura in cui una Parte è obbligata dalla legge o da un'ordinanza del tribunale a rivelarli.`}
 
        </Text>
        <Text style={{ ...styles.newTermsHeadings, marginTop: '3px' }}>
            {language === 'English' ? `Non-solicitation`: `Non sollecitazione`}:{' '}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
            {language === 'English'
              ? `At any time when LingoYou Group Srl is providing the Service to the Client under this document and for two (2) years thereafter, the Client will not solicit or induce any employee, translator, interpreter or consultant of LingoYou Group Srl engaged in the provision of services to the client to resign or to end the engagement with LingoYou Group Srl.`
              : `In qualsiasi momento in cui LingoYou Group Srl stia fornendo il servizio al Committente ai sensi del presente documento e per due (2) anni a seguire, il Committente non solleciterà né indurrà alcun dipendente, traduttore, interprete o consulente di LingoYou Group Srl impegnato nella fornitura dei servizi al Committente a licenziarsi o a terminare l'impegno con LingoYou Group Srl . `}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal'}}>
            {language === 'English'
              ? `The Client acknowledges to LingoYou Group Srl the right of execution in a specific form or of injunctive or other equitable provisional measure in order to enforce this provision.`
              : `Il Committente riconosce a LingoYou Group Srl il diritto di esecuzione in forma specifica o di un provvedimento ingiuntivo o altro provvedimento equo al fine di applicare la presente disposizione.`}
          </Text>
        <Text style={{ ...styles.newTermsHeadings, marginTop: '3px' }}>
            {language === 'English' ? `Warranties: `: `Garanzie: `} 
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
          {language === "English" ? `LingoYou Group Srl guarantees to the Client that all services provided will comply in all relevant respects with the specifications set out in the applicable Quote. In the event of failure to promptly contest the claim, nothing can be claimed by way of compensation. The Supplier shall also not be liable for defects attributable to the user or to a third party unrelated to the provision of the services.` : 
          `LingoYou Group Srl garantisce al Committente che tutti i servizi effettuati saranno conformi in tutti gli aspetti rilevanti alle specifiche stabilite nel Preventivo applicabile. In caso di mancata tempestiva contestazione nulla potrà essere richiesto a titolo risarcitorio. Il Fornitore non potrà altresì essere ritenuto responsabile per difetti imputabili all’utente o ad un terzo estraneo alla fornitura dei servizi.`}
 </Text>
 <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
 {language === "English" ? `Except as provided in this section, LingoYou Group Srl makes no warranties or representations, whether express, implied by law or otherwise, with respect to the services or any final products provided hereunder and specifically denies any implied warranties of merchantability, title, non-infringement and fitness for a particular purpose.` : 
          `Salvo quanto stabilito nella presente sezione, Lingoyou Group Srl non fornisce alcuna garanzia o dichiarazione, sia essa esplicita, implicitamente prevista dalla legge o altrimenti, in relazione ai servizi o a eventuali prodotti finali forniti ai sensi del presente documento e, nello specifico, esclude qualsiasi garanzia implicita di commerciabilità, titolo, non-violazione e idoneità a un particolare scopo.`}
</Text>
          <Text style={{...styles.newTermsHeadings,  marginTop: '3px'}}>
            {language === 'English' ? `Remedies`: `Rimedi`}:{' '}
          </Text>

          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
            {language === 'English'
              ? `The Supplier may remedy the identified defect upon prompt written objection (within five (5) working days from the provision of the Service) of any lack of conformity during the performance of the Service provided by LingoYou Group Srl.`
              : `Il Fornitore può porre rimedio al difetto rilevato previa tempestiva contestazione scritta (entro cinque (5) giorni lavorativi dalla fornitura del servizio) di eventuali difetti di conformità durante l’esecuzione del servizio fornito da LingoYou Group Srl.`}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, marginTop: '3px' }}>
            {language === 'English' ? `Limitation of Liability`: `Limitazione di responsabilità`}:{' '}
          </Text>

          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
            {language === 'English'
              ? `The liability of each party (whether defined by contract, whether arising out of wrongful act, negligence, direct liability, regulatory provision or otherwise) to the other Party and any third party in respect of or concerning this Agreement shall be cumulatively limited to direct and actual damages equal to or less than the amount payable to LingoYou Group Srl under this Agreement for the portion of the services it has given the source of that complaint.`
              : `La responsabilità di ciascuna Parte (sia definita da un contratto, che derivante da atto illecito, negligenza, diretta responsabilità, disposizione normativa o altro) nei confronti dell'altra Parte e di eventuali terze parti riguardante o relativa al presente Accordo sarà limitata cumulativamente ai danni diretti ed effettivi pari a un ammontare non superiore a quello da corrispondere a LingoYou Group Srl ai sensi del presente Accordo per la porzione dei servizi che ha dato origine a tale reclamo.`}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
            {language === 'English'
              ? `Without prejudice to gross negligence or misconduct, in no event shall either Party be liable for the following types of damage and each Party hereby waives its right in respect of the following types of damage: consequential; incidental; punitive; special; exemplary; indirect; loss of profits; loss of refunds; loss of data; or loss of savings. Each Party waives its right to such damage, even if that party has been informed of the possibility of such damage occurring.`
              : `Escludendo colpa grave o condotta scorretta, in nessun caso alcuna delle Parti sarà responsabile per i seguenti tipi di danno e ciascuna Parte rinuncia con la presente al proprio diritto in relazione ai seguenti tipi di danno: conseguente; accidentale; punitivo; speciale; esemplare; indiretto; perdita di profitti; perdita di rimborsi; perdita di dati; o perdita di risparmi. Ciascuna Parte rinuncia al diritto relativo ai suddetti danni, anche se tale Parte è stata informata della possibilità di avvenimento di tali danni.`}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, marginTop: '4px' }}>
            {language === 'English' ? `Applicable law and competent court`: 
            `Legge applicabile e Foro Competente`}:{' '}
          </Text>

          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
            {language === 'English'
              ? `Except as otherwise provided in the Statement of work, this Agreement shall be governed by Italian law without application of its principles of conflict of laws.`
              : `Salvo quanto diversamente previsto nella Dichiarazione di lavoro, il presente Accordo sarà regolato dalla Legge Italiana senza applicazione dei suoi principi di conflitto di leggi.`}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
            {language === 'English'
              ? `Any action in connection with this Agreement shall be brought before the State or Federal Court of Milan and the Parties consent exclusively to the seat and personal authority provided for therein.`
              : `Qualsiasi causa connessa al presente Accordo dovrà essere intentata presso il Tribunale Statale o Federale di Milano e le Parti acconsentono esclusivamente alla sede e alla giurisdizione personale ivi previste.`}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, marginTop: '4px' }}>
            {language === 'English' ? `Agreement`: `Accordo`}:{' '}
          </Text>

          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
            {language === 'English'
              ? `Unless expressly agreed otherwise by both Parties in writing, as in a Framework Agreement (FA), if any, this Quote together with these Terms & Conditions constitutes the Parties’ entire Agreement and understanding with respect to the subject matter hereof and supersedes all prior agreements (oral or written) and intended in this regard.`
              : `Salvo quanto diversamente concordato espressamente da entrambe le Parti per iscritto, come in un Accordo Quadro (AQ), se presente, questo Preventivo insieme a questi Termini & Condizioni, costituisce l'intero Accordo e comprensione delle Parti in relazione all'oggetto del presente documento e sostituisce tutti i precedenti accordi (orali o scritti) e intese al riguardo. `}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal' }}>
            {language === 'English'
              ? `No representations, promises, inducements or intentions have been made by any Party not incorporated herein, and no Party shall be bound by or liable for any alleged representations, promises or inducements not so established herein, including, but not limited to, the Terms & Conditions of the Customer’s pre-printed documents (e.g. purchase orders, order confirmations, etc.).`
              : `Nessuna dichiarazione, promessa, incentivo, dichiarazione o intenzione è stata fatta da alcuna Parte che non sia incorporata nel presente documento, e nessuna Parte sarà vincolata o responsabile per qualsiasi presunta dichiarazione, promessa, incentivo o dichiarazione non così stabilita nel presente documento, inclusi - senza limitazioni - i Termini & Condizioni dei documenti prestampati del Cliente (ad esempio, ordini di acquisto, conferme d'ordine e simili). `}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '6.5px', fontWeight: 'normal',   lineHeight:`${language === "English" ? '' : '1.7px'}` }}>
            {language === 'English'
              ? `This Agreement may be modified, amended, superseded or canceled only by a written instrument signed by each Party, and all terms, understandings, representations, warranties or conditions herein may be waived only by a written instrument executed by the Party to be bound by such waiver.`
              : `Il presente Contratto può essere modificato, emendato, sostituito o annullato solo da uno strumento scritto firmato da ciascuna delle Parti e tutti i termini, patti, dichiarazioni, garanzie o condizioni del presente documento possono essere derogati solo da uno strumento scritto eseguito dalla Parte per essere vincolato da tale rinuncia.`}
          </Text>
         
        </View>
      </View>
      
    </Page>
     <Page wrap={true} size="A4" style={{ ...styles.page, padding: 0 }}>
     <View style={{flexDirection:"row"}}>
     <View style={{ width: '32%', marginRight: '5px' }}>
     <View style={{marginTop:"50px"}}>
             <Text style={{ ...styles.newTermsHeadings, marginBottom: '7.5px', textAlign: 'center' }}>
             {
                   language === 'English' ? `Signature for acceptance of quote CI/${data?.orderNumber}:` : 
                   `Firma per accettazione Preventivo CI/${data?.orderNumber}:`
                 }
             </Text>
             <Text>
               {(data?.status === 'Quote-Won' || data?.status === "Sent To PM") && (name || quoteSignature) && (
                 <View style={{ ...styles.termsFooterBodyItemBtn, alignContent: 'center', alignItems: 'center' , marginBottom:"6px", borderBottom: '1px solid grey' }}>
                   <Text>{name || quoteSignature}</Text>
                 </View>
               )}
             </Text>
             </View>
     </View>
       <View style={{ width: '32%', marginRight: '5px' }}>
       <View style={{marginTop:"50px"}}>
             <Text style={{ ...styles.newTermsHeadings, marginBottom: '8px', textAlign: 'center' }}>
               {language === 'English'
                 ? 'Signature for acceptance of the Terms & Conditions:'
                 : 'Firma per accettazione Termini & Condizioni:'}
             </Text>
             <Text>
               {(data?.status === 'Quote-Won' || data?.status === "Sent To PM") && (name || quoteSignature) && (
                 <View style={{ ...styles.termsFooterBodyItemBtn, alignContent: 'center', alignItems: 'center' , marginBottom:"6px", borderBottom: '1px solid grey' }}>
                   <Text>{name || quoteSignature}</Text>
                 </View>
               )}
             </Text>
             </View>
       </View>
   <View style={{ width: '32%', marginRight: '5px' }}>
   
            
             <View style={{marginTop:"50px"}}>
             <Text style={{ ...styles.newTermsHeadings, marginBottom: '8px', textAlign: 'center' }}>
             {
                   language === 'English' ? 'Signature for acceptance Terms and conditions of the site' : 'Firma per accettazione Termini & condizioni del sito '
                 } <Link href='http://www.lingoyou.com/terminicondizioni'>(http://www.lingoyou.com/terminicondizioni)</Link>  
             </Text>
             <Text>
               {(data?.status === 'Quote-Won' || data?.status === "Sent To PM") && (name || quoteSignature) && (
                 <View style={{ ...styles.termsFooterBodyItemBtn, alignContent: 'center', alignItems: 'center' , marginBottom:"6px", borderBottom: '1px solid grey' }}>
                   <Text>{name || quoteSignature}</Text>
                 </View>
               )}
             </Text>
             </View>
   </View>
     </View>
    
       </Page>
       </>
  );
}

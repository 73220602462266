import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './globalStyles';

const Connect = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
       <View style={{ ...styles.header, marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
        <Text style={{...styles.headerItemText, fontSize:"12px"}}>
        {language === "English" ? "THE SOFTWARE – Rafiky Connect 1.4" : 
        "IL SOFTWARE – Rafiky Connect 1.4"}

        </Text>        
          </View>
         <View>
          <Image style={{...styles.headerRightImg }} src="/rafiky/logo.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={styles.techBody}>
       <Text style={{...styles.techParaBody}}>
       {language === "English" ? "With Rafiky you can transform any place into a conference room! We combine the power of cloud technology with modern devices to facilitate the interpretation service." : `Con Rafiky puoi  trasformare qualsiasi luogo in una sala conferenze! Uniamo la potenza della tecnologia cloud con i moderni dispositivi per agevolare il servizio d’interpretariato.`}

       </Text>
        <Text style={{...styles.techParaBody, marginTop:"10px"}}>
        • {language === "English" ? "Any language in real time" : "Qualsiasi lingua in tempo reale"}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
        • {language === "English" ? "Highest Quality Video & Crystal Clear Audio" : "Video di altissima qualità & Audio cristallino"}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
        • {language === "English" ? "Branding customization" : "Personalizzazione branding"}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
        • {language === "English" ? "Compatibility with all Web Conferencing software" : "Compatibilità con tutti i software di Web Conferencing"}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
        • {language === "English" ? "Upload PPT & Video" : "Upload PPT & Video"}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
        • {language === "English" ? "Chat Translation in +60 languages ​​- NEW!" : "Traduzione Chat in + di 60 lingue - NEW!"}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
        • {language === "English" ? "Speaker's girth" : "Sottopancia Relatore"}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
        • {language === "English" ? "Video Box with sign language" : "Video Box con lingua dei segni"}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
        • {language === "English" ? "Virtual Voting System" : "Sistema di votazione virtuale"}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
        • {language === "English" ? "Post-event reporting" : "Reportistica post-evento"}
        </Text>
              </View>
             
      <View style={styles.techFooter}>
      <View style={{width:"400px"}}>
      <Link href="https://www.youtube.com/embed/ZvecBiunr5I">
      <Image style={styles.techFooterImage} src="/rafiky/brochure/connect-footer.png"/>
      </Link>
        </View>
      </View>
    </Page>
  );
};

export default Connect;
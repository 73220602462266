import { StyleSheet, Font } from "@react-pdf/renderer";
import PoppinsRegular from '../../pdf-commons/fonts/Poppins-Medium.ttf';
import PoppinsLight from '../../pdf-commons/fonts/Poppins-Light.ttf';
import PoppinsBold from '../../pdf-commons/fonts/Poppins-Bold.ttf'




Font.register({
  family: "Poppins 'sans-serif'", fonts: [
    { src: PoppinsRegular, fontStyle: 'normal', fontWeight: "normal" },
    { src: PoppinsRegular, fontStyle: 'italic', fontWeight: "normal" },
    { src: PoppinsLight, fontStyle: 'normal', fontWeight: "light" },
    { src: PoppinsBold, fontStyle: 'normal', fontWeight: "bold" },
  ]
})


export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    paddingHorizontal: "20px",
    paddingVertical: "10px",
    fontFamily: "Poppins 'sans-serif'"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "40px",
    borderBottom: "1px solid #39065F",
    marginBottom: "50px"
  },
  headerItem: {
    flex: 1,
  },
  headerItemText: {
    color: "#39065F",
    textAlign: "left",
    fontSize: "14px",
  },
  headerRight: {
    flex: 1,
    display: "flex",
  },
  headerRightImg: {
    width: "110px",
  },
  tableCustomer: {
    width: "75%",
    marginHorizontal: "auto",
    marginBottom: "50px",
  },
  tableCustomerHeader: {
    backgroundColor: "#2fcbe9",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: "10px",
    paddingVertical: "16px",
    borderRadius:"4px"
  },
  tableCustomerItem: {
    flex: 1,
    alignItems: "flex-start",
  },
  tableCustomerText: {
    fontSize: "10px",
  },
  tableCustomerBody: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    fontSize: "11px",
  },
  bold: {
    fontWeight: "bold",
  },
  light: {
    fontWeight: "light",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  del: {
    textDecoration: "line-through"
  },
  celCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  description: {
    fontSize: "8px",
  },
  tableItems: {
    borderBottom: "1px solid #2DA0F0",
    marginBottom: '20px',
  },
  tableItemsHeader: {
    backgroundColor: 'rgb(57, 6, 95)',
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    gap: "10px",
    paddingHorizontal: "10px",
    paddingVertical: "16px",
    fontSize: "10px",
    borderRadius:"4px"
  },
  tableItemsItemLeft: {
    flex: 1,
    alignItems: 'flex-start',
  },
  tableItemsItemRight: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
  },
  tableItemsItemCol: {
    width: "60px",
    textAlign: "center",
  },
  tableItemsBody: {

  },
  tableItemsBodyItem: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    padding: "10px",
    gap: "10px",
    fontSize: "10px",
    color: "rgba(0,0,0,.87)",
  },
  notes: {
    fontSize: "10px",
    paddingHorizontal: "10px",
  },
  notesNote: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  preventivo: {
    width: "75%",
    marginHorizontal: "auto",
    marginTop: "50px",
    marginBottom: "50px",
  },
  preventivoHeader: {
    backgroundColor: "#2fcbe9",
    color: "#FFFFFF",
    paddingHorizontal: "10px",
    paddingVertical: "16px",
    textAlign: "left",
    fontSize: "10px",
    borderRadius:"4px"
  },
  preventivoBody: {
    padding: "15px",
  },
  preventivoBodyText: {
    fontSize: "10px",
  },

  termsBody: {
    marginBottom: "10px"
  },

  termsBodyRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    paddingVertical: "7px",
    borderBottom: "1px solid #39065F",
  },
  termsBodyItem: {
    textAlign: "left",
    paddingHorizontal:"10px",
  },
  termsBodyItemText: {
    color: "rgba(0,0,0,.87)",
    fontSize: "8px",
  },
  termsFooter: {
   backgroundColor:"#39065F",
   padding: "15px",
   color: "#FFFFFF",
   flex: 1
  },
  termsFooterTitle: {
    fontSize: "16px",
    marginBottom: "10px",
  },
  termsFooterBody: {
    display: "flex",
    flexDirection: "row",
    alignItems:"center",
    gap: "100px",
    fontSize: "10px",
  },
  termsFooterBodyItem: {
    
  },
  termsFooterBodyItemText: {
    
  },
  termsFooterBodyItemBtn: {
    backgroundColor: "#00FFFF",
    paddingVertical:"6px",
    width: "64px",
    textAlign:"center",
    textTransform: "uppercase",
    borderRadius: "4px",
  },
  cards: {
    marginTop: '10px',
  },
  card1: {
    textAlign: 'center',
    textAlign: '-webkit-center',
  },
  cardData: {
    borderRadius: '5px',
    padding: '10px',
    height: '110px',
    width:"180px",
    fontFamily: "Poppins 'sans-serif'",
    fontStyle: 'normal',
    fontWeight: '400',
    backgroundColor:"#540594"
  },
  heading: {
    borderBottom: '2px solid white',
    color:"white",
    fontSize:"10px",
    textAlignLast:"center",
    textAlign:"center"
  },
  paragraph:{
    color:"white",
    fontSize:"8px",
    marginTop:"5px"
  },
  footer: {
    backgroundColor: '#2DA0F0',
  },
  footerCards: {
    display: 'flex',
    flexDirection:"row",
    margin: '10px auto',
  },
  footerCard1: {
    display:"flex",
    height: '400px',
    width: '250px',
    borderRadius: '0px',
    textAlign: 'center',
    backgroundColor:"#540594"
  },
  footerCard2: {
   
  },
  footerCard3: {
 
  },
  offer: {
    marginLeft: '15px',
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    color: '#FFFFFF',
    textAlign: '-webkit-center',
    paddingTop: '30px',
  },
  offer: {
    h1: {
      borderBottom: '2px solid white',
      paddingBottom:"10px",
      paddingTop:"10px",
      color:"white"
    },
    p: {
      color:"white",
      display:"block",
      textAlign:"center"
    },
    div: {
      marginTop: '70px',
      color:"white", 
      marginLeft:"20px"
    },
    price:{
      marginTop:"20px"
    }
  },
  offer2: {
    marginLeft: '25px',
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    lineHeight: '-100px',
    color: '#FFFFFF',
    width: '300px',
    textAlign: '-webkit-center',
    paddingTop: '30px',
    h1: {
      borderBottom: '2px solid white'
    },
    p: {
      img: {
        width: '30px',
        height: '30px'
      }
    },
    div: {
      marginTop: '70px',
      lineHeight: '20px'
    },
    button: {
      background: 'white',
      borderRadius: '33.9553px',
      width: '170px',
      height: '40px',
      border: 'none',
      fontFamily: 'Poppins, sans-serif',
      color: 'black',
      fontSize: '16px'
    }
  },
  offer3: {
    marginLeft: '40px',
    fontFamily: 'Poppins, sans-serif',
    fontStyle: 'normal',
    lineHeight: '-100px',
    color: '#FFFFFF',
    width: '300px',
    textAlign: '-webkit-center',
    paddingTop: '30px',
    h1: {
      borderBottom: '2px solid white'
    },
    p: {
      img: {
        width: '30px',
        height: '30px'
      }
    },
    div: {
      marginTop: '70px',
      lineHeight: '20px'
    },
    button: {
      background: 'white',
      borderRadius: '33.9553px',
      width: '170px',
      height: '40px',
      border: 'none',
      fontFamily: 'Poppins, sans-serif',
      color: 'black',
      fontSize: '16px'
    }
  },
//about us 1
aboutUs1Body:{
  marginLeft: "15px",
  marginTop: "20px",
  textAlign: "justify",
  color: "#161F30",
  width: "95%",
},
aboutUs1ParaBody:{
  fontFamily: "Poppins 'sans-serif'",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "9px",
  marginBottom:"10px",

},
aboutUs1Footer:{
  backgroundColor:"#39065F",
  padding: "15px",
  color: "#FFFFFF",
  flex: 1 
},
aboutUs1FooterImages1:{
  display:"inline-flex",
  flexDirection:"row"
},
avatars:{
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  marginInline:"auto",
  marginLeft:"35px"
},
aboutUs1FooterNames1:{
  display:"inline-flex",
  flexDirection:"row"
},
aboutUs1FooterName:{
  marginInline:"auto",
  fontSize:"10px",
  fontWeight:"bold"
},
aboutUs1FooterJobs1:{
  display:"inline-flex",
  flexDirection:"row"
},
aboutUs1FooterJob:{
  marginInline:"auto",
  marginLeft:"45px",
  fontSize:"7px",
  fontStyle:"italic",
  textAlign:"center",
},
aboutUs1FooterImages2:{
  display:"inline-flex",
  flexDirection:"row"
},
avatars2:{
  width: "100px",
  height: "100px",
  borderRadius: "50%",
  marginInline:"auto",
  marginLeft:"35px"
},
aboutUs1FooterNames2:{
  display:"inline-flex",
  flexDirection:"row"
},
aboutUs1FooterJobs2:{
  display:"inline-flex",
  flexDirection:"row"
},

//introduction

introductionBody:{
  marginLeft: "15px",
  marginTop: "20px",
  textAlign: "justify",
  color: "#161F30",
  width: "95%",
},
introductionParaBody:{
  fontFamily: "Poppins 'sans-serif'",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "10px",
  marginBottom:"15px"
},
introductionFooter:{
  backgroundColor:"#39065F",
  padding: "15px",
  color: "#FFFFFF",
  flex: 1 
},
introductionFooterImages1:{
  display:"block",
  marginBottom:"-10px"
},
avatarAM:{
  width: "110px",
  height: "110px",
  borderRadius: "50%",
  marginInline:"auto",
  textAlign:"center",
  justifyContent:"center",
  placeContent:"center",
  marginLeft:"220px",
},
sign:{
  width: "120px",
  height: "80px",
  marginInline:"auto",
  textAlign:"center",
  justifyContent:"center",
  placeContent:"center",
  marginLeft:"220px"

},
introductionFooterNames1:{
  display:"block",
},
introductionFooterName:{
  marginInline:"auto",
  fontSize:"11px",
  textAlign:"center",
  fontWeight:"bold"
},
introductionFooterJobs1:{
  display:"block",
},
introductionFooterJob:{
  marginInline:"auto",
  marginLeft:"230px",
  textAlign:"center",
  fontSize:"10px",
  fontStyle:"italic",
  display:"inline-flex",
  flexDirection:"row"
},
artworkIcon:{
  width:"15px",
  height:"10px",
  marginLeft:"5px",
  marginTop:"3px"
},

//About Us 2

aboutUs2Cards:{
marginTop:"20px",
display:"inline-flex",
flexDirection:"row",
marginBottom:"20px",
marginRight:"10px",
},
aboutUs2Card:{
  display: "block",
  marginLeft:"10px",
  /* flex-direction: column; */
  /* align-items: flex-start; */
  padding: "15px",
  position: "relative",
  textAlignLast: "center",
  width: "350px",
  height: "180px",
  /* left: 5%; */
  background: "#FFFFFF",
  borderRadius: "12.8012px",
  marginInline: "auto",
  border:"1px solid grey"
},
cardImage:{
  marginLeft:"60px",
  width:"120px",
  marginInline:"auto",
  placeContent:"center"
},
aboutUs2CardText:{
  fontFamily: "Poppins 'sans-serif'",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "8px",
  textAlign: "justify",
  color: "#000000",
  padding: "5px",
  marginInline: "auto",
  textAlignLast: "auto"
},
divider:{

},
dividerText:{
  height: "41px",
  width:"100%",
  backgroundColor: "#39065F",
  padding: "10px",
  textAlign: "center",
  textTransform: "uppercase",
  color: "#FFFFFF",
  fontFamily: "Poppins 'sans-serif'",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "12px",
},
coImages:{
  marginTop:"-40px",
  display:"inline-flex",
  zIndex:"-99",
  flexDirection:"row"
},
coImage1:{
  zIndex:"-99",
  width:"50%"
},
coStats:{
  display:"inline-flex"
},
coStats1:{
  width:"50%",
marginTop:"-440px",
display:"block",
marginLeft:"5px",
textAlign:"center"
},
coTitle:{
  marginTop:"10px",
color:"white",
fontSize:"20px",
fontStyle:"normal",
fontWeight:"bold",

},
coYear:{
  marginTop:"300px",
  color:"white",
  fontSize:"45px",
  letterSpacing:"8px",
fontStyle:"normal",
fontWeight:"bold"

},

coImage2:{
  zIndex:"-99",
  marginRight:"0",
  width:"50%"
},
coStats2:{
  alignItems:"left",
  marginLeft:"55%",
  marginRight:"0",
  width:"50%",
marginTop:"-250px",
display:"block"
},
coStats21:{
display:"inline-flex",
flexDirection:"row",
marginBottom:"25px",

},
coStats211:{

},
coStats212:{
  marginLeft:"35px"
},
coStats22:{
  display:"inline-flex",
  flexDirection:"row",
  marginBottom:"15px"
  },
  coStats221:{
  
  },
  coStats222:{
    marginLeft:"35px"
  },
coStatsData:{
  color:"white",
  fontFamily: "Poppins 'sans-serif'",
  fontStyle:"normal",
  fontSize:"16px",
  fontWeight:"bold"
},
coStatsDesc:{
  color:"white",
fontFamily: "Poppins 'sans-serif'",
  fontStyle:"normal",
  fontSize:"9px",
  fontWeight:"normal",
  width:"80px"
},

//Interpreting
interpretingBody:{
  marginLeft: "20px",
  marginTop: "25px",
  textAlign: "justify",
  color: "#161F30",
  width: "95%",
},
interpretingParaBody:{
  fontFamily: "Poppins 'sans-serif'",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "10px",
  marginBottom:"5px"
},
interpretingIcons:{
  display:"inline-flex",
  flexDirection:"row",
  marginTop:"10px",
  marginBottom:"5px"
},
interpretingIcon:{
marginLeft:"55px",
width:"35px",
height:"30px"
},
interpretingIconsText:{
display:"inline-flex",
flexDirection:"row"
},
interpretingIconText:{
  width:"82px",
  fontFamily: "Poppins 'sans-serif'",
  fontStyle: "normal",
  fontWeight: "normal",
  textAlign:"center",
  fontSize: "8px",
  marginBottom:"5px",
},
interpretingFooter:{
  backgroundColor:"#39065F",
  padding: "15px",
  color: "#FFFFFF",
  flex: 1 
},
interpretingFooterImage:{
width:"100%",
height:"100%",
},

//Tech Equipment
techBody:{
  marginLeft: "15px",
  marginTop: "30px",
  textAlign: "justify",
  color: "#161F30",
  width: "95%",
},
techParaBody:{
  fontFamily: "Poppins 'sans-serif'",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "8px",
  marginBottom:"5px"
},
techSections:{
display:"inline-flex",
flexDirection:"row",
marginLeft:"5px",
marginRight:"5px"
},
techSection1:{
width:"50%",
display:"inline-flex",
flexDirection:"row"
},
techSection2:{
  width:"50%",
  display:"inline-flex",
  flexDirection:"row",
  marginLeft:"5px"
  },
  techSectionImage:{
    width:"80px",
    height:"70px",
    backgroundColor:"#6943cf"
  },
  techSectionText:{
    fontFamily: "Poppins 'sans-serif'",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "9px",
    marginBottom:"5px",
    marginLeft:"5px",
    marginRight:"10px",
    textAlign:"justify"
  },
techFooter:{
  backgroundColor:"#39065F",
  padding: "5px",
  color: "#FFFFFF",
  flex: 1,
  alignItems:"center",
  marginInline:"auto",
  placeContent:"center"
},
techFooterImage:{
  width:"500px",
  height:"100%",
  alignItems:"center",
  marginInline:"auto",
  placeContent:"center"
},
discountImage:{
},
discountTextSection:{
display:"inline-flex",
flexDirection:"row"
},
discountImages:{
width:"200px",
marginTop:"-840px",
height:"auto",
objectFit:"contain",
marginLeft:"20px"
},
discountText:{
marginTop:"-250px",
marginLeft:"10px",
marginRight:"10px"
},
discountList:{
  color:"white",
  fontSize:"10px",
  marginBottom:"10px",
},

greenImage:{
},
greenTextSection:{
display:"inline-flex",
flexDirection:"row"
},
greenImages:{
  width:"250px",
  marginTop:"-720px",
  height:"50px",
  objectFit:"contain",
  marginLeft:"10px"
  },
greenText:{
  marginBottom:"250px",
  marginLeft:"10px",
  marginRight:"10px"
},

homeImage:{
  marginTop:"-450px",
  height:"auto",
  objectFit:"contain",
  },

  //Testimonials
  testimonial:{
    marginTop:"10px",
    flexDirection:"column",
    display:"block"
  },

  testimonialCards:{
    display:"inline-flex",
    flexDirection:"row",
    marginRight:"10px",
    marginTop:"20px"
  },
  testimonialCard:{
    width:"200px",
    height:"130px",
    marginLeft:"10px",
    backgroundColor:"white",
    borderRadius:"12.12px",
    padding:"5px"
  },
  testimonialImage: {
    marginTop:"15px", display:"inline-flex", flexDirection:"row",
    marginRight:"10px"
  },

  //BLOGS
  blogList:{
    display:"inline-flex",
    flexDirection:"row",
    marginTop:"20px",
  },
  blogsLink:{
    width:"130px",
    color:"black",
    marginLeft:"20px",
    textDecoration:"underline",
    fontSize:"9px",
    fontStyle:"normal",
    fontWeight:"normal"
  },

  // our clients
  clientsImages: {
     display:"inline-flex", flexDirection:"row",
    borderBottom:"2px solid white"
  },
  clientsImage:{
    paddingTop:"5px",
    width:"200px",
    height:"130px",
    objectFit:"contain",
    paddingLeft:"5px",
    paddingRight:"5px",
    borderRight:"2px solid white",
    marginLeft:"10px",
  }
});

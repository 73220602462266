import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const Memory = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
       <View style={{ ...styles.header, marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
        <Text style={styles.headerItemText}>{language === 'English' ? `Translation Memory` : 
          `QUALITY`}</Text>    
              </View>
         <View>
          <Image style={{...styles.headerRightImg }} src="/translationAssets/desktop6/lingoyou.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={styles.techBody}>
      <Text style={styles.techParaBody}>
       {language === "English" ? `The Translation Memory (TM) is a fundamental tool in the workflow of a translation and interpreting agency. It's about a type of linguistic database used to store texts in one source language and its translations into another (targets). The fundamental idea of memory-based systems of translation is that it allows the translator or translation team to reuse previously translated segments, so as to make the translation process much faster, agile and efficient.`: 
       `La Translation Memory (TM) è uno strumento fondamentale nel lavoro quotidiano di un’agenzia di traduzioni e interpretariato. Si tratta di un tipo di database linguistico utilizzato per memorizzare testi in una lingua sorgente (source) e le relative traduzioni in un'altra (target). L'idea fondamentale dei sistemi basati sulla memoria di traduzione è che consente al traduttore o al team di traduzione di riutilizzare segmenti precedentemente tradotti, così da rendere il processo di traduzione più veloce, agile ed efficiente.`
          }
        </Text>
        <Text style={{...styles.techParaBody, fontWeight:"bold", fontStyle:"normal"}}>
       {language === "English" ? `What are the benefits of Translation Memory?`:
       `Quali sono i vantaggi della Translation Memory?`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• Terminological uniformity and consistent interpretation`:
       `• Uniformità terminologica e interpretazione coerente`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• Cost savings for the customer`:
       `• Risparmio sui costi per il cliente`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• Terminological or stylistic preference`:
       `• Preferenza terminologica o stilistica`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• Faster translation times with a subsequent increase in the number of completed projects and turnover`:
       `• Tempi di traduzioni più rapidi con il conseguente aumento del numero di progetti completati e del fatturato.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• Consistency and quality of translations for greater customer satisfaction`:
       `• Coerenza e qualità delle traduzioni a beneficio della soddisfazione del cliente.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• Easy format export`:`• Facile esportazione del formato`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `There are many C.A.T. for the Translation Memory, the best ones used by most translation agencies and translators professionals are: SDL Trados Studio, Déjà Vu X3, MemoQ and Wordfast.`: 
       `Esistono tanti C.A.T. per la Translation Memory, i migliori utilizzati dalla maggior parte delle agenzie di traduzioni e dei traduttori professionisti, sono SDL Trados Studio, Déjà Vu X3, MemoQ e Wordfast.`}
        </Text>
              </View>
              <Text style={{...styles.dividerText, backgroundColor:"#2DA0F0"}}>{language === 'English' ? 
      `TRANSLATION MEMORY IS PERFECT FOR` :
       `LA MEMORIA DI TRADUZIONE È IDEALE PER`}
       </Text>
       <View style={{...styles.testimonialImage,marginTop:"20px", marginBottom:"20px"}}>
          <Image style={{marginLeft:"15px", marginRight:"15px", width:"100%"}} src="/translationAssets/memory/footer.png"/>
          
       </View>
       
    </Page>
  );
};

export default Memory;
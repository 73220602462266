import { Grid } from "@mui/material";
import styles from "../styles/OnSiteInterpreting.module.css";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import { useSelector } from "react-redux";


const OnSiteInterpreting = () => {

  const { language} = useSelector(state => state.proposal)


    return (
      
        <div className={styles.desktop10}>
        {/* <div className={styles.div}>06</div> */}
        <div className={styles.interpreting} >
        {language === "English" ? "HYBRID SYSTEM ON-SITE INTERPRETING" : 
        "SISTEMA IBRIDO INTERPRETARIATO ON-SITE"}
        </div>
        <div className={styles.text3543Parent}>
          <img alt="" src="/rafiky/logo.webp" />{" "}
        </div>
        <div className={styles.desktop10Child} />
  
       
        <Grid
          container
          spacing={3}
          className={styles.section}
          sx={{ width: "98%" }}
        >
          <Grid
            item
            xs={12}
            lg={12}
            className={styles.section1}
            id={styles.sub_section1}
          >
            
            <div className={styles.text}>

              <b style={{fontSize:"20px"}}>
              {language === "English" ? "MODALITY" : " MODALITÀ"}
            </b><br/>
            - {language === "English" ? "On Site Speakers" : " Relatori on-site"}.
            <br/>
            - {language === "English" ? "Interpreters work remotely" : 
            "Gli interpreti lavorano da remoto"}.
            <br/>
            - {language === "English" ? "Participants listen to the conference via receiver and headphones or mobile app in the desired language." : 
            " I partecipanti ascoltano la conferenza tramite ricevitore e cuffie o mobile app nella lingua desiderata."}

            </div>
          </Grid>
          <Grid
            item
            xs={12}
            lg={12}
            className={styles.section1}
             sx={{ paddingTop:"8px" }}
          >
            <div className={styles.text}>

<b style={{fontSize:"20px"}}>
{language === "English" ? "THE ADVANTAGES" : 
"I VANTAGGI"}
</b><br/>
- {language === "English" ? "Save 50% on technical equipment (no cabin needed)." : 
"Risparmio del 50% sull’attrezzatura tecnica (nessuna cabina necessaria)."}
<br/>
- {language === "English" ? "Ideal for rooms where the installation of cabins is not possible/requires effort and greater costs" : 
" Ideale per sale in cui l’installazione delle cabine non è possibile/richiede sforzi e costi maggiori"}.
<br/>
- {language === "English" ? "Audio-video direction and on-site technical assistance" : " Regia audio-video ed assistenza tecnica in loco"}.

</div>
          </Grid>
        </Grid>
        
        <div className={styles.footer}>
         <img src="/rafiky/brochure/onSiteInterpreting.jpg"/>        
         </div>
      
      </div>
    );
};

export default OnSiteInterpreting;

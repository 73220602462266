export const states = [
  {code:"NOT IN ITALY",province:"NOT IN ITALY",region:"NOT IN ITALY"},
  {code:"AG",province:"Agrigento",region:"Sicilia"},
  {code:"AL",province:"Alessandria",region:"Piemonte"},
  {code:"AN",province:"Ancona",region:"Marche"},
  {code:"AO",province:"Aosta",region:"Valle d'Aosta"},
  {code:"AQ",province:"L'Aquila",region:"Abruzzo"},
  {code:"AR",province:"Arezzo",region:"Toscana"},
  {code:"AP",province:"Ascoli-Piceno",region:"Marche"},
  {code:"AT",province:"Asti",region:"Piemonte"},
  {code:"AV",province:"Avellino",region:"Campania"},
  {code:"BA",province:"Bari",region:"Puglia"},
  {code:"BT",province:"Barletta-Andria-Trani",region:"Puglia"},
  {code:"BL",province:"Belluno",region:"Veneto"},
  {code:"BN",province:"Benevento",region:"Campania"},
  {code:"BG",province:"Bergamo",region:"Lombardia"},
  {code:"BI",province:"Biella",region:"Piemonte"},
  {code:"BO",province:"Bologna",region:"Emilia Romagna"},
  {code:"BZ",province:"Bolzano",region:"Trentino Alto Adige"},
  {code:"BS",province:"Brescia",region:"Lombardia"},
  {code:"BR",province:"Brindisi",region:"Puglia"},
  {code:"CA",province:"Cagliari",region:"Sardegna"},
  {code:"CL",province:"Caltanissetta",region:"Sicilia"},
  {code:"CB",province:"Campobasso",region:"Molise"},
  {code:"CI",province:"Carbonia Iglesias",region:"Sardegna"},
  {code:"CE",province:"Caserta",region:"Campania"},
  {code:"CT",province:"Catania",region:"Sicilia"},
  {code:"CZ",province:"Catanzaro",region:"Calabria"},
  {code:"CH",province:"Chieti",region:"Abruzzo"},
  {code:"CO",province:"Como",region:"Lombardia"},
  {code:"CS",province:"Cosenza",region:"Calabria"},
  {code:"CR",province:"Cremona",region:"Lombardia"},
  {code:"KR",province:"Crotone",region:"Calabria"},
  {code:"CN",province:"Cuneo",region:"Piemonte"},
  {code:"EN",province:"Enna",region:"Sicilia"},
  {code:"FM",province:"Fermo",region:"Marche"},
  {code:"FE",province:"Ferrara",region:"Emilia Romagna"},
  {code:"FI",province:"Firenze",region:"Toscana"},
  {code:"FG",province:"Foggia",region:"Puglia"},
  {code:"FC",province:"Forli-Cesena",region:"Emilia Romagna"},
  {code:"FR",province:"Frosinone",region:"Lazio"},
  {code:"GE",province:"Genova",region:"Liguria"},
  {code:"GO",province:"Gorizia",region:"Friuli Venezia Giulia"},
  {code:"GR",province:"Grosseto",region:"Toscana"},
  {code:"IM",province:"Imperia",region:"Liguria"},
  {code:"IS",province:"Isernia",region:"Molise"},
  {code:"SP",province:"La-Spezia",region:"Liguria"},
  {code:"LT",province:"Latina",region:"Lazio"},
  {code:"LE",province:"Lecce",region:"Puglia"},
  {code:"LC",province:"Lecco",region:"Lombardia"},
  {code:"LI",province:"Livorno",region:"Toscana"},
  {code:"LO",province:"Lodi",region:"Lombardia"},
  {code:"LU",province:"Lucca",region:"Toscana"},
  {code:"MC",province:"Macerata",region:"Marche"},
  {code:"MN",province:"Mantova",region:"Lombardia"},
  {code:"MS",province:"Massa-Carrara",region:"Toscana"},
  {code:"MT",province:"Matera",region:"Basilicata"},
  {code:"VS",province:"Medio Campidano",region:"Sardegna"},
  {code:"ME",province:"Messina",region:"Sicilia"},
  {code:"MI",province:"Milano",region:"Lombardia"},
  {code:"MO",province:"Modena",region:"Emilia Romagna"},
  {code:"MB",province:"Monza-Brianza",region:"Lombardia"},
  {code:"NA",province:"Napoli",region:"Campania"},
  {code:"NO",province:"Novara",region:"Piemonte"},
  {code:"NU",province:"Nuoro",region:"Sardegna"},
  {code:"OG",province:"Ogliastra",region:"Sardegna"},
  {code:"OT",province:"Olbia Tempio",region:"Sardegna"},
  {code:"OR",province:"Oristano",region:"Sardegna"},
  {code:"PD",province:"Padova",region:"Veneto"},
  {code:"PA",province:"Palermo",region:"Sicilia"},
  {code:"PR",province:"Parma",region:"Emilia Romagna"},
  {code:"PV",province:"Pavia",region:"Lombardia"},
  {code:"PG",province:"Perugia",region:"Umbria"},
  {code:"PU",province:"Pesaro-Urbino",region:"Marche"},
  {code:"PE",province:"Pescara",region:"Abruzzo"},
  {code:"PC",province:"Piacenza",region:"Emilia Romagna"},
  {code:"PI",province:"Pisa",region:"Toscana"},
  {code:"PT",province:"Pistoia",region:"Toscana"},
  {code:"PN",province:"Pordenone",region:"Friuli Venezia Giulia"},
  {code:"PZ",province:"Potenza",region:"Basilicata"},
  {code:"PO",province:"Prato",region:"Toscana"},
  {code:"RG",province:"Ragusa",region:"Sicilia"},
  {code:"RA",province:"Ravenna",region:"Emilia Romagna"},
  {code:"RC",province:"Reggio-Calabria",region:"Calabria"},
  {code:"RE",province:"Reggio-Emilia",region:"Emilia Romagna"},
  {code:"RI",province:"Rieti",region:"Lazio"},
  {code:"RN",province:"Rimini",region:"Emilia Romagna"},
  {code:"RM", province:"Roma",region:"Lazio"},
  {code:"RO",province:"Rovigo",region:"Veneto"},
  {code:"SA",province:"Salerno",region:"Campania"},
  {code:"SS",province:"Sassari",region:"Sardegna"},
  {code:"SV",province:"Savona",region:"Liguria"},
  {code:"SI",province:"Siena",region:"Toscana"},
  {code:"SR",province:"Siracusa",region:"Sicilia"},
  {code:"SO",province:"Sondrio",region:"Lombardia"},
  {code:"TA",province:"Taranto",region:"Puglia"},
  {code:"TE",province:"Teramo",region:"Abruzzo"},
  {code:"TR",province:"Terni",region:"Umbria"},
  {code:"TO",province:"Torino",region:"Piemonte"},
  {code:"TP",province:"Trapani",region:"Sicilia"},
  {code:"TN",province:"Trento",region:"Trentino Alto Adige"},
  {code:"TV",province:"Treviso",region:"Veneto"},
  {code:"TS",province:"Trieste",region:"Friuli Venezia Giulia"},
  {code:"UD",province:"Udine",region:"Friuli Venezia Giulia"},
  {code:"VA",province:"Varese",region:"Lombardia"},
  {code:"VE",province:"Venezia",region:"Veneto"},
  {code:"VB",province:"Verbania",region:"Piemonte"},
  {code:"VC",province:"Vercelli",region:"Piemonte"},
  {code:"VR",province:"Verona",region:"Veneto"},
  {code:"VV",province:"Vibo-Valentia",region:"Calabria"},
  {code:"VI",province:"Vicenza",region:"Veneto"},
  {code:"VT",province:"Viterbo",region:"Lazio"},
];

import { Grid } from "@mui/material";
import styles from "../styles/AboutUs1.module.css";
import { useSelector } from "react-redux";

const AboutUs1 = () => {

  const {language} = useSelector((state)=>state.proposal);

  if(language === "English") {
    
  return(<div className={styles.desktop7}>
    <div className={styles.desktop7Item} />
    {/* <div className={styles.div}>03</div> */}
    <div className={styles.aboutUs}>ABOUT US</div>
    <div className={styles.text3543Parent}>
      <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{" "}
    </div>
    <div className={styles.weProvideTheContainer}>
      <p className={styles.weProvideTheExpertiseAndC}>
        <span>
        LingoYou was founded to provide accurate translations at affordable prices convenient to customers from all over the world. From the very beginning, we have been a key player in the translation industry all over the world
        </span>
      </p>
      <p className={styles.weProvideTheExpertiseAndC}>
        <span>&nbsp;</span>
      </p>
      <p className={styles.weProvideTheExpertiseAndC}>
        <span>
        This is why our mission has always been to break down all language barriers and to guarantee communication and interaction between speakers of different languages.
        </span>
      </p>
      <p className={styles.weProvideTheExpertiseAndC}>
        <span>&nbsp;</span>
      </p>
      <p className={styles.weProvideTheExpertiseAndC}>
        <span>
        Our team of experts has been working in the field of translation and interpreting for more than ten years and we believe the time has come to open the doors to innovation. With this goal in mind we have created our own customer portal and platform Rafiky!
        </span>
      </p>
     
    </div>
    <div className={styles.desktop7Frame}>
    <Grid
      container
      spacing={3}
      
    >
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/ChiaraMoschitta.webp"
        />
        <div className={styles.proyek}>Chiara Moschitta</div>
        <div className={styles.accountManager}>Account Manager</div>

      </Grid>
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/GiuliaCaravasso.webp"
        />
        <div className={styles.proyek}>Giulia Caravasso</div>
        <div className={styles.accountManager}>Account Manager</div>

      </Grid>
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/GiuseppeGagliano.webp"
        />
        <div className={styles.proyek}>Giuseppe Gagliano</div>
        <div className={styles.accountManager}>Account Manager</div>

      </Grid>
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
       <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/MariaSegreto.webp"
        />
        <div className={styles.proyek}>Maria Segreto</div>
        <div className={styles.accountManager}>Client Success Manager</div>

      </Grid>
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/MiriamFratacci.webp"
        />
        <div className={styles.proyek}>Miriam Fratacci</div>
        <div className={styles.accountManager}>Translation Project Manager</div>

      </Grid>
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/GiuseppePinelli.webp"
        />
        <div className={styles.proyek}>Giuseppe Pinelli</div>
        <div className={styles.accountManager}>Accountant</div>

      </Grid>
           
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/JimPuccio.webp"
        />
        <div className={styles.proyek}>Jim Puccio</div>
        <div className={styles.accountManager}>Graphic Designer</div>

      </Grid>
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/VeronicaGaudesi.webp"
        />
        <div className={styles.proyek}>Veronica Gaudesi</div>
        <div className={styles.accountManager}>Translation Project Manager</div>

      </Grid> 
      
    </Grid>
    </div>

  </div>)
  }
  return (
    <div className={styles.desktop7}>
      <div className={styles.desktop7Item} />
      {/* <div className={styles.div}>03</div> */}
      <div className={styles.aboutUs}>CHI SIAMO</div>
      <div className={styles.text3543Parent}>
        <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{" "}
      </div>
      <div className={styles.weProvideTheContainer}>
        <p className={styles.weProvideTheExpertiseAndC}>
          <span>
            LingoYou è stata fondata per fornire traduzioni accurate a prezzi
            convenienti a clienti da tutto il mondo. Fin dalla nostra istituzione, siamo un attore chiave nel settore della
            traduzione in più continenti.
          </span>
        </p>
        <p className={styles.weProvideTheExpertiseAndC}>
          <span>&nbsp;</span>
        </p>
        <p className={styles.weProvideTheExpertiseAndC}>
          <span>
            Per questo la nostra mission è sempre
            stata quella di abbattere ogni barriera linguistica e di garantire
            la comunicazione e l’interazione tra parlanti di lingue diverse.
          </span>
        </p>
        <p className={styles.weProvideTheExpertiseAndC}>
          <span>&nbsp;</span>
        </p>
        <p className={styles.weProvideTheExpertiseAndC}>
          <span>
          Il nostro Team di esperti lavora nel settore delle traduzioni e
            dell’interpretariato da più di dieci anni e crediamo che sia
            arrivato il momento di aprire le porte all’innovazione. Con questo
            obiettivo abbiamo creato il nostro portale clienti e la piattaforma
            Rafiky!
          </span>
        </p>
       
      </div>
      <div className={styles.desktop7Frame}>
      <Grid
      container
      spacing={3}
      
    >
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/ChiaraMoschitta.webp"
        />
        <div className={styles.proyek}>Chiara Moschitta</div>
        <div className={styles.accountManager}>Account Manager</div>

      </Grid>
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/GiuliaCaravasso.webp"
        />
        <div className={styles.proyek}>Giulia Caravasso</div>
        <div className={styles.accountManager}>Account Manager</div>

      </Grid>
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/GiuseppeGagliano.webp"
        />
        <div className={styles.proyek}>Giuseppe Gagliano</div>
        <div className={styles.accountManager}>Account Manager</div>

      </Grid>
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
       <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/MariaSegreto.webp"
        />
        <div className={styles.proyek}>Maria Segreto</div>
        <div className={styles.accountManager}>Client Success Manager</div>

      </Grid>
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/MiriamFratacci.webp"
        />
        <div className={styles.proyek}>Miriam Fratacci</div>
        <div className={styles.accountManager}>Translation Project Manager</div>

      </Grid>
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/GiuseppePinelli.webp"
        />
        <div className={styles.proyek}>Giuseppe Pinelli</div>
        <div className={styles.accountManager}>Accountant</div>

      </Grid>
           
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/JimPuccio.webp"
        />
        <div className={styles.proyek}>Jim Puccio</div>
        <div className={styles.accountManager}>Graphic Designer</div>

      </Grid>
      <Grid
        sx={{ flexDirection: "column", textAlign: "center" }}
        item
        xs={6}
        md={3}
      >
        <img  
          className={styles.frameChild}
          alt=""
          src="/translationAssets/desktop7/VeronicaGaudesi.webp"
        />
        <div className={styles.proyek}>Veronica Gaudesi</div>
        <div className={styles.accountManager}>Translation Project Manager</div>

      </Grid> 
      
    </Grid>
      </div>
    
    </div>
  );
};

export default AboutUs1;

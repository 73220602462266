import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const Interpreting = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={{ ...styles.header, marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
          <Text style={{...styles.headerItemText, fontSize:"9px"}}>{language === 'English' ? `INTERPRETATION & TECHNICAL EQUIPMENT` :
           `INTERPRETARIATO & ATTREZZATURA TECNICA`}</Text>
        </View>
         <View>
          <Image style={styles.headerRightImg} src="/assets/desktop6/group-1000001719.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
       
       <View style={{...styles.interpretingBody, width:"99%"}}>
        <Text style={{...styles.interpretingParaBody, display:"flex", flexDirection:"row", fontSize:"9px"}}>
       <Text>
       {language === "English" ? `We at Congressi Internazionali guarantee ` :
        `Noi di Congressi Internazionali garantiamo `}
      
       </Text>
       <Text style={{fontStyle:"normal", fontWeight:"bold"}}>
       {language === "English" ? `the best interpreting service on the territory.` :
        `il miglior servizio di interpretariato sul territorio. `}
      
      </Text> 
        </Text>
        <Text style={{...styles.interpretingParaBody, fontSize:"9px"}}>
       {language === "English" ? `The characteristics of our interpreters:` : ` Le caratteristiche dei nostri interpreti:`}
        </Text>
      </View>
     <View>
     <View style={styles.interpretingIcons}>
        <Image style={styles.interpretingIcon} src="/assets/icons/medal.png"/>
        <Image style={styles.interpretingIcon} src="/assets/icons/email.png"/>
        <Image style={styles.interpretingIcon} src="/assets/icons/user.png"/>
        <Image style={styles.interpretingIcon} src="/assets/icons/Certificate.png"/>
        <Image style={styles.interpretingIcon} src="/assets/icons/presentation.png"/>
        <Image style={styles.interpretingIcon} src="/assets/icons/star.png"/>

      </View>
      <View style={styles.interpretingIconsText}>
        <Text style={{...styles.interpretingIconText, marginLeft:"35px",}}>
        {language === "English" ? `Master Degree in Languages` : `Laurea Magistrale in Lingue`}
        </Text>
        <Text style={{...styles.interpretingIconText, marginLeft:"5px"}}>
        {language === "English" ? `At least 5 years of experience` : `Almeno 5 anni di esperienza`}
        </Text>
        <Text style={{...styles.interpretingIconText, marginLeft:"8px"}}>
        {language === "English" ? `Membership in linguist associations` : `Appartenenza ad Associazioni di Categoria`}
        </Text>
        <Text style={{...styles.interpretingIconText, marginLeft:"10px"}}>
        {language === "English" ? `Quality certifications` : `Certificazioni di qualità`}
        </Text>
        <Text style={{...styles.interpretingIconText, marginLeft:"5px"}}>
        {language === "English" ? `Sector specialization` : `Specializzazione Settoriale `}
        </Text>
        <Text style={{...styles.interpretingIconText, marginLeft:"5px"}}>
        {language === "English" ? `At least 5 Positive Feedbacks` : `Almeno 5 Feedback Positivi`}
        </Text>
      </View>
     </View>
          
     <View style={{width:"94%"}}>
        <View style={{...styles.techBody, width:"100%"}}>
        {/* <Text style={styles.techParaBody}>
       {language === "English" ? `At Congressi Internazionali we specialize in the rental of equipment for simultaneous translation. NO OUTSOURCING! At our warehouses in Milan and Rome, we have the best interpreting facilities in our sector.` : `Noi di Congressi Internazionali siamo specializzati nel noleggio di attrezzatura per la traduzione simultanea. NO OUTSOURCING! Presso i nostri depositi di Milano e Roma, disponiamo dei migliori impianti di interpretariato nel nostro settore.`}
        </Text> */}
        <Text style={{...styles.techParaBody, fontStyle:"normal", fontWeight:"bold", marginBottom:"5px"}}>
       {language === "English" ? `Facilities are a very important aspect of a conference with simultaneous translation; quality equipment is required for impeccable service. Here are our solutions:` : 
       `Gli impianti rappresentano un aspetto molto importante di una conferenza con traduzione simultanea; è necessaria un’attrezzatura di qualità per un servizio impeccabile. Ecco le nostre soluzioni:`}
        </Text>
      </View>
      <View style={{...styles.techSections,width:"96%", marginRight:"10px", display:"block", flexDirection:"column"}}>
      <View style={{...styles.techSection1,marginTop:"10px", width:"95%", marginRight:"10px"}}>
        <Image style={styles.techSectionImage} src="/assets/desktop10/image-22@2x.png"/>
        <Text style={styles.techSectionText}>
        {language === "English" ? `Table Booth: No place for an interpreting booth? No problem! We are one of the few companies in the country to have the Standard system with table booths. Thanks to our 120 cm Desktop booths in compliance with ISO/AIIC standards, with two workstations, we can offer you a series of advantages: - Reduction of assembly and disassembly times and costs: just a few minutes! - Guaranteed quality of the interpreting service: the interpreter is perfectly capable of isolating himself from the external environment. - Full compliance with international standards: safety and quality are our priorities. - Minimum size (measurements: 1.60 X 1.20) and unchanged design of the surrounding space.` : 
        `Cabina da Tavolo: Non c’è posto per una cabina di interpretariato? Nessun problema! Siamo una delle poche aziende sul territorio nazionale a disporre del sistema Standard con cabine da tavolo. Grazie alle nostre cabine Desktop da 120 cm a norme ISO/AIIC, a due posti lavoro, possiamo offrirvi una serie di vantaggi: - Riduzione dei tempi e dei costi di montaggio e smontaggio: solo pochi minuti! - Qualità del servizio di interpretariato garantita: l’interprete è perfettamente in grado di isolarsi dall’ambiente esterno. - Pieno rispetto degli standard internazionali: sicurezza e qualità sono le nostre priorità. -  Ingombro minimo  (misure: 1,60 X 1,20) e design dello spazio intorno immutato.`}

        </Text>
        </View>
        <View style={{...styles.techSection2, marginTop:"10px", width:"95%", marginRight:"10px"}}>
        <Image style={styles.techSectionImage} src="/assets/desktop10/image-20@2x.png"/>
        <Text style={styles.techSectionText}>
        {language === "English" ? `Premium Simultaneous System: our best translation system, practical and effective, thanks to the use of 360 degree soundproof booths (Premium booth: 168 cm soundproofed interpreting booth in accordance with ISO/AIIC standards, with two workstations, overall dimensions: 168x 204 metres). Translation is carried out simultaneously by professionals in special soundproofed interpreting booths. The interpreter receives the voice through headphones and translates into the microphone. The signal is distributed on the different frequencies through individual receivers supplied by the public.`: 
        `Sistema di Simultanea Premium: il nostro miglior sistema di traduzione, pratico ed efficace, grazie all’utilizzo di cabine insonorizzate a 360 gradi (Cabina premium: Cabina interpreti insonorizzata da 168 cm a norme ISO/AIIC, a due posti lavoro, misure ingombro: 168x 204 metri) La traduzione viene svolta in simultanea da professionisti all'interno di apposite cabine interpreti insonorizzate. L’interprete riceve la voce in cuffia e traduce al microfono. Il segnale viene distribuito sulle diverse frequenze attraverso ricevitori individuali in dotazione del pubblico.`}

        </Text>
      </View>
      </View>
      <View style={{...styles.techBody, width:"95%", marginTop:"10px", marginBottom:"20px"}}>
        <Text style={styles.techParaBody}>
       {language === "English" ? `It doesn't matter where in Italy you are: in case of request for specialized assistance, one of our technicians will assist you from the first inspection phase, and for the entire duration of the event, thus guaranteeing an incomparable success.` : 
       `Non importa in che zona d'Italia vi troviate: in caso di richiesta di assistenza specializzata, uno dei nostri tecnici Vi assisterà dalla fase di primo sopralluogo, e per tutta la durata dell'evento, garantendone così un successo impareggiabile. `}
        </Text>
      </View>
        </View>
        <View>
       
        </View> 
      
    </Page>
  );
};

export default Interpreting;
import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './globalStyles';

const CustomerGuide1 = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
       <View style={{ ...styles.header, marginBottom: 0, width: '97%', marginHorizontal: 'auto' }}>
        <View style={{ ...styles.headerItem }}>
          <Text style={{ ...styles.headerItemText, fontSize: '11px' }}>
          {language === "English" ? "CUSTOMER GUIDE - INTERPRETER ROLE" : "GUIDA PER IL CLIENTE - RUOLO INTERPRETE"}
          </Text>
        </View>
        <View>
          <Image style={{ ...styles.headerRightImg }} src="/rafiky/logo.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
     <View style={{display:"flex", flexDirection:"row"}}>
     <View style={{width:"60%", padding:"10px",
     }}>
       
       <View style={{marginBottom:"15px", marginTop:"15px"}}>
        <Text style={{fontSize:"10px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>1. </Text>
        {language === "English" ? "ISO PAS 24019 certified interpreter interface." : 
                "Interfaccia interprete certificata ISO PAS 24019."}
               
        </Text>
       </View>
       <View style={{marginBottom:"15px"}}>
        <Text style={{fontSize:"10px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>2. </Text>

        {language === "English" ? "Best audio and video quality." : 
            "La migliore qualità audio e video."}
        </Text>
       </View>
       <View style={{marginBottom:"15px"}}>
       
        <Text style={{fontSize:"10px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>3. </Text>
        {language === "English" ? "Relais mode: Possibility of listening to other interpreter booths." : 
            "Modalità Relais: possibilità di ascoltare altre cabine interpreti. "}
        </Text>
       </View>
       <View style={{marginBottom:"15px"}}>
        
        <Text style={{fontSize:"10px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>4. </Text>
        {language === "English" ? "Partner communication: Possibility for interpreters of listening to each other just like during on-site events." : 
"Comunicazione tra partner: possibilità per gli interpreti di ascoltarsi a vicenda, proprio come durante gli eventi in loco."}
</Text>
       </View>
       <View style={{marginBottom:"15px"}}>
       
        <Text style={{fontSize:"10px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>5. </Text>

        {language === "English" ? "Handover: Useful and simple button to set the timer for delivery during service." : 
            "Handover: pulsante utile per impostare il timer di cambio interprete durante il servizio."}

         
        </Text>
       </View>
       <View style={{marginBottom:"15px"}}>
        
        <Text style={{fontSize:"10px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>6. </Text>
        {language === "English" ? "Slow down: Asks the speaker to slow down to facilitate the interpreters' work and provide the best translation" : 
            "Slow down: chiede all'oratore di rallentare per facilitare il lavoro degli interpreti e fornire la migliore traduzione"}
           
        </Text>
       </View>
       <View style={{marginBottom:"15px"}}>
        
        <Text style={{fontSize:"10px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>7. </Text>
        {language === "English" ? "Receives viewers' questions privately and then addresses them to the speakers" : "Riceve privatamente le domande dei viewer per poi rivolgerle agli speaker"}

        </Text>
       </View>
       <View style={{marginBottom:"5px"}}>
       
        <Text style={{fontSize:"10px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>8. </Text>
        {language === "English" ? "HD Video and Full Screen Mode for the best display of shared files" : 
            "Video HD e modalità a schermo intero per la migliore visualizzazione dei file condivisi"}
           
        </Text>
       </View>
       
       
     </View>
     <View style={{width:"40%"}}>
     <Image style={{width:"90%", marginLeft:"5px", objectFit:"contain", zIndex:"-99",
      border:"9px solid white",borderTopLeftRadius:"20px", borderBottomRightRadius:"20px", 
        marginTop:"100px", marginRight:"40px"}}  src="/rafiky/brochure/CustomerGuide1.jpg"/>

     </View>
     </View>
     <Image style={{width:"100%",zIndex:"-99",marginTop: '-341px', height: '380px'}}  
     src="/rafiky/brochure/background.png"/>
    
    </Page>
  );
};

export default CustomerGuide1;
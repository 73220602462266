import { Grid } from '@mui/material';
import React from 'react';
import styles from '../styles/MiniLingoyouAcademy.module.css';
import { useSelector } from 'react-redux';

export default function MiniLingoyouAcademy() {
  const { language } = useSelector((state) => state.proposal);

  return (
    <div
    className={styles.pageImg}
      style={{
        backgroundImage: `url('/MiniBrochureImages/bg_academy.webp')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
      }}
    >
      <div className={styles.desktop17}>
        {/* <div className={styles.desktop17Child} />
        <div className={styles.interpreting}>INTERPRETATION</div>
        <div className={styles.text3543Parent}>
          <img alt="" src="/assets/desktop6/group-1000001719.svg" />{' '}
        </div> */}
        <div className={styles.header}>
          <h1>
          {language === "English"?"Elevate Your Language Skills: Dive into Our Diverse Language Courses" : 
          "Potenzia le Tue Competenze Linguistiche: Immergiti nei Nostri Corsi di Lingua Diversificati"
          }
          </h1>
          <p>
          {language === "English" ? "Unleash Your Linguistic Potential with Expert Guidance and Interactive Learning Tools":
          "Sblocca il Tuo Potenziale Linguistico con Guida Esperta e Strumenti Interattivi di Apprendimento"}
          </p>
        </div>
        <div className={styles.divider}>
        </div>
        <div className={styles.image}>
          <img src="/MiniBrochureImages/lingoyouAcademy.webp"/>
          </div>
          <div className={styles.btn}>
          <a href='https://www.lingoyou.com/academy/' target='_blank'>
            Visit the site
          </a>
          </div>
      </div>
    </div>
  );
}

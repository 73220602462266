import { Box, TableCell, TableRow, Typography } from '@mui/material'
import React from 'react'
import { fCurrency } from 'src/utils/formatNumber'
import { languageMappings } from '../../languages'
import styles1 from '../../styles/TablesItems.module.css';
import { useSelector } from 'react-redux';


function Table2Items({row, index, itemsTranslation }) {

    const {language} = useSelector((state)=>state.proposal);
  return (
   <>
   {language === "English" ? (
     <TableRow key={index}>
     <TableCell
       sx={{
         '.css-4dwhzq-MuiTableCell-root': {
           marginBottom: 'none',
         },
         borderBottom: 'none',
         maxWidth: '400px',
         fontFamily: "'Poppins', sans-serif",
         fontSize: 'large',
       }}
       align="left"
     >
       <Box>
        {row?.sourceLanguage?.name ? <Typography
           variant="subtitle2"
           sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
         >
           {row.title} - {row.sourceLanguage?.name} -{' '}
           {row?.targetLanguage?.map((language) => language.name).join(', ')}
         </Typography> : <Typography
           variant="subtitle2"
           sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
         >
           {row.title}
         </Typography>
         }
         <Typography
           sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
         >
           {row.service.Service['english']}
         </Typography>
         <Typography
           className={styles1.description}
           variant="body2"
           sx={{ color: 'text.secondary', fontSize: '15px' }}
         >
           {itemsTranslation ? itemsTranslation : ''}
         </Typography>
       </Box>
     </TableCell>
     <TableCell
       sx={{
         '.css-4dwhzq-MuiTableCell-root': {
           marginBottom: 'none',
         },
         borderBottom: 'none',
         fontFamily: "'Poppins', sans-serif",
         fontSize: 'large',
         maxWidth: '100px',
       }}
       align="center"
     >
       &nbsp;{fCurrency(row.price)}
     </TableCell>
     <TableCell
       sx={{
         '.css-4dwhzq-MuiTableCell-root': {
           marginBottom: 'none',
         },
         borderBottom: 'none',
         fontFamily: "'Poppins', sans-serif",
         fontSize: 'large',
       }}
       align="center"
     >
       {row.quantity}{' '}
     </TableCell>
     <TableCell
       sx={{
         '.css-4dwhzq-MuiTableCell-root': {
           marginBottom: 'none',
         },
         borderBottom: 'none',
         fontFamily: "'Poppins', sans-serif",
         fontSize: 'large',
       }}
       align="center"
     >
       {row.discount ? row.discount + '%' : ''}
     </TableCell>
     {row?.discount ? (
       <TableCell
         colSpan={2}
         sx={{ width: '100%', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
         align="center"
       >
         &nbsp;
         {fCurrency((
           Number(row?.price) * Number(row?.quantity) -
           (Number(row?.price) * Number(row?.quantity) * Number(row.discount)) / 100
         ))}{' '}
         <>
           |<br />{' '}
           <del>&nbsp;{fCurrency((Number(row?.price) * Number(row?.quantity)))}</del>
         </>
       </TableCell>
     ) : (
       <TableCell
         colSpan={2}
         sx={{ width: '100%', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
         align="center"
       >
         &nbsp;{fCurrency((Number(row.price) * Number(row.quantity)))}
       </TableCell>
     )}
   </TableRow>
   ) : (
    <TableRow key={index}>
    <TableCell
      sx={{
        '.css-4dwhzq-MuiTableCell-root': {
          marginBottom: 'none',
        },
        borderBottom: 'none',
        maxWidth: '400px',
        fontFamily: "'Poppins', sans-serif",
        fontSize: 'large',
      }}
      align="left"
    >
      <Box>
       {row?.sourceLanguage?.name ? <Typography
          variant="subtitle2"
          sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
        >
          {row.title} - {languageMappings[`${row.sourceLanguage?.name}`]} -{' '}
          {row?.targetLanguage?.map((language) => languageMappings[`${language.name}`]).join(', ')}
        </Typography> : <Typography
          variant="subtitle2"
          sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
        >
          {row.title} 
        </Typography> 
        }
        <Typography
          sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
        >
          {row.service.Service['italian']}
        </Typography>
        <Typography
          className={styles1.description}
          variant="body2"
          sx={{ color: 'text.secondary', fontSize: '15px' }}
        >
          {row.service.Description['italian']}
        </Typography>
      </Box>
    </TableCell>
    <TableCell
      sx={{
        '.css-4dwhzq-MuiTableCell-root': {
          marginBottom: 'none',
        },
        borderBottom: 'none',
        fontFamily: "'Poppins', sans-serif",
        fontSize: 'large',
        maxWidth: '100px',
      }}
      align="center"
    >
       &nbsp;{fCurrency(row.price)}
    </TableCell>
    <TableCell
      sx={{
        '.css-4dwhzq-MuiTableCell-root': {
          marginBottom: 'none',
        },
        borderBottom: 'none',
        fontFamily: "'Poppins', sans-serif",
        fontSize: 'large',
      }}
      align="center"
    >
      {row.quantity}{' '}
    </TableCell>
    <TableCell
      sx={{
        '.css-4dwhzq-MuiTableCell-root': {
          marginBottom: 'none',
        },
        borderBottom: 'none',
        fontFamily: "'Poppins', sans-serif",
        fontSize: 'large',
      }}
      align="center"
    >
      {row.discount ? row.discount + '%' : ''}
    </TableCell>
    {row?.discount ? (
      <TableCell
        colSpan={2}
        sx={{ width: '100%', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
        align="center"
      >
         &nbsp;
        {fCurrency((
          Number(row?.price) * Number(row?.quantity) -
          (Number(row?.price) * Number(row?.quantity) * Number(row.discount)) / 100
        ))}{' '}
        <>
          |<br />{' '}
          <del> &nbsp;{fCurrency((Number(row?.price) * Number(row?.quantity)))}</del>
        </>
      </TableCell>
    ) : (
      <TableCell
        colSpan={2}
        sx={{ width: '100%', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
        align="center"
      >
         &nbsp;{fCurrency((Number(row.price) * Number(row.quantity)))}
      </TableCell>
    )}
  </TableRow>
   )}
   </>
 
  )
}

export default Table2Items
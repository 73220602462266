export const TermsAndConditions = {
  en: {
    title1: 'Application',
    title1Para1:
      'Unless otherwise agreed in writing, these Conditions will be incorporated into every quote and/or contract for the sale or supply of goods or services by Rafiky.',
    title2: 'Acceptance',
    title2Para1:
      "All offers are provided subject to confirmation by Rafiky upon receipt of the customer's order. No contract will be concluded until such confirmation is given, or until the customer's order is otherwise accepted. Each order, once accepted, constitutes a separate contract.",
    title3: 'Payment Terms',
    title3Para1: 'Prepayment is required.',
    title4: 'Offer Validity',
    title4Para1: (date)=>`You must send confirmation of the quote by ${date}. After this date the quote may be subject to changes.`,
    title5: 'Participant Connection Criteria',
    title5Para1:
      'Limit of 3 participants with active cameras (including screen sharing as an active speaker). The number of non-active speakers should be a maximum of 50 and the number of connected viewers should be a maximum of 500.',
    title6: 'Technical Requirements',
    title6Heading1: 'Laptop or tablet',
    title6Para1: 'Headphones or earphones',
    title6Para2: 'Google Chrome browser (Preferred)',
    title6Para3: 'Internet connection (Ethernet or Wi-Fi)',
    title6Para4: 'Minimum internet speed of 6 Mbps for both download and upload.',
    title6Heading2: "Unless otherwise agreed in writing, it is the Client's responsibility to ensure",
    title6Para5:
      '(a) according to the guidelines established by Rafiky, sufficient internet bandwidth and quality Wi-Fi are provided on-site to all event participants.',
    title6Para6:
      '(b) If necessary, both remote and on-site access to the venue are made available to Rafiky in good time to allow the installation and testing of services and equipment.',
    title7: 'Connectivity',
    title7Para1:
      "If connectivity problems stemming from the customer's internet connection (or associated devices, systems, or platforms) prevent interpretation, the invoice will be fully paid. For clarity, if the connectivity issues are due to Rafiky's internet connection (or associated devices, systems, or platforms), the customer will be billed only for the period of time the interpretation was performed on a proportional basis.",
    title8: 'Additional Hours',
    title8Para1:
      'Additional costs will be charged in case of platform use for extra time not included in the quote. Additional costs will be based on the additional minutes/hours, number of channels, and number of participants. The customer will be billed only for the time the platform was used on a proportional basis. In the case of prolonged technical rehearsals and/or the conference, a fee of €150 per hour for interpreters and €100 per hour for technicians will be applied.',
    title9: 'Warranty',
    title9Para1:
      'Rafiky guarantees that the services and equipment provided will conform to the quote or, if other services and equipment are substituted, that they will not be of inferior quality to what was quoted.',
    title10: 'Cancellations / Postponed Events',
    title10Para1:
      'Cancellation by the Client of a duly confirmed order via email is subject to cancellation fees, based on the following conditions: For interpretation services, a cancellation penalty of 20 business days equal to 50% of the total quote applies. A cancellation penalty of 10 business days equal to 100% of the total quote applies. For Platform rental, a cancellation penalty equal to 100% of the total applies in case of cancellation within 10 business days before the event.',
    title11: 'Indemnity',
    title11Para1:
      "The Client is required to indemnify Rafiky for all damages and/or injuries to any business or company and for all legal costs and expenses for which Rafiky may be held liable in relation to goods sold or services provided under a contract, except to the extent expressly admitted in these Conditions and unless such damages or injuries were the direct result of Rafiky's negligence and cannot be attributed to any other cause (in whole or in part).",
    title12: 'Confidentiality',
    title12Para1:
      'Rafiky binds its employees, both internal and subcontractors, to exercise the utmost confidentiality regarding the documents and the information contained therein.',
    title13: 'Liability',
    title13Para1:
      'The software and services are provided without warranties of any kind. We will not be liable for software and server malfunctions.',
    title14: 'Streaming Service',
    title14Para1:
      'The streaming service is performed on third-party servers (Facebook, YouTube, etc.). As we have no technical control over these external platforms, we are not responsible for service interruptions, delays in publishing the video, or any issues related to streaming.',
    title15: 'Recording Service',
    title15Para1:
      'Our technicians will strive, in accordance with our code of ethics and professionalism, to record the complete contents of the conference on our cloud servers. However, we are not responsible for any missing conference content due to online connection drops/delays or issues in transferring data from the servers.',
  },
  it: {
    title1: 'Applicazione',
    title1Para1: `Se non diversamente concordato per iscritto, le presenti Condizioni saranno incorporate in ogni preventivo e/o contratto per la vendita o la fornitura di beni o servizi da parte di Rafiky.`,
    title2: 'Accettazione',
    title2Para1: `Tutte le offerte sono fornite con riserva di conferma da parte di Rafiky al ricevimento dell'ordine del cliente. Nessun contratto sarà concluso fino a quando tale conferma non sarà data, o fino a quando l'ordine del cliente non sarà stato accettato in altro modo. Ogni ordine, una volta accettato, costituisce un contratto separato.`,
    title3: 'Termini di pagamento',
    title3Para1: 'È richiesto il pagamento anticipato.',
    title4: 'Validità offerta',
    title4Para1:(date)=> `È necessario inviare conferma del preventivo entro il ${date}. Oltre tale data il preventivo potrebbe subire delle variazioni.`,
    title5: 'Criteri di collegamento dei partecipanti',
    title5Para1: `Limite di 3 partecipanti con camera attiva (da includere lo screen sharing come speaker attivo).Il numero degli speaker non attivi deve essere massimo di 50 e quello dei viewers collegati, di massimo 500.`,
    title6: ' Requisiti tecnici',
    title6Heading1: ` Laptop o tablet`,
    title6Para1: `cuffie o auricolari`,
    title6Para2: `browser Google Chrome (Preferibile)`,
    title6Para3: `Connessione Internet (Ethernet o Wi-Fi) `,
    title6Para4: `Velocità minima di internet 6 mbps in Download e Upload.`,
    title6Heading2: `Se non diversamente concordato per iscritto, è responsabilità del Cliente assicurare`,
    title6Para5: `(a) secondo le linee guida stabilite da Rafiky, sia fornita sul posto una sufficiente larghezza di banda Internet e un Wi-Fi di qualità a tutti i partecipanti all'evento.`,
    title6Para6: `(b) Se necessario, sia l'accesso da remoto che l'accesso in loco alla sede siano messi a disposizione di Rafiky in tempo utile per consentire l'installazione e il collaudo dei servizi e delle attrezzature`,
    title7: 'Connettività',
    title7Para1: `Qualora problemi di connettività derivanti dalla connessione internet del cliente (o da dispositivi, sistemi o piattaforme associate) impedissero di effettuare l'interpretazione, la fattura sarà integralmente pagata. A scanso di equivoci, se i problemi di connettività sono originati dalla connessione internet di Rafiky (o dai dispositivi, sistemi o piattaforme associati), il cliente sarà fatturato solo per il periodo di tempo in cui l'interpretazione è stata effettuata su base proporzionale.`,
    title8: 'Ore aggiuntive',
    title8Para1: ` I costi aggiuntivi saranno addebitati in caso di utilizzo della piattaforma per un tempo supplementare non incluso nel presente preventivo. I costi aggiuntivi saranno basati sui minuti/ore aggiuntive, sul numero di canali e sul numero di partecipanti. Il cliente sarà fatturato solo per il periodo di tempo in cui la piattaforma è stata utilizzata in modo proporzionale. In case di prolungamento delle prove tecniche e/o della conferenza verranno applicatele tariffe di 150€ all'ora per interprete - 100€ all'ora per tecnico. `,
    title9: 'Garanzia',
    title9Para1: `Rafiky garantisce che i servizi e le attrezzature fornite saranno conformi al preventivo o, se vengono sostituiti altri servizi e attrezzature, che non saranno inferiori in termini di qualità a ciò che è stato preventivato.`,
    title10: 'Cancellazioni / Eventi posticipati',
    title10Para1: `L'annullamento da parte del Cliente di un ordine debitamente confermato via email è soggetto a spese di annullamento, sulla base delle seguenti condizioni: Per i servizi di interpretariato è prevista una penale di cancellazione di 20 giorni lavorativi pari al 50% del preventivo totale. E' prevista inoltre una penale di cancellazione di 10 giorni lavorativi pari al 100% del preventivo totale. Per il noleggio della Piattaforma, si applica una penale di cancellazione pari al 100% del totale in caso di cancellazione entro 10 giorni lavorativi prima dell'evento.`,
    title11: `Indennità`,
    title11Para1: ` Il Cliente è tenuto a risarcire Rafiky per tutti i danni e/o le lesioni a qualsiasi impresa o società e per tutti gli oneri e le spese processuali per i quali Rafiky può essere tenuto responsabile in relazione alle merci vendute o ai servizi forniti nell'ambito di un contratto, salvo nella misura espressamente ammessa nelle presenti Condizioni e a meno che tali danni o lesioni non siano stati il risultato diretto della negligenza di Rafiky e non possano essere attribuiti a nessun'altra causa (in tutto o in parte).`,
    title12: 'Riservatezza',
    title12Para1: `Rafiky impegna i propri dipendenti, sia interni che subappaltatori, ad esercitare la massima riservatezza in merito ai documenti e alle informazioni in essi contenute.`,
    title13: `Responsabilità`,
    title13Para1: ` Il software ed i servizi sono forniti senza garanzie di alcun tipo. Non saremo responsabili di malfunzionamenti del software e dei server.`,
    title14: `Servizio di Streaming`,
    title14Para1: ` Il servizio di streaming viene effettuato su server di aziende terze (Facebook, Youtube, ecc...) non avendo nessun controllo a livello tecnico di queste piattaforme esterne, non ci riteniamo responsabili di interruzione/i del servizio, ritardo/i in fase di pubblicazione del video e problematiche di qualsiasi tipo legate allo streaming.`,
    title15: `Servizio di Recording`,
    title15Para1: ` I nostri tecnici si impegneranno, rispettando il nostro codice etico e di professionalità al fine di registrare i contenuti completi della conferenza sui nostri server in cloud. Non ci riteniamo tuttavia responsabili di mancanze di contenuti della conferenza generate da un calo/ritardi delle connessioni online e/o problematiche nel trasferimento dei dati dai server.`,
  },
};

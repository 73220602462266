import React from "react";
import styles from '../styles/TechPortal.module.css'
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { useSelector } from "react-redux";

export default function TechPortal() {

  const {language} = useSelector((state)=>state.proposal);

  if (language === 'English') {
    return (<div>
      <div className={styles.desktop8Border} />
      <div className={styles.desktop8}>
        <div className={styles.desktop8Child} />
        <img
          className={styles.unionIcon}
          alt=""
          src="/translationAssets/desktop7/union9.svg"
        />
        <div className={styles.desktop8Item} />
        {/* <div className={styles.div}>08</div> */}
        <div className={styles.aboutUs}>Technology – LingoYou Portal</div>
        <div className={styles.text3543Parent}>
          <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{" "}
        </div>
        <div className={styles.list}>
          <ul>
            <li>Receive instant quotations.</li>
            <li>Upload and download files.</li>
            <li>
              Check out comprehensive project status reports with task lists and calendars.

            </li>
            <li>Receive projects, anytime, anywhere.</li>
            <li>Import and export spreadsheet information.</li>
            <li>
              Sign up to receive project status information and automatic email updates.

            </li>
            <li>
              Apply role-based security systems to all users.

            </li>
            <li>Collaborate with selected virtual team members.
            </li>
          </ul>
        </div>
        <div className={styles.desktop8Item}>
                <div style={{width:"720px", paddingTop:"100px",marginInline:"auto"}}>
    <LiteYouTubeEmbed 
        id="_x5KCDcJft4"
        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
    />
          </div>
        </div>
      </div>
    </div>)
  }
  return (
    <div>
      <div className={styles.desktop8Border} />
      <div className={styles.desktop8}>
        <div className={styles.desktop8Child} />
        <img
          className={styles.unionIcon}
          alt=""
          src="/translationAssets/desktop7/union9.svg"
        />
        <div className={styles.desktop8Item} />
        {/* <div className={styles.div}>08</div> */}
        <div className={styles.aboutUs}>Tecnologia – Portale LingoYou</div>
        <div className={styles.text3543Parent}>
          <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{" "}
        </div>
        <div className={styles.list}>
          <ul>
            <li> Ricevi preventivi e offerte immediati.</li>
            <li>Carica e scarica file.</li>
            <li>
              Controlla i report completi sullo status dei progetti con gli
              elenchi di attività e calendari.
            </li>
            <li>Ricevi i progetti, in qualsiasi momento e ovunque.</li>
            <li>Importa ed esporta le informazioni dei fogli di calcolo.</li>
            <li>
              Iscriviti per ricevere informazioni sullo status del progetto e
              aggiornamenti e-mail automatici.
            </li>
            <li>
              Applica sistemi di sicurezza basati sui ruoli a tutti gli utenti.
            </li>
            <li>Collabora con i membri del team virtuale selezionato.</li>
          </ul>
        </div>
        <div className={styles.desktop8Item}>
                <div style={{width:"720px", paddingTop:"100px",marginInline:"auto"}}>
    <LiteYouTubeEmbed 
        id="_x5KCDcJft4"
        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
    />
          </div>
        </div>
      </div>
    </div>
  );
}









import { Box, Grid } from "@mui/material";
import styles from "../styles/Home.module.css";
import { useCallback } from "react";
import {AiOutlineCloudUpload} from 'react-icons/ai';
import { useSelector } from "react-redux";
import { SectorsContent } from "../sectorsText";


const Home = () => {

  const {details, language} = useSelector(state => state.proposal)

  const {companyNameAndId, bookedBy, accountManager, subject, orderNumber, companyLogo, industry} = details

  return (
    <div className={styles.desktop5}>
      <div className={styles.mainVector}>
       <div className={styles.vector1}></div>
       <div className={styles.vector2}></div>
       <div className={styles.vector3}></div>
       <div className={styles.vector4}></div>
       <div className={styles.vector5}></div>
       <div className={styles.vector6}></div>

      </div>
      <div className={styles.desktop5Child} style={{ background: `url(${SectorsContent[industry?.name]?.cover}) no-repeat right`, backgroundPositionX:'right', visibility:"collapse" }}>

      </div>
      <img
        className={styles.desktop5Child}
        alt=""
        src={`${SectorsContent[industry?.name]?.cover}`}
      />
      <div className={styles.text3543Parent}>
      <img
        alt=""
        src="/translationAssets/desktop5/lingoyoulogo.png"
      /> </div>
      
       {language === 'English' ? (
        <>
      <div className={styles.projectOfficialProEngParent}>
        <i className={styles.projectOfficial}>
        Kindly Addressed To {bookedBy ? `${bookedBy?.firstName} ${bookedBy?.lastName}` : '___'}.
        </i>
        <i className={styles.editedByManuel}>Document Created By {accountManager ? accountManager.name : "__"}.</i>
      </div>
      <div className={styles.proposalDedicatedTo}>
      Brochure DEDICATED TO <br/>{companyNameAndId ? companyNameAndId.name : '___'}<br/>
      {companyLogo &&  <img src={companyLogo}/>}
      </div>
       </>
       )
       :
       <>
       <div className={styles.projectOfficialProEngParent}>
         <i className={styles.projectOfficial}>
           All'attenzione di {bookedBy ? `${bookedBy?.firstName} ${bookedBy?.lastName}` : '___'}.
         </i>
         <i className={styles.editedByManuel}>Documento Realizzato da {accountManager ? accountManager.name : "__"}.</i>
       </div>
       <div className={styles.proposalDedicatedTo}>
       Brochure dedicata a <br/>{companyNameAndId ? companyNameAndId.name : '___'}<br/>
         {companyLogo &&  <img src={companyLogo}/>}

       </div>
        </>
       }
    </div>
  );
};

export default Home;

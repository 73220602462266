import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const TechnologyPlateform = ({ language }) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={{ ...styles.header, marginBottom: 0, width: '94%', marginHorizontal: 'auto' }}>
        <View style={{ ...styles.headerItem }}>
          <Text style={styles.headerItemText}>
            {language === 'English' ? `Technology – Rafiky platform` : `Tecnologia – Piattaforma Rafiky`}
          </Text>
        </View>
        <View>
          <Image style={{ ...styles.headerRightImg }} src="/translationAssets/desktop6/lingoyou.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={styles.techBody}>
        <Text style={{ fontSize: '11px', fontWeight: 'normal' }}>
          {language === 'English'
            ? 'We combine the power of cloud technology with modern devices to facilitate the technique of interpretation.'
            : 'Uniamo la potenza della tecnologia cloud con i moderni dispositivi per agevolare la tecnica dell’interpretariato.'}
        </Text>
        <View style={styles.techCards}>
          <View style={styles.techCard1}>
            <Text style={styles.techCardContent}>
              {language === 'English' ? 'Any language in real time' : 'Qualsiasi lingua in tempo reale'}
            </Text>
          </View>
          <View style={styles.techCard1}>
            <Text style={styles.techCardContent}>
              {language === 'English'
                ? 'Highest Quality Video & Crystal Clear Audio'
                : 'Video di altissima qualità & Audio cristallino'}
            </Text>
          </View>
          <View style={styles.techCard1}>
            <Text style={styles.techCardContent}>
              {language === 'English' ? 'Branding customization' : 'Personalizzazione branding'}
            </Text>
          </View>
        </View>
        <View style={styles.techCards}>
          <View style={styles.techCard1}>
            <Text style={styles.techCardContent}>
              {language === 'English'
                ? 'Post-event reporting'
                : 'Reportistica post-evento'}{' '}
            </Text>
          </View>
          <View style={styles.techCard1}>
            <Text style={styles.techCardContent}>
              {language === 'English' ? 'Upload PPT & Video' : 'Upload PPT & Video'}
            </Text>
          </View>
          <View style={styles.techCard1}>
            <Text style={styles.techCardContent}>
              {language === 'English' ? 'Virtual voting system' : 'Sistema di votazione virtuale'}
            </Text>
          </View>
        </View>
        <View style={styles.techCards}>
          <View style={styles.techCard1}>
            <Text style={styles.techCardContent}>
              {language === 'English' ? 'Girth Speaker' : 'Sottopancia Relatore'}
            </Text>
          </View>
          <View style={styles.techCard1}>
            <Text style={styles.techCardContent}>
              {language === 'English' ? 'Video Box with sign language' : 'Video Box con lingua dei segni'}{' '}
            </Text>
          </View>
          <View style={styles.techCard1}>
            <Text style={styles.techCardContent}>
              {language === 'English'
                ? 'Translation into more than 60 languages ​​for interface content'
                : 'Traduzione in + di 60 lingue per i contenuti interfaccia'}{' '}
            </Text>
          </View>
        </View>
        <View style={styles.techCards}>
          <View style={{...styles.techCard1, marginLeft:"15%"}}>
            <Text style={styles.techCardContent}>
            {language === 'English'
                  ? 'Compatibility with all Web Conferencing software'
                  : 'Compatibilità con tutti i software di Web Conferencing'}       
                       </Text>
          </View>
          <View style={{...styles.techCard1, border:"none", paddingTop:"10px", marginRight:"15%"}}>
            <Text style={styles.techCardContent}>
            {language === 'English' ? '.... And  much more!' : '... E tanto altro ancora!'}
            </Text>
          </View>
        </View>
        <View style={styles.techCards}>
          <Image style={{marginLeft:"35%", width:"100%", height:"40px", marginRight:"30%"}} src="/translationAssets/desktop9/image 19.png"/>
        </View>
      </View>
      <View style={{ ...styles.techFooter, textAlign: 'center', placeContent: 'center', width: '100%' }}>
        
        <Link style={{ width: '400px' }} href="https://www.youtube.com/embed/KCgEhNeVhUc">
          <Image style={styles.techFooterImage} src="/translationAssets/desktop9/techplateform.png" />
        </Link>
        <Link
          style={{
            marginTop:"10px",
            padding: '5px',
            width: '250px',
            color: 'black',
            textAlign: 'center',
            backgroundColor: 'white',
            borderRadius: '15px',
            fontSize:'12px'
          }}
          href="https://www.rafiky.net/"
        >
          {language === 'English' ? 'Request a Free Demo':'Richiedi una demo gratuita'}
        </Link>
      </View>
    </Page>
  );
};

export default TechnologyPlateform;

import React from 'react';
import styles from '../styles/TechnologyPlateform.module.css';
import { Grid } from '@mui/material';
import LiteYouTubeEmbed from 'react-lite-youtube-embed';
import { useSelector } from 'react-redux';

export default function TechnologyPlateform() {

  const {language} = useSelector((state)=>state.proposal);

  return (
    <div>
      <div className={styles.desktop9Border} />
      <div className={styles.desktop9}>
        <img className={styles.unionIcon} alt="" src="/translationAssets/desktop7/union9.svg" />
        {/* <div className={styles.div}>09</div> */}
        <div className={styles.text3543Parent}>
          <div className={styles.aboutUs}>
            {language === 'English' ? 'Technology – Rafiky platform' : 'Tecnologia – Piattaforma Rafiky'}
          </div>
          <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{' '}
        </div>
        <div className={styles.desktop9Child} />

        <p className={styles.line1}>
          {language === 'English'
            ? 'We combine the power of cloud technology with modern devices to facilitate the technique of interpretation.'
            : 'Uniamo la potenza della tecnologia cloud con i moderni dispositivi per agevolare la tecnica dell’interpretariato.'}
        </p>
        <Grid className={styles.list} sx={{ marginRight: '20px' }} container rowSpacing={3}>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <div className={styles.cards}>
              <p>{language === 'English' ? 'Any language in real time' : 'Qualsiasi lingua in tempo reale'}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <div className={styles.cards}>
              <p>
                {language === 'English'
                  ? 'Highest Quality Video & Crystal Clear Audio'
                  : 'Video di altissima qualità & Audio cristallino'}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <div className={styles.cards}>
              <p>{language === 'English' ? 'Branding customization' : 'Personalizzazione branding'}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <div className={styles.cards}>
              <p>
                {language === 'English'
                  ? 'Post-event reporting'
                  : 'Reportistica post-evento'}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <div className={styles.cards}>
              <p>{language === 'English' ? 'Upload PPT & Video' : 'Upload PPT & Video'}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <div className={styles.cards}>
              <p>{language === 'English' ? 'Virtual voting system' : 'Sistema di votazione virtuale'}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <div className={styles.cards}>
              <p>{language === 'English' ? 'Girth Speaker' : 'Sottopancia Relatore'}</p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <div className={styles.cards}>
              <p>{language === 'English' ? 'Video Box with sign language' : 'Video Box con lingua dei segni'}</p>
            </div>
          </Grid>
          <Grid item xs={12} xl={2}></Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <div className={styles.cards}>
              <p>
                {language === 'English'
                  ? 'Translation into more than 60 languages ​​for interface content'
                  : 'Traduzione in + di 60 lingue per i contenuti interfaccia'}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <div className={styles.cards}>
              <p>
                {language === 'English'
                  ? 'Compatibility with all Web Conferencing software'
                  : 'Compatibilità con tutti i software di Web Conferencing'}
              </p>
            </div>
          </Grid>
          <Grid item xs={12} md={6} lg={4} xl={3}>
            <p style={{ padding: '20px' }}>
              {language === 'English' ? '.... And  much more!' : '... E tanto altro ancora!'}
            </p>
          </Grid>
          <Grid item xs={12} xl={1}></Grid>
          <Grid item xs={12} md={6} lg={12}>
            <div style={{ textAlign: 'center', marginTop: '20px', marginBottom: '30px' }}>
              <img style={{ marginInline: 'auto' }} src="/translationAssets/desktop9/image 19.png" />
            </div>
          </Grid>
        </Grid>

        <div className={styles.desktop9Item} style={{height:"600px"}}>
        <div style={{width:"720px", paddingTop:"50px",marginInline:"auto"}}>
    <LiteYouTubeEmbed 
        id="KCgEhNeVhUc"
        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
    />
          </div>
          <button style={{marginTop:"20px"}}>
            <a href="https://www.rafiky.net/">
              {language === 'English' ? 'REQUEST A FREE DEMO' : 'RICHIEDI UNA DEMO GRATUITA'}
            </a>
          </button>
        </div>
      </div>
    </div>
  );
}

import { Button } from '@mui/material';
import React from 'react';
import styles from '../styles/Contact.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDoc from '../PDFDoc/PDFDoc';
import { useSelector } from 'react-redux';


export default function Contact() {
  
  const {details, language} = useSelector(state => state.proposal)

  
  return (
    <div className={styles.desktop19}>
      <div className={styles.mainVector}>
        <div className={styles.vector1}></div>
        <div className={styles.vector2}></div>
        <div className={styles.vector3}></div>
        <div className={styles.vector4}></div>
        <div className={styles.vector5}></div>
        <div className={styles.vector6}></div>
      </div>
      <img className={styles.desktop19Child} alt="" src="/assets/desktop5/group-1000001731.svg" />
      <div className={styles.text3543Parent}>
        <img alt="" src="/assets/desktop5/group-1000001730.svg" />{' '}
      </div>
   {language === "English" ?  <div className={styles.addresses}>
        <div className={styles.address1}>
          <b>
            <img src="/assets/icons/Location.svg" /> Turin
          </b>
          <p>10 Via Confienza, Turino 10121<br/> Italy</p>
        </div>
        <div className={styles.address2}>
          <b>
            <img src="/assets/icons/Location.svg" /> Milan
          </b>
          <p>Viale Fulvio Testi, 59 Milan 20158<br/> Italy</p>
        </div>
        <div className={styles.address3}>
          <b>
            <img src="/assets/icons/Location.svg" /> Florence
          </b>
          <p>15 Viale Giacomo Matteotti, Florence 50100<br/> Italy</p>
        </div>{' '}
        <div className={styles.address4}>
          <b>
            <img src="/assets/icons/Location.svg" /> Roma
          </b>
          <p>Via Ruggero D’Altavilla, 12 Rome 00176<br/> Italy</p>
        </div>
        <div className={styles.address5}>
          <b>
            <img src="/assets/icons/Location.svg" /> Naples
          </b>
          <p>63 via Benedetto Brin, Naples 80100<br/> Italy</p>
        </div>
      </div> :  <div className={styles.addresses}>
        <div className={styles.address1}>
          <b>
            <img src="/assets/icons/Location.svg" /> Torino
          </b>
          <p>10 Via Confienza, Torino 10121<br/> Italia</p>
        </div>
        <div className={styles.address2}>
          <b>
            <img src="/assets/icons/Location.svg" /> Milano
          </b>
          <p>Viale Fulvio Testi, 59 Milano 20158<br/> Italia</p>
        </div>
        <div className={styles.address3}>
          <b>
            <img src="/assets/icons/Location.svg" /> Firenze
          </b>
          <p>15 Viale Giacomo Matteotti, Firenze 50100<br/> Italia</p>
        </div>{' '}
        <div className={styles.address4}>
          <b>
            <img src="/assets/icons/Location.svg" /> Roma
          </b>
          <p>Via Ruggero D’Altavilla, 12 Roma 00176<br/> Italia</p>
        </div>
        <div className={styles.address5}>
          <b>
            <img src="/assets/icons/Location.svg" /> Napoli
          </b>
          <p>63 via Benedetto Brin, Napoli 80100<br/> Italia</p>
        </div>
      </div>}
      <div className={styles.contact}>
        <img style={{marginTop:"-20px",  width:"50px"}} src="/assets/icons/Phone.svg" />{' '}
        <p className={styles.number}> +39 3755806530, +39 0282957099</p>
        <img style={{marginTop:"-10px", width:"50px"}} src="/assets/icons/Facebook.svg" />{' '}
        <a href="https://web.facebook.com/congressiint/?_rdc=1&_rdr" target="_blank"style={{bottom:"-5px"}} className={styles.fb}>
          https://www.facebook.com/congressiint/
        </a>
        <br />
        <br />
        <img style={{width:"50px"}} src="/assets/icons/Instagram.svg" />{' '}
        <a href="https://www.instagram.com/congressi_internazionali/" target="_blank" style={{bottom:"-20px"}} className={styles.insta}>
          https://www.instagram.com/congressi_internazionali/
        </a>
      </div>
      <PDFDownloadLink fileName={language==="English" ? `QUOTE CI-${details?.orderNumber}`:`PREVENTIVO CI-${details?.orderNumber}`}  style={{
              position: 'absolute',
              bottom: '4%',
              borderRadius:"5px",
              right: '10%',
              opacity: '1',
              zIndex: 99,
              background: '#5D00A2',
              color: 'white',
            }} document={<PDFDoc language={language} details={details} />}>
          {({ loading }) => (loading ? <CircularProgress

            className={styles.loadingQuoteBtn} color="secondary" /> : <Button
            sx={{
              background: '#5D00A2',
              color: 'white',
            }}
              className={styles.quotebutton} size="medium" >
            Download Quote as Pdf
          </Button>)}
        </PDFDownloadLink>
      {/* {!pdfLoading ? (
        <Button
        sx={{
          position: 'absolute',
          bottom: '3%',
          right: '2%',
          opacity: '1',
          zIndex: 99,
          background: '#5D00A2',
          color: 'white',
        }}
        className={styles.button} size="medium" onClick={handlePdf}>
          Download as Pdf
        </Button>
      ) : (
        <CircularProgress
        sx={{
          position: 'absolute',
          bottom: '3%',
          right: '2%',
          opacity: '1',
          zIndex: 99,
        }}
        className={styles.loadingBtn} color="secondary" />
      )} */}
    </div>
  );
}

import OurClients from './components/OurClients';
import React, { useEffect, useState } from 'react';
import TablesItems from './components/TablesItems';
import Testimonials from './components/Testimonials';
import Interpreting from './components/Interpreting';
import AboutUs2 from './components/AboutUs2';
import AboutUs1 from './components/AboutUs1';
import Introduction from './components/Introduction';
import Home from './components/Home';
import Contact from './components/Contact';
import Quality from './components/Quality';
 import Packages from './components/Packages';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import BottomNavigation from '@mui/material/BottomNavigation';
import { RiFileDownloadFill } from 'react-icons/ri';
import { FaFileSignature } from 'react-icons/fa';
import Paper from '@mui/material/Paper';
import './index.css';
import { FaRegHandPointLeft } from 'react-icons/fa';
import {BsVectorPen, BsFileEarmarkCheckFill} from 'react-icons/bs';
import { Alert, Box, Button, CircularProgress, Typography } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDoc from './PDFDoc/PDFDoc';
import { useSnackbar } from 'notistack';
import { fCurrency } from 'src/utils/formatNumber';
import { useDispatch, useSelector } from 'react-redux';
import { addBtnDisplay, addDefaultItemsTranslation, addItems2Translation, addName, addServices2Translation } from 'src/redux/slices/proposal';
import { useMutation } from '@apollo/client';
import { QUOTE_ACCEPT_TRANSLATION } from 'src/graphQL/mutations';
import swal from 'sweetalert';
import SectorClients from './components/SectorClients';
import PackagesTablesItems from './components/PackagesTableItems';
import TermsAndCondition from './components/TermsAndCondition';


function subtractDays (dateStr, days) {
  console.log("Option", days)
  var date = new Date(dateStr);
  if(days === 2){
    date.setDate(date.getDate() + 1);
  }else if(days === 1){
    date.setDate(date.getDate());
  }else if(days === 0){
    date.setDate(date.getDate() - 1 );

  }else{
    date.setDate(date.getDate());
  }
  return date;
}


export default function TranslationClientView({refetch}) {
  const [open, setOpen] = React.useState(false);
  const targetRef = React.useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const [QuoteAccept] = useMutation(QUOTE_ACCEPT_TRANSLATION)

  const {details,orgPkgsOption,selectedPkgDeliveryDate, name, option, language,packagesNetTotals,selectedPackageOption, packagesItemsTranslation, packagesItem, quoteId, ip, addOns, isBtn, items1Translation, service1Translation, items2Translation, service2Translation, packagesPrices} = useSelector(state => state.proposal)
  const dispatch = useDispatch()
  const {
   
    grandTotal,
    grandTotalOptions,
    additionalService,
    translationItem2,
    additionalService2,
    translationItem,
  } = details ? details : {};
  const handleAcceptance = async () => {
    let selectedPackageDetails;
    if(addOns?.packages){
      
      const {item: translationItem, service: additionalService, subtotal, netTotal, totalDiscount, taxes, grandTotal } = packagesItem 
      selectedPackageDetails = {translationItem, additionalService, subtotal, netTotal: String(netTotal), totalDiscount, 
        taxes, optionSelected:selectedPackageOption,orgPkgsOption, grandTotal, deliveryDateTime:new Date(selectedPkgDeliveryDate)}
    }
    
    await QuoteAccept({variables:{selectedPackageDetails, quoteAcceptId:quoteId, name, ip, optionSelected:option === 0 ? 0 : option-1}})
    .then((res)=>{
        console.log(res);
        if(res.data.quoteTranslationAccept){
          if(language === "English"){
          swal("Quote accepted!", "Thank you for accepting the quote!", "success");
          }else{
            swal("Preventivo Accettato!", "Grazie per aver accettato il preventivo!", "success");
          }
            dispatch(addBtnDisplay(false))
            if(addOns?.packages){
              refetch()
            }
        }
    })
    .catch((err)=>{
      swal("", `${err?.message}`, "error");
    })

}
 const handleClickOpen = () => {
  if(option > 0 ){
    setOpen(true);
    
  }
  else if(option === 0 && translationItem2?.length > 0){
    enqueueSnackbar(`${language === "English" ? "Please Choose any Option": "Si prega di selezionare l'opzione desiderata"}`, { variant: 'error' });
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }else{
    setOpen(true);
    
  }
};
  const handleClickClose = () => {
    setOpen(false);
    handleAcceptance();
  };
  const handleClose = () => {
    setOpen(false);
  };
  useEffect(()=>{
   async function fetchTranslation(){
    if(language === "English" && details){
      const body1 = [...translationItem, ...additionalService]?.map((item)=>item.service?.Description['italian']);
      const requestData1 = body1?.map((text)=>{
        return {text}
      });
    const headers = {
        'Ocp-Apim-Subscription-Key': `${process.env.REACT_APP_TEXT_TRANSLATION_KEY}`,
        'Ocp-Apim-Subscription-Region': "global",
        'Content-Type': 'application/json',
    };
   await fetch(`https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=it&to=en`, {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(requestData1),
    })
    .then(response => response.json())
    .then(data => {
      // const results = data;
      const textArray = data?.map(item => item.translations[0].text);
      dispatch(addDefaultItemsTranslation({items:textArray?.slice(0, translationItem?.length), services:textArray?.slice(translationItem?.length)}))
    })
    .catch(error => {
      console.error('Error:', error);
    });
    
    if(translationItem2?.length > 0){
     
    const optionItems = [];
    
    for(var i = 0; i < translationItem2?.length; i++){
      const body2 = [...translationItem2[i]]?.map(item =>item.service.Description["italian"])
      const requestData2 = body2?.map((text)=>{
        return {text}
      });
     await fetch(`https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=it&to=en`, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestData2),
      })
      .then(response => response.json())
      .then(data => {
        // const results = data;
        const textArray = data?.map(item => item.translations[0].text);
        optionItems?.push(textArray);
      })
      .catch(error => {
        console.error('Error:', error);
      });
    }
    dispatch(addItems2Translation(optionItems))
    //  setItems2Translation(optionItems)
    }
    if(additionalService2?.length > 0){
     
      const optionServices = [];
      for(var i = 0; i < additionalService2?.length; i++){
        const body2 = [...additionalService2[i]]?.map(item =>item.service.Description["italian"])
        const requestData2 = body2?.map((text)=>{
          return {text}
        });
       await fetch(`https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&from=it&to=en`, {
          method: 'POST',
          headers: headers,
          body: JSON.stringify(requestData2),
        })
        .then(response => response.json())
        .then(data => {
          // const results = data;
          const textArray = data?.map(item => item.translations[0].text);
          optionServices?.push(textArray);
        })
        .catch(error => {
          console.error('Error:', error);
        });
      }
      dispatch(addServices2Translation(optionServices))
      //  setService2Translation(optionServices)
      }
    }
   }
   
    fetchTranslation()
   },[details])
  return (
    <>
      <div id="translation-proposal" style={{marginInline: 'auto', boxShadow: '0px 0px 5px 3px rgb(0 0 0 / 40%)' }}>

        {addOns?.cover && (
          <div id="my-node">
            <Home  />
          </div>
        )}
        {addOns?.companyInfo && (
          <div className='desktop-pages'>
            <div>
              <Introduction  />
            </div>
          </div>
        )}
        {addOns?.sector && (
          <div className='desktop-pages'>
          <SectorClients/>
          </div>
        )}
        {addOns?.companyInfo && (
          <div className='desktop-pages'>
            <div>
              <AboutUs1  />
            </div>
            <div>
              <AboutUs2 />
            </div>
            <div>
              <Interpreting />
            </div>
            <div>
              <Quality />
            </div>
            
            <div>
              <OurClients />
            </div>
            <div id="testimonials">
              <Testimonials />
            </div>
          </div>
        )}
        
        {addOns?.quoteInfo && (
          <>
          {addOns.packages && (
              <div>
                <Packages   />
              </div>
            )}
            <div id="tables">
              {addOns.packages ? <PackagesTablesItems/>  : <TablesItems/>}
            </div>

            <div id="terms">
              <TermsAndCondition
                handleAcceptance={handleAcceptance}
              />
            </div>
            
          </>
        )}
        {(addOns?.companyInfo || addOns?.quoteInfo || addOns?.cover) && (
          <div className='desktop-pages' id="contact">
            <Contact />
          </div>
        )}
      </div>
      {addOns && addOns?.quoteInfo &&<Paper
        id="bottom_bar"
        sx={{ position: 'fixed', height: '90px', zIndex: 9999999999, bottom: 0, left: 0, right: 0 }}
        elevation={3}
      >
        <BottomNavigation
        id='bottom_nav'
          sx={{ justifyContent: 'end', margin: '10px' }}
          showLabels
          // value={value}
          onChange={(event, newValue) => {
            // setValue(newValue);
          }}
        >
          <Box>
          {((option > 0 && grandTotalOptions?.length > 0) || grandTotalOptions?.length === 0) && (
            <Typography variant="h5" sx={{ color: 'white', marginTop: '3px', marginRight: '20px' }}>
        {language === "English" ? 'Total Estimate' : 'Totale Preventivo' }
            </Typography>
          )}
          {(grandTotalOptions?.length === 0 || (grandTotalOptions?.length > 0 && option === 1)) && (
            <Typography variant="h3" sx={{ color: 'white', marginTop: '1px', marginRight: '20px' }}>
               &nbsp;{addOns?.packages ? fCurrency(packagesItem?.grandTotal || 0) : fCurrency(grandTotal)} <span style={{fontSize:'11px',verticalAlign:'middle'}}>
               {language === "English" ? '(VAT included)': '(IVA Inclusa)'}
               </span>
            </Typography>
          )}
           {(grandTotalOptions?.length > 0 && option > 1) && (
            <Typography variant="h3" sx={{ color: 'white', marginTop: '1px', marginRight: '20px' }}>
               &nbsp;{ fCurrency(grandTotalOptions[option-2])}<span style={{fontSize:'11px',verticalAlign:'middle'}}>
               {language === "English" ? '(VAT included)': '(IVA Inclusa)'}
               </span>
            </Typography>
          )}

          {(grandTotalOptions?.length > 0 && option === 0) && (
<Typography variant="h5" sx={{ color: 'white', paddingTop: '20px', marginRight: '20px' }}>
{language==="English" ? `Select an Option`:`Seleziona un'opzione`}
            </Typography>
          )}
          </Box>
          <PDFDownloadLink className='pdf-btn-desktop'  fileName={language==="English" ? `QUOTE LY-${details?.orderNumber}`:`PREVENTIVO LY-${details?.orderNumber}`}  style={{fontSize:"17px", pointerEvents:"cursor", borderRadius:"5px", backgroundColor: 'white', color: 'grey', width: '210px', height: '70px', marginRight: '20px' }}
   document={<PDFDoc language={language} details={details} name={name} isBtn={isBtn} items1Translation={items1Translation}
   service1Translation={service1Translation}
   items2Translation={items2Translation}
   service2Translation={service2Translation}
   packagesNetTotals={packagesNetTotals}
   packagesItem={packagesItem || {}}
   packagesItemsTranslation={packagesItemsTranslation}
   packagesPrices={packagesPrices}
   selectedPackageOption={selectedPackageOption}
   orgPkgsOption={orgPkgsOption}
   />}>
                    {({ loading }) => (
            <>
            {loading ? <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: "10px" }}>
        <CircularProgress />
      </Box> : 
       <Button  disabled={loading && true} sx={{ fontSize: "unset", width:'210px'}}>
              <RiFileDownloadFill style={{ color:`${loading ? 'grey': '#ff0c00b3'}`, marginRight: '2px', height: '35px', width: '45px' }} />{' '}
              {language === "English" ? 'DOWNLOAD PDF' : ' SCARICA PDF'}
            </Button> 
            }
            </>  
          )}
        </PDFDownloadLink> 
        <PDFDownloadLink className='pdf-btn-mbl' fileName={language==="English" ? `QUOTE LY-${details?.orderNumber}`:`PREVENTIVO LY-${details?.orderNumber}`}  style={{fontSize:"17px", pointerEvents:"cursor", backgroundColor: 'white', color: 'grey',borderRadius:'50%', width: '60px', height: '60px', marginLeft: '20px' }}
   document={<PDFDoc language={language} details={details} name={name} isBtn={isBtn} items1Translation={items1Translation}
   service1Translation={service1Translation}
   items2Translation={items2Translation}
   service2Translation={service2Translation}
   packagesNetTotals={packagesNetTotals}
   packagesItemsTranslation={packagesItemsTranslation}
   packagesItem={packagesItem}
   packagesPrices={packagesPrices}
   selectedPackageOption={selectedPackageOption}
   orgPkgsOption={orgPkgsOption}
   />}>
                    {({ loading }) => (
            <>
            {loading ? <Box className='download' sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress />
      </Box> : 
            <img className='download'  alt='download' src={'/assets/icons/download-pdf.svg'}/>
            }
            </>  
          )}
        </PDFDownloadLink> 
          {(details?.status === "Quote-Won" || details?.status === "Sent To PM" || !isBtn) ? 
        <>
          <Button className='pdf-btn-desktop' disabled={true}
            sx={{"&:disabled":{fontSize:"18px", backgroundColor: 'white', color: 'rgb(61 191 83 / 97%)', width: '150px', height: '70px' }}}
          >
            <BsFileEarmarkCheckFill style={{ color: 'rgb(61 191 83 / 97%)', marginRight: '2px', height: '30px', width: '40px' }} /> 
            {language === "English" ? 'SIGNED' : 'FIRMATO' }
          </Button> 
          <Button className='pdf-btn-mbl' disabled={true}
            sx={{"&:disabled":{fontSize:"18px", backgroundColor: 'white', color: 'rgb(61 191 83 / 97%)',  width: '60px', height: '60px', borderRadius:'50%', marginLeft:'15px' }}}
          >
          <BsFileEarmarkCheckFill className='pdf-btn-mbl' style={{ color: 'rgb(61 191 83 / 97%)', height: '60px', width: '40px',}} /> 
          </Button>
        </>
      
                    : 
                   <>
                    <Button
                    className='pdf-btn-desktop'
                    sx={{fontSize:"17px", backgroundColor: 'white', color: `${(option === 0 && translationItem2?.length > 0) ? "grey":'#4BD5F1'}`, width: '150px', height: '70px' }}
                    onClick={handleClickOpen}
                  >
                    <FaFileSignature style={{ color: '#4BD5F1', marginRight: '2px', height: '30px', width: '40px' }} /> 
                    {language === "English" ? 'SIGN' : 'FIRMA' }

                  </Button>
                  <Button
                    className='pdf-btn-mbl'
                    sx={{fontSize:"17px", backgroundColor: 'white', color: `${(option === 0 && translationItem2?.length > 0) ? "grey":'#4BD5F1'}`, width: '60px', height: '60px', borderRadius:'50%', marginLeft:'15px' }}
                    onClick={handleClickOpen}
                  >
                              <img className='download'  alt='download' src={'/assets/icons/sign-pen.svg'}/>


                  </Button>
                   </>
                   
                    }

        </BottomNavigation>
      </Paper>
}
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          sx={{
            '& .css-y4brsv-MuiPaper-root-MuiDialog-paper.MuiPaper-rounded': { borderRadius: '5px' },
            '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '500px', textAlign: 'center', borderRadius: '5px' },
            borderRadius: '5px',
          }}
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
              color: 'rgba(0,0,0,.65)',
              fontWeight: '600',
              fontSize: '25px',
            }}
          >
             {language === "English" ? 'Accept the quote' : 'Accetta il preventivo' }
          </DialogTitle>
          <DialogContent
            id="dialog_content"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
            <span style={{display:"inline-flex", marginTop:"30px"}}>
          <BsVectorPen style={{fontSize:"25px", marginTop:"5px"}} />  <input placeholder={`${language === "English" ? "Sign here":"Firma qui"}`} value={name} onChange={(e) => dispatch(addName(e.target.value))} />{' '}
          <FaRegHandPointLeft style={{fontSize:"25px", marginTop:"5px"}} />
           
            </span>
            <DialogContentText id="alert-dialog-description">{name}</DialogContentText>
          </DialogContent>
          <DialogActions id="alert-dialog-btn">
            <Button
          
              sx={{
                background: '#7cd1f9',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
                fontWeight: '600',
                fontSize: '14px',
                padding: '10px 10px',
                cursor: 'pointer',
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
                  color: 'white',
                  background: 'grey',
                },
              }}
              disabled={!name && true}
              onClick={handleClickClose}
              autoFocus
            >
                {language === "English" ? 'Confirm Quote' : 'Conferma preventivo' }
            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
}

import { useSelector } from 'react-redux';
import styles from '../styles/Introduction.module.css';

const Introduction = () => {

  const {details, language} = useSelector(state => state.proposal)

  const { bookedBy, accountManager,brand, companyNameAndId, addOns, clients } = details;
  if (language === 'English') {
    return (
      <>
        <div className={styles.desktop6}>
          {/* <div className={styles.div}>02</div> */}
          {brand && brand?.rafiky ? 
          <>
                    <div className={styles.desktop6Item} />
                    <div className={styles.introductionLetter} style={{fontWeight:"bold"}}>INTRODUCTORY LETTER</div>
          </>
          : 
          <>
                    <div className={styles.desktop6Item1} />
                    <div className={styles.introductionLetter1} style={{fontWeight:"bold"}}>INTRODUCTORY LETTER</div>
          </>          }
          <div className={styles.text3543Parent}>
          {brand && brand?.rafiky ? <img  style={{marginTop:"-25px"}}
        alt=""
        src="/rafiky/logo.webp"
      /> :  <img alt="" src="/assets/desktop6/group-1000001719.svg" />}           </div> 
         {!addOns?.friendly ? <div className={styles.dearTestThanksContainer}>
            <p className={styles.dearTest}>
              <span>Dear {bookedBy ? bookedBy?.firstName : '__'},</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
              Thank you for choosing to collaborate with {brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"}. To get more information about our reality, please browse the pages of this dedicated presentation to your company.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>As requested, please find our quote below.</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            
            <p className={styles.dearTest}>

              <span>{`Our considerable experience in the sector, combined with flexibility and precision, makes ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} the best choice for companies of the caliber of `}</span>
              <b className={styles.skyGoogleBmw}>
              {addOns?.sector ? clients?.map((client)=> client?.name).join(", "): 
             `Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda, Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini
             `
             }         
             </b>
             </p>
            <p className={styles.dearTest}>
              <span>
                For {brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} each Client is unique and special, and we would particularly like to get
                started a long and fruitful collaboration with {companyNameAndId?.name}.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
                Quality has always been our strong point: for this reason we only select interpreters with at least five
                years of work experience, members of the major linguist associations (AITI, AssoInterpreti, AIIC, etc.),
                specialized in your sector.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>We will contact you by phone to agree on the next steps together.</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.bestRegards}>
              <span>Best regards,</span>
            </p>
            
          </div> : <div className={styles.dearTestThanksContainer}>
            <p className={styles.dearTest}>
              <span>Dear {bookedBy ? bookedBy?.firstName : '__'},</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
              We thank you for choosing to collaborate with {brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"}. For more information about our company, please browse the pages of this presentation dedicated to your company.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>As requested, we will send you our quote below.</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            
            <p className={styles.dearTest}>

              <span>
              Our considerable experience in the sector, combined with flexibility and precision, makes {brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} the best choice for companies of the caliber of{' '}
              </span>
              <b className={styles.skyGoogleBmw}>
              {addOns?.sector ? clients?.map((client)=> client?.name).join(", "): 
             `Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda, Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini
             `
             }.                  
              </b>
            </p>
            <p className={styles.dearTest}>
              <span>
              For {brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} each Client is unique and special, and we would particularly like to get started a long and fruitful collaboration with {companyNameAndId?.name}.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
              Quality has always been our strong point: for this reason, we only select interpreters with at least five years of work experience, members of the major linguist associations (AITI, AssoInterpreti, AIIC, etc.), specialized in your sector.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>We will contact you by phone to agree on the next steps together.</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.bestRegards}>
              <span>See you soon,</span>
            </p>
          </div>}

          <div className={brand && brand?.rafiky ? styles.desktop6Child1: styles.desktop6Child}>
            <div id="footer_AM" className={styles.ellipseParent}>
            {accountManager && accountManager.name === "Giuseppe Gagliano" && <img  
                id="avatar"
                className={styles.frameChild}
                alt=""
                src="/assets/desktop7/GiuseppeGagliano.webp"
              />
              }
              {accountManager && accountManager.name === 'Alessia Tortorici' && (

<img  
              id="avatar"
              className={styles.frameChild}
              alt="" src="/assets/desktop7/AlessiaTortorici.webp" />

)}
              {accountManager && accountManager.name === "Chiara Moschitta" && <img  
                id="avatar"
                className={styles.frameChild}
                alt=""
                src="/assets/desktop7/ChiaraMoschitta.webp"
              />
              } 
              {accountManager && accountManager.name === 'Giulia Caravasso' && (
                <img   id="avatar" className={styles.frameChild} alt="" src="/assets/desktop7/GiuliaCaravasso.webp" />
              )}
              <img className={styles.sign1Icon} alt="" src="/assets/desktop6/sign-1@2x.png" />
              <b className={styles.missFedericaCaramella}>{accountManager ? accountManager.name : '__'}</b>
              <i className={styles.accountManager}>{accountManager?.jobTitle} 
              {brand && brand?.rafiky ? " Rafiky" : 
                " Congressi Internazionali"}
              </i>
              <div className={styles.languagesParent}>
                <i className={styles.accountManager}>Languages:</i>
                <div className={styles.chinaParent}>
                  {accountManager &&
                    accountManager?.language &&
                    accountManager?.language?.map((language) => {
                      if (language.name === 'Chinese') {
                        return (
                          <div className={styles.china}>
                            <img className={styles.artworkIcon} alt="" src="/assets/desktop6/artwork.svg" />
                          </div>
                        );
                      } else if (language.name === 'Italian') {
                        return (
                          <div className={styles.china}>
                            <img className={styles.artworkIcon} alt="" src="/assets/desktop6/artwork1.svg" />
                          </div>
                        );
                      } else if (language.name === 'English') {
                        return (
                          <div className={styles.china}>
                            <img className={styles.artworkIcon} alt="" src="/assets/desktop6/artwork2.svg" />
                          </div>
                        );
                      } else if (language.name === 'Spanish') {
                        return (
                          <div className={styles.china}>
                            <img
                              className={styles.artworkIcon}
                              style={{ width: '30px' }}
                              alt=""
                              src="/assets/desktop6/artwork.png"
                            />
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className={styles.desktop6}>
          {/* <div className={styles.desktop6Item} /> */}
          {/* <div className={styles.div}>02</div> */}
          {/* <div className={styles.introductionLetter} style={{fontWeight:"bold"}}>Lettera Introduttiva</div> */}
          {brand && brand?.rafiky ? 
          <>
                    <div className={styles.desktop6Item} />
                    <div className={styles.introductionLetter} style={{fontWeight:"bold"}}>Lettera Introduttiva</div>
          </>
          : 
          <>
                    <div className={styles.desktop6Item1} />
                    <div className={styles.introductionLetter1} style={{fontWeight:"bold"}}>Lettera Introduttiva</div>
          </>          }
          <div className={styles.text3543Parent}>
          {brand && brand?.rafiky ? <img  style={{marginTop:"-25px"}}
        alt=""
        src="/rafiky/logo.webp"
      /> :  <img alt="" src="/assets/desktop6/group-1000001719.svg" />}           </div>
         {!addOns?.friendly ? <div className={styles.dearTestThanksContainer}>
            <p className={styles.dearTest}>
              <span>Gentile {bookedBy ? bookedBy?.firstName : '__'},</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
                La ringraziamo per aver scelto di collaborare con {brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"}. Per avere maggiori
                informazioni sulla nostra realtà, La preghiamo di sfogliare le pagine di questa presentazione dedicata
                alla vostra azienda.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>Come richiesto, Le inviamo di seguito il nostro preventivo.</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            
                       <p className={styles.dearTest}>

<span>{`La nostra notevole esperienza nel settore, unita a flessibilità e precisione, rende ${brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} la scelta migliore per aziende del calibro di `}{' '}</span>
<b className={styles.skyGoogleBmw}>
{addOns?.sector ? clients?.map((client)=> client?.name).join(", "): 
             `Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda, Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini
             `
             }.
            </b>
             </p>
            <p className={styles.dearTest}>
              <span>
                Per {brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} ogni Cliente è unico e speciale, e ci terremmo particolarmente ad iniziare
                una lunga e proficua collaborazione con {companyNameAndId?.name}.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
                La qualità è da sempre il nostro punto di forza: per questo selezioniamo soltanto interpreti con almeno
                cinque anni di esperienza lavorativa, iscritti alle maggiori associazioni di categoria (AITI,
                AssoInterpreti, AIIC, ecc.), specializzati nel Vostro settore.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>Vi contatteremo telefonicamente per concordare insieme i prossimi step.</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.bestRegards}>
              <span>Cordiali saluti,</span>
            </p>
          </div> : <div className={styles.dearTestThanksContainer}>
            <p className={styles.dearTest}>
              <span>Gentile {bookedBy ? bookedBy?.firstName : '__'},</span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
              Ti ringraziamo per aver scelto di collaborare con {brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"}. Per avere maggiori informazioni sulla nostra realtà, ti preghiamo di sfogliare le pagine di questa presentazione dedicata alla vostra azienda.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>Come richiesto, ti inviamo di seguito il nostro preventivo. </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            
                       <p className={styles.dearTest}>

<span>
La nostra notevole esperienza nel settore, unita a flessibilità e precisione, rende {brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} la scelta migliore per aziende del calibro di {' '}
</span>
<b className={styles.skyGoogleBmw}>
{addOns?.sector ? clients?.map((client)=> client?.name).join(", "): 
             `Sky, Google, Ministero dell'Interno, Telepass, Ferrari, Mercedes-Benz, Honda, Alfasigma, Takeda, Clarins, MAC Cosmetics, Unicredit, Banca d'Italia, Gucci, Dior, Gruppo Angelini
             `
             }.
             </b>
            </p>
            <p className={styles.dearTest}>
              <span>
              Per {brand && brand?.rafiky ? "Rafiky":"Congressi Internazionali"} ogni Cliente è unico e speciale, e ci terremmo particolarmente ad iniziare una lunga e proficua collaborazione con {companyNameAndId?.name}.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
              La qualità è da sempre il nostro punto di forza: per questo selezioniamo soltanto interpreti con almeno cinque anni di esperienza lavorativa, iscritti alle maggiori associazioni di categoria (AITI, AssoInterpreti, AIIC, ecc.), specializzati nel Vostro settore.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.dearTest}>
              <span>
              Ti contatteremo telefonicamente per concordare insieme i prossimi step.
              </span>
            </p>
            <p className={styles.dearTest}>
              <span>&nbsp;</span>
            </p>
            <p className={styles.bestRegards}>
              <span>A presto,</span>
            </p>
          </div> }

          <div className={brand && brand?.rafiky ? styles.desktop6Child1: styles.desktop6Child}>
            <div id="footer_AM" className={styles.ellipseParent}>
            {accountManager && accountManager.name === "Giuseppe Gagliano" && <img  
                id="avatar"
                className={styles.frameChild}
                alt=""
                src="/assets/desktop7/GiuseppeGagliano.webp"
              />
              }
              {accountManager && accountManager.name === 'Alessia Tortorici' && (

<img  
              id="avatar"
              className={styles.frameChild}
              alt="" src="/assets/desktop7/AlessiaTortorici.webp" />

)}
              {accountManager && accountManager.name === "Chiara Moschitta" && <img  
                id="avatar"
                className={styles.frameChild}
                alt=""
                src="/assets/desktop7/ChiaraMoschitta.webp"
              />
              } 
              {accountManager && accountManager.name === 'Giulia Caravasso' && (
                <img   id="avatar" className={styles.frameChild} alt="" src="/assets/desktop7/GiuliaCaravasso.webp" />
              )}
              <img className={styles.sign1Icon} alt="" src="/assets/desktop6/sign-1@2x.png" />
              <b className={styles.missFedericaCaramella}>{accountManager ? accountManager.name : '__'}</b>
              <i className={styles.accountManager}>{accountManager?.jobTitle} 
              {brand && brand?.rafiky ? " Rafiky" : 
                " Congressi Internazionali"}
              </i>
              <div className={styles.languagesParent}>
                <i className={styles.accountManager}>Languages:</i>
                <div className={styles.chinaParent}>
                  {accountManager &&
                    accountManager?.language &&
                    accountManager?.language?.map((language) => {
                      if (language.name === 'Chinese') {
                        return (
                          <div className={styles.china}>
                            <img className={styles.artworkIcon} alt="" src="/assets/desktop6/artwork.svg" />
                          </div>
                        );
                      } else if (language.name === 'Italian') {
                        return (
                          <div className={styles.china}>
                            <img className={styles.artworkIcon} alt="" src="/assets/desktop6/artwork1.svg" />
                          </div>
                        );
                      } else if (language.name === 'English') {
                        return (
                          <div className={styles.china}>
                            <img className={styles.artworkIcon} alt="" src="/assets/desktop6/artwork2.svg" />
                          </div>
                        );
                      } else if (language.name === 'Spanish') {
                        return (
                          <div className={styles.china}>
                            <img
                              className={styles.artworkIcon}
                              style={{ width: '30px' }}
                              alt=""
                              src="/assets/desktop6/artwork.png"
                            />
                          </div>
                        );
                      }
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default Introduction;

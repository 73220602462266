import { Box, Table, TableBody, TableCell, TableHead, Typography } from '@mui/material'
import React from 'react'
import { useSelector } from 'react-redux'
import { TableRow } from 'semantic-ui-react'

function Table1() {

    const {language, details} = useSelector((state)=>state.proposal);

    const {accountManager, bookedBy, companyNameAndId} = details;
  return (
    <Table sx={{ width: '100%' }}>
                  <TableHead
                    sx={{
                      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
                      '& th': { backgroundColor: '#AA32E1' },
                      '& .css-7amdnm-MuiTableCell-root:first-of-type': {
                        boxShadow: 'none',
                      },
                      '& .css-7amdnm-MuiTableCell-root:last-of-type': {
                        boxShadow: 'none',
                      },
                    }}
                  >
                    <TableRow>
                      <TableCell
                        sx={{
                          backgroundColor: '#AA32E1',
                          width: '50%',
                          color: 'white',
                          fontSize: 'large',
                          boxShadow: 'none',
                        }}
                        align="left"
                      >
                        {language === 'English' ? 'Customer Billing Address' : 'Dati Fatturazione Cliente'}
                      </TableCell>
                      <TableCell
                        sx={{
                          backgroundColor: '#AA32E1',
                          width: '50%',
                          color: 'white',
                          fontSize: 'large',
                          boxShadow: 'none',
                        }}
                        align="left"
                      >
                        {accountManager?.jobTitle}{' '}
                      </TableCell>
                    </TableRow>
                  </TableHead>

                  <TableBody>
                    <TableRow>
                      <TableCell
                        sx={{
                          '.css-4dwhzq-MuiTableCell-root': {
                            marginBottom: 'none',
                          },
                          borderBottom: 'none',
                          maxWidth: '200px',
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: 'large',
                        }}
                        align="left"
                      >
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                          >
                            {companyNameAndId?.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                          >
                            {bookedBy?.firstName} {bookedBy?.lastName}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                          >
                            <b> {language === 'English' ? 'Address: ' : 'Indirizzo:'} </b>{' '}
                            {companyNameAndId?.address1 && (
                              <>
                                {companyNameAndId?.address1}
                                <br />
                              </>
                            )}
                            {companyNameAndId?.address2 && (
                              <>
                                &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                                {companyNameAndId?.address2}
                                <br />
                              </>
                            )}
                            &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                            {companyNameAndId?.postcode} {companyNameAndId?.city}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                          >
                            <b>P. IVA: </b> {companyNameAndId?.VAT}
                          </Typography>
                        </Box>
                      </TableCell>
                      <TableCell
                        sx={{
                          '.css-4dwhzq-MuiTableCell-root': {
                            marginBottom: 'none',
                          },
                          borderBottom: 'none',
                          maxWidth: '200px',
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: 'large',
                        }}
                        align="left"
                      >
                        <Box>
                          <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                          >
                            {accountManager?.name}
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                          >
                            E-mail: info@lingoyou.com
                          </Typography>
                          <Typography
                            variant="body2"
                            sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                          >
                            Telefono:(+39) <b>0282957101</b>
                          </Typography>
                        </Box>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                </Table>
  )
}

export default Table1
import { Grid } from "@mui/material";
import styles from "../styles/Interpreting.module.css";
import { useSelector } from "react-redux";

const Interpreting = () => {

  const {language} = useSelector((state)=>state.proposal);

  if (language === 'English') {
    return (<div className={styles.desktop9}>
      <div className={styles.desktop9Child} />
      {/* <div className={styles.div}>05</div> */}
      <div className={styles.interpreting}>COMPANY VALUES</div>
      <div className={styles.text3543Parent}>
        <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{" "}
      </div>
      <div className={styles.atCongressiInternazionaliContainer}>
        <p className={styles.atCongressiInternazionali}>
        At LingoYou, we strongly believe in our corporate culture. Our values make us unique: they guide our behaviour and our choices and rank our priorities. We are therefore committed to preserving and safeguarding by all means the principles and ideals on which we base our strategies, guaranteeing professionalism, quality of services and attention to details to all of our customers
        </p>
        <Grid
          container
          spacing={3}
          sx={{ marginTop: "20px", width: "95%", margin: "5px", placeContent: "center" }}
          className={styles.icons}
        >
          <Grid item xs={12} lg={1}>

          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/usercheckmark.png" />
              <p>Responsibility</p>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/star.png" />
              <p>Innovation </p>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/settings.png" />
              <p>Accessibility </p>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/shield.png" />
              <p>Sustainability  </p>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/windows.png" />
              <p>Transparency</p>
            </div>
          </Grid>
          <Grid item xs={12} lg={1}>

          </Grid>
        </Grid>
        <div className={styles.divider}>WHY US?
        </div>
        <Grid
          container
          spacing={3}
          sx={{ marginLeft: "3px", marginTop: "20px" }}
          className={styles.icons1}
        >

          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/building.png" />
              <p>A 100% Italian company with global presence in over 90 countries</p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/Certificate.png" />
              <p>ISO 9001 Quality Certification</p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/message.png" />
              <p>200 Languages </p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/hands.png" />
              <p>The most advanced technology in the field of Remote Interpreting and Client Portal
              </p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/cash.png" />
              <p>3 Prices – Translations for every budget
              </p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/clock.png" />
              <p>International Account Managers available 24/7
              </p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/discount-shape.png" />
              <p>20% discount for new customers</p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/designtools.png" />
              <p>Internal Team of Graphic Designers
              </p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/code-browser.png" />
              <p>Internal Team of Software Developers for CMS integrated translations
              </p>
            </div>
          </Grid>
          <Grid item xs={10} lg={2.4} sx={{ textAlignLast: "center", marginInline: "auto" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/flash.png" />
              <p>Fast service - Machine and Neuronal Translation
              </p>
            </div>
          </Grid>
        </Grid>
 
      </div>


    </div>)
  }
  return (
    <div className={styles.desktop9}>
      <div className={styles.desktop9Child} />
      {/* <div className={styles.div}>05</div> */}
      <div className={styles.interpreting}>VALORI DELL'AZIENDA</div>
      <div className={styles.text3543Parent}>
        <img   alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{" "}
      </div>
      <div className={styles.atCongressiInternazionaliContainer}>
        <p className={styles.atCongressiInternazionali}>
          Noi di LingoYou crediamo fermamente nella nostra cultura aziendale.
          Sono i nostri valori a renderci unici: guidano i nostri comportamenti,
          orientano le nostre scelte e definiscono le nostre priorità. Ci
          impegniamo pertanto a preservare e a salvaguardare con ogni mezzo i
          principi e gli ideali su cui fondiamo le nostre strategie, garantendo
          ai nostri Clienti professionalità, qualità dei servizi e attenzione ai
          dettagli.
        </p>
        <Grid
          container
          spacing={3}
          sx={{ marginTop: "20px", width: "95%", margin: "5px", placeContent: "center" }}
          className={styles.icons}
        >
          <Grid item xs={12} lg={1}>
 
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/usercheckmark.png" />
              <p>Responsabilità</p>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/star.png" />
              <p>Innovazione </p>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/settings.png" />
              <p>Accessibilità </p>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/shield.png" />
              <p>Sostenibilità  </p>
            </div>
          </Grid>
          <Grid item xs={6} sm={4} lg={2}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/windows.png" />
              <p>Trasparenza</p>
            </div>
          </Grid>
          <Grid item xs={12} lg={1}>

          </Grid>
        </Grid>
        <div className={styles.divider}>PERCHE' NOI?</div>
        <Grid
          container
          spacing={3}
          sx={{ marginLeft: "3px", marginTop: "20px" }}
          className={styles.icons1}
        >

          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/building.png" />
              <p>Azienda 100% Italiana con presenza globale in + di 90 Paesi</p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/Certificate.png" />
              <p>Certificazione di Qualità ISO 9001</p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/message.png" />
              <p>200 Lingue </p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/hands.png" />
              <p>La tecnologia più avanzata nel settore Interpretariato da remoto e Portale Clienti</p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/cash.png" />
              <p>3 Prezzi – Traduzioni per ogni budget</p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/clock.png" />
              <p>Account Managers Internazionali disponibili 24/7</p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/discount-shape.png" />
              <p>20% di sconto per i nuovi clienti</p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/designtools.png" />
              <p>Team Interno di Graphic Designers</p>
            </div>
          </Grid>
          <Grid item xs={4} lg={2.4} sx={{ textAlignLast: "center" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/code-browser.png" />
              <p>Team Interno di software Developers per traduzioni integrate di CMS</p>
            </div>
          </Grid>
          <Grid item xs={10} lg={2.4} sx={{ textAlignLast: "center", marginInline: "auto" }}>
            <div className={styles.icon}>
              <img   src="/translationAssets/icons/flash.png" />
              <p>Servizio rapido - Traduzione Automatica e Neuronale</p>
            </div>
          </Grid>
        </Grid>
       
      </div>


    </div>
  );
};

export default Interpreting;

import React from 'react';
import { styles } from '../globalStyles';
import { Text, View } from '@react-pdf/renderer';
import { fCurrency } from 'src/utils/formatNumber';
import { languageMappings } from '../../languages';
import TableTotals from './TableTotals';

const TableItems = ({ language, details,   items1Translation,
  service1Translation,
   }) => {
 
  const {interpretationItem, additionalService } = details
  return (
    <>
      
        {interpretationItem && <View style={styles.tableItemsBody}>
          {interpretationItem.map((row, index) => (
            <View style={styles.tableItemsBodyItem} key={index}>
              <View style={styles.tableItemsItemLeft}>
                {row.sourceLanguage?.name ?<Text style={styles.bold}>{row.title} - {language === "English" ? row.sourceLanguage?.name : languageMappings[`${row.sourceLanguage?.name}`]} - 
                {row?.targetLanguage?.map((lang) => {
                    if(language === "English"){
                     return lang.name
                    }else{
                     return languageMappings[`${lang?.name}`]
                    }
                   
                    }).join(', ')}
                </Text> : <Text style={styles.bold}>{row.title}</Text>}
                <Text style={styles.bold}>{row.service.Service[language.toLowerCase()]}</Text>
                <Text style={styles.description}>{language === "English" ? `${items1Translation?.length > 0 ? items1Translation[index] : ''}`:
 row.service.Description[language.toLowerCase()]}</Text>
              </View>
              <View style={styles.tableItemsItemRight}>
                <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}><Text> &nbsp;{row.price}</Text></View>
                <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}><Text>{row.quantity}</Text></View>
                <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}><Text>{row.discount ? row.discount + '%' : ''}</Text></View>
                <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}>
                  {row?.discount ? <><Text style={{ borderBottom: "1px solid black" }}>
                     &nbsp;
                    {fCurrency(
                      Number(row?.price) * Number(row?.quantity) -
                      (Number(row?.price) * Number(row?.quantity) * Number(row.discount)) / 100
                    )}
                  </Text>
                    <Text style={styles.del}>  &nbsp;{fCurrency(Number(row?.price) * Number(row?.quantity))}</Text></> : <Text>&nbsp;{fCurrency(Number(row.price) * Number(row.quantity))}</Text>
                    }
                </View>
              </View>
            </View>
          ))}
        </View>}
        {additionalService && <View style={styles.tableItemsBody}>
          {additionalService.map((row, index) => (
            <View style={styles.tableItemsBodyItem} key={index}>
              <View style={styles.tableItemsItemLeft}>
              <Text style={styles.bold}>{row?.title}</Text>
                <Text style={styles.bold}>{row.service.Service[language.toLowerCase()]}</Text>
                <Text style={styles.description}>
                {language === "English" ? `${service1Translation?.length > 0 ? service1Translation[index] : ''}` :
                  row.service.Description[language.toLowerCase()]}
                
                </Text>
              </View>
              <View style={styles.tableItemsItemRight}>
                <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}><Text> &nbsp;{fCurrency(row.price)}</Text></View>
                <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}><Text>{row.quantity}</Text></View>
                <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}><Text>{row.discount ? row.discount + '%' : ''}</Text></View>
                <View style={{ ...styles.tableItemsItemCol, ...styles.celCenter }}>
                {row?.discount ? <><Text style={{ borderBottom: "1px solid black" }}>
                     &nbsp;
                    {fCurrency(
                      Number(row?.price) * Number(row?.quantity) -
                      (Number(row?.price) * Number(row?.quantity) * Number(row.discount)) / 100
                    )}
                  </Text>
                    <Text style={styles.del}> &nbsp;{fCurrency(Number(row?.price) * Number(row?.quantity))}</Text></> : <Text> &nbsp;{fCurrency(Number(row.price) * Number(row.quantity))}</Text>
                    }
                </View>
              </View>
            </View>
          ))}
        </View>}
      
    </>
  );
};

export default TableItems;

import React, { useState } from 'react';
import './index.css';
import WhyRafiky from 'src/sections/interpretClientOutput/rafiky/components/WhyRafiky';
import Process from 'src/sections/interpretClientOutput/rafiky/components/Process';
import Discount from 'src/sections/interpretClientOutput/rafiky/components/Discount';
import UseCases from 'src/sections/interpretClientOutput/rafiky/components/UseCases';
import Connect from 'src/sections/interpretClientOutput/rafiky/components/Connect';
import CustomerGuide from 'src/sections/interpretClientOutput/rafiky/components/CustomerGuide';
import CustomerGuide1 from 'src/sections/interpretClientOutput/rafiky/components/CustomerGuide1';
import OnSiteInterpreting from 'src/sections/interpretClientOutput/rafiky/components/OnSiteInterpreting';
import { BottomNavigation, Box, Button, CircularProgress, Paper } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDoc from './PDFDoc/PDFDoc';
import { RiFileDownloadFill } from 'react-icons/ri';
import WebConferencing from 'src/sections/interpretClientOutput/rafiky/components/WebConferencing';
import Introduction from './components/Introduction';
import Home from './components/Home';
import AboutUs2 from 'src/sections/interpretClientOutput/rafiky/components/AboutUs2';
import AboutUs1 from 'src/sections/interpretClientOutput/rafiky/components/AboutUs1';
import { useSelector } from 'react-redux';
import Contact from './components/ContactUs';
import OurClients from './components/OurClients';
import Testimonials from './components/Testimonials';

export default function RafikyClient({language}) {
  const {details } = useSelector(state => state.proposal)
 
  return (
    <>
      <div style={{ marginInline: 'auto', maxWidth: '1600px', boxShadow: '0px 0px 5px 3px rgb(0 0 0 / 40%)' }}>
            <Home  />
              <Introduction />
             
              <AboutUs1/>
              <AboutUs2/>
      <WhyRafiky language={language}/>
      <Process language={language}/>
         <UseCases language={language}/>
         <Connect language={language}/>
         {/* <CustomerGuide2 language={language}/> */}
         <CustomerGuide language={language}/>
         <CustomerGuide1 language={language}/>
         {/* <Technical language={language}/> */}
         <WebConferencing language={language}/>
         <OnSiteInterpreting language={language}/>
         <Discount language={language}/>
         <OurClients language={language}/>
         <Testimonials language={language}/>
         <Contact/>
      </div>
      <Paper
        sx={{ position: 'fixed',zIndex:99999999999, height: '90px', bottom: 0, left: 0, right: 0,
           backgroundColor: '#8316d3' }}
        elevation={3}
      >
        <BottomNavigation
          sx={{ backgroundColor: '#8316d3', justifyContent: 'end', margin: '10px' }}
          showLabels
          // value={value}
        >
          <PDFDownloadLink fileName={language==="English" ? `BROCHURE`: 'BROCHURE' } style={{fontSize:"17px", pointerEvents:"cursor", borderRadius:"5px", backgroundColor: 'white', color: 'grey', width: '210px', height: '70px', marginRight: '20px' }}
   document={<PDFDoc language={language} details={details}/>}>
                    {({ loading }) => (
            <>
            {loading ? <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: "14px" }}>
        <CircularProgress />
      </Box> :  <Button disabled={loading && true} sx={{ fontSize: "unset", marginTop: "14px" }}>
              <RiFileDownloadFill style={{ color:`${loading ? 'grey': '#ff0c00b3'}`, marginRight: '2px', height: '30px', width: '45px' }} />{' '}
              {language === "English" ? 'DOWNLOAD PDF' : ' SCARICA PDF'}
            </Button>
            }
            </>  
          )}
        </PDFDownloadLink> 

        </BottomNavigation>
      </Paper>
    </>
  );
}

import React from 'react';
import styles from '../styles/WhyRafiky.module.css';
import { Grid, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

export default function WhyRafiky() {

  const { language} = useSelector(state => state.proposal)

  return (
    <div>
      <section className={styles.header}>
            <span>
            {language === "English" ? "WHY RAFIKY?" : "PERCHÉ RAFIKY?"}

            </span>
            <img src='/rafiky/logo.webp' alt='logo'/>
        </section>
      <Grid container spacing={2} sx={{pt:3,paddingBottom:"60px", 
        backgroundImage:"url('/rafiky/brochure/background.png')", 
        backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    // boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 5px 3px",
    width:"100%",
    marginLeft:"0px"
        }}>
           
        <Grid item xs={12} md={6} sx={{mx:3}} className={styles.section1}>
          {/* <Typography variant="h1">
          {language === "English" ? "WHY RAFIKY?" : "PERCHÉ RAFIKY?"}
            </Typography> */}

          <ol className={styles.list}>
            <li className={styles.items}>
            {language === "English" ? `100% Guarantee of "Data Security and Privacy Protection"` : 
                "Garanzia al 100% di «Sicurezza Dati e Tutela Privacy»"}
              </li>
            <li className={styles.items}>
            {language === "English" ? `The best software for interpreting - new features to support professional interpreters in their work` : 
            `Il miglior software di interpretariato - funzionalità inedite per supportare gli interpreti
              professionisti nel loro lavoro`}
            </li>
            <li className={styles.items}>
            {language === "English" ? "Multilingual video and audio recording" : 
            "Registrazione video e audio multilingua"}
            </li>
            <li className={styles.items}>
            {language === "English" ? "All the main features of webinar platforms (voting, moderator panel, multi-room chat, sign language, speaker subtitles, etc.)" : 
"Tutte le principali features delle piattaforme per webinar (votazione, pannello moderatore, multi-room chat, lingua dei segni, sottopancia relatore ecc.)"}

            </li>
            <li className={styles.items}>
            {language === "English" ? "Internally developed platform - Compatibility with all other platforms (Zoom, Go to meeting, Skype, Teams etc.)" : 
            "Piattaforma sviluppata internamente - Compatibilità con tutte le altre piattaforme (Zoom, Go to meeting, Skype, Teams ecc.)"}

         
            </li>
            <li className={styles.items}>
            {language === "English" ? "Technical assistance during the event" : 
            "Assistenza Tecnica durante l’evento"}
              </li>
            <li>
            {language === "English" ? "More than 50 Rafiky Hubs on the national territory" : 
            "Piu di 50 Rafiky Hub sul territorio nazionale "}            
            </li>
          </ol>
        </Grid>
        <Grid item xs={12} md={5}>
          <img className={styles.img} alt='question mark' src='/rafiky/brochure/question.png'/>
        </Grid>
      </Grid>
    </div>
  );
}

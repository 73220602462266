export const Clients = {
  "Financial": [
    {
      name: 'Generali',
      logo: '/clients/finance/generali.png',
    },
    {name:'Allianz Italia', logo: '/clients/finance/Allianz.png'},
    {
      name: 'Banca D’Italia',
      logo: '/clients/finance/banca.png',
    },
    {
      name: 'WeFox Italy Spa',
      logo: '/clients/finance/wefox.png',
    },
    {
      name: 'Intesa Sanpaolo Spa',
      logo: '/clients/finance/Intesa.png',
    },
    {
      name: 'UniCredit',
      logo: '/clients/finance/uniCredit.png',
    },
    {
      name: 'Banca Monte dei Paschi di Siena',
      logo: '/clients/finance/banca_monte.png',
    },
    {
      name: 'Azimut Holding',
      logo: '/clients/finance/azimut.png',
    },
    {
      name: 'Mediobanca',
      logo: '/clients/finance/Mediobanca.png',
    },
  ],
  'Medical Areas':[
    {
        name:'Takeda',
        logo:'/clients/medical/takeda.png'
    },
    {name:'Esaote', logo:'/clients/medical/Esaote.png'},
    {name:'Angelini', logo:'/clients/medical/Angelini.png'},
    {name:'Medtronic', logo:'/clients/medical/Medtronic.png'},
    {
        name:'Storz Medical Italia',
        logo:'/clients/medical/storz_medical.png'
    },
    {
        name:'Alfasigma',
        logo:'/clients/medical/alfasigma.png'
    },
    {
        name:'EUROSIREL SPA',
        logo:'/clients/medical/EUROSIREL.png'
    },
    {
        name:'IRCCS SAN RAFFAELE ROMA S.R.L',
        logo:'/clients/medical/irccs.png'
    },
    {
        name:'IMMUNOTEC HEALTHCARE PRODUCTS',
        logo:'/clients/medical/immunotec.png'
    }
  ],
  'Medical Devices':[
    {
        name:'Takeda',
        logo:'/clients/medical/takeda.png'
    },
    {
      name:'EUROSIREL SPA',
      logo:'/clients/medical/EUROSIREL.png'
  },
    {name:'Esaote', logo:'/clients/medical/Esaote.png'},
    {name:'Angelini', logo:'/clients/medical/Angelini.png'},
    {name:'Medtronic', logo:'/clients/medical/Medtronic.png'},
    {
        name:'Storz Medical Italia',
        logo:'/clients/medical/storz_medical.png'
    },
    {
        name:'Alfasigma',
        logo:'/clients/medical/alfasigma.png'
    },
    {
        name:'IRCCS SAN RAFFAELE ROMA S.R.L',
        logo:'/clients/medical/irccs.png'
    },
    {
        name:'IMMUNOTEC HEALTHCARE PRODUCTS',
        logo:'/clients/medical/immunotec.png'
    }
  ],
  'Medical Materials':[
    {
        name:'Takeda',
        logo:'/clients/medical/takeda.png'
    },
    {name:'Esaote', logo:'/clients/medical/Esaote.png'},
    {name:'Angelini', logo:'/clients/medical/Angelini.png'},
    {name:'Medtronic', logo:'/clients/medical/Medtronic.png'},
    {
        name:'Storz Medical Italia',
        logo:'/clients/medical/storz_medical.png'
    },
    {
        name:'Alfasigma',
        logo:'/clients/medical/alfasigma.png'
    },
    {
      name:'EUROSIREL SPA',
      logo:'/clients/medical/EUROSIREL.png'
  },
    {
        name:'IRCCS SAN RAFFAELE ROMA S.R.L',
        logo:'/clients/medical/irccs.png'
    },
    {
        name:'IMMUNOTEC HEALTHCARE PRODUCTS',
        logo:'/clients/medical/immunotec.png'
    }
  ],
  'Transport':[
    {name:'Telepass', logo:'/clients/transport/telepass.png'},
    {name:'Airbus Helicopters', logo:'/clients/transport/airbus.png'},
    {name:'Fit -Cisl Federazione Italiana Trasporti', logo:'/clients/transport/fit-cisl.png'},
    {name:'Ferrari', logo:'/clients/transport/ferrari.png'},
    {name:'Mercedes-Benz', logo:'/clients/transport/mercedes.png'},
    {name:'Honda Europe Limited', logo:'/clients/transport/honda.png'},
    {name:'Audi', logo:'/clients/transport/audi.png'},
    {name:'Stellantis', logo:'/clients/transport/stellantis.png'},
    {name:'Toyota', logo:'/clients/transport/toyota.png'},
  ],
  'Logistics':[
    {name:'Telepass', logo:'/clients/transport/telepass.png'},
    {name:'Airbus Helicopters', logo:'/clients/transport/airbus.png'},
    {name:'Fit -Cisl Federazione Italiana Trasporti', logo:'/clients/transport/fit-cisl.png'},
    {name:'Ferrari', logo:'/clients/transport/ferrari.png'},
    {name:'Mercedes-Benz', logo:'/clients/transport/mercedes.png'},
    {name:'Honda Europe Limited', logo:'/clients/transport/honda.png'},
    {name:'Audi', logo:'/clients/transport/audi.png'},
    {name:'Stellantis', logo:'/clients/transport/stellantis.png'},
    {name:'Toyota', logo:'/clients/transport/toyota.png'},
  ],
  'Not-for-Profit':[
    {name:"UNHCR", logo:'/clients/notForProfit/unhcr.png'},
    {name:"Unicef", logo:'/clients/notForProfit/Unicef.png'},
    {name:"Emergency", logo:'/clients/notForProfit/Emergency.png'},
    {name:"Associazione della Croce Rossa Italiana", logo:"/clients/notForProfit/croce.png"},
    {name:"Intercultura Onlus, Medici Senza Frontiere", logo:"/clients/notForProfit/medici-sensa.png"},
    {name:"Save The Children", logo:"/clients/notForProfit/save-the-children.png"},
    {name:"AVIS Nazionale", logo:"/clients/notForProfit/avis.png"},
    {name:"UN/DESA", logo:"/clients/notForProfit/un-desa.png"},
    {name:"Amnesty International", logo:"/clients/notForProfit/amnesty.png"}
  ],
  'Fashion':[
    {name:"Chanel", logo:'/clients/fashion/chanel.png'},
    {name:"Brunello Cucinelli", logo:'/clients/fashion/brunello.png'},
    {name:"Freddy Spa", logo:'/clients/fashion/freddy.png'},
    {name:"Armani", logo:'/clients/fashion/armaani.png'},
    {name:"Hollister S.P.A", logo:'/clients/fashion/hollister.png'},
    {name:"Gattinoni", logo:'/clients/fashion/gattinoni.png'},
    {name:"Gucci", logo:'/clients/fashion/gucci.png'},
    {name:"Dolce & Gabbana", logo:'/clients/fashion/dolce.png'},
    {name:"Calzedonia Holding S.p.A", logo:'/clients/fashion/Calzedonia.png'},
  ],
  'Art':[
    {name:'Peggy Guggenheim Collection',logo:'/clients/Art/Peggy.png' },
    {name:"Museo Archeologico Salinas", logo:'/clients/Art/salinas.png'},
    {name:"Fondazione MAXXI,", logo:'/clients/Art/maxi.png'},
    {name:"Palazzo Pitti,", logo:'/clients/Art/palazzo.png'},
    {name:"Galleria Degli Uffizi", logo:'/clients/Art/gallerie.png'},
    {name:"Galleria Borghese", logo:'/clients/Art/borghese.png'},
    {name:"GAM (Galleria d’Arte Moderna)", logo:'/clients/Art/GAM.png'},
    {name:"Museo Archeologico Nazionale di Napoli", logo:'/clients/Art/napoli.png'},
    {name:"Museo Nazionale del Bargello", logo:'/clients/Art/bargello.png'},
  ],
  'Agronomy':[
    {name:'Barilla Agriculture', logo:'/clients/Agronomy/Barilla.png'},
    {name:'Corteva Agriscience',  logo:'/clients/Agronomy/CortevaAgriscience.png'},
    {name:'Ferrero Farming', logo:'/clients/Agronomy/Ferrero.png'},
    {name:'Organizzazione Mondiale Agricoltori', logo:'/clients/Agronomy/world-farmer-org.png'},
    {name:'Sipcam', logo:'/clients/Agronomy/sipcam.png'},
    {name:"Associazione Italiana di Ingegneria Agraria (AIIA)", logo:'/clients/Agronomy/aiia.png'},
    {name:"Associazione Italiana per l'Agricoltura Biologica (AIAB)", logo:'/clients/Agronomy/aiab.png'},
    {name:"Consiglio per la ricerca in agricoltura e l'analisi dell'economia agraria (CREA)", logo:'/clients/Agronomy/crea.png'},
    {name:'Fondazione per lo Sviluppo Sostenibile delle Aree Rurali (FSSAR)', logo:'/clients/Agronomy/fssar.png'},
  ],
  'Communication':[
    {name:'We are social', logo:'/clients/Communication/weAreSocial.png'},
    {name:'Netflix', logo:'/clients/Communication/Netflix.png'},
    {name:'Mediaset', logo:'/clients/Communication/mediaset.png'},
    {name:'Rai', logo:'/clients/Communication/rai.png'},
    {name:'Sky Italia', logo:'/clients/Communication/sky.png'},
    {name:'Amazon Prime Video', logo:'/clients/Communication/Amazon.png'},
    {name:'Disney+', logo:'/clients/Communication/disneyplus.png'},
    {name:'Warner Music Group', logo:'/clients/Communication/Warner.png'},
    {name:'Universal Music Group', logo:'/clients/Communication/Universal.png'},
  ],
  'Construction':[
    {name:'Itinera SpA', logo:'/clients/Construction/Itinera.png'},
    {name:'HEMPEL ITALY SRL', logo:'/clients/Construction/hempel.png'},
    {name:'Salini Impregilo', logo:'/clients/Construction/salini.png'},
    {name:'Astaldi', logo:'/clients/Construction/astaldi.png'},
    {name:'Trevi Group', logo:'/clients/Construction/Trevi.png'},
    {name:'Bonatti', logo:'/clients/Construction/Bonatti.png'},
    {name:'Webuild', logo:'/clients/Construction/Webuild.png'},
    {name:'Fincantieri',  logo:'/clients/Construction/Fincantieri.png'},
    {name:'Pizzarotti & C. S.p.A.', logo:'/clients/Construction/Pizzarotti.png'}
  ],
  'E-Learning':[
    {name:'eCampus', logo:'/clients/E-Learning/eCampus.png'},
    {name:'Udemy', logo:'/clients/E-Learning/Udemy.png'},
    {name:'Orienta Spa', logo:'/clients/E-Learning/orienta.png'},
    {name:'E4Impact', logo:'/clients/E-Learning/e4impact.png'},
    {name:'Università Campus Bio-Medico di Roma', logo:'/clients/E-Learning/Bio-Medico.png'},
    {name:'Università di Roma Tor Vergata', logo:'/clients/E-Learning/Tor-Vergata.png'},
    {name:'Università di Firenze', logo:'/clients/E-Learning/Firenze.png'},
    {name:'LUISS Business School', logo:'/clients/E-Learning/LUISS.png'},
    {name:'Università degli Studi di Milano', logo:'/clients/E-Learning/Milano.png'},
  ],
  'Education':[
    {name:'Orienta Spa', logo:'/clients/Education/orienta.png'},
    {name:'E4Impact', logo:'/clients/Education/e4impact.png'},
    {name:'Università Campus Bio-Medico di Roma', logo:'/clients/Education/Bio-Medico.png'},
    {name:'Università di Roma Tor Vergata', logo:'/clients/Education/Tor-Vergata.png'},
    {name:'Università di Firenze', logo:'/clients/Education/Firenze.png'},
    {name:'LUISS Business School', logo:'/clients/Education/LUISS.png'},
    {name:'Università degli Studi di Milano', logo:'/clients/Education/Milano.png'},
    {name:'Politecnico di Milano', logo:'/clients/Education/PolitecnicoMilano.png'},
    {name:'Università Bocconi', logo:'/clients/Education/Bocconi.png'},

  ],
  'Consumer Products':[
    {name:'Fater Spa', logo:'/clients/ConsumerProducts/fatar.png'},
    {name:'Vision Group Spa', logo:'/clients/ConsumerProducts/vision.png'},
    {name:'KMW', logo:'/clients/ConsumerProducts/kmw.png'},
    {name:'Clarins', logo:'/clients/ConsumerProducts/Clarins.png'},
    {name:'Luxottica', logo:'/clients/ConsumerProducts/Luxottica.png'},
    {name:'De’Longhi', logo:'/clients/ConsumerProducts/DeLonghi.png'},
    {name:'Calzedonia Holding S.p.A', logo:'/clients/ConsumerProducts/Calzedonia.png'},
    {name:'Barilla', logo:'/clients/ConsumerProducts/Barilla.png'},
    {name:'Sammontana Spa', logo:'/clients/ConsumerProducts/Sammontana.png'}
  ],
  'Energy':[
    {name:'Enel Green Power', logo:'/clients/Energy/Enel.png'},
    {name:'Eni', logo:'/clients/Energy/Eni.png'},
    {name:'Edison', logo:'/clients/Energy/Edison.png'},
    {name:'Terna', logo:'/clients/Energy/Terna.png'},
    {name:'Enel', logo:'/clients/Energy/Enel1.png'},
    {name:'Snam', logo:'/clients/Energy/Snam.png'},
    {name:'Energyear', logo:'/clients/Energy/Energyear.png'},
    {name:'Anaergia', logo:'/clients/Energy/Anaergia.png'},
    {name:'Hera Group', logo:'/clients/Energy/Hera.png'},

  ],
  'Environmental':[
    {name:'Ecosistemi', logo:'/clients/Environmental/Ecosistemi.png'},
    {name:'ENEA', logo:'/clients/Environmental/ENEA.png'},
    {name:'Hera Group', logo:'/clients/Environmental/Hera.png'},
    {name:'Terna', logo:'/clients/Environmental/Terna.png'},
    {name:'Enel Green Power', logo:'/clients/Environmental/Enel.png'},
    {name:'Novamont', logo:'/clients/Environmental/Novamont.png'},
    {name:'Sofidel', logo:'/clients/Environmental/Sofidel.png'},
    {name:'Legambiente', logo:'/clients/Environmental/Legambiente.png'},
    {name:'GSE', logo:'/clients/Environmental/GSE.png'},
  ],
  'Engineering':[
    {name:'Whirpool', logo:'/clients/Engineering/Whirpool.png'},
    {name:'Fedegari Autoclavi Spa', logo:'/clients/Engineering/Fedegari-Autoclavi.png'},
    {name:'Wika Instruments Spa', logo:'/clients/Engineering/Wika.png'},
    {name:'Tecnimont', logo:'/clients/Engineering/tecnimont.png'},
    {name:'Fincantieri',  logo:'/clients/Engineering/Fincantieri.png'},
    {name:'Maire Tecnimont',  logo:'/clients/Engineering/Maire_Tecnimont.png'},
    {name:'Leonardo',  logo:'/clients/Engineering/Leonardo.png'},
    {name:'Prysmian Group', logo:'/clients/Engineering/Prysmian.png' },
    {name:'Saipem', logo:'/clients/Engineering/Saipem.png' },
  ],
  'Event Organiser':[
    {name:'Uvet Events', logo:'/clients/EventOrganiser/Uvet.png'},
    {name:'Live Nation Italia', logo:'/clients/EventOrganiser/LiveNation.png'},
    {name:'RCS MediaGroup', logo:'/clients/EventOrganiser/RCS.png'},
    {name:'Reed Exhibitions Italia', logo:'/clients/EventOrganiser/Reed.png'},
    {name:'Fiera Milano', logo:'/clients/EventOrganiser/FieraMilano.png'},
    {name:'Fiera di Roma', logo:'/clients/EventOrganiser/FieraRoma.png'},
    {name:'Zed Live', logo:'/clients/EventOrganiser/Zed.png'},
    {name:'AIM Group International', logo:'/clients/EventOrganiser/AIM.png'},
    {name:'Cecoforma SA', logo:'/clients/EventOrganiser/Cecoforma.png'},
  ],
  'Food and Beverage':[
    {name:'Ferrero', logo:'/clients/Food/Ferrero.png'},
    {name:'Molinari', logo:'/clients/Food/Molinari.png'},
    {name:'Sammontana Spa', logo:'/clients/Food/Sammontana.png'},
    {name:'Lavazza', logo:'/clients/Food/Lavazza.png'},
    {name:'Campari Group', logo:'/clients/Food/Campari.png'},
    {name:'Ferrarelle', logo:'/clients/Food/Ferrarelle.png'},
    {name:'San Pellegrino', logo:'/clients/Food/SanPellegrino.png'},
    {name:'Galbani', logo:'/clients/Food/Galbani.png'},
    {name:'Amadori', logo:'/clients/Food/Amadori.png'},
  ],
  'Games/Apps':[
    {name:'Bad Jokes Studio', logo:'/clients/Games/BadJokes.png'},
    {name:'Bad Seed', logo:'/clients/Games/BadSeed.png'},
    {name:'Balzo', logo:'/clients/Games/Balzo.png'},
    {name:'Ubisoft Entertainment', logo:'/clients/Games/Ubisoft.png'},
    {name:'Milestone', logo:'/clients/Games/Milestone.png'},
    {name:'Rainbow Srl', logo:'/clients/Games/Rainbow.png'},
    {name:'Ovosonico', logo:'/clients/Games/Ovosonico.jpg'},
    {name:'Digital Bros',  logo:'/clients/Games/DigitalBros.png'},
    {name:'505 Games',  logo:'/clients/Games/505Games.png'}
  ],
  'Legal':[
    {name:'GRIMALDI STUDIO LEGALE', logo:'/clients/Legal/GRIMALDI.png'},
    {name:'Simmons & Simmons', logo:'/clients/Legal/Simmons.png'},
    {name:'TOFFOLETTO DE LUCA TAMAJO E SOCI', logo:'/clients/Legal/TOFFOLETTO.png'},
    {name:'EY Law', logo:'/clients/Legal/EY.png'},
    {name:'PWC TLS', logo:'/clients/Legal/pwc.png'},
    {name:'Sts Deloitte', logo:'/clients/Legal/Deloitte.png'},
    {name:'Studio Legale Chiomenti', logo:'/clients/Legal/Chiomenti.png'},
    {name:'Studio Legale NCTM', logo:'/clients/Legal/NCTM.png'},
    {name:'Studio Legale BonelliErede', logo:'/clients/Legal/BonelliErede.png'}
  ],
  'Manufacturing':[
    {name:'Electrolux S.p.A',logo:'/clients/Manufacturing/Electrolux.png' },
    {name:'ABB', logo:'/clients/Manufacturing/ABB.png'},
    {name:'FCA Italy S.p.A', logo:'/clients/Manufacturing/FCA.png' },
    {name:'Bosch Rexroth', logo:'/clients/Manufacturing/Bosch.png'},
    {name:'Emerson', logo:'/clients/Manufacturing/Emerson.png'},
    {name:'Honeywell', logo:'/clients/Manufacturing/Honeywell.png'},
    {name:'Mitsubishi Electric', logo:'/clients/Manufacturing/Mitsubishi.png'},
    {name:'Rockwell Automation', logo:'/clients/Manufacturing/Rockwell.png'},
    {name:'Schneider Electric', logo:'/clients/Manufacturing/Schneider.png'},
  ],
  'Entertainment Media':[
    {name:'Mediaset', logo:'/clients/Entertainment/mediaset.png'},
    {name:'Rai', logo:'/clients/Entertainment/rai.png'},
    {name:'Sky Italia', logo:'/clients/Entertainment/sky.png'},
    {name:'Amazon Prime Video', logo:'/clients/Entertainment/Amazon.png'},
    {name:'Disney+', logo:'/clients/Entertainment/disneyplus.png'},
    {name:'WPP Group plc', logo:'/clients/Entertainment/WPP.png'},
    {name:'Endemol Group', logo:'/clients/Entertainment/Endemol.png'},
    {name:'Radio Maria', logo:'/clients/Entertainment/RadioMaria.png'},
    {name:'Universal Music Group', logo:'/clients/Entertainment/Universal.png'},
  ],
  'Human Resources':[
    {name:'Adecco Group', logo:'/clients/HR/Adecco.png'},
    {name:'ManpowerGroup', logo:'/clients/HR/Manpower.png'},
    {name:'Randstad', logo:'/clients/HR/Ransted.png'},
    {name:'Gi Group', logo:'/clients/HR/Gi.png'},
    {name:'HR Solutions', logo:'/clients/HR/HRSolutions.png'},
    {name:'Assolavoro', logo:'/clients/HR/Assolavoro.png'},
    {name:'Openjobmetis', logo:'/clients/HR/Openjobmetis.png'},
    {name:'Human Value', logo:'/clients/HR/HumanValue.png'},
    {name:'Eurointerim', logo:'/clients/HR/Eurointerim.png'},
  ],
  'Patents':[
    {name:'Barzanò & Zanardo', logo:'/clients/Patents/Barzanò.png'},
    {name:'De Simone & Partners', logo:'/clients/Patents/DeSimone.png'},
    {name:'Modiano & Partners', logo:'/clients/Patents/Modiano.png'},
    {name:'Società Italiana Brevetti S.p.A', logo:'/clients/Patents/Brevetti.png'},
    {name:'Bugnion S.p.A', logo:'/clients/Patents/bugnion.png'},
    {name:'Jacobacci & Partners', logo:'/clients/Patents/Jacobacci.png'},
    {name:'Pavia e Ansaldo', logo:'/clients/Patents/PaviaAnsaldo.png'},
    {name:'Studio Turini', logo:'/clients/Patents/turini.png'},    
    {name:'Maiorana & Partners S.p.A.', logo:'/clients/Patents/Maiorana.png'},

  ],
  'Marketing':[
    {name:'Omnicom Group Italia', logo:'/clients/Marketing/Omnicom.png'},
    {name:'WPP Italia', logo:'/clients/Marketing/WPP.png'},
    {name:'Publicis Groupe Italia', logo:'/clients/Marketing/Publicis.png'},
    {name:'Dentsu Aegis Network Italia', logo:'/clients/Marketing/DentsuAegis.png'},
    {name:'Havas Group Italia', logo:'/clients/Marketing/Havas.png'},
    {name:'Accenture Interactive', logo:'/clients/Marketing/Accenture.png'},
    {name:'Ogilvy Italia', logo:'/clients/Marketing/Ogilvy.png'},
    {name:'Leo Burnett Italia', logo:'/clients/Marketing/LeoBurnett.png'},
    {name:'M&C Saatchi', logo:'/clients/Marketing/MCSaatchi.png'},
  ],
  'Literature':[
    {name:'Feltrinelli Editore', logo:'/clients/Literature/Feltrinelli.png'},
    {name:'Mondadori Editore', logo:'/clients/Literature/Mondadori.png'},
    {name:'Einaudi Editore', logo:'/clients/Literature/Einaudi.png'},
    {name:'Bompiani Editore', logo:'/clients/Literature/Bompiani.png'},
    {name:'Laterza', logo:'/clients/Literature/Laterza.png'},
    {name:'Rizzoli Editore',logo:'/clients/Literature/Rizzoli.png' },
    {name:'Baldini+Castoldi',logo:'/clients/Literature/BaldiniCastoldi.png' },
    {name:'Marsilio Editori',logo:'/clients/Literature/Marsilio.png' },
    {name:'Guanda Editore',logo:'/clients/Literature/Guanda.png' },
  ],
  'Internet':[
    {name:'Google Italia',logo:'/clients/Internet/Google.png' },
    {name:'Facebook Italia',logo:'/clients/Internet/Facebook.png' },
    {name:'Amazon Italia',logo:'/clients/Internet/Amazon.png' },
    {name:'eBay Italia',logo:'/clients/Internet/eBay.png' },
    {name:'LinkedIn Italia',logo:'/clients/Internet/LinkedIn.png' },
    {name:'Twitter Italia',logo:'/clients/Internet/Twitter.png' },
    {name:'Netflix Italia',logo:'/clients/Internet/Netflix.png' },
    {name:'Airbnb Italia',logo:'/clients/Internet/Airbnb.png' },
    {name:'Booking.com Italia',logo:'/clients/Internet/Booking.png' },
  ],
  'IT':[
    {name:'Reply', logo:'/clients/IT/Reply.png'},
    {name:'Engineering Ingegneria Informatica', logo:'/clients/IT/EngineeringIngegneria.png'},
    {name:'TXT e-solutions', logo:'/clients/IT/TXT.png'},
    {name:'AlmavivA', logo:'/clients/IT/AlmavivA.png'},
    {name:'Exprivia', logo:'/clients/IT/Exprivia.png'},
    {name:'Lutech', logo:'/clients/IT/Lutech.png'},
    {name:'Italtel', logo:'/clients/IT/Italtel.png'},
    {name:'Engineering D.HUB', logo:'/clients/IT/EngineeringDHUB.png'},
    {name:'Dedagroup', logo:'/clients/IT/Dedagroup.png'},
  ],
  'Life Sciences':[
    {name:'Angelini Pharma', logo:'/clients/LifeSciences/Angelini_pharma.png'},
    {name:'Recordati', logo:'/clients/LifeSciences/Recordati.png'},
    {name:'Menarini', logo:'/clients/LifeSciences/Menarini.png'},
    {name:'Chiesi Farmaceutici', logo:'/clients/LifeSciences/Chiesi.png'},
    {name:'Dompé', logo:'/clients/LifeSciences/Dompé.png'},
    {name:'Bracco Imaging', logo:'/clients/LifeSciences/Bracco.png'},
    {name:'Molteni Farmaceutici', logo:'/clients/LifeSciences/Molteni.png'},
    {name:'Abiogen Pharma', logo:'/clients/LifeSciences/Abiogen.png'},
    {name:'Novartis',logo:'/clients/LifeSciences/Novartis.png' }
  ],
  'Leisure':[
    {name:'Gruppo Armani Hotels & Resorts',logo:'/clients/Leisure/Armani.png' },
    {name:'Starhotels',logo:'/clients/Leisure/Starhotels.png' },
    {name:'Baglioni Hotels & Resorts',logo:'/clients/Leisure/Baglioni.png' },
    {name:'B&B Hotels Italia',logo:'/clients/Leisure/B&B.png' },
    {name:'NH Hotel Group Italia',logo:'/clients/Leisure/NH.png' },
    {name:'Gardaland',logo:'/clients/Leisure/Gardaland.png' },
    {name:'Mirabilandia',logo:'/clients/Leisure/Mirabilandia.png' },
    {name:'Rainbow Magicland',logo:'/clients/Leisure/Rainbow.png' },
    {name:'Una Hotels & Resorts', logo:'/clients/Leisure/Una.png'}
  ],
  'Sports':[
    {name:'Comitato Olimpico Nazionale Italiano (CONI)',logo:'/clients/Sports/CONI.png' },
    {name:'Federazione Italiana Giuoco Calcio (FIGC)',logo:'/clients/Sports/FIGC.png' },
    {name:'Federazione Italiana Rugby (FIR)',logo:'/clients/Sports/FIR.png' },
    {name:'Federazione Italiana Pallacanestro (FIP)',logo:'/clients/Sports/FIP.png' },
    {name:'Federazione Italiana Pallavolo (FIPAV)',logo:'/clients/Sports/FIPAV.png' },
    {name:'Federazione Italiana Tennis (FIT)',logo:'/clients/Sports/FIT.png' },
    {name:'Lega Serie A',logo:'/clients/Sports/SerieA.png' },
    {name:'Lega Pro',logo:'/clients/Sports/Pro.png' },
    {name:'Lega B',logo:'/clients/Sports/LegaB.png' },
  ],
  'Religious':[
    {name:'Chiesa Cattolica Romana',logo:'' },
    {name:'Chiesa Evangelica Valdese', logo:'' },
    {name:'Chiesa Ortodossa Greca', logo:'' },
    {name:'Chiesa Apostolica Armena', logo:'' },
    {name:'Unione delle Comunità Ebraiche Italiane', logo:'' },
    {name:'Chiesa Evangelica Luterana in Italia', logo:'' },
    {name:'Chiesa Anglicana', logo:'' },
    {name:'Chiesa Battista', logo:'' },
    {name:'Chiesa Pentecostale', logo:'' },

  ],
  'Public Sector':[
    {name:'Ministero della Salute', logo:'/clients/PublicSector/MinisterodellaSalute.png' },
    {name:'Ministero della difesa', logo:'/clients/PublicSector/delladifesa.png' },
    {name:'Nazioni Unite', logo:'/clients/PublicSector/NazioniUnite.png' },
    {name:'Ministero dell`Interno', logo:'/clients/PublicSector/dellInterno.png' },
    {name:'Parlamento Europeo', logo:'/clients/PublicSector/ParlamentoEuropeo.png' },
    {name:'Regione Lazio', logo:'/clients/PublicSector/RegioneLazio.png' },
    {name:'Regione Lombardia', logo:'/clients/PublicSector/RegioneLombardia.png' },
    {name:'Consolato Svizzero', logo:'/clients/PublicSector/ConsolatoSvizzero.png' },
    {name:'Regione Veneto', logo:'/clients/PublicSector/RegioneVeneto.png' },
  ],
  'Support Services':[
    {name:'ManpowerGroup', logo:'/clients/SupportServices/ManpowerGroup.png' },
    {name:'Adecco Group', logo:'/clients/SupportServices/Adecco.png' },
    {name:'Randstad Italia', logo:'/clients/SupportServices/Ransted.png' },
    {name:'Sodexo Italia', logo:'/clients/SupportServices/Sodexo.png' },
    {name:'ISS Facility Services', logo:'/clients/SupportServices/ISS.png' },
    {name:'Compass Group Italia', logo:'/clients/SupportServices/Compass.png' },
    {name:'Aramark Italia', logo:'/clients/SupportServices/Aramark.png' },
    {name:'Intertek', logo:'/clients/SupportServices/Intertek.png' },
    {name:'Bureau Veritas', logo:'/clients/SupportServices/BureauVeritas.png' },
  ],
  'Retail':[
    {name:'Esselunga', logo:'/clients/Retail/Esselunga.png' },
    {name:'Coop Italia', logo:'/clients/Retail/Coop.png' },
    {name:'Conad', logo:'/clients/Retail/Conad.png' },
    {name:'Zalando', logo:'/clients/Retail/Zalando.png' },
    {name:'Carrefour Italia', logo:'/clients/Retail/Carrefour.png' },
    {name:'Lidl Italia', logo:'/clients/Retail/Lidl.png' },
    {name:'Eurospin', logo:'/clients/Retail/Eurospin.png' },
    {name:'Decathlon Italia', logo:'/clients/Retail/Decathlon.png' },
    {name:'IKEA Italia', logo:'/clients/Retail/IKEA.png' },
  ],
  'Technology':[
    {name:'Samsung Electronics', logo:'/clients/Technology/Samsung.png' },
    {name:'Siemens', logo:'/clients/Technology/Siemens.png' },
    {name:'Cisco', logo:'/clients/Technology/Cisco.png' },
    {name:'10 Zig Technology', logo:'/clients/Technology/10Zig.png' },
    {name:'Intel', logo:'/clients/Technology/Intel.png' },
    {name:'Gsr Technology', logo:'/clients/Technology/Gsr.png' },
    {name:'Nikon', logo:'/clients/Technology/Nikon.png' },
    {name:'Zendesk', logo:'/clients/Technology/Zendesk.png' },
    {name:'Oracle', logo:'/clients/Technology/Oracle.png' },
  ],
  'Technical':[
    {name:'Siemens AG', logo:'/clients/Technical/Siemens.png' },
    {name:'General Electric Company', logo:'/clients/Technical/GE.png' },
    {name:'Bosch Group', logo:'/clients/Technical/Bosch.png' },
    {name:'ABB Ltd', logo:'/clients/Technical/ABB.png' },
    {name:'Schneider Electric SE', logo:'/clients/Technical/SE.png' },
    {name:'Honeywell International Inc.', logo:'/clients/Technical/Honeywell.png' },
    {name:'Emerson Electric Co', logo:'/clients/Technical/Emerson.png' },
    {name:'Rockwell Automation', logo:'/clients/Technical/Rockwell.png' },
    {name:'Danaher Corporation', logo:'/clients/Technical/Danaher.png' },

  ],
  'Automotive':[
    {name:'Tata', logo:'/clients/Automotive/Tata.png' },
    {name:'Autochair', logo:'/clients/Automotive/Autochair.png' },
    {name:'Gruppo Fiat', logo:'/clients/Automotive/GruppoFiat.png' },
    {name:'Mazda', logo:'/clients/Automotive/Mazda.png' },
    {name:'Magneti Marelli', logo:'/clients/Automotive/MagnetiMarelli.png' },
    {name:'Mercedes-Benz', logo:'/clients/Automotive/MercedesBenz.png' },
    {name:'Bridgestone', logo:'/clients/Automotive/Bridgestone.png' },
    {name:'Pirelli', logo:'/clients/Automotive/pirelli.png' },
    {name:'Ansaldo Breda', logo:'/clients/Automotive/Ansaldo.png' },
  ],
  'Cosmetics':[
    {name:'L’Oréal Professionnel', logo:'/clients/Cosmetics/loreal.png' },
    {name:'Dior', logo:'/clients/Cosmetics/Dior.png' },
    {name:'e Yves Saint Laurent', logo:'/clients/Cosmetics/eYves.png' },
    {name:'Deborah', logo:'/clients/Cosmetics/Deborah.png' },
    {name:'KIKO', logo:'/clients/Cosmetics/KIKO.png' },
    {name:'Sephora', logo:'/clients/Cosmetics/Sephora.png' },
    {name:'Procter & Gamble', logo:'/clients/Cosmetics/Procter.png' },
    {name:'Clarins', logo:'/clients/Cosmetics/Clarins.png' },
    {name:'Avon Products', logo:'/clients/Cosmetics/Avon.png' },
  ]
};

import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const OurClients = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
          <View style={{ ...styles.header, marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
          <Text style={styles.headerItemText}>{language === 'English' ? `OUR CUSTOMERS` : `I NOSTRI CLIENTI`}</Text>
        </View>
         <View>
          <Image style={{...styles.headerRightImg }} src="/translationAssets/desktop6/lingoyou.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
     <View style={styles.testimonial}>

       <View style={{...styles.clientsImages, marginTop:"20px"}}>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image1.png"/>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image2.png"/>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image3.png"/>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image4.png"/>

       </View>
       <View style={styles.clientsImages}>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image5.png"/>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image6.png"/>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image7.png"/>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image8.png"/>

       </View>
       <View style={styles.clientsImages}>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image9.png"/>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image10.png"/>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image11.png"/>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image12.png"/>

       </View>
       <View style={{...styles.clientsImages, borderBottom:"none"}}>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image13.png"/>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image14.png"/>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image15.png"/>
          <Image style={styles.clientsImage} src="/translationAssets/clients/image16.png"/>

       </View>
      
       </View>
    </Page>
  );
};

export default OurClients;
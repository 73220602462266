import { Grid } from "@mui/material";
import styles from "../styles/Interpreting.module.css";
import {MdOutlineCalendarMonth} from 'react-icons/md';
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import { useSelector } from "react-redux";


const Interpreting = () => {
  const { language} = useSelector(state => state.proposal)

  if (language === 'English') {
  return (
    <div className={styles.desktop9}>
      <div className={styles.desktop9Child} />
      {/* <div className={styles.div}>05</div> */}
      <div className={styles.interpreting}>INTERPRETATION & TECHNICAL EQUIPMENT</div>
      <div className={styles.text3543Parent}>
        <img alt="" src="/assets/desktop6/group-1000001719.svg" />{" "}
      </div>
      <div className={styles.atCongressiInternazionaliContainer}>
        
          <Grid container spacing={3} sx={{marginTop:"20px"}}>
          <Grid item xs={12} lg={8} className={styles.icons}>  
          <p className={styles.atCongressiInternazionali}>
              We at Congressi Internazionali guarantee <b>
              the best interpreting service on the territory.
                </b>
          <br />
          The characteristics of our interpreters:
        </p>
          <Grid container spacing={3}>          
          <Grid item xs={6} md={4} className={styles.icon}>
            <img alt='logos' src="/assets/icons/medal.png" />
            <p>Master Degree in Languages</p>
          </Grid>
          <Grid item xs={6} md={4} className={styles.icon}>
            <MdOutlineCalendarMonth style={{fontSize:"65px", marginInline:"auto"}}/>
            {/* <img alt='logos' src="/assets/icons/email.png" /> */}
            <p>At least 5 years of experience</p>
          </Grid>
          <Grid item xs={6} md={4} className={styles.icon}>
            <img alt='logos' src="/assets/icons/user.png" /> 
            <p>Membership in linguist associations </p>
          </Grid>
          <Grid item xs={6} md={4} className={styles.icon}>
            <img alt='logos' src="/assets/icons/Certificate.png" />
            <p>Quality certifications</p>
          </Grid>
          <Grid item xs={6} md={4} className={styles.icon}>
            <img alt='logos' src="/assets/icons/presentation.png" />
            <p>	Sector specialization </p>
          </Grid>
          <Grid item xs={6} md={4} className={styles.icon}>
            <img alt='logos' src="/assets/icons/star.png" />
            <p>At least 5 Positive feedbacks</p>
          </Grid>
          </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
          <div className={styles.video}>
    <LiteYouTubeEmbed 
        id="uZJFL0IVC-8"
        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
    />
          </div>
          </Grid>
        </Grid>        
      </div>
      
    </div>
  );
  }
  else{
    return (
      <div className={styles.desktop9}>
        <div className={styles.desktop9Child} />
        {/* <div className={styles.div}>05</div> */}
        <div className={styles.interpreting}>INTERPRETARIATO & ATTREZZATURA TECNICA</div>
        <div className={styles.text3543Parent}>
          <img alt='logos' src="/assets/desktop6/group-1000001719.svg" />{" "}
        </div>
        <div className={styles.atCongressiInternazionaliContainer}>
         
          <Grid container spacing={3} sx={{marginTop:"20px"}}>
          <Grid item xs={12} lg={8} className={styles.icons}>  
          <p className={styles.atCongressiInternazionali}>
            Noi di Congressi Internazionali garantiamo <b>
            il miglior servizio di interpretariato sul
            territorio.
              </b> 
            <br />
            Le caratteristiche dei nostri interpreti:
          </p>
          <Grid container spacing={3}>
            <Grid item xs={6} md={4} className={styles.icon}>
              <img alt='logos' src="/assets/icons/medal.png" />
              <p>Laurea Magistrale in Lingue </p>
            </Grid>
            <Grid item xs={6} md={4} className={styles.icon}>
            <MdOutlineCalendarMonth style={{fontSize:"65px", marginInline:"auto"}}/>
              <p>Almeno 5 anni di esperienza </p>
            </Grid>
            <Grid item xs={6} md={4} className={styles.icon}>
              <img alt='logos' src="/assets/icons/user.png" />
              <p>Appartenenza ad Associazioni di Categoria </p>
            </Grid>
            <Grid item xs={6} md={4} className={styles.icon}>
              
              <img alt='logos' src="/assets/icons/Certificate.png" />
              <p>Certificazioni di qualità </p>
            </Grid>
            <Grid item xs={6} md={4} className={styles.icon}>
              <img alt='logos' src="/assets/icons/presentation.png" />
              <p>Specializzazione Settoriale </p>
            </Grid>
            <Grid item xs={6} md={4} className={styles.icon}>
              <img alt='logos' src="/assets/icons/star.png" />
              <p>Almeno 5 Feedback Positivi</p>
            </Grid>
          </Grid>
          </Grid>
          <Grid item xs={12} lg={4}>
          <div className={styles.video}>
    <LiteYouTubeEmbed 
        id="uZJFL0IVC-8"
        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
    />
          </div>
          </Grid>
          </Grid>
        </div>
        
      </div>
    );
  }
};

export default Interpreting;

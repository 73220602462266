import React from 'react';
import {  Document } from "@react-pdf/renderer";
import AboutUs1 from 'src/sections/interpretClientOutput/PDFDoc/pages/AboutUs1.js';
import Introduction from './pages/Introduction.js';
import AboutUs2 from 'src/sections/interpretClientOutput/PDFDoc/pages/AboutUs2.js';
import CompanyPerformance from 'src/sections/interpretClientOutput/PDFDoc/pages/CompanyPerformance.js';
import Location from 'src/sections/interpretClientOutput/PDFDoc/pages/Location.js';
import Interpreting from 'src/sections/interpretClientOutput/PDFDoc/pages/Interpreting.js';
import Discount from './pages/Discount.js';
import GreenWay from 'src/sections/interpretClientOutput/PDFDoc/pages/GreenWay.js';
import OurClients from 'src/sections/interpretClientOutput/PDFDoc/pages/OurClients.js';
import Home from './pages/Home.js';
import Testimonials from 'src/sections/interpretClientOutput/PDFDoc/pages/Testimonials.js';
import Blogs from 'src/sections/interpretClientOutput/PDFDoc/pages/Blogs.js';
import ContactUs from 'src/sections/interpretClientOutput/PDFDoc/pages/ContactUs.js';
import SectorClients from './pages/SectorClients.js';


const PDFDoc = ({ language = "Italian", details }) => {
  const {
    companyNameAndId,
    accountManager,
    bookedBy,
    brochureNumber,
    companyLogo,
    subject,
    industry,
    clients
  } = details;
    console.log(details)
  return (
    <>
   {details && (
   <Document >
            <Home language={language} data={{ companyNameAndId, bookedBy, accountManager, brochureNumber, subject, companyLogo, industry }}/>
      <Introduction language={language}industry={industry} bookedBy={bookedBy} accountManager={accountManager} 
      companyNameAndId={companyNameAndId} clients={clients}/>
      <SectorClients language={language} industry={industry} clients={clients}/> 
      <AboutUs1 language={language}/>
      <AboutUs2 language={language}/>
      <CompanyPerformance language={language}/>
      <Location language={language}/>
      <Interpreting language={language}/>
      <OurClients language={language}/>
      <Testimonials language={language}/>
      <Discount language={language}/>
      <GreenWay language={language}/>
      <Blogs language={language}/>
      <ContactUs language={language}/>
    </Document>
    
  )}
  </>
  )
};

export default PDFDoc;

import { Avatar, Box, Checkbox, Container, FormControlLabel, Radio, Typography } from '@mui/material';
import React, {useState, useEffect} from 'react';
import { styled, useTheme } from '@mui/material/styles';
import { Table, TableRow, TableBody, TableHead, TableCell, TableContainer } from '@mui/material';
import Scrollbar from './Scrollbar';
import styles from '../styles/AboutUs2.module.css';
import styles1 from '../styles/TablesItems.module.css';
import { fCurrency } from 'src/utils/formatNumber';
import { useDispatch, useSelector } from 'react-redux';
import { addOption } from 'src/redux/slices/proposal';
import Table1 from './Table/Table1';
import Table2Header from './Table/Table2Header';
import Table2Items from './Table/Table2Items';
import Table3 from './Table/Table3';
import Table2ServiceItems from './Table/Table2ServiceItems';

const RowResultStyle = styled(TableRow)(({ theme }) => ({
  '& td': {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
  },
}));
function PackagesTablesItems() {

    const {language, packagesItemsTranslation, service1Translation, packagesItem} = useSelector((state)=>state.proposal);
    const dispatch = useDispatch();
 
   const {item: translationItem, service: additionalService, subtotal, netTotal, totalDiscount, taxes, grandTotal, notes } = packagesItem

  return (
    <>
      <Box
        sx={{
          display: 'flow-root',
          height: `auto`,
          position: 'relative',
          paddingBottom: '20px',
          backgroundColor: 'white',
          boxShadow: "0 0 2px 1px rgb(0 0 0 / 40%)"

        }}
      >
        <div className={styles.desktop8Item} />
        {/* <div className={styles.div}>17</div> */}
        <div className={styles.aboutUs}>{language === "English" ? 'Quote' : "Preventivo"}</div>
        <div className={styles.text3543Parent} style={{top:'0.37%'}}>
          <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{' '}
        </div>
        <div className={styles1.tables}>
          <div className={styles1.table1}>
            {/* <Card sx={{ display: 'contents' }}> */}
            <Scrollbar>
              <TableContainer sx={{ marginLeft: '2%', width: '95%' }}>
              <Table1/>
              </TableContainer>
            </Scrollbar>
            {/* </Card> */}
          </div>
          <div
            className={styles1.table2}
            style={{
              marginBottom: '4em',
              padding: '0px',
              marginLeft: '0px',
              marginInline: 'auto',
               borderRadius: '15px',
              marginTop: '3em',
              padding: '25px',
            }}
          >
            {/* <Card sx={{ display: 'contents' }}> */}
            <Scrollbar>
              <TableContainer sx={{ marginLeft: '1%', width: '95%' }}>
                <Table sx={{ width: '100%', borderCollapse: 'inherit' }}>
                <Table2Header/>
                 <TableBody>
                    {translationItem &&
                      translationItem.map((row, index) => (
                      <Table2Items row={row} index={index} itemTranslation={packagesItemsTranslation?.length > 0 ? packagesItemsTranslation[index] : ''}/>
                      ))}
                    {additionalService &&
                      additionalService.map((row, index) => (
                        <Table2ServiceItems row={row} index={index} itemTranslation={service1Translation?.length > 0 ? service1Translation[index] : ''}/>

                      ))}

                    <RowResultStyle sx={{ borderBottom: 'none' }}>
                      <TableCell
                        colSpan={5}
                        sx={{
                          borderBottom: 'none',
                          borderTop: '1.29922px solid #2DA0F0',
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: 'large',
                        }}
                        align="left"
                      >
                        {notes && notes !== '' && notes !== '<p><br></p>' && (
                          <>
                            <Box sx={{ mt: 2 }} />
                            <Typography
                              sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
                            >
                  {language === 'English' ? "Notes" : "Note"}

                            </Typography>
                            <Typography variant="body2" sx={{ color: 'text.secondary', fontSize: '15px' }}>
                            {notes.replace(/(&nbsp;|<[^>]*>)/g, ' ')}                            </Typography>
                          </>
                        )}
                      </TableCell>
                    </RowResultStyle>
                    <RowResultStyle sx={{ borderBottom: 'none' }}>
                      <TableCell sx={{ borderBottom: 'none' }} align="left">
                        <Box sx={{ mt: 2 }} />
                        <Typography
                          sx={{
                            color: "black",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 'large',
                            fontWeight: '700',
                          }}
                        >
                          {language === 'English' ? 'Subtotal' : 'Subtotale'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell sx={{ borderBottom: 'none' }} />

                      <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                        <Typography sx={{color:"black", fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}>
                           &nbsp;{fCurrency(Number(subtotal))}
                        </Typography>
                      </TableCell>
                    </RowResultStyle>
                    {totalDiscount ? (
                      <RowResultStyle>
                        <TableCell sx={{ borderBottom: 'none' }} align="left">
                          <Typography
                            sx={{
                              color: "black",
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: 'large',
                              fontWeight: '700',
                            }}
                          >
                            {language === 'English' ? 'Discount' : 'Sconto'} ({totalDiscount ? totalDiscount : 0}%)
                          </Typography>
                        </TableCell>
                        <TableCell sx={{ borderBottom: 'none' }} />
                        <TableCell sx={{ borderBottom: 'none' }} />
                        <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                          <Typography
                            sx={{
                              color: "black",
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: 'large',
                              fontWeight: '700',
                            }}
                          >
                             &nbsp;{fCurrency(Number((Number(subtotal) * Number(totalDiscount)) / 100))}
                          </Typography>
                        </TableCell>
                      </RowResultStyle>
                    ) : (
                      ''
                    )}
                    <RowResultStyle>
                      <TableCell sx={{ borderBottom: 'none' }} align="left">
                        <Typography
                          sx={{
                            color: "black",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 'large',
                            fontWeight: '700',
                          }}
                        >
                          {language === 'English' ? 'Net Total' : 'Totale netto'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                        <Typography sx={{color:"black", fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}>
                           &nbsp;{fCurrency(netTotal)}
                        </Typography>
                      </TableCell>
                    </RowResultStyle>
                    <RowResultStyle>
                      <TableCell sx={{ borderBottom: 'none' }} align="left">
                        <Typography
                          sx={{
                            color: "black",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 'large',
                            fontWeight: '700',
                          }}
                        >
                          IVA ({taxes}%)
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell sx={{ borderBottom: 'none' }} />

                      <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                        <Typography sx={{color:"black", fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}>
                           &nbsp;{fCurrency(Number((Number(netTotal) * Number(taxes)) / 100))}
                        </Typography>
                      </TableCell>
                    </RowResultStyle>

                    <RowResultStyle>
                      <TableCell sx={{ borderBottom: 'none' }} align="left">
                        <Typography
                          sx={{
                            color: "black",
                            fontFamily: "'Poppins', sans-serif",
                            fontSize: 'large',
                            fontWeight: '700',
                          }}
                        >
                          {language === 'English' ? 'Total Gross' : 'Totale Lordo'}
                        </Typography>
                      </TableCell>
                      <TableCell sx={{ borderBottom: 'none' }} />
                      <TableCell sx={{ borderBottom: 'none' }} />
                      {grandTotal ? (
                        <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                          <Box sx={{ mt: 2 }} />
                          <Typography
                            sx={{
                              color: "black",
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: 'large',
                              fontWeight: '700',
                            }}
                          >
                             &nbsp;{fCurrency(Number(grandTotal))}
                          </Typography>
                        </TableCell>
                      ) : (
                        <TableCell colSpan={2} sx={{ borderBottom: 'none' }} align="right">
                          <Typography
                            sx={{
                              color: "black",
                              fontFamily: "'Poppins', sans-serif",
                              fontSize: 'large',
                              fontWeight: '700',
                            }}
                          >
                             &nbsp;{fCurrency((Number(netTotal) + (Number(netTotal) * Number(taxes)) / 100))}
                          </Typography>
                        </TableCell>
                      )}
                    </RowResultStyle>
                  </TableBody>
                </Table>
              </TableContainer>
            </Scrollbar>
            {/* </Card> */}
          </div>
        <Table3/>
        </div>
      </Box>
    </>
  );
}

export default PackagesTablesItems;

import { Grid } from '@mui/material';
import styles from '../styles/AboutUs1.module.css';
import { useSelector } from 'react-redux';



const AboutUs1 = () => {

  const { language} = useSelector(state => state.proposal)

    return (
      <div id='mission' className={styles.desktop7}>
        {/* <div className={styles.div}>03</div> */}
        <div className={styles.aboutUs} style={{fontWeight:"bold"}}>
        {language === 'English' ? `OUR MISSION` : `LA NOSTRA MISSION`}
        </div>
        <div className={styles.text3543Parent}>
          <img style={{marginTop:"-21px"}} alt="avatar" src="/rafiky/logo.webp" />{' '}
        </div>
        <div className={styles.desktop7Item} />

        <div className={styles.weProvideTheContainer}>
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
            {language === 'English'
            ? `Are you ready to create prestigious and successful events? Welcome to Rafiky!`
            : `Siete pronti a realizzare eventi prestigiosi e di successo? Benvenuti in Rafiky!`}
            </span>
          </p>
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
            {language === 'English'
            ? `The past is behind us! We are changing the way we see the interpreting.`
            : `Il passato è alle spalle! Stiamo cambiando il modo in cui vediamo l'interpretariato.`}
            </span>
          </p>
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
            {language === 'English'
            ? `What sets us apart is the ability to offer tailor-made solutions for our clients. We are passionate about knowledge, innovation and collaboration, and that is why we are committed to providing cutting-edge solutions, quality customer service and availability throughout Italy and abroad. Whether you want to organize medium or large-scale events, our mission is the success of our clients.`
            : `Ciò che ci contraddistingue è l’abilità di offrire soluzioni su misura per i nostri clienti. Siamo appassionati di conoscenza, innovazione e collaborazione, ed è per questo che ci impegniamo a fornire soluzioni all’avanguardia, un customer service di qualità e disponibilità in tutta Italia e all’estero. Che vogliate organizzare eventi di media o di grande portata, la nostra missione è il successo dei nostri clienti.`}
      
            </span>
          </p>
         
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
            {language === 'English'
            ? `We have broken down all language barriers thanks to a software created by us. Rafiky and our team represent the innovation of a service sector that allows you to communicate with people from all over the world: remote simultaneous translation.`
            : `Abbiamo abbattuto ogni barriera linguistica grazie ad un software creato da noi. Rafiky e il nostro team rappresentano l'innovazione di un settore dei servizi che permette di comunicare con persone di tutto il mondo: la traduzione simultanea a distanza.`}
      
            </span>
          </p>
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
            {language === 'English'
            ? `Our mission? Only 17% of the world speaks English as a native or second language. However, in professional meetings and events, English is often the default language. We want to change the narrative. Our mission is to connect the world in all its languages.`
            : `La nostra missione? Solo il 17% del mondo parla inglese come madrelingua o seconda lingua. Tuttavia, nelle riunioni ed eventi professionali, l'inglese è spesso la lingua predefinita. Vogliamo cambiare la narrativa. La nostra missione è connettere il mondo in tutte le sue lingue.`}
      
            </span>
          </p>
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
            {language === 'English'
            ? `We imagine a future where everyone can express themselves in their native language, be it Spanish, Mandarin, Arabic, French or any other language. We overcome language barriers, valuing every voice and culture. We connect the world in their language.`
            : `Immaginiamo un futuro dove ognuno può esprimersi nella propria lingua madre, che sia spagnolo, mandarino, arabo, francese o qualsiasi altra lingua. Superiamo le barriere linguistiche, valorizzando ogni voce e cultura. Connettiamo il mondo nella sua lingua.`}
      
            </span>
          </p>
        </div>
        <Grid container sx={{ marginTop: '40px', bottom: '0%' }} spacing={3} className={styles.desktop7Frame}>
        <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/ChiaraMoschitta.webp" />
            <div className={styles.proyek}>Chiara Moschitta</div>
            <div className={styles.accountManager}>Account Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/GiuliaCaravasso.webp" />
            <div className={styles.proyek}>Giulia Caravasso</div>
            <div className={styles.accountManager}>Account Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/GiuseppeGagliano.webp" />
            <div className={styles.proyek}>Giuseppe Gagliano</div>
            <div className={styles.accountManager}>Account Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/MariaSegreto.webp" />
            <div className={styles.proyek}>Maria Segreto</div>
            <div className={styles.accountManager}>Client Success Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/MiriamFratacci.webp" />
            <div className={styles.proyek}>Miriam Fratacci</div>
            <div className={styles.accountManager}>Event Planner</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/GiuseppePinelli.webp" />
            <div className={styles.proyek}>Giuseppe Pinelli</div>
            <div className={styles.accountManager}>Accountant</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/JimPuccio.webp" />
            <div className={styles.proyek}>Jim Puccio</div>
            <div className={styles.accountManager}>Interpretation Project Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/VeronicaGaudesi.webp" />
            <div className={styles.proyek}>Veronica Gaudesi</div>
            <div className={styles.accountManager}>Interpretation Project Manager</div>
          </Grid> 
         
        </Grid>
      </div>
    );
  
};

export default AboutUs1;

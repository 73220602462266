import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const Privacy = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
     <View style={styles.testimonial}>
     <View style={{ display: "flex",
    flexDirection: "row",
    alignItems: "center",
     width: "94%", marginBottom:"10px" }}>
          <Text style={{...styles.headerItemText, marginLeft:"10px"}}>
            {language === 'English' ? `Privacy & Cyber Security` : `Privacy & Cyber Security`}</Text>
          <Image style={{...styles.headerRightImg, marginLeft:"15%"}} src="/translationAssets/desktop6/lingoyou.png" />
      </View>
      <Text style={{marginTop:"80px", color:"white",fontWeight:"bold", marginLeft:"230px", width:"155px", fontSize:"7px"}}>
      {language === 'English' ? `Maximum guarantee of user privacy and cyber security & Documentation provided` :
       `Massima garanzia privacy e cyber security degli utenti & Documentazione fornita`}

      </Text>
      <View style={{display:"inline-flex", flexDirection:"row", width:"100%"}}>
      <Text style={{marginTop:"48px", color:"white",fontWeight:"bold", marginLeft:"10px", width:"155px", fontSize:"7px"}}>
      {language === 'English' ? `Non Disclosure Agreement form - On Demand` :
       `Non Disclosure Agreement form - On Demand`}
      </Text>
      <Text style={{marginTop:"48px", color:"white",fontWeight:"bold", marginLeft:"47%", width:"155px", fontSize:"7px"}}>
      {language === 'English' ? `Customer portal developed on AWS server (S3) - Maximum data security` :
       `Portale cliente sviluppato su server AWS (S3) - Massima Sicurezza dei dati`}
      </Text>
      </View>
      <View style={{display:"inline-flex", flexDirection:"row", width:"100%"}}>
      <Text style={{marginTop:"45px", color:"white",fontWeight:"bold", marginLeft:"10px", width:"155px", fontSize:"7px"}}>
      {language === 'English' ? `Penetration Test – half yearly` :
       `Penetration Test – semestrale`}
      </Text>
      <Text style={{marginTop:"45px", color:"white",fontWeight:"bold", marginLeft:"47%", width:"155px", fontSize:"7px"}}>
      {language === 'English' ? `Translations in line with GDPR requirements ` :
       `Traduzioni in linea con quanto richiesto dal GDPR`}
      </Text>
      </View>
      <View style={{display:"inline-flex", flexDirection:"row", width:"100%"}}>
      <Text style={{marginTop:"50px", color:"white",fontWeight:"bold", marginLeft:"10px", width:"155px", fontSize:"7px"}}>
      {language === 'English' ? `Cyber Essential Certificate` :
       `Cyber Essential Certificate`}
      </Text>
      <Text style={{marginTop:"50px", color:"white", fontWeight:"bold", marginLeft:"47%", width:"155px", fontSize:"7px"}}>
      {language === 'English' ? `Translations in line with CCPA requirements` :
       `Traduzioni in linea con quanto richiesto dal CCPA`}
      </Text>
      </View>
     <Image style={{width:"125%", marginLeft:"-75px", marginTop:"-290px", marginBottom:"50px", zIndex:"-99"}}  src="/translationAssets/privacy/background.png"/>      
      
       </View>
    </Page>
  );
};

export default Privacy;
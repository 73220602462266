import React, { useEffect, useState } from 'react';
import TechPortal from 'src/sections/translationClientOutput/components/TechPortal';
import Memory from 'src/sections/translationClientOutput/components/Memory';

import '../../../sections/translationClientOutput/index.css';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { addLanguageAndId } from 'src/redux/slices/proposal';
import MiniLingoyou from 'src/sections/translationClientOutput/components/MiniLingoyou';



export default function Lingoyou() {

const {lang} = useParams()

const dispatch = useDispatch()

useEffect(()=>{
    dispatch(addLanguageAndId({language: lang, id:""}))

}, [1])
  return (
    <>
      <div id="translation-proposal" style={{marginInline: 'auto', boxShadow: '0px 0px 5px 3px rgb(0 0 0 / 40%)' }}>
      
        
              <Memory />
              <TechPortal />
              <MiniLingoyou/>
        
      </div>
     
    </>
  );
}

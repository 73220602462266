import { Grid } from '@mui/material';
import React from 'react';
import styles from '../styles/OurClients.module.css';
import { useSelector } from 'react-redux';

export default function OurClients() {

  const {language} = useSelector((state)=>state.proposal);

    return (
      <div>
        <div className={styles.desktop9Border} />

        <div className={styles.desktop14}>
          <div className={styles.desktop14Child} />
          <img className={styles.unionIcon} alt="" src="/translationAssets/desktop7/union9.svg" />
          <div className={styles.desktop14Item} />
          {/* <div className={styles.div}>15</div> */}
          <div className={styles.clients}>{language === "English" ? "OUR CUSTOMERS" : "I NOSTRI CLIENTI"}</div>
          <div className={styles.text3543Parent}>
            <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{' '}
          </div>
          <Grid container spacing={3} sx={{ marginTop: '105px', zIndex: '99', width: 'auto' }}>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{
                borderRight: '0.5px solid #161F30',
                opacity: 1,
                backgroundColor: 'white',
                zIndex: 99,
                borderBottom: '0.5px solid #161F30',
              }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image1.png) no-repeat center",

                // }}
              >
                <img src="/translationAssets/clients/image1.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{
                borderRight: '0.5px solid #161F30',
                opacity: 1,
                zIndex: 99,
                backgroundColor: 'white',
                borderBottom: '0.5px solid #161F30',
              }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image2.png) no-repeat center",

                // }}
              >
                <img src="/translationAssets/clients/image2.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{
                borderRight: '0.5px solid #161F30',
                backgroundColor: 'white',
                opacity: 1,
                backgroundColor: 'white',
                zIndex: 99,
                borderBottom: '0.5px solid #161F30',
              }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image3.png) no-repeat center",

                // }}
              >
                <img src="/translationAssets/clients/image3.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{backgroundColor:"white", borderRight: '0.5px solid #161F30', opacity: 1, zIndex: 99, borderBottom: '0.5px solid #161F30' }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image4.png) no-repeat center",
                // }}
              >
                <img src="/translationAssets/clients/image4.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{backgroundColor:"white", borderRight: '0.5px solid #161F30', opacity: 1, zIndex: 99, borderBottom: '0.5px solid #161F30' }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image5.png) no-repeat center",
                // }}
              >
                <img src="/translationAssets/clients/image5.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{backgroundColor:"white", borderRight: '0.5px solid #161F30', opacity: 1, zIndex: 99, borderBottom: '0.5px solid #161F30' }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image6.png) no-repeat center",

                // }}
              >
                <img src="/translationAssets/clients/image6.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{backgroundColor:"white", borderRight: '0.5px solid #161F30', opacity: 1, zIndex: 99, borderBottom: '0.5px solid #161F30' }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image7.png) no-repeat center",
                // }}
              >
                <img src="/translationAssets/clients/image7.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{backgroundColor:"white", borderRight: '0.5px solid #161F30', opacity: 1, zIndex: 99, borderBottom: '0.5px solid #161F30' }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image8.png) no-repeat center",
                // }}
              >
                <img src="/translationAssets/clients/image8.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{backgroundColor:"white", borderRight: '0.5px solid #161F30', opacity: 1, zIndex: 99, borderBottom: '0.5px solid #161F30' }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image9.png) no-repeat center",
                // }}
              >
                <img src="/translationAssets/clients/image9.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{backgroundColor:"white", borderRight: '0.5px solid #161F30', opacity: 1, zIndex: 99, borderBottom: '0.5px solid #161F30' }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image10.png) no-repeat center",
                // }}
              >
                <img src="/translationAssets/clients/image10.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{backgroundColor:"white", borderRight: '0.5px solid #161F30', opacity: 1, zIndex: 99, borderBottom: '0.5px solid #161F30' }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image11.png) no-repeat center",

                // }}
              >
                <img src="/translationAssets/clients/image11.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{backgroundColor:"white", borderRight: '0.5px solid #161F30', opacity: 1, zIndex: 99, borderBottom: '0.5px solid #161F30' }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image12.png) no-repeat center",
                // }}
              >
                <img src="/translationAssets/clients/image12.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{backgroundColor:"white", borderRight: '0.5px solid #161F30', opacity: 1, zIndex: 99, borderBottom: '0.5px solid #161F30' }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image13.png) no-repeat center",
                // }}
              >
                <img src="/translationAssets/clients/image13.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{backgroundColor:"white", borderRight: '0.5px solid #161F30', opacity: 1, zIndex: 99, borderBottom: '0.5px solid #161F30' }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image14.png) no-repeat center",
                // }}
              >
                <img src="/translationAssets/clients/image14.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{backgroundColor:"white", borderRight: '0.5px solid #161F30', opacity: 1, zIndex: 99, borderBottom: '0.5px solid #161F30' }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image15.png) no-repeat center",
                // }}
              >
                <img src="/translationAssets/clients/image15.png" />
              </div>
            </Grid>
            <Grid
              item
              xs={6}
              sm={4}
              lg={3}
              sx={{backgroundColor:"white", borderRight: '0.5px solid #161F30', opacity: 1, zIndex: 99, borderBottom: '0.5px solid #161F30' }}
            >
              <div
                className={styles.logos}
                // style={{
                //   background: "url(/translationAssets/clients/image16.png) no-repeat center",
                //   backgroundSize:"cover"
                // }}
              >
                <img src="/translationAssets/clients/image16.png" />
              </div>
            </Grid>
          </Grid>
        </div>
      </div>
    );
  }


import React from 'react';
import styles from '../styles/News2.module.css';
import { MdLocationPin } from 'react-icons/md';
import { useSelector } from 'react-redux';

export default function News2() {

  const { language } = useSelector(state => state.proposal)

  return (
    <div className={styles.desktop18}>
      <div className={styles.mainVector}>
        <div className={styles.vector1}></div>
        <div className={styles.vector2}></div>
        <div className={styles.vector3}></div>
        <div className={styles.vector4}></div>
      </div>
      <div className={styles.desktop18Item} />
      {/* <div className={styles.div}>15</div> */}
      <div className={styles.aboutUs}>
        {language === 'English' ? `EVENTS THROUGHOUT ITALY` : `EVENTI IN TUTTA ITALIA`}
      </div>
      <div className={styles.text3543Parent}>
        <img alt="" src="/assets/desktop5/group-1000001730.svg" />{' '}
      </div>
      <div className={styles.parentDiv}>
        <div className={styles.para}>
          <p>
            {language === 'English'
              ? `We are thrilled to offer complete organization services for prestigious and successful events throughout Italy. Our renowned brand, Congressi Internazionali, is a leader in the organization of events, congresses, trade fairs, corporate meetings and conferences on a global scale. Our fundamental philosophy is to provide customized solutions that fully meet the needs of our customers. We are driven by a passion for acquiring knowledge, constantly seeking innovation and promoting collaboration.`
              : `Siamo entusiasti di offrire servizi completi di organizzazione per eventi prestigiosi e di successo in tutta Italia. Il nostro rinomato brand, Congressi Internazionali, è un leader nel settore dell'organizzazione di eventi, congressi, fiere, riunioni aziendali e convegni su scala globale. La nostra filosofia fondamentale è quella di fornire soluzioni personalizzate che soddisfino appieno le esigenze dei nostri clienti. Siamo mossi dalla passione per l'acquisizione di conoscenze, la costante ricerca di innovazione e la promozione della collaborazione.`}
            <br /><br/>
            {language === 'English'
              ? `During the year 2023, we have had the privilege of successfully organizing more than 500 events across the Italian peninsula, including the Islands. Our commitment to offering services of the highest professionalism and quality extends from North to South, ensuring that our team of technicians and interpreters is ready to serve you in the best locations available in Italy.`
              : `Nel corso dell'anno 2023, abbiamo avuto il privilegio di organizzare con successo più di 500 eventi in tutta la penisola italiana, comprese le isole. Il nostro impegno a offrire servizi di massima professionalità e qualità si estende da Nord a Sud, garantendo che il nostro team di tecnici e interpreti sia pronto a servirvi nelle migliori location disponibili in Italia.             `}
            <br/><br/>
            {language === 'English'
              ? `With a strategic presence in Rome, which allows us to effectively cover Central Italy, the South and the Islands, and a team in Milan dedicated to Northern Italy, we are able to guarantee maximum geographical coverage. This allows us to offer unprecedented access to a wide range of high-level congresses, conferences, and events across the country.`
              : `
              Con una presenza strategica a Roma, che ci permette di coprire con efficacia il Centro Italia, il Sud e le Isole, e un team a Milano che si dedica al Nord Italia, siamo in grado di garantire la massima copertura geografica. Questo ci consente di offrire un accesso senza precedenti a una vasta gamma di congressi, conferenze ed eventi di alto livello in tutto il paese.`}
            <br/><br/>
            {language === 'English'
              ? `Our mission remains steadfast in pursuing the success of our customers. We are constantly committed to providing cutting-edge solutions, excellent customer service and maximum availability both in Italy and abroad. Regardless of the size of the event, whether it is medium or large in scale, we work with dedication to ensure that our clients achieve their goals.`
              : `
              La nostra missione rimane ferma nel perseguire il successo dei nostri clienti. Siamo costantemente impegnati a fornire soluzioni all'avanguardia, un customer service di eccellenza e la massima disponibilità sia in Italia che all'estero. Indipendentemente dalle dimensioni dell'evento, che sia di portata media o grandiosa, lavoriamo con dedizione per garantire che i nostri clienti raggiungano i loro obiettivi.        
          `}
          <br/><br/>
            {language === 'English'
              ? `Our deep-rooted presence from North to South is a tangible reflection of our commitment to fostering innovation and knowledge sharing in every corner of this beautiful nation. We are proud to be the preferred choice when it comes to successful multilingual events and excited to partner with you to create your next extraordinary event`
              : `
              La nostra presenza radicata da nord a sud è un riflesso tangibile del nostro impegno a promuovere l'innovazione e la condivisione delle conoscenze in ogni angolo di questa splendida nazione. Siamo orgogliosi di essere la scelta preferita quando si tratta di eventi multilingue di successo e siamo entusiasti di collaborare con voi per realizzare il vostro prossimo evento straordinario.          `}
          </p>
        </div>
        <div className={styles.map} style={{ background: 'url(/assets/desktop18/map.webp) no-repeat' }}>

          <div className={styles.data1}>
            <span>7%</span>
          </div>
          <div className={styles.data4}>
            <span>30%</span>
          </div>
          <div className={styles.loc1}>
            <MdLocationPin style={{ fontSize: '20px' }} />
            Milan
          </div>
          <div className={styles.data2}>
            <span>3%</span>
          </div>
          <div className={styles.data6}>
            <span>7%</span>
          </div>
          <div className={styles.loc2}>
            <MdLocationPin style={{ fontSize: '18px', minInlineSize: 'maxContent' }} />
            Rome
          </div>
          <div className={styles.data3}>
            <span>40%</span>
          </div>
          <div className={styles.data5}>
            <span>10%</span>
          </div>
          <div className={styles.data7}>
            <span>3%</span>
          </div>
        </div>
      </div>
      
    </div>
  );
}

import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const ContactUs = ({ language }) => {
 
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
    
   {language === "English" ?   <View style={styles.greenImage}>
     <Image style={{...styles.greenImages, width:"30%", marginLeft:"20px", marginBottom:"-160px", marginTop:"30" }}  src="/assets/desktop16/logocongress.png"/>
     <View style={{ paddingTop:"300px", display:"inline-flex", flexDirection:"row", }}>
          <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"30px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Turin
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"63px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Milan
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"63px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Florence
              </Text>
              </View>
          </View>
          <View style={{marginTop:"5px", display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"8px", color:"white", width:"100px", marginLeft:"33px"}}>
          10 Via Confienza, Turin 10121
            </Text>
            <Text style={{fontSize:"8px", color:"white",width:"100px", marginLeft:"15px"}}>
            Viale Fulvio Testi, 59 Milan 20158
            </Text>     
            <Text style={{fontSize:"9px", color:"white", width:"120px", marginLeft:"10px"}}>
            15 Viale Giacomo Matteotti, Florence 50100
            </Text>
          </View>
          <View style={{display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"8px", color:"white", width:"100px", marginLeft:"33px"}}>
          Italy
            </Text>
            <Text style={{fontSize:"8px", color:"white",width:"100px", marginLeft:"15px"}}>
            Italy
            </Text>     
            <Text style={{fontSize:"9px", color:"white", width:"100px", marginLeft:"10px"}}>
            Italy
            </Text>
          </View>
          <View style={{ marginTop:"50px", display:"inline-flex", flexDirection:"row", }}>
          <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"30px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Rome
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"63px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Naples
              </Text>
              </View>
            
          </View>
          <View style={{marginTop:"5px", display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"8px", color:"white", width:"100px", marginLeft:"33px"}}>        
          Via Ruggero D’Altavilla, 12 Rome 00176     
          </Text>
            <Text style={{fontSize:"8px", color:"white",width:"100px", marginLeft:"15px"}}>
            63 via Benedetto Brin, Naples 80100
            </Text>     
            
          </View>
          <View style={{display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"8px", color:"white", width:"100px", marginLeft:"33px"}}>
          Italy            
          </Text>
            <Text style={{fontSize:"8px", color:"white",width:"100px", marginLeft:"15px"}}>
           Italy
            </Text>     
            
          </View>
      <Image style={{width:"100%", objectFit:"cover", marginTop:"-405px", zIndex:"-99", height:"790px"}}  src="/assets/desktop13/Dark Gradient 01.png"/>      
       <View style={styles.greenTextSection}>
       <Image style={{...styles.homeImage, width:"15%",objectFit:'contain', height:"200px", alignItems:"right", marginRight:"0px", marginLeft:"520px"}}  src="/assets/desktop5/group-1000001731.png"/>
       </View>
       <View style={{...styles.discountText, marginTop:"-220px", marginBottom:"30px"}}>
        <Text style={{...styles.discountList, marginBottom:"1px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px", height:"15px", marginLeft:"5px"}}  src="/assets/icons/Phone.png"/>      
 +39 3755806530, +39 0282957099
        </Text>
        <Text style={{...styles.discountList, marginBottom:"1px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px", height:"15px"}}  src="/assets/icons/facebook.png"/>      
         <Link style={{color:"white",paddingBottom:"40px", marginTop:"-20px", marginLeft:"5px"}} href="https://web.facebook.com/congressiint/?_rdc=1&_rdr">https://www.facebook.com/congressiint/</Link>
        </Text>
        <Text style={{...styles.discountList, marginBottom:"10px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px", height:"15px"}}  src="/assets/icons/instagram.png"/>      
        <Link style={{color:"white",paddingBottom:"40px", marginTop:"-20px", marginLeft:"5px"}} href="https://www.instagram.com/congressi_internazionali/">
          https://www.instagram.com/congressi_internazionali/
        </Link>
        </Text>
       </View>
       </View> : 
       <View style={styles.greenImage}>
     <Image style={{...styles.greenImages, width:"30%", marginLeft:"20px", marginBottom:"-160px", marginTop:"30" }}  src="/assets/desktop16/logocongress.png"/>
     <View style={{ paddingTop:"300px", display:"inline-flex", flexDirection:"row", }}>
          <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"30px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Torino
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"63px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Milano
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"63px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Firenze
              </Text>
              </View>
          </View>
          <View style={{marginTop:"5px", display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"8px", color:"white", width:"100px", marginLeft:"33px"}}>
          10 Via Confienza, Torino 10121
            </Text>
            <Text style={{fontSize:"8px", color:"white",width:"100px", marginLeft:"15px"}}>
            Viale Fulvio Testi, 59 Milano 20158
            </Text>     
            <Text style={{fontSize:"9px", color:"white", width:"120px", marginLeft:"10px"}}>
            15 Viale Giacomo Matteotti, Firenze 50100
            </Text>
          </View>
          <View style={{display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"8px", color:"white", width:"100px", marginLeft:"33px"}}>
          Italia
            </Text>
            <Text style={{fontSize:"8px", color:"white",width:"100px", marginLeft:"15px"}}>
            Italia
            </Text>     
            <Text style={{fontSize:"9px", color:"white", width:"100px", marginLeft:"10px"}}>
            Italia
            </Text>
          </View>
          <View style={{ marginTop:"50px", display:"inline-flex", flexDirection:"row", }}>
          <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"30px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Roma
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"63px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Napoli
              </Text>
              </View>
            
          </View>
          <View style={{marginTop:"5px", display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"8px", color:"white", width:"100px", marginLeft:"33px"}}>
          Via Ruggero D’Altavilla 12 Roma 00176            </Text>
            <Text style={{fontSize:"8px", color:"white",width:"100px", marginLeft:"15px"}}>
            63 via Benedetto Brin, Napoli 80100
            </Text>     
            
          </View>
          <View style={{display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"8px", color:"white", width:"100px", marginLeft:"33px"}}>
          Italia            </Text>
            <Text style={{fontSize:"8px", color:"white",width:"100px", marginLeft:"15px"}}>
           Italia
            </Text>     
            
          </View>
      <Image style={{width:"100%", objectFit:"cover", marginTop:"-405px", zIndex:"-99", height:"790px"}}  src="/assets/desktop13/Dark Gradient 01.png"/>      
       <View style={styles.greenTextSection}>
       <Image style={{...styles.homeImage, width:"15%",objectFit:'contain', height:"200px", alignItems:"right", marginRight:"0px", marginLeft:"520px"}}  src="/assets/desktop5/group-1000001731.png"/>
       </View>
       <View style={{...styles.discountText, marginTop:"-220px", marginBottom:"30px"}}>
        <Text style={{...styles.discountList, marginBottom:"1px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px", height:"15px", marginLeft:"5px"}}  src="/assets/icons/Phone.png"/>      
 +39 3755806530, +39 0282957099
        </Text>
        <Text style={{...styles.discountList, marginBottom:"1px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px", height:"15px"}}  src="/assets/icons/facebook.png"/>      
         <Link style={{color:"white",paddingBottom:"40px", marginTop:"-20px", marginLeft:"5px"}} href="https://web.facebook.com/congressiint/?_rdc=1&_rdr">https://www.facebook.com/congressiint/</Link>
        </Text>
        <Text style={{...styles.discountList, marginBottom:"10px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px", height:"15px"}}  src="/assets/icons/instagram.png"/>      
        <Link style={{color:"white",paddingBottom:"40px", marginTop:"-20px", marginLeft:"5px"}} href="https://www.instagram.com/congressi_internazionali/">
          https://www.instagram.com/congressi_internazionali/
        </Link>
        </Text>
       </View>
       </View>
       }
    </Page>
  );
};

export default ContactUs;
import { Grid } from '@mui/material';
import styles from '../styles/WebConferencing.module.css';
import { useSelector } from 'react-redux';

const WebConferencing = () => {
  const { language } = useSelector((state) => state.proposal);

  
  return (
    <div className={styles.desktop10}>
      <div className={styles.desktop10Child} />
      {/* <div className={styles.div}>06</div> */}
      <div className={styles.quality}>
      {language === 'English'
          ? 'INTEGRATION OF OTHER WEB CONFERENCING SOLUTIONS'
          : 'INTEGRAZIONE ALTRE SOLUZIONI DI WEB CONFERENCING'}
      </div>
      <div className={styles.text3543Parent} style={{marginTop:"-10px"}}>
        <img alt="" src="/rafiky/logo.webp" />{' '}
      </div>
      <div className={styles.detail1}>
        <p>
          {language === 'English'
            ? 'Rafiky is compatible with all Web Conferencing software. So, you can also opt for a hybrid solution, via laptop, tablet or mobile app!'
            : 'Rafiky è compatibile con tutti i software di Web Conferencing. Quindi, puoi anche optare per una soluzione ibrida, tramite laptop, tablet o mobile app!'}
        </p>
        <p>
          {language === 'English'
            ? 'Participants will be able to observe the speaker in the third-party platform while listening to the translation provided by the interpreters on Rafiky.'
            : 'I partecipanti saranno in grado di guardare il relatore nella piattaforma terza pur ascoltando la traduzione fornita dagli interpreti su Rafiky.'}
        </p>
        <p>
          {language === 'English'
            ? 'Most used solution for third-party integrations: Mobile App.'
            : 'Soluzione più utilizzata per integrazioni con terze parti: Mobile App.'}
        </p>
        <p>
          {language === 'English'
            ? 'Just like an audio receiver in an on-site event, participants via the Rafiky app will be able to listen to.'
            : 'Proprio come un ricevitore audio in un evento on-site, i partecipanti, tramite l’app di Rafiky, potranno ascoltare.'}
        </p>
        <p>
          <ul>
            <li>
            {language === 'English'
            ? 'Floor (consisting of third party application audio channeled to our app)'
            : ' Floor (composto dall’audio dell’applicazione terza incanalato sulla nostra app)'}
     
            </li>
            <li>
            {language === 'English'
            ? 'Translation channels'
            : 'Canali di traduzione'}
    
            </li>
          </ul>
        </p>
        
      </div>
      <div className={styles.divider}>
        {language === "English" ? "Events held with:" : "Eventi svolti con:"}
      </div>
      <Grid container spacing={3} className={styles.logos}>
        <Grid item xs={12} sm={6} md={3}>
          <img alt="goto" src="/rafiky/brochure/goto.png" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <img alt="cisco" src="/rafiky/brochure/cisco.png" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <img alt="meet" src="/rafiky/brochure/meet.png" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <img alt="connect" src="/rafiky/brochure/connect.png" style={{ height: '100px' }} />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <img alt="skype" src="/rafiky/brochure/skype.png" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <img alt="whereby" src="/rafiky/brochure/whereby.png" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <img alt="zoom" src="/rafiky/brochure/zoom.png" />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <img alt="teams" src="/rafiky/brochure/teams.png" />
        </Grid>
      </Grid>
    </div>
  );
};

export default WebConferencing;

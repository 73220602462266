import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { useLazyQuery, useQuery, useMutation } from '@apollo/client';
import { TRANSLATION_QUOTE_DETAILS } from 'src/graphQL/queries';
import Translation from 'src/sections/translationClientOutput'
import axios from 'axios'
import { useDispatch, useSelector } from 'react-redux';
import {  addIp, addDetails, addLanguageAndId } from 'src/redux/slices/proposal';


export default function TranslationClientOutput() {

  const {id, lang} = useParams()
  // const [sendNotification] = useMutation(PROPOSAL_NOTIFICATION)
  
   const dispatch = useDispatch()
  //creating function to load ip address from the API
  const getData = async () => {
    const res = await axios.get('https://geolocation-db.com/json/')
    dispatch(addIp(res.data.IPv4))
  }
  const { loading, error, data, refetch }= useQuery(TRANSLATION_QUOTE_DETAILS, {
    variables: { translationOrderId: id },
  });
  console.log({id,data})


  useEffect(()=>{
   async function storeData(){
    getData()
    // if(data && !lang){
    //   await sendNotification({variables:{pageOpeningNotificationId:id}})
    //   .then((res)=>console.log(res?.data))
    // }
    dispatch(addDetails({details: data?.translationOrder, type:"LY"}))
    dispatch(addLanguageAndId({language: lang ? lang : data?.translationOrder?.language, id:id}))
   }
   storeData()
  },[data])
  return (
    <div>
      {data &&  <Translation refetch={refetch} />

}</div>
  )
}

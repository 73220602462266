import { Box, Grid } from "@mui/material";
import React from "react";
import styles from '../styles/CoPerformance.module.css';
import { useSelector } from "react-redux";


export default function CoPerformance() {
  const { language, details} = useSelector(state => state.proposal)
  const {brand} = details;
  return (
    <Box
    sx={{
      display: "flex",
      marginTop: "2px",
    }}
  >
    <div className={styles.desktop12}>
      <div className={styles.desktop12Item} />
      {/* <div className={styles.div}>10</div> */}
      <div className={styles.aboutUs}>COMPANY PERFORMANCE</div>
      <div className={styles.text3543Parent}>
      {brand && brand?.rafiky ? <img  style={{marginTop:"-25px"}}
        alt=""
        src="/rafiky/logo-w.png"
      /> : <img  
        alt=""
        src="/assets/desktop5/group-1000001730.svg"
      />} </div>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={5.5} className={styles.section1}>
        <h1>Company Performance</h1>
           <h2>2023</h2>
        <img   src="/assets/desktop12/dark-Gradient01.webp" className={styles.company}/>
       
            <div className={styles.mainVector1} >
           <div className={styles.vector5} ></div>
           <div className={styles.vector6}></div>
           <div className={styles.vector7}></div>
           <div className={styles.vector8}></div>

            </div>
        </Grid>
      {language === "Italian" ? 
        <Grid item xs={12} lg={6.5}>
           <div style={{display:"flex"}}>
          <div className={styles.mainData1}>
            <div className={styles.data1}>
              <h1>Top 1</h1>
              <p>Leader di crescita del fatturato nel 2022 (373%)</p>
            </div>
            <div className={styles.data1} style={{marginTop:"200px"}}>
              <h1 style={{fontSize:""}}>Top 30</h1>
              <p>Valore di mercato nel settore</p>
            </div>
            <div className={styles.data1} style={{marginTop:"400px"}}>
              <h1>99.1%</h1>
              <p>Client Retention</p>
            </div>
            <div className={styles.data1} style={{marginTop:"600px"}}>
              <h1>4.75</h1>
              <p>Reviews.io</p>
            </div>
          </div>
          <div className={styles.mainData2}>
            <div className={styles.data1}>
              <h1>Top 5</h1>
              <p>	Efficienza nel settore</p>
            </div>
            <div className={styles.data1} style={{marginTop:"200px"}}>
              <h1>+500</h1>
              <p>Eventi svolti nel 2023</p>
            </div>
            <div className={styles.data1} style={{marginTop:"400px"}}>
              <h1>+200</h1>
              <p>Lingue</p>
            </div>
            <div className={styles.data1} style={{marginTop:"600px"}}>
              <h1>50</h1>
              <p>Città italiane</p>
            </div>
          </div>
         </div>
            <div className={styles.mainVector2}>
           <div className={styles.vector1}></div>
           <div className={styles.vector2}></div>
           <div className={styles.vector3}></div>
           <div className={styles.vector4}></div>
           </div>
        </Grid>
     : 
     <Grid item xs={12} lg={6.5}>
         <div style={{display:"flex"}}>
          <div className={styles.mainData1}>
            <div className={styles.data1}>
              <h1>Top 1</h1>
              <p>
              Revenue growth leader in 2022 (373%)
              </p>
            </div>
            <div className={styles.data1} style={{marginTop:"200px"}}>
              <h1 style={{fontSize:""}}>Top 30</h1>
              <p>Market value in the industry</p>
            </div>
            <div className={styles.data1} style={{marginTop:"400px"}}>
              <h1>99.1%</h1>
              <p>Client Retention</p>
            </div>
            <div className={styles.data1} style={{marginTop:"600px"}}>
              <h1>4.75</h1>
              <p>Reviews.io</p>
            </div>
          </div>
          <div className={styles.mainData2}>
            <div className={styles.data1}>
              <h1>Top 5</h1>
              <p>Efficiency in the field</p>
            </div>
            <div className={styles.data1} style={{marginTop:"200px"}}>
              <h1>+500</h1>
              <p>Events in 2023</p>
            </div>
            <div className={styles.data1} style={{marginTop:"400px"}}>
              <h1>+200</h1>
              <p>Languages</p>
            </div>
            <div className={styles.data1} style={{marginTop:"600px"}}>
              <h1>50</h1>
              <p>Italian cities</p>
            </div>
          </div>
         </div>
            <div className={styles.mainVector2}>
           <div className={styles.vector1}></div>
           <div className={styles.vector2}></div>
           <div className={styles.vector3}></div>
           <div className={styles.vector4}></div>
           </div>
        </Grid> 
     }
      </Grid>
     
      </div>
      </Box>
  );
}

import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './globalStyles';

const CustomerGuide = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
       <View style={{ ...styles.header, marginBottom: 0, width: '97%', marginHorizontal: 'auto' }}>
        <View style={{ ...styles.headerItem }}>
          <Text style={{ ...styles.headerItemText, fontSize: '12px' }}>
          {language === "English" ? "CUSTOMER GUIDE - SPEAKER ROLE" : "GUIDA PER IL CLIENTE - RUOLO SPEAKER"}
          </Text>
        </View>
        <View>
          <Image style={{ ...styles.headerRightImg }} src="/rafiky/logo.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
     <View style={{display:"flex", flexDirection:"row"}}>
     <View style={{width:"60%", padding:"10px",
     }}>
       
       <View style={{marginBottom:"15px", marginTop:"15px"}}>
        <Text style={{fontSize:"9px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>1. </Text>
         {language === "English" ? "Open the panel by entering the link that was sent into your browser (preferably Google Chrome)." : 
                "Aprire il pannello inserendo il link che è stato inviato nel browser (preferibilmente Google Chrome)."}
        
               
        </Text>
       </View>
       <View style={{marginBottom:"15px"}}>
        <Text style={{fontSize:"9px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>2. </Text>

         {language === "English" ? `Enter your login credentials (Username and Email) – Click on “Join Meeting".` : 
            `Inserire le credenziali di accesso (Nome Utente & Email) – Cliccare su “Join Meeting".`}
       
        </Text>
       </View>
       <View style={{marginBottom:"15px"}}>
       
        <Text style={{fontSize:"9px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>3. </Text>
         {language === "English" ? "Select audio input devices (headphones, speakers) and audio output devices (microphones). Allow the browser to use the camera and microphone." : 
            " Selezionare i dispositivi audio in entrata (cuffie, altoparlanti) e i dispositivi audio in uscita (microfoni). Consentire al browser di utilizzare la telecamera e il microfono."}
         
        </Text>
       </View>
       <View style={{marginBottom:"15px"}}>
        
        <Text style={{fontSize:"9px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>4. </Text>
         {language === "English" ? "Select the audio channel (language) you want to listen to. It is recommended to change the floor while listening to the translation." : 
"Selezionare il canale audio (lingua) che si desidera ascoltare. Si consiglia di mutare il floor mentre si è in ascolto della traduzione."}
   
        </Text>
       </View>
       <View style={{marginBottom:"15px"}}>
       
        <Text style={{fontSize:"9px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>5. </Text>

         {language === "English" ? "Press the “Raise Hand” button to ask for the floor and speak during the conference." : 
            "Premere il pulsante “Raise Hand” per chiedere la parola e parlare durante la conferenza."}

         
        </Text>
       </View>
       <View style={{marginBottom:"15px"}}>
        
        <Text style={{fontSize:"9px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>6. </Text>
        {language === "English" ? "Use the chat to request technical assistance or to communicate with other participants and interpreters." : 
            "Utilizzare la chat per richiedere assistenza tecnica o per comunicare con il resto dei partecipanti e degli interpreti."}   
        </Text>
       </View>
       <View style={{marginBottom:"5px"}}>
       
        <Text style={{fontSize:"9px", fontWeight:"normal"}}>
        <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>7. </Text>
         {language === "English" ? "To share your screen during the presentation, please click the “Share Screen” button." : 
            " Per condividere lo schermo durante la presentazione, cliccare sul tasto “Share Screen”. "}
         
        </Text>
       </View>
       
       
     </View>
     <View style={{width:"40%"}}>
     <Image style={{width:"90%", marginLeft:"5px", objectFit:"contain", zIndex:"-99",
      border:"9px solid white",borderTopLeftRadius:"20px", borderBottomRightRadius:"20px", 
        marginTop:"100px", marginRight:"40px"}}  src="/rafiky/brochure/customerGuide.png"/>

     </View>
     </View>
     <Image style={{width:"100%",zIndex:"-99",marginTop: '-337px', height: '380px'}}  
     src="/rafiky/brochure/background.png"/>
    
    </Page>
  );
};

export default CustomerGuide;
export const sectors = [
  { code: '1', name: 'Agronomy' },
  { code: '36', name: 'Art' },
  {code: '40', name: 'Automotive'},
  { code: '2', name: 'Communication' },
  { code: '3', name: 'Construction' },
  { code: '4', name: 'Consumer Products' },
  {code: '39', name: 'Cosmetics'},
  { code: '5', name: 'E-Learning' },
  { code: '6', name: 'Education' },
  { code: '7', name: 'Energy' },
  { code: '8', name: 'Engineering' },
  { code: '9', name: 'Entertainment Media' },
  { code: '10', name: 'Environmental' },
  { code: '37', name: 'Event Organiser' },
  { code: '11', name: 'Fashion' },
  { code: '12', name: 'Financial' },
  { code: '13', name: 'Food and Beverage' },
  { code: '14', name: 'Games/Apps' },
  { code: '15', name: 'Human Resources' },
  { code: '16', name: 'Internet' },
  { code: '17', name: 'IT' },
  { code: '18', name: 'Legal' },
  { code: '19', name: 'Leisure' },
  { code: '20', name: 'Life Sciences' },
  { code: '21', name: 'Literature' },
  { code: '22', name: 'Logistics' },
  { code: '23', name: 'Manufacturing' },
  { code: '24', name: 'Marketing' },
  { code: '25', name: 'Medical Areas' },
  { code: '26', name: 'Medical Devices' },
  { code: '25', name: 'Medical Materials' },
  { code: '27', name: 'Not-for-Profit' },
  { code: '28', name: 'Patents' },
  { code: '29', name: 'Public Sector' },
  { code: '38', name: 'Religious' },
  { code: '30', name: 'Retail' },
  { code: '31', name: 'Sports' },
  { code: '32', name: 'Support Services' },
  { code: '33', name: 'Technical' },
  { code: '34', name: 'Technology' },
  { code: '35', name: 'Transport' },
];

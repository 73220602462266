import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const AboutUs1 = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
         <View style={{ ...styles.header, marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
        <Text style={styles.headerItemText}>{language === 'English' ? `ABOUT US` : `CHI SIAMO`}</Text>
        </View>
         <View>
          <Image style={{...styles.headerRightImg }} src="/translationAssets/desktop6/lingoyou.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
     
      <View style={styles.aboutUs1Body}>
        <Text style={styles.aboutUs1ParaBody}>
       {language === "English" ? `LingoYou was founded to provide accurate translations at affordable prices convenient to customers from all over the world. From the very beginning, we have been a key player in the translation industry all over the world. ` : 
       `LingoYou è stata fondata per fornire traduzioni accurate a prezzi convenienti a clienti da tutto il mondo. Fin dalla nostra istituzione, siamo un attore chiave nel settore della traduzione in più continenti.`}
        </Text>
        <Text style={styles.aboutUs1ParaBody}>
       {language === "English" ? `This is why our mission has always been to break down all language barriers and to guarantee communication and interaction between speakers of different languages.` : 
       `Per questo la nostra mission è sempre stata quella di abbattere ogni barriera linguistica e di garantire la comunicazione e l’interazione tra parlanti di lingue diverse.`}
        </Text>
        <Text style={styles.aboutUs1ParaBody}>
       {language === "English" ? `Our team of experts has been working in the field of translation and interpreting for more than ten years and we believe the time has come to open the doors to innovation. With this goal in mind we have created our own customer portal and platform Rafiky!` :
        `Il nostro Team di esperti lavora nel settore delle traduzioni e dell’interpretariato da più di dieci anni e crediamo che sia arrivato il momento di aprire le porte all’innovazione. Con questo obiettivo abbiamo creato il nostro portale clienti e la piattaforma Rafiky!`}
        </Text>
       
      </View>
      <View style={styles.aboutUs1Footer}>
      <View style={styles.aboutUs1FooterImages1}>
      <Image style={styles.avatars} src="/translationAssets/desktop7/ChiaraMoschitta.png" />
      <Image style={styles.avatars}  src="/translationAssets/desktop7/GiuliaCaravasso.png" />
      <Image style={styles.avatars} src="/translationAssets/desktop7/GiuseppeGagliano.png" />
      <Image style={styles.avatars}  src="/translationAssets/desktop7/MariaSegreto.png" />
       </View>
       <View style={styles.aboutUs1FooterNames1}>
        <Text style={{...styles.aboutUs1FooterName, marginLeft:"45px",}}>
        Chiara Moschitta
        </Text>
        <Text style={{...styles.aboutUs1FooterName, marginLeft:"47px"}}>
        Giulia Caravasso
        </Text>
        <Text style={{...styles.aboutUs1FooterName, marginLeft:"42px"}}>
        Giuseppe Gagliano
        </Text>
        <Text style={{...styles.aboutUs1FooterName, marginLeft:"43px"}}>
        Maria Segreto
        </Text>
       </View>
       <View style={{...styles.aboutUs1FooterJobs1, marginBottom:"8px"}}>
       <Text style={{...styles.aboutUs1FooterJob, marginLeft:"47px", width:"65px"}}>
        Account Manager
        </Text>
        <Text style={{...styles.aboutUs1FooterJob, marginLeft:"72px", width:"65px"}}>
        Account Manager
        </Text>
        <Text style={{...styles.aboutUs1FooterJob, marginLeft:"73px", width:"65px"}}>
        Account Manager 
        </Text>
        <Text style={{...styles.aboutUs1FooterJob, marginLeft:"71px"}}>
        Client Success Manager
        </Text>
       </View>
       <View style={styles.aboutUs1FooterImages2}>
      <Image style={styles.avatars2} src="/translationAssets/desktop7/MiriamFratacci.png" />
      <Image style={styles.avatars2} src="/translationAssets/desktop7/GiuseppePinelli.png" />
      <Image style={styles.avatars2} src="/translationAssets/desktop7/JimPuccio.png" />
      <Image style={styles.avatars2} src="/translationAssets/desktop7/VeronicaGaudesi.png" />
       </View>
       <View style={styles.aboutUs1FooterNames2}>
        <Text style={{...styles.aboutUs1FooterName, marginLeft:"49px",}}>
        Miriam Fratacci
        </Text>
        <Text style={{...styles.aboutUs1FooterName, marginLeft:"56px"}}>
        Giuseppe Pinelli
        </Text>
        <Text style={{...styles.aboutUs1FooterName, marginLeft:"61px"}}>
         Jim Puccio
        </Text>
        <Text style={{...styles.aboutUs1FooterName, marginLeft:"61px"}}>
        Veronica Gaudesi
        </Text>
       </View>
       <View style={styles.aboutUs1FooterJobs2}>
       <Text style={{...styles.aboutUs1FooterJob, marginLeft:"46px", width:"68px"}}>
       
       Translation Project Manager
        </Text>
        <Text style={{...styles.aboutUs1FooterJob, marginLeft:"66px", width:"75px"}}>
        Accountant
        </Text>
        <Text style={{...styles.aboutUs1FooterJob, marginLeft:"65px", width:"75px"}}>
        Graphic Designer
        </Text>
        <Text style={{...styles.aboutUs1FooterJob, marginLeft:"65px", width:"75px"}}>
        Translation Project Manager
        </Text>
       </View>
      </View>
    </Page>
  );
};

export default AboutUs1;
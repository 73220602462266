import React, { useState } from 'react';
import OurClients from 'src/sections/interpretClientOutput/components/OurClients';
import Desktop16 from 'src/sections/interpretClientOutput/components/Desktop16';
import Testimonials from 'src/sections/interpretClientOutput/components/Testimonials';
import Discount from './components/Discount';
import CoPerformance from 'src/sections/interpretClientOutput/components/CoPerformance';
import Interpreting from 'src/sections/interpretClientOutput/components/Interpreting';
import AboutUs1 from 'src/sections/interpretClientOutput/components/AboutUs1';
import Introduction from './components/Introduction';
import Home from './components/Home';
import News from 'src/sections/interpretClientOutput/components/News';
import News2 from 'src/sections/interpretClientOutput/components/News2';
import Contact from './components/Contact';
import TechnicalEquipment from 'src/sections/interpretClientOutput/components/TechnicalEquipment';
import './index.css';
import AboutUs2 from 'src/sections/interpretClientOutput/components/AboutUs2';
import BottomNavigation from '@mui/material/BottomNavigation';
import { RiFileDownloadFill } from 'react-icons/ri';
import Paper from '@mui/material/Paper';
import { Box, Button, CircularProgress } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDoc from './PDFDoc/PDFDoc';
import { useDispatch, useSelector } from 'react-redux';
import SectorClients from './components/SectorClients';

export default function InterpretClient() {

  const {details, language } = useSelector(state => state.proposal)


 

  return (
    <>
      <div style={{ marginInline: 'auto', maxWidth: '1600px', boxShadow: '0px 0px 5px 3px rgb(0 0 0 / 40%)' }}>
          <div id="home">
            <Home  />
          </div>
          <div>
            <div  id="intro">
              <Introduction />
            </div>
            <div>
              <SectorClients/>
            </div>
            <div>
              <AboutUs1 />
            </div>
            <div>
              <AboutUs2  />
            </div>
          </div>
            <div>
              <CoPerformance  />
            </div>
            <div id="map">
              <News2 />
            </div>
            <div>
              <Interpreting  />
            </div>
            <div>
              <TechnicalEquipment  />
            </div>
            <div>
              <OurClients />
            </div>
            <div>
              <Testimonials  />
            </div>
            <div id="discount">
              <Discount  />
            </div>
            <div id="planet">
              <Desktop16  />
            </div>
            <div id="blogs">
              <News  />
            </div>
            
          <div id="contact">
            <Contact />
          </div>
      </div>
      <Paper
        sx={{ position: 'fixed',zIndex:99999999999, height: '90px', bottom: 0, left: 0, right: 0, backgroundColor: '#39065F' }}
        elevation={3}
      >
        <BottomNavigation
          sx={{ backgroundColor: '#39065F', justifyContent: 'end', margin: '10px' }}
          showLabels
          // value={value}
        >
          <PDFDownloadLink fileName={language==="English" ? `BROCHURE`: 'BROCHURE' } style={{fontSize:"17px", pointerEvents:"cursor", borderRadius:"5px", backgroundColor: 'white', color: 'grey', width: '210px', height: '70px', marginRight: '20px' }}
   document={<PDFDoc language={language} details={details} />}>
                    {({ loading }) => (
            <>
            {loading ? <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: "14px" }}>
        <CircularProgress />
      </Box> :  <Button disabled={loading && true} sx={{ fontSize: "unset", marginTop: "14px" }}>
              <RiFileDownloadFill style={{ color:`${loading ? 'grey': '#ff0c00b3'}`, marginRight: '2px', height: '30px', width: '45px' }} />{' '}
              {language === "English" ? 'DOWNLOAD PDF' : ' SCARICA PDF'}
            </Button>
            }
            </>  
          )}
        </PDFDownloadLink> 

        </BottomNavigation>
      </Paper>
    </>
  );
}

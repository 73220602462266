import React from 'react'
import styles1 from '../../styles/TablesItems.module.css';
import Scrollbar from '../Scrollbar';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

export default function Table1() {
 
    const {language, details} = useSelector((state)=>state.proposal);

    const {
        accountManager,
        companyNameAndId,
        bookedBy
    } = details
  return (
    <div className={styles1.table1}>
    {/* <Card sx={{ display: 'contents' }}> */}
    <Scrollbar>
      <TableContainer sx={{ marginLeft: '2%', width: '95%' }}>
        <Table sx={{ width: '100%' }}>
          <TableHead
            sx={{
              height: '80px',
              borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
              '& th': { backgroundColor: '#2fcbe9' },
              '& .css-7amdnm-MuiTableCell-root:first-of-type': {
                boxShadow: 'none',
              },
              '& .css-7amdnm-MuiTableCell-root:last-of-type': {
                boxShadow: 'none',
              },
            }}
          >
            <TableRow>
              <TableCell
                sx={{
                  backgroundColor: '#2fcbe9',
                  width: '50%',
                  color: 'white',
                  fontSize: 'large',
                  boxShadow: 'none',
                }}
                align="left"
              >
                {language === 'English' ? `Customer Billing Data` : `Dati Fatturazione Cliente`}
              </TableCell>
              <TableCell
                sx={{
                  backgroundColor: '#2fcbe9',
                  width: '50%',
                  color: 'white',
                  fontSize: 'large',
                  boxShadow: 'none',
                }}
                align="left"
              >
                {accountManager?.jobTitle}{' '}
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            <TableRow>
              <TableCell
                sx={{
                  '.css-4dwhzq-MuiTableCell-root': {
                    marginBottom: 'none',
                  },
                  borderBottom: 'none',
                  maxWidth: '200px',
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: 'large',
                }}
                align="left"
              >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                  >
                    {companyNameAndId?.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                  >
                    {bookedBy?.firstName} {bookedBy?.lastName}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                  >
                    <b>{language === 'English' ? `Address:` : `Indirizzo:`}</b>{' '}
                    {companyNameAndId?.address1 && (
                      <>
                        {companyNameAndId?.address1}
                        <br />
                      </>
                    )}
                    {companyNameAndId?.address2 && (
                      <>
                        &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                        {companyNameAndId?.address2}
                        <br />
                      </>
                    )}
                    &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
                    {companyNameAndId?.postcode} {companyNameAndId?.city}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                  >
                    <b>P. IVA: </b> {companyNameAndId?.VAT}
                  </Typography>
                </Box>
              </TableCell>
              <TableCell
                sx={{
                  '.css-4dwhzq-MuiTableCell-root': {
                    marginBottom: 'none',
                  },
                  borderBottom: 'none',
                  maxWidth: '200px',
                  fontFamily: "'Poppins', sans-serif",
                  fontSize: 'large',
                }}
                align="left"
              >
                <Box>
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                  >
                    {accountManager?.name}
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                  >
                    E-mail: info@lingoyou.com
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                  >
                    Telefono:(+39) <b>0282957101</b>
                  </Typography>
                  <Typography
                    variant="body2"
                    sx={{ color: 'text.secondary', fontFamily: "'Poppins', sans-serif", fontSize: 'large' }}
                  >
                    (+39) 3757441330
                  </Typography>
                </Box>
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </TableContainer>
    </Scrollbar>
    {/* </Card> */}
  </div>
  )
}

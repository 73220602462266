export const SectorsContent = {
    'Financial':{
        en:{
            title: "Financial Translation Services - Your Linguistic Guide!",
            para1: "LingoYou is recognized in the industry for the excellence of its financial translations, encompassing a wide range of vital documents for financial institutions. Among the documents we translate with precision and expertise are:",
            listItem1Title: "Balance Sheets: ",
            listItem1Para: "We translate company balance sheets, including consolidated balance sheets, statement of financial position, and income statement, ensuring consistency and accuracy in financial data.",
            listItem2Title: "Financial Reports: ",
            listItem2Para: "Annual, quarterly, or monthly financial reports are carefully translated to maintain clarity of the data and analysis provided to investors and stakeholders.",
            listItem3Title: "Information Memoranda: ",
            listItem3Para: "We translate information memoranda of mutual funds, ETFs, and other investment vehicles, ensuring an accurate understanding of risks and opportunities for investors.",
            listItem4Title: "Investment Contracts: ",
            listItem4Para: "We translate investment contracts, merger and acquisition agreements, and other legal documents governing complex financial transactions.",
            listItem5Title: "Legal Documentation: ",
            listItem5Para: "We handle the translation of legal documents such as articles of association, company incorporation documents, and legal documents related to the financial sector.",
            para2: "Our team of experienced translators and industry professionals in the financial sector ensures that every translation is accurate, consistent, and meets the highest standards of regulatory compliance. With LingoYou, you can rely on reliable translations that facilitate communication and international cooperation in the financial sector.",
            para2Pdf: "Our team of expert translators and financial industry professionals ensures that each translation is accurate, consistent, and adheres to the highest standards of regulatory compliance."   
        },
        it:{
            title:`Servizi di Traduzione Finanziaria - La Vostra Guida Linguistica!`,
            para1:`L'azienda LingoYou è riconosciuta nel settore per l'eccellenza delle sue traduzioni finanziarie, abbracciando una vasta gamma di documenti vitali per le istituzioni finanziarie. Tra i documenti che traduciamo con precisione e competenza ci sono:`,
            listItem1Title:'Bilanci: ',
            listItem1Para:`Traduciamo bilanci aziendali, includendo bilanci consolidati, stato patrimoniale e conto economico, assicurando coerenza e precisione nei dati finanziari.`,
            listItem2Title:`Relazioni Finanziarie: `,
            listItem2Para:`Le relazioni finanziarie annue, trimestrali o mensili sono tradotte con attenzione per mantenere la chiarezza dei dati e delle analisi fornite agli investitori e agli stakeholder.`,
            listItem3Title:`Prospetti Informativi: `,
            listItem3Para:`Traduciamo prospetti informativi di fondi comuni di investimento, ETF e altre forme di investimento, garantendo una comprensione accurata dei rischi e delle opportunità per gli investitori.`,
            listItem4Title:`Contratti di Investimento: `,
            listItem4Para:`Traduciamo contratti di investimento, accordi di fusione e acquisizione, e altri documenti legali che regolano transazioni finanziarie complesse.`,
            listItem5Title:`Documentazione Legale: `,
            listItem5Para:`Ci occupiamo della traduzione di documenti legali come statuti sociali, documenti di costituzione di società e documenti giuridici correlati al settore finanziario.`,
            para2:`La nostra squadra di traduttori esperti e professionisti del settore finanziario assicura che ogni traduzione sia accurata, coerente e rispetti i più elevati standard di conformità normativa. Con LingoYou, potete contare su traduzioni affidabili che facilitano la comunicazione e la cooperazione internazionale nel settore finanziario.`,
            para2Pdf:`La nostra squadra di traduttori esperti e professionisti del settore finanziario assicura che ogni traduzione sia accurata, coerente e rispetti i più elevati standard di conformità normativa.`  
        },
        cover: '/clients/finance/coverfinance.webp',
        coverPdf: '/clients/finance/coverfinance.png',
        sector:'/clients/finance/finance.webp',
        sectorPdf:'/clients/finance/finance.png'
    },
    'Medical Areas':{
        en:{
            title: "Medical Sector Translations - Your Trusted Linguistic Partner",
            para1: "LingoYou is a cornerstone in the field of medical translations, providing high-quality translation services for a wide range of vital documents in the healthcare field. Among the documents we translate with competence and precision are:",
            listItem1Title: "Medical Reports: ",
            listItem1Para: "We translate medical reports, including diagnostic reports, laboratory reports, and surgical reports, ensuring an accurate understanding of medical conditions and treatments.",
            listItem2Title: "Clinical Documentation: ",
            listItem2Para: "Our team translates clinical documentation, such as medical records, discharge summaries, and visit reports, to facilitate communication among healthcare professionals and ensure effective care.",
            listItem3Title: "Medical Device Manuals: ",
            listItem3Para: "We translate instruction manuals and technical documentation for medical devices, ensuring that users can safely and correctly use such devices.",
            listItem4Title: "Clinical Studies and Research: ",
            listItem4Para: "We translate clinical studies, scientific publications, and research documents, maintaining the integrity of data and discoveries in the medical field.",
            listItem5Title: "Drug and Healthcare Product Registrations: ",
            listItem5Para: "We translate drug labels, package inserts, and documentation related to the registration of drugs and healthcare products to ensure the safety and efficacy of treatments.",
            para2: "Our team of translators specialized in the medical sector understands complex medical terminology and is committed to ensuring accuracy and consistency in every translation. With LingoYou, you can rely on reliable translations that facilitate communication between healthcare professionals and patients worldwide, promoting better global healthcare.",
            para2Pdf: "Our team of translators specialized in the medical sector understands complex medical terminology and is committed to ensuring accuracy and consistency in every translation."   
        },
        it:{
            title:`Traduzioni nel Settore Medico - Il Vostro Partner Linguistico di Fiducia`,
            para1:`L'azienda LingoYou è un pilastro nel settore delle traduzioni mediche, fornendo servizi di traduzione di alta qualità per una vasta gamma di documenti vitali nel campo della sanità. Tra i documenti che traduciamo con competenza e precisione, vi sono:`,
            listItem1Title:'Rapporti Medici: ',
            listItem1Para:`Traduciamo rapporti medici, compresi referti diagnostici, rapporti di laboratorio, e relazioni chirurgiche, garantendo una comprensione accurata delle condizioni mediche e dei trattamenti.`,
            listItem2Title:`Documentazione Clinica: `,
            listItem2Para:`La nostra equipe traduce documentazione clinica, come cartelle cliniche, schede di dimissione ospedaliera e rapporti di visita, per agevolare la comunicazione tra i professionisti sanitari e garantire una cura efficace.`,
            listItem3Title:`Manuali di Dispositivi Medici: `,
            listItem3Para:`Traduciamo manuali di istruzioni e documentazione tecnica per dispositivi medici, assicurando che gli utenti possano utilizzare in modo sicuro e corretto tali dispositivi.`,
            listItem4Title:`Studi Clinici e Ricerche: `,
            listItem4Para:`Traduciamo studi clinici, pubblicazioni scientifiche e documenti di ricerca, mantenendo l'integrità dei dati e delle scoperte nel campo medico.`,
            listItem5Title:`Registrazioni di Farmaci e Prodotti Sanitari: `,
            listItem5Para:`Traduciamo etichette di farmaci, fogli illustrativi e documentazione relativa alla registrazione di farmaci e prodotti sanitari per garantire la sicurezza e l'efficacia dei trattamenti.`,
            para2:`La nostra equipe di traduttori specializzati nel settore medico comprende terminologia medica complessa e si impegna per garantire la precisione e la coerenza in ogni traduzione. Con LingoYou, potete affidarvi a traduzioni affidabili che facilitano la comunicazione tra professionisti sanitari e pazienti in tutto il mondo, promuovendo una migliore assistenza sanitaria globale.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore medico comprende terminologia medica complessa e si impegna per garantire la precisione e la coerenza in ogni traduzione.`  
        },
        cover: '/clients/medical/coverMedicalAreas.webp',
        coverPdf: '/clients/medical/coverMedicalAreas.png',
        sector:'/clients/medical/medicalAreas.webp',
        sectorPdf:'/clients/medical/medicalAreas.png'
    },
    'Medical Materials':{
        en:{
            title: "Medical Materials Translations - Your Partner for Linguistic Precision",
            para1: "LingoYou is a cornerstone in the field of medical translations, providing high-quality translation services for a wide range of vital documents in the healthcare field. Among the documents we translate with competence and precision are:",
            listItem1Title: "Medical Reports: ",
            listItem1Para: "We translate medical reports, including diagnostic reports, laboratory reports, and surgical reports, ensuring an accurate understanding of medical conditions and treatments.",
            listItem2Title: "Clinical Documentation: ",
            listItem2Para: "Our team translates clinical documentation, such as medical records, discharge summaries, and visit reports, to facilitate communication among healthcare professionals and ensure effective care.",
            listItem3Title: "Medical Device Manuals: ",
            listItem3Para: "We translate instruction manuals and technical documentation for medical devices, ensuring that users can safely and correctly use such devices.",
            listItem4Title: "Clinical Studies and Research: ",
            listItem4Para: "We translate clinical studies, scientific publications, and research documents, maintaining the integrity of data and discoveries in the medical field.",
            listItem5Title: "Drug and Healthcare Product Registrations: ",
            listItem5Para: "We translate drug labels, package inserts, and documentation related to the registration of drugs and healthcare products to ensure the safety and efficacy of treatments.",
            para2: "Our team of translators specialized in the medical sector understands complex medical terminology and is committed to ensuring accuracy and consistency in every translation. With LingoYou, you can rely on reliable translations that facilitate communication between healthcare professionals and patients worldwide, promoting better global healthcare.",
            para2Pdf: "Our team of translators specialized in the medical sector understands complex medical terminology and is committed to ensuring accuracy and consistency in every translation."  
        },
        it:{
            title:`Traduzioni di Materiali Medici - Il Vostro Partner per la Precisione Linguistica`,
            para1:`L'azienda LingoYou è un pilastro nel settore delle traduzioni mediche, fornendo servizi di traduzione di alta qualità per una vasta gamma di documenti vitali nel campo della sanità. Tra i documenti che traduciamo con competenza e precisione, vi sono:`,
            listItem1Title:'Rapporti Medici: ',
            listItem1Para:`Traduciamo rapporti medici, compresi referti diagnostici, rapporti di laboratorio, e relazioni chirurgiche, garantendo una comprensione accurata delle condizioni mediche e dei trattamenti.`,
            listItem2Title:`Documentazione Clinica: `,
            listItem2Para:`La nostra equipe traduce documentazione clinica, come cartelle cliniche, schede di dimissione ospedaliera e rapporti di visita, per agevolare la comunicazione tra i professionisti sanitari e garantire una cura efficace.`,
            listItem3Title:`Manuali di Dispositivi Medici: `,
            listItem3Para:`Traduciamo manuali di istruzioni e documentazione tecnica per dispositivi medici, assicurando che gli utenti possano utilizzare in modo sicuro e corretto tali dispositivi.`,
            listItem4Title:`Studi Clinici e Ricerche: `,
            listItem4Para:`Traduciamo studi clinici, pubblicazioni scientifiche e documenti di ricerca, mantenendo l'integrità dei dati e delle scoperte nel campo medico.`,
            listItem5Title:`Registrazioni di Farmaci e Prodotti Sanitari: `,
            listItem5Para:`Traduciamo etichette di farmaci, fogli illustrativi e documentazione relativa alla registrazione di farmaci e prodotti sanitari per garantire la sicurezza e l'efficacia dei trattamenti.`,
            para2:`La nostra equipe di traduttori specializzati nel settore medico comprende terminologia medica complessa e si impegna per garantire la precisione e la coerenza in ogni traduzione. Con LingoYou, potete affidarvi a traduzioni affidabili che facilitano la comunicazione tra professionisti sanitari e pazienti in tutto il mondo, promuovendo una migliore assistenza sanitaria globale.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore medico comprende terminologia medica complessa e si impegna per garantire la precisione e la coerenza in ogni traduzione.`    
        },
        cover: '/clients/medical/covermedicalmaterials.webp',
        coverPdf: '/clients/medical/covermedicalmaterials.png',
        sector:'/clients/medical/medicalMaterials.webp',
        sectorPdf:'/clients/medical/medicalMaterials.png'
    },
    'Medical Devices':{
        en:{
            title: "Medical Device Translations - Your Reliable Linguistic Guide",
            para1: "LingoYou is a cornerstone in the field of medical translations, providing high-quality translation services for a wide range of vital documents in the healthcare field. Among the documents we translate with competence and precision are:",
            listItem1Title: "Medical Reports: ",
            listItem1Para: "We translate medical reports, including diagnostic reports, laboratory reports, and surgical reports, ensuring an accurate understanding of medical conditions and treatments.",
            listItem2Title: "Clinical Documentation: ",
            listItem2Para: "Our team translates clinical documentation, such as medical records, discharge summaries, and visit reports, to facilitate communication among healthcare professionals and ensure effective care.",
            listItem3Title: "Medical Device Manuals: ",
            listItem3Para: "We translate instruction manuals and technical documentation for medical devices, ensuring that users can safely and correctly use such devices.",
            listItem4Title: "Clinical Studies and Research: ",
            listItem4Para: "We translate clinical studies, scientific publications, and research documents, maintaining the integrity of data and discoveries in the medical field.",
            listItem5Title: "Drug and Healthcare Product Registrations: ",
            listItem5Para: "We translate drug labels, package inserts, and documentation related to the registration of drugs and healthcare products to ensure the safety and efficacy of treatments.",
            para2: "Our team of translators specialized in the medical sector understands complex medical terminology and is committed to ensuring accuracy and consistency in every translation. With LingoYou, you can rely on reliable translations that facilitate communication between healthcare professionals and patients worldwide, promoting better global healthcare.",
            para2Pdf: "Our team of translators specialized in the medical sector understands complex medical terminology and is committed to ensuring accuracy and consistency in every translation."   
        },
        it:{
            title:`Traduzioni di Dispositivi Medici - La Vostra Guida Linguistica Affidabile Medical`,
            para1:`L'azienda LingoYou è un pilastro nel settore delle traduzioni mediche, fornendo servizi di traduzione di alta qualità per una vasta gamma di documenti vitali nel campo della sanità. Tra i documenti che traduciamo con competenza e precisione, vi sono:`,
            listItem1Title:'Rapporti Medici: ',
            listItem1Para:`Traduciamo rapporti medici, compresi referti diagnostici, rapporti di laboratorio, e relazioni chirurgiche, garantendo una comprensione accurata delle condizioni mediche e dei trattamenti.`,
            listItem2Title:`Documentazione Clinica: `,
            listItem2Para:`La nostra equipe traduce documentazione clinica, come cartelle cliniche, schede di dimissione ospedaliera e rapporti di visita, per agevolare la comunicazione tra i professionisti sanitari e garantire una cura efficace.`,
            listItem3Title:`Manuali di Dispositivi Medici: `,
            listItem3Para:`Traduciamo manuali di istruzioni e documentazione tecnica per dispositivi medici, assicurando che gli utenti possano utilizzare in modo sicuro e corretto tali dispositivi.`,
            listItem4Title:`Studi Clinici e Ricerche: `,
            listItem4Para:`Traduciamo studi clinici, pubblicazioni scientifiche e documenti di ricerca, mantenendo l'integrità dei dati e delle scoperte nel campo medico.`,
            listItem5Title:`Registrazioni di Farmaci e Prodotti Sanitari: `,
            listItem5Para:`Traduciamo etichette di farmaci, fogli illustrativi e documentazione relativa alla registrazione di farmaci e prodotti sanitari per garantire la sicurezza e l'efficacia dei trattamenti.`,
            para2:`La nostra equipe di traduttori specializzati nel settore medico comprende terminologia medica complessa e si impegna per garantire la precisione e la coerenza in ogni traduzione. Con LingoYou, potete affidarvi a traduzioni affidabili che facilitano la comunicazione tra professionisti sanitari e pazienti in tutto il mondo, promuovendo una migliore assistenza sanitaria globale.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore medico comprende terminologia medica complessa e si impegna per garantire la precisione e la coerenza in ogni traduzione.` 
        },
        cover: '/clients/medical/covermedicaldevices.webp',
        coverPdf: '/clients/medical/coverMedicalDevices.png',
        sector:'/clients/medical/medicalDevices.webp',
        sectorPdf:'/clients/medical/medicalDevices.png'
    },
    'Fashion':{
            en:{
                title: "Translation Services for the Fashion Industry - Your Linguistic Partner for Excellence",
        para1: "BeautyLingo is your trusted partner for translation services in the beauty industry, providing top-notch language solutions for various essential documents in this vibrant and innovative field. Here are some examples of documents we expertly translate:",
        listItem1Title: "Product Descriptions: ",
        listItem1Para: "We translate product descriptions, ingredient lists, usage instructions, and safety warnings, ensuring that the details of your beauty products are accurately conveyed to your international clientele.",
        listItem2Title: "Marketing Materials and Packaging: ",
        listItem2Para: "We specialize in translating marketing materials, packaging labels, and promotional content, capturing the essence and allure of your beauty brand to captivate audiences worldwide.",
        listItem3Title: "Website Localization and E-commerce Platforms: ",
        listItem3Para: "We localize websites, e-commerce platforms, and online beauty blogs, providing a seamless browsing and shopping experience for your global customers.",
        listItem4Title: "Regulatory Compliance Documents: ",
        listItem4Para: "We translate regulatory compliance documents, safety data sheets, and product certifications, ensuring that your beauty brand complies with international standards and regulations.",
        para2: "Our team of linguists specializing in the beauty industry understands the nuances of beauty terminology and is dedicated to delivering accurate and culturally appropriate translations. With BeautyLingo, you can expand your brand's reach and appeal to diverse markets worldwide.",
        para2Pdf: "Our team of translators specialized in the fashion sector understands the importance of precision, creativity, and style in fashion texts and is committed to ensuring the consistency and accuracy of translations."   
    },
            it:{
                title:'Servizi di Traduzione per il Settore della Moda - Il Vostro Partner Linguistico di Eccellenza',
                para1:`L'azienda LingoYou è il vostro punto di riferimento nel settore delle traduzioni per la moda, offrendo servizi linguistici di alta qualità per una vasta gamma di documenti essenziali in questo settore dinamico e creativo. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
                listItem1Title:'Descrizioni dei Prodotti: ',
                listItem1Para:`Traduciamo descrizioni dei prodotti, specifiche di tessuto, misure e istruzioni per il lavaggio, garantendo che i dettagli dei vostri capi di abbigliamento e accessori siano accuratamente comunicati ai vostri clienti internazionali.`,
                listItem2Title:`Cataloghi e Brochure: `,
                listItem2Para:`Ci occupiamo della traduzione di cataloghi, brochure e materiale promozionale, catturando lo stile e l'essenza del vostro brand per attrarre clienti in tutto il mondo.`,
                listItem3Title:`Siti Web e Piattaforme di E-commerce: `,
                listItem3Para:`Traduciamo siti web, piattaforme di e-commerce e contenuti online, creando un'esperienza di shopping senza soluzione di continuità per i vostri clienti globali.`,
                listItem4Title:`Contratti di Licenza e Distribuzione: `,
                listItem4Para:`Traduciamo contratti di licenza, accordi di distribuzione e documenti legali relativi alla gestione dei vostri rapporti commerciali internazionali nel settore della moda.`,
                listItem5Title:`Comunicati Stampa e Articoli di Moda: `,
                listItem5Para:`Traduciamo comunicati stampa, articoli di moda e interviste con designer, fornendo una traduzione fluente e accattivante per promuovere la vostra marca e le vostre collezioni a livello globale.`,
                para2:`La nostra equipe di traduttori specializzati nel settore della moda comprende l'importanza della precisione, della creatività e dello stile nei testi di moda e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che riflettono l'eleganza e il glamour del vostro brand, consentendovi di raggiungere nuovi mercati e conquistare nuovi clienti in tutto il mondo.`,
                para2Pdf:`La nostra equipe di traduttori specializzati nel settore della moda comprende l'importanza della precisione, della creatività e dello stile nei testi di moda e si impegna per garantire la coerenza e l'accuratezza delle traduzioni.`           
            },
            cover: '/clients/fashion/coverfashion.webp',
            coverPdf: '/clients/fashion/coverfashion.png',
            sector:'/clients/fashion/fashion.webp',
            sectorPdf:'/clients/fashion/fashion.png'        
        },
    'Transport':{
        en:{
            title: "Translations in the Transport and Automotive Sector - Your Reliable Guide",
            para1: "LingoYou is a reference point in the field of translations for transportation and automotive, offering high-quality language services for a wide range of essential documents in this dynamic sector. Here are some examples of documents we translate with competence and precision:",
            listItem1Title: "User Manuals and Maintenance Manuals: ",
            listItem1Para: "We translate user manuals and maintenance manuals for vehicles and equipment, ensuring that users fully understand the instructions for safe and proper use of the products.",
            listItem2Title: "Technical Specifications: ",
            listItem2Para: "We translate technical specifications for vehicles, components, and systems, providing a clear understanding of product features and performance.",
            listItem3Title: "Compliance Documentation and Certificates: ",
            listItem3Para: "We handle the translation of regulatory compliance documents, approval certificates, and documentation related to quality and safety standards in the automotive industry.",
            listItem4Title: "Purchase and Supply Contracts: ",
            listItem4Para: "We translate purchase contracts, supply agreements, and related legal documentation for transactions in the transportation and automotive sector, ensuring clarity and precision of contractual terms.",
            listItem5Title: "Technical Service Manuals: ",
            listItem5Para: "We translate technical service manuals for mechanics and service operators, providing detailed instructions for vehicle and equipment maintenance and repair.",
            para2: "Our team of translators specialized in the transportation and automotive sector understands specific technical terminology and is committed to ensuring the consistency and accuracy of translations. With LingoYou, you can rely on reliable translations that support effective communication and international cooperation in the transportation and automotive sector, promoting innovation and growth in the industry.",
            para2Pdf: "Our team of translators specialized in the transportation and automotive sector understands specific technical terminology and is committed to ensuring the consistency and accuracy of translations."
            
        },
        it:{
            title:`Traduzioni nel Settore Trasporti e Automotive - Il Vostro Guida Affidabile`,
            para1:`L'azienda LingoYou è un punto di riferimento nel settore delle traduzioni per i trasporti e l'automotive, offrendo servizi linguistici di alta qualità per una vasta gamma di documenti essenziali in questo settore dinamico. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:'Manuali di Utilizzo e Manutenzione: ',
            listItem1Para:`Traduciamo manuali di utilizzo e manutenzione per veicoli e attrezzature, garantendo che gli utenti comprendano completamente le istruzioni per l'uso sicuro e corretto dei prodotti.`,
            listItem2Title:`Specifiche Tecniche: `,
            listItem2Para:`Traduciamo specifiche tecniche per veicoli, componenti e sistemi, fornendo una chiara comprensione delle caratteristiche e delle prestazioni dei prodotti.`,
            listItem3Title:`Documentazione di Conformità e Certificati: `,
            listItem3Para:`Ci occupiamo della traduzione di documenti di conformità normativa, certificati di omologazione e documentazione relativa agli standard di qualità e sicurezza nel settore dell'automotive.`,
            listItem4Title:`Contratti di Acquisto e Fornitura: `,
            listItem4Para:`Traduciamo contratti di acquisto, accordi di fornitura e documentazione legale correlata alle transazioni nel settore dei trasporti e dell'automotive, garantendo la chiarezza e la precisione dei termini contrattuali.`,
            listItem5Title:`Manuali di Assistenza Tecnica: `,
            listItem5Para:`Traduciamo manuali di assistenza tecnica per meccanici e operatori di servizio, fornendo istruzioni dettagliate per la manutenzione e la riparazione di veicoli e apparecchiature.`,
            para2:`La nostra equipe di traduttori specializzati nel settore dei trasporti e dell'automotive comprende la terminologia tecnica specifica e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che supportano la comunicazione efficace e la cooperazione internazionale nel settore dei trasporti e dell'automotive, promuovendo l'innovazione e la crescita nel settore.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore dei trasporti e dell'automotive comprende la terminologia tecnica specifica e si impegna per garantire la coerenza e l'accuratezza delle traduzioni`   
        },
        cover: '/clients/transport/covertransport.webp',
        coverPdf: '/clients/transport/covertransport.png',
        sector:'/clients/transport/transport.webp',
        sectorPdf:'/clients/transport/transport.png'
    },
    'Logistics':{
        en:{
            title: "Translations in the Logistics Sector - Your Reliable Linguistic Partner",
            para1: "LingoYou is a reference point in the field of translations for transportation and automotive, offering high-quality language services for a wide range of essential documents in this dynamic sector. Here are some examples of documents we translate with competence and precision:",
            listItem1Title: "User Manuals and Maintenance Manuals: ",
            listItem1Para: "We translate user manuals and maintenance manuals for vehicles and equipment, ensuring that users fully understand the instructions for safe and proper use of the products.",
            listItem2Title: "Technical Specifications: ",
            listItem2Para: "We translate technical specifications for vehicles, components, and systems, providing a clear understanding of product features and performance.",
            listItem3Title: "Compliance Documentation and Certificates: ",
            listItem3Para: "We handle the translation of regulatory compliance documents, approval certificates, and documentation related to quality and safety standards in the automotive industry.",
            listItem4Title: "Purchase and Supply Contracts: ",
            listItem4Para: "We translate purchase contracts, supply agreements, and related legal documentation for transactions in the transportation and automotive sector, ensuring clarity and precision of contractual terms.",
            listItem5Title: "Technical Service Manuals: ",
            listItem5Para: "We translate technical service manuals for mechanics and service operators, providing detailed instructions for vehicle and equipment maintenance and repair.",
            para2: "Our team of translators specialized in the transportation and automotive sector understands specific technical terminology and is committed to ensuring the consistency and accuracy of translations. With LingoYou, you can rely on reliable translations that support effective communication and international cooperation in the transportation and automotive sector, promoting innovation and growth in the industry.",
            para2Pdf: "Our team of translators specialized in the transportation and automotive sector understands specific technical terminology and is committed to ensuring the consistency and accuracy of translations."
        },
        it:{
            title:`Traduzioni nel Settore della Logistica - Il Vostro Partner Linguistico Affidabile `,
            para1:`L'azienda LingoYou è un punto di riferimento nel settore delle traduzioni per i trasporti e l'automotive, offrendo servizi linguistici di alta qualità per una vasta gamma di documenti essenziali in questo settore dinamico. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:'Manuali di Utilizzo e Manutenzione: ',
            listItem1Para:`Traduciamo manuali di utilizzo e manutenzione per veicoli e attrezzature, garantendo che gli utenti comprendano completamente le istruzioni per l'uso sicuro e corretto dei prodotti.`,
            listItem2Title:`Specifiche Tecniche: `,
            listItem2Para:`Traduciamo specifiche tecniche per veicoli, componenti e sistemi, fornendo una chiara comprensione delle caratteristiche e delle prestazioni dei prodotti.`,
            listItem3Title:`Documentazione di Conformità e Certificati: `,
            listItem3Para:`Ci occupiamo della traduzione di documenti di conformità normativa, certificati di omologazione e documentazione relativa agli standard di qualità e sicurezza nel settore dell'automotive.`,
            listItem4Title:`Contratti di Acquisto e Fornitura: `,
            listItem4Para:`Traduciamo contratti di acquisto, accordi di fornitura e documentazione legale correlata alle transazioni nel settore dei trasporti e dell'automotive, garantendo la chiarezza e la precisione dei termini contrattuali.`,
            listItem5Title:`Manuali di Assistenza Tecnica: `,
            listItem5Para:`Traduciamo manuali di assistenza tecnica per meccanici e operatori di servizio, fornendo istruzioni dettagliate per la manutenzione e la riparazione di veicoli e apparecchiature.`,
            para2:`La nostra equipe di traduttori specializzati nel settore dei trasporti e dell'automotive comprende la terminologia tecnica specifica e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che supportano la comunicazione efficace e la cooperazione internazionale nel settore dei trasporti e dell'automotive, promuovendo l'innovazione e la crescita nel settore.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore dei trasporti e dell'automotive comprende la terminologia tecnica specifica e si impegna per garantire la coerenza e l'accuratezza delle traduzioni.`
        },
        cover: '/clients/transport/covertransport.webp',
        coverPdf: '/clients/transport/covertransport.png',
        sector:'/clients/transport/transport.webp',
        sectorPdf:'/clients/transport/transport.png'
    },
    'Not-for-Profit':{
        en:{
            title: "Translations in the Nonprofit Sector - Your Beacon of Linguistic Solidarity",
            para1: "LingoYou is a beacon of solidarity in the nonprofit translation sector, committed to providing high-quality language services to support nonprofit organizations in their important work. Here are some examples of documents we translate with competence and precision:",
            listItem1Title: "Annual Reports: ",
            listItem1Para: "We translate annual reports and financial statements of nonprofit organizations, ensuring an accurate understanding of activities conducted and results achieved.",
            listItem2Title: "Project Proposals: ",
            listItem2Para: "We handle the translation of project proposals and related documentation for funding and grants, helping nonprofit organizations effectively communicate with donors and funders.",
            listItem3Title: "Awareness Material: ",
            listItem3Para: "We translate awareness material, such as flyers, brochures, and websites, to promote the causes supported by nonprofit organizations locally and globally.",
            listItem4Title: "Bylaws and Legal Documents: ",
            listItem4Para: "We translate bylaws, internal regulations, and other legal documents of nonprofit organizations, ensuring regulatory compliance and clarity of texts.",
            listItem5Title: "Monitoring and Evaluation Reports: ",
            listItem5Para: "We translate monitoring and evaluation reports of nonprofit projects, providing a detailed assessment of the impact of activities carried out.",
            para2: "Our team of translators specialized in the nonprofit sector understands the complexity of social issues addressed by these organizations and is committed to ensuring consistency and accuracy in translations. With LingoYou, you can rely on reliable translations that support effective communication and the success of nonprofit initiatives, promoting positive change in communities worldwide.",
            para2Pdf: "Our team of translators specialized in the nonprofit sector understands the complexity of social issues addressed by these organizations and is committed to ensuring the consistency and accuracy of translations."    
        },
        it:{
            title:`Traduzioni nel Settore Non Profit - Il Vostro Faro di Solidarietà Linguistica`,
            para1:`L'azienda LingoYou è un faro di solidarietà nel settore delle traduzioni non profit, impegnata a fornire servizi linguistici di alta qualità per sostenere le organizzazioni senza fini di lucro nel loro importante lavoro. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:'Relazioni Annuali: ',
            listItem1Para:`Traduciamo relazioni annuali e bilanci delle organizzazioni non profit, garantendo una comprensione accurata delle attività svolte e dei risultati raggiunti.`,
            listItem2Title:`Proposte di Progetto: `,
            listItem2Para:`Ci occupiamo della traduzione di proposte di progetto e documentazione correlata per finanziamenti e sovvenzioni, aiutando le organizzazioni non profit a comunicare in modo efficace con donatori e finanziatori.`,
            listItem3Title:`Materiale di Sensibilizzazione: `,
            listItem3Para:`Traduciamo materiale di sensibilizzazione, come volantini, brochure e siti web, per promuovere le cause sostenute dalle organizzazioni non profit a livello locale e globale.`,
            listItem4Title:`Statuti e Documenti Legali: `,
            listItem4Para:`Traduciamo statuti, regolamenti interni e altri documenti legali delle organizzazioni non profit, garantendo la conformità normativa e la chiarezza dei testi.`,
            listItem5Title:`Rapporti di Monitoraggio e Valutazione: `,
            listItem5Para:`Traduciamo rapporti di monitoraggio e valutazione dei progetti non profit, fornendo una valutazione dettagliata dell'impatto delle attività svolte.`,
            para2:`La nostra equipe di traduttori specializzati nel settore non profit comprende la complessità delle questioni sociali affrontate da queste organizzazioni e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che sostengono la comunicazione efficace e il successo delle iniziative non profit, promuovendo il cambiamento positivo nelle comunità in tutto il mondo.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore non profit comprende la complessità delle questioni sociali affrontate da queste organizzazioni e si impegna per garantire la coerenza e l'accuratezza delle traduzioni.`   
        },
        cover: '/clients/notForProfit/covernotforprofit.webp',
        coverPdf: '/clients/notForProfit/covernotforprofit.png',
        sector:'/clients/notForProfit/notforprofit.webp',
        sectorPdf:'/clients/notForProfit/notforprofit.png'
    },
    'Art':{
        en:{
            title: "Translations in the Art Sector - Your Trusted Linguistic Curator",
            para1: "LingoYou is your reference point in the field of translations for art, offering high-quality language services for a wide range of essential documents in this creative and stimulating world. Here are some examples of documents we translate with competence and precision:",
            listItem1Title: "Artwork Descriptions: ",
            listItem1Para: "We translate detailed descriptions of artworks, artist biographies, and informational cards for exhibitions and art galleries, ensuring an accurate and appreciative understanding of the meaning and aesthetics of the work.",
            listItem2Title: "Catalogs and Monographs: ",
            listItem2Para: "We handle the translation of exhibition catalogs, artist monographs, and specialized publications on art, maintaining the elegance and style of the original text.",
            listItem3Title: "Exhibition Texts: ",
            listItem3Para: "We translate exhibition texts, captions, and informational panels for art exhibitions, providing engaging and captivating narration for international viewers.",
            listItem4Title: "Legal Documentation: ",
            listItem4Para: "We translate purchase contracts, loan agreements, provenance statements, and other legal documents in the art sector, ensuring clarity and precision of contractual terms.",
            listItem5Title: "Press Releases and Marketing Communications: ",
            listItem5Para: "We translate press releases, marketing communications, and promotional materials for artistic events and art galleries, capturing the essence and atmosphere of the featured works.",
            para2: "Our team of translators specialized in the art sector understands the aesthetic and cultural sensitivity required to effectively communicate in the art world and is committed to ensuring consistency and accuracy in translations. With LingoYou, you can rely on reliable translations that enhance and promote art and culture worldwide, allowing for broader dissemination of your works and artistic events.",
            para2Pdf: "Our team of translators specialized in the art sector understands the aesthetic and cultural sensitivity necessary to communicate effectively in the world of art and is committed to ensuring the consistency and accuracy of translations."
        },
        it:{
            title:`Traduzioni nel Settore dell'Arte - Il Vostro Curatore Linguistico di Fiducia `,
            para1:`L'azienda LingoYou è il vostro punto di riferimento nel settore delle traduzioni per l'arte, offrendo servizi linguistici di alta qualità per una vasta gamma di documenti essenziali in questo mondo creativo e stimolante. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Descrizioni delle Opere d'Arte: `,
            listItem1Para:`Traduciamo descrizioni dettagliate di opere d'arte, biografie degli artisti e schede informative per mostre e gallerie d'arte, garantendo una comprensione accurata e apprezzativa del significato e dell'estetica dell'opera.`,
            listItem2Title:`Cataloghi e Monografie: `,
            listItem2Para:`Ci occupiamo della traduzione di cataloghi di mostre, monografie su artisti e pubblicazioni specializzate sull'arte, mantenendo l'eleganza e lo stile del testo originale.`,
            listItem3Title:`Testi Espositivi: `,
            listItem3Para:`Traduciamo testi espositivi, didascalie e pannelli informativi per mostre d'arte, offrendo una narrazione coinvolgente e accattivante per gli spettatori internazionali.`,
            listItem4Title:`Documentazione Legale: `,
            listItem4Para:`Traduciamo contratti d'acquisto, accordi di prestito, dichiarazioni di provenienza e altri documenti legali nel settore dell'arte, garantendo la chiarezza e la precisione dei termini contrattuali.`,
            listItem5Title:`Comunicati Stampa e Comunicazioni di Marketing: `,
            listItem5Para:`Traduciamo comunicati stampa, comunicazioni di marketing e materiali promozionali per eventi artistici e gallerie d'arte, catturando l'essenza e l'atmosfera delle opere presentate.`,
            para2:`La nostra equipe di traduttori specializzati nel settore dell'arte comprende la sensibilità estetica e culturale necessaria per comunicare in modo efficace nel mondo dell'arte e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che valorizzano e promuovono l'arte e la cultura in tutto il mondo, consentendo una più ampia diffusione delle vostre opere e dei vostri eventi artistici.`,
            para2Pdf:` La nostra equipe di traduttori specializzati nel settore dell'arte com prende la sensibilità estetica e culturale necessaria per comunicare in modo efficace nel mondo dell'arte e si impegna per garantire la co erenza e l'accuratezza delle traduzioni.`
        },
        cover: '/clients/Art/coverart.webp',
        coverPdf: '/clients/Art/coverart.png',
        sector:'/clients/Art/Art.webp',
        sectorPdf:'/clients/Art/Art.png'
    },
    'Agronomy':{
        en:{
            title: "Translations in the Agronomic Sector - Your Linguistic Guide in the Fields",
            para1: "LingoYou is your reliable choice in the agronomic translation sector, offering high-quality language services for a wide range of fundamental documents in this vital and ever-evolving sector. Here are some examples of documents we translate with competence and precision:",
            listItem1Title: "Cultivation Manuals: ",
            listItem1Para: "We translate cultivation manuals, providing detailed and practical instructions for crop management, irrigation, fertilizer use, and pest management.",
            listItem2Title: "Soil Analysis Reports: ",
            listItem2Para: "We handle the translation of soil analysis reports, providing a clear understanding of the nutrients present in the soil and recommendations for improving fertility.",
            listItem3Title: "Technical-Agricultural Guides: ",
            listItem3Para: "We translate technical-agricultural guides on specific topics such as crop rotation, integrated disease management, and water resource conservation.",
            listItem4Title: "Agricultural Product Documentation: ",
            listItem4Para: "We translate labels of agricultural products, pesticide and fertilizer technical sheets, ensuring understanding of risks and correct usage methods.",
            listItem5Title: "Scientific Publications: ",
            listItem5Para: "We translate scientific publications and research articles in the field of agronomy, enabling the global sharing of new discoveries and sustainable agricultural practices.",
            para2: "Our team of translators specialized in the agronomic sector understands specific technical terminology and is committed to ensuring consistency and accuracy in translations. With LingoYou, you can rely on reliable translations that facilitate the dissemination of agronomic knowledge and promote sustainable and productive agricultural practices worldwide.",
            para2Pdf: "Our team of translators specialized in the agronomy sector understands specific technical terminology and is committed to ensuring the consistency and accuracy of translations."
        },
        it:{
            title:`Traduzioni nel Settore Agronomico - La Vostra Guida Linguistica nei Campi`,
            para1:`L'azienda LingoYou è la vostra scelta affidabile nel settore delle traduzioni agronomiche, offrendo servizi linguistici di alta qualità per una vasta gamma di documenti fondamentali in questo settore vitale e in continua evoluzione. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Manuali di Coltivazione: `,
            listItem1Para:`Traduciamo manuali di coltivazione, fornendo istruzioni dettagliate e pratiche per la gestione delle colture, l'irrigazione, l'uso dei fertilizzanti e la gestione dei parassiti.`,
            listItem2Title:`Rapporti di Analisi del Terreno: `,
            listItem2Para:`Ci occupiamo della traduzione di rapporti di analisi del terreno, fornendo una comprensione chiara dei nutrienti presenti nel suolo e delle raccomandazioni per migliorare la fertilità.`,
            listItem3Title:`Guide Tecnico-Agricole: `,
            listItem3Para:`Traduciamo guide tecnico-agricole su tematiche specifiche come la rotazione delle colture, la gestione integrata delle malattie e la conservazione delle risorse idriche.`,
            listItem4Title:`Documentazione di Prodotti Agricoli: `,
            listItem4Para:`Traduciamo etichette di prodotti agricoli, schede tecniche di pesticidi e fertilizzanti, garantendo la comprensione dei rischi e delle modalità d'uso corrette.`,
            listItem5Title:`Pubblicazioni Scientifiche: `,
            listItem5Para:`Traduciamo pubblicazioni scientifiche e articoli di ricerca nel campo dell'agronomia, consentendo la condivisione globale di nuove scoperte e pratiche agricole sostenibili.`,
            para2:`La nostra equipe di traduttori specializzati nel settore agronomico comprende la terminologia tecnica specifica e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che facilitano la diffusione delle conoscenze agronomiche e promuovono pratiche agricole sostenibili e produttive in tutto il mondo.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore agronomico comprende la terminologia tecnica specifica e si impegna per garantire la coerenza e l'accuratezza delle traduzioni.`
        },
        cover: '/clients/Agronomy/coveragronomy.webp',
        coverPdf: '/clients/Agronomy/coveragronomy.png',
        sector:'/clients/Agronomy/Agronomy.webp',
        sectorPdf:'/clients/Agronomy/Agronomy.png'
    },
    'Communication':{
        en:{
            title: "Translations in the Communication Sector - Your Trusted Linguistic Partner",
            para1: "LingoYou is your reliable linguistic partner in the communication sector, offering high-quality translation services for a wide range of essential documents in this dynamic and ever-evolving field. Here are some examples of documents we translate with competence and precision:",
            listItem1Title: "Press Releases and Official Announcements: ",
            listItem1Para: "We translate press releases, official announcements, and public messages, ensuring that your message is conveyed clearly and accurately on an international level.",
            listItem2Title: "Marketing and Advertising Material: ",
            listItem2Para: "We handle the translation of marketing and advertising material, such as brochures, advertisements, and social media content, tailoring your message to effectively reach a global audience.",
            listItem3Title: "Texts for Websites and Online Platforms: ",
            listItem3Para: "We translate texts for websites, company blogs, and online platforms, ensuring a multilingual online presence that reflects the identity and values of your brand.",
            listItem4Title: "Internal Documentation: ",
            listItem4Para: "We translate internal documentation, such as company manuals, corporate policies, and internal communications, facilitating communication among employees of different languages and cultures.",
            listItem5Title: "Presentations and Training Documents: ",
            listItem5Para: "We translate corporate presentations, educational materials, and training documents, ensuring that your message is understood and appreciated by a global audience.",
            para2: "Our team of translators specialized in the communication sector understands the importance of conveying an effective and persuasive message and is committed to ensuring consistency and accuracy in translations. With LingoYou, you can rely on reliable translations that help you successfully communicate with your international audience, facilitating the growth and success of your company.",
            para2Pdf: "Our team of translators specialized in the communication sector understands the importance of conveying an effective and persuasive message and is committed to ensuring the consistency and accuracy of translations."    
        },
        it:{
            title:`Traduzioni nel Settore della Comunicazione - Il Vostro Partner Linguistico di Fiducia`,
            para1:`L'azienda LingoYou è il vostro partner linguistico affidabile nel settore della comunicazione, offrendo servizi di traduzione di alta qualità per una vasta gamma di documenti essenziali in questo campo dinamico e in continua evoluzione. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Comunicati Stampa e Comunicati Ufficiali: `,
            listItem1Para:`Traduciamo comunicati stampa, comunicati ufficiali e messaggi pubblici, garantendo che il vostro messaggio sia comunicato in modo chiaro e accurato a livello internazionale.`,
            listItem2Title:`Materiale di Marketing e Pubblicità: `,
            listItem2Para:`Ci occupiamo della traduzione di materiale di marketing e pubblicità, come brochure, annunci e contenuti social media, adattando il vostro messaggio per raggiungere un pubblico globale in modo efficace.`,
            listItem3Title:`Testi per Siti Web e Piattaforme Online: `,
            listItem3Para:`Traduciamo testi per siti web, blog aziendali e piattaforme online, garantendo una presenza online multilingue che rispecchi l'identità e i valori del vostro brand.`,
            listItem4Title:`Documentazione Interna: `,
            listItem4Para:`Traduciamo documentazione interna, come manuali aziendali, politiche aziendali e comunicazioni interne, facilitando la comunicazione tra i dipendenti di diverse lingue e culture.`,
            listItem5Title:`Presentazioni e Documenti di Formazione: `,
            listItem5Para:`Traduciamo presentazioni aziendali, materiali didattici e documenti di formazione, assicurando che il vostro messaggio sia compreso e apprezzato da un pubblico globale.`,
            para2:`La nostra equipe di traduttori specializzati nel settore della comunicazione comprende l'importanza di trasmettere un messaggio efficace e persuasivo e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che vi aiutano a comunicare con successo con il vostro pubblico internazionale, facilitando la crescita e il successo della vostra azienda.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore della comunicazione comprende l'importanza di trasmettere un messaggio efficace e persuasivo e si impegna per garantire la coerenza e l'accuratezza delle traduzioni.`   
        },
        cover: '/clients/Communication/covercommunication.webp',
        coverPdf: '/clients/Communication/covercommunication.png',
        sector:'/clients/Communication/Communication.webp',
        sectorPdf:'/clients/Communication/Communication.png'
    },
    'Construction':{
        en:{
            title: "Translations in the Construction Sector - Your Reliable Linguistic Partner",
            para1: "LingoYou is your reliable partner in the construction translation sector, offering high-quality language services for a wide range of fundamental documents in this dynamic and challenging sector. Here are some examples of documents we translate with competence and precision:",
            listItem1Title: "Construction Plans: ",
            listItem1Para: "We translate construction plans, architectural drawings, and technical specifications, ensuring that every detail is accurately understood by construction team members.",
            listItem2Title: "Tender Documents: ",
            listItem2Para: "We handle the translation of tender documents, bids, and construction contracts, ensuring that all parties involved clearly understand the project terms and conditions.",
            listItem3Title: "Safety Manuals: ",
            listItem3Para: "We translate workplace safety manuals, standard operating procedures (SOPs), and instructions for the use of safety equipment, contributing to ensuring a safe working environment for all workers on the site.",
            listItem4Title: "Evaluation Reports: ",
            listItem4Para: "We translate construction evaluation reports, building inspections, and regulatory compliance documents, providing a clear understanding of the status and performance of structures.",
            listItem5Title: "Legal and Regulatory Documentation: ",
            listItem5Para: "We translate legal and regulatory documents in the construction sector, such as building permits, licenses, and building regulations, ensuring compliance with local and national laws.",
            para2: "Our team of translators specialized in the construction sector understands specific technical terminology and is committed to ensuring consistency and accuracy in translations. With LingoYou, you can rely on reliable translations that facilitate effective communication and international cooperation in construction projects, contributing to the success and safety of your construction projects.",
            para2Pdf: "Our team of translators specialized in the construction sector understands specific technical terminology and is committed to ensuring the consistency and accuracy of translations."
        },
        it:{
            title:`Traduzioni nel Settore delle Costruzioni - Il Vostro Partner Linguistico Affidabile `,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per le costruzioni, offrendo servizi linguistici di alta qualità per una vasta gamma di documenti fondamentali in questo settore dinamico e impegnativo. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Piani di Costruzione: `,
            listItem1Para:`Traduciamo piani di costruzione, disegni architettonici e specifiche tecniche, garantendo che ogni dettaglio sia compreso accuratamente dai membri del team di costruzione.`,
            listItem2Title:`Documenti di Appalto: `,
            listItem2Para:`Ci occupiamo della traduzione di documenti di appalto, offerte e contratti di costruzione, assicurando che tutte le parti coinvolte comprendano chiaramente i termini e le condizioni del progetto.`,
            listItem3Title:`Manuali di Sicurezza: `,
            listItem3Para:`Traduciamo manuali di sicurezza sul lavoro, procedure operative standard (SOP) e istruzioni per l'uso di attrezzature di sicurezza, contribuendo a garantire un ambiente di lavoro sicuro per tutti i lavoratori sul cantiere.`,
            listItem4Title:`Rapporti di Valutazione: `,
            listItem4Para:`Traduciamo rapporti di valutazione di costruzione, ispezioni di edifici e documenti di conformità normativa, fornendo una chiara comprensione dello stato e delle prestazioni delle strutture.`,
            listItem5Title:`Documentazione Legale e Normativa: `,
            listItem5Para:`Traduciamo documenti legali e normativi nel settore delle costruzioni, come permessi di costruzione, licenze e regolamenti edilizi, assicurando la conformità alle leggi locali e nazionali.`,
            para2:`La nostra equipe di traduttori specializzati nel settore delle costruzioni comprende la terminologia tecnica specifica e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che facilitano la comunicazione efficace e la cooperazione internazionale nei progetti di costruzione, contribuendo al successo e alla sicurezza dei vostri progetti edilizi. `,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore delle costruzioni comprende la terminologia tecnica specifica e si impegna per garantire la coerenza e l'accuratezza delle traduzioni.`    
        },
        cover: '/clients/Construction/coverconstruction.webp',
        coverPdf: '/clients/Construction/coverconstruction.png',
        sector:'/clients/Construction/Construction.webp',
        sectorPdf:'/clients/Construction/Construction.png'
    },
    'E-Learning':{
        en:{
            title: "Translations in the E-Learning Sector - Your Online Linguistic Tutor",
            para1: "LingoYou is your reference point in the e-learning translation sector, providing high-quality language services for a wide range of online educational materials. Here are some examples of documents we translate with competence and precision: ",
            listItem1Title: "Online Lessons and Courses: ",
            listItem1Para: "We translate lessons, complete courses, and teaching materials on a variety of topics, ensuring that international students can access educational content clearly and understandably in their native language.",
            listItem2Title: "Training Modules: ",
            listItem2Para: "We handle the translation of corporate training modules, training courses, and practical guides, providing effective multilingual training for employees worldwide.",
            listItem3Title: "Educational Texts and Exams: ",
            listItem3Para: "We translate educational texts, exams, and quizzes, ensuring that students can accurately and reliably assess their knowledge and skills.",
            listItem4Title: "E-Learning Interfaces and Platforms: ",
            listItem4Para: "We translate user interfaces, menus, and other features of e-learning platforms, ensuring a seamless learning experience for users of different languages.",
            listItem5Title: "Videos and Multimedia Resources: ",
            listItem5Para: "We translate scripts for educational videos, subtitles, and other multimedia content, offering an engaging and accessible learning experience for international students.",
            para2: "Our team of translators specialized in the e-learning sector understands the importance of conveying complex information clearly and understandably and is committed to ensuring consistency and accuracy in translations. With LingoYou, you can rely on reliable translations that support global accessibility to online education, facilitating learning and personal and professional development worldwide.",
            para2Pdf: "Our team of translators specialized in the e-learning sector understands the importance of conveying complex information clearly and understandably and is committed to ensuring the consistency and accuracy of translations."
        },
        it:{
            title:`Traduzioni nel Settore dell'E-Learning - Il Vostro Tutor Linguistico Online`,
            para1:`L'azienda LingoYou è il vostro punto di riferimento nel settore delle traduzioni per l'e-learning, fornendo servizi linguistici di alta qualità per una vasta gamma di materiali educativi online. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione: `,
            listItem1Title:`Lezioni e Corsi Online: `,
            listItem1Para:`Traduciamo lezioni, corsi completi e materiali didattici per una varietà di argomenti, garantendo che gli studenti internazionali possano accedere ai contenuti educativi in modo chiaro e comprensibile nella propria lingua madre. `,
            listItem2Title:`Moduli di Formazione: `,
            listItem2Para:`Ci occupiamo della traduzione di moduli di formazione aziendale, corsi di addestramento e guide pratiche, fornendo una formazione multilingue efficace per i dipendenti in tutto il mondo.`,
            listItem3Title:`Testi Didattici e Esami: `,
            listItem3Para:`Traduciamo testi didattici, esami e quiz, assicurando che gli studenti possano valutare le proprie conoscenze e competenze in modo accurato e affidabile.`,
            listItem4Title:`Interfacce e Piattaforme E-Learning: `,
            listItem4Para:`Traduciamo interfacce utente, menu e altre funzionalità delle piattaforme e-learning, garantendo un'esperienza di apprendimento senza soluzione di continuità per gli utenti di diverse lingue. `,
            listItem5Title:`Video e Risorse Multimediali: `,
            listItem5Para:`Traduciamo script di video didattici, sottotitoli e altri contenuti multimediali, offrendo un'esperienza di apprendimento coinvolgente e accessibile per gli studenti internazionali. `,
            para2:`La nostra equipe di traduttori specializzati nel settore dell'e-learning comprende l'importanza di trasmettere informazioni complesse in modo chiaro e comprensibile e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che supportano l'accessibilità globale all'istruzione online, facilitando l'apprendimento e lo sviluppo personale e professionale in tutto il mondo. `,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore dell'e-learning comprende l'importanza di trasmettere informazioni complesse in modo chiaro e comprensibile e si impegna per garantire la coerenza e l'accuratezza delle traduzioni.`   
        },
        cover: '/clients/E-Learning/coverelearning.webp',
        coverPdf: '/clients/E-Learning/coverelearning.png',
        sector:'/clients/E-Learning/eLearning.webp',
        sectorPdf:'/clients/E-Learning/eLearning.png'
    },
    'Education':{
        en:{
            title: "Translations in the Education Sector - Your Academic Linguistic Tutor",
            para1: "LingoYou is your reliable resource in the education translation sector, providing high-quality language services for a wide range of academic materials. Here are some examples of documents we translate with competence and precision:",
            listItem1Title: "Educational Texts: ",
            listItem1Para: "We translate textbooks, handouts, and teaching materials for a variety of academic disciplines, ensuring that students can access educational content clearly and understandably in their native language.",
            listItem2Title: "Scientific Articles and Research: ",
            listItem2Para: "We handle the translation of scientific articles, thesis papers, and academic research, enabling the global sharing of knowledge and discoveries in the field of education and research.",
            listItem3Title: "School Documentation: ",
            listItem3Para: "We translate educational texts, exams, and quizzes, ensuring that students can accurately and reliably assess their knowledge and skills.",
            listItem4Title: "Presentations and Training Material: ",
            listItem4Para: "We translate academic presentations, training material, and teacher guides, providing multilingual resources to support teaching and learning in international educational contexts.",
            listItem5Title: "Educational Cooperation Projects: ",
            listItem5Para: "We translate documents for international educational cooperation projects, promoting the exchange of experiences and best practices in the field of education.",
            para2: "Our team of translators specialized in the education sector understands the importance of conveying knowledge and skills clearly and accurately and is committed to ensuring consistency and accuracy in translations. With LingoYou, you can rely on reliable translations that support access to quality education for all, contributing to the global dissemination of knowledge and learning.",
            para2Pdf: "Our team of translators specialized in the education sector understands the importance of conveying knowledge and skills clearly and accurately and is committed to ensuring the consistency and accuracy of translations."   
        },
        it:{
            title:`Traduzioni nel Settore dell'Educazione - Il Vostro Tutor Linguistico Accademico`,
            para1:`L'azienda LingoYou è la vostra risorsa affidabile nel settore delle traduzioni per l'educazione, offrendo servizi linguistici di alta qualità per una vasta gamma di materiali accademici. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Testi Didattici: `,
            listItem1Para:`Traduciamo libri di testo, dispense e materiali didattici per una varietà di discipline accademiche, garantendo che gli studenti possano accedere ai contenuti educativi in modo chiaro e comprensibile nella propria lingua madre.`,
            listItem2Title:`Articoli Scientifici e Ricerche: `,
            listItem2Para:`Ci occupiamo della traduzione di articoli scientifici, tesi di laurea e ricerche accademiche, permettendo la condivisione globale di conoscenze e scoperte nel campo dell'istruzione e della ricerca.`,
            listItem3Title:`Documentazione Scolastica: `,
            listItem3Para:`Traduciamo testi didattici, esami e quiz, assicurando che gli studenti possano valutare le proprie conoscenze e competenze in modo accurato e affidabile.`,
            listItem4Title:`Presentazioni e Materiale Formativo: `,
            listItem4Para:`Traduciamo presentazioni accademiche, materiale formativo e guide per gli insegnanti, fornendo risorse multilingue per supportare l'insegnamento e l'apprendimento in contesti educativi internazionali.`,
            listItem5Title:`Progetti di Cooperazione Educativa: `,
            listItem5Para:`Traduciamo documenti per progetti di cooperazione educativa internazionale, promuovendo lo scambio di esperienze e buone pratiche nel campo dell'istruzione.`,
            para2:`La nostra equipe di traduttori specializzati nel settore dell'educazione comprende l'importanza di trasmettere conoscenze e competenze in modo chiaro e accurato e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che supportano l'accesso all'istruzione di qualità per tutti, contribuendo alla diffusione globale della conoscenza e dell'apprendimento. `,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore dell'educazione comprende l'importanza di trasmettere conoscenze e competenze in modo chiaro e accurato e si impegna per garantire la coerenza e l'accuratezza delle traduzioni.`
        },
        cover: '/clients/Education/covereducation.webp',
        coverPdf: '/clients/Education/covereducation.png',
        sector:'/clients/Education/education.webp',
        sectorPdf:'/clients/Education/education.png'
    },
    'Consumer Products':{
        en:{
            title: "Translations in the Consumer Products Sector - Your Linguistic Companion for Shopping",
            para1: "LingoYou is your reliable partner in the translation sector for consumer products, offering high-quality language services for a wide range of consumer items. Here are some examples of documents we translate with competence and precision:",
            listItem1Title: "Product Labels: ",
            listItem1Para: "We translate product labels, ingredients, usage instructions, and warnings, ensuring that consumers can fully understand the product before purchasing and using it.",
            listItem2Title: "Packaging and Packaging Materials: ",
            listItem2Para: "We handle the translation of packaging, boxes, and packaging materials, ensuring that product information is clear and accurately communicated in all target languages.",
            listItem3Title: "Technical Data Sheets and Safety Data Sheets: ",
            listItem3Para: "We translate technical data sheets and safety data sheets for chemicals and hazardous products, ensuring safety and regulatory compliance in the industrial and household sectors.",
            listItem4Title: "Assembly and Installation Instructions: ",
            listItem4Para: "We translate assembly, installation, and maintenance instructions for products such as furniture, appliances, and equipment, facilitating correct use by consumers.",
            listItem5Title: "Promotional and Advertising Materials: ",
            listItem5Para: "We translate promotional materials, advertisements, and brochures, creating an engaging and accessible shopping experience for consumers worldwide.",
            para2: "Our team of translators specialized in the consumer products sector understands the importance of effectively communicating with customers and is committed to ensuring consistency and accuracy in translations. With LingoYou, you can rely on reliable translations that support your presence in the global market and enhance the overall consumer experience with your products.",
            para2Pdf: "Our team of translators specialized in the consumer products sector understands the importance of effectively communicating with customers and is committed to ensuring the consistency and accuracy of translations."   
        },
        it:{
            title:`Traduzioni nel Settore dei Prodotti di Consumo - Il Vostro Compagno Linguistico per lo Shopping`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per i prodotti di consumo, offrendo servizi linguistici di alta qualità per una vasta gamma di articoli destinati ai consumatori. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione: `,
            listItem1Title:`Etichette dei Prodotti: `,
            listItem1Para:`Traduciamo etichette dei prodotti, ingredienti, istruzioni per l'uso e avvertenze, garantendo che i consumatori possano comprendere completamente il prodotto prima dell'acquisto e dell'utilizzo.`,
            listItem2Title:`Confezioni e Materiali di Imballaggio: `,
            listItem2Para:`Ci occupiamo della traduzione di confezioni, scatole e materiali di imballaggio, assicurando che le informazioni sul prodotto siano chiare e accuratamente comunicate in tutte le lingue di destinazione.`,
            listItem3Title:`Schede Tecniche e Schede di Sicurezza: `,
            listItem3Para:`Traduciamo schede tecniche e schede di sicurezza per prodotti chimici e pericolosi, garantendo la sicurezza e la conformità normativa nei settori dell'industria e della casa. `,
            listItem4Title:`Istruzioni per l'Assemblaggio e l'Installazione: `,
            listItem4Para:`Traduciamo istruzioni per l'assemblaggio, l'installazione e la manutenzione di prodotti come mobili, elettrodomestici e apparecchiature, facilitando l'utilizzo corretto da parte dei consumatori. `,
            listItem5Title:`Materiale Promozionale e Pubblicitario: `,
            listItem5Para:`Traduciamo materiale promozionale, annunci pubblicitari e brochure, creando un'esperienza di shopping coinvolgente e accessibile per i consumatori di tutto il mondo.`,
            para2:`La nostra equipe di traduttori specializzati nel settore dei prodotti di consumo comprende l'importanza di comunicare in modo efficace con i clienti e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che supportano la vostra presenza sul mercato globale e migliorano l'esperienza complessiva dei consumatori con i vostri prodotti.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore dei prodotti di consumo comprende l'importanza di comunicare in modo efficace con i clienti e si impegna per garantire la coerenza e l'accuratezza delle traduzioni.`    
        },
        cover: '/clients/ConsumerProducts/coverconsumerproducts.webp',
        coverPdf: '/clients/ConsumerProducts/coverconsumerproducts.png',
        sector:'/clients/ConsumerProducts/consumerProducts.webp',
        sectorPdf:'/clients/ConsumerProducts/consumerProducts.png'
    },
    'Energy':{
        en:{
            title: "Translations in the Energy Sector - Your Reliable Linguistic Partner",
            para1: "LingoYou is your reliable partner in energy sector translations, offering high-quality linguistic services for a wide range of essential documents in this crucial and rapidly evolving field. Here are some examples of documents we translate with competence and precision:",
            listItem1Title: "Technical Reports and Feasibility Studies:",
            listItem1Para: "We translate technical reports, feasibility studies, and market analyses in the energy sector, providing a clear understanding of data and trends in renewable energy, energy efficiency, and more.",
            listItem2Title: "Contracts and Supply Agreements:",
            listItem2Para: "We handle the translation of energy supply contracts, service agreements, and related legal documentation, ensuring clarity and precision in contractual terms for all involved parties.",
            listItem3Title: "Manuals and Technical Sheets:",
            listItem3Para: "We translate instruction manuals, technical specifications, and documentation related to the installation and maintenance of energy systems and equipment, ensuring safety and operational efficiency.",
            listItem4Title: "Energy Policies and Regulations:",
            listItem4Para: "We translate energy policies, regulations, and national and international standards in the energy sector, helping companies understand and adapt to evolving regulatory requirements.",
            listItem5Title: "Awareness and Training Materials:",
            listItem5Para: "We translate awareness materials, training courses, and educational content on energy topics, promoting awareness and adoption of sustainable practices in the sector.",
            para2: "Our team of translators specialized in the energy sector understands the complexity of technical and regulatory issues involved and is committed to ensuring the consistency and accuracy of translations. With LingoYou, you can rely on reliable translations that facilitate communication and international cooperation in the energy sector, contributing to the transition to a sustainable and reliable energy future.",
            para2Pdf: "Our team of translators specialized in the energy sector understands the complexity of the technical and regulatory issues involved and is committed to ensuring the consistency and accuracy of translations."    
        },
        it:{
            title:`Traduzioni nel Settore dell'Energia - Il Vostro Partner Linguistico Affidabile`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per l'energia, offrendo servizi linguistici di alta qualità per una vasta gamma di documenti essenziali in questo settore cruciale e in rapida evoluzione. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Rapporti Tecnici e Studi di Fattibilità: `,
            listItem1Para:`Traduciamo rapporti tecnici, studi di fattibilità e analisi di mercato nel settore dell'energia, fornendo una comprensione chiara dei dati e delle tendenze nel campo dell'energia rinnovabile, dell'efficienza energetica e altro ancora.`,
            listItem2Title:`Contratti e Accordi di Fornitura: `,
            listItem2Para:`Ci occupiamo della traduzione di contratti di fornitura di energia, accordi di servizio e documentazione legale correlata, garantendo la chiarezza e la precisione dei termini contrattuali per tutte le parti coinvolte.`,
            listItem3Title:`Manuali e Schede Tecniche: `,
            listItem3Para:`Traduciamo manuali di istruzioni, schede tecniche e documentazione relativa all'installazione e alla manutenzione di impianti energetici e apparecchiature, assicurando la sicurezza e l'efficienza delle operazioni.`,
            listItem4Title:`Politiche Energetiche e Normative: `,
            listItem4Para:`Traduciamo politiche energetiche, regolamenti e normative nazionali e internazionali nel settore dell'energia, aiutando le aziende a comprendere e adattarsi ai requisiti normativi in costante evoluzione.`,
            listItem5Title:`Materiali di Sensibilizzazione e Formazione: `,
            listItem5Para:`Traduciamo materiale di sensibilizzazione, corsi di formazione e contenuti educativi sulle tematiche energetiche, promuovendo la consapevolezza e l'adozione di pratiche sostenibili nel settore.`,
            para2:`La nostra equipe di traduttori specializzati nel settore dell'energia comprende la complessità delle questioni tecniche e normative coinvolte e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che facilitano la comunicazione e la cooperazione internazionale nel settore dell'energia, contribuendo alla transizione verso un futuro energetico sostenibile e affidabile. `,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore dell'energia comprende la complessità delle questioni tecniche e normative coinvolte e si impegna per garantire la coerenza e l'accuratezza delle traduzioni.`
        },
        cover: '/clients/Energy/coverenergy.webp',
        coverPdf: '/clients/Energy/coverenergy.png',
        sector:'/clients/Energy/energy.webp',
        sectorPdf:'/clients/Energy/energy.png'
        
    },
    'Environmental':{
        en:{
            title: "Translations in the Environmental Sector - Your Linguistic Ally for Sustainability",
            para1: "LingoYou is your reliable ally in the environmental translations sector, offering high-quality linguistic services for a wide range of essential documents in this crucial sector for the sustainability of the planet. Here are some examples of documents we translate with competence and precision:",
            listItem1Title: "Environmental Reports and Impact Analyses:",
            listItem1Para: "We translate environmental reports, environmental impact assessments, and sustainability studies, providing a clear understanding of the effects of human activities on the environment and climate.",
            listItem2Title: "Environmental Legislation and Regulations:",
            listItem2Para: "We handle the translation of environmental laws, regulations, and directives, ensuring that businesses and institutions understand and comply with legal obligations related to the environment.",
            listItem3Title: "Educational and Awareness Materials:",
            listItem3Para: "We translate educational material, awareness campaigns, and resources for environmental education, promoting awareness and action towards sustainable practices and greater respect for the environment.",
            listItem4Title: "Corporate Communications and CSR:",
            listItem4Para: "We translate sustainability reports, corporate environmental policies, and corporate communications related to Corporate Social Responsibility (CSR), demonstrating companies' commitment to sustainable business practices.",
            listItem5Title: "Environmental Technical Documentation:",
            listItem5Para: "We translate environmental technical documentation, such as project specifications for renewable energies, waste management procedures, and biodiversity conservation plans, supporting the implementation of environmental projects worldwide.",
            para2: "Our team of translators specialized in the environmental sector understands the importance of environmental protection and is committed to ensuring the consistency and accuracy of translations. With LingoYou, you can rely on reliable translations that support communication and action for environmental conservation, contributing to a more sustainable future for all.", 
             para2Pdf: "Our team of translators specialized in the environmental sector understands the importance of environmental protection and is committed to ensuring the consistency and accuracy of translations."  
        },
        it:{
            title:`Traduzioni nel Settore Ambientale - Il Vostro Alleato Linguistico per la Sostenibilità`,
            para1:`L'azienda LingoYou è il vostro alleato affidabile nel settore delle traduzioni ambientali, offrendo servizi linguistici di alta qualità per una vasta gamma di documenti essenziali in questo settore cruciale per la sostenibilità del pianeta. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Rapporti Ambientali e Analisi di Impatto: `,
            listItem1Para:`Traduciamo rapporti ambientali, valutazioni di impatto ambientale e studi di sostenibilità, fornendo una comprensione chiara degli effetti delle attività umane sull'ambiente e sul clima.`,
            listItem2Title:`Legislazione e Normative Ambientali: `,
            listItem2Para:`Ci occupiamo della traduzione di leggi, regolamenti e direttive ambientali, garantendo che le aziende e le istituzioni comprendano e rispettino gli obblighi legali in materia ambientale.`,
            listItem3Title:`Materiali Educativi e di Sensibilizzazione: `,
            listItem3Para:`Traduciamo materiale educativo, campagne di sensibilizzazione e risorse per l'educazione ambientale, promuovendo la consapevolezza e l'azione verso pratiche sostenibili e un maggiore rispetto per l'ambiente.`,
            listItem4Title:`Comunicazioni Corporate e CSR: `,
            listItem4Para:`Traduciamo rapporti di sostenibilità, politiche aziendali ambientali e comunicazioni corporate relative alla responsabilità sociale d'impresa (CSR), dimostrando l'impegno delle aziende verso pratiche commerciali sostenibili.`,
            listItem5Title:`Documentazione Tecnica Ambientale: `,
            listItem5Para:`Traduciamo documentazione tecnica ambientale, come specifiche di progetto per energie rinnovabili, procedure di gestione dei rifiuti e piani di conservazione della biodiversità, supportando la realizzazione di progetti ambientali in tutto il mondo.`,
            para2:`La nostra equipe di traduttori specializzati nel settore ambientale comprende l'importanza della protezione dell'ambiente e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che supportano la comunicazione e l'azione per la salvaguardia dell'ambiente, contribuendo a un futuro più sostenibile per tutti.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore ambientale comprende l'importanza della protezione dell'ambiente e si impegna per garantire la coerenza e l'accuratezza delle traduzioni.`    
        },
        cover: '/clients/Environmental/coverenvironmental.webp',
        coverPdf: '/clients/Environmental/coverenvironmental.png',
        sector:'/clients/Environmental/environmental.webp',
        sectorPdf:'/clients/Environmental/environmental.png'
    },
    'Engineering':{
        en:{
            title: "Translations for Advanced Technology Companies - Your Specialized Linguistic Partner",
            para1: "LingoYou is your reliable partner in the field of translations for advanced technology companies, offering specialized linguistic services for a wide range of technical documents, contracts, and corporate communications. Here are some examples of documents we translate with competence and precision:",
            listItem1Title: "Technical Specifications and Research and Development Projects:",
            listItem1Para: "We translate technical specifications, design documents, and research and development projects, ensuring an accurate understanding of technical details and cutting-edge innovations.",
            listItem2Title: "Contracts and Business Agreements:",
            listItem2Para: "We handle the translation of supply contracts, partnership agreements, and other legal documents, ensuring clarity and precision of contractual terms for all parties involved.",
            listItem3Title: "Product Manuals and Solutions Documentation:",
            listItem3Para: "We translate user manuals, installation guides, and technical documentation for products and corporate solutions, ensuring a smooth and reliable user experience for global customers.",
            listItem4Title: "Presentations and Press Releases:",
            listItem4Para: "We translate corporate presentations, press releases, and promotional materials, providing a consistent and engaging message for the international audience.",
            listItem5Title: "Correspondence and Internal Communications:",
            listItem5Para: "We translate emails, company memos, and other internal communications, facilitating collaboration among multilingual teams within the company.",
            para2: "Our team of translators specialized in the field of advanced technology understands the importance of accuracy and confidentiality in corporate documents and is committed to ensuring the consistency and accuracy of translations. With LingoYou, you can rely on reliable translations that support your global presence and success in international markets.",
            para2Pdf: "Our team of translators specialized in the advanced technology sector understands the importance of precision and confidentiality in business documents and is committed to ensuring the consistency and accuracy of translations."      
        },
        it:{
            title:`Traduzioni per Aziende di Tecnologia Avanzata - Il Vostro Partner Linguistico Specializzato`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per le aziende di tecnologia avanzata, offrendo servizi linguistici specializzati per una vasta gamma di documenti tecnici, contratti e comunicazioni aziendali. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione: `,
            listItem1Title:`Specifiche Tecniche e Progetti di Ricerca e Sviluppo: `,
            listItem1Para:`Traduciamo specifiche tecniche, documenti di progettazione e progetti di ricerca e sviluppo, assicurando una comprensione accurata dei dettagli tecnici e delle innovazioni all'avanguardia.`,
            listItem2Title:`Contratti e Accordi Commerciali: `,
            listItem2Para:`Ci occupiamo della traduzione di contratti di fornitura, accordi di partnership e altri documenti legali, garantendo la chiarezza e la precisione dei termini contrattuali per tutte le parti coinvolte.`,
            listItem3Title:`Manualistica di Prodotti e Soluzioni: `,
            listItem3Para:`Traduciamo manuali utente, guide di installazione e documentazione tecnica per prodotti e soluzioni aziendali, assicurando un'esperienza di utilizzo fluida e affidabile per i clienti globali.`,
            listItem4Title:`Presentazioni e Comunicati Stampa: `,
            listItem4Para:`Traduciamo presentazioni aziendali, comunicati stampa e materiale promozionale, fornendo un messaggio coerente e coinvolgente per il pubblico internazionale.`,
            listItem5Title:`Corrispondenza e Comunicazioni Interne: `,
            listItem5Para:`Traduciamo e-mail, memo aziendali e altre comunicazioni interne, facilitando la collaborazione tra team multilingue all'interno dell'azienda. `,
            para2:`La nostra equipe di traduttori specializzati nel settore della tecnologia avanzata comprende l'importanza della precisione e della confidenzialità nei documenti aziendali e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che supportano la vostra presenza globale e il successo nei mercati internazionali. `,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore della tecnologia avanzata comprende l'importanza della precisione e della confidenzialità nei documenti aziendali e si impegna per garantire la coerenza e l'accuratezza delle traduzioni`   
        },
        cover: '/clients/Engineering/coverengineering.webp',
        coverPdf: '/clients/Engineering/coverengineering.png',
        sector:'/clients/Engineering/Engineering.webp',
        sectorPdf:'/clients/Engineering/Engineering.png'
    
    },
    'Event Organiser':{
        en:{
            title: "Translations for Event Organizers - Your Linguistic Partner for a Successful Event",
            para1: "LingoYou is your reliable partner in the field of translations for event organizers, offering specialized linguistic services to ensure effective and seamless communication throughout the entire event planning and execution process. Here are some examples of documents and materials we translate with competence and precision:",
            listItem1Title: "Event Plans and Proposals:",
            listItem1Para: "We translate detailed event plans and proposals, ensuring that all parties involved clearly understand the objectives, logistical details, and budget.",
            listItem2Title: "Invitations and Promotional Materials:",
            listItem2Para: "We handle the translation of invitations, event programs, posters, and other promotional materials, creating an engaging experience for guests from different languages and cultures.",
            listItem3Title: "Communications with Suppliers and Partners:",
            listItem3Para: "We translate emails, contracts, and other communications with suppliers, sponsors, and event partners, facilitating cooperation and negotiation in a multilingual context.",
            listItem4Title: "Technical Sheets and Safety Plans:",
            listItem4Para: "We translate technical sheets, safety plans, and event-related regulations, ensuring regulatory compliance and safety during the event.",
            listItem5Title: "Post-Event Documentation:",
            listItem5Para: "We translate post-event reports, evaluation surveys, and other post-event documentation, allowing for an accurate assessment of performance and participant feedback.",
            para2: "Our team of translators specialized in the events industry understands the importance of accuracy and timeliness in event-related communications and is committed to ensuring the consistency and accuracy of translations. With LingoYou, you can rely on reliable translations that contribute to the success and smooth running of your events on an international scale.",
            para2Pdf: "Our team of translators specialized in the events sector understands the importance of accuracy and timeliness in event-related communications and is committed to ensuring the consistency and accuracy of translations."       
        },
        it:{
            title:`Traduzioni per Organizzatori di Eventi - Il Vostro Partner Linguistico per un Evento di Successo`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per gli organizzatori di eventi, offrendo servizi linguistici specializzati per garantire una comunicazione efficace e senza soluzione di continuità durante tutto il processo di pianificazione e realizzazione dell'evento. Ecco alcuni esempi di documenti e materiali che traduciamo con competenza e precisione:`,
            listItem1Title:`Piani e Proposte di Eventi: `,
            listItem1Para:`Traduciamo piani dettagliati e proposte di eventi, assicurando che tutte le parti coinvolte comprendano chiaramente gli obiettivi, i dettagli logistici e il budget.`,
            listItem2Title:`Inviti e Materiali Promozionali: `,
            listItem2Para:`Ci occupiamo della traduzione di inviti, programmi dell'evento, manifesti e altri materiali promozionali, creando un'esperienza coinvolgente per gli ospiti di diverse lingue e culture.`,
            listItem3Title:`Comunicazioni con Fornitori e Partner: `,
            listItem3Para:`Traduciamo e-mail, contratti e altre comunicazioni con fornitori, sponsor e partner dell'evento, facilitando la cooperazione e la negoziazione in un contesto multilingue.`,
            listItem4Title:`Schede Tecniche e Piani di Sicurezza: `,
            listItem4Para:`Traduciamo schede tecniche, piani di sicurezza e normative relative all'evento, garantendo la conformità normativa e la sicurezza durante lo svolgimento dell'evento.`,
            listItem5Title:`Documentazione Post-Evento: `,
            listItem5Para:`Traduciamo relazioni post-evento, sondaggi di valutazione e altre documentazioni post-evento, consentendo una valutazione accurata delle prestazioni e dei feedback dei partecipanti.`,
            para2:`La nostra equipe di traduttori specializzati nel settore degli eventi comprende l'importanza della precisione e della tempistica nelle comunicazioni legate agli eventi e si impegna per garantire la coerenza e l'accuratezza delle traduzioni. Con LingoYou, potete contare su traduzioni affidabili che contribuiscono al successo e alla riuscita dei vostri eventi in un contesto internazionale.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore degli eventi comprende l'importanza della precisione e della tempistica nelle comunicazioni legate agli eventi e si impegna per garantire la coerenza e l'accuratezza delle traduzioni.`   
        },
        cover: '/clients/EventOrganiser/covereventorganiser.webp',
        coverPdf: '/clients/EventOrganiser/covereventorganiser.png',
        sector:'/clients/EventOrganiser/eventOrganiser.webp',
        sectorPdf:'/clients/EventOrganiser/eventOrganiser.png'
    },
    'Food and Beverage':{
        en:{
            title: "Translations in the Food Industry - Your Linguistic Partner for Taste and Quality",
            para1: "LingoYou is your reliable partner in the field of translations for the food industry, offering specialized linguistic services for a wide range of documents and materials related to the food sector. Here are some examples of documents we translate with competence and precision:",
            listItem1Title: "Product Labels and Packaging:",
            listItem1Para: "We translate product labels, ingredients, nutritional information, and usage instructions, ensuring regulatory compliance and clarity of information for consumers.",
            listItem2Title: "Recipes and Menus:",
            listItem2Para: "We handle the translation of recipes, menus, and dish descriptions for restaurants, cafes, and food businesses, ensuring an attractive presentation and effective communication of culinary offerings.",
            listItem3Title: "Promotional and Advertising Materials:",
            listItem3Para: "We translate brochures, catalogs, advertisements, and other promotional materials in the food industry, creating persuasive and engaging messages for your international consumer audience.",
            listItem4Title: "Regulations and Certifications:",
            listItem4Para: "We translate food regulations, quality certifications, and regulatory documents, helping companies understand and adhere to local and international regulations in the food sector.",
            listItem5Title: "Food Safety Manuals and Production Instructions:",
            listItem5Para: "We translate food safety manuals, hygiene procedures, and production instructions, ensuring the safety and quality of food products throughout the production process.",
            para2: "Our team of translators specialized in the food industry understands the importance of accuracy and consistency in translations to ensure regulatory compliance and effective communication with consumers of different languages and cultures. With LingoYou, you can rely on reliable translations that enhance your brand and promote your excellence in the food sector.",
            para2Pdf: "Our team of translators specialized in the food sector understands the importance of precision and consistency in translations to ensure regulatory compliance and effective communication with consumers of different languages and cultures."  
        },
        it:{
            title:`Traduzioni nel Settore Alimentare - Il Vostro Partner Linguistico per il Gusto e la Qualità`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per l'industria alimentare, offrendo servizi linguistici specializzati per una vasta gamma di documenti e materiali legati al settore alimentare. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione: `,
            listItem1Title:`Etichette e Imballaggi dei Prodotti: `,
            listItem1Para:`Traduciamo etichette dei prodotti, ingredienti, informazioni nutrizionali e istruzioni per l'uso, garantendo la conformità normativa e la chiarezza delle informazioni per i consumatori.`,
            listItem2Title:`Ricette e Menu: `,
            listItem2Para:`Ci occupiamo della traduzione di ricette, menu e descrizioni dei piatti per ristoranti, caffetterie e aziende alimentari, assicurando una presentazione accattivante e una comunicazione efficace delle proposte culinarie.`,
            listItem3Title:`Materiale Promozionale e Pubblicitario: `,
            listItem3Para:`Traduciamo brochure, cataloghi, annunci e altri materiali promozionali nel settore alimentare, creando messaggi persuasivi e coinvolgenti per il vostro pubblico di consumatori internazionali.`,
            listItem4Title:`Normative e Certificazioni: `,
            listItem4Para:`Traduciamo normative alimentari, certificazioni di qualità e documenti normativi, aiutando le aziende a comprendere e aderire alle regolamentazioni locali e internazionali nel settore alimentare. `,
            listItem5Title:`Manuali di Sicurezza Alimentare e Istruzioni di Produzione: `,
            listItem5Para:`Traduciamo manuali di sicurezza alimentare, procedure di igiene e istruzioni di produzione, garantendo la sicurezza e la qualità dei prodotti alimentari durante tutto il processo produttivo.`,
            para2:`La nostra equipe di traduttori specializzati nel settore alimentare comprende l'importanza della precisione e della coerenza nelle traduzioni per garantire la conformità normativa e la comunicazione efficace con i consumatori di diverse lingue e culture. Con LingoYou, potete contare su traduzioni affidabili che valorizzano la vostra marca e promuovono la vostra eccellenza nel settore alimentare.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore alimentare comprende l'importanza della precisione e della coerenza nelle traduzioni per garantire la conformità normativa e la comunicazione efficace con i consumatori di diverse lingue e culture.`
        },
        cover: '/clients/Food/coverfood.webp',
        coverPdf: '/clients/Food/coverfood.png',
        sector:'/clients/Food/food.webp',
        sectorPdf:'/clients/Food/food.png'     
    },
    'Games/Apps':{
        en:{
            title: "Translations in the Games/App Sector - We're Linguistic Experts!",
            para1: "At LingoYou, we are committed to providing high-quality translation services in the gaming and app industry. We understand the importance of effectively communicating with the global audience to ensure the success of a digital product. Our specialized skills extend to a wide range of documents, including but not limited to:",
            listItem1Title: "Game Localization:",
            listItem1Para: "We adapt the text, dialogues, and graphical elements of games to meet the linguistic and cultural needs of different regions, ensuring an engaging experience for all players.",
            listItem2Title: "Translation of Manuals and Instructions:",
            listItem2Para: "We provide precise and detailed translations of user manuals, game guides, and instructions to enable players to fully understand the features and mechanics of the game.",
            listItem3Title: "Translation of Scripts and Dialogues:",
            listItem3Para: "We work closely with developers to accurately translate character dialogues, preserving the original tone, style, and humor of the game.",
            listItem4Title: "App Localization:",
            listItem4Para: "We adapt mobile apps into different languages, ensuring an intuitive interface and optimal user experience for users worldwide.",
            listItem5Title: "Translation of Technical Documentation:",
            listItem5Para: "We offer precise translations of technical documentation, such as project specifications, system requirements, and release notes, to support developers during the game or app development cycle.",
            para2: "With our team of professional translators who are passionate about video games and technology, we are committed to providing reliable and customized translation services to meet the individual needs of our clients in the gaming and app industry.",       
            para2Pdf: "With our team of professional translators who are passionate about video games and technology, we are committed to providing reliable and customized translation services to meet the individual needs of our clients in the gaming and app industry."
    
        },
        it:{
            title:`Traduzioni nel Settore dei Giochi/App - Siamo Esperti Linguistici!`,
            para1:`In LingoYou, ci impegniamo a offrire servizi di traduzione di alta qualità nel settore dei videogiochi e delle app. Siamo consapevoli dell'importanza di comunicare efficacemente con il pubblico globale per garantire il successo di un prodotto digitale. Le nostre competenze specializzate si estendono a una vasta gamma di documenti, compresi ma non limitati a:  `,
            listItem1Title:`Localizzazione di giochi: `,
            listItem1Para:`Adattiamo il testo, i dialoghi e gli elementi grafici dei giochi per soddisfare le esigenze linguistiche e culturali di diverse regioni, garantendo un'esperienza coinvolgente per tutti i giocatori.`,
            listItem2Title:`Traduzione di manuali e istruzioni: `,
            listItem2Para:`Forniamo traduzioni precise e dettagliate di manuali utente, guide di gioco e istruzioni per consentire ai giocatori di comprendere appieno le funzionalità e le meccaniche del gioco.`,
            listItem3Title:`Traduzione di script e dialoghi: `,
            listItem3Para:`Lavoriamo a stretto contatto con gli sviluppatori per tradurre in modo accurato i dialoghi dei personaggi, preservando il tono, lo stile e l'umorismo originali del gioco.`,
            listItem4Title:`Localizzazione di app: `,
            listItem4Para:`Adattiamo le app per dispositivi mobili in diverse lingue, garantendo un'interfaccia intuitiva e una user experience ottimale per utenti di tutto il mondo.`,
            listItem5Title:`Traduzione di documentazione tecnica: `,
            listItem5Para:`Offriamo traduzioni precise di documentazione tecnica, come specifiche di progetto, requisiti di sistema e note di rilascio, per supportare gli sviluppatori durante il ciclo di sviluppo del gioco o dell'app.`,
            para2:`Con il nostro team di traduttori professionisti e appassionati di videogiochi e tecnologia, ci impegniamo a fornire servizi di traduzione affidabili e su misura per soddisfare le esigenze individuali dei nostri clienti nel settore dei videogiochi e delle app.`,
            para2Pdf:`Con il nostro team di traduttori professionisti e appassionati di videogiochi e tecnologia, ci impegniamo a fornire servizi di traduzione affidabili e su misura per soddisfare le esigenze individuali dei nostri clienti nel settore dei videogiochi e delle app.`
          
        },
        cover: '/clients/Games/covergamesapps.webp',
        coverPdf: '/clients/Games/covergamesapps.png',
        sector:'/clients/Games/gamesApps.webp',
        sectorPdf:'/clients/Games/gamesApps.png'
    },
    'Legal':{
        en:{
            title: "Translations in the Legal Sector - Your Linguistic Partner for Legal Precision",
            para1: "LingoYou is your reliable partner in the field of legal translations, offering specialized linguistic services for a wide range of documents and materials related to the legal sector. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Contracts and Legal Agreements:",
            listItem1Para: "We translate contracts, license agreements, non-disclosure agreements (NDAs), and other legal documents, ensuring clarity and accuracy of contractual terms in all involved languages.",
            listItem2Title: "Legal and Judicial Documents:",
            listItem2Para: "We handle the translation of legal documents, judgments, petitions, summonses, and other legal proceedings, ensuring accurate understanding of legal issues in multilingual contexts.",
            listItem3Title: "Laws and Regulations:",
            listItem3Para: "We translate laws, regulations, codes, and other legislative provisions, providing accurate translation tailored to the specific regulatory context of each country or region.",
            listItem4Title: "Legal Correspondence:",
            listItem4Para: "We translate legal correspondence, legal opinions, memoranda, and other communication documents between lawyers and clients, maintaining confidentiality and consistency of legal language.",
            listItem5Title: "Corporate and Contractual Documentation:",
            listItem5Para: "We translate corporate documents, articles of association, internal policies, and other contractual documents, ensuring compliance with local and international laws and regulations.",
            para2: "Our team of translators specialized in the legal sector understands the importance of precision, confidentiality, and consistency in translations to ensure the validity and effectiveness of legal documents in international contexts. With LingoYou, you can rely on reliable translations that support your legal and business activities worldwide.",
            para2Pdf: "Our team of translators specialized in the legal sector understands the importance of accuracy, confidentiality, and consistency in translations to ensure the validity and effectiveness of legal documents in international contexts."   
        },
        it:{
            title:`Traduzioni nel Settore Legale - Il Vostro Partner Linguistico per la Precisione Giuridica`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni legali, offrendo servizi linguistici specializzati per una vasta gamma di documenti e materiali legati al settore legale. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Contratti e Accordi Giuridici: `,
            listItem1Para:`Traduciamo contratti, accordi di licenza, accordi di non divulgazione (NDA) e altri documenti legali, garantendo la chiarezza e l'accuratezza dei termini contrattuali in tutte le lingue coinvolte.`,
            listItem2Title:`Documenti Giudiziari e Giuridici: `,
            listItem2Para:`Ci occupiamo della traduzione di documenti giudiziari, sentenze, petizioni, atti di citazione e altri documenti processuali, assicurando la comprensione accurata delle questioni legali in contesti multilingue.`,
            listItem3Title:`Leggi e Regolamenti: `,
            listItem3Para:`Traduciamo leggi, regolamenti, codici e altre disposizioni legislative, fornendo una traduzione accurata e adattata al contesto normativo specifico di ogni paese o regione.`,
            listItem4Title:`Corrispondenza Legale: `,
            listItem4Para:`Traduciamo corrispondenza legale, pareri legali, memorandum e altri documenti di comunicazione tra avvocati e clienti, mantenendo la riservatezza e la coerenza del linguaggio legale.`,
            listItem5Title:`Documentazione Aziendale e Contrattuale: `,
            listItem5Para:`Traduciamo documenti aziendali, statuti sociali, politiche interne e altri documenti contrattuali, garantendo la conformità alle leggi e normative locali e internazionali.`,
            para2:`La nostra equipe di traduttori specializzati nel settore legale comprende l'importanza della precisione, della riservatezza e della coerenza nelle traduzioni per garantire la validità e l'efficacia dei documenti legali in contesti internazionali. Con LingoYou, potete contare su traduzioni affidabili che supportano la vostra attività legale e commerciale in tutto il mondo.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore legale comprende l'importanza della precisione, della riservatezza e della coerenza nelle traduzioni per garantire la validità e l'efficacia dei documenti legali in contesti internazionali.`   
        },
        cover: '/clients/Legal/coverlegal.webp',
        coverPdf: '/clients/Legal/coverlegal.png',
        sector:'/clients/Legal/legal.webp',
        sectorPdf:'/clients/Legal/legal.png'
    },
    'Manufacturing':{
        en:{
            title: "Translations in the Manufacturing Sector - Expand Your Production Globally",
            para1: "LingoYou is your reliable partner in the field of translations for manufacturing, offering high-quality linguistic services for a wide range of essential documents in this creative and stimulating world. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "User and Technical Manuals:",
            listItem1Para: "We translate user manuals and maintenance manuals for machinery, equipment, and manufactured products, ensuring safety and efficiency in use.",
            listItem2Title: "Labels and Packaging:",
            listItem2Para: "The translation of labels and packaging instructions is essential for international trade. We ensure that the information is clear and compliant with local regulations.",
            listItem3Title: "Contracts and Agreements:",
            listItem3Para: "We translate inspection reports, certificates of conformity, and other documents related to the quality control of manufactured products.",
            listItem4Title: "Legal Correspondence:",
            listItem4Para: "Legal documentation in the manufacturing sector includes supply contracts, licensing agreements, and other commercial agreements. We guarantee the accuracy of contractual terms.",
            listItem5Title: "Internal and External Communications:",
            listItem5Para: "We translate company communications, newsletters, press releases, and marketing materials to promote manufacturing products and services globally.",
            para2: "Our team of translators specialized in the manufacturing sector understands the technical and cultural nuances necessary for accurate and consistent translation. We are ready to support you in your international expansion and ensure that your documents are understandable and professional in every language.",
            para2Pdf: "Our team of translators specialized in the manufacturing sector understands the technical and cultural nuances necessary for accurate and coherent translation."     
        },
        it:{
            title:`Traduzioni nel Settore Manifatturiero - Espandete la Vostra Produzione Globalmente`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per il manifatturiero, offrendo servizi linguistici di alta qualità per una vasta gamma di documenti essenziali in questo mondo creativo e stimolante. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Manuali Utente e Tecnici: `,
            listItem1Para:`Traduciamo manuali di utilizzo e manutenzione per macchinari, attrezzature e prodotti manifatturieri, garantendo la sicurezza e l’efficienza nell’uso.`,
            listItem2Title:`Etichette e Imballaggi: `,
            listItem2Para:`La traduzione di etichette e istruzioni di imballaggio è essenziale per il commercio internazionale. Assicuriamo che le informazioni siano chiare e conformi alle normative locali.`,
            listItem3Title:`Contratti e Accordi: `,
            listItem3Para:`Traduciamo report di ispezione, certificati di conformità e altri documenti relativi al controllo qualità dei prodotti manifatturieri.`,
            listItem4Title:`Corrispondenza Legale: `,
            listItem4Para:`La documentazione legale nel settore manifatturiero include contratti di fornitura, accordi di licenza e altri accordi commerciali. Garantiamo la precisione dei termini contrattuali.`,
            listItem5Title:`Comunicazioni Interne ed Esterne: `,
            listItem5Para:`Traduciamo comunicazioni aziendali, newsletter, comunicati stampa e materiali di marketing per promuovere prodotti e servizi manifatturieri a livello globale.`,
            para2:`La nostra squadra di traduttori specializzati nel settore manifatturiero comprende le sfumature tecniche e culturali necessarie per una traduzione accurata e coerente. Siamo pronti a supportarvi nella vostra espansione internazionale e a garantire che i vostri documenti siano comprensibili e professionali in ogni lingua.`,
            para2Pdf:`La nostra squadra di traduttori specializzati nel settore manifatturiero comprende le sfumature tecniche e culturali necessarie per una traduzione accurata e coerente.`     
        },
        cover: '/clients/Manufacturing/covermanufacturing.webp',
        coverPdf: '/clients/Manufacturing/covermanufacturing.png',
        sector:'/clients/Manufacturing/Manufacturing.webp',
        sectorPdf:'/clients/Manufacturing/Manufacturing.png'
    },
    'Entertainment Media':{
        en:{
            title: "Translations in the Entertainment and Media Sector - Your Linguistic Partner for Creative Excellence",
            para1: "LingoYou is your reliable partner in the field of translations for entertainment and media, offering specialized linguistic services for a wide range of content and materials related to the world of entertainment. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Scripts and Subtitles:",
            listItem1Para: "We translate film scripts, TV show scripts, subtitles, and dialogues for movies and TV series, ensuring the fluency and accuracy of translations to engage the international audience.",
            listItem2Title: "Production and Distribution Contracts:",
            listItem2Para: "We handle the translation of production contracts, distribution agreements, licensing contracts, and other contractual documents in the entertainment field, ensuring clear and comprehensive understanding of clauses and conditions in different languages.",
            listItem3Title: "Promotional and Advertising Materials:",
            listItem3Para: "We translate promotional materials such as trailers, posters, brochures, and advertisements, adapting the creative and engaging message to reach audiences of diverse cultures and languages.",
            listItem4Title: "Texts for Websites and Digital Platforms:",
            listItem4Para: "We offer translations for websites, streaming platforms, apps, and other digital content, maintaining the style and voice of the original to ensure a consistent and engaging experience for global users.",
            listItem5Title: "Legal and Contractual Documentation:",
            listItem5Para: "We translate actor contracts, music licensing agreements, employment contracts, and other legal documents in the entertainment and media industry, ensuring compliance with local and international laws and regulations.",
            para2: "Our team of translators specialized in the entertainment sector understands the importance of creativity, cultural sensitivity, and linguistic consistency to ensure the success of your projects globally. With LingoYou, you can rely on high-quality translations that enhance and promote your content worldwide.",
            para2Pdf: "Our team of translators specialized in the entertainment sector understands the importance of creativity, cultural sensitivity, and linguistic consistency to ensure the success of your projects globally."    
        },
        it:{
            title:`Traduzioni nel Settore dell'Intrattenimento e dei Media - Il Vostro Partner Linguistico per l'Eccellenza Creativa`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per l'intrattenimento e i media, offrendo servizi linguistici specializzati per una vasta gamma di contenuti e materiali legati al mondo dell'intrattenimento. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Sceneggiature e Sottotitoli: `,
            listItem1Para:`Traduciamo sceneggiature cinematografiche, script televisivi, sottotitoli e dialoghi per film e serie TV, garantendo la fluidità e l'accuratezza delle traduzioni per coinvolgere il pubblico internazionale.`,
            listItem2Title:`Contratti di Produzione e Distribuzione: `,
            listItem2Para:`Gestiamo la traduzione di contratti di produzione, accordi di distribuzione, contratti di licenza e altri documenti contrattuali nel campo dell'intrattenimento, assicurando la comprensione chiara e completa delle clausole e delle condizioni in diverse lingue.`,
            listItem3Title:`Materiali Promozionali e Pubblicitari: `,
            listItem3Para:`Traduciamo materiali promozionali come trailer, locandine, brochure e annunci pubblicitari, adattando il messaggio creativo e coinvolgente per raggiungere il pubblico di diverse culture e lingue.`,
            listItem4Title:`Testi per Siti Web e Piattaforme Digitali: `,
            listItem4Para:`Offriamo traduzioni per siti web, piattaforme di streaming, app e altri contenuti digitali, mantenendo lo stile e la voce dell'originale per garantire un'esperienza coerente e coinvolgente per gli utenti globali.`,
            listItem5Title:`Documentazione Legale e Contrattuale: `,
            listItem5Para:`Traduciamo contratti di attori, accordi di licenza musicale, contratti di lavoro e altri documenti legali nel settore dell'intrattenimento e dei media, garantendo la conformità alle leggi e normative locali e internazionali.`,
            para2:`La nostra equipe di traduttori specializzati nel settore dell'intrattenimento comprende l'importanza della creatività, della sensibilità culturale e della coerenza linguistica per garantire il successo dei vostri progetti a livello globale. Con LingoYou, potete contare su traduzioni di alta qualità che valorizzano e promuovono i vostri contenuti in tutto il mondo.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore dell'intrattenimento comprende l'importanza della creatività, della sensibilità culturale e della coerenza linguistica per garantire il successo dei vostri progetti a livello globale`   
        },
        cover: '/clients/Entertainment/coverentertainment.webp',
        coverPdf: '/clients/Entertainment/coverentertainment.png',
        sector:'/clients/Entertainment/Entertainment.webp',
        sectorPdf:'/clients/Entertainment/Entertainment.png'
    
    },
    'Human Resources':{
        en:{
            title: "Translations in the Human Resources Sector - Your Linguistic Partner for Organizational Efficiency",
            para1: "LingoYou is your reliable partner in the field of translations for human resources, offering specialized linguistic services for a wide range of documents and materials related to personnel management. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Company Policies and Procedures:",
            listItem1Para: "We translate company manuals, HR policies, and procedures, ensuring that guidelines and regulations are clear and understandable for employees in all languages involved.",
            listItem2Title: "Hiring and Onboarding Documents:",
            listItem2Para: "We handle the translation of job postings, resumes, hiring documents, and onboarding materials, ensuring effective and accurate communication with new employees.",
            listItem3Title: "Training and Development:",
            listItem3Para: "We translate training materials, online courses, training manuals, and documentation related to professional development, ensuring that employees can access necessary resources for learning in different languages.",
            listItem4Title: "Internal Communications:",
            listItem4Para: "We manage the translation of company newsletters, internal press releases, HR communications, and other forms of internal communication, maintaining consistency and transparency in the information conveyed to all employees.",
            listItem5Title: "Workplace Safety and Insurance Policies:",
            listItem5Para: "We translate workplace safety policies, emergency procedures, insurance documents, and other materials related to employee safety and well-being, ensuring understanding and compliance with regulations in different cultural contexts.",
            para2: "Our team of translators specialized in the human resources sector understands the importance of sensitivity and confidentiality regarding personnel matters. With LingoYou, you can rely on reliable translations that support efficient and inclusive management of your team worldwide.",
            para2Pdf: "Our team of translators specialized in the human resources sector understands the importance of sensitivity and confidentiality regarding personnel matters."  
        },
        it:{
            title:`Traduzioni nel Settore delle Risorse Umane - Il Vostro Partner Linguistico per l'Efficienza Organizzativa`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per le risorse umane, offrendo servizi linguistici specializzati per una vasta gamma di documenti e materiali legati alla gestione del personale. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Politiche Aziendali e Procedure: `,
            listItem1Para:`Traduciamo manuali aziendali, politiche e procedure HR, garantendo che le linee guida e le normative siano chiare e comprensibili per i dipendenti in tutte le lingue coinvolte.`,
            listItem2Title:`Documenti di Assunzione e Onboarding: `,
            listItem2Para:`Ci occupiamo della traduzione di annunci di lavoro, curriculum, documenti di assunzione e materiali per il processo di onboarding, assicurando una comunicazione efficace e accurata con i nuovi dipendenti.`,
            listItem3Title:`Formazione e Sviluppo: `,
            listItem3Para:`Traduciamo materiali formativi, corsi online, manuali di formazione e documentazione relativa allo sviluppo professionale, garantendo che i dipendenti possano accedere alle risorse necessarie per il loro apprendimento in diverse lingue.`,
            listItem4Title:`Comunicazioni Interne: `,
            listItem4Para:`Gestiamo la traduzione di newsletter aziendali, comunicati stampa interni, comunicazioni HR e altre forme di comunicazione interna, mantenendo coerenza e trasparenza nelle informazioni trasmesse a tutti i dipendenti.`,
            listItem5Title:`Politiche di Sicurezza sul Lavoro e Assicurative: `,
            listItem5Para:`Traduciamo politiche di sicurezza sul lavoro, procedure di emergenza, documenti assicurativi e altri materiali relativi alla sicurezza e al benessere dei dipendenti, assicurando la comprensione e l'adesione alle normative in diversi contesti culturali.`,
            para2:`La nostra equipe di traduttori specializzati nel settore delle risorse umane comprende l'importanza della sensibilità e della riservatezza nei confronti delle questioni del personale. Con LingoYou, potete contare su traduzioni affidabili che supportano la gestione efficiente e inclusiva del vostro team in tutto il mondo.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore delle risorse umane comprende l'importanza della sensibilità e della riservatezza nei confronti delle questioni del personale.`
        },
        cover: '/clients/HR/coverHR.webp',
        coverPdf: '/clients/HR/coverHR.png',
        sector:'/clients/HR/HR.webp',
        sectorPdf:'/clients/HR/HR.png' 
    },
    'Patents':{
        en:{
            title: "Translations in the Patent Sector - Your Linguistic Partner for Legal Precision",
            para1: "LingoYou is your reliable partner in the field of patent translations, offering specialized linguistic services for a wide range of legal and technical documents. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Patent Applications:",
            listItem1Para: "We translate patent applications, invention descriptions, claims, and technical drawings, ensuring the accurate transmission of specific invention information in all languages involved.",
            listItem2Title: "Patent Research and Analysis:",
            listItem2Para: "We handle the translation of patent searches, prior art reports, and other technical and legal analyses in the field of patents, ensuring accurate understanding of the legal and scientific issues involved.",
            listItem3Title: "Intellectual Property Documents:",
            listItem3Para: "We translate license agreements, technology transfer agreements, and other documents related to intellectual property, ensuring the correct interpretation of rights and obligations of the parties involved.",
            listItem4Title: "Legal Correspondence and Patent Office:",
            listItem4Para: "We manage the translation of legal correspondence, communications with patent offices, responses to oppositions, and other legal procedural documents, maintaining clarity and precision in legal language.",
            listItem5Title: "Expert Testimonies and Technical-Legal Reports:",
            listItem5Para: "We translate expert testimonies, technical reports, and other documents used in judicial proceedings and arbitration processes related to patents, ensuring accurate and persuasive communication of technical and legal arguments.",
            para2: "Our team of translators specialized in the patent sector understands the importance of terminological accuracy, legal coherence, and data confidentiality to ensure reliable translations that support the protection and valorization of your technological innovations. With LingoYou, you can rely on high-quality translations that contribute to the success and security of your patent portfolio globally.",
            para2Pdf: "Our team of translators specialized in the patent sector understands the importance of terminological precision, legal consistency, and data confidentiality to ensure reliable translations that support the protection and valorization of your technological innovations."  
        },
        it:{
            title:`Traduzioni nel Settore dei Brevetti - Il Vostro Partner Linguistico per la Precisione Giuridica`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per i brevetti, offrendo servizi linguistici specializzati per una vasta gamma di documenti legali e tecnici. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Domande di Brevetto: `,
            listItem1Para:`Traduciamo domande di brevetto, descrizioni di invenzioni, rivendicazioni e disegni tecnici, garantendo la corretta trasmissione delle informazioni specifiche dell'invenzione in tutte le lingue coinvolte.`,
            listItem2Title:`Ricerca e Analisi di Brevetti: `,
            listItem2Para:`Ci occupiamo della traduzione di ricerche di brevetti, relazioni di prior art, e altre analisi tecniche e legali nel campo dei brevetti, assicurando una comprensione accurata delle questioni giuridiche e scientifiche coinvolte.`,
            listItem3Title:`Documenti di Proprietà Intellettuale: `,
            listItem3Para:`Traduciamo contratti di licenza, accordi di trasferimento di tecnologia, e altri documenti legati alla proprietà intellettuale, garantendo la corretta interpretazione dei diritti e degli obblighi delle parti coinvolte.`,
            listItem4Title:`Corrispondenza Legale e Ufficio Brevetti: `,
            listItem4Para:`Gestiamo la traduzione di corrispondenza legale, comunicazioni con gli uffici brevetti, risposte ad opposizioni e altri documenti di procedura legale, mantenendo la chiarezza e la precisione del linguaggio giuridico.`,
            listItem5Title:`Testimonianze Esperte e Relazioni Tecnico-Legal: `,
            listItem5Para:`Traduciamo testimonianze esperte, relazioni tecniche e altri documenti utilizzati nei procedimenti giudiziari e nei processi di arbitrato legati ai brevetti, assicurando una comunicazione accurata e persuasiva delle argomentazioni tecniche e legali.`,
            para2:`La nostra equipe di traduttori specializzati nel settore dei brevetti comprende l'importanza della precisione terminologica, della coerenza giuridica e della riservatezza dei dati per garantire traduzioni affidabili che supportino la protezione e la valorizzazione delle vostre innovazioni tecnologiche. Con LingoYou, potete contare su traduzioni di alta qualità che contribuiscono al successo e alla sicurezza del vostro portafoglio brevettuale a livello globale.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore dei brevetti comprende l'importanza della precisione terminologica, della coerenza giuridica e della riservatezza dei dati per garantire traduzioni affidabili che supportino la protezione e la valorizzazione delle vostre innovazioni tecnologiche.`  
        },
        cover: '/clients/Patents/coverpatent.webp',
        coverPdf: '/clients/Patents/coverpatent.png',
        sector:'/clients/Patents/patent.webp',
        sectorPdf:'/clients/Patents/patent.png'
    },
    'Marketing':{
        en:{
            title: "Translations in the Marketing Sector - Your Linguistic Partner for Effective Communication",
            para1: "LingoYou is your reliable partner in the field of marketing translations, offering specialized linguistic services for a wide range of promotional content and materials. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Advertising Material:",
            listItem1Para: "We translate print advertisements, television commercials, online ads, and other promotional materials, adapting the message and tone to capture the attention of the target audience and stimulate action.",
            listItem2Title: "Websites and Digital Content:",
            listItem2Para: "We handle the translation of websites, company blogs, online articles, and other digital content, ensuring an effective and engaging online presence in all target languages.",
            listItem3Title: "Social Media Campaigns:",
            listItem3Para: "We translate social media posts, promotional ads, and content for social platforms, ensuring relevant and engaging communication for users of diverse cultures and languages.",
            listItem4Title: "Promotional Materials:",
            listItem4Para: "We manage the translation of brochures, flyers, catalogs, and other promotional materials, maintaining brand consistency and persuasive messaging to convince potential customers.",
            listItem5Title: "Global Marketing Strategies:",
            listItem5Para: "We translate marketing plans, market analyses, and other marketing strategies, ensuring clear understanding and proper execution of promotional initiatives in all target markets.",
            para2: "Our team of translators specialized in the marketing sector understands the importance of creativity, cultural adaptation, and message consistency for the success of your promotional initiatives globally. With LingoYou, you can rely on high-quality translations that amplify your brand and reach your audience worldwide.",
            para2Pdf: "Our team of translators specialized in the marketing sector understands the importance of creativity, cultural adaptation, and message consistency for the success of your promotional initiatives globally."      
        },
        it:{
            title:`Traduzioni nel Settore del Marketing - Il Vostro Partner Linguistico per la Comunicazione Efficace`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per il marketing, offrendo servizi linguistici specializzati per una vasta gamma di contenuti e materiali promozionali. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Materiale Pubblicitario: `,
            listItem1Para:`Traduciamo annunci stampa, spot televisivi, pubblicità online, e altri materiali promozionali, adattando il messaggio e il tono per catturare l'attenzione del pubblico di destinazione e stimolare l'azione.`,
            listItem2Title:`Siti Web e Contenuti Digitali: `,
            listItem2Para:`Ci occupiamo della traduzione di siti web, blog aziendali, articoli online, e altri contenuti digitali, garantendo una presenza online efficace e coinvolgente in tutte le lingue target.`,
            listItem3Title:`Campagne Social Media: `,
            listItem3Para:`Traduciamo post sui social media, annunci promozionali, e contenuti per piattaforme sociali, assicurando una comunicazione rilevante e accattivante per gli utenti di diverse culture e lingue.`,
            listItem4Title:`Materiali Promozionali: `,
            listItem4Para:`Gestiamo la traduzione di brochure, volantini, cataloghi, e altro materiale promozionale, mantenendo coerenza di marchio e persuasione nel convincere i potenziali clienti.`,
            listItem5Title:`Strategie di Marketing Globale: `,
            listItem5Para:`Traduciamo piani di marketing, analisi di mercato, e altre strategie di marketing, garantendo una comprensione chiara e una corretta esecuzione delle iniziative promozionali in tutti i mercati target.`,
            para2:`La nostra equipe di traduttori specializzati nel settore del marketing comprende l'importanza della creatività, dell'adattamento culturale, e della coerenza di messaggio per il successo delle vostre iniziative promozionali a livello globale. Con LingoYou, potete contare su traduzioni di alta qualità che amplificano il vostro brand e raggiungono il vostro pubblico in tutto il mondo.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore del marketing comprende l'importanza della creatività, dell'adattamento culturale, e della coerenza di messaggio per il successo delle vostre iniziative promozionali a livello globale.`   
        },
        cover: '/clients/Marketing/covermarketing.webp',
        coverPdf: '/clients/Marketing/covermarketing.png',
        sector:'/clients/Marketing/Marketing.webp',
        sectorPdf:'/clients/Marketing/Marketing.png'
    },
    'Literature':{
        en:{
            title: "Translations in the Literature Sector - Your Linguistic Partner for Artistic Excellence",
            para1: "LingoYou is your reliable partner in the field of literature translations, offering specialized linguistic services for a wide range of literary works and artistic texts. Here are some examples of documents we translate with expertise and passion:",
            listItem1Title: "Novels and Short Stories:",
            listItem1Para: "We translate novels, short story collections, and narrative works of various genres, preserving the style, voice, and essence of the original work to ensure that the reading experience remains faithful to the author's intent.",
            listItem2Title: "Poetry and Verses:",
            listItem2Para: "We handle the translation of poems, lyrics, and poetic compositions, striving to maintain the musicality, rhythm, and emotion of the original verses even in the target language.",
            listItem3Title: "Theater and Dramaturgy:",
            listItem3Para: "We translate plays, dramas, and texts for stage performances, ensuring a linguistic rendition that preserves the power and emotional impact of the words even on the foreign stage.",
            listItem4Title: "Literary Criticism and Academic Studies:",
            listItem4Para: "We manage the translation of essays, academic articles, reviews, and other critical-literary material, contributing to the dissemination and debate of ideas in the international literary landscape.",
            listItem5Title: "Children's Books and Young Readers:",
            listItem5Para: "We translate children's books, young adult novels, and other texts aimed at younger readers, adapting the language and tone to engage and inspire audiences of all ages.",
            para2: "Our team of translators specialized in the literature sector understands the importance of creativity, artistic sensitivity, and linguistic precision in transforming an author's words into a masterpiece accessible to readers worldwide. With LingoYou, you can rely on translations that respect and celebrate the beauty and diversity of literary works, contributing to the dissemination of culture and knowledge across languages.",
            para2Pdf: "Our team of translators specialized in the literature sector understands the importance of creativity, artistic sensitivity, and linguistic precision in transforming an author's words into a masterpiece accessible to readers worldwide."        
        },
        it:{
            title:`Traduzioni nel Settore della Letteratura - Il Vostro Partner Linguistico per l'Eccellenza Artistica`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per la letteratura, offrendo servizi linguistici specializzati per una vasta gamma di opere letterarie e testi artistici. Ecco alcuni esempi di documenti che traduciamo con competenza e passione:`,
            listItem1Title:`Romanzi e Racconti: `,
            listItem1Para:`Traduciamo romanzi, raccolte di racconti, e opere narrative di vario genere, preservando lo stile, la voce e l'essenza dell'opera originale per far sì che l'esperienza di lettura sia fedele all'intento dell'autore.`,
            listItem2Title:`Poesia e Versi: `,
            listItem2Para:`Ci occupiamo della traduzione di poesie, liriche, e componimenti poetici, cercando di mantenere la musicalità, il ritmo e l'emozione dei versi originali anche nella lingua di destinazione.`,
            listItem3Title:`Teatro e Drammaturgia: `,
            listItem3Para:`Traduciamo opere teatrali, drammi, e testi per performance sceniche, assicurando una resa linguistica che mantenga la potenza e l'impatto emotivo delle parole anche sul palcoscenico straniero.`,
            listItem4Title:`Critica Letteraria e Studi Accademici: `,
            listItem4Para:`Gestiamo la traduzione di saggi, articoli accademici, recensioni e altro materiale critico-letterario, contribuendo alla diffusione e al dibattito delle idee nel panorama letterario internazionale.`,
            listItem5Title:`Libri per l'Infanzia e Giovani Lettori: `,
            listItem5Para:`Traduciamo libri per bambini, romanzi per giovani adulti, e altri testi destinati ai lettori più giovani, adattando il linguaggio e il tono per coinvolgere e ispirare il pubblico di ogni età.`,
            para2:`La nostra equipe di traduttori specializzati nel settore della letteratura comprende l'importanza della creatività, della sensibilità artistica e della precisione linguistica nel trasformare le parole di un autore in un capolavoro accessibile a lettori di tutto il mondo. Con LingoYou, potete contare su traduzioni che rispettano e celebrano la bellezza e la diversità delle opere letterarie, contribuendo alla diffusione della cultura e della conoscenza attraverso le lingue.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore della letteratura comprende l'importanza della creatività, della sensibilità artistica e della precisione linguistica nel trasformare le parole di un autore in un capolavoro accessibile a lettori di tutto il mondo`      
        },
        cover: '/clients/Literature/coverliterature.webp',
        coverPdf: '/clients/Literature/coverliterature.png',
        sector:'/clients/Literature/literature.webp',
        sectorPdf:'/clients/Literature/literature.png' 
    },
    'Internet':{
        en:{
            title: "Translations in the Internet Sector - Your Linguistic Partner for Global Connection",
            para1: "LingoYou is your reliable partner in the field of translations for the Internet, offering specialized linguistic services for a wide range of digital content and materials. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Websites and Applications:",
            listItem1Para: "We translate websites, mobile applications, e-commerce platforms, and other online resources, ensuring a smooth and consistent user experience in all languages.",
            listItem2Title: "Digital Content:",
            listItem2Para: "We handle the translation of articles, blogs, social media posts, e-books, and other digital content, adapting the language and style to the culture and target audience.",
            listItem3Title: "Privacy Policies and Terms of Service:",
            listItem3Para: "We translate privacy policies, terms of service, terms of use, and other legal information, ensuring compliance with local and international regulations regarding data protection and user rights.",
            listItem4Title: "Online Marketing Material:",
            listItem4Para: "We translate online advertisements, newsletters, marketing emails, and other promotional communications, ensuring a persuasive and engaging message for recipients of different languages and cultures.",
            listItem5Title: "Technical Documentation:",
            listItem5Para: "We manage the translation of technical documentation, user manuals, installation guides, and other informative resources, ensuring the accuracy and comprehensibility of instructions in all target markets.",
            para2: "Our team of translators specialized in the Internet sector understands the importance of technology, innovation, and digital communication. With LingoYou, you can rely on high-quality translations that help you connect with your global audience and expand your online presence worldwide.",
            para2Pdf: "Our team of translators specialized in the Internet sector understands the importance of technology, innovation, and digital communication."   
        },
        it:{
            title:`Traduzioni nel Settore di Internet - Il Vostro Partner Linguistico per la Connessione Globale`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per Internet, offrendo servizi linguistici specializzati per una vasta gamma di contenuti e materiali legati al mondo digitale. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Siti Web e Applicazioni: `,
            listItem1Para:`Traduciamo siti web, applicazioni mobili, piattaforme di e-commerce e altre risorse online, garantendo un'esperienza utente fluida e coerente in tutte le lingue.`,
            listItem2Title:`Contenuti Digitali: `,
            listItem2Para:`Ci occupiamo della traduzione di articoli, blog, post sui social media, e-book e altri contenuti digitali, adattando il linguaggio e lo stile alla cultura e al pubblico di destinazione.`,
            listItem3Title:`Politiche sulla Privacy e Termini di Servizio: `,
            listItem3Para:`Traduciamo politiche sulla privacy, termini di servizio, condizioni d'uso e altre informazioni legali, assicurando la conformità alle normative locali e internazionali in materia di protezione dei dati e diritti degli utenti.`,
            listItem4Title:`Materiale di Marketing Online: `,
            listItem4Para:`Traduciamo annunci pubblicitari online, newsletter, e-mail di marketing e altre comunicazioni promozionali, garantendo un messaggio persuasivo e coinvolgente per i destinatari di diverse lingue e culture.`,
            listItem5Title:`Documentazione Tecnica: `,
            listItem5Para:`Gestiamo la traduzione di documentazione tecnica, manuali utente, guide di installazione e altre risorse informative, assicurando la precisione e la comprensibilità delle istruzioni in tutti i mercati target.`,
            para2:`La nostra equipe di traduttori specializzati nel settore di Internet comprende l'importanza della tecnologia, dell'innovazione e della comunicazione digitale. Con LingoYou, potete contare su traduzioni di alta qualità che vi aiutano a connettervi con il vostro pubblico globale e a espandere la vostra presenza online in tutto il mondo.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore di Internet comprende l'importanza della tecnologia, dell'innovazione e della comunicazione digitale.`   
        },
        cover: '/clients/Internet/coverinternet.webp',
        coverPdf: '/clients/Internet/coverinternet.png',
        sector:'/clients/Internet/Internet.webp',
        sectorPdf:'/clients/Internet/Internet.png'
    },
    'IT':{
        en:{
            title: "Translations in the IT Sector - Your Linguistic Partner for Technical Precision",
            para1: "LingoYou is your reliable partner in the field of translations for Information Technology (IT), offering specialized linguistic services for a wide range of documents and materials related to technology. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Technical Documentation:",
            listItem1Para: "We translate user manuals, installation guides, technical specifications, and support documentation for software and hardware, ensuring clarity and accuracy of information in all involved languages.",
            listItem2Title: "Software Development:",
            listItem2Para: "We handle the translation of source code, programming strings, design documents, and other materials used in the software development process, ensuring consistency and integrity of code in multilingual environments.",
            listItem3Title: "Application and Website Localization:",
            listItem3Para: "We translate user interfaces (UI), error messages, menus, and other components of software applications and websites, adapting language and terminology to the culture and language preferences of the recipients.",
            listItem4Title: "IT Project Management:",
            listItem4Para: "We manage the translation of project management documents, work plans, progress reports, and other internal and external communications in the IT field, ensuring effective communication among international teams and clients.",
            listItem5Title: "Cybersecurity and Regulations:",
            listItem5Para: "We translate cybersecurity policies, regulatory compliance procedures, risk assessment documents, and other resources related to data protection and regulatory compliance, ensuring compliance with local and international laws and regulations.",
            para2: "Our team of translators specialized in the IT sector understands the importance of technical precision, terminological consistency, and familiarity with the latest trends and technologies in the industry. With LingoYou, you can rely on reliable translations that support your projects and goals in the field of Information Technology worldwide.",
            para2Pdf: "Our team of translators specialized in the IT sector understands the importance of technical accuracy, terminological consistency, and familiarity with the latest trends and technologies in the industry."     
        },
        it:{
            title:`Traduzioni nel Settore IT - Il Vostro Partner Linguistico per la Precisione Tecnologica`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per l'Information Technology (IT), offrendo servizi linguistici specializzati per una vasta gamma di documenti e materiali legati alla tecnologia. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Documentazione Tecnica: `,
            listItem1Para:`Traduciamo manuali utente, guide di installazione, specifiche tecniche e documentazione di supporto per software e hardware, garantendo la chiarezza e l'accuratezza delle informazioni in tutte le lingue coinvolte.`,
            listItem2Title:`Sviluppo Software: `,
            listItem2Para:`Ci occupiamo della traduzione di codice sorgente, stringhe di programmazione, documenti di progettazione e altri materiali utilizzati nel processo di sviluppo software, assicurando la coerenza e l'integrità del codice in ambienti multilingue.`,
            listItem3Title:`Localizzazione di Applicazioni e Siti Web: `,
            listItem3Para:`Traduciamo interfacce utente (UI), messaggi di errore, menu e altre componenti di applicazioni software e siti web, adattando il linguaggio e la terminologia alla cultura e alle preferenze linguistiche dei destinatari. `,
            listItem4Title:`Gestione dei Progetti IT: `,
            listItem4Para:`Gestiamo la traduzione di documenti di gestione dei progetti, piani di lavoro, report di progresso e altre comunicazioni interne ed esterne nel campo dell'IT, garantendo una comunicazione efficace tra team e clienti internazionali.`,
            listItem5Title:`Sicurezza Informatica e Normative: `,
            listItem5Para:`Traduciamo politiche di sicurezza informatica, procedure di conformità normativa, documenti di valutazione del rischio e altre risorse relative alla protezione dei dati e alla conformità normativa, assicurando la conformità alle leggi e ai regolamenti locali e internazionali.`,
            para2:`La nostra equipe di traduttori specializzati nel settore IT comprende l'importanza della precisione tecnica, della coerenza terminologica e della familiarità con le ultime tendenze e tecnologie del settore. Con LingoYou, potete contare su traduzioni affidabili che supportano i vostri progetti e obiettivi nel campo dell'Information Technology in tutto il mondo.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore IT comprende l'importanza della precisione tecnica, della coerenza terminologica e della familiarità con le ultime tendenze e tecnologie del settore.`  
        },
        cover: '/clients/IT/coverIT.webp',
        coverPdf: '/clients/IT/coverIT.png',
        sector:'/clients/IT/IT.webp',
        sectorPdf:'/clients/IT/IT.png'
    },
    'Leisure':{
        en:{
            title: "Translations in the Leisure Sector - Your Linguistic Partner for Excellence",
            para1: "LingoYou is your reliable partner in the field of translations for leisure, offering specialized linguistic services for a wide range of content and materials related to entertainment and leisure activities. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Tourist Guides and Promotional Materials:",
            listItem1Para: "We translate tourist guides, brochures, leaflets, and other promotional materials for tourist attractions, cultural events, theme parks, and other leisure destinations, ensuring an engaging experience for international visitors.",
            listItem2Title: "Travel and Adventure Publications:",
            listItem2Para: "We handle the translation of travel books, specialized magazines, travel blogs, and other publications related to tourism and adventure, ensuring that readers of different languages can fully enjoy the experiences narrated.",
            listItem3Title: "Events and Shows:",
            listItem3Para: "We translate promotional material for sports events, concerts, festivals, theatrical performances, and other leisure events, adapting language and style to reach audiences of different cultures and languages.",
            listItem4Title: "Holiday Plans and Reservations:",
            listItem4Para: "We manage the translation of holiday packages, accommodation bookings, travel itineraries, and other practical information for international travelers, ensuring clear and accurate communication during vacation planning.",
            listItem5Title: "Entertainment Applications and Platforms:",
            listItem5Para: "We translate mobile applications, streaming platforms, online games, and other digital resources for leisure time, ensuring an optimal user experience for users of different languages and cultural backgrounds.",
            para2: "Our team of translators specialized in the leisure sector understands the importance of enthusiasm, creativity, and cultural sensitivity to provide translations that enrich and promote fun and relaxing experiences worldwide. With LingoYou, you can count on high-quality translations that turn your leisure time into unforgettable adventures, wherever you go.",
            para2Pdf: "Our team of translators specialized in the leisure sector understands the importance of enthusiasm, creativity, and cultural sensitivity to provide translations that enrich and promote fun and relaxation experiences worldwide."     
        },
        it:{
            title:`Traduzioni nel Settore del Tempo Libero - Il Vostro Partner Linguistico per l'Eccellenza`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per il leisure, offrendo servizi linguistici specializzati per una vasta gamma di contenuti e materiali legati al divertimento e al tempo libero. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Guide Turistiche e Materiale Promozionale: `,
            listItem1Para:`Traduciamo guide turistiche, brochure, opuscoli e altri materiali promozionali per attrazioni turistiche, eventi culturali, parchi a tema e altre destinazioni leisure, garantendo un'esperienza coinvolgente per i visitatori internazionali.`,
            listItem2Title:`Pubblicazioni su Viaggi e Avventura: `,
            listItem2Para:`Ci occupiamo della traduzione di libri di viaggio, riviste specializzate, blog di viaggio e altre pubblicazioni legate al turismo e all'avventura, assicurando che i lettori di diverse lingue possano godere appieno delle esperienze narrate.`,
            listItem3Title:`Eventi e Spettacoli: `,
            listItem3Para:`Traduciamo materiale promozionale per eventi sportivi, concerti, festival, spettacoli teatrali e altre manifestazioni leisure, adattando il linguaggio e lo stile per raggiungere il pubblico di diverse culture e lingue.`,
            listItem4Title:`Piani Vacanza e Prenotazioni: `,
            listItem4Para:`Gestiamo la traduzione di pacchetti vacanza, prenotazioni di alloggi, itinerari di viaggio e altre informazioni pratiche per i viaggiatori internazionali, assicurando una comunicazione chiara e accurata durante la pianificazione delle vacanze.`,
            listItem5Title:`Applicazioni e Piattaforme di Intrattenimento: `,
            listItem5Para:`Traduciamo applicazioni mobili, piattaforme di streaming, giochi online e altre risorse digitali per il tempo libero, garantendo un'esperienza utente ottimale per gli utenti di diverse lingue e background culturali.`,
            para2:`La nostra equipe di traduttori specializzati nel settore leisure comprende l'importanza dell'entusiasmo, della creatività e della sensibilità culturale per fornire traduzioni che arricchiscano e promuovano esperienze di divertimento e relax in tutto il mondo. Con LingoYou, potete contare su traduzioni di alta qualità che trasformano il vostro tempo libero in avventure indimenticabili, ovunque voi andiate.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore leisure comprende l'importanza dell'entusiasmo, della creatività e della sensibilità culturale per fornire traduzioni che arricchiscano e promuovano esperienze di divertimento e relax in tutto il mondo`  
        },
        cover: '/clients/Leisure/coverleisure.webp',
        coverPdf: '/clients/Leisure/coverleisure.png',
        sector:'/clients/Leisure/Leisure.webp',
        sectorPdf:'/clients/Leisure/Leisure.png'
    },
    'Sports':{
        en:{
            title: "Translations in the Sports Sector - Your Linguistic Partner for Sporting Excellence",
            para1: "LingoYou is your reliable partner in the field of translations for sports, offering specialized linguistic services for a wide range of content and materials related to sports activities. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Press Releases and Sports Articles:",
            listItem1Para: "We translate press releases, sports articles, interviews, and other forms of communication in the world of sports, ensuring international coverage and clear understanding of sports events.",
            listItem2Title: "Sports Regulations and Norms:",
            listItem2Para: "We handle the translation of sports regulations, disciplinary codes, statutes, and other regulatory provisions in the context of sports, ensuring compliance and understanding of the rules in different languages.",
            listItem3Title: "Sports Commentary and Live Broadcasts:",
            listItem3Para: "We translate sports commentary, live event descriptions, sports television and radio broadcasts, ensuring engaging and accurate narration for international viewers.",
            listItem4Title: "Training Programs and Sports Techniques:",
            listItem4Para: "We manage the translation of training programs, technical guides, instruction manuals for sports equipment, and other resources useful for athletes and sports enthusiasts worldwide.",
            listItem5Title: "Sports Marketing and Sponsorships:",
            listItem5Para: "We translate promotional materials, advertisements, sponsorship contracts, and other marketing communications in the context of sports, ensuring effective promotion and visibility for brands and sports events.",
            para2: "Our team of translators specialized in the sports sector understands the importance of passion, energy, and technical precision to offer translations that capture the competitive spirit and enthusiasm of the sports world. With LingoYou, you can count on high-quality translations that celebrate and spread the passion for sports worldwide.",
            para2Pdf: "Our team of translators specialized in the sports sector understands the importance of passion, energy, and technical precision to provide translations that capture the competitive spirit and enthusiasm of the sports world."     
        },
        it:{
            title:`Traduzioni nel Settore dello Sport - Il Vostro Partner Linguistico per l'Eccellenza Sportiva`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per lo sport, offrendo servizi linguistici specializzati per una vasta gamma di contenuti e materiali legati all'attività sportiva. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Comunicati Stampa e Articoli Sportivi: `,
            listItem1Para:`Traduciamo comunicati stampa, articoli sportivi, interviste e altre forme di comunicazione nel mondo dello sport, garantendo una copertura internazionale e una comprensione chiara delle vicende sportive.`,
            listItem2Title:`Regolamenti e Normative Sportive: `,
            listItem2Para:`Ci occupiamo della traduzione di regolamenti sportivi, codici disciplinari, statuti e altre disposizioni normative nel contesto dello sport, assicurando la conformità e la comprensione delle norme in diverse lingue.`,
            listItem3Title:`Commento Sportivo e Trasmissioni in Diretta: `,
            listItem3Para:`Traduciamo commenti sportivi, descrizioni di eventi in diretta, trasmissioni televisive e radiofoniche sportive, assicurando una narrazione coinvolgente e accurata per gli spettatori internazionali.`,
            listItem4Title:`Programmi di Allenamento e Tecniche Sportive: `,
            listItem4Para:`Gestiamo la traduzione di programmi di allenamento, guide tecniche, manuali di istruzioni per attrezzature sportive e altre risorse utili per gli atleti e gli appassionati di sport in tutto il mondo.`,
            listItem5Title:`Marketing e Sponsorizzazioni Sportive: `,
            listItem5Para:`Traduciamo materiali promozionali, annunci pubblicitari, contratti di sponsorizzazione e altre comunicazioni di marketing nel contesto dello sport, garantendo un'efficace promozione e visibilità per i brand e gli eventi sportivi.`,
            para2:`La nostra equipe di traduttori specializzati nel settore dello sport comprende l'importanza della passione, dell'energia e della precisione tecnica per offrire traduzioni che catturino lo spirito competitivo e l'entusiasmo del mondo sportivo. Con LingoYou, potete contare su traduzioni di alta qualità che celebrano e diffondono la passione per lo sport in tutto il mondo.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore dello sport comprende l'importanza della passione, dell'energia e della precisione tecnica per offrire traduzioni che catturino lo spirito competitivo e l'entusiasmo del mondo sportivo`   
        },
        cover: '/clients/Sports/coversports.webp',
        coverPdf: '/clients/Sports/coversports.png',
        sector:'/clients/Sports/sports.webp',
        sectorPdf:'/clients/Sports/sports.png'
    },
    'Life Sciences':{
        en:{
            title: "Translations in the Life Sciences Sector - Your Linguistic Partner for Scientific Excellence",
            para1: "LingoYou is your reliable partner in the field of translations for life sciences, offering specialized linguistic services for a wide range of content and materials related to biological and medical sciences. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Clinical Research and Experimental Studies:",
            listItem1Para: "We translate research protocols, clinical trial reports, informed consent documents, and other materials used in experimentation and research processes in the field of life sciences.",
            listItem2Title: "Regulatory Documentation and Compliance:",
            listItem2Para: "We handle the translation of regulatory documents, registration dossiers, pharmaceutical product labels, and other regulatory materials in the life sciences sector, ensuring compliance with local and international regulations.",
            listItem3Title: "Medical Manuals and Guides:",
            listItem3Para: "We translate instruction manuals for medical devices, guides for healthcare professionals, pharmacological monographs, and other informative resources used in the medical and healthcare context.",
            listItem4Title: "Scientific and Academic Publications:",
            listItem4Para: "We manage the translation of scientific articles, academic research, medical publications, and textbooks in the field of life sciences, ensuring the accuracy and clarity of scientific content.",
            listItem5Title: "Healthcare Communications and Outreach:",
            listItem5Para: "We translate informative brochures, patient educational materials, press releases, and other resources intended for healthcare communication and scientific outreach, adapting the language to the target audience.",
            para2: "Our team of translators specialized in the life sciences sector understands the importance of scientific accuracy, ethical sensitivity, and regulatory compliance to ensure reliable and high-quality translations in the medical and biological field. With LingoYou, you can count on translations that support research, clinical practice, and innovation in the life sciences sector, contributing to the improvement of global health and well-being.",
            para2Pdf: "Our team of translators specialized in the life sciences sector understands the importance of scientific accuracy, ethical sensitivity, and regulatory compliance to ensure reliable and high-quality translations in the medical and biological fields."   
        },
        it:{
            title:`Traduzioni nel Settore delle Scienze della Vita - Il Vostro Partner Linguistico per l'Eccellenza Scientifica`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per le scienze della vita, offrendo servizi linguistici specializzati per una vasta gamma di contenuti e materiali legati alle scienze biologiche e mediche. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Ricerche Cliniche e Studi Sperimentali: `,
            listItem1Para:`Traduciamo protocolli di ricerca, rapporti di sperimentazione clinica, documenti di consenso informato e altri materiali utilizzati nei processi di sperimentazione e ricerca nel campo delle scienze della vita.`,
            listItem2Title:`Documentazione Regolamentare e Normativa: `,
            listItem2Para:`Ci occupiamo della traduzione di documenti regolamentari, dossier di registrazione, etichette di prodotti farmaceutici e altri materiali normativi nel settore delle scienze della vita, garantendo la conformità alle normative locali e internazionali.`,
            listItem3Title:`Manuali e Guide Mediche: `,
            listItem3Para:`Traduciamo manuali di istruzioni per dispositivi medici, guide per operatori sanitari, monografie farmacologiche e altre risorse informative utilizzate nel contesto medico e sanitario.`,
            listItem4Title:`Pubblicazioni Scientifiche e Accademiche: `,
            listItem4Para:`Gestiamo la traduzione di articoli scientifici, ricerche accademiche, pubblicazioni mediche e libri di testo nel campo delle scienze della vita, assicurando la precisione e la chiarezza dei contenuti scientifici.`,
            listItem5Title:`Comunicazioni Sanitarie e Divulgative: `,
            listItem5Para:`Traduciamo brochure informative, materiale educativo per pazienti, comunicati stampa e altre risorse destinate alla comunicazione sanitaria e alla divulgazione scientifica, adattando il linguaggio al pubblico di destinazione.`,
            para2:`La nostra equipe di traduttori specializzati nel settore delle scienze della vita comprende l'importanza della precisione scientifica, della sensibilità etica e della conformità normativa per garantire traduzioni affidabili e di alta qualità nel campo medico e biologico. Con LingoYou, potete contare su traduzioni che supportano la ricerca, la pratica clinica e l'innovazione nel settore delle scienze della vita, contribuendo al miglioramento della salute e del benessere globale.`, 
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore delle scienze della vita comprende l'importanza della precisione scientifica, della sensibilità etica e della conformità normativa per garantire traduzioni affidabili e di alta qualità nel campo medico e biologico.`
        },
        cover: '/clients/LifeSciences/coverlifesciences.webp',
        coverPdf: '/clients/LifeSciences/coverlifesciences.png',
        sector:'/clients/LifeSciences/LifeSciences.webp',
        sectorPdf:'/clients/LifeSciences/LifeSciences.png'
    
    },
    'Religious':{
        en:{
            title: "Translations in the Religious Sector - Your Linguistic Partner for Spiritual Communication",
            para1: "LingoYou is your reliable partner in the field of translations for the religious world, offering specialized linguistic services for a wide range of content and materials related to faith and spirituality. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Sacred and Religious Texts:",
            listItem1Para: "We translate sacred texts such as the Bible, the Quran, the Bhagavad Gita, and other fundamental religious texts, ensuring accurate and respectful interpretation of spiritual messages and religious principles.",
            listItem2Title: "Prayers and Liturgies:",
            listItem2Para: "We handle the translation of prayers, liturgies, hymns, and other texts used in religious rites and ceremonies, ensuring understanding and participation of diverse language communities.",
            listItem3Title: "Scriptures and Commentaries:",
            listItem3Para: "We translate religious scriptures and theological commentaries, texts of scholars and spiritual leaders, ensuring accurate transmission of interpretations and reflections on faith and religious practice.",
            listItem4Title: "Educational and Spiritual Material:",
            listItem4Para: "We manage the translation of religious books for children, catechetical material, biblical study courses, and other educational and spiritual materials used in religious communities and educational institutions.",
            listItem5Title: "Church Communications:",
            listItem5Para: "We translate official church communications, sermons, parish bulletins, and other documents for internal and external communication, ensuring accurate transmission of spiritual and pastoral messages.",
            para2: "Our team of translators specialized in the religious sector understands the importance of cultural sensitivity, devotion, and linguistic precision in conveying the values and traditions of different faiths. With LingoYou, you can count on translations that respect and enhance the richness of religious diversity and contribute to the spread of compassion and understanding among people worldwide.",
            para2Pdf: "Our team of translators specialized in the religious sector understands the importance of cultural sensitivity, devotion, and linguistic precision in conveying the values and traditions of different faiths."   
        },
        it:{
            title:`Traduzioni nel Settore Religioso - Il Vostro Partner Linguistico per la Comunicazione Spirituale`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per il mondo religioso, offrendo servizi linguistici specializzati per una vasta gamma di contenuti e materiali legati alla fede e alla spiritualità. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Testi Sacri e Religiosi: `,
            listItem1Para:`Traduciamo testi sacri come la Bibbia, il Corano, la Bhagavad Gita e altri testi religiosi fondamentali, garantendo un'interpretazione accurata e rispettosa dei messaggi spirituali e dei principi religiosi.`,
            listItem2Title:`Preghiere e Liturgie: `,
            listItem2Para:`Ci occupiamo della traduzione di preghiere, liturgie, inni e altri testi utilizzati nei riti e nelle cerimonie religiose, assicurando la comprensione e la partecipazione delle comunità di lingua diversa.`,
            listItem3Title:`Scritture e Commentari: `,
            listItem3Para:`Traduciamo scritture religiose e commentari teologici, testi di studiosi e leader spirituali, garantendo la trasmissione accurata delle interpretazioni e delle riflessioni sulla fede e sulla pratica religiosa.`,
            listItem4Title:`Materiale Educativo e Spirituale: `,
            listItem4Para:`Gestiamo la traduzione di libri religiosi per bambini, materiale catechistico, corsi di studio biblici e altri materiali educativi e spirituali utilizzati nelle comunità religiose e nelle istituzioni educative.`,
            listItem5Title:`Comunicazioni Ecclesiastiche: `,
            listItem5Para:`Traduciamo comunicazioni ufficiali della chiesa, sermoni, bollettini parrocchiali e altri documenti di comunicazione interna ed esterna, garantendo la trasmissione accurata dei messaggi spirituali e pastorali.`,
            para2:`La nostra equipe di traduttori specializzati nel settore religioso comprende l'importanza della sensibilità culturale, della devozione e della precisione linguistica nel trasmettere i valori e le tradizioni delle diverse fedi. Con LingoYou, potete contare su traduzioni che rispettano e valorizzano la ricchezza della diversità religiosa e contribuiscono alla diffusione della compassione e della comprensione tra le persone di tutto il mondo.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore religioso comprende l'importanza della sensibilità culturale, della devozione e della precisione linguistica nel trasmettere i valori e le tradizioni delle diverse fedi.`   
        },
        cover: '/clients/Religious/coverreligious.webp',
        coverPdf: '/clients/Religious/coverreligious.png',
        sector:'/clients/Religious/religious.webp',
        sectorPdf:'/clients/Religious/religious.png'
    },
    'Public Sector':{
        en:{
            title: "Translations in the Public Sector - Your Linguistic Partner for Institutional Communication",
            para1: "LingoYou is your reliable partner in the field of translations for the public sector, offering specialized linguistic services for a wide range of institutional documents and materials. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Laws and Regulations:",
            listItem1Para: "We translate laws, decrees, regulations, and other legislative acts, ensuring accurate interpretation of legislative provisions for citizens and industry operators.",
            listItem2Title: "Administrative Documents:",
            listItem2Para: "We handle the translation of administrative documents such as minutes, reports, and official correspondence, ensuring clarity and consistency of institutional language.",
            listItem3Title: "Public Policies and Strategies:",
            listItem3Para: "We translate public policy documents, strategic plans, and institutional reports, facilitating the dissemination and understanding of government decisions and actions.",
            listItem4Title: "Institutional Communications:",
            listItem4Para: "We manage the translation of press releases, official speeches, and informational materials for the public, ensuring effective and inclusive communication with citizens and communities.",
            listItem5Title: "Social and Welfare Documentation:",
            listItem5Para: "We translate documents related to social services, welfare policies, and support programs, ensuring the correct utilization of services by beneficiaries.",
            para2: "Our team of translators specialized in the public sector understands the importance of transparency, accessibility, and accuracy in providing linguistic services that support government action and promote the well-being and participation of citizens. With LingoYou, you can count on high-quality translations that strengthen trust and collaboration between institutions and civil society, contributing to the building of inclusive and informed communities.",
            para2Pdf: "Our team of translators specialized in the public sector understands the importance of transparency, accessibility, and accuracy in providing language services that support government action and promote the well-being and participation of citizens."     
        },
        it:{
            title:`Traduzioni nel Settore Pubblico - Il Vostro Partner Linguistico per la Comunicazione Istituzionale`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per il pubblico, offrendo servizi linguistici specializzati per una vasta gamma di documenti e materiali istituzionali. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Leggi e Regolamenti: `,
            listItem1Para:`Traduciamo leggi, decreti, regolamenti e altri atti normativi, garantendo una corretta interpretazione delle disposizioni legislative per cittadini e operatori del settore.`,
            listItem2Title:`Documenti Amministrativi: `,
            listItem2Para:`Ci occupiamo della traduzione di documenti amministrativi come verbali, relazioni, e corrispondenza ufficiale, assicurando la chiarezza e la coerenza del linguaggio istituzionale.`,
            listItem3Title:`Politiche Pubbliche e Strategie: `,
            listItem3Para:`Traduciamo documenti di politica pubblica, piani strategici, e relazioni istituzionali, facilitando la diffusione e la comprensione delle decisioni e delle azioni governative.`,
            listItem4Title:`Comunicazioni Istituzionali: `,
            listItem4Para:`Gestiamo la traduzione di comunicati stampa, discorsi ufficiali, e materiali informativi per il pubblico, garantendo una comunicazione efficace e inclusiva con i cittadini e le comunità.`,
            listItem5Title:`Documentazione Sociale e Welfare: `,
            listItem5Para:`Traduciamo documenti relativi ai servizi sociali, alle politiche di welfare, e ai programmi di sostegno, assicurando la corretta fruizione dei servizi da parte dei destinatari.`,
            para2:`La nostra equipe di traduttori specializzati nel settore pubblico comprende l'importanza della trasparenza, dell'accessibilità e della precisione nel fornire servizi linguistici che supportino l'azione governativa e promuovano il benessere e la partecipazione dei cittadini. Con LingoYou, potete contare su traduzioni di alta qualità che rafforzano la fiducia e la collaborazione tra le istituzioni e la società civile, contribuendo alla costruzione di comunità inclusive e informate.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore pubblico comprende l'importanza della trasparenza, dell'accessibilità e della precisione nel fornire servizi linguistici che supportino l'azione governativa e promuovano il benessere e la partecipazione dei cittadini`   
        },
        cover: '/clients/PublicSector/coverpublicsector.webp',
        coverPdf: '/clients/PublicSector/coverpublicsector.png',
        sector:'/clients/PublicSector/publicSector.webp',
        sectorPdf:'/clients/PublicSector/publicSector.png' 
    },
    'Retail':{
        en:{
            title: "Translations in the Retail Sector - Your Linguistic Partner for Global Expansion",
            para1: "LingoYou is your reliable partner in the retail sector, offering specialized linguistic services for a wide range of content and materials aimed at retail commerce. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Websites and Online Catalogs:",
            listItem1Para: "We translate e-commerce websites, online catalogs, product descriptions, and other digital resources to ensure an engaging and accessible shopping experience for international customers.",
            listItem2Title: "Promotional Material:",
            listItem2Para: "We handle the translation of promotional flyers, brochures, advertisements, and other marketing materials, adapting the message and tone for audiences of different cultures and languages.",
            listItem3Title: "Labels and Packaging:",
            listItem3Para: "We translate product labels, packaging, and usage instructions, ensuring that product information is understandable and compliant with local regulations in target markets.",
            listItem4Title: "Customer Communications:",
            listItem4Para: "We manage the translation of promotional emails, newsletters, app push notifications, and other communications directed at customers, maintaining personalized and engaging communication in all languages.",
            listItem5Title: "Staff Training:",
            listItem5Para: "We translate training manuals, sales guides, onboarding materials, and more to ensure that staff can understand and provide high-quality service in multilingual contexts.",
            para2: "Our team of translators specialized in the retail sector understands the importance of accurately presenting products, persuasive communication, and customer satisfaction for business success. With LingoYou, you can count on high-quality translations that support your global expansion strategy and enhance the overall experience of your customers worldwide.",
            para2Pdf: "Our team of translators specialized in the retail sector understands the importance of accurately presenting products, persuasive communication, and customer satisfaction for business success."       
        },
        it:{
            title:`Traduzioni nel Settore Retail - Il Vostro Partner Linguistico per l'Espansione Globale  `,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per il retail, offrendo servizi linguistici specializzati per una vasta gamma di contenuti e materiali destinati al commercio al dettaglio. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Siti Web e Cataloghi Online: `,
            listItem1Para:`Traduciamo siti web di e-commerce, cataloghi online, descrizioni dei prodotti e altre risorse digitali per garantire una shopping experience coinvolgente e accessibile per i clienti internazionali.`,
            listItem2Title:`Materiale Promozionale: `,
            listItem2Para:`Ci occupiamo della traduzione di volantini promozionali, brochure, annunci pubblicitari e altri materiali di marketing, adattando il messaggio e il tono per il pubblico di diverse culture e lingue.`,
            listItem3Title:`Etichette e Packaging: `,
            listItem3Para:`Traduciamo etichette dei prodotti, packaging e istruzioni per l'uso, assicurando che le informazioni sui prodotti siano comprensibili e conformi alle normative locali nei mercati di destinazione.`,
            listItem4Title:`Comunicazioni Clienti: `,
            listItem4Para:`Gestiamo la traduzione di e-mail promozionali, newsletter, messaggi push delle app e altre comunicazioni dirette ai clienti, mantenendo una comunicazione personalizzata e coinvolgente in tutte le lingue.`,
            listItem5Title:`Formazione del Personale: `,
            listItem5Para:`Traduciamo manuali di formazione, guide di vendita, materiali di onboarding e altro ancora per garantire che il personale possa comprendere e fornire un servizio di alta qualità in contesti multilingue.`,
            para2:`La nostra equipe di traduttori specializzati nel settore retail comprende l'importanza della presentazione accurata dei prodotti, della comunicazione persuasiva e della soddisfazione del cliente per il successo aziendale. Con LingoYou, potete contare su traduzioni di alta qualità che supportano la vostra strategia di espansione globale e migliorano l'esperienza complessiva dei vostri clienti in tutto il mondo.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore retail comprende l'importanza della presentazione accurata dei prodotti, della comunicazione persuasiva e della soddisfazione del cliente per il successo aziendale.`   
        },
        cover: '/clients/Retail/coverretail.webp',
        coverPdf: '/clients/Retail/coverretail.png',
        sector:'/clients/Retail/retail.webp',
        sectorPdf:'/clients/Retail/retail.png'
    },
    'Support Services':{
        en:{
            title: "Translations in the Support Services Sector - Your Linguistic Partner for Excellence in Assistance",
            para1: "LingoYou is your reliable partner in the support services sector, offering specialized linguistic services for a wide range of documents and materials related to customer assistance and support. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "User Manuals and Support Guides:",
            listItem1Para: "We translate user manuals, technical support guides, FAQs, and other informational materials to ensure that customers can use products and services effectively and independently.",
            listItem2Title: "Customer Communications:",
            listItem2Para: "We handle the translation of support emails, live chats, support tickets, and other communications directed at customers, ensuring a timely and accurate response to their needs.",
            listItem3Title: "Technical Documentation:",
            listItem3Para: "We translate technical documentation, product specifications, data sheets, and more to ensure a clear and comprehensive understanding of product features and functionalities.",
            listItem4Title: "Support Staff Training:",
            listItem4Para: "We manage the translation of training materials for support staff, standard operating procedures (SOPs), and more to ensure that the team is adequately prepared to provide high-quality assistance in multiple languages.",
            listItem5Title: "Internal Resources:",
            listItem5Para: "We translate internal company documents, policies and procedures, internal communications, and more to ensure effective communication and optimal collaboration within the organization.",
            para2: "Our team of translators specialized in the support services sector understands the importance of clarity, courtesy, and competence in providing customer assistance and supporting business operations. With LingoYou, you can count on high-quality translations that enhance the overall customer experience and contribute to the success and reputation of your company in global markets.",
            para2Pdf: "Our team of translators specialized in the support services sector understands the importance of clarity, courtesy, and competence in providing customer assistance and supporting business operations."      
        },
        it:{
            title:`Traduzioni nel Settore dei Servizi di Supporto - Il Vostro Partner Linguistico per l'Eccellenza nell'Assistenza `,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per i servizi di supporto, offrendo servizi linguistici specializzati per una vasta gamma di documenti e materiali legati all'assistenza e al supporto clienti. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Manuali Utente e Guide di Supporto: `,
            listItem1Para:`Traduciamo manuali utente, guide di supporto tecnico, FAQ e altri materiali informativi per garantire che i clienti possano utilizzare i prodotti e i servizi in modo efficace e autonomo.`,
            listItem2Title:`Comunicazioni Clienti: `,
            listItem2Para:`Ci occupiamo della traduzione di e-mail di assistenza, chat dal vivo, ticket di supporto e altre comunicazioni dirette ai clienti, assicurando una risposta tempestiva e accurata alle loro esigenze.`,
            listItem3Title:`Documentazione Tecnica: `,
            listItem3Para:`Traduciamo documentazione tecnica, specifiche di prodotto, schede tecniche e altro ancora per garantire una comprensione chiara e completa delle caratteristiche e delle funzionalità dei prodotti e dei servizi.`,
            listItem4Title:`Formazione del Personale di Supporto: `,
            listItem4Para:`Gestiamo la traduzione di materiali di formazione per il personale di supporto, procedure operative standard (SOP), e altro ancora per garantire che il team sia adeguatamente preparato a fornire assistenza di alta qualità in diverse lingue.`,
            listItem5Title:`Risorse Interne: `,
            listItem5Para:`Traduciamo documenti aziendali interni, politiche e procedure aziendali, comunicazioni interne e altro ancora per garantire una comunicazione efficace e una collaborazione ottimale all'interno dell'organizzazione.`,
            para2:`La nostra equipe di traduttori specializzati nel settore dei servizi di supporto comprende l'importanza della chiarezza, della cortesia e della competenza nel fornire assistenza ai clienti e nel supportare le operazioni aziendali. Con LingoYou, potete contare su traduzioni di alta qualità che migliorano l'esperienza complessiva del cliente e contribuiscono al successo e alla reputazione della vostra azienda nei mercati globali.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore dei servizi di supporto comprende l'importanza della chiarezza, della cortesia e della competenza nel fornire assistenza ai clienti e nel supportare le operazioni aziendali.`   
        },
        cover: '/clients/SupportServices/coversupportservices.webp',
        coverPdf: '/clients/SupportServices/coversupportservices.png',
        sector:'/clients/SupportServices/supportservices.webp',
        sectorPdf:'/clients/SupportServices/supportservices.png'
    },
    'Technical':{
        en:{
            title: "Translations in the Technical Sector - Your Linguistic Partner for Technical Precision",
            para1: "LingoYou is your reliable partner in the technical sector, offering specialized linguistic services for a wide range of documents and materials related to technology and engineering. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "User Manuals and Technical Guides:",
            listItem1Para: "We translate user manuals, installation guides, technical guides, and other support materials to ensure that users can effectively and safely use products and services.",
            listItem2Title: "Technical Specifications and Product Documentation:",
            listItem2Para: "We handle the translation of technical specifications, product data sheets, project documentation, and more, ensuring an accurate understanding of product features and functionalities.",
            listItem3Title: "Engineering and Design Documents:",
            listItem3Para: "We translate technical drawings, diagrams, project plans, and other documents used in the engineering and design process, ensuring clear and precise communication among stakeholders.",
            listItem4Title: "Analysis and Test Reports:",
            listItem4Para: "We manage the translation of analysis reports, quality tests, technical reports, and more to ensure the correct communication of results and conclusions to stakeholders.",
            listItem5Title: "Safety and Regulatory Documentation:",
            listItem5Para: "We translate workplace safety documentation, regulations, compliance manuals, and more to ensure that products and processes comply with safety regulations and requirements.",
            para2: "Our team of translators specialized in the technical sector understands the importance of terminological precision, consistency, and in-depth understanding of technical concepts. With LingoYou, you can count on high-quality translations that support your technical and engineering activities in global contexts.",
            para2Pdf: "Our team of translators specialized in the technical sector understands the importance of terminological precision, consistency, and deep understanding of technical concepts."   
        },
        it:{
            title:`Traduzioni nel Settore Tecnico - Il Vostro Partner Linguistico per la Precisione Tecnologica `,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per il settore tecnico, offrendo servizi linguistici specializzati per una vasta gamma di documenti e materiali legati alla tecnologia e all'ingegneria. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Manuali Utente e Guide Tecniche: `,
            listItem1Para:`Traduciamo manuali utente, guide di installazione, guide tecniche e altro materiale di supporto per garantire che gli utenti possano utilizzare i prodotti e i servizi in modo efficace e sicuro.`,
            listItem2Title:`Specifiche Tecniche e Documentazione di Prodotto: `,
            listItem2Para:`Ci occupiamo della traduzione di specifiche tecniche, schede prodotto, documentazione di progetto e altro ancora, garantendo una comprensione accurata delle caratteristiche e delle funzionalità dei prodotti e dei servizi.`,
            listItem3Title:`Documenti di Ingegneria e Progettazione: `,
            listItem3Para:`Traduciamo disegni tecnici, schemi, piani di progetto e altri documenti utilizzati nel processo di ingegneria e progettazione, assicurando una comunicazione chiara e precisa tra gli stakeholder.`,
            listItem4Title:`Rapporti di Analisi e Test: `,
            listItem4Para:`Gestiamo la traduzione di rapporti di analisi, test di qualità, relazioni tecniche e altro ancora per garantire una corretta comunicazione dei risultati e delle conclusioni agli interessati.`,
            listItem5Title:`Documentazione di Sicurezza e Normativa: `,
            listItem5Para:`Traduciamo documentazione di sicurezza sul lavoro, normative, manuali di conformità e altro ancora per garantire che i prodotti e i processi siano conformi alle normative e ai requisiti di sicurezza.`,
            para2:`La nostra equipe di traduttori specializzati nel settore tecnico comprende l'importanza della precisione terminologica, della coerenza e della comprensione approfondita dei concetti tecnici. Con LingoYou, potete contare su traduzioni di alta qualità che supportano la vostra attività tecnica e ingegneristica in contesti globali.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore tecnico comprende l'importanza della precisione terminologica, della coerenza e della comprensione approfondita dei concetti tecnici.`    
        },
        cover: '/clients/Technical/covertechnical.webp',
        coverPdf: '/clients/Technical/covertechnical.png',
        sector:'/clients/Technical/technical.webp',
        sectorPdf:'/clients/Technical/technical.png'
    },
    'Technology':{
        en:{
            title: "Translations in the Technology Sector - Your Linguistic Partner for Global Innovation",
            para1: "LingoYou is your reliable partner in the technology sector, offering specialized linguistic services for a wide range of documents and materials related to technological innovation. Here are some examples of documents we translate with expertise and precision:",
            listItem1Title: "Technical Documentation:",
            listItem1Para: "We translate user manuals, API documentation, installation guides, and other technical materials to ensure accurate and comprehensive understanding of your products and services.",
            listItem2Title: "Websites and Applications:",
            listItem2Para: "We handle the translation of websites, mobile apps, software, and user interfaces, ensuring a smooth and localized experience for users of different languages and cultures.",
            listItem3Title: "Technology Marketing Materials:",
            listItem3Para: "We translate company presentations, product brochures, advertisements, and other marketing materials to promote your products and services in the global market.",
            listItem4Title: "Research and Development Documents:",
            listItem4Para: "We manage the translation of research papers, white papers, patents, and other innovation materials, ensuring global dissemination of your ideas and technological advancements.",
            listItem5Title: "Internal and External Communications:",
            listItem5Para: "We translate internal company communications, newsletters, developer updates, and other communication materials to ensure effective collaboration and knowledge sharing within the company and with external partners.",
            para2: "Our team of translators specialized in the technology sector understands the importance of technical precision, timeliness, and consistency in providing high-quality translations that support your growth and success in the global market. With LingoYou, you can count on translations that convey your innovation and commitment to technological excellence worldwide.",
            para2Pdf: "Our team of translators specialized in the technology sector understands the importance of technical accuracy, timeliness, and consistency in providing high-quality translations that support your growth and success in the global market."     
        },
        it:{
            title:`Traduzioni nel Settore Tecnologico - Il Vostro Partner Linguistico per l'Innovazione Globale`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per la tecnologia, offrendo servizi linguistici specializzati per una vasta gamma di documenti e materiali legati all'innovazione tecnologica. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Documentazione Tecnica: `,
            listItem1Para:`Traduciamo manuali utente, documentazione API, guide di installazione e altro materiale tecnico per garantire una comprensione accurata e completa dei vostri prodotti e servizi.`,
            listItem2Title:`Siti Web e Applicazioni: `,
            listItem2Para:`Ci occupiamo della traduzione di siti web, app mobile, software e interfacce utente, assicurando un'esperienza fluida e localizzata per gli utenti di diverse lingue e culture.`,
            listItem3Title:`Materiali di Marketing Tecnologico: `,
            listItem3Para:`Traduciamo presentazioni aziendali, brochure di prodotti, annunci pubblicitari e altro materiale di marketing per promuovere i vostri prodotti e servizi sul mercato globale.`,
            listItem4Title:`Documenti di Ricerca e Sviluppo: `,
            listItem4Para:`Gestiamo la traduzione di documenti di ricerca, white paper, brevetti e altro materiale di innovazione, garantendo una diffusione globale delle vostre idee e dei vostri progressi tecnologici.`,
            listItem5Title:`Comunicazioni Interne ed Esterne: `,
            listItem5Para:`Traduciamo comunicazioni interne aziendali, newsletter, aggiornamenti per gli sviluppatori e altro materiale di comunicazione per garantire una collaborazione efficace e una condivisione delle conoscenze all'interno dell'azienda e con i partner esterni.`,
            para2:`La nostra equipe di traduttori specializzati nel settore tecnologico comprende l'importanza della precisione tecnica, della tempestività e della coerenza nel fornire traduzioni di alta qualità che supportino la vostra crescita e il vostro successo sul mercato globale. Con LingoYou, potete contare su traduzioni che trasmettono la vostra innovazione e il vostro impegno per l'eccellenza tecnologica in tutto il mondo.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore tecnologico comprende l'importanza della precisione tecnica, della tempestività e della coerenza nel fornire traduzioni di alta qualità che supportino la vostra crescita e il vostro successo sul mercato globale`   
        },
        cover: '/clients/Technology/covertechnology.webp',
        coverPdf: '/clients/Technology/covertechnology.png',
        sector:'/clients/Technology/Technology.webp',
        sectorPdf:'/clients/Technology/Technology.png'
    },
    'Cosmetics':{
        en:{
            title:`Translations in the Cosmetic Sector: Your Linguistic Partner for Beauty and Quality`,
            para1:`LingoYou is your reliable partner in the field of translations for the cosmetic sector, offering specialized linguistic services for a wide range of documents and materials related to beauty and personal care. Here are some examples of documents we translate with competence and precision:`,
            listItem1Title:`Labels and Packaging: `,
            listItem1Para:`We translate product labels, packaging, usage instructions, and other related materials to ensure correct understanding and safe application of cosmetics.`,
            listItem2Title:`Technical Sheets and Formulations: `,
            listItem2Para:`We handle the translation of technical sheets, ingredient lists, formulations, and more, ensuring an accurate understanding of the characteristics and properties of cosmetic products.`,
            listItem3Title:`Promotional and Advertising Material: `,
            listItem3Para:`We translate brochures, advertisements, web content, and other promotional materials to ensure an effective and engaging message about your cosmetic products.`,
            listItem4Title:`Regulations and Standards: `,
            listItem4Para:`We manage the translation of regulations, certifications, safety tests, and more to ensure compliance of your cosmetic products with laws and regulatory requirements in different markets.`,
            listItem5Title:`Market Research and Consumer Analysis: `,
            listItem5Para:`We translate market research, surveys, consumer analysis, and more to help you better understand the needs and preferences of your customers worldwide.`,
            para2:`Our team of translators specialized in the cosmetic sector understands the importance of language and culture in effectively communicating the essence of your products. With LingoYou, you can rely on high-quality translations that enhance your brand in the global cosmetic market.`,
            para2Pdf:`Our team of translators specialized in the cosmetic sector understands the importance of language and culture in effectively communicating the essence of your products.`
        },
        it:{
            title:`Traduzioni nel Settore Cosmetico: Il Vostro Partner Linguistico per la Bellezza e la Qualità`,
            para1:`L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per il settore cosmetico, offrendo servizi linguistici specializzati per una vasta gamma di documenti e materiali legati alla bellezza e alla cura personale. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title:`Etichette e Imballaggi: `,
            listItem1Para:`Traduciamo etichette di prodotto, confezioni, istruzioni per l'uso e altro materiale correlato per garantire una corretta comprensione e un'applicazione sicura dei cosmetici.`,
            listItem2Title:`Schede Tecniche e Formulazioni: `,
            listItem2Para:`Ci occupiamo della traduzione di schede tecniche, elenchi degli ingredienti, formulazioni e altro ancora, assicurando una comprensione accurata delle caratteristiche e delle proprietà dei prodotti cosmetici.`,
            listItem3Title:`Materiale Promozionale e Pubblicitario: `,
            listItem3Para:`Traduciamo brochure, annunci pubblicitari, contenuti web e altro materiale promozionale per garantire un messaggio efficace e coinvolgente sui vostri prodotti cosmetici.`,
            listItem4Title:`Normative e Regolamenti: `,
            listItem4Para:`Gestiamo la traduzione di normative, certificazioni, test di sicurezza e altro ancora per garantire la conformità dei vostri prodotti cosmetici alle leggi e ai requisiti regolamentari in diversi mercati.`,
            listItem5Title:`Ricerche di Mercato e Analisi dei Consumatori: `,
            listItem5Para:`Traduciamo ricerche di mercato, sondaggi, analisi dei consumatori e altro ancora per aiutarvi a comprendere meglio le esigenze e le preferenze dei vostri clienti in tutto il mondo.`,
            para2:`La nostra equipe di traduttori specializzati nel settore cosmetico comprende l'importanza della lingua e della cultura nel comunicare efficacemente l'essenza dei vostri prodotti. Con LingoYou, potete contare su traduzioni di alta qualità che valorizzano la vostra marca nel mercato cosmetico globale.`,
            para2Pdf:`La nostra equipe di traduttori specializzati nel settore cosmetico comprende l'importanza della lingua e della cultura nel comunicare efficacemente l'essenza dei vostri prodotti.`
        },
        cover: '/clients/Cosmetics/coverCosmetics.webp',
        coverPdf: '/clients/Cosmetics/coverCosmetics.png',
        sector:'/clients/Cosmetics/Cosmetics.webp',
        sectorPdf:'/clients/Cosmetics/Cosmetics.png'
    },
    'Automotive':{
        en:{
            title: `Translations in the Automotive Sector: Your Linguistic Partner for Technical Excellence`,
            para1: `LingoYou is your reliable partner in the field of translations for the automotive industry, offering specialized linguistic services for a wide range of documents and materials related to automobiles and automotive engineering. Here are some examples of documents we translate with competence and precision:`,
            listItem1Title: `Owner's Manuals and Maintenance Guides: `,
            listItem1Para: `We translate owner's manuals, maintenance manuals, user instructions, and other related materials to ensure that users can understand and properly utilize vehicles and their components.`,
            listItem2Title: `Technical Specifications and Product Documentation: `,
            listItem2Para: `We handle the translation of technical specifications, product sheets, project documentation, and more, ensuring an accurate understanding of vehicle features and performance.`,
            listItem3Title: `Repair and Service Manuals: `,
            listItem3Para: `We translate repair manuals, service booklets, maintenance instructions, and other technical materials used by technicians and mechanics for vehicle maintenance and repair.`,
            listItem4Title: `Design Technical Documentation: `,
            listItem4Para: `We manage the translation of design technical documentation, engineering drawings, schematics, and more, ensuring clear and precise communication during the vehicle design and development process.`,
            listItem5Title: `Training and Instruction Materials: `,
            listItem5Para: `We translate training materials for automotive technicians, instruction guides, training courses, and more, to ensure a proper understanding of processes and procedures related to vehicle maintenance and repair.`,
            para2: `Our team of translators specialized in the automotive sector understands the importance of terminological accuracy, consistency, and a thorough understanding of specific technical concepts in the automotive industry. With LingoYou, you can rely on high-quality translations that support your activities in the automotive industry in global contexts.`,
            para2Pdf: `Our team of translators specialized in the automotive sector understands the importance of terminological accuracy, consistency, and a thorough understanding of specific technical concepts in the industry.`
        },
        it:{
            title: `Traduzioni nel Settore Automobilistico: Il Vostro Partner Linguistico per l'Eccellenza Tecnica`,
            para1: `L'azienda LingoYou è il vostro partner affidabile nel settore delle traduzioni per l'industria automobilistica, offrendo servizi linguistici specializzati per una vasta gamma di documenti e materiali legati all'automobile e all'ingegneria automobilistica. Ecco alcuni esempi di documenti che traduciamo con competenza e precisione:`,
            listItem1Title: `Manuali del Proprietario e Manutenzione: `,
            listItem1Para: `Traduciamo manuali del proprietario, manuali di manutenzione, istruzioni per l'uso e altro materiale correlato per garantire che gli utenti possano comprendere e utilizzare correttamente i veicoli e i loro componenti.`,
            listItem2Title: `Specifiche Tecniche e Documentazione di Prodotto: `,
            listItem2Para: `Ci occupiamo della traduzione di specifiche tecniche, schede prodotto, documentazione di progetto e altro ancora, garantendo una comprensione accurata delle caratteristiche e delle prestazioni dei veicoli e dei loro accessori.`,
            listItem3Title: `Manuali di Riparazione e Servizio: `,
            listItem3Para: `Traduciamo manuali di riparazione, libretti di servizio, istruzioni per la manutenzione e altro materiale tecnico utilizzato dai tecnici e dai meccanici per la manutenzione e la riparazione dei veicoli.`,
            listItem4Title: `Documentazione Tecnica di Progettazione: `,
            listItem4Para: `Gestiamo la traduzione di documentazione tecnica di progettazione, disegni di ingegneria, schemi e altro ancora, garantendo una comunicazione chiara e precisa durante il processo di progettazione e sviluppo dei veicoli.`,
            listItem5Title: `Materiali di Formazione e Istruzione: `,
            listItem5Para: `Traduciamo materiali di formazione per tecnici automobilistici, guide di istruzione, corsi di addestramento e altro ancora, per garantire una corretta comprensione dei processi e delle procedure legate alla manutenzione e alla riparazione dei veicoli.`,
            para2: `La nostra equipe di traduttori specializzati nel settore automobilistico comprende l'importanza della precisione terminologica, della coerenza e della comprensione approfondita dei concetti tecnici specifici del settore automobilistico. Con LingoYou, potete contare su traduzioni di alta qualità che supportano la vostra attività nell'industria automobilistica in contesti globali.`,
            para2Pdf: `La nostra equipe di traduttori specializzati nel settore automobilistico comprende l'importanza della precisione terminologica, della coerenza e della comprensione approfondita dei concetti tecnici specifici del settore.`
        },
        cover: '/clients/Automotive/coverAutomotive.webp',
        coverPdf: '/clients/Automotive/coverAutomotive.png',
        sector:'/clients/Automotive/Automotive.webp',
        sectorPdf:'/clients/Automotive/Automotive.png'
    }
    }
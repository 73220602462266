import jwtDecode from 'jwt-decode';
import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer } from 'react';
// utils
import axios from '../utils/axios';
import { isValidToken, setSession } from '../utils/jwt';

// ----------------------------------------------------------------------

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  type: ''
};

const handlers = {
  INITIALIZE: (state, action) => {
    const { isAuthenticated, user, type } = action.payload;
    return {
      ...state,
      isAuthenticated,
      isInitialized: true,
      user,
      type
    };
  },
  LOGIN: (state, action) => {
    const { user, type } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      type
    };
  },
  LOGOUT: (state) => ({
    ...state,
    isAuthenticated: false,
    user: null,
    type: ''
  }),
  REGISTER: (state, action) => {
    const { user, type } = action.payload;

    return {
      ...state,
      isAuthenticated: true,
      user,
      type
    };
  },
};

const reducer = (state, action) => (handlers[action.type] ? handlers[action.type](state, action) : state);

const AuthContext = createContext({
  ...initialState,
  method: 'jwt',
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
});

// ----------------------------------------------------------------------

AuthProvider.propTypes = {
  children: PropTypes.node,
};

function AuthProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  function checkTokenExpiration() {
    const accessToken = localStorage.getItem('accessToken');
    if (!accessToken) return;
    const { exp } = jwtDecode(accessToken);
    const currentTime = Date.now();
    const timeLeft = exp * 1000 - currentTime;

    if (timeLeft <= 0) {
      setSession(null);
      dispatch({ type: 'LOGOUT' });
    }
  }
  useEffect(() => {
    checkTokenExpiration();
    const intervalId = setInterval(() => {
      checkTokenExpiration();
    }, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);
// Function to verify the token
async function verifyToken(token) {
  try {
      // Replace with your backend API endpoint
      const response = await axios.get(`${process.env.REACT_APP_APOLLO_SERVER}/verify-token`, {
          params: {
              token: token
          }
      });
      
      if (response.data.valid) {
          return response.data.valid
          // Perform actions for a valid token
      } else {
          return false
          // Perform actions for an invalid token
      }
  } catch (error) {
      console.error('Error verifying token:', error);
      return false
      // Handle error
  }
}

  useEffect(() => {
   async function tokenVerificationProcess () {

    try {
      const queryParams = new URLSearchParams(window.location.search);
      const token =  queryParams?.get('token') || null;
      let accessToken = null
      if(token){
         const result = await verifyToken(token);
         if(result){
          localStorage.setItem('accessToken', token)
          accessToken = token;
         }else{
          localStorage.removeItem('accessToken');
          accessToken = null;
         }
         
      }else{
        accessToken = localStorage.getItem('accessToken');
      }
      const isAuthenticated = isValidToken(accessToken);
      if (accessToken && isAuthenticated) {
        setSession(accessToken);
        const user = jwtDecode(accessToken);
        const type = user.type
         console.log("SessionUser", user)
        if(type){
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: true,
              user,
              type
            },
          });
        }else {
          dispatch({
            type: 'INITIALIZE',
            payload: {
              isAuthenticated: false,
              user: null,
              type: ''
            },
          });
        }
        
      } else {
        dispatch({
          type: 'INITIALIZE',
          payload: {
            isAuthenticated: false,
            user: null,
            type: ''
          },
        });
      }
    } catch (err) {
      console.error(err);
      dispatch({
        type: 'INITIALIZE',
        payload: {
          isAuthenticated: true,
        },
      });
    }
  }
  tokenVerificationProcess()
  }, []);

  const login = async (user) => {
    const { token: accessToken } = user;

    setSession(accessToken);
    const loggedInUser = jwtDecode(accessToken);
    const type = loggedInUser.type
    console.log(type)
    dispatch({
      type: 'LOGIN',
      payload: {
        user,
        type
      },
    });
  };

  const register = async (email, password, firstName, lastName) => {
    const response = await axios.post('/api/account/register', {
      email,
      password,
      firstName,
      lastName,
    });
    const { accessToken, user } = response.data;

    localStorage.setItem('accessToken', accessToken);

    dispatch({
      type: 'REGISTER',
      payload: {
        user,
      },
    });
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: 'LOGOUT' });
  };

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: 'jwt',
        login,
        logout,
        register,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}

export { AuthContext, AuthProvider };
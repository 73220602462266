import React from 'react';
import { styles } from '../globalStyles';
import { Image, Text, View } from '@react-pdf/renderer';
import { icons } from '../icons';

const Preventivo = ({ language }) => {
  return (
    <View style={styles.preventivo}>
      <View style={styles.preventivoHeader}>
        <Text>                        {language === 'English' ? "The quote includes:":"Il preventivo include:"}
</Text>
      </View>
      <View style={styles.preventivoBody}>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.customerService} /> {language === 'English' ? "Customer Service available 24/7;" : "Servizio Clienti disponibile 24/7;"}
        </Text>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.translation} />
          {language === 'English'
            ?
            "Professional interpreting service performed by linguists with at least 5 years of experience in the requested sector;"
            : "Servizio di interpretariato professionale effettuato da linguisti con almeno 5 anni di esperienza nel settore richiesto;"}
        </Text>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.contactSupport} />
          {language === 'English'
            ?
            "Preparation of the interpreters based on the support material provided by the client;"
            : "Preparazione degli interpreti sulla base del materiale di supporto fornito dal cliente;"}
        </Text>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.phonePlus} />
          {language === 'English'
            ?
            "15-minute pre-event telephone briefing with the interpreter;"
            : "Briefing Telefonico pre-evento di 15 minuti con l’interprete;"}
        </Text>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.phonePlus} />
          {language === 'English'
            ?
            "15-minute pre-event telephone briefing with the audio technician (in case of simultaneous technical equipment rental)"
            : "Briefing Telefonico pre-evento di 15 minuti con il tecnico audio (In caso di noleggio attrezzatura tecnica per simultanea)"}
        </Text>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.eventAvailable} />
          {language === 'English'
            ?
            "Interpreters available 30 minutes before the start of the event for a Final briefing;"
            : "Interpreti disponibili 30 minuti prima dell’inizio dell’evento per un briefing Finale;"}
        </Text>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.audio} />
          {language === 'English'
            ?
            "Equipment assembly and Audio Test finalized at least 30 minutes before the start of the event/meeting;"
            : "Montaggio Attrezzatura e Test Audio finalizzato almeno 30 minuti prima dell’inizio dell’evento/meeting;"}
        </Text>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.videoCall} />
          {language === 'English'
            ?
            "Virtual inspection via video call or on site;"
            : "Sopralluogo Virtuale tramite Video Call o in loco;"}
        </Text>
        <Text style={{ ...styles.bold, ...styles.preventivoBodyText }}>
          <Image style={{ width: "15px" }} src={icons.feedback} />
          {language === 'English'
            ?
            "Final feedback request via Call & Email"
            : "Richiesta feedback finale tramite Call & Email."}
        </Text>
      </View>
    </View>
  );
};

export default Preventivo;
import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';
import { PATH_AUTH } from 'src/routes/paths';

const Quality = ({ language, quoteId }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
       <View style={{ ...styles.header, marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
        <Text style={styles.headerItemText}>{language === 'English' ? `QUALITY` : 
          `QUALITY`}</Text>    
              </View>
         <View>
          <Image style={{...styles.headerRightImg }} src="/translationAssets/desktop6/lingoyou.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={styles.techBody}>
      <Text style={styles.techParaBody}>
       {language === "English" ? `By setting ourselves the goal of achieving a high degree of customer satisfaction, in LingoYou we are committed to the highest quality standards in the provision of services translation and interpreting.`:
       `Ponendoci come obiettivo il raggiungimento di un elevato grado di soddisfazione del Cliente, in LingoYou ci impegniamo a garantire i più alti standard di qualità nella fornitura dei servizi di traduzione ed interpretariato.`}
        </Text>
        <Text style={styles.techParaBody}>
       {language === "English" ? `• We comply and respond to the needs and times of the Customer through clear and transparent communication.`:
       `• Ci conformeremo e risponderemo alle esigenze e ai tempi del Cliente attraverso una comunicazione chiara e trasparente.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• We respond to each request within a maximum of 8 working hours.`:
       `• Risponderemo ad ogni richiesta entro massimo 8 ore lavorative.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• We ensure consistency and accuracy at every stage of the project.`:
       `• Garantiremo coerenza e accuratezza in ogni fase del progetto.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• We consider feedback and suggestions to monitor the level of customer satisfaction and improve every day.`:
       `• Terremo in considerazione feedback e suggerimenti per monitorare il livello di soddisfazione del Cliente e migliorarci ogni giorno.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• We guarantee maximum professionalism and high-quality standards.`:
       `• Garantiremo massima professionalità e alti standard di qualità.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• We ensure that quality objectives are compatible with the company's strategic direction.`:
       `• Faremo in modo che gli obiettivi di qualità siano compatibili con la direzione strategica dell'azienda.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• We constantly evaluate risks and opportunities in order to improve our procedures and consequently customer satisfaction.`:`• Valuteremo costantemente rischi e opportunità al fine di migliorare le nostre procedure e di conseguenza la soddisfazione dei clienti.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `Our quality policy is periodically reviewed to ensure compliance with the context and with a view to continuous improvement of our actions, procedures, and operations through the implementation of administration systems consistent with ISO 9001 requirements.`:
       `La nostra politica della qualità viene rivista periodicamente per garantirne l’adeguamento con il contesto e in direzione del miglioramento continuo delle nostre azioni, procedure ed operazioni attraverso l'implementazione di sistemi di amministrazione coerenti con i requisiti ISO 9001.`}
        </Text>
              </View>
             <Text style={{display:"flex",paddingLeft:"18px",paddingTop:"9px", backgroundColor:"#2DA0F0", flexDirection:"row", height:"35px", borderRadius: '7px', fontSize:"10px", width:"170px",marginLeft:"35%", marginTop:"30px"}}>
             <Image
                style={{ width: '15px', height: '12px', padding:"10px" }}
                src="/assets/icons/lock.png"
              />
           <Text 
            style={{
            textAlign: 'center',
            color: '#FFFFFF',
            fontFamily: "Poppins 'sans-serif'",
            fontStyle: 'normal',
            fontWeight: 'normal',fontSize:"11px", paddingBottom:"5px"}}>
          <Link style={{color:"white"}} 
        href={`${process.env.REACT_APP_URL}${PATH_AUTH.quotePrivacyClientView(
          quoteId,
          `${language === "English" ? "English": "Italian"}`
        )}`}
        >
{language === 'English' ? 
      `Privacy & Cyber Security` :
      `Privacy & Cyber Security`}       
      </Link>
          
       </Text>
            </Text> 
            
       <View style={{...styles.testimonialImage, marginBottom:"15px"}}>
       <Image style={{ width:"100%", objectFit:"obtain"}} src="/translationAssets/Quality/logos.png"/>
      </View>
       
    </Page>
  );
};

export default Quality;
import OurClients from 'src/sections/translationClientOutput/components/OurClients';
import React, { useEffect, useState } from 'react';
import Testimonials from 'src/sections/translationClientOutput/components/Testimonials';
import Interpreting from './components/Interpreting';
import AboutUs2 from './components/AboutUs2';
import AboutUs1 from './components/AboutUs1';
import Introduction from './components/Introduction';
import Home from './components/Home';
import Contact from './components/Contact';
import Quality from 'src/sections/translationClientOutput/components/Quality';
import TechnologyPlateform from './components/TechnologyPlateform';
import TechPortal from './components/TechPortal';
import Memory from './components/Memory';
import Academy from './components/Academy';
import Privacy from 'src/sections/translationClientOutput/components/Privacy';
import './index.css';
import BottomNavigation from '@mui/material/BottomNavigation';
import { RiFileDownloadFill } from 'react-icons/ri';
import Paper from '@mui/material/Paper';
import './index.css';
import { Box, Button, CircularProgress } from '@mui/material';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDoc from './PDFDoc/PDFDoc';
import { useSelector } from 'react-redux';
import SectorClients from './components/SectorClients';

export default function TranslationClientView() {

  const {details, language } = useSelector(state => state.proposal)

  return (
    <>
      <div style={{ marginInline: 'auto', maxWidth: '1600px', boxShadow: '0px 0px 5px 3px rgb(0 0 0 / 40%)' }}>
      
          <div id="my-node">
            <Home  />
          </div>
            <div id="intro">
              <Introduction  />
            </div>
            <div>
            <SectorClients />
           </div>
            <div>
              <AboutUs1  />
            </div>
            <div>
              <AboutUs2 />
            </div>
            <div>
              <Interpreting />
            </div>
            <div>
              <Quality />
            </div>
            <div id="memory">
              <Memory />
            </div>
            <div>
              <Privacy />
            </div>
            <div>
              <Academy />
            </div>

            <div>
              <TechPortal  />
            </div>
            <div>
              <TechnologyPlateform />
            </div>
            <div>
              <OurClients />
            </div> 
            <div id="testimonials">
              <Testimonials />
            </div>
          
          <div id="contact">
            <Contact />
          </div>
      </div>
      <Paper
        sx={{ position: 'fixed', height: '90px', zIndex: 99999999999, bottom: 0, left: 0, right: 0, backgroundColor: '#2DA0F0' }}
        elevation={3}
      >
        <BottomNavigation
          sx={{ backgroundColor: '#2DA0F0', justifyContent: 'end', margin: '10px' }}
          showLabels
          // value={value}
        >
          <PDFDownloadLink fileName={language==="English" ? `BROCHURE`:`BROCHURE`}  style={{fontSize:"17px", pointerEvents:"cursor", borderRadius:"5px", backgroundColor: 'white', color: 'grey', width: '210px', height: '70px', marginRight: '20px' }}
   document={<PDFDoc language={language} details={details} />}>
                    {({ loading }) => (
            <>
            {loading ? <Box sx={{ display: 'flex', justifyContent: 'center', marginTop: "14px" }}>
        <CircularProgress />
      </Box> :  <Button disabled={loading && true} sx={{ fontSize: "unset", marginTop: "14px" }}>
              <RiFileDownloadFill style={{ color:`${loading ? 'grey': '#ff0c00b3'}`, marginRight: '2px', height: '30px', width: '45px' }} />{' '}
              {language === "English" ? 'DOWNLOAD PDF' : ' SCARICA PDF'}
            </Button> 
            }
            </>  
          )}
        </PDFDownloadLink> 

        </BottomNavigation>
      </Paper>
    </>
  );
}

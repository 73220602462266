import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const TechPortal = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
       <View style={{ ...styles.header, marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
        <Text style={styles.headerItemText}>{language === 'English' ? `Technology – LingoYou Portal` : 
          `Tecnologia – Portale LingoYou`}</Text>        </View>
         <View>
          <Image style={{...styles.headerRightImg }} src="/translationAssets/desktop6/lingoyou.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={styles.techBody}>
       
        <Text style={styles.techParaBody}>
       {language === "English" ? `• Receive instant quotations.`:`• Ricevi preventivi immediati.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• Upload and download files.`:`• Carica e scarica file.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• Check out comprehensive project status reports with task lists and calendars.`:
       `• Controlla i report completi sullo status dei progetti con gli elenchi di attività e calendari.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• Receive projects, anytime, anywhere.`:`• Ricevi i progetti, in qualsiasi momento e ovunque.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• Import and export spreadsheet information.`:`• Importa ed esporta le informazioni dei fogli di calcolo.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• Sign up to receive project status information and automatic email updates.`:
       `• Iscriviti per ricevere informazioni sullo status del progetto e aggiornamenti e-mail automatici.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• Apply role-based security systems to all users.`:`• Applica sistemi di sicurezza basati sui ruoli a tutti gli utenti.`}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
       {language === "English" ? `• Collaborate with selected virtual team members.`:`• Collabora con i membri del team virtuale selezionato.`}
        </Text>
              </View>
      <View style={styles.techFooter}>
      <Link style={{width:"400px"}} href="https://www.youtube.com/embed/_x5KCDcJft4">
      <Image style={styles.techFooterImage} src="/translationAssets/desktop9/techportal.png"/>
        </Link>
      </View>
    </Page>
  );
};

export default TechPortal;
import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './globalStyles';

const WhyRafiky = ({ language }) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={{ ...styles.header, marginBottom: 0, width: '94%', marginHorizontal: 'auto' }}>
        <View style={{ ...styles.headerItem }}>
          <Text style={{ ...styles.headerItemText, fontSize: '13px' }}>
            {language === 'English' ? 'WHY RAFIKY?' : 'PERCHÉ RAFIKY?'}
          </Text>
        </View>
        <View>
          <Image style={{ ...styles.headerRightImg }} src="/rafiky/logo.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={{ display: 'flex', flexDirection: 'row' }}>
        <View style={{ width: '60%', padding: '10px', marginLeft:"15px" }}>
          <View style={{ marginBottom: '20px', marginTop: '20px' }}>
            <Text style={{ fontSize: '11px', fontWeight: 'normal' }}>
              <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>1. </Text>
              {language === 'English'
                ? `100% Guarantee of "Data Security and Privacy Protection"`
                : 'Garanzia al 100% di «Sicurezza Dati e Tutela Privacy»'}
            </Text>
          </View>
          <View style={{ marginBottom: '20px' }}>
            <Text style={{ fontSize: '11px', fontWeight: 'normal' }}>
            <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>2. </Text>

              {language === 'English'
                ? `The best software for interpreting new features to support professional interpreters in their work`
                : `Il miglior software di interpretariato funzionalità inedite per supportare gli interpreti professionisti nel loro lavoro`}
            </Text>
          </View>
          <View style={{ marginBottom: '20px' }}>
            <Text style={{ fontSize: '11px', fontWeight: 'normal' }}>
            <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>3. </Text>
              {language === 'English'
                ? 'Multilingual video and audio recording'
                : 'Registrazione video e audio multilingua'}
            </Text>
          </View>
          <View style={{ marginBottom: '20px' }}>
            <Text style={{ fontSize: '11px', fontWeight: 'normal' }}>
            <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>4. </Text>

              {language === 'English'
                ? 'All the main features of webinar platforms (voting, moderator panel, multi-room chat, sign language, speaker subtitles, etc.)'
                : 'Tutte le principali features delle piattaforme per webinar (votazione, pannello moderatore, multi-room chat, lingua dei segni, sottopancia relatore ecc.)'}
            </Text>
          </View>
          <View style={{ marginBottom: '20px' }}>
            <Text style={{ fontSize: '11px', fontWeight: 'normal' }}>
            <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>5. </Text>

              {language === 'English'
                ? 'Internally developed platform - Compatibility with all other platforms (Zoom, Go to meeting, Skype, Team etc.)'
                : 'Piattaforma sviluppata internamente - Compatibilità con tutte le altre piattaforme (Zoom, Go to meeting, Skype, Team ecc.)'}
            </Text>
          </View>
          <View style={{ marginBottom: '20px' }}>
            <Text style={{ fontSize: '11px', fontWeight: 'normal' }}>
            <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>6. </Text>

              {language === 'English' ? 'Technical assistance during the event' : 'Assistenza Tecnica durante l’evento'}
            </Text>
          </View>
          <View style={{ marginBottom: '10px' }}>
            <Text style={{ fontSize: '11px', fontWeight: 'normal' }}>
            <Text style={{ color: '#8316d3', fontWeight: 'bold' }}>7. </Text>
              {language === 'English'
                ? 'More than 50 Rafiky Hubs on the national territory'
                : 'Piu di 50 Rafiky Hub sul territorio nazionale '}
            </Text>
          </View>
        </View>
        <View style={{ width: '40%' }}>
          <Image
            style={{
              width: '90%',
              marginLeft: '5px',
              objectFit: 'contain',
              zIndex: '-99',
              borderRadius: '50%',
              border: '4px solid #8316d3',
              marginTop: '50px',
              marginRight: '20px',
            }}
            src="/rafiky/brochure/question.png"
          />
        </View>
      </View>
      <Image
        style={{ width: '100%', zIndex: '-99', marginTop: '-369px', height: '400px' }}
        src="/rafiky/brochure/background.png"
      />
    </Page>
  );
};

export default WhyRafiky;

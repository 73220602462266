import React, { useEffect, useState } from 'react';
import 'src/sections/translationClientOutput/index.css';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { addLanguageAndId } from 'src/redux/slices/proposal';
import Academy from 'src/sections/translationClientOutput/components/Academy';
import MiniLingoyouAcademy from 'src/sections/translationClientOutput/components/MiniLingoyouAcademy';



export default function LingoyouAcademy() {

const {lang} = useParams()

const dispatch = useDispatch()

useEffect(()=>{
    dispatch(addLanguageAndId({language: lang, id:""}))

}, [1])
  return (
    <>
      <div id="translation-proposal" style={{marginInline: 'auto', boxShadow: '0px 0px 5px 3px rgb(0 0 0 / 40%)' }}>
      
        
              <Academy/>
              <MiniLingoyouAcademy/>
        
      </div>
     
    </>
  );
}

import { Grid } from "@mui/material";
import React from "react";
import styles from "../styles/Memory.module.css";
import { useSelector } from "react-redux";

export default function Memory() {

  const {language} = useSelector((state)=>state.proposal);

  if (language === 'English') {
    return (<div className={styles.desktop22}>
      <div className={styles.desktop22Child} />
      {/* <div className={styles.div}>10</div> */}
      <div className={styles.quality}>Translation Memory</div>
      <div className={styles.text3543Parent}>
        <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{" "}
      </div>
      <div className={styles.text}>
        <p>
        The Translation Memory (TM) is a fundamental tool in the workflow of a translation and interpreting agency. It's about a type of linguistic database used to store texts in one source language and its translations into another (targets). The fundamental idea of memory-based systems of translation is that it allows the translator or translation team to reuse previously translated segments, so as to make the translation process much faster, agile and efficient.
        </p>
        <p>
          <b>What are the benefits of Translation Memory?
          </b>
        </p>
        <ul>
          <li>Terminological uniformity and consistent interpretation
          </li>
          <li>Cost savings for the customer
          </li>
          <li>Terminological or stylistic preference
          </li>
          <li>
          Faster translation times with a subsequent increase in the number of completed projects and turnover
          </li>
          <li>
          Consistency and quality of translations for greater customer satisfaction
          </li>
          
          <li>Easy format export
          </li>
        </ul>
        <p>
        There are many C.A.T. for the Translation Memory, the best ones used by most translation agencies and translators professionals are: SDL Trados Studio, Déjà Vu X3, MemoQ and Wordfast.
        </p>
      </div>
      <div className={styles.divider}>
      TRANSLATION MEMORY IS PERFECT FOR
        {" "}
      </div>
      <Grid container spacing={3} className={styles.tabs}>
        <Grid item xs={2}></Grid>
        <Grid item xs={12} className={styles.row1}>
          <div
            className={styles.tab1}
            style={{ background: "url(/translationAssets/memory/Vector1.webp) no-repeat" }}
          >
            <p className={styles.tabText}>Technical documentation</p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/pink-file.png" />
            </div>
          </div>
          <div
            className={styles.tab2}
            style={{ background: "url(/translationAssets/memory/Vector2.webp) no-repeat" }}
          >
            <p className={styles.tabText}>Support portals
            </p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/user-support.png" />
            </div>
          </div>
          <div
            className={styles.tab3}
            style={{ background: "url(/translationAssets/memory/Vector3.webp) no-repeat" }}
          >
            <p className={styles.tabText}>
            Product manuals
            </p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/checklist.png" />

            </div>
          </div>
          <div
            className={styles.tab4}
            style={{ background: "url(/translationAssets/memory/Vector4.webp) no-repeat" }}
          >
            <p className={styles.tabText}>
            Videogames localisation 
            </p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/joystick-xbox.png" />

            </div>
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container spacing={3} className={styles.tabs2}>
        <Grid item xs={2}></Grid>
        <Grid item xs={12} className={styles.row2}>
          <div
            className={styles.tab5}
            style={{ background: "url(/translationAssets/memory/Vector1.webp) no-repeat" }}
          >
            <p className={styles.tabText}>
            Software localisation
            </p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/code-p.png" />
            </div>
          </div>
          <div
            className={styles.tab6}
            style={{ background: "url(/translationAssets/memory/Vector2.webp) no-repeat" }}
          >
            <p className={styles.tabText}>Ecommerce content</p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/money-currency.png" />

            </div>
          </div>
          <div
            className={styles.tab7}
            style={{ background: "url(/translationAssets/memory/Vector3.webp) no-repeat" }}
          >
            <p className={styles.tabText}>
            Legal and financial content
            </p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/court-legal.png" />

            </div>
          </div>
          <div
            className={styles.tab8}
            style={{ background: "url(/translationAssets/memory/Vector4.webp) no-repeat" }}
          >
            <p className={styles.tabText}>Product descriptions</p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/text-align-left.png" />
            </div>
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>     
    </div>)
  }

  return (
    <div className={styles.desktop22}>
      <div className={styles.desktop22Child} />
      {/* <div className={styles.div}>10</div> */}
      <div className={styles.quality}>Translation Memory</div>
      <div className={styles.text3543Parent}>
        <img alt="" src="/translationAssets/desktop6/group-1000001719.svg" />{" "}
      </div>
      <div className={styles.text}>
        <p>
          La Translation Memory (TM) è uno strumento fondamentale nel lavoro
          quotidiano di un’agenzia di traduzioni e interpretariato. Si tratta di
          un tipo di database linguistico utilizzato per memorizzare testi in
          una lingua sorgente (source) e le relative traduzioni in un'altra
          (target). L'idea fondamentale dei sistemi basati sulla memoria di
          traduzione è che consente al traduttore o al team di traduzione di
          riutilizzare segmenti precedentemente tradotti, così da rendere il
          processo di traduzione più veloce, agile ed efficiente.
        </p>
        <p>
          <b>Quali sono i vantaggi della Translation Memory?</b>
        </p>
        <ul>
          <li>Uniformità terminologica e interpretazione coerente</li>
          <li>Risparmio sui costi per il cliente</li>
          <li>Preferenza terminologica o stilistica</li>
          <li>
            Tempi di traduzioni più rapidi con il conseguente aumento del numero
            di progetti completati e del fatturato.
          </li>
          <li>
            Coerenza e qualità delle traduzioni a beneficio della soddisfazione
            del cliente.
          </li>
          <li>
            Elaborazione di glossari personalizzati su misura di ogni singolo
            cliente
          </li>
          <li>Facile esportazione del formato</li>
        </ul>
        <p>
          Esistono tanti C.A.T. per la Translation Memory, i migliori utilizzati
          dalla maggior parte delle agenzie di traduzioni e dei traduttori
          professionisti, sono SDL Trados Studio, Déjà Vu X3, MemoQ e Wordfast.
        </p>
      </div>
      <div className={styles.divider}>
        LA MEMORIA DI TRADUZIONE È IDEALE PER{" "}
      </div>
      <Grid container spacing={3} className={styles.tabs}>
        <Grid item xs={2}></Grid>
        <Grid item xs={12} className={styles.row1}>
          <div
            className={styles.tab1}
            style={{ background: "url(/translationAssets/memory/Vector1.webp) no-repeat" }}
          >
            <p className={styles.tabText}>Documentazione tecnica</p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/pink-file.png" />
            </div>
          </div>
          <div
            className={styles.tab2}
            style={{ background: "url(/translationAssets/memory/Vector2.webp) no-repeat" }}
          >
            <p className={styles.tabText}>Portali di supporto</p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/user-support.png" />
            </div>
          </div>
          <div
            className={styles.tab3}
            style={{ background: "url(/translationAssets/memory/Vector3.webp) no-repeat" }}
          >
            <p className={styles.tabText}>Manuali di
              prodotto</p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/checklist.png" />

            </div>
          </div>
          <div
            className={styles.tab4}
            style={{ background: "url(/translationAssets/memory/Vector4.webp) no-repeat" }}
          >
            <p className={styles.tabText}>Localizzazione
              di videogiochi</p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/joystick-xbox.png" />

            </div>
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
      <Grid container spacing={3} className={styles.tabs2}>
        <Grid item xs={2}></Grid>
        <Grid item xs={12} className={styles.row2}>
          <div
            className={styles.tab5}
            style={{ background: "url(/translationAssets/memory/Vector1.webp) no-repeat" }}
          >
            <p className={styles.tabText}>Localizzazione
              del software</p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/code-p.png" />
            </div>
          </div>
          <div
            className={styles.tab6}
            style={{ background: "url(/translationAssets/memory/Vector2.webp) no-repeat" }}
          >
            <p className={styles.tabText}>Contenuti dell'ecommerce</p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/money-currency.png" />

            </div>
          </div>
          <div
            className={styles.tab7}
            style={{ background: "url(/translationAssets/memory/Vector3.webp) no-repeat" }}
          >
            <p className={styles.tabText}>Contenuti legali e
              finanziari</p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/court-legal.png" />

            </div>
          </div>
          <div
            className={styles.tab8}
            style={{ background: "url(/translationAssets/memory/Vector4.webp) no-repeat" }}
          >
            <p className={styles.tabText}>Descrizioni
              dei prodotti</p>
            <div className={styles.tabIcon}>
              <img   src="/translationAssets/icons/text-align-left.png" />
            </div>
          </div>
        </Grid>
        <Grid item xs={2}></Grid>
      </Grid>
    </div>
  );
}

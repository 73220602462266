import { Grid } from "@mui/material";
import styles from "../styles/TechEquipment.module.css";
import LiteYouTubeEmbed from "react-lite-youtube-embed";
import { useSelector } from "react-redux";

const TechnicalEquipment = () => {

  const { language } = useSelector(state => state.proposal)

  if(language === "English"){
  return (
    <div className={styles.desktop10} style={{marginTop:"30px", marginBottom:"50px"}}>
        {/* <div className={styles.div}>06</div> */}
        
       {/* <div style={{backgroundColor:"#4ed5ee", height:"60px", width:"100%"}}>
       </div> */}
       <Grid
          container
          spacing={3}
          sx={{ width: "98%" }}
        >
      <Grid item xs={12} lg={8}>
      <div className={styles.detail1}>
        <p>
          <b>Facilities are a very important aspect of a conference with simultaneous translation; quality equipment is required for impeccable service. Here are our solutions:</b>
        </p>
      </div>
      </Grid> 
        <Grid item xs={12} lg={4}>

        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
        <Grid
          container
          spacing={3}
          className={styles.section}
          sx={{ width: "98%", marginLeft:"10px" }}
        >
          <Grid
            item
            xs={12}
            className={styles.section1}
            id={styles.sub_section1}
          >
          <img   src="/assets/desktop10/image-22@2x.webp" />
          <div className={styles.text}>
            <b>Table Booth: </b> No place for an interpreting booth? No problem! We are one of the few companies in the country to have the Standard system with table booths. Thanks to our 120 cm Desktop booths in compliance with ISO/AIIC standards, with two workstations, we can offer you a series of advantages: - Reduction of assembly and disassembly times and costs: just a few minutes! - Guaranteed quality of the interpreting service: the interpreter is perfectly capable of isolating himself from the external environment. - Full compliance with international standards: safety and quality are our priorities. - Minimum size (measurements: 1.60 X 1.20) and unchanged design of the surrounding space.
          </div>
        </Grid>
        <Grid
          item
          xs={12}
          className={styles.section1}
          // sx={{ display: "flex" }}
        >
          <img   src="/assets/desktop10/image-20@2x.webp" />
          <div className={styles.text}>
            <b>Premium Simultaneous System: </b> 
            Our best translation system, practical and effective, thanks to the use of 360 degree soundproof booths (Premium booth: 168 cm soundproofed interpreting booth in accordance with ISO/AIIC standards, with two workstations, overall dimensions: 168x 204 metres). Translation is carried out simultaneously by professionals in special soundproofed interpreting booths. The interpreter receives the voice through headphones and translates into the microphone. The signal is distributed on the different frequencies through individual receivers supplied by the public.
          </div>
          </Grid>
        </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
        <div className={styles.video}>
    <LiteYouTubeEmbed 
        id="Z4vGrQxHQ38"
        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
    />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}  sx={{ width: "98%" }}>
      <Grid item xs={12} lg={8}>
      <div className={styles.detail2}>
      It doesn't matter where in Italy you are: in case of request for specialized assistance, one of our technicians will assist you from the first inspection phase, and for the entire duration of the event, thus guaranteeing an incomparable success.
        
      </div>
      </Grid>
      <Grid item xs={12} lg={4}>

</Grid>
</Grid>
      
    </div>
  );
  }
  else{
    return (
      <div className={styles.desktop10} style={{marginTop:"30px", marginBottom:"50px"}}>
        {/* <div className={styles.div}>06</div> */}
        
       {/* <div style={{backgroundColor:"#4ed5ee", height:"60px", width:"100%"}}>
        
       </div> */}
       <Grid
          container
          spacing={3}
          sx={{ width: "98%" }}
        >
      <Grid item xs={12} lg={8}>
      <div className={styles.detail1}>
       
          <p>
            <b>Gli impianti rappresentano un aspetto molto importante di una conferenza con traduzione simultanea; è necessaria un’attrezzatura di qualità per un servizio impeccabile. Ecco le nostre soluzioni:</b>
          </p>
        </div>
        </Grid> 
        <Grid item xs={12} lg={4}>

        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item xs={12} lg={8}>
        <Grid
          container
          spacing={3}
          className={styles.section}
          sx={{ width: "98%", marginLeft:"10px" }}
        >
          <Grid
            item
            xs={12}
            className={styles.section1}
            id={styles.sub_section1}
          >
            <img   src="/assets/desktop10/image-22@2x.webp" />
            <div className={styles.text}>
              <b>Cabina da Tavolo:</b> Non c’è posto per una cabina di interpretariato? Nessun problema!
  Siamo una delle poche aziende sul territorio nazionale a disporre del sistema Standard con cabine da tavolo. Grazie alle nostre cabine Desktop da 120 cm a norme ISO/AIIC, a due posti lavoro, possiamo offrirvi una serie di vantaggi: - Riduzione dei tempi e dei costi di montaggio e smontaggio: solo pochi minuti!
  - Qualità del servizio di interpretariato garantita: l’interprete è perfettamente in grado di isolarsi dall’ambiente esterno. - Pieno rispetto degli standard internazionali: sicurezza e qualità sono le nostre priorità. 
  -  Ingombro minimo  (misure: 1,60 X 1,20) e design dello spazio intorno immutato.
            </div>
          </Grid>
          <Grid
            item
            xs={12}
            className={styles.section1}
            // sx={{ display: "flex" }}
          >
            <img   src="/assets/desktop10/image-20@2x.webp" />
            <div className={styles.text}>
              <b>Sistema di Simultanea Premium:</b> il nostro miglior sistema di
              traduzione, pratico ed efficace, grazie all’utilizzo di cabine
              insonorizzate a 360 gradi (Cabina premium: Cabina interpreti insonorizzata da 168 cm a norme ISO/AIIC, a due posti lavoro, misure ingombro: 168x 204 metri) La traduzione viene svolta in simultanea da professionisti all'interno di apposite cabine interpreti insonorizzate. L’interprete riceve la voce in cuffia e traduce al microfono. Il segnale viene distribuito sulle diverse frequenze attraverso ricevitori individuali in dotazione del pubblico.
            </div>
          </Grid>
        </Grid>
        </Grid>
        <Grid item xs={12} lg={4}>
        <div className={styles.video}>
    <LiteYouTubeEmbed 
        id="Z4vGrQxHQ38"
        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
    />
          </div>
        </Grid>
      </Grid>
      <Grid container spacing={3}  sx={{ width: "98%" }}>
      <Grid item xs={12} lg={8}>
      <div className={styles.detail2}>
        Non importa in che zona d'Italia vi troviate: in caso di richiesta di assistenza specializzata, uno
        dei nostri tecnici Vi assisterà dalla fase di primo sopralluogo, e per tutta la durata dell'evento,
        garantendone così un successo impareggiabile. 
          
        </div>
      </Grid>
      <Grid item xs={12} lg={4}>

      </Grid>
      </Grid>
       
        {/* <div className={styles.footer}>
        <div style={{width:"720px", paddingTop:"60px",marginInline:"auto"}}>
    <LiteYouTubeEmbed 
        id="Z4vGrQxHQ38"
        title="What’s new in Material Design for the web (Chrome Dev Summit 2019)"
    />
          </div>
        </div> */}
      </div>
    );
  }
};

export default TechnicalEquipment;

import React, {useEffect, useState} from 'react'
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import InterpretClient from 'src/sections/interpretClientOutput'
import { INTERPRETATION_QUOTE_DETAILS } from "src/graphQL/queries";
import { useDispatch, useSelector } from 'react-redux';
import {  addDetails, addLanguageAndId, addOption } from 'src/redux/slices/proposal';
import RafikyClient from 'src/sections/interpretClientOutput/rafiky';



export default function InterpretClientOutput() { 

  const {id, lang} = useParams()

  // const [sendNotification] = useMutation(PROPOSAL_NOTIFICATION_INTERPRETATION)
  const dispatch = useDispatch()
  const {  data } = useQuery(INTERPRETATION_QUOTE_DETAILS, {
    variables: { interpretationOrderId: id },
  });
 


useEffect(() => {
 //passing getData method to the lifecycle method
 async function fetchData(){
//   if(data){
//    await sendNotification({variables:{pageOpeningNotificationId:id}})
//   //  .then((res)=>console.log(res?.data))
//  }
dispatch(addDetails({details: data?.interpretationOrder, type:"CI"}))

//  dispatch(addDetails(data?.interpretationOrder))
 dispatch(addLanguageAndId({language: lang ? lang : data?.interpretationOrder?.language, id:id}))
 
 }
 const scrollToSection = () => {
  // Get the hash from the URL
  const hash = window.location.hash;
  // If hash exists and it's not empty
  if (hash !== '' && hash.length > 1) {
    // Scroll to the section with the specified ID
    const element = document.querySelector(hash);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    } else {
      console.error(`Element with ID '${hash}' not found`);
    }
  }
};

// Call the function when the component mounts
scrollToSection();
fetchData()
}, [data])

  return (
    <>
{data && data?.interpretationOrder?.brand && data?.interpretationOrder?.brand?.rafiky ? <RafikyClient/> :
<InterpretClient/>
}    
</>
  )
}

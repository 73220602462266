import React from 'react';
import styles from '../styles/Contact.module.css';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import { PDFDownloadLink } from '@react-pdf/renderer';
import PDFDoc from '../PDFDoc/PDFDoc';
import { useSelector } from 'react-redux';


export default function Contact() {
  
  const {language, details} = useSelector((state)=>state.proposal);

  const {accountManager} = details;
  
  return (
    <div className={styles.desktop19}> 
      <div className={styles.mainVector}>
        <div className={styles.vector1}></div>
        <div className={styles.vector2}></div>
        <div className={styles.vector3}></div>
        <div className={styles.vector4}></div>
        <div className={styles.vector5}></div>
        <div className={styles.vector6}></div>
      </div>
      <img className={styles.desktop19Child} alt="" src="/translationAssets/desktop5/group-1000001731.svg" />
      <div className={styles.text3543Parent}>
        <img   alt="" src="/translationAssets/desktop5/group-1000001730.png" />{' '}
      </div>
      {language === "English" ?  <div className={styles.addresses}>
        <div className={styles.address1}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" />
            London
          </b>
          <p>
          71-75 Shelton Street, Covent Garden,
            <br />
            London, WC2H9JQ
            <br />
            Email: info.uk@lingoyou.com
          </p>
        </div>
        <div className={styles.address2}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> Madrid
          </b>
          <p>
          Pinar, 5, 28006 
            <br />
           Email: info.es@lingoyou.com
          </p>
        </div>
        <div className={styles.address3}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> Dubai
          </b>
          <p>
          Level 12, Ajman Chamber of
          <br/>
Commerce building, Corniche,Ajman
            <br />
            Email: info.ae@lingoyou.com
          </p>
        </div>
        <div className={styles.address4}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> Milan
          </b>
          <p>
          Viale Fulvio Testi, 59 Milan
            <br />
            20158 Italy
            <br />
            Email: info.it@lingoyou.com
          </p>
        </div>
        <div className={styles.address5}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> Paris
          </b>
          <p>
            3B Rue Taylor, CS 20004 Paris,
            <br />
            75481
            <br />
            Email: info.fr@lingoyou.com
          </p>
        </div>
        <div className={styles.address6}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> Tokyo
          </b>
          <p>
          1-8-2 Marunouchi, 4F Tekko Building,
            <br />
            Chiyoda-ku, Tokyo, Tokyo, <br/>100-0005

            <br />
            Email: info.jp@lingoyou.com
          </p>
        </div>{' '}
        <div className={styles.address7}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> New York
          </b>
          <p>
            405 Lexington Avenue, New York
            <br />
            City, NY 10174
            <br />
            Email: info.us@lingoyou.com
          </p>
        </div>
        <div className={styles.address8}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> Copenhagen
          </b>
          <p>
            Tuborg Boulevard 12,
            <br />
            Copenhagen, 2900 Hellerup
            <br />
            Email: info.dk@lingoyou.com
          </p>
        </div>
        
      </div>:<div className={styles.addresses}>
        <div className={styles.address1}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" />
            Londra
          </b>
          <p>
            71-75 Shelton Street, Covent Garden,
            <br />
            London, WC2H9JQ
            <br />
            Email: info.uk@lingoyou.com
          </p>
        </div>
        <div className={styles.address2}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> Madrid
          </b>
          <p>
          Pinar, 5, 28006 
            <br />
           Email: info.es@lingoyou.com
          </p>
        </div>
        <div className={styles.address3}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> Dubai
          </b>
          <p>
            Level 12, Ajman Chamber of
            <br />
            Commerce building,Corniche,Ajman
            <br />
            Email: info.ae@lingoyou.com
          </p>
        </div>
        <div className={styles.address4}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> Milano
          </b>
          <p>
          Viale Fulvio Testi, 59 Milan
            <br />
            20158 Italy
            <br />
            Email: info.it@lingoyou.com
          </p>
        </div>
        <div className={styles.address5}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> Parigi
          </b>
          <p>
            3B Rue Taylor, CS 20004 Paris,
            <br />
            75481
            <br />
            Email: info.fr@lingoyou.com
          </p>
        </div>
        <div className={styles.address6}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> Tokyo
          </b>
          <p>
          1-8-2 Marunouchi, 4F Tekko Building,
            <br />
            Chiyoda-ku, Tokyo, Tokyo, <br/>100-0005

            <br />
            Email: info.jp@lingoyou.com
          </p>
        </div>{' '}
        <div className={styles.address7}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> New York
          </b>
          <p>
            405 Lexington Avenue, New York
            <br />
            City, NY 10174
            <br />
            Email: info.us@lingoyou.com
          </p>
        </div>
        <div className={styles.address8}>
          <b>
            <img   src="/translationAssets/icons/Location.svg" /> Copenhagen
          </b>
          <p>
            Tuborg Boulevard 12,
            <br />
            Copenhagen, 2900 Hellerup
            <br />
            Email: info.dk@lingoyou.com
          </p>
        </div>
        
      </div>
      }
      <div className={styles.contact}>
        <img src="/translationAssets/icons/Phone.svg" /> <br />
        <p>
          {accountManager?.jobTitle}
          <br />
          {accountManager?.name}
          <br />
          Email: info@lingoyou.com
          <br />
          Telefono: +39 0282957101
        </p>
      </div>
      <PDFDownloadLink fileName={language==="English" ? `QUOTE LY-${details?.orderNumber}`:`PREVENTIVO LY-${details?.orderNumber}`} style={{
              position: 'absolute',
              bottom: '4%',
              borderRadius:"5px",
              right: '10%',
              opacity: '1',
              zIndex: 99,
              background: '#5D00A2',
              color: 'white',
            }} document={<PDFDoc language={language} details={details} />}>
          {({ loading }) => (loading ? <CircularProgress

            className={styles.loadingQuoteBtn} color="secondary" /> : <Button
            sx={{
              background: '#5D00A2',
              color: 'white',
            }}
              className={styles.quotebutton} size="medium" >
            Download Quote as Pdf
          </Button>)}
        </PDFDownloadLink>
      {/* {!pdfLoading ? (
        <Button
          sx={{
            position: 'absolute',
            bottom: '9%',
            right: '2%',
            opacity: '1',
            zIndex: 99,
            background: '#5D00A2',
            color: 'white',
          }}
          className={styles.button}
          size="medium"
          onClick={handlePdf}
        >
          Download as Pdf
        </Button>
      ) : (
        <CircularProgress
        sx={{
          position: 'absolute',
          bottom: '9%',
          right: '2%',
          opacity: '1',
          zIndex: 99,
        }}
        className={styles.loadingBtn} color="secondary" />
      )} */}
    </div>
  );
}

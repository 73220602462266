import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './globalStyles';

const OnSiteInterpreting = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
       <View style={{ ...styles.header, marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
        <Text style={{...styles.headerItemText, fontSize:"10px"}}>
        {language === "English" ? "HYBRID SYSTEM ON-SITE INTERPRETING" : 
        "SISTEMA IBRIDO INTERPRETARIATO ON-SITE"}

        </Text>        
          </View>
         <View>
          <Image style={{...styles.headerRightImg }} src="/rafiky/logo.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={styles.techBody}>
       <Text style={{...styles.techParaBody, fontWeight:"bold", fontSize:"14px", color:"#8316d3"}}>
       {language === "English" ? "MODALITY" : " MODALITÀ"}

       </Text>
        <Text style={styles.techParaBody}>
        - {language === "English" ? "On Site Speakers" : " Relatori on-site"}.
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
        - {language === "English" ? "Interpreters work remotely" : "Gli interpreti lavorano da remoto"}.
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
        - {language === "English" ? "Participants listen to the conference via receiver and headphones or mobile app in the desired language." :
         " I partecipanti ascoltano la conferenza tramite ricevitore e cuffie o mobile app nella lingua desiderata."}
        </Text>
       
              </View>
              <View style={styles.techBody}>
       <Text style={{...styles.techParaBody, fontWeight:"bold", fontSize:"14px", color:"#8316d3"}}>
       {language === "English" ? "THE ADVANTAGES" : 
"I VANTAGGI"}
       </Text>
        <Text style={styles.techParaBody}>
        - {language === "English" ? "Save 50% on technical equipment (no cabin needed)." : 
"Risparmio del 50% sull’attrezzatura tecnica (nessuna cabina necessaria)."}        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal"}}>
        - {language === "English" ? "Ideal for rooms where the installation of cabins is not possible/requires effort and greater costs." : 
        " Ideale per sale in cui l’installazione delle cabine non è possibile/richiede sforzi e costi maggiori."}
        </Text>
        <Text style={{...styles.techParaBody, fontStyle:"normal", marginBottom:"10px"}}>
        - {language === "English" ? "Audio-video direction and on-site technical assistance." : 
        " Regia audio-video ed assistenza tecnica in loco."}
        </Text>
       
              </View>
      <View style={styles.techFooter}>
      <View style={{width:"400px"}}>
      <Image style={styles.techFooterImage} src="/rafiky/brochure/onSiteInterpreting.jpg"/>
        </View>
      </View>
    </Page>
  );
};

export default OnSiteInterpreting;
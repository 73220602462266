import { StyleSheet, Font } from "@react-pdf/renderer";
import PoppinsRegular from '../../pdf-commons/fonts/Poppins-Medium.ttf';
import PoppinsLight from '../../pdf-commons/fonts/Poppins-Light.ttf';
import PoppinsBold from '../../pdf-commons/fonts/Poppins-Bold.ttf'
 



Font.register({
  family: "Poppins 'sans-serif'", fonts: [
    { src: PoppinsRegular, fontStyle: 'normal', fontWeight: "normal" },
    { src: PoppinsRegular, fontStyle: 'italic', fontWeight: "normal" },
    { src: PoppinsLight, fontStyle: 'normal', fontWeight: "light" },
    { src: PoppinsBold, fontStyle: 'normal', fontWeight: "bold" },
  ]
})


export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    paddingHorizontal: "20px",
    paddingVertical: "10px",
    fontFamily: "Poppins 'sans-serif'"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "40px",
    borderBottom: "1px solid #8316d3",
    marginBottom: "50px"
  },
  headerItem: {
    flex: 1,
  },
  headerItemText: {
    color: "#8316d3",
    textAlign: "left",
    fontSize: "16px",
  },
  headerRight: {
    flex: 1,
    display: "flex",
  },
  headerRightImg: {
    width: "110px",
  },
  //introduction

introductionBody:{
  marginLeft: "15px",
  marginTop: "20px",
  textAlign: "justify",
  color: "#161F30",
  width: "95%",
},
introductionParaBody:{
  fontFamily: "Poppins 'sans-serif'",
  fontStyle: "normal",
  fontWeight: "normal",
  fontSize: "10px",
  marginBottom:"15px"
},
introductionFooter:{
  backgroundColor:"#39065F",
  padding: "15px",
  color: "#FFFFFF",
  flex: 1 
},
introductionFooter1:{
  backgroundColor:"#8316d3",
  padding: "15px",
  color: "#FFFFFF",
  flex: 1 
},
introductionFooterImages1:{
  display:"block",
  marginBottom:"-10px"
},
avatarAM:{
  width: "110px",
  height: "110px",
  borderRadius: "50%",
  marginInline:"auto",
  textAlign:"center",
  justifyContent:"center",
  placeContent:"center",
  marginLeft:"220px",
},
sign:{
  width: "120px",
  height: "80px",
  marginInline:"auto",
  textAlign:"center",
  justifyContent:"center",
  placeContent:"center",
  marginLeft:"220px"

},
introductionFooterNames1:{
  display:"block",
},
introductionFooterName:{
  marginInline:"auto",
  fontSize:"11px",
  textAlign:"center",
  fontWeight:"bold"
},
introductionFooterJobs1:{
  display:"block",
},
introductionFooterJob:{
  marginInline:"auto",
  marginLeft:"230px",
  textAlign:"center",
  fontSize:"10px",
  fontStyle:"italic",
  display:"inline-flex",
  flexDirection:"row"
},
artworkIcon:{
  width:"15px",
  height:"10px",
  marginLeft:"5px",
  marginTop:"3px"
},
  tableCustomer: {
    width: "75%",
    marginHorizontal: "auto",
    marginBottom: "50px",
  },
  tableCustomerHeader: {
    backgroundColor: "#2fcbe9",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: "10px",
    paddingVertical: "16px",
    borderRadius:"4px"
  },
  tableCustomerItem: {
    flex: 1,
    alignItems: "flex-start",
  },
  tableCustomerText: {
    fontSize: "10px",
  },
  tableCustomerBody: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    fontSize: "11px",
  },
  bold: {
    fontWeight: "bold",
  },
  light: {
    fontWeight: "light",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  del: {
    textDecoration: "line-through"
  },
  celCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  description: {
    fontSize: "8px",
  },
  discountImage:{
  },
  discountTextSection:{
  display:"inline-flex",
  flexDirection:"row"
  },
  discountImages:{
  width:"200px",
  marginTop:"-840px",
  height:"auto",
  objectFit:"contain",
  marginLeft:"20px"
  },
  discountText:{
  marginTop:"-250px",
  marginLeft:"10px",
  marginRight:"10px"
  },
  discountList:{
    color:"white",
    fontSize:"10px",
    marginBottom:"10px",
  },
  privacy:{
    marginTop:"10px",
    flexDirection:"column",
    display:"block"
  },
  techBody:{
    marginLeft: "15px",
    marginTop: "20px",
    textAlign: "justify",
    color: "#161F30",
    width: "95%",
  },
  techParaBody:{
    fontFamily: "Poppins 'sans-serif'",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "9px",
    marginBottom:"5px"
  },
  techSections:{
  display:"inline-flex",
  flexDirection:"row",
  marginLeft:"5px",
  marginRight:"5px"
  },
  techSection1:{
  width:"50%",
  display:"inline-flex",
  flexDirection:"row"
  },
  techSection2:{
    width:"50%",
    display:"inline-flex",
    flexDirection:"row",
    marginLeft:"5px"
    },
  techSectionImage:{
    width:"50px",
    height:"40px",
    backgroundColor:"#8316d3"
  },
  techSectionText:{
    fontFamily: "Poppins 'sans-serif'",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "6px",
    marginBottom:"5px",
    marginLeft:"5px",
    width: "280px",
    textAlign:"justify"
  },
  techFooter:{
    backgroundColor:"#8316d3",
    padding: "5px",
    color: "#FFFFFF",
    flex: 1,
    alignItems:"center",
    marginInline:"auto",
    placeContent:"center"
  },
  techFooterImage:{
    width:"100%",
    alignItems:"center",
    marginInline:"auto",
    placeContent:"center"
  },
  //Testimonials
  testimonial:{
    marginTop:"10px",
    flexDirection:"column",
    display:"block"
  },

  testimonialCards:{
    display:"inline-flex",
    flexDirection:"row",
    marginRight:"10px",
    marginTop:"20px"
  },
  testimonialCard:{
    width:"200px",
    height:"130px",
    marginLeft:"10px",
    backgroundColor:"white",
    borderRadius:"12.12px",
    padding:"5px"
  },
  testimonialImage: {
    marginTop:"15px", display:"inline-flex", flexDirection:"row",
    marginRight:"10px"
  },
  dividerText:{
    height: "41px",
    width:"100%",
    backgroundColor: "#8316d3",
    padding: "10px",
    textAlign: "center",
    textTransform: "uppercase",
    color: "#FFFFFF",
    fontFamily: "Poppins 'sans-serif'",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "12px",
  },
});

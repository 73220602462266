import { TableCell, TableHead, TableRow } from '@mui/material';
import React from 'react'
import { useSelector } from 'react-redux';

function Table2Header() {

    const {language, details} = useSelector((state)=>state.proposal);

  return (
    <TableHead
    sx={{
      borderBottom: (theme) => `solid 1px ${theme.palette.divider}`,
      '& th': { backgroundColor: '#2DA0F0' },
    }}
  >
    <TableRow>
      <TableCell
        sx={{
          backgroundColor: '#2DA0F0',
          width: '65%',
          color: 'white',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
        }}
        align="left"
      >
        {language === 'English' ? 'Description: ' : 'Descrizione:'}{' '}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          backgroundColor: '#2DA0F0',
          color: 'white',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
          width: '10%',
        }}
      >
        {language === 'English' ? 'Rate: ' : 'Tariffa:'}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          backgroundColor: '#2DA0F0',
          color: 'white',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
          width: '10%',
        }}
      >
        {' '}
        {language === 'English' ? 'No. of words' : 'N. di parole'}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          backgroundColor: '#2DA0F0',
          color: 'white',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
        }}
      >
        {' '}
        {language === 'English' ? 'Discount' : 'Sconto'}
      </TableCell>
      <TableCell
        align="center"
        sx={{
          backgroundColor: '#2DA0F0',
          color: 'white',
          fontFamily: "'Poppins', sans-serif",
          fontSize: 'large',
          width: '100%',
        }}
      >
        {' '}
        {language === 'English' ? 'Subtotal' : 'Subtotale'}
      </TableCell>
    </TableRow>
  </TableHead>
  )
}

export default Table2Header
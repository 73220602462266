import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './globalStyles';

const WebConferencing = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
       <View style={{ ...styles.header, marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
        <Text style={{...styles.headerItemText, fontSize:"9px"}}>
        {language === 'English'
          ? 'INTEGRATION OF OTHER WEB CONFERENCING SOLUTIONS'
          : 'INTEGRAZIONE ALTRE SOLUZIONI DI WEB CONFERENCING'}
          </Text>    
              </View>
         <View>
          <Image style={{...styles.headerRightImg }} src="/rafiky/logo.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={{...styles.techBody, marginBottom:"20px"}}>
      <Text style={{...styles.techParaBody,  fontSize:"12px", marginBottom:"15px",}}>
      {language === 'English'
            ? 'Rafiky is compatible with all Web Conferencing software. So, you can also opt for a hybrid solution, via laptop, tablet or mobile app!'
            : 'Rafiky è compatibile con tutti i software di Web Conferencing. Quindi, puoi anche optare per una soluzione ibrida, tramite laptop, tablet o mobile app!'}
          </Text>
        <Text style={{...styles.techParaBody,  fontSize:"12px", marginBottom:"15px",}}>
        {language === 'English'
            ? 'Participants will be able to observe the speaker in the third-party platform while listening to the translation provided by the interpreters on Rafiky.'
            : 'I partecipanti saranno in grado di guardare il relatore nella piattaforma terza pur ascoltando la traduzione fornita dagli interpreti su Rafiky.'}
        </Text>
        <Text style={{...styles.techParaBody, fontSize:"12px", marginBottom:"15px", fontStyle:"normal"}}>
        {language === 'English'
            ? 'Most used solution for third-party integrations: Mobile App.'
            : 'Soluzione più utilizzata per integrazioni con terze parti: Mobile App.'}   
        </Text>
        <Text style={{...styles.techParaBody, fontSize:"12px", marginBottom:"15px", fontStyle:"normal"}}>
        {language === 'English'
            ? 'Just like an audio receiver in an on-site event, participants via the Rafiky app will be able to listen to.'
            : 'Proprio come un ricevitore audio in un evento on-site, i partecipanti, tramite l’app di Rafiky, potranno ascoltare.'}
         </Text>
        
        <Text style={{...styles.techParaBody, fontSize:"12px", marginBottom:"15px", fontStyle:"normal"}}>
      
       • {language === 'English'
            ? 'Floor (consisting of third party application audio channeled to our app)'
            : ' Floor (composto dall’audio dell’applicazione terza incanalato sulla nostra app)'}
        </Text>
        <Text style={{...styles.techParaBody, fontSize:"12px", marginBottom:"15px", fontStyle:"normal"}}>
        • {language === 'English'
            ? 'Translation channels'
            : 'Canali di traduzione'}
    
        </Text>
        
              </View>
              <Text style={{...styles.dividerText,color:"white", padding:"20px",textAlign: "center", backgroundColor:"#4bd5f1"}}>
              {language === "English" ? "Events held with:" : "Eventi svolti con:"}

       </Text>
       <Image style={{width:"100%", marginLeft:"5px", objectFit:"contain", zIndex:"-99",height:"auto"}}  
     src="/rafiky/brochure/webconferencing_logos.png"/>
    </Page>
  );
};

export default WebConferencing;
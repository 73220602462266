import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const Blogs = ({ language }) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={{ ...styles.header, marginBottom: 0, width: '94%', marginHorizontal: 'auto' }}>
        <View style={{ ...styles.headerItem }}>
          <Text style={styles.headerItemText}>{language === 'English' ? `BLOG & GALLERY` : `BLOG & GALLERY`}</Text>
        </View>
        <View>
          <Image style={styles.headerRightImg} src="/assets/desktop6/group-1000001719.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={styles.blogList}>
        <Link
          style={{ ...styles.blogsLink }}
          src="https://congressiinternazionali.it/interpreti-il-processo-di-selezione-degli-interpreti-come-garantire-un-servizio-di-qualita-per-il-tuo-evento/"
        >
          {language === 'English'
            ? `The interpreter selection process: how to guarantee a quality service for your event`
            : `Il processo di selezione degli interpreti: come garantire un servizio di qualità per il tuo evento`}
        </Link>
        <Link
          style={{ ...styles.blogsLink }}
          src="https://congressiinternazionali.it/interpretariato-di-conferenza-quanti-interpreti-servono-per-il-tuo-evento-una-guida-pratica-per-scegliere-il-numero-giusto/"
        >
          {language === 'English'
            ? `How many interpreters do you need for your event? A practical guide to choosing the right number`
            : `Quanti interpreti servono per il tuo evento? Una guida pratica per scegliere il numero giusto`}
        </Link>
        <Link
          style={{ ...styles.blogsLink }}
          src="https://congressiinternazionali.it/interpretazione-simultanea-e-consecutiva-quando-usare-luna-o-laltra/"
        >
          {language === 'English'
            ? `Simultaneous and consecutive interpreting: when to use one or the other`
            : `Interpretazione simultanea e consecutiva: quando usare l’una o l’altra`}
        </Link>
        <Link
          style={{
            ...styles.blogsLink,
            width: '100px',
            border: '1px solid black',
            borderRadius: '30%',
            textAlign: 'center',
            paddingTop: '5px',
            height: '30px',
            backgroundColor: 'cyan',
            color: 'white',
          }}
          target="_blank"
          src="https://congressiinternazionali.it/"
        >
          {language === 'English' ? `Visit the site` : `Visita il sito`}
        </Link>
      </View>
      <View style={styles.blogList}>
        <Link
          style={{ ...styles.blogsLink }}
          src="https://congressiinternazionali.it/interpretazione-simultanea-linterpretazione-simultanea-nelle-conferenze-scientifiche-e-mediche/"
        >
          {language === 'English'
            ? `Simultaneous interpreting in scientific and medical conferences`
            : `L’interpretazione simultanea nelle conferenze scientifiche e mediche`}
        </Link>
        <Link
          style={{ ...styles.blogsLink }}
          src="https://congressiinternazionali.it/interpretariato-consecutivo-per-le-trattative-commerciali-consigli-e-suggerimenti-pratici/"
        >
          {language === 'English'
            ? `Consecutive interpreting for business negotiations: practical tips and tricks`
            : `Interpretariato consecutivo per le trattative commerciali: consigli e suggerimenti pratici`}
        </Link>
        <Link
          style={{ ...styles.blogsLink }}
          src="https://congressiinternazionali.it/interpretariato-di-conferenza-istituzionale-come-gli-interpreti-supportano-i-diplomatici-nei-loro-incarichi-internazionali/"
        >
          {language === 'English'
            ? `How interpreters support diplomats in their international assignments`
            : `Come gli interpreti supportano i diplomatici nei loro incarichi internazionali`}
        </Link>
      </View>
      <View style={{ ...styles.interpretingFooter, height: '340px', marginTop: '5px' }}>
        <Image style={{ objectFit: 'contain', height: '300px' }} src="/assets/images/1.png" />
      </View>
    </Page>
  );
};

export default Blogs;

import { Avatar, Grid } from "@mui/material";
import React from "react";
import styles from "../styles/Testimonials.module.css";
import { useSelector } from "react-redux";

export default function Testimonials() {
  const {language} = useSelector((state)=>state.proposal);
  return (
    <div className={styles.desktop15}>
      <div className={styles.mainVector}>
        <div className={styles.vector1}></div>
        <div className={styles.vector2}></div>
        <div className={styles.vector3}></div>
        <div className={styles.vector4}></div>
        <div className={styles.vector5}></div>
        <div className={styles.vector6}></div>
      </div>
      <div className={styles.desktop15Item} />
      {/* <div className={styles.div}>16</div> */}
      <div className={styles.aboutUs}>TESTIMONIALS</div>
      <div className={styles.text3543Parent}>
      <img
        alt=""
        src="/translationAssets/desktop6/group-1000001719.svg"
      /> </div>
      <Grid container spacing={3} sx={{ placeContent: "center" }}>
        <Grid item xs={12} md={4}>
          <div className={styles.cards}>
            <p>
              <Avatar alt="Cindy Baker" src="/translationAssets/desktop15/testimonialface6.png" />
              <span style={{ display: "block" }}>
                Elisa S.
                <br />
                <img   alt="stars" src="/translationAssets/desktop15/stars.png" />
              </span>
            </p>
            <div className={styles.comment}>
{language === 'English' ? 'We are pleased to inform you that the translation service requested by us was carried out impeccably by the translator, exceeding the best expectations.':
`Siamo lieti di comunicarvi che il servizio di traduzione da noi richiesto è stato svolto dalla traduttrice in maniera impeccabile, superando le migliori aspettative.`
}            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={styles.cards}>
            <p>
              <Avatar alt="Cindy Baker" src="/translationAssets/desktop15/testimonialface5.png" />
              <span style={{ display: "block" }}>
                Giulia F.
                <br />
                <img   alt="stars" src="/translationAssets/desktop15/stars.png" />
              </span>
            </p>
            <div className={styles.comment}>
            {language === 'English' ?  'I want to thank all the team for the service that you offered us on very short notice. Our side everything was perfect, the interpreter provided is a true professional. Thank you!':
`Ci tengo a ringraziare tutto il team per il servizio che ci avete offerto con pochissimo preavviso. Lato nostro è stato tutto perfetto, l'interprete fornito è stato molto preparato e capace. Grazie!`}
            </div>
          </div>{" "}
        </Grid>
        <Grid item xs={12} md={4}>
          <div className={styles.cards}>
            <p>
              <Avatar alt="Cindy Baker" src="/translationAssets/desktop15/testimonialface2.png" />
              <span style={{ display: "block" }}>
                Giuseppe C. <br />
                <img   alt="stars" src="/translationAssets/desktop15/stars.png" />
              </span>
            </p>
            <div className={styles.comment}>
           {language === "English" ? `I did not know this agency, but I decided to trust the great reviews. I confirm everything what you read! They are professional, fast and very kind!
`: `Non conoscevo questa agenzia, ma ho deciso di fidarmi delle ottime recensioni. Confermo tutto quello che leggete! Sono professionali, veloci e gentilissimi!`}
            </div>
          </div>{" "}
        </Grid>
        <Grid item xs={12} md={4} className={styles.bottomCards}>
          <div className={styles.cards}>
            <p>
              <Avatar alt="Cindy Baker" src="/translationAssets/desktop15/testimonialface1.png" />
              <span style={{ display: "block" }}>
                Simone P.
                <br />
                <img   alt="stars" src="/translationAssets/desktop15/stars.png" />
              </span>
            </p>
            <div className={styles.comment}>
            {language === "English" ? `Excellent agency. Reliable, precise and very courteous and kind! Recommended!
`: `Ottima agenzia. Affidabili, precisi e molto cortesi e gentili! Consigliata!`}
            </div>
          </div>
        </Grid>
        <Grid item xs={12} md={4} className={styles.bottomCards}>
          <div className={styles.cards}>
            <p>
              <Avatar alt="Cindy Baker" src="/translationAssets/desktop15/testimonialface4.png" />
              <span style={{ display: "block" }}>
              Valentina M.
                <br />
                <img   alt="stars" src="/translationAssets/desktop15/stars.png" />
              </span>
            </p>
            <div className={styles.comment}>
            {language === "English" ?  `I found a kind and prepared staff! We will definitely work together in the future too!
`:`Staff gentile ed estremamente disponibile! Sicuramente collaboreremo anche in futuro!`}
            </div>
          </div>{" "}
        </Grid>
        <Grid item xs={12} md={4} className={styles.bottomCards}>
          <div className={styles.cards}>
            <p>
              <Avatar alt="Cindy Baker" src="/translationAssets/desktop15/testimonialface3.png" />
              <span style={{ display: "block" }}>
              Francesca M.<br />
                <img   alt="stars" src="/translationAssets/desktop15/stars.png" />
              </span>
            </p>
            <div className={styles.comment}>
            {language === "English" ?  `I found a professional team ready to satisfy every request. Absolutely deserved five stars!`:
            `Ho trovato un team professionale e pronto a soddisfare ogni richiesta. Cinque stelle assolutamente meritate!
            `
            }           </div>
          </div>{" "}
        </Grid>
      </Grid>
     
      <div className={styles.divider}>
        Testimonials
      </div>
      <div className={styles.companiesFeedback}>
          <img loading="lazy" src="/assets/desktop15/facebook.webp"/>
          <img loading="lazy"  src="/assets/desktop15/google.webp"/>
          <img loading="lazy"  src="/assets/desktop15/reviews.webp"/>
      </div>
    </div>
  );
}

import React from 'react'
import styles from '../styles/CustomerGuide1.module.css'
import { Grid, Typography } from '@mui/material'
import { useSelector } from 'react-redux'

export default function CustomerGuide1() {

  const { language} = useSelector(state => state.proposal)

  return (
    <div style={{boxShadow: "rgba(0, 0, 0, 0.4) 0px 0px 5px 3px",}}>
      <section className={styles.header}>
            <span>
            {language === "English" ? "CUSTOMER GUIDE - INTERPRETER ROLE" : "GUIDA PER IL CLIENTE - RUOLO INTERPRETE"}

            </span>
            <img src='/rafiky/logo.webp' alt='logo'/>
        </section>
         <Grid container spacing={2} sx={{pt:3, paddingBottom:"30px",
        backgroundImage:"url('/rafiky/brochure/background.png')", 
        backgroundPosition: "right",
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    width:"100%",
    marginLeft:"0px",
    paddingBottom:"30px"
        }}>
        <Grid item xs={12} md={7} className={styles.section1}>
         
          <ul className={styles.list}>
            <li className={styles.items}>
            {language === "English" ? "ISO PAS 24019 certified interpreter interface." : 
                "Interfaccia interprete certificata ISO PAS 24019."}                </li>
            <li className={styles.items}>
            {language === "English" ? "Best audio and video quality." : 
            "La migliore qualità audio e video."}

            </li>
            <li className={styles.items}>
            {language === "English" ? "Relais mode: Possibility of listening to other interpreter booths." : 
            "Modalità Relais: possibilità di ascoltare altre cabine interpreti. "}
            </li>
            <li className={styles.items}>
            {language === "English" ? "Partner communication: Possibility for interpreters of listening to each other just like during on-site events." : 
"Comunicazione tra partner: possibilità per gli interpreti di ascoltarsi a vicenda, proprio come durante gli eventi in loco."}

            </li>
            <li className={styles.items}>
            {language === "English" ? "Handover: Useful and simple button to set the timer for delivery during service." : 
            "Handover: pulsante utile per impostare il timer di cambio interprete durante il servizio."}

         
            </li>
            <li className={styles.items}>
            {language === "English" ? "Slow down: Asks the speaker to slow down to facilitate the interpreters' work and provide the best translation" : 
            "Slow down: chiede all'oratore di rallentare per facilitare il lavoro degli interpreti e fornire la migliore traduzione"}
          </li>
            <li className={styles.items}>
            {language === "English" ? "Receives viewers' questions privately and then addresses them to the speakers" : "Riceve privatamente le domande dei viewer per poi rivolgerle agli speaker"}
            </li>
            <li className={styles.items}>
            {language === "English" ? "HD Video and Full Screen Mode for the best display of shared files" : 
            "Video HD e modalità a schermo intero per la migliore visualizzazione dei file condivisi"}
            </li>
          </ul>
        </Grid>
        <Grid item xs={12} md={5}>
          <img className={styles.img} alt='question mark' src='/rafiky/brochure/CustomerGuide1.jpg'/>

        </Grid>
      </Grid>
    </div>
  )
}

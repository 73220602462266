import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';
import { SectorsContent } from '../../sectorsText';
import { Clients } from 'src/_mock';

const SectorClients = ({ language, clients, industry }) => {

  const length = clients?.length / 3;
  const logosRendering = Math.ceil(length) 
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={styles.greenImage}>
        <View style={{ marginLeft: '5px' }}>
        <Image 
     style={{ width:"40px", height:"40px",  marginTop:"6px" }}  
     src="/translationAssets/desktop5/lingoyoulogo.png"/>
         <View style={{ marginTop: '6px', fontSize: '9px', color: 'white', width: '97%' }}>
            <Text style={{ fontSize: '11px', marginBottom: '10px', fontWeight: 'normal' }}>
            {language === "English" ? `${SectorsContent[industry?.name]?.en.title}`: 
           `${SectorsContent[industry?.name]?.it.title}`}
            </Text>
            <Text style={{ fontSize: '9px', fontWeight: 'normal', marginBottom: '5px' }}>
            {language === "English" ? `${SectorsContent[industry?.name]?.en.para1}`: 
        `${SectorsContent[industry?.name]?.it.para1}`}

            </Text>
            <Text style={{ marginBottom: '5px', fontSize: '9px', fontWeight: 'normal', flexDirection:"row", display:"flex" }}>
            <Text style={{fontWeight:"bold"}}>
            {language === "English" ? `1. ${SectorsContent[industry?.name]?.en.listItem1Title}`: 
                `1. ${SectorsContent[industry?.name]?.it.listItem1Title}`}

            </Text>
            <Text>
            {language === "English" ? ` ${SectorsContent[industry?.name]?.en.listItem1Para}`: 
                ` ${SectorsContent[industry?.name]?.it.listItem1Para}`}

            </Text>
              </Text>
              <Text style={{ marginBottom: '5px', fontSize: '9px', fontWeight: 'normal', flexDirection:"row", display:"flex" }}>
            <Text style={{fontWeight:"bold"}}>
            {language === "English" ? `2. ${SectorsContent[industry?.name]?.en.listItem2Title}`: 
                `2. ${SectorsContent[industry?.name]?.it.listItem2Title}`}

            </Text>
            <Text>
            {language === "English" ? ` ${SectorsContent[industry?.name]?.en.listItem2Para}`: 
                ` ${SectorsContent[industry?.name]?.it.listItem2Para}`}

            </Text>
              </Text>
              <Text style={{ marginBottom: '5px', fontSize: '9px', fontWeight: 'normal', flexDirection:"row", display:"flex" }}>
            <Text style={{fontWeight:"bold"}}>
            {language === "English" ? `3. ${SectorsContent[industry?.name]?.en.listItem3Title}`: 
                `3. ${SectorsContent[industry?.name]?.it.listItem3Title}`}

            </Text>
            <Text>
            {language === "English" ? ` ${SectorsContent[industry?.name]?.en.listItem3Para}`: 
                ` ${SectorsContent[industry?.name]?.it.listItem3Para}`}

            </Text>
              </Text>
              <Text style={{ marginBottom: '5px', fontSize: '9px', fontWeight: 'normal', flexDirection:"row", display:"flex" }}>
            <Text style={{fontWeight:"bold"}}>
            {language === "English" ? `4. ${SectorsContent[industry?.name]?.en.listItem4Title}`: 
                `4. ${SectorsContent[industry?.name]?.it.listItem4Title}`}

            </Text>
            <Text>
            {language === "English" ? ` ${SectorsContent[industry?.name]?.en.listItem4Para}`: 
                ` ${SectorsContent[industry?.name]?.it.listItem4Para}`}

            </Text>
              </Text>
              <Text style={{ marginBottom: '5px', fontSize: '9px', fontWeight: 'normal', flexDirection:"row", display:"flex" }}>
            <Text style={{fontWeight:"bold"}}>
            {language === "English" ? `5. ${SectorsContent[industry?.name]?.en.listItem5Title}`: 
                `5. ${SectorsContent[industry?.name]?.it.listItem5Title}`}

            </Text>
            <Text>
            {language === "English" ? ` ${SectorsContent[industry?.name]?.en.listItem5Para}`: 
                ` ${SectorsContent[industry?.name]?.it.listItem5Para}`}

            </Text>
              </Text>
            <Text style={{  fontSize: '9px', fontWeight: 'normal', marginTop: '5px' }}>
            {language === "English" ? `${SectorsContent[industry?.name]?.en.para2Pdf}`: 
                `${SectorsContent[industry?.name]?.it.para2Pdf}`}
            </Text>
          </View>
        </View>
        <Image
          style={{ width: '100%', objectFit: 'fill', marginTop: '-520px', zIndex: '-99', height: '540px' }}
          src="/clients/sectorPage.png"
        />
        {industry?.name !== "Religious" && (
                  <View style={{ placeContent: 'center', textAlignLast: 'center', alignItems: 'center', justifyContent: 'center', width:"100%", textAlign:"center", marginBottom:"10px"}}>

         {(!clients || clients?.length === 0) ? <>
          <View style={{marginTop:"10px", placeContent: 'center', textAlignLast: 'center', alignItems: 'center', justifyContent: 'center', display:"flex", flexDirection:"row", width:"80%", textAlign:"center"}}>
          {Clients[industry?.name]?.length > 0  && Clients[industry?.name].slice(0, 5)?.map((client)=>(
            <Image
            style={{
              marginLeft: '5px',
              height:'60px',
              width:'100px',
              objectFit:'contain',
              marginTop: '10px',
            }}
            src={client?.logo}
          />

          ))}
          
          </View>
          <View style={{ placeContent: 'center', textAlignLast: 'center', alignItems: 'center', justifyContent: 'center', display:"flex", flexDirection:"row", width:"60%", textAlign:"center"}}>
          {Clients[industry?.name]?.length > 0 && Clients[industry?.name].slice(5, 9)?.map((client)=>(
            <Image
            style={{
              ...styles.greenImages,
              marginRight: '5px',
              height:'60px',
              width:"70px",
              objectFit:'contain',
              marginTop: '12px',
            }}
            src={client?.logo}
          />

          ))}
          
          </View>
         </> : <>
         <View style={{marginTop:"10px", placeContent: 'center', textAlignLast: 'center', alignItems: 'center', justifyContent: 'center', display:"flex", flexDirection:"row", width:"80%", textAlign:"center"}}>
          {clients?.length > 0  && clients.slice(0, 5)?.map((client)=>(
            <Image
            style={{
              marginLeft: '5px',
              height:'60px',
              width:'100px',
              objectFit:'contain',
              marginTop: '10px',
            }}
            src={client?.logo}
          />

          ))}
          
          </View>
          <View style={{ placeContent: 'center', textAlignLast: 'center', alignItems: 'center', justifyContent: 'center', display:"flex", flexDirection:"row", width:"60%", textAlign:"center"}}>
          {clients?.length > 0 && clients.slice(5, 9)?.map((client)=>(
            <Image
            style={{
              ...styles.greenImages,
              marginRight: '5px',
              height:'60px',
              width:"70px",
              objectFit:'contain',
              marginTop: '12px',
            }}
            src={client?.logo}
          />

          ))}
          
          </View>
         </>
         }
          </View>
          )}
      </View>
    </Page>
  );
};

export default SectorClients;

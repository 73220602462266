import React, { useEffect, useState } from 'react';
import Privacy from 'src/sections/translationClientOutput/components/Privacy';

import '../../../sections/translationClientOutput/index.css';
import { useParams } from 'react-router';
import { useDispatch } from 'react-redux';
import { addLanguageAndId } from 'src/redux/slices/proposal';



export default function PrivacyBrochure() {

const {lang} = useParams()

const dispatch = useDispatch()

useEffect(()=>{
    dispatch(addLanguageAndId({language: lang, id:""}))

}, [1])
  return (
    <>
      <div id="translation-proposal" style={{marginInline: 'auto', boxShadow: '0px 0px 5px 3px rgb(0 0 0 / 40%)' }}>
      
        <Privacy/>
      </div>
     
    </>
  );
}

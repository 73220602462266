import { Grid } from '@mui/material';
import React from 'react';
import styles from '../styles/MiniInterpretation.module.css';
import { useSelector } from 'react-redux';

export default function MiniInterpretation() {
  const { language } = useSelector((state) => state.proposal);

  return (
    <div
    className={styles.pageImg}
      style={{
        backgroundImage: `url('/MiniBrochureImages/bg_congressi.webp')`,
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '100%',
      }}
    >
      <div className={styles.desktop17}>
        {/* <div className={styles.desktop17Child} />
        <div className={styles.interpreting}>INTERPRETATION</div>
        <div className={styles.text3543Parent}>
          <img alt="" src="/assets/desktop6/group-1000001719.svg" />{' '}
        </div> */}
        <div className={styles.header}>
        <h1>
          {language === "English"?"Connect Cultures Seamlessly: Enhance Your Multilingual Events" : 
          "Collegare Culture Senza Interruzioni: Migliora i Tuoi Eventi Multilingue"
          }
          </h1>
          <p>
          {language === "English" ? "Facilitating Smooth Communication Across Diverse Language Groups, Enriching the Experience for Every Participant and Fostering Global Understanding":
          "Facilitare una Comunicazione Fluida tra Diversi Gruppi Linguistici, Arricchendo l'Esperienza per Ogni Partecipante e Promuovendo la Comprensione Globale"}
          </p>
        </div>
        <div className={styles.divider}>
        </div>
        <div className={styles.image}>
          <img src="/MiniBrochureImages/congressi.webp"/>
          </div>
          <div className={styles.btn}>
          <a href='https://congressiinternazionali.it/' target='_blank'>
            Visit the site
          </a>
          </div>
      </div>
    </div>
  );
}

import { Grid } from '@mui/material';
import React from 'react';
import Slider from 'react-slick';
import styles from '../styles/News.module.css';
import { useSelector } from 'react-redux';


export default function News() {
  const { language } = useSelector(state => state.proposal)

  const settings = {
    dots: true,
    infinite: true,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 2500,
    pauseOnHover: false,
  };
    return (
      <div className={styles.desktop17}>
        <div className={styles.desktop17Child} />
        {/* <div className={styles.div}>19</div> */}
        <div className={styles.interpreting}>BLOG & GALLERY</div>
        <div className={styles.text3543Parent}>
          <img alt="" src="/assets/desktop6/group-1000001719.svg" />{' '}
        </div>
        <div className={styles.text}>
          <ul>
            <li>
              <a href="https://congressiinternazionali.it/interpreti-il-processo-di-selezione-degli-interpreti-come-garantire-un-servizio-di-qualita-per-il-tuo-evento/" target="_blank" rel="noreferrer">
              {language === 'English'
            ? `The interpreter selection process: how to guarantee a quality service for your event`
            : `Il processo di selezione degli interpreti: come garantire un servizio di qualità per il tuo evento`}
                                   </a>
            </li>
            <li>
              <a
                href="https://congressiinternazionali.it/interpretazione-simultanea-linterpretazione-simultanea-nelle-conferenze-scientifiche-e-mediche/"
                target="_blank" rel="noreferrer"
              >
              {language === 'English'
            ? `Simultaneous interpreting in scientific and medical conferences`
            : `L’interpretazione simultanea nelle conferenze scientifiche e mediche`}
              </a>{' '}
            </li>
          </ul>
          <ul>
            <li>
              <a
                href="https://congressiinternazionali.it/interpretariato-di-conferenza-quanti-interpreti-servono-per-il-tuo-evento-una-guida-pratica-per-scegliere-il-numero-giusto/"
                target="_blank" rel="noreferrer"
              >
{language === 'English'
            ? `How many interpreters do you need for your event? A practical guide to choosing the right number`
            : `Quanti interpreti servono per il tuo evento? Una guida pratica per scegliere il numero giusto`}
       
          </a>
            </li>
            <li>
              <a
                href="https://congressiinternazionali.it/interpretariato-consecutivo-per-le-trattative-commerciali-consigli-e-suggerimenti-pratici/"
                target="_blank" rel="noreferrer"
              >
{language === 'English'
            ? `Consecutive interpreting for business negotiations: practical tips and tricks`
            : `Interpretariato consecutivo per le trattative commerciali: consigli e suggerimenti pratici`}
             </a>
            </li>
          </ul>
          <ul>
            <li>
              <a
                href="https://congressiinternazionali.it/interpretazione-simultanea-e-consecutiva-quando-usare-luna-o-laltra/"
                target="_blank" rel="noreferrer"
              >
{language === 'English'
            ? `Simultaneous and consecutive interpreting: when to use one or the other`
            : `Interpretazione simultanea e consecutiva: quando usare l’una o l’altra`}            </a>
            </li>
            <li>
              <a
                href="https://congressiinternazionali.it/interpretariato-di-conferenza-istituzionale-come-gli-interpreti-supportano-i-diplomatici-nei-loro-incarichi-internazionali/"
                target="_blank" rel="noreferrer"
              >
{language === 'English'
            ? `How interpreters support diplomats in their international assignments`
            : `Come gli interpreti supportano i diplomatici nei loro incarichi internazionali`}          </a>
            </li>
          </ul>
          <ul>
            <a href="https://congressiinternazionali.it/" target="_blank" rel="noreferrer">
              <button>
              {language === 'English' ? `Visit the site` : `Visita il sito`}
              </button>
            </a>
          </ul>
        </div>

        <Slider {...settings} className={styles.slider}>
          <div>
            <img alt='office' src="/assets/images/1.webp"/>
          </div>
          <div className={styles.slider_images}>
            <img alt='office'  src="/assets/images/2.webp"/>
          </div>
          <div className={styles.slider_images}>
            <img alt='office'  src="/assets/images/3.webp"/>
          </div>
          <div className={styles.slider_images}>
            <img alt='office'  src="/assets/images/4.webp"/>
          </div>
          <div className={styles.slider_images}>
            <img alt='office'  src="/assets/images/5.webp" />
          </div>
          <div className={styles.slider_images}>
            <img alt='office'  src="/assets/images/6.webp"/>
          </div>
          <div>
            <img alt='office'  src="/assets/images/7.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/8.webp"/>
          </div>
          <div>
            <img alt='office'  src="/assets/images/9.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/10.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/11.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image1.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image2.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image3.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image4.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image5.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image6.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image7.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image8.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image9.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image10.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image11.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image12.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image13.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image14.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image15.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image16.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image17.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image18.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image20.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image21.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image22.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image23.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image24.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image25.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image26.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image27.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image28.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image29.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image30.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image31.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image32.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image33.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image34.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image35.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image36.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image37.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image38.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image39.webp" />
          </div>
          <div>
            <img alt='office'  src="/assets/images/image40.webp" />
          </div>
        </Slider>
      </div>
    );
  
}

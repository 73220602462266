import React from 'react';
import { Page, Text, View, Image, Document } from "@react-pdf/renderer";
import { styles } from '../../PDFDoc/globalStyles.js';
import Preventivo from '../../PDFDoc/pdf-components/Preventivo.js';
import TableItems from '../../PDFDoc/pdf-components/TableItems.js';
import AboutUs1 from './AboutUs1.js';
import Introduction from '../../PDFDoc/pages/Introduction.js';
import AboutUs2 from './AboutUs2.js';
import OurClients from '../../PDFDoc/pages/OurClients.js';
import Home from '../../PDFDoc/pages/Home.js';
import Testimonials from '../../PDFDoc/pages/Testimonials.js';
import ContactUs from './ContactUs.js';
import Header from '../../PDFDoc/pdf-components/Header.js';
import TableTotals from '../../PDFDoc/pdf-components/TableTotals.js';
import TableHeader from '../../PDFDoc/pdf-components/TableHeader.js';
import SectorClients from '../../PDFDoc/pages/SectorClients.js';
import TermsAndCondition from './Terms&Condition.js';
import Discount from '../../PDFDoc/pages/Discount.js';
import WhyRafiky from './WhyRafiky.js';
import UseCases from './UseCases.js';
import Connect from './Connect.js';
import CustomerGuide from './CustomerGuide.js';
import CustomerGuide1 from './CustomerGuide1.js';
import WebConferencing from './WebConferencing.js';
import OnSiteInterpreting from './OnSiteInterpreting.js';
import Process from './Process.js';


const PDFDoc = ({ language = "Italian", details,name, items1Translation,
items2Translation,
service1Translation,
service2Translation, quoteId }) => {
  const {
totalDiscountOptions, 
subtotalOptions, 
taxesOptions, 
grandTotalOptions, 
grandTotal,
    companyNameAndId,
    accountManager,
    notes,
    taxes,
    netTotalOptions,
    netTotal,
    totalDiscount,
    subtotal,
    bookedBy,
    additionalService,
    address1,
    quoteDeadline,
    interpretationItem,
    interpretationItem2,
    additionalService2,
    orderNumber,
    companyLogo,
    subject,
    addOns,
    industry,
    clients,
    notesOptions,
    quoteSignature,
    brand
  } = details;
    console.log(details)
    const totalLengthOption2 = interpretationItem2?.length + additionalService2?.length;
  console.log("ServiceTranslation", service1Translation, items1Translation);
  const combinedItems = [
    ...interpretationItem,
    ...additionalService,
  ];

  const itemsPerPage = combinedItems?.length <= 5 ? 4 : 5;
  const totalPages = combinedItems.length > 3 ?  Math.ceil(combinedItems.length / itemsPerPage) : 1;
  
  const renderItemsForPage = (pageNumber) => {
    const combinedItemsTranslation = items1Translation ? [
      ...items1Translation,
      ...service1Translation 
    ] : []
    const startIndex = (pageNumber - 1) * itemsPerPage;
    const endIndex = Math.min(pageNumber * itemsPerPage, combinedItems.length);

    const itemsToRender = combinedItems.slice(startIndex, endIndex);
    const itemsTransToRender = combinedItemsTranslation?.slice(startIndex, endIndex)
    return (
      <TableItems 
      language={language} 
      details={{interpretationItem:itemsToRender}}
      items1Translation={itemsTransToRender ? itemsTransToRender : null}
    />
    );
  };

  const combinedItemOptions =  [...interpretationItem2]?.map((item, index)=>[...item, ...additionalService2[index]])
  let itemsPerPageOption = combinedItemOptions?.length > 0 ?  combinedItemOptions.map((item)=> item?.length <= 5 ? 4 : 5) : [];
  let totalPagesPerOption = [];
 if(itemsPerPageOption?.length > 0){
  for(var i = 0; i < itemsPerPageOption?.length; i++){
   totalPagesPerOption.push(combinedItemOptions[i].length > 3 ?  Math.ceil(combinedItemOptions[i].length / itemsPerPageOption[i]) : 1);
  }
 }else{
  itemsPerPageOption = []
 }
  const renderItemOptionsForPage = (pageNumber, index, optionNo) => {
    const optionItemsTranslation = items2Translation?.length > 0 ? [...items2Translation[optionNo]] : []
    const optionServicesTranslation = service2Translation?.length > 0 ? [...service2Translation[optionNo]] : []
    const combinedItemsTranslation = (optionItemsTranslation?.length > 0 || optionServicesTranslation?.length > 0) ? [
      ...optionItemsTranslation,
      ...optionServicesTranslation 
    ] : null
    const startIndex = (pageNumber - 1) * itemsPerPageOption[optionNo];
    const endIndex = Math.min(pageNumber * itemsPerPageOption[optionNo], combinedItemOptions[optionNo].length);

    const itemsToRender = combinedItemOptions[optionNo].slice(startIndex, endIndex);
    console.log("Options", index, combinedItemOptions[optionNo], totalPagesPerOption);
    console.log("Options1", itemsToRender, itemsPerPageOption)
    const itemsTransToRender = combinedItemsTranslation && combinedItemsTranslation?.slice(startIndex, endIndex)
    return (
      <TableItems 
      language={language} 
      details={{interpretationItem:itemsToRender}}
      items1Translation={itemsTransToRender ? itemsTransToRender : null}
    />
    );
  };
  return (
    <>
   {details?.addOns && (
   <Document >
           {addOns && addOns?.cover && (
            <Home language={language} data={{ companyNameAndId, brand, bookedBy, accountManager, orderNumber, subject, companyLogo, addOns, industry }}/>
           )}
           {addOns && addOns?.companyInfo && (
 <Introduction language={language}
 bookedBy={bookedBy}
 brand={brand}
 addOns={addOns}
  accountManager={accountManager} 
  companyNameAndId={companyNameAndId}
  clients={clients}
  />
           )}
           {addOns && addOns?.sector && (
        <SectorClients language={language} clients={clients} industry={industry}/>
      )}
      {addOns && addOns?.companyInfo && (
        <>
     
      <AboutUs1 language={language}/>
      <AboutUs2 language={language} quoteId={quoteId}/>
      <WhyRafiky language={language}/>  
      <Process language={language} /> 
   <UseCases language={language}/>  
   <Connect language={language}/> 
   <CustomerGuide language={language}/>
      <CustomerGuide1 language={language}/>
      <WebConferencing language={language}/>
      <OnSiteInterpreting language={language}/>
      <Discount language={language}/>
      <OurClients language={language} brand={brand}/>
      <Testimonials language={language} brand={brand}/>
      </>
      )}
      {addOns && addOns?.quoteInfo && (
        <>
     {Array.from({ length: totalPages }, (_, index) => (
      <Page wrap={false} size={[595.28, 640.89]} style={styles.page}>
      {index === 0 ? (
        <>
        <Header language={language} bookedBy={bookedBy} accountManager={accountManager} companyNameAndId={companyNameAndId}/>
         <TableHeader language={language} option={interpretationItem2?.length > 0 ? 1 : 0}/>
         {renderItemsForPage(index + 1)}
        </>
        
      ): (
        <>
               {renderItemsForPage(index + 1)}
        </>
      )}
       {((totalPages - index) === 1) && <>
        <View style={styles.tableItems}> </View>
        <TableTotals details={{ notes,
            totalDiscount,  
            subtotal, netTotal, taxes, grandTotal}}
           language={language}/>
            {totalLengthOption2 === 0 && <Preventivo language={language}/>}
        
       </>
          } 
      </Page>
     ))}
        {totalLengthOption2 > 0 && interpretationItem2?.map((item, index)=>(
       <>
       {Array.from({ length: totalPagesPerOption[index] }, (_, ind) => (
        <Page wrap={ false } size={[595.28, 640.89]} style={styles.page}>
        {ind === 0 ? (
          <>
           <TableHeader language={language} option={index + 2}/>
           {renderItemOptionsForPage(ind + 1, ind, index)}
          </>
          
        ): (
          <>
                 {renderItemOptionsForPage(ind + 1, ind, index)}
          </>
        )}
        {((totalPagesPerOption[index] - ind) === 1) && <>
        <View style={styles.tableItems}> </View>
        <TableTotals details={{ notes:notesOptions[index],
          totalDiscount:totalDiscountOptions[index],  
          subtotal: subtotalOptions[index], netTotal:netTotalOptions[index], taxes:taxesOptions[index], grandTotal:grandTotalOptions[index]}} language={language}/>
        {(interpretationItem2?.length - index === 1) && <Preventivo language={language} />
}
        </>
        }
        </Page>
        
      ))}
      </>
     ))}
      <TermsAndCondition 
        data={details}
        brand={brand}
        language={language} 
        additionalService={additionalService}
        interpretationItem={interpretationItem}
        address1={address1}
        quoteDeadline={quoteDeadline}
        companyNameAndId={companyNameAndId}
        name={name}
        quoteSignature={quoteSignature}
        interpretationItem2={interpretationItem2}
        additionalService2={additionalService2}
      /> 

      </>
      )}
      {((addOns && addOns?.companyInfo) || addOns?.quoteInfo || addOns?.cover) && (
      <ContactUs language={language}/>
      )}
    </Document>
    
  )}
  </>
  )
};

export default PDFDoc;
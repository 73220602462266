export const timezone = [
    { text: 'UTC (Coordinated Universal Time)', value: 'UTC (Coordinated Universal Time)' },
    { text: 'UTC (Coordinated Universal Time) Dublin, Edinburgh, London', value: 'UTC (Coordinated Universal Time) Dublin, Edinburgh, London' },
    { text: 'UTC (no DST) Tangiers, Casablanca', value: 'UTC (no DST) Tangiers, Casablanca' },
    { text: 'UTC+00:00 Lisbon', value: 'UTC+00:00 Lisbon' },
    { text: 'UTC+01:00 Algeria', value: 'UTC+01:00 Algeria' },
    { text: 'UTC+01:00 Paris, Madrid', value: 'UTC+01:00 Paris, Madrid' },
    { text: 'UTC+01:00 Berlin, Stockholm, Rome, Bern, Brussels', value: 'UTC+01:00 Berlin, Stockholm, Rome, Bern, Brussels' },
    { text: 'UTC+01:00 Prague, Warsaw', value: 'UTC+01:00 Prague, Warsaw' },
    { text: 'UTC+03:00 Baghdad, Kuwait, Nairobi, Riyadh', value: 'UTC+03:00 Baghdad, Kuwait, Nairobi, Riyadh' },
    { text: 'UTC+02:00 Israel', value: 'UTC+02:00 Israel' },
    { text: 'UTC+02:00 Harare, Pretoria', value: 'UTC+02:00 Harare, Pretoria' },
    { text: 'UTC+02:00 Eastern Europe', value: 'UTC+02:00 Eastern Europe' },
    { text: 'UTC+02:00 Cairo', value: 'UTC+02:00 Cairo' },
    { text: 'UTC+02:00 Athens, Helsinki, Istanbul', value: 'UTC+02:00 Athens, Helsinki, Istanbul' },
    { text: 'UTC+03:00 Moscow, St. Petersburg, Volgograd', value: 'UTC+03:00 Moscow, St. Petersburg, Volgograd' },
    { text: 'UTC+03:30 Tehran', value: 'UTC+03:30 Tehran' },
    { text: 'UTC+04:00 Abu Dhabi, Muscat, Tbilisi, Kazan', value: 'UTC+04:00 Abu Dhabi, Muscat, Tbilisi, Kazan' },
    { text: 'UTC+04:00 Armenia', value: 'UTC+04:00 Armenia' },
    { text: 'UTC+04:30 Kabul', value: 'UTC+04:30 Kabul' },
    { text: 'UTC+05:00 Islamabad, Karachi', value: 'UTC+05:00 Islamabad, Karachi' },
    { text: 'UTC+05:00 Sverdlovsk', value: 'UTC+05:00 Sverdlovsk' },
    { text: 'UTC+05:00 Tashkent', value: 'UTC+05:00 Tashkent' },
    { text: 'UTC+05:30 Mumbai, Kolkata, Chennai, New Delhiselected', value: 'UTC+05:30 Mumbai, Kolkata, Chennai, New Delhiselected' },
    { text: 'UTC+05:45 Kathmandu, Nepal', value: 'UTC+05:45 Kathmandu, Nepal' },
    { text: 'UTC+06:00 Almaty, Dhaka', value: 'UTC+06:00 Almaty, Dhaka' },
    { text: 'UTC+06:00 Omsk, Novosibirsk', value: 'UTC+06:00 Omsk, Novosibirsk' },
    { text: 'UTC+07:00 Bangkok, Jakarta, Hanoi', value: 'UTC+07:00 Bangkok, Jakarta, Hanoi' },
    { text: 'UTC+07:00 Krasnoyarsk', value: 'UTC+07:00 Krasnoyarsk' },
    { text: 'UTC+08:00 Beijing, Chongqing, Urumqi', value: 'UTC+08:00 Beijing, Chongqing, Urumqi' },
    { text: 'UTC+08:00 Hong Kong SAR, Perth, Singapore, Taipei', value: 'UTC+08:00 Hong Kong SAR, Perth, Singapore, Taipei' },
    { text: 'UTC+08:00 Irkutsk (Lake Baikal)', value: 'UTC+08:00 Irkutsk (Lake Baikal)' },
    { text: 'UTC+09:00 Tokyo, Osaka, Sapporo, Seoul', value: 'UTC+09:00 Tokyo, Osaka, Sapporo, Seoul' },
    { text: 'UTC+09:30 Adelaide', value: 'UTC+09:30 Adelaide' },
    { text: 'UTC+09:30 Darwin', value: 'UTC+09:30 Darwin' },
    { text: 'UTC+10:00 Brisbane', value: 'UTC+10:00 Brisbane' },
    { text: 'UTC+10:00 Guam, Port Moresby', value: 'UTC+10:00 Guam, Port Moresby' },
    { text: 'UTC+10:00 Magadan, Vladivostok', value: 'UTC+10:00 Magadan, Vladivostok' },
    { text: 'UTC+10:00 Sydney, Melbourne', value: 'UTC+10:00 Sydney, Melbourne' },
    { text: 'UTC+10:00 Yakutsk (Lena River)', value: 'UTC+10:00 Yakutsk (Lena River)' },
    { text: 'UTC+11:00 Hobart', value: 'UTC+11:00 Hobart' },
    { text: 'UTC+12:00 Eniwetok, Kwajalein', value: 'UTC+12:00 Eniwetok, Kwajalein' },
    { text: 'UTC+12:00 Fiji Islands, Marshall Islands', value: 'UTC+12:00 Fiji Islands, Marshall Islands' },
    { text: 'UTC+12:00 Kamchatka', value: 'UTC+12:00 Kamchatka' },
    { text: 'UTC+12:00 Solomon Islands, New Caledonia', value: 'UTC+12:00 Solomon Islands, New Caledonia' },
    { text: 'UTC+12:00 Wellington, Auckland', value: 'UTC+12:00 Wellington, Auckland' },
    { text: 'UTC+13:00 Apia (Samoa)', value: 'UTC+13:00 Apia (Samoa)' },
    { text: 'UTC-01:00 Azores, Cape Verde Island', value: 'UTC-01:00 Azores, Cape Verde Island' },
    { text: 'UTC-02:00 Mid-Atlantic', value: 'UTC-02:00 Mid-Atlantic' },
    { text: 'UTC-03:00 E Argentina (BA, DF, SC, TF)', value: 'UTC-03:00 E Argentina (BA, DF, SC, TF)' },
    { text: 'UTC-03:00 NE Brazil (MA, PI, CE, RN, PB)', value: 'UTC-03:00 NE Brazil (MA, PI, CE, RN, PB)' },
    { text: 'UTC-03:00 Pernambuco', value: 'UTC-03:00 Pernambuco' },
    { text: 'UTC-03:00 S & SE Brazil (GO, DF, MG, ES, RJ, SP, PR, SC, RS)', value: 'UTC-03:00 S & SE Brazil (GO, DF, MG, ES, RJ, SP, PR, SC, RS)' },
    { text: 'UTC-03:30 Newfoundland', value: 'UTC-03:30 Newfoundland' },
    { text: 'UTC-04:00 Atlantic Time (Canada)', value: 'UTC-04:00 Atlantic Time (Canada)' },
    { text: 'UTC-04:00 Caracas', value: 'UTC-04:00 Caracas' },
    { text: 'UTC-04:00 La Paz', value: 'UTC-04:00 La Paz' },
    { text: 'UTC-05:00 Bogota, Lima', value: 'UTC-05:00 Bogota, Lima' },
    { text: 'UTC-05:00 Eastern Time (US & Canada)', value: 'UTC-05:00 Eastern Time (US & Canada)' },
    { text: 'UTC-05:00 Eastern Time - Indiana - most locations', value: 'UTC-05:00 Eastern Time - Indiana - most locations' },
    { text: 'UTC-06:00 Central Time (US & Canada)', value: 'UTC-06:00 Central Time (US & Canada)' },
    { text: 'UTC-06:00 Eastern Time - Indiana - Starke County', value: 'UTC-06:00 Eastern Time - Indiana - Starke County' },
    { text: 'UTC-06:00 Mexico City, Tegucigalpa', value: 'UTC-06:00 Mexico City, Tegucigalpa' },
    { text: 'UTC-06:00 Nicaragua', value: 'UTC-06:00 Nicaragua' },
    { text: 'UTC-06:00 Saskatchewan', value: 'UTC-06:00 Saskatchewan' },
    { text: 'UTC-07:00 Arizona', value: 'UTC-07:00 Arizona' },
    { text: 'UTC-07:00 Mountain Time (US & Canada)', value: 'UTC-07:00 Mountain Time (US & Canada)' },
    { text: 'UTC-08:00 Pacific Time (US & Canada); Los Angeles', value: 'UTC-08:00 Pacific Time (US & Canada); Los Angeles' },
    { text: 'UTC-08:00 Pacific Time (US & Canada); Tijuana', value: 'UTC-08:00 Pacific Time (US & Canada); Tijuana' },
    { text: 'UTC-09:00 Alaska', value: 'UTC-09:00 Alaska' },
    { text: 'UTC-10:00 Hawaii', value: 'UTC-10:00 Hawaii' },
    { text: 'UTC-11:00 Midway Island, Samoa', value: 'UTC-11:00 Midway Island, Samoa' },
  ]
import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const AboutUs1 = ({ language }) => {
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={{ ...styles.header, marginBottom: 0, width: '94%', marginHorizontal: 'auto' }}>
        <View style={{ ...styles.headerItem }}>
          <Text style={styles.headerItemText}>{language === 'English' ? `ABOUT US` : `CHI SIAMO`}</Text>
        </View>
        <View>
          <Image style={styles.headerRightImg} src="/assets/desktop6/group-1000001719.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={styles.aboutUs1Body}>
        <Text style={styles.aboutUs1ParaBody}>
          {language === 'English'
            ? `Are you ready to create prestigious and successful events? Welcome to Congressi Internazionali, the leading brand in the organization of events, congresses, fairs, corporate meetings, and conferences globally.`
            : `Siete pronti a realizzare eventi prestigiosi e di successo? Benvenuti in Congressi Internazionali, il brand leader nell’organizzazione di eventi, congressi, fiere, riunioni aziendali e convegni a livello globale.`}
        </Text>
        <Text style={styles.aboutUs1ParaBody}>
          {language === 'English'
            ? `What sets us apart is the ability to offer tailor-made solutions for our customers. We are passionate about knowledge, innovation, and collaboration, and this is why we are committed to providing cutting-edge solutions, quality customer service and availability throughout Italy and abroad. Whether you want to organize medium-sized or large-scale events, our mission is the success of our customers.`
            : `Ciò che ci contraddistingue è l’abilità di offrire soluzioni su misura per i nostri clienti. Siamo appassionati di conoscenza, innovazione e collaborazione, ed è per questo che ci impegniamo a fornire soluzioni all’avanguardia, un customer service di qualità e disponibilità in tutta Italia e all’estero. Che vogliate organizzare eventi di media o di grande portata, la nostra missione è il successo dei nostri clienti.`}
        </Text>
        <Text style={styles.aboutUs1ParaBody}>
          {language === 'English'
            ? `The Congressi Internazionali team covers the entire Italian peninsula, with technicians on site in Rome - for coverage of all of Central Italy, the South and the Islands - and Milan - for coverage of Northern Italy, carrying out the mission of creating the best multilingual events. With a deep-rooted presence from north to south, we serve the needs of those seeking to create successful multilingual events.`
            : ` Il team di Congressi Internazionali copre l'intera penisola italiana, con tecnici in loco su Roma – per la copertura di tutto il Centro Italia, del Sud e delle Isole – e Milano – per la copertura del Nord Italia, portando avanti la missione di creare i migliori eventi multilingue. Con una presenza radicata da nord a sud, serviamo le esigenze di coloro che cercano di creare eventi multilingue di successo.`}
        </Text>
        <Text style={{ ...styles.aboutUs1ParaBody }}>
          {language === 'English'
            ? `With our nationwide coverage, we pride ourselves on offering unprecedented access to world-class conventions, conferences and events. Our presence across the country reflects our commitment to promoting innovation and knowledge sharing in every corner of our beautiful country.`
            : `Grazie alla nostra copertura nazionale, siamo orgogliosi di offrire un accesso senza precedenti a congressi, conferenze e eventi di prim'ordine. La nostra presenza in tutto il territorio è un riflesso del nostro impegno a promuovere l'innovazione e la condivisione delle conoscenze in ogni angolo del nostro splendido paese.`}
        </Text>
      </View>
      <View style={styles.aboutUs1Footer}>
        <View style={styles.aboutUs1FooterImages1}>
        <Image style={styles.avatars} src="/assets/desktop7/ChiaraMoschitta.png" />
          <Image style={styles.avatars} src="/assets/desktop7/GiuliaCaravasso.png" />
          <Image style={styles.avatars} src="/assets/desktop7/GiuseppeGagliano.png" />
          <Image style={styles.avatars} src="/assets/desktop7/MariaSegreto.png" />
        </View>
        <View style={styles.aboutUs1FooterNames1}>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '42px' }}>
            Chiara Moschitta
            </Text>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '48px' }}>Giulia Caravasso</Text>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '43px' }}>Giuseppe Gagliano</Text>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '43px' }}>
            Maria Segreto
            </Text>
        </View>
        <View style={{...styles.aboutUs1FooterJobs1, marginBottom:"8px"}}>
          <Text style={{ ...styles.aboutUs1FooterJob, marginLeft: '47px', width: '65px' }}>Account Manager</Text>
          <Text style={{ ...styles.aboutUs1FooterJob, marginLeft: '72px', width: '65px' }}>Account Manager</Text>
          <Text style={{ ...styles.aboutUs1FooterJob, marginLeft: '75px', width: '65px' }}>Account Manager</Text>
          <Text style={{ ...styles.aboutUs1FooterJob, marginLeft: '65px'}}>
            {language === "English" ? 'Client Success Manager' : 'Client Success Manager'}
          </Text>
        </View>
        <View style={styles.aboutUs1FooterImages2}>
          <Image style={styles.avatars2} src="/assets/desktop7/MiriamFratacci.png" />
          <Image style={styles.avatars2} src="/assets/desktop7/GiuseppePinelli.png" />
          <Image style={styles.avatars2} src="/assets/desktop7/JimPuccio.png" />
          <Image style={styles.avatars2} src="/assets/desktop7/VeronicaGaudesi.png" />
        </View>
        <View style={styles.aboutUs1FooterNames2}>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '46px' }}>Miriam Fratacci</Text>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '55px' }}>Giuseppe Pinelli</Text>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '67px' }}>Jim Puccio</Text>
          <Text style={{ ...styles.aboutUs1FooterName, marginLeft: '67px' }}>
          Veronica Gaudesi
          </Text>
        </View>
        <View style={styles.aboutUs1FooterJobs2}>
          <Text style={{ ...styles.aboutUs1FooterJob, marginLeft: '47px', width: '65px' }}>Event Planner</Text>
          <Text style={{ ...styles.aboutUs1FooterJob, marginLeft: '67px', width: '75px' }}>Accountant</Text>
          <View style={{ display:"block", flexDirection:"column",  marginLeft:"10px" }}>
          <Text style={{...styles.aboutUs1FooterJob,marginLeft:"57",  width: '75px'}}>
            Interpretation
            </Text>
            <Text style={{...styles.aboutUs1FooterJob, marginLeft:"57",  width: '75px'}}>
            Project Manager
            </Text>
          </View>
          <View style={{ display:"block", flexDirection:"column" }}>
          <Text style={{...styles.aboutUs1FooterJob,marginLeft:"62",  width: '75px'}}>
            Interpretation
            </Text>
            <Text style={{...styles.aboutUs1FooterJob,marginLeft:"62",  width: '75px'}}>
            Project Manager
            </Text>
            </View>
        </View>
      </View>
    </Page>
  );
};

export default AboutUs1;

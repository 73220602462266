import React, { useEffect, useState } from 'react';
import Desktop16 from 'src/sections/interpretClientOutput/components/Desktop16';
import TechnicalEquipment from 'src/sections/interpretClientOutput/components/TechnicalEquipment';
import 'src/sections/interpretClientOutput/index.css';
import MuiAlert from '@mui/material/Alert';
import Interpreting from 'src/sections/interpretClientOutput/components/Interpreting';
import { addLanguageAndId } from 'src/redux/slices/proposal';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router';
import MiniInterpretation from 'src/sections/interpretClientOutput/components/MiniInterpretation';
import News2 from 'src/sections/interpretClientOutput/components/News2';
import News from 'src/sections/interpretClientOutput/components/News';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
export default function CongressiInternazionali() {
    const {lang} = useParams()

    const dispatch = useDispatch()
    
    useEffect(()=>{
        dispatch(addLanguageAndId({language: lang, id:""}))
    }, [1])


  return (
    <>
    
      <div id="translation-proposal" style={{ marginInline: 'auto', boxShadow: '0px 0px 5px 3px rgb(0 0 0 / 40%)' }}>
        
              <News2/>
              <Interpreting/>
              <TechnicalEquipment/> 
               <Desktop16/>
               <News/>
              <MiniInterpretation/>
           
      </div>
     
    </>
  );
}

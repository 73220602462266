import React, {useEffect, useState} from 'react'
import { useParams } from "react-router-dom";
import { useQuery } from "@apollo/client";
import InterpretClient from 'src/sections/BrochureOutputs/interpretClientOutput'
import { BROCHURE_DETAILS } from "src/graphQL/queries";
import Translation from 'src/sections/BrochureOutputs/translationClientOutput'
import { useDispatch } from 'react-redux';
import { addDetails, addLanguageAndId } from 'src/redux/slices/proposal';
import RafikyClient from 'src/sections/BrochureOutputs/RafikyOutput';

 

export default function BrochureClientOutput() {

  const {id, lang} = useParams()
  const { loading, error, data } = useQuery(BROCHURE_DETAILS, {
    variables: { brochureId: id },
  });
  const dispatch = useDispatch()
  //creating function to load ip address from the API
  useEffect(() => {
    //passing getData method to the lifecycle method
    async function fetchData(){
     if(data){
     //  .then((res)=>console.log(res?.data))
     dispatch(addDetails({details: data?.brochure, type:""}))
    // dispatch(addDetails(data?.brochure))
    dispatch(addLanguageAndId({language:lang, id:id}))    
     }
    }
   fetchData()
   }, [data])


  return (
    <>
     {data?.brochure?.quote === "Interpretation" && <InterpretClient />}
      {data?.brochure?.quote === "Translation" &&  <Translation />}
      {data?.brochure?.quote === "Rafiky" &&  <RafikyClient language={lang} />}

    </>
  )
}

import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const Interpreting = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
             <View style={{ ...styles.header, marginBottom: 0, width: "94%", marginHorizontal: "auto" }}>
        <View style={{ ...styles.headerItem }}>
        <Text style={styles.headerItemText}>{language === 'English' ? `COMPANY VALUES` : `VALORI DELL'AZIENDA`}</Text>
        </View>
         <View>
          <Image style={{...styles.headerRightImg }} src="/translationAssets/desktop6/lingoyou.png" />
        </View>
        <View style={styles.headerItem} />
      </View>
      <View style={styles.interpretingBody}>
        <Text style={styles.interpretingParaBody}>
       {language === "English" ? `At LingoYou, we strongly believe in our corporate culture. Our values make us unique: they guide our behaviour and our choices and rank our priorities. We are therefore committed to preserving and safeguarding by all means the principles and ideals on which we base our strategies, guaranteeing professionalism, quality of services and attention to details to all of our customers` : 
       `Noi di LingoYou crediamo fermamente nella nostra cultura aziendale. Sono i nostri valori a renderci unici: guidano i nostri comportamenti, orientano le nostre scelte e definiscono le nostre priorità. Ci impegniamo pertanto a preservare e a salvaguardare con ogni mezzo i principi e gli ideali su cui fondiamo le nostre strategie, garantendo ai nostri Clienti professionalità, qualità dei servizi e attenzione ai dettagli.`}
        </Text>
        
      </View>
      <View style={{...styles.interpretingIcons, marginLeft:"15px", marginRight:"15px"}}>
        <View style={{textAlign:"center", flexDirection:"column", padding:"5px",
borderRadius:"5px",
border:"1px solid #AA32E1"}}>
        <Image style={styles.interpretingIcon} src="/translationAssets/icons/usercheckmark.png"/>
        <Text style={{...styles.interpretingIconText}}>
        {language === "English" ? `Responsibility` : `Responsabilità`}
        </Text>
        </View>
        <View style={{textAlign:"center", flexDirection:"column", padding:"5px",
borderRadius:"5px",
border:"1px solid #AA32E1", marginLeft:"20px"}}>
        <Image style={styles.interpretingIcon} src="/translationAssets/icons/star.png"/>
        <Text style={{...styles.interpretingIconText}}>
        {language === "English" ? `Innovation` : `Innovazione`}
        </Text>
        </View>
        <View style={{textAlign:"center", flexDirection:"column", padding:"5px",
borderRadius:"5px",
border:"1px solid #AA32E1", marginLeft:"20px"}}>
        <Image style={styles.interpretingIcon} src="/translationAssets/icons/settings.png"/>
        <Text style={{...styles.interpretingIconText}}>
        {language === "English" ? `Accessibility` : `Accessibilità`}
        </Text>
        </View>
        <View style={{textAlign:"center", flexDirection:"column", padding:"5px",
borderRadius:"5px",
border:"1px solid #AA32E1", marginLeft:"20px"}}>
        <Image style={styles.interpretingIcon} src="/translationAssets/icons/shield.png"/>
        <Text style={{...styles.interpretingIconText}}>
        {language === "English" ? `Sustainability` : `Sostenibilità`}
        </Text>
        </View>
        <View style={{textAlign:"center", flexDirection:"column", padding:"5px",
borderRadius:"5px",
border:"1px solid #AA32E1", marginLeft:"20px"}}>
        <Image style={styles.interpretingIcon} src="/translationAssets/icons/windows.png"/>
        <Text style={{...styles.interpretingIconText}}>
        {language === "English" ? `Transparency ` : `Trasparenza`}
        </Text>
        </View>
      </View>
      <Text style={{...styles.dividerText, marginTop:"20px", backgroundColor:"#2DA0F0"}}>{language === 'English' ? 
      `WHY US?` :
       `PERCHE' NOI?`}
       </Text>
       <View style={{...styles.interpretingIcons, marginLeft:"0px"}}>
        <Image style={styles.interpretingIconBottom} src="/translationAssets/icons/building.png"/>
        <Image style={styles.interpretingIconBottom} src="/translationAssets/icons/Certificate.png"/>
        <Image style={styles.interpretingIconBottom} src="/translationAssets/icons/message.png"/>
        <Image style={styles.interpretingIconBottom} src="/translationAssets/icons/hands.png"/>
        <Image style={styles.interpretingIconBottom} src="/translationAssets/icons/cash.png"/>
      </View>
      <View style={styles.interpretingIconsText}>
        <Text style={{...styles.interpretingIconText, marginLeft:"26px",}}>
        {language === "English" ? `A 100% Italian company with global presence in over 90 countries` : `Azienda 100% Italiana con presenza globale in + di 90 Paesi`}
        </Text>
        <Text style={{...styles.interpretingIconText, marginLeft:"5px"}}>
        {language === "English" ? `ISO 9001 Quality Certification` : `Certificazione di Qualità ISO 9001`}
        </Text>
        <Text style={{...styles.interpretingIconText, marginLeft:"7px"}}>
        {language === "English" ? `200 Languages` : `200 Lingue`}
        </Text>
        <Text style={{...styles.interpretingIconText, marginLeft:"10px"}}>
        {language === "English" ? `The most advanced technology in the field of Remote Interpreting and Client Portal` :
         `La tecnologia più avanzata nel settore Interpretariato da remoto e Portale Clienti`}
        </Text>
        <Text style={{...styles.interpretingIconText, marginLeft:"10px"}}>
        {language === "English" ? `3 Prices – Translations for every budget` : `3 Prezzi – Traduzioni per ogni budget `}
        </Text>
      </View>
      <View style={{...styles.interpretingIcons, marginLeft:"0px"}}>
        <Image style={styles.interpretingIconBottom} src="/translationAssets/icons/clock.png"/>
        <Image style={styles.interpretingIconBottom} src="/translationAssets/icons/discount-shape.png"/>
        <Image style={styles.interpretingIconBottom} src="/translationAssets/icons/designtools.png"/>
        <Image style={styles.interpretingIconBottom} src="/translationAssets/icons/code-browser.png"/>
        <Image style={styles.interpretingIconBottom} src="/translationAssets/icons/flash.png"/>
      </View>
      <View style={styles.interpretingIconsText}>
        <Text style={{...styles.interpretingIconText, marginLeft:"26px",}}>
        {language === "English" ? `International Account Managers available 24/7` : `Account Managers Internazionali disponibili 24/7`}
        </Text>
        <Text style={{...styles.interpretingIconText, marginLeft:"5px"}}>
        {language === "English" ? `20% discount for new customers` : `20% di sconto per i nuovi clienti`}
        </Text>
        <Text style={{...styles.interpretingIconText, marginLeft:"7px"}}>
        {language === "English" ? `Internal Team of Graphic Designers` : `Team Interno di Graphic Designers`}
        </Text>
        <Text style={{...styles.interpretingIconText, marginLeft:"10px"}}>
        {language === "English" ? `Internal Team of Software Developers for CMS integrated translations` : `Team Interno di software Developers per traduzioni integrate di CMS`}
        </Text>
        <Text style={{...styles.interpretingIconText, marginLeft:"10px"}}>
        {language === "English" ? `Fast service - Machine and Neuronal Translation` : `Servizio rapido - Traduzione Automatica e Neuronale`}
        </Text>
      </View>
    </Page>
  );
};

export default Interpreting;
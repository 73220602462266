import React from 'react';
import styles from '../styles/Desktop16.module.css';
import { useSelector } from 'react-redux';

export default function Desktop16() {
  const { language} = useSelector(state => state.proposal)

  return (
    <div className={styles.desktop16}>
      <div className={styles.mainVector}>
        <div className={styles.vector1}></div>
        <div className={styles.vector2}></div>
        <div className={styles.vector3}></div>
        <div className={styles.vector4}></div>
        <div className={styles.vector5}></div>
        <div className={styles.vector6}></div>
      </div>
      <div className={styles.desktop5Child} style={{ background: `url("/assets/desktop16/greenway.webp") no-repeat right`, backgroundPositionX:'right' }}>
      </div>
      <div className={styles.text3543Parent}>
        <img alt="" src="/assets/desktop5/group-1000001730.svg" />{' '}
      </div>
      <div className={styles.laptop}>
        <h2> {language === 'English' ? 'For every event we plant a tree - Sustainable Events' : 'Per ogni evento piantiamo un albero - Eventi Sostenibili'}</h2>

        <p>
          {language === 'English'
            ? 'We are an interpreting company committed to promoting environmental sustainability through concrete actions and meaningful collaborations. We actively support charitable platforms such as Treevotion, which is dedicated to reforestation and the fight against climate change. This partnership allows us to contribute to tree planting in regions affected by deforestation, helping to reduce carbon emissions and preserve natural ecosystems.'
            : "Siamo un'azienda di interpretariato impegnata nella promozione della sostenibilità ambientale attraverso azioni concrete e collaborazioni significative. Supportiamo attivamente piattaforme benefiche come Treevotion, che si dedica alla riforestazione e alla lotta contro il cambiamento climatico. Questa partnership ci consente di contribuire alla piantumazione di alberi nelle regioni colpite dalla deforestazione, aiutando a ridurre le emissioni di carbonio e a preservare gli ecosistemi naturali."}{' '}
          <br />
          {language === 'English'
            ? 'In addition, we promote hybrid events that minimize the use of traditional interpreting equipment. This approach has several environmental benefits:'
            : `Inoltre, promuoviamo eventi ibridi che minimizzano l'uso di attrezzature di interpretariato tradizionali. Questo approccio comporta numerosi vantaggi ambientali:`}
          <br />
          <b>
          {language === 'English'
            ? '1. Lower resource consumption: '
            : '1. Minore consumo di risorse: '}
          </b>
          {language === 'English'
            ? 'By reducing the need for interpreting booths, audio equipment and specialized transport for interpreters, we decrease overall resource consumption and waste production related to traditional equipment.'
            : 'Riducendo la necessità di cabine di interpretariato, apparecchiature audio e trasporti specializzati per gli interpreti, diminuiamo il consumo complessivo di risorse e la produzione di rifiuti legati alle attrezzature tradizionali.'}
          <br />
          <b>
          {language === 'English'
            ? '2. Reduce emissions: '
            : '2. Riduzione delle emissioni: '}
         
          </b>
          {language === 'English'
            ? 'By eliminating or minimizing interpreter travel and equipment transportation, we help reduce greenhouse gas emissions related to travel and industrial activities.'
            : 'Eliminando o riducendo al minimo gli spostamenti degli interpreti e il trasporto delle attrezzature, contribuiamo a ridurre le emissioni di gas serra legate ai viaggi e alle attività industriali.'}
          <br />
          <b>
          {language === 'English'
            ? '3. Flexibility and scalability: '
            : "3. Flessibilità e scalabilità: "}{' '}
        
          </b>
          {language === 'English'
            ? 'Hybrid events allow you to quickly adapt to the needs of the event without having to invest in expensive equipment or make structural changes, ensuring a more sustainable approach.'
            : "Gli eventi ibridi consentono di adattarsi rapidamente alle esigenze dell'evento senza dover investire in attrezzature costose o apportare modifiche strutturali, garantendo un approccio più sostenibile."}{' '}
          <br />
          <b>
          {language === 'English'
            ? '4. Global accessibility: '
            : '4. Accessibilità globale: '}
         
          </b>
          {language === 'English'
            ? 'Interpreting apps allow interpreters to work from anywhere with internet connection, opening doors to a global community of professionals and reducing the need for international air travel.'
            : 'Le app di interpretariato consentono agli interpreti di lavorare da qualsiasi luogo con connessione internet, aprendo le porte a una comunità globale di professionisti e riducendo la necessità di viaggi aerei internazionali.'}
          <br />
          <b>
          {language === 'English'
            ? '5. Promoting sustainable technology: '
            : "5. Promozione della tecnologia sostenibile: "}
        
          </b>
          {language === 'English'
            ? 'By supporting the adoption of interpreting apps, we promote sustainable technological innovation in the interpreting industry and inspire others to follow our example.'
            : "Sostenendo l'adozione di app di interpretariato, promuoviamo l'innovazione tecnologica sostenibile nel settore dell'interpretariato e ispiriamo altri a seguire i nostro esempio."}
          <br />
          <br />
          {language === 'English'
            ? 'We are also committed to educating our clients and partners on the importance of environmental sustainability in the interpreting industry. Through workshops, webinars and information materials, we spread awareness about sustainable practices and encourage others to join us in adopting more eco-friendly approaches.'
            : "Ci impegniamo inoltre a educare i nostri clienti e partner sull'importanza della sostenibilità ambientale nell'industria dell'interpretariato. Attraverso workshop, webinar e materiali informativi, diffondiamo la consapevolezza sulle pratiche sostenibili e incoraggiamo altri a unirsi a noi nell'adozione di approcci più eco-compatibili."}
        </p>
      </div>
      
      {language === 'English' ? (
        <div className={styles.text}>
          official partners <img loading="lazy" src="/assets/desktop16/logo.png" />
        </div>
      ) : (
        <div className={styles.text}>
          partner ufficiali <img loading="lazy" src="/assets/desktop16/logo.png" />
        </div>
      )}
    </div>
  );
}

import React from 'react'
import { Box, TableCell, TableRow, Typography } from '@mui/material';
import styles1 from '../../styles/TablesItems.module.css';
import { fCurrency } from 'src/utils/formatNumber';
import { useSelector } from 'react-redux';



function Table2ServiceItems({row, index, itemTranslation}) {
    const {language} = useSelector((state)=>state.proposal);

  return (
    <TableRow key={index}>
    <TableCell
      sx={{
        '.css-4dwhzq-MuiTableCell-root': {
          marginBottom: 'none',
        },
        borderBottom: 'none',
      }}
      align="left"
    >
      {language === 'English' ? (
        <Box>
          <Typography
            sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
          >
            {row?.service?.Item['english']}
          </Typography>
          <Typography
            className={styles1.description}
            variant="body2"
            sx={{ color: 'text.secondary', fontSize: '15px' }}
          >
            {itemTranslation ? itemTranslation : ''}
          </Typography>
        </Box>
      ) : (
        <Box>
          <Typography
            sx={{ fontFamily: "'Poppins', sans-serif", fontSize: 'large', fontWeight: '700' }}
          >
            {row?.service?.Item['italian']}
          </Typography>
          <Typography
            className={styles1.description}
            variant="body2"
            sx={{ color: 'text.secondary', fontSize: '15px' }}
          >
            {row?.service?.Description['italian']}
          </Typography>
        </Box>
      )}
    </TableCell>
    <TableCell
      sx={{
        '.css-4dwhzq-MuiTableCell-root': {
          marginBottom: 'none',
        },
        borderBottom: 'none',
        fontFamily: "'Poppins', sans-serif",
        fontSize: 'large',
      }}
      align="center"
    >
       &nbsp;{fCurrency(row.price)}
    </TableCell>
    <TableCell
      sx={{
        '.css-4dwhzq-MuiTableCell-root': {
          marginBottom: 'none',
        },
        borderBottom: 'none',
        fontFamily: "'Poppins', sans-serif",
        fontSize: 'large',
      }}
      align="center"
    >
      {row.quantity}
    </TableCell>
    <TableCell
      sx={{
        '.css-4dwhzq-MuiTableCell-root': {
          marginBottom: 'none',
        },
        borderBottom: 'none',
        fontFamily: "'Poppins', sans-serif",
        fontSize: 'large',
      }}
      align="center"
    >
      {row.discount ? row.discount + '%' : ''}
    </TableCell>
    {row?.discount ? (
                        <TableCell sx={{
                          '.css-4dwhzq-MuiTableCell-root': {
                            marginBottom: 'none',
                          },
                          borderBottom: 'none',
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: 'large',
                          width: '100%',
                        }}
                        align="center">
                          {fCurrency(
                            Number(row?.price) * Number(row?.quantity) -
                              (Number(row?.price) * Number(row?.quantity) * Number(row.discount)) / 100
                          )}{' '}
                          {row.discount > 0 && row.discount && (
                            <>
                              | <del>{fCurrency(Number(row?.price) * Number(row?.quantity))}</del>
                            </>
                          )}
                        </TableCell>
                      ) : (
                        <TableCell 
                        sx={{
                          '.css-4dwhzq-MuiTableCell-root': {
                            marginBottom: 'none',
                          },
                          borderBottom: 'none',
                          fontFamily: "'Poppins', sans-serif",
                          fontSize: 'large',
                          width: '100%',
                        }}
                        align="center"
                        >
                          {fCurrency(Number(row.price) * Number(row.quantity))}</TableCell>
                      )}
    {/* <TableCell
      sx={{
        '.css-4dwhzq-MuiTableCell-root': {
          marginBottom: 'none',
        },
        borderBottom: 'none',
        fontFamily: "'Poppins', sans-serif",
        fontSize: 'large',
        width: '100%',
      }}
      align="center"
    >
       &nbsp;{fCurrency((Number(row.price) * Number(row.quantity)) )}
    </TableCell> */}
  </TableRow>
  )
}

export default Table2ServiceItems
import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const Testimonials = ({ language }) => {

  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
     <View style={styles.testimonial}>
     <View style={{ display: "flex",
    flexDirection: "row",
    alignItems: "center",
     width: "94%", marginBottom:"10px" }}>
          <Text style={{...styles.headerItemText, color:"white", marginLeft:"10px"}}>
            {language === 'English' ? `TESTIMONIALS` : `TESTIMONIALS`}</Text>
          <Image style={{...styles.headerRightImg, marginLeft:"30%"}} src="/translationAssets/desktop6/lingoyou.png" />
      </View>
        <View style={styles.testimonialCards}> 
        <View style={styles.testimonialCard}> 
        <View style={{display:"inline-flex", flexDirection:"row"}}>
        <Image style={{marginLeft:"3px", width:"30px", height:"30px", borderRadius:"50%"}} 
        src="/translationAssets/desktop15/testimonialface6.png"/>
        <View>
        <Text style={{marginLeft:"2px", fontSize:"9px", marginTop:"7px"}}>
        Elisa S.
        </Text>
        <Image style={{marginLeft:"4px", width:"60px", height:"10px", borderRadius:"50%"}} src="/assets/desktop15/stars.png"/>

        </View>
        </View>
        <Text style={{fontSize:"9px", marginTop:"15px"}}>
        {language === 'English' ? 'We are pleased to inform you that the translation service requested by us was carried out impeccably by the translator, exceeding the best expectations.':
`Siamo lieti di comunicarvi che il servizio di traduzione da noi richiesto è stato svolto dalla traduttrice in maniera impeccabile, superando le migliori aspettative.`
}        </Text>
        </View>
        <View style={styles.testimonialCard}> 
        <View style={{display:"inline-flex", flexDirection:"row"}}>
        <Image style={{marginLeft:"3px", width:"30px", height:"30px", borderRadius:"50%"}}
         src="/translationAssets/desktop15/testimonialface5.png"/>
        <View>
        <Text style={{marginLeft:"2px", fontSize:"9px", marginTop:"7px"}}>
        Giulia F.
        </Text>
        <Image style={{marginLeft:"4px", width:"60px", height:"10px", borderRadius:"50%"}} src="/assets/desktop15/stars.png"/>

        </View>
        </View>
        <Text style={{fontSize:"9px", marginTop:"15px"}}>
        {language === 'English' ?  'I want to thank all the team for the service that you offered us on very short notice. Our side everything was perfect, the interpreter provided is a true professional. Thank you!':
`Ci tengo a ringraziare tutto il team per il servizio che ci avete offerto con pochissimo preavviso. Lato nostro è stato tutto perfetto, l'interprete fornito è un vero professionista. Grazie!`}
                </Text>
        </View>
        <View style={styles.testimonialCard}> 
        <View style={{display:"inline-flex", flexDirection:"row"}}>
        <Image style={{marginLeft:"3px", width:"30px", height:"30px", borderRadius:"50%"}}
         src="/translationAssets/desktop15/testimonialface2.png"/>
        <View>
        <Text style={{marginLeft:"2px", fontSize:"9px", marginTop:"7px"}}>
        Giuseppe C.
        </Text>
        <Image style={{marginLeft:"4px", width:"60px", height:"10px", borderRadius:"50%"}} src="/assets/desktop15/stars.png"/>

        </View>
        </View>
        <Text style={{fontSize:"9px", marginTop:"15px"}}>
        {language === "English" ? `I did not know this agency, but I decided to trust the great reviews. I confirm everything what you read! They are professional, fast and very kind!
`: `Non conoscevo questa agenzia, ma ho deciso di fidarmi delle ottime recensioni. Confermo tutto quello che leggete! Sono professionali, veloci e gentilissimi!`}
                 </Text>
        </View>
        </View>
        <View style={styles.testimonialCards}> 
        <View style={styles.testimonialCard}> 
        <View style={{display:"inline-flex", flexDirection:"row"}}>
        <Image style={{marginLeft:"3px", width:"30px", height:"30px", borderRadius:"50%"}}
         src="/translationAssets/desktop15/testimonialface1.png"/>
        <View>
        <Text style={{marginLeft:"2px", fontSize:"9px", marginTop:"7px"}}>
        Simone P.
        </Text>
        <Image style={{marginLeft:"4px", width:"60px", height:"10px", borderRadius:"50%"}} src="/assets/desktop15/stars.png"/>

        </View>
        </View>
        <Text style={{fontSize:"9px", marginTop:"15px"}}>
        {language === "English" ? `Excellent agency. Reliable, precise and very courteous and kind! Recommended!
`: `Ottima agenzia. Affidabili, precisi e molto cortesi e gentili! Raccomandata!`}
                  </Text>
        </View>
        <View style={styles.testimonialCard}> 
        <View style={{display:"inline-flex", flexDirection:"row"}}>
        <Image style={{marginLeft:"3px", width:"30px", height:"30px", borderRadius:"50%"}}
         src="/translationAssets/desktop15/testimonialface4.png"/>
        <View>
        <Text style={{marginLeft:"2px", fontSize:"9px", marginTop:"7px"}}>
        Valentina M.
        </Text>
        <Image style={{marginLeft:"4px", width:"60px", height:"10px", borderRadius:"50%"}} src="/assets/desktop15/stars.png"/>

        </View>
        </View>
        <Text style={{fontSize:"9px", marginTop:"15px"}}>
        {language === "English" ?  `I found a kind and prepared staff! We will definitely work together in the future too!
`:`Ho trovato uno staff gentile e preparato! Sicuramente collaboreremo anche in futuro!`}
                </Text>
        </View>
        <View style={styles.testimonialCard}> 
        <View style={{display:"inline-flex", flexDirection:"row"}}>
        <Image style={{marginLeft:"3px", width:"30px", height:"30px", borderRadius:"50%"}} 
        src="/translationAssets/desktop15/testimonialface3.png"/>
        <View>
        <Text style={{marginLeft:"2px", fontSize:"9px", marginTop:"7px"}}>
        Francesca M.
        </Text>
        <Image style={{marginLeft:"4px", width:"60px", height:"10px", borderRadius:"50%"}} src="/assets/desktop15/stars.png"/>

        </View>
        </View>
        <Text style={{fontSize:"9px", marginTop:"15px"}}>
        {language === "English" ?  `I found a professional team ready to satisfy every request. Absolutely deserved five stars!`:
            `Ho trovato un team professionale e pronto a soddisfare ogni richiesta. Cinque stelle assolutamente meritate!
            `
            }        </Text>
        </View>
        </View>
        <Text style={{...styles.dividerText, marginTop:"15px"}}>{language === 'English' ? 
      `Testimonials` :
       `Testimonials`}
       </Text>
       <View style={styles.testimonialImage}>
          <Image style={{marginLeft:"10px"}} src="/assets/desktop15/facebook.png"/>
          <Image style={{marginLeft:"10px"}} src="/assets/desktop15/google.png"/>
          <Image style={{marginLeft:"10px"}} src="/assets/desktop15/reviews.png"/>
       </View>
     <Image style={{width:"100%", objectFit:"cover", marginTop:"-550px", zIndex:"-99", height:"650px"}}  src="/translationAssets/desktop5/Dark Gradient 01.png"/>      
      
       </View>
    </Page>
  );
};

export default Testimonials;
import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';
import { SectorsContent } from '../../sectorsText';


const Home = ({ data, language }) => {
  const {companyNameAndId, bookedBy, accountManager, addOns, orderNumber, industry} = data
  console.log(data) 
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
    
     <View style={styles.greenImage}>
     <Image 
     style={{...styles.greenImages, width:"40px", height:"40px", marginLeft:"11px", marginBottom:"-100px", marginTop:"30" }}  
     src="/translationAssets/desktop5/lingoyoulogo.png"/>
      <View style={{marginLeft:"11px", marginTop:"100px"}}>
          <Text style={{ marginTop:"90px", fontSize:"15px",fontStyle:"normal", fontWeight:"bold", color:"white", width:"50%", textTransform:"uppercase"}}>
          {language === "English" ? `PROPOSAL DEDICATED TO`:`PROPOSAL DEDICATO A`}            
          </Text>
          <Text style={{ fontSize:"15px",fontStyle:"normal", fontWeight:"bold", color:"white", width:"50%", textTransform:"uppercase"}}>
          {companyNameAndId ? companyNameAndId.name : '___'}
            
          </Text>
     </View>
      <Image 
      style={{width:"100%", objectFit:"none", marginTop:"-420px", zIndex:"-99", height:"830px"}}  
      src={addOns?.sector ? `${SectorsContent[industry?.name]?.coverPdf}` : 
      '/clients/SupportServices/coversupportservices.png'}/>       
      <View style={styles.greenTextSection}>
       </View>
       <View style={{...styles.discountText}}>
       <Text style={{...styles.discountList, marginBottom:"5px", marginLeft:"11px", fontSize:"14px",fontStyle:"normal", fontWeight:"bold",}}>
          {language === "English" ? `Quote: LY/${orderNumber}`:`Preventivo: LY/${orderNumber}`}
        </Text>
        <Text style={{...styles.discountList, marginBottom:"5px", marginLeft:"11px"}}>
          {language === "English" ? `Kindly Addressed to: ${bookedBy ? `${bookedBy?.firstName} ${bookedBy?.lastName}` : '___'}.`:`All'attenzione di ${bookedBy ? `${bookedBy?.firstName} ${bookedBy?.lastName}` : '___'}`}
        </Text>
        <Text style={{...styles.discountList, marginBottom:"25px", fontSize:'8px', marginLeft:"11px"}}>
          {language === "English" ? `Document Created by ${accountManager ? accountManager.name : "__"}.`:`Documento Realizzato da ${accountManager ? accountManager.name : "__"}.`}
        </Text>
       </View>
       </View>
    </Page>
  );
};

export default Home;


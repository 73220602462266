import React from 'react';
import styles from '../styles/T&CsWeb.module.css';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { FaRegHandPointLeft } from 'react-icons/fa';
import { BsVectorPen } from 'react-icons/bs';
import { addName } from 'src/redux/slices/proposal';
import styles1 from '../styles/AboutUs2.module.css';
import { TermsAndConditions } from '../Terms&ConditionsText';

function formatDate(param) {
  // Extracting date components
  const date = new Date(param);
  var day = date.getDate();
  var month = date.getMonth() + 1; // Months are zero indexed
  var year = date.getFullYear() % 100; // Getting last two digits of the year
  var hours = date.getHours();
  var minutes = date.getMinutes();

  // Adding leading zeros if necessary
  day = (day < 10 ? '0' : '') + day;
  month = (month < 10 ? '0' : '') + month;
  year = (year < 10 ? '0' : '') + year;
  hours = (hours < 10 ? '0' : '') + hours;
  minutes = (minutes < 10 ? '0' : '') + minutes;

  // Concatenating date components
  var formattedDate = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;

  return formattedDate;
}

export default function TermsAndCondition({
  handleAcceptance
}) {
  const {language, name, details:data, isBtn } = useSelector((state)=>state.proposal);
 const dispatch = useDispatch()
 const {brand} = data;
 const {companyNameAndId} = data;
 const [open, setOpen] = React.useState(false);
  const [error, setError] = React.useState('');
  const [openTerms, setOpenTerms] = React.useState(false);
  const handleClickOpenTerms = () => {
    setError('');
    setOpenTerms(true);
  };
  const handleClickCloseTerms = () => {
    setOpenTerms(false);
    handleAcceptance();
  };
  const handleCloseTerms = () => {
    setOpenTerms(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClickClose = () => {
    setOpen(false);
    handleAcceptance();
  };
  const handleClose = () => {
    setOpen(false);
  };
  let previousDateKey = [] // Define previousDateKey variable

  return (
    <div className={styles.desktop7}>
     <div className={styles1.desktop8Item} />
        {/* <div className={styles.div}>17</div> */}
        <div className={styles1.aboutUs} style={{textTransform:"uppercase", fontWeight:"bold"}}>{language === "English" ? 'Terms and conditions' : "Termini e Condizioni"}</div>
        <div className={styles1.text3543Parent} style={{top:'0.37%'}}>
        {brand && brand?.rafiky ? <img className={styles.rafikyImg} 
        alt=""
        src="/rafiky/logo.webp"
      /> :
      <img style={{ top: '0.17%' }} alt="" src="/assets/desktop6/group-1000001719.svg" />
        }         </div>
      <Grid container spacing={3} sx={{ marginTop: '70px' }}  className={styles.sections}>
        <Grid item xs={4}>
        <p className={styles.titles}><b>
          1. {language === "English" ? TermsAndConditions.en["title1"] : TermsAndConditions.it["title1"]}
          </b></p>
          <p> 
          {language === "English" ? TermsAndConditions.en["title1Para1"] : TermsAndConditions.it["title1Para1"]}
          </p>
          
          <p className={styles.titles}><b>
          2. {language === "English" ? TermsAndConditions.en["title2"] : TermsAndConditions.it["title2"]}
          </b></p>
          <p> 
          {language === "English" ? TermsAndConditions.en["title2Para1"] : TermsAndConditions.it["title2Para1"]}
          </p>
          <p className={styles.titles}><b>
          3. {language === "English" ? TermsAndConditions.en["title3"] : TermsAndConditions.it["title3"]}
          </b></p>
          
          <p>
          
          {language === "English" ? TermsAndConditions.en["title3Para1"] : TermsAndConditions.it["title3Para1"]}

          </p>
          <p>
          {data?.interpretationItem2?.length > 0 || data?.interpretationItem?.length > 0 ? (
 [data?.interpretationItem, ...data?.interpretationItem2]?.map((items, option)=>{
  previousDateKey = []
  return(
 <div>
  {data?.interpretationItem2?.length > 0 && <b style={{fontWeight:"bold", marginTop:"5px"}}>
  {language === "English" ? "Option " :  "Opzione "} {option + 1}:<br/>
  </b>}
 {
  items?.length > 0 ? items?.map((item, index) => {
    const startDate = new Date(item?.startDateTime);
    const endDate = new Date(item?.endDateTime);
    const dateKey = `${startDate.getDate()}${startDate.getMonth()}${startDate.getFullYear()}`;

    if (index === 0 || !previousDateKey.includes(dateKey)) {
      const startDateDay = String(startDate.getDate()).padStart(2, '0');
      const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const startDateYear = String(startDate.getFullYear()).slice(-2);
      let startDateHrs = String(startDate.getHours()).padStart(2, '0');
      let startDatemins = String(startDate.getMinutes()).padStart(2, '0');
      const startingDate =
        startDateDay +
        '/' +
        startDateMonth +
        '/' +
        startDateYear +
        ' ' +
        startDateHrs +
        ':' +
        startDatemins;
      const endDateDay = String(endDate.getDate()).padStart(2, '0');
      const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const endDateYear = String(endDate.getFullYear()).slice(-2);
      let endDateHrs = String(endDate.getHours()).padStart(2, '0');
      let endDateMins = String(endDate.getMinutes()).padStart(2, '0');
      const endingDate =
        endDateDay +
        '/' +
        endDateMonth +
        '/' +
        endDateYear +
        ' ' +
        endDateHrs +
        ':' +
        endDateMins; 

      previousDateKey.push(dateKey);
      return (
        <div key={item?.startDateTime}>
          {language === "English" ? " From" :  "Da"}: <b style={{fontSize:"15px"}}>
          {startingDate}
            </b> &nbsp; {language === "English" ? " To" :  "A"}: <b style={{fontSize:"15px"}}>
            {endingDate}
              </b>
          <br/>
        </div>
      );
    }
  }) : ( 
    [data?.additionalService, ...data?.additionalService2]?.map((services, index)=>(
      
     option === index && services?.map((item, index) => {
    const startDate = new Date(item?.startDateTime);
    const endDate = new Date(item?.endDateTime);
    const dateKey = `${startDate.getDate()}${startDate.getMonth()}${startDate.getFullYear()}`;

    if (index === 0 || !previousDateKey.includes(dateKey)) {
      const startDateDay = String(startDate.getDate()).padStart(2, '0');
      const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const startDateYear = String(startDate.getFullYear()).slice(-2);
      let startDateHrs = String(startDate.getHours()).padStart(2, '0');
      let startDatemins = String(startDate.getMinutes()).padStart(2, '0');
      const startingDate =
        startDateDay +
        '/' +
        startDateMonth +
        '/' +
        startDateYear +
        ' ' +
        startDateHrs +
        ':' +
        startDatemins;
      const endDateDay = String(endDate.getDate()).padStart(2, '0');
      const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const endDateYear = String(endDate.getFullYear()).slice(-2);
      let endDateHrs = String(endDate.getHours()).padStart(2, '0');
      let endDateMins = String(endDate.getMinutes()).padStart(2, '0');
      const endingDate =
        endDateDay +
        '/' +
        endDateMonth +
        '/' +
        endDateYear +
        ' ' +
        endDateHrs +
        ':' +
        endDateMins;

        previousDateKey.push(dateKey);

      return (
        <div key={item?.startDateTime}>
          {language === "English" ? " From" :  "Da"}: {startingDate} &nbsp; {language === "English" ? " To" :  "A"}: {endingDate}
        <br/>
      </div>
      );
    }
  })))
  )
 }
 </div>
 )}) 
) : (
  data?.additionalService.map((item, index) => {
    const startDate = new Date(item?.startDateTime);
    const endDate = new Date(item?.endDateTime);
    const dateKey = `${startDate.getDate()}${startDate.getMonth()}${startDate.getFullYear()}`;

    if (index === 0 || !previousDateKey.includes(dateKey)) {
      const startDateDay = String(startDate.getDate()).padStart(2, '0');
      const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const startDateYear = String(startDate.getFullYear()).slice(-2);
      let startDateHrs = String(startDate.getHours()).padStart(2, '0');
      let startDatemins = String(startDate.getMinutes()).padStart(2, '0');
      const startingDate =
        startDateDay +
        '/' +
        startDateMonth +
        '/' +
        startDateYear +
        ' , ' +
        startDateHrs +
        ':' +
        startDatemins;
      const endDateDay = String(endDate.getDate()).padStart(2, '0');
      const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const endDateYear = String(endDate.getFullYear()).slice(-2);
      let endDateHrs = String(endDate.getHours()).padStart(2, '0');
      let endDateMins = String(endDate.getMinutes()).padStart(2, '0');
      const endingDate =
        endDateDay +
        '/' +
        endDateMonth +
        '/' +
        endDateYear +
        ' , ' +
        endDateHrs +
        ':' +
        endDateMins;

        previousDateKey.push(dateKey);

      return (
        <div key={item?.startDateTime}>
          {language === "English" ? " From" :  "Da"}: {startingDate} &nbsp; {language === "English" ? " To" :  "A"}: {endingDate}
          <br />
        </div>
      );
    }
  })
)}
          </p>
          <p className={styles.titles}><b>
          4. {language === "English" ? TermsAndConditions.en["title4"] : TermsAndConditions.it["title4"]}
          </b></p>
          <p>
         
          {language === "English" ? <span>
            You must send confirmation of the quote by <b>{formatDate(data?.quoteDeadline)}</b>. After this date the quote may be subject to changes.
          </span> : 
          <span>
È necessario inviare conferma del preventivo entro il <b>{formatDate(data?.quoteDeadline)}</b>. Oltre tale data il preventivo potrebbe subire delle variazioni.
          </span>
}
          </p>
          <p className={styles.titles}><b>
          5. {language === "English" ? TermsAndConditions.en["title5"] : TermsAndConditions.it["title5"]}
          </b></p>
          <p>
          {language === "English" ? TermsAndConditions.en["title5Para1"] : TermsAndConditions.it["title5Para1"]}

          </p>
          <p className={styles.titles}><b>
          6. {language === "English" ? TermsAndConditions.en["title6"] : TermsAndConditions.it["title6"]}
          </b></p>
          <p>
            <b>
            {language === "English" ? TermsAndConditions.en["title6Heading1"] : 
          TermsAndConditions.it["title6Heading1"]}
            </b>
          
          </p>
         
            <ol style={{marginLeft:"20px"}}>
                <li>
                {language === "English" ? TermsAndConditions.en["title6Para1"] : 
          TermsAndConditions.it["title6Para1"]}
                </li>
                <li>
                {language === "English" ? TermsAndConditions.en["title6Para2"] : 
          TermsAndConditions.it["title6Para2"]}
                </li>
                <li>
                {language === "English" ? TermsAndConditions.en["title6Para3"] : 
          TermsAndConditions.it["title6Para3"]}
                </li>
                <li>
                {language === "English" ? TermsAndConditions.en["title6Para4"] : 
          TermsAndConditions.it["title6Para4"]}
                </li>
            </ol>
          <p><b>
            {language === "English" ? TermsAndConditions.en["title6Heading2"] : 
          TermsAndConditions.it["title6Heading2"]}
            </b>
          
          </p>
          <p>
        
          {language === "English" ? TermsAndConditions.en["title6Para5"] : 
          TermsAndConditions.it["title6Para5"]}  </p>
        
        
         <p style={{lineHeight:`${language === "English" ? "23px":"normal"}`}}>
        
         {language === "English" ? TermsAndConditions.en["title6Para6"] : 
          TermsAndConditions.it["title6Para6"]}  
          </p>
         
        </Grid>
        <Grid item xs={4} sx={{marginTop:"5px"}}>
        <p className={styles.titles}><b>
         7. {language === "English" ? TermsAndConditions.en["title7"] : 
          TermsAndConditions.it["title7"]}
          </b></p>
         <p>
         {language === "English" ? TermsAndConditions.en["title7Para1"] : 
          TermsAndConditions.it["title7Para1"]}
         </p>
         <p className={styles.titles}><b>
         8. {language === "English" ? TermsAndConditions.en["title8"] : 
          TermsAndConditions.it["title8"]}
           </b></p>
          <p>
          {language === "English" ? TermsAndConditions.en["title8Para1"] : 
          TermsAndConditions.it["title8Para1"]}
            </p>
          <p className={styles.titles}><b>
          9. {language === "English" ? TermsAndConditions.en["title9"] : 
          TermsAndConditions.it["title9"]}
          </b></p>
          <p>
          {language === "English" ? TermsAndConditions.en["title9Para1"] : 
          TermsAndConditions.it["title9Para1"]}
          </p>
          
        <p className={styles.titles}><b>
          10. {language === "English" ? TermsAndConditions.en["title10"] : 
          TermsAndConditions.it["title10"]}
          </b></p>
          <p style={{lineHeight:`${language === "English" ? "23px":"normal"}`}}>
          {language === "English" ? TermsAndConditions.en["title10Para1"] : 
          TermsAndConditions.it["title10Para1"]}
          </p>
         
          <p className={styles.titles}><b>
          11. {language === "English" ? TermsAndConditions.en["title11"] : 
          TermsAndConditions.it["title11"]} 
          </b></p>
          <p style={{lineHeight:`${language === "English" ? "23px":"normal"}`}}>
          {language === "English" ? TermsAndConditions.en["title11Para1"] : 
          TermsAndConditions.it["title11Para1"]}
          </p>
         
        </Grid>
        <Grid item xs={4}>
     
        <p className={styles.titles}><b>
          12. {language === "English" ? TermsAndConditions.en["title12"] : 
          TermsAndConditions.it["title12"]}
          </b></p>
          <p>
          {language === "English" ? TermsAndConditions.en["title12Para1"] : 
          TermsAndConditions.it["title12Para1"]}
        </p>
        <p className={styles.titles}><b>
          13. {language === "English" ? TermsAndConditions.en["title13"] : 
          TermsAndConditions.it["title13"]}
          </b></p>
          <p>
          {language === "English" ? TermsAndConditions.en["title13Para1"] : 
          TermsAndConditions.it["title13Para1"]}
          </p>       
       <p className={styles.titles}><b>
       14. {language === "English" ? TermsAndConditions.en["title14"] : 
          TermsAndConditions.it["title14"]}
          </b></p>
          <p>
          {language === "English" ? TermsAndConditions.en["title14Para1"] : 
          TermsAndConditions.it["title14Para1"]}
          </p>
          
          <p className={styles.titles}><b>
          15. {language === "English" ? TermsAndConditions.en["title15"] : 
          TermsAndConditions.it["title15"]}
          </b></p>
          <p>
          {language === "English" ? TermsAndConditions.en["title15Para1"] : 
          TermsAndConditions.it["title15Para1"]}
        </p>
        
          <div style={{marginTop:`${language === "English" ? "20px": "15px"}`}} className={styles.sign}>
          <div>
                <p>
                  <b>
                  {
                language === 'English' ? `Signature for acceptance of quote CI/${data?.orderNumber}:` : 
                `Firma per accettazione Preventivo CI/${data?.orderNumber}:`
              }                
              </b>
                </p>
               {(data?.status !== 'Quote-Won' && data?.status !== "Sent To PM") && isBtn ? (
                  <span onClick={handleClickOpen}>
                    <Button sx={{ width: "150px", fontSize: "20px", color:"white", backgroundColor:"#2da0f0" }} onClick={handleClickOpen}>FIRMA</Button>
                  </span>
                ) : <span style={{ fontFamily: "Allura", fontSize: "26px" }}>{name || data?.quoteSignature}</span>
                }
               </div>
          </div>
          <div style={{marginTop:"10px"}} className={styles.sign}>
          <div>
                <p>
                  <b>
                  {
                language === 'English' ? 'Signature for acceptance of the Terms & Conditions:' : 'Firma per accettazione Termini & Condizioni:'
              }                  
              </b>
                </p>
               {(data?.status !== 'Quote-Won' && data?.status !== "Sent To PM") && isBtn ? (
                  <span onClick={handleClickOpen}>
                    <Button sx={{ width: "150px", fontSize: "20px", color:"white", backgroundColor:"#2da0f0" }} onClick={handleClickOpen}>FIRMA</Button>
                  </span>
                ) : <span style={{ fontFamily: "Allura", fontSize: "26px" }}>{name || data?.quoteSignature}</span>
                }
               </div>
          </div>
          <div style={{marginTop:"10px"}} className={styles.sign}>
          <div>
                <p>
                  <b>
                  {
                language === 'English' ? 'Signature for acceptance Terms and conditions of the site' : 'Firma per accettazione Termini & condizioni del sito '
              } <a href='http://www.lingoyou.com/terminicondizioni'>(http://www.lingoyou.com/terminicondizioni)</a>                 
              </b>
                </p>
               {(data?.status !== 'Quote-Won' && data?.status !== "Sent To PM") && isBtn ? (
                  <span onClick={handleClickOpen}>
                    <Button sx={{ width: "150px", fontSize: "20px", color:"white", backgroundColor:"#2da0f0" }} onClick={handleClickOpen}>FIRMA</Button>
                  </span>
                ) : <span style={{ fontFamily: "Allura", fontSize: "26px" }}>{name || data?.quoteSignature}</span>
                }
               </div>
          </div>
        </Grid>
       
       
      </Grid>
      <Dialog
          open={open}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          sx={{
            '& .css-y4brsv-MuiPaper-root-MuiDialog-paper.MuiPaper-rounded': { borderRadius: '5px' },
            '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '500px', textAlign: 'center', borderRadius: '5px' },
            borderRadius: '5px',
          }}
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
              color: 'rgba(0,0,0,.65)',
              fontWeight: '600',
              fontSize: '25px',
            }}
          >

            {
              language === 'English' ? 'Accept the quote' : 'Accetta il preventivo'
            }
          </DialogTitle>
          <DialogContent
            id="dialog_content"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
            <span style={{ display: "inline-flex", marginTop: "30px" }}>
              <BsVectorPen style={{ fontSize: "25px", marginTop: "5px" }} />  <input placeholder={`${language === "English" ? "Sign here":"Firma qui"}`} value={name} onChange={(e) => dispatch(addName(e.target.value))} />{' '}
              <FaRegHandPointLeft style={{ fontSize: "25px", marginTop: "5px" }} />

            </span>
            {(error && !name && isBtn && data?.status !== 'Quote-Won') && <span className={styles.error}>{error} <br /></span>}
            <DialogContentText id="alert-dialog-description">{name}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button

              sx={{
                background: '#7cd1f9',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
                fontWeight: '600',
                fontSize: '14px',
                padding: '10px 10px',
                cursor: 'pointer',
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
                  color: 'white',
                  background: 'grey',
                },
              }}
              disabled={!name && true}
              onClick={handleClickClose}
              autoFocus
            >
              Conferma preventivo
              {
                language === 'English' ? 'Quote confirmation                ' : 'Accetta il preventivo'
              }
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog
          open={openTerms}
          onClose={handleCloseTerms}
          aria-labelledby="alert-dialog-title"
          sx={{ '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '500px', textAlign: 'center' } }}
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle
            id="alert-dialog-title"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
            {
              language === 'English' ? 'Accept the Terms & Conditions' : 'Accetta i Termini e Condizioni'
            }
          </DialogTitle>
          <DialogContent
            id="dialog_content"
            sx={{
              '& .css-y4brsv-MuiPaper-root-MuiDialog-paper': { width: '-webkit-fill-available', textAlign: 'center' },
            }}
          >
            <span style={{ display: "inline-flex", marginTop: "30px" }}>
              <BsVectorPen style={{ fontSize: "25px", marginTop: "5px" }} />  <input placeholder={`${language === "English" ? "Sign here":"Firma qui"}`} value={name} onChange={(e) => dispatch(addName(e.target.value))} />{' '}
              <FaRegHandPointLeft style={{ fontSize: "25px", marginTop: "5px" }} />
            </span>
            <DialogContentText id="alert-dialog-description">{name}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              sx={{
                background: '#7cd1f9',
                color: 'white',
                border: 'none',
                boxShadow: 'none',
                fontWeight: '600',
                fontSize: '14px',
                padding: '10px 10px',
                cursor: 'pointer',
                '&:disabled': {
                  cursor: 'not-allowed',
                  pointerEvents: 'none',
                  color: 'white',
                  background: 'grey',
                },
              }}
              disabled={!name && true}
              onClick={handleClickCloseTerms}
              autoFocus
            >


              {
                language === 'English' ? 'Confirm Quote                ' : 'Conferma preventivo'
              }            </Button>
          </DialogActions>
        </Dialog>
    </div>
  );
}

import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';

const ContactUs = ({ language }) => {
 
  return (
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
    
   {language === "English" ?  <View style={styles.greenImage}>
     <Image style={{...styles.greenImages, width:"40px", height:"40px", marginLeft:"20px", marginBottom:"-160px", marginTop:"30" }}  src="/translationAssets/desktop5/group-1000001730.png"/>
     <View style={{ paddingTop:"270px", display:"inline-flex", flexDirection:"row", }}>
          <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"30px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              London
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"74px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Madrid
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"77px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Dubai
              </Text>
              </View>
          </View>
          <View style={{marginTop:"5px", display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"7px", color:"white", width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"33px"}}>
          <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"33px"}}>
          71-75 Shelton Street, Covent Garden,

          </Text>
          <Text style={{fontSize:"7px", flexDirection:"column", display:"inline-block",color:"white", marginLeft:"33px"}}>
          London, WC2H9JQ
           </Text>
           <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"33px"}}>
           Email: info.uk@lingoyou.com
           </Text>
            </Text>
            <Text style={{fontSize:"7px", color:"white",width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"15px"}}>
            <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"15px"}}>
            Pinar, 5, 28006
            </Text>
            <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"15px"}}>
            Email: info.es@lingoyou.com
            </Text>

            </Text>     
            <Text style={{fontSize:"7px", color:"white", width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"10px"}}>
            <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"10px"}}>
            Level 12, Ajman Chamber of
              </Text>
              <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"10px"}}>
              Commerce building, Corniche,Ajman
              </Text>
              <Text style={{fontSize:"7px",flexDirection:"column",display:"inline-block", color:"white", marginLeft:"10px"}}>
              Email: info.ae@lingoyou.com
              </Text>


            </Text>
          </View>
          <View style={{ marginTop:"40px", display:"inline-flex", flexDirection:"row", }}>
          <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"30px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Milan
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"74px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Paris
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"77px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Tokyo
              </Text>
              </View>
          </View>
          <View style={{marginTop:"5px", display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"7px", color:"white", width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"33px"}}>
          <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white",  marginLeft:"33px"}}>
          Viale Fulvio Testi, 59 Milan
            </Text>
            <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"33px"}}>
            20158 Italy {' '}
            </Text>
            <View>
            <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"33px"}}>
            Email: info.it@lingoyou.com
            </Text>
          </View>
        </Text>
            <Text style={{fontSize:"7px", color:"white",width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"15px"}}>
            <Text style={{fontSize:"7px", flexDirection:"column", display:"inline-block", color:"white", marginLeft:"15px"}}>
            3B Rue Taylor, CS 20004 Paris,
              </Text>
              <Text style={{fontSize:"7px", flexDirection:"column", display:"inline-block", color:"white", marginLeft:"15px"}}>
              75481 {' '}
              </Text>
              <View>
              <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"15px"}}>
              Email: info.fr@lingoyou.com
              </Text>
              </View>
             
            </Text>     
            <Text style={{fontSize:"7px", color:"white", width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"10px"}}>
            <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white",  marginLeft:"10px"}}>
            1-8-2 Marunouchi, 4F Tekko Building,
              </Text>
            <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white",  marginLeft:"10px"}}>
            Chiyoda-ku, Tokyo, Tokyo,
              </Text>
              <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"10px"}}>
              100-0005 {' '}
              </Text>
              <View>
              <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"10px"}}>
              Email: info.jp@lingoyou.com
              </Text>
              </View>
             

            </Text>
          </View>
          <View style={{ marginTop:"40px", display:"inline-flex", flexDirection:"row", }}>
          <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"30px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              New York
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"68px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Copenhagen
              </Text>
              </View>
              
          </View>
          <View style={{marginTop:"5px", display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"7px", color:"white", width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"33px"}}>
          <Text style={{fontSize:"7px", color:"white", marginLeft:"33px"}}>
          405 Lexington Avenue, New York 
            </Text>
            <Text style={{fontSize:"7px", color:"white",  marginLeft:"33px"}}>
            City, NY 10174 {''}
            </Text>
            <View>
            <Text style={{fontSize:"7px", color:"white", marginLeft:"33px"}}>
            Email: info.us@lingoyou.com
            </Text>
            </View>
            

            </Text>
            <Text style={{fontSize:"7px", color:"white",width:"120px", flexDirection:"column", marginLeft:"15px"}}>
            <Text style={{fontSize:"7px", color:"white", marginLeft:"15px"}}>
            Tuborg Boulevard 12,
              </Text>
              <Text style={{fontSize:"7px", color:"white", marginLeft:"15px"}}>
              Copenhagen, 2900 Hellerup {''}
              </Text>
              <Text style={{fontSize:"7px", color:"white", marginLeft:"15px"}}>
              Email: info.dk@lingoyou.com
              </Text>

            </Text>     
          </View>
      <Image style={{width:"100%", objectFit:"cover", marginTop:"-490px", zIndex:"-99", height:"800px"}} 
       src="/translationAssets/desktop5/Dark Gradient 01.png"/>      
       <View style={styles.greenTextSection}>
       <Image style={{...styles.homeImage,marginTop:"-430px", width:"15%",objectFit:'contain', height:"200px", alignItems:"right", marginRight:"0px", marginLeft:"520px"}}  src="/assets/desktop5/group-1000001731.png"/>
       </View>
       <View style={{...styles.discountText, marginTop:"-220px", marginBottom:"30px"}}>
        <Text style={{...styles.discountList, marginBottom:"5px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px", height:"15px",marginBottom:"-10px", marginLeft:"5px"}}  src="/assets/icons/Phone.png"/>      
 +39 3755806530, +39 0282957099
        </Text>
        <Text style={{...styles.discountList, marginBottom:"5px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px",marginBottom:"-10px", height:"15px"}}  src="/assets/icons/facebook.png"/>      
         <Link style={{color:"white", marginBottom:"10px", marginLeft:"5px"}} href="https://www.facebook.com/profile.php?id=100068384772421">https://www.facebook.com/profile.php?id=100068384772421</Link>
        </Text>
        <Text style={{...styles.discountList, marginBottom:"10px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px", marginBottom:"-10px", height:"15px"}}  src="/assets/icons/instagram.png"/>      
        <Link style={{color:"white", marginBottom:"10px", marginLeft:"5px"}} href="https://www.instagram.com/lingo_you/">
        https://www.instagram.com/lingo_you/ 
        </Link>
        </Text>
       </View>
       </View> :  <View style={styles.greenImage}>
     <Image style={{...styles.greenImages, width:"40px", height:"40px", marginLeft:"20px", marginBottom:"-160px", marginTop:"30" }}  src="/translationAssets/desktop5/group-1000001730.png"/>
     <View style={{ paddingTop:"270px", display:"inline-flex", flexDirection:"row", }}>
          <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"30px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Londra
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"74px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Madrid
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"77px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Dubai
              </Text>
              </View>
          </View>
          <View style={{marginTop:"5px", display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"7px", color:"white", width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"33px"}}>
          <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"33px"}}>
          71-75 Shelton Street, Covent Garden,

          </Text>
          <Text style={{fontSize:"7px", flexDirection:"column", display:"inline-block",color:"white", marginLeft:"33px"}}>
          London, WC2H9JQ
           </Text>
           <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"33px"}}>
           Email: info.uk@lingoyou.com
           </Text>
            </Text>
            <Text style={{fontSize:"7px", color:"white",width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"15px"}}>
            <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"15px"}}>
            Pinar, 5, 28006
            </Text>
            <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"15px"}}>
            Email: info.es@lingoyou.com
            </Text>

            </Text>     
            <Text style={{fontSize:"7px", color:"white", width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"10px"}}>
            <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"10px"}}>
            Level 12, Ajman Chamber of
              </Text>
              <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"10px"}}>
              Commerce building,Corniche,Ajman
              </Text>
              <Text style={{fontSize:"7px",flexDirection:"column",display:"inline-block", color:"white", marginLeft:"10px"}}>
              Email: info.ae@lingoyou.com
              </Text>


            </Text>
          </View>
          <View style={{ marginTop:"40px", display:"inline-flex", flexDirection:"row", }}>
          <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"30px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Milano
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"74px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Parigi
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"77px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Tokyo
              </Text>
              </View>
          </View>
          <View style={{marginTop:"5px", display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"7px", color:"white", width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"33px"}}>
          <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white",  marginLeft:"33px"}}>
          Viale Fulvio Testi, 59 Milan
            </Text>
            <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"33px"}}>
            20158 Italy {' '}
            </Text>
            <View>
            <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"33px"}}>
            Email: info.it@lingoyou.com
            </Text>
          </View>
        </Text>
            <Text style={{fontSize:"7px", color:"white",width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"15px"}}>
            <Text style={{fontSize:"7px", flexDirection:"column", display:"inline-block", color:"white", marginLeft:"15px"}}>
            3B Rue Taylor, CS 20004 Paris,
              </Text>
              <Text style={{fontSize:"7px", flexDirection:"column", display:"inline-block", color:"white", marginLeft:"15px"}}>
              75481 {' '}
              </Text>
              <View>
              <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"15px"}}>
              Email: info.fr@lingoyou.com
              </Text>
              </View>
             
            </Text>     
            <Text style={{fontSize:"7px", color:"white", width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"10px"}}>
            <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white",  marginLeft:"10px"}}>
            Chiyoda-ku, Tokyo, Tokyo,
              </Text>
              <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"10px"}}>
              100-0005 {' '}
              </Text>
              <View>
              <Text style={{fontSize:"7px",flexDirection:"column", display:"inline-block", color:"white", marginLeft:"10px"}}>
              Email: info.jp@lingoyou.com
              </Text>
              </View>
             

            </Text>
          </View>
          <View style={{ marginTop:"40px", display:"inline-flex", flexDirection:"row", }}>
          <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"30px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              New York
              </Text>
              </View>
              <View style={{display:"inline-flex", flexDirection:"row", marginLeft:"68px"}}>
              <Image style={{width:"22px", height:"22px"}} src="/assets/icons/Location.png"/>
              <Text style={{fontSize:"9px", color:"white", marginTop:"5px"}}>
              Copenhagen
              </Text>
              </View>
              
          </View>
          <View style={{marginTop:"5px", display:"inline-flex", flexDirection:"row"}}>
          <Text style={{fontSize:"7px", color:"white", width:"120px", flexDirection:"column", display:"inline-block", marginLeft:"33px"}}>
          <Text style={{fontSize:"7px", color:"white", marginLeft:"33px"}}>
          405 Lexington Avenue, New York 
            </Text>
            <Text style={{fontSize:"7px", color:"white",  marginLeft:"33px"}}>
            City, NY 10174 {''}
            </Text>
            <View>
            <Text style={{fontSize:"7px", color:"white", marginLeft:"33px"}}>
            Email: info.us@lingoyou.com
            </Text>
            </View>
            

            </Text>
            <Text style={{fontSize:"7px", color:"white",width:"120px", flexDirection:"column", marginLeft:"15px"}}>
            <Text style={{fontSize:"7px", color:"white", marginLeft:"15px"}}>
            Tuborg Boulevard 12,
              </Text>
              <Text style={{fontSize:"7px", color:"white", marginLeft:"15px"}}>
              Copenhagen, 2900 Hellerup {''}
              </Text>
              <Text style={{fontSize:"7px", color:"white", marginLeft:"15px"}}>
              Email: info.dk@lingoyou.com
              </Text>

            </Text>     
          </View>
      <Image style={{width:"100%", objectFit:"cover", marginTop:"-490px", zIndex:"-99", height:"800px"}} 
       src="/translationAssets/desktop5/Dark Gradient 01.png"/>      
       <View style={styles.greenTextSection}>
       <Image style={{...styles.homeImage,marginTop:"-430px", width:"15%",objectFit:'contain', height:"200px", alignItems:"right", marginRight:"0px", marginLeft:"520px"}}  src="/assets/desktop5/group-1000001731.png"/>
       </View>
       <View style={{...styles.discountText, marginTop:"-220px", marginBottom:"30px"}}>
        <Text style={{...styles.discountList, marginBottom:"5px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px", height:"15px",marginBottom:"-10px", marginLeft:"5px"}}  src="/assets/icons/Phone.png"/>      
 +39 3755806530, +39 0282957099
        </Text>
        <Text style={{...styles.discountList, marginBottom:"5px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px",marginBottom:"-10px", height:"15px"}}  src="/assets/icons/facebook.png"/>      
         <Link style={{color:"white", marginBottom:"10px", marginLeft:"5px"}} href="https://www.facebook.com/profile.php?id=100068384772421">https://www.facebook.com/profile.php?id=100068384772421</Link>
        </Text>
        <Text style={{...styles.discountList, marginBottom:"10px", marginLeft:"25px", fontSize:"9px",fontStyle:"normal", fontWeight:"bold",}}>
        <Image style={{width:"15px", marginBottom:"-10px", height:"15px"}}  src="/assets/icons/instagram.png"/>      
        <Link style={{color:"white", marginBottom:"10px", marginLeft:"5px"}} href="https://www.instagram.com/lingo_you/">
        https://www.instagram.com/lingo_you/ 
        </Link>
        </Text>
       </View>
       </View>}
    </Page>
  );
};

export default ContactUs;
import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from './styles';
import { TermsAndConditions } from '../Terms&ConditionsText';


function formatDate(param) {
  // Extracting date components
  const date = new Date(param);
  var day = date.getDate();
  var month = date.getMonth() + 1; // Months are zero indexed
  var year = date.getFullYear() % 100; // Getting last two digits of the year
  var hours = date.getHours();
  var minutes = date.getMinutes();

  // Adding leading zeros if necessary
  day = (day < 10 ? '0' : '') + day;
  month = (month < 10 ? '0' : '') + month;
  year = (year < 10 ? '0' : '') + year;
  hours = (hours < 10 ? '0' : '') + hours;
  minutes = (minutes < 10 ? '0' : '') + minutes;

  // Concatenating date components
  var formattedDate = day + '/' + month + '/' + year + ' ' + hours + ':' + minutes;

  return formattedDate;
}


export default function TermsAndCondition({data,brand, language,name,quoteSignature, interpretationItem, additionalService, address1, companyNameAndId, quoteDeadline, interpretationItem2, additionalService2 }) {

    let previousDateKey = [] 

  return (
    <>
    <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
      <View style={{ ...styles.header,alignItems:"justify", marginLeft:"10px", marginBottom: 0, width: "99%", marginHorizontal: "auto" }}>
        <View style={{ display:"flex", flexDirection:"row", width:"100%" }}>
        <Text style={{...styles.headerItemText,textAlign:"justify",width:"100%",marginTop:"10px", fontSize:"11px",}}>
        {language === "English" ? 'Terms and conditions' : "Termini e Condizioni"}
        </Text>
        {/* <Image style={{width:"90px", height:"25px", marginTop:"10px", }} src="/assets/desktop6/group-1000001719.png" /> */}
        {brand && brand?.rafiky ? 
        <Image style={{width:"90px", height:"25px", marginTop:"10px", }} src="/rafiky/logo.png" />
        :   
    <Image style={{width:"90px", height:"25px", marginTop:"10px", }} src="/assets/desktop6/group-1000001719.png" />
}
        </View>
         {/* <View style={{alignItems:"right", alignContent:"right", float:"right"}}>
          <Image style={{...styles.headerRightImg, alignItems:"right", alignContent:"right", float:"right" }} src="/translationAssets/desktop6/lingoyou.png" />
        </View> */} 
        <View style={styles.headerItem} />
      </View>
      <View style={{ display: 'flex', flexDirection: 'row', marginTop: '10px', marginLeft: '10px', marginBottom:"8px" }}>
        <View style={{ width: '32%', marginRight: '5px' }}>
          <Text style={{...styles.newTermsHeadings, fontSize:"8px"}}>
          1. {language === "English" ? TermsAndConditions.en["title1"] : TermsAndConditions.it["title1"]}:
          </Text>
          <Text style={{ display: 'flex', flexDirection: 'row' }}>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
          {language === "English" ? TermsAndConditions.en["title1Para1"] : TermsAndConditions.it["title1Para1"]}

            </Text>
            
          </Text>
         
            
            <Text style={{...styles.newTermsHeadings,  fontSize:"8px", marginTop:"3px"}}>
            2. {language === "English" ? TermsAndConditions.en["title2"] : TermsAndConditions.it["title2"]}:
            </Text>
            <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
            {language === "English" ? TermsAndConditions.en["title2Para1"] : TermsAndConditions.it["title2Para1"]}

            </Text>
            <Text style={{...styles.newTermsHeadings,  fontSize:"8px", marginTop:"3px"}}>
            3. {language === "English" ? TermsAndConditions.en["title3"] : TermsAndConditions.it["title3"]}:
            </Text>
            <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
            {language === "English" ? TermsAndConditions.en["title3Para1"] : TermsAndConditions.it["title3Para1"]}

            </Text>
            {interpretationItem2?.length > 0 || interpretationItem?.length > 0 ? (
 [interpretationItem, ...interpretationItem2]?.map((items, option)=>{
  previousDateKey = []
  return(
 <div>
  {interpretationItem2?.length > 0 &&
    <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px'}}>
    {language === "English" ? "Option" : "Opzione"} {option + 1}:
  </Text>
  }
 {
  items?.length > 0 ? items?.map((item, index) => {
    const startDate = new Date(item?.startDateTime);
    const endDate = new Date(item?.endDateTime);
    const dateKey = `${startDate.getDate()}${startDate.getMonth()}${startDate.getFullYear()}`;

    if (index === 0 || !previousDateKey.includes(dateKey)) {
      const startDateDay = String(startDate.getDate()).padStart(2, '0');
      const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const startDateYear = String(startDate.getFullYear()).slice(-2);
      let startDateHrs = String(startDate.getHours()).padStart(2, '0');
      let startDatemins = String(startDate.getMinutes()).padStart(2, '0');
      const startingDate =
        startDateDay +
        '/' +
        startDateMonth +
        '/' +
        startDateYear +
        ' ' +
        startDateHrs +
        ':' +
        startDatemins;
      const endDateDay = String(endDate.getDate()).padStart(2, '0');
      const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const endDateYear = String(endDate.getFullYear()).slice(-2);
      let endDateHrs = String(endDate.getHours()).padStart(2, '0');
      let endDateMins = String(endDate.getMinutes()).padStart(2, '0');
      const endingDate =
        endDateDay +
        '/' +
        endDateMonth +
        '/' +
        endDateYear +
        ' ' +
        endDateHrs +
        ':' +
        endDateMins;

      previousDateKey.push(dateKey);
      return (
       <>
       {language === "English" ?  <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal',  }}>
        From: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
        {startingDate}
            </Text>  &nbsp; To: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
            {endingDate}
                </Text>
        </Text>
       : <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
       Da: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
       {startingDate}
           </Text>  &nbsp; A: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
           {endingDate}
               </Text>
       </Text>
      }
       </>   
      );
    }
  }) : ( 
    [additionalService, ...additionalService2]?.map((services, index)=>(
      
     option === index && services?.map((item, index) => {
    const startDate = new Date(item?.startDateTime);
    const endDate = new Date(item?.endDateTime);
    const dateKey = `${startDate.getDate()}${startDate.getMonth()}${startDate.getFullYear()}`;

    if (index === 0 || !previousDateKey.includes(dateKey)) {
      const startDateDay = String(startDate.getDate()).padStart(2, '0');
      const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const startDateYear = String(startDate.getFullYear()).slice(-2);
      let startDateHrs = String(startDate.getHours()).padStart(2, '0');
      let startDatemins = String(startDate.getMinutes()).padStart(2, '0');
      const startingDate =
        startDateDay +
        '/' +
        startDateMonth +
        '/' +
        startDateYear +
        ' ' +
        startDateHrs +
        ':' +
        startDatemins;
      const endDateDay = String(endDate.getDate()).padStart(2, '0');
      const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const endDateYear = String(endDate.getFullYear()).slice(-2);
      let endDateHrs = String(endDate.getHours()).padStart(2, '0');
      let endDateMins = String(endDate.getMinutes()).padStart(2, '0');
      const endingDate =
        endDateDay +
        '/' +
        endDateMonth +
        '/' +
        endDateYear +
        ' ' +
        endDateHrs +
        ':' +
        endDateMins;

        previousDateKey.push(dateKey);

      return (
        <>
       {language === "English" ? <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
        From: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
        {startingDate}
            </Text>  &nbsp; To: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
            {endingDate}
                </Text>
        </Text>
       : <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
       From: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
       {startingDate}
           </Text>  &nbsp; To: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
           {endingDate}
               </Text>
       </Text>
      }
       </>   
      );
    }
  })))
  )
 }
 </div>
 )}) 
) : (
  additionalService.map((item, index) => {
    const startDate = new Date(item?.startDateTime);
    const endDate = new Date(item?.endDateTime);
    const dateKey = `${startDate.getDate()}${startDate.getMonth()}${startDate.getFullYear()}`;

    if (index === 0 || !previousDateKey.includes(dateKey)) {
      const startDateDay = String(startDate.getDate()).padStart(2, '0');
      const startDateMonth = String(startDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const startDateYear = String(startDate.getFullYear()).slice(-2);
      let startDateHrs = String(startDate.getHours()).padStart(2, '0');
      let startDatemins = String(startDate.getMinutes()).padStart(2, '0');
      const startingDate =
        startDateDay +
        '/' +
        startDateMonth +
        '/' +
        startDateYear +
        ' , ' +
        startDateHrs +
        ':' +
        startDatemins;
      const endDateDay = String(endDate.getDate()).padStart(2, '0');
      const endDateMonth = String(endDate.getMonth() + 1).padStart(2, '0'); // January is 0!
      const endDateYear = String(endDate.getFullYear()).slice(-2);
      let endDateHrs = String(endDate.getHours()).padStart(2, '0');
      let endDateMins = String(endDate.getMinutes()).padStart(2, '0');
      const endingDate =
        endDateDay +
        '/' +
        endDateMonth +
        '/' +
        endDateYear +
        ' , ' +
        endDateHrs +
        ':' +
        endDateMins;

        previousDateKey.push(dateKey);

      return (
        <>
        {language === "English" ? <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
        From: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
        {startingDate}
            </Text>  &nbsp; To: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
            {endingDate}
                </Text>
        </Text>
        : <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
        From: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
        {startingDate}
            </Text>  &nbsp; To: <Text style={{ ...styles.newTermsHeadings, fontSize: '7.5px' }}>
            {endingDate}
                </Text>
        </Text>
       }
        </>   
      );
    }
  })
)}
          <Text style={{ ...styles.newTermsHeadings, fontSize:"8px", marginTop:  "2px"  }}>
          4. {language === "English" ? TermsAndConditions.en["title4"] : TermsAndConditions.it["title4"]}:
          </Text>
          
            
            {language === "English" ? <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
            You must send confirmation of the quote by <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'bold' }}>{formatDate(data?.quoteDeadline)}.</Text> After this date the quote may be subject to changes.
          </Text> : 
          <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
            È necessario inviare conferma del preventivo entro il <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'bold' }}>{formatDate(data?.quoteDeadline)}.</Text> Oltre tale data il preventivo potrebbe subire delle variazioni.
          </Text>
}
          <Text style={{ ...styles.newTermsHeadings, fontSize:"8px", marginTop: "2px" }}>
          5. {language === "English" ? TermsAndConditions.en["title5"] : TermsAndConditions.it["title5"]}:
          </Text>
            <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
            {language === "English" ? TermsAndConditions.en["title5Para1"] : TermsAndConditions.it["title5Para1"]}

          </Text>
         
          <Text style={{ ...styles.newTermsHeadings, fontSize:"8px", marginTop: "2px"  }}>
          6. {language === "English" ? TermsAndConditions.en["title6"] : TermsAndConditions.it["title6"]}:
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'bold', }}>
          {language === "English" ? TermsAndConditions.en["title6Heading1"] : 
          TermsAndConditions.it["title6Heading1"]}   
            </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
        {language === "English" ? TermsAndConditions.en["title6Para1"] : 
          TermsAndConditions.it["title6Para1"]}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
        {language === "English" ? TermsAndConditions.en["title6Para2"] : 
          TermsAndConditions.it["title6Para2"]}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
        {language === "English" ? TermsAndConditions.en["title6Para3"] : 
          TermsAndConditions.it["title6Para3"]}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
        {language === "English" ? TermsAndConditions.en["title6Para4"] : 
          TermsAndConditions.it["title6Para4"]}
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize:"7px", marginTop:"2px"  }}>
          {language === "English" ? TermsAndConditions.en["title6Heading2"] : 
          TermsAndConditions.it["title6Heading2"]}       
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
        {language === "English" ? TermsAndConditions.en["title6Para5"] : 
          TermsAndConditions.it["title6Para5"]}  
         </Text>
         <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
        {language === "English" ? TermsAndConditions.en["title6Para6"] : 
          TermsAndConditions.it["title6Para6"]}  
         </Text>
        
        </View>
        <View style={{ width: '32%', marginRight: '5px', marginTop:"2px" }}>
        
        <Text style={{ ...styles.newTermsHeadings, fontSize:"8px", marginTop: `${language === "English" ? '4px' : '3px'}` }}>
        7. {language === "English" ? TermsAndConditions.en["title7"] : 
          TermsAndConditions.it["title7"]}:      
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
        {language === "English" ? TermsAndConditions.en["title7Para1"] : 
          TermsAndConditions.it["title7Para1"]}
        </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize:"8px", marginTop: "3px"  }}>
        8. {language === "English" ? TermsAndConditions.en["title8"] : 
          TermsAndConditions.it["title8"]}:
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
        {language === "English" ? TermsAndConditions.en["title8Para1"] : 
          TermsAndConditions.it["title8Para1"]}
          </Text>
          <Text style={{ ...styles.newTermsHeadings,fontSize:"8px", marginTop: "3px" }}>
          9. {language === "English" ? TermsAndConditions.en["title9"] : 
          TermsAndConditions.it["title9"]}:      
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
        {language === "English" ? TermsAndConditions.en["title9Para1"] : 
          TermsAndConditions.it["title9Para1"]}
         </Text>
         
         <Text style={{ ...styles.newTermsHeadings,fontSize:"8px", marginTop: "3px"  }}>
         10. {language === "English" ? TermsAndConditions.en["title10"] : 
          TermsAndConditions.it["title10"]}:   
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '7px',lineHeight:"1.7px" , fontWeight: 'normal' }}>
        {language === "English" ? TermsAndConditions.en["title10Para1"] : 
          TermsAndConditions.it["title10Para1"]}
         </Text>      
          
      
        </View>
        <View style={{ width: '32%', marginRight: '5px' }}>
        <Text style={{ ...styles.newTermsHeadings,fontSize:"8px", marginTop:  "2px" }}>
         11. {language === "English" ? TermsAndConditions.en["title11"] : 
          TermsAndConditions.it["title11"]}: 
         </Text>
         <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal', lineHeight:"1.7px" }}>
         {language === "English" ? TermsAndConditions.en["title11Para1"] : 
          TermsAndConditions.it["title11Para1"]}
        </Text>
          
        <Text style={{ ...styles.newTermsHeadings, fontSize:"8px", marginTop:"2px" }}>
        12. {language === "English" ? TermsAndConditions.en["title12"] : 
          TermsAndConditions.it["title12"]}:    
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal', lineHeight:"1.7px" }}>
        {language === "English" ? TermsAndConditions.en["title12Para1"] : 
          TermsAndConditions.it["title12Para1"]}
         </Text>  
        
        <Text style={{ ...styles.newTermsHeadings, fontSize:"8px", marginTop: "2px" }}>
        13. {language === "English" ? TermsAndConditions.en["title13"] : 
          TermsAndConditions.it["title13"]}:
          </Text>
        <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal',  lineHeight:"1.7px" }}>
        {language === "English" ? TermsAndConditions.en["title13Para1"] : 
          TermsAndConditions.it["title13Para1"]}
         </Text>  
<Text style={{ ...styles.newTermsHeadings, fontSize:"8px", marginTop: `${language === "English" ? '4px' : '3px'}` }}>
14. {language === "English" ? TermsAndConditions.en["title14"] : 
          TermsAndConditions.it["title14"]}:
          </Text>
        
        
        <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal' }}>
        {language === "English" ? TermsAndConditions.en["title14Para1"] : 
          TermsAndConditions.it["title14Para1"]}
         </Text>
          
        <Text style={{ ...styles.newTermsHeadings, fontSize:"8px", marginTop: '3px' }}>
          15. {language === "English" ? TermsAndConditions.en["title15"] : 
          TermsAndConditions.it["title15"]}:     
          </Text>
          <Text style={{ ...styles.newTermsHeadings, fontSize: '7px', fontWeight: 'normal',  lineHeight:`${language === "English" ? '' : '1.7px'}` }}>
          {language === "English" ? TermsAndConditions.en["title15Para1"] : 
          TermsAndConditions.it["title15Para1"]}
        </Text>
         
        </View>
      </View>
      
    </Page>
     <Page wrap={true} size="A4" style={{ ...styles.page, padding: 0 }}>
     <View style={{flexDirection:"row"}}>
     <View style={{ width: '32%', marginRight: '5px' }}>
     <View style={{marginTop:"50px"}}>
             <Text style={{ ...styles.newTermsHeadings, marginBottom: '7.5px', textAlign: 'center' }}>
             {
                   language === 'English' ? `Signature for acceptance of quote CI/${data?.orderNumber}:` : 
                   `Firma per accettazione Preventivo CI/${data?.orderNumber}:`
                 }
             </Text>
             <Text>
               {(data?.status === 'Quote-Won' || data?.status === "Sent To PM") && (name || quoteSignature) && (
                 <View style={{ ...styles.termsFooterBodyItemBtn, alignContent: 'center', alignItems: 'center' , marginBottom:"6px", borderBottom: '1px solid grey' }}>
                   <Text>{name || quoteSignature}</Text>
                 </View>
               )}
             </Text>
             </View>
     </View>
       <View style={{ width: '32%', marginRight: '5px' }}>
       <View style={{marginTop:"50px"}}>
             <Text style={{ ...styles.newTermsHeadings, marginBottom: '8px', textAlign: 'center' }}>
               {language === 'English'
                 ? 'Signature for acceptance of the Terms & Conditions:'
                 : 'Firma per accettazione Termini & Condizioni:'}
             </Text>
             <Text>
               {(data?.status === 'Quote-Won' || data?.status === "Sent To PM") && (name || quoteSignature) && (
                 <View style={{ ...styles.termsFooterBodyItemBtn, alignContent: 'center', alignItems: 'center' , marginBottom:"6px", borderBottom: '1px solid grey' }}>
                   <Text>{name || quoteSignature}</Text>
                 </View>
               )}
             </Text>
             </View>
       </View>
   <View style={{ width: '32%', marginRight: '5px' }}>
   
            
             <View style={{marginTop:"50px"}}>
             <Text style={{ ...styles.newTermsHeadings, marginBottom: '8px', textAlign: 'center' }}>
             {
                   language === 'English' ? 'Signature for acceptance Terms and conditions of the site' : 'Firma per accettazione Termini & condizioni del sito '
                 } <Link href='http://www.lingoyou.com/terminicondizioni'>(http://www.lingoyou.com/terminicondizioni)</Link>  
             </Text>
             <Text>
               {(data?.status === 'Quote-Won' || data?.status === "Sent To PM") && (name || quoteSignature) && (
                 <View style={{ ...styles.termsFooterBodyItemBtn, alignContent: 'center', alignItems: 'center' , marginBottom:"6px", borderBottom: '1px solid grey' }}>
                   <Text>{name || quoteSignature}</Text>
                 </View>
               )}
             </Text>
             </View>
   </View>
     </View>
    
       </Page>
       </>
  );
}

import React from 'react';
import { Document } from "@react-pdf/renderer";
import Discount from 'src/sections/interpretClientOutput/rafiky/PDFDoc/Discount.js';
import WhyRafiky from 'src/sections/interpretClientOutput/rafiky/PDFDoc/WhyRafiky.js';
import OnSiteInterpreting from 'src/sections/interpretClientOutput/rafiky/PDFDoc/OnSiteInterpreting.js';
import CustomerGuide1 from 'src/sections/interpretClientOutput/rafiky/PDFDoc/CustomerGuide1.js';
import CustomerGuide from 'src/sections/interpretClientOutput/rafiky/PDFDoc/CustomerGuide.js';
import Connect from 'src/sections/interpretClientOutput/rafiky/PDFDoc/Connect.js';
import UseCases from 'src/sections/interpretClientOutput/rafiky/PDFDoc/UseCases.js';
import WebConferencing from 'src/sections/interpretClientOutput/rafiky/PDFDoc/WebConferencing.js';
import AboutUs1 from 'src/sections/interpretClientOutput/rafiky/PDFDoc/AboutUs1.js';
import AboutUs2 from 'src/sections/interpretClientOutput/rafiky/PDFDoc/AboutUs2.js';
import Process from 'src/sections/interpretClientOutput/rafiky/PDFDoc/Process.js';
import OurClients from './pages/OurClients.js';
import Testimonials from './pages/Testimonials.js';
import ContactUs from 'src/sections/interpretClientOutput/rafiky/PDFDoc/ContactUs.js';
import Introduction from './pages/Introduction.js';
import Home from './pages/Home.js';


const PDFDoc = ({ language = "Italian", details, quoteId }) => {
  const {
    companyNameAndId,
    accountManager,
    bookedBy,
    brochureNumber,
    companyLogo,
    subject,
    industry,
    clients
  } = details;
    console.log(details)
  return (
    <>
   <Document >
   <Home language={language} data={{ companyNameAndId, bookedBy, accountManager, subject, companyLogo, industry }}/>
   <Introduction brand={{rafiky:true}} language={language}industry={industry} bookedBy={bookedBy} accountManager={accountManager} 
      companyNameAndId={companyNameAndId} clients={clients}/>
   <AboutUs1 language={language}/>
      <AboutUs2 language={language} quoteId={quoteId}/>
      {/* <Solution language={language}/> */}
      <WhyRafiky language={language}/>  
      <Process language={language} /> 
   <UseCases language={language}/>  
   <Connect language={language}/> 
   <CustomerGuide language={language}/>
      <CustomerGuide1 language={language}/>
      <WebConferencing language={language}/>
      <OnSiteInterpreting language={language}/>
      <Discount language={language}/>
      <OurClients language={language} brand={"Rafiky"}/>
      <Testimonials language={language}/>
      <ContactUs/>
    </Document>
    </>
  )
};

export default PDFDoc;

import { StyleSheet, Font } from "@react-pdf/renderer";
import PoppinsRegular from '../../PDFDoc/fonts/Poppins-Medium.ttf';
import PoppinsLight from '../../PDFDoc/fonts/Poppins-Light.ttf';
import PoppinsBold from '../../PDFDoc/fonts/Poppins-Bold.ttf'




Font.register({
  family: "Poppins 'sans-serif'", fonts: [
    { src: PoppinsRegular, fontStyle: 'normal', fontWeight: "normal" },
    { src: PoppinsRegular, fontStyle: 'italic', fontWeight: "normal" },
    { src: PoppinsLight, fontStyle: 'normal', fontWeight: "light" },
    { src: PoppinsBold, fontStyle: 'normal', fontWeight: "bold" },
  ]
})


export const styles = StyleSheet.create({
  page: {
    backgroundColor: '#FFFFFF',
    paddingHorizontal: "20px",
    paddingVertical: "10px",
    fontFamily: "Poppins 'sans-serif'"
  },
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "40px",
    borderBottom: "1px solid #8316d3",
    marginBottom: "50px"
  },
  headerItem: {
    flex: 1,
  },
  headerItemText: {
    color: "#8316d3",
    textAlign: "left",
    fontSize: "16px",
    marginTop:"3px",
    fontWeight:"bold", textTransform:"uppercase"
  },
  headerRight: {
    flex: 1,
    display: "flex",
  },
  headerRightImg: {
    width: "110px",
  },
  tableCustomer: {
    width: "75%",
    marginHorizontal: "auto",
    marginBottom: "50px",
  },
  tableCustomerHeader: {
    backgroundColor: "#2fcbe9",
    color: "#FFFFFF",
    display: "flex",
    flexDirection: "row",
    paddingHorizontal: "10px",
    paddingVertical: "16px",
    borderRadius:"4px"
  },
  tableCustomerItem: {
    flex: 1,
    alignItems: "flex-start",
  },
  tableCustomerText: {
    fontSize: "10px",
  },
  tableCustomerBody: {
    backgroundColor: "#FFFFFF",
    color: "rgba(0, 0, 0, 0.6)",
    display: "flex",
    flexDirection: "row",
    padding: "10px",
    fontSize: "11px",
  },
  bold: {
    fontWeight: "bold",
  },
  light: {
    fontWeight: "light",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
  },
  del: {
    textDecoration: "line-through"
  },
  celCenter: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center"
  },
  description: {
    fontSize: "8px",
  },
  discountImage:{
  },
  discountTextSection:{
  display:"inline-flex",
  flexDirection:"row"
  },
  discountImages:{
  width:"200px",
  marginTop:"-840px",
  height:"auto",
  objectFit:"contain",
  marginLeft:"20px"
  },
  discountText:{
  marginTop:"-250px",
  marginLeft:"10px",
  marginRight:"10px"
  },
  discountList:{
    color:"white",
    fontSize:"10px",
    marginBottom:"10px",
  },
  privacy:{
    marginTop:"10px",
    flexDirection:"column",
    display:"block"
  },
  techBody:{
    marginLeft: "15px",
    marginTop: "20px",
    textAlign: "justify",
    color: "#161F30",
    width: "95%",
  },
  techParaBody:{
    fontFamily: "Poppins 'sans-serif'",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "9px",
    marginBottom:"5px"
  },
  techSections:{
  display:"inline-flex",
  flexDirection:"row",
  marginLeft:"5px",
  marginRight:"5px"
  },
  techSection1:{
  width:"50%",
  display:"inline-flex",
  flexDirection:"row"
  },
  techSection2:{
    width:"50%",
    display:"inline-flex",
    flexDirection:"row",
    marginLeft:"5px"
    },
  techSectionImage:{
    width:"50px",
    height:"40px",
    backgroundColor:"#8316d3"
  },
  techSectionText:{
    fontFamily: "Poppins 'sans-serif'",
    fontStyle: "normal",
    fontWeight: "normal",
    fontSize: "6px",
    marginBottom:"5px",
    marginLeft:"5px",
    width: "280px",
    textAlign:"justify"
  },
  techFooter:{
    backgroundColor:"#8316d3",
    padding: "5px",
    color: "#FFFFFF",
    flex: 1,
    alignItems:"center",
    marginInline:"auto",
    placeContent:"center"
  },
  techFooterImage:{
    width:"100%",
    alignItems:"center",
    marginInline:"auto",
    placeContent:"center"
  },
});

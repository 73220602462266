import { Grid } from '@mui/material';
import styles from '../styles/AboutUs2.module.css';
import { useSelector } from 'react-redux';
import { PATH_AUTH, PATH_DASHBOARD } from 'src/routes/paths';
import { Link } from 'react-router-dom';

const AboutUs2 = () => {
  const { language, quoteId, details } = useSelector((state) => state.proposal);

  const {brand} = details
  if (language === 'English') {
    return (
      <div className={styles.desktop8}>
        <div className={styles.desktop8Item} />
        {/* <div className={styles.div}>04</div> */}
        <div className={styles.aboutUs}>A SOLID GROUP FOR AN ALL-INCLUSIVE LANGUAGE COVERAGE</div>
        <div className={styles.text3543Parent}>
        {brand && brand?.rafiky ? <img 
        alt=""
        src="/rafiky/logo.webp"
      /> :  <img alt="" src="/assets/desktop6/group-1000001719.svg" />}        
      </div>
        <div className={styles.aboutCards}>
          <Grid container spacing={3} sx={{ placeContent: 'center', width:"98%", marginInline:"auto" }}>
            <Grid item xs={12} md={6} xl={3}>
              <div className={styles.cards}> 
                <div style={{width:"fit-content"}}>      
                <img className={styles.cardImage} src="/translationAssets/desktop8/lingoyou.png" />
                </div>              
                <div className={styles.comment}>
                Global vision, local service. 20,000+ translators for complete language solutions. 
                </div>
                <a
                  href={`${process.env.REACT_APP_URL}${PATH_AUTH.quoteLingoyouClientView(quoteId, 'English')}`}
                  target="_blank"
                >   
                Find out more               
                </a>
              </div>
            </Grid>

            <Grid item xs={12} md={6} xl={3}>
            
              <div className={styles.cards}>
                <div style={{width:"fit-content",  marginBottom:"25px", marginTop:"30px"}}>
                <img className={styles.cardImage} src="/translationAssets/desktop8/congressi.png" />
                </div>
              <div className={styles.comment} style={{ fontSize:"15px"}}>
              Exceptional interpreting agency: selected experts, advanced technology, full coverage. Chosen by companies in Italy and Europe.
                              
              </div>
              <a
                href="#map"
              >
            Find out more
              </a>
            </div>
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
            <div className={styles.cards}>
                <div style={{marginBottom:"13px", width:"fit-content"}}>
                <img
                  className={styles.cardImage} 
                  src="/translationAssets/desktop8/lingoyou_academy.png"
                />
                </div>
                
              <div className={styles.comment}>
              Quality professional training. Language courses, certifications, recovery, exam preparation.
              </div>
              <a
                href={`${process.env.REACT_APP_URL}${PATH_AUTH.quoteLingoyouAcademyClientView(quoteId, 'English')}`}
                target="_blank"
              >
             Find out more
              </a>
            </div>
            </Grid>

            <Grid item xs={12} md={6} xl={3}>
              <div className={styles.cards}>
                <div style={{width:"fit-content",  marginBottom:"25px", marginTop:"30px"}}>
                <img style={{width:"220px"}} className={styles.cardImage} src="/translationAssets/desktop8/rafiky.png" />
                </div>
                <div className={styles.comment}>
                Our cutting-edge remote interpreting platform offers innovative communication solutions.               
                </div>
                <a
                  href={`${process.env.REACT_APP_URL}${PATH_AUTH.quoteRafikyClientView(quoteId, 'English')}`}
                  target="_blank"
                >
               Find out more
                </a>
              </div>{' '}
            </Grid>
          </Grid>

          <div
            style={{
              backgroundImage: `url('/assets/desktop8/bg_chart.png')`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            className={styles.chart}
          >
            <div className={styles.col1}>
              <div className={styles.col11}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop6/lingoyou.png"
                  />
                  <span>01</span>
                </div>
                <p>
                Translation Service
                </p>
              </div>
              <div className={styles.col12}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto'}}
                    alt="logo"
                    src="/translationAssets/desktop6/lingoyou.png"
                  />
                  <span>02</span>
                </div>
                <p>
                Localization Service 
                </p>
              </div>
              <div className={styles.col13}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop6/lingoyou.png"
                  />
                  <span>03</span>
                </div>
                <p>
                Audiovisual Linguistic Services 
                </p>
              </div>
              <div className={styles.col14}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop6/lingoyou.png"
                  />
                  <span>04</span>
                </div>
                <p>
                Certified Translation 
                </p>
              </div>
              <div className={styles.col15}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto', width: '110px' }}
                    alt="logo"
                    src="/translationAssets/desktop8/lingoyou_academy1.png "
                  />
                  <span>05</span>
                </div>
                <p>
                LANGUAGE SCHOOL
                </p>
              </div>
            </div>
            <div className={styles.col2}>
              <div className={styles.col21}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop8/congressi1.png"
                  />
                  <span>06</span>
                </div>
                <p>
                On-Site Interpreting Services  
                </p>
              </div>
              <div className={styles.col22}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop8/congressi1.png"
                  />
                  <span>07</span>
                </div>
                <p>
                Interpreter Equipment Rental 
                </p>
              </div>
              <div className={styles.col23}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop8/congressi1.png"
                  />
                  <span>08</span>
                </div>
                <p>
                Hostesses & Stewards 
                </p>
              </div>
              <div className={styles.col24}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop8/rafiky1.png"
                  />
                  <span>09</span>
                </div>
                <p>
                Remote Interpreting Services 
                </p>
              </div>
              <div className={styles.col25}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop8/rafiky1.png"
                  />
                  <span>10</span>
                </div>
                <p>
                INTEGRATION WITH MICROSOFT TEAMS 
                </p>
              </div>
            </div>
          </div>

          
        </div>
      </div>
    );
  }
  return (
    <div className={styles.desktop8}>
      <div className={styles.desktop8Item} />
      {/* <div className={styles.div}>04</div> */}
      <div className={styles.aboutUs}>UN GRUPPO SOLIDO PER UNA COPERTURA LINGUISTICA A 360 GRADI</div>
      <div className={styles.text3543Parent}>
      {brand && brand?.rafiky ? <img  style={{marginTop:"-25px"}}
        alt=""
        src="/rafiky/logo.webp"
      /> :  <img alt="" src="/assets/desktop6/group-1000001719.svg" />}      </div>
      <div className={styles.aboutCards}>
          <Grid container spacing={3} sx={{ placeContent: 'center', width:"98%", marginInline:"auto" }}>
            <Grid item xs={12} md={6} xl={3}>
              <div className={styles.cards}>
              <div style={{width:"fit-content"}}>
              <img className={styles.cardImage} src="/translationAssets/desktop8/lingoyou.png" />
              </div>
                <div className={styles.comment}>
                Traduzione globale, servizio clienti locale. Oltre 20.000 traduttori professionisti per soluzioni linguistiche complete. 
                </div>
                <a
                  href={`${process.env.REACT_APP_URL}${PATH_AUTH.quoteLingoyouClientView(quoteId, 'Italian')}`}
                  target="_blank"
                >   
               Scopri di più              
                </a>
              </div>
            </Grid>

            <Grid item xs={12} md={6} xl={3}>
             
              <div className={styles.cards}>
                <div style={{width:"fit-content",  marginBottom:"25px", marginTop:"30px"}}>
                <img  className={styles.cardImage} src="/translationAssets/desktop8/congressi.png" />
                </div>
              <div  className={styles.comment} style={{fontSize:"15px"}}>
              Interpretariato eccellente: esperti selezionati, tecnologie avanzate, copertura completa. Scelta da aziende in Italia ed Europa.              </div>
              <a
                href="#map"
              >
            Scopri di più
              </a>
            </div>
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
            <div className={styles.cards}>
                <div style={{marginBottom:"13px", width:"fit-content"}}>
                <img
                  className={styles.cardImage}
                  src="/translationAssets/desktop8/lingoyou_academy.png" 
                />
                </div>
               
              <div className={styles.comment}>
              Formazione professionale di qualità. Corsi di lingue, certificazioni, recupero, preparazione esami.   
              </div>
              <a
                href={`${process.env.REACT_APP_URL}${PATH_AUTH.quoteLingoyouAcademyClientView(quoteId, 'Italian')}`}
                target="_blank"
              >
            Scopri di più
              </a>
            </div>
            </Grid>
            <Grid item xs={12} md={6} xl={3}>
              <div className={styles.cards}>
                <div style={{width:"fit-content",  marginBottom:"25px", marginTop:"30px"}}>
                <img style={{width:"220px"}} className={styles.cardImage} src="/translationAssets/desktop8/rafiky.png" />
                </div>
                <div className={styles.comment}>
                Piattaforma di interpretariato da remoto, all'avanguardia nel settore, offre soluzioni di comunicazione innovative.            
                </div>
                <a
                  href={`${process.env.REACT_APP_URL}${PATH_AUTH.quoteRafikyClientView(quoteId, 'Italian')}`}
                  target="_blank"
                >
               Scopri di più
                </a>
              </div>{' '}
            </Grid>
          </Grid>

          <div
            style={{
              backgroundImage: `url('/assets/desktop8/bg_chart.png')`,
              backgroundPosition: 'center',
              backgroundRepeat: 'no-repeat',
            }}
            className={styles.chart}
          >
            <div className={styles.col1}>
              <div className={styles.col11}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop6/lingoyou.png"
                  />
                  <span>01</span>
                </div>
                <p>
                SERVIZIO DI TRADUZIONE 
                </p>
              </div>
              <div className={styles.col12}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop6/lingoyou.png"
                  />
                  <span>02</span>
                </div>
                <p>
               SERVIZIO DI LOCALIZZAZIONE
                </p>
              </div>
              <div className={styles.col13}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop6/lingoyou.png"
                  />
                  <span>03</span>
                </div>
                <p>
               SERVIZI LINGUISTICI AUDIOVISIVI 
                </p>
              </div>
              <div className={styles.col14}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop6/lingoyou.png"
                  />
                  <span>04</span>
                </div>
                <p>
                TRADUZIONE CERTIFICATA 
                </p>
              </div>
              <div className={styles.col15}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto', width:"110px" }}
                    alt="logo"
                    src="/translationAssets/desktop8/lingoyou_academy1.png"
                  />
                  <span>05</span>
                </div>
                <p>
                LANGUAGE SCHOOL
                </p>
              </div>
            </div>
            <div className={styles.col2}>
              <div className={styles.col21}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop8/congressi1.png"
                  />
                  <span>06</span>
                </div>
                <p>
                SERVIZI DI INTERPRETARIATO IN LOCO  
                </p>
              </div>
              <div className={styles.col22}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop8/congressi1.png"
                  />
                  <span>07</span>
                </div>
                <p>
                NOLEGGIO ATTREZZATURA PER INTERPRETI 
                </p>
              </div>
              <div className={styles.col23}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop8/congressi1.png"
                  />
                  <span>08</span>
                </div>
                <p>
                HOSTESS & STEWARD
                </p>
              </div>
              <div className={styles.col24}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop8/rafiky1.png"
                  />
                  <span>09</span>
                </div>
                <p>
                	SERVIZI DI INTERPRETARIATO DA REMOTO 
                </p>
              </div>
              <div className={styles.col25}>
                <div className={styles.div1} style={{ display: 'flex' }}>
                  <img
                    style={{ height: 'auto' }}
                    alt="logo"
                    src="/translationAssets/desktop8/rafiky1.png"
                  />
                  <span>10</span>
                </div>
                <p>
                INTEGRAZIONE CON MICROSOFT TEAMS
                </p>
              </div>
            </div>
          </div>

         
        </div>
    </div>
  );
};

export default AboutUs2;

// import { Grid } from "@mui/material";
// import React from "react";
// import styles from "../styles/Packages.module.css";
import { Image, Link, Page, Text, View } from '@react-pdf/renderer';
import React from 'react';
import { styles } from '../globalStyles';
import { AiOutlineTranslation, AiOutlineAudit } from 'react-icons/ai';
import { FaPeopleArrows } from 'react-icons/fa';
import { MdOutlineHighQuality } from 'react-icons/md';
import { icons } from '../icons';

export default function Packages({ netTotal, language, netTotalOptions }) {

    return (
      <Page wrap={false} size="A4" style={{ ...styles.page, padding: 0 }}>
        <View style={{ ...styles.header, marginBottom: 0, width: '94%', marginHorizontal: 'auto' }}>
          <View style={{ ...styles.headerItem }}>
            <Text style={styles.headerItemText}>Packages</Text>
          </View>
          <View>
            <Image style={styles.headerRightImg} src="/translationAssets/desktop6/lingoyou.png" />
          </View>
          <View style={styles.headerItem} />
        </View>

        <View style={{ ...styles.cards, display: 'flex', flexDirection: 'row' }}>
          <View style={{ width: '250px', margin: '10px', display: 'flex' }}>
            <View style={styles.card1}>
              <Image
                style={{ width: '70px', height: '70px', marginLeft: '50px' }}
                src="/translationAssets/packages/logo1.png"
              />
              <View style={{ ...styles.cardData }}>
                <Text style={styles.heading}>
                 {language === "English" ? `Economy` : `Economy`}
                  </Text>
                <Text style={styles.paragraph}>
                {language === "English" ? `Automatic translation through one of our software. Quick review of the document by an expert native translator. Translations suitable for understanding a text or for private use, not suitable for publication.` : 
                `Traduzione automatica attraverso uno dei nostri software. Revisione veloce del documento da parte di un traduttore madrelingua esperto. Traduzioni adatte alla comprensione di un testo o per uso privato, non adatto alla pubblicazione.`}

                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: '250px', margin: '10px', display: 'flex' }}>
            <View style={styles.card1}>
              <Image
                style={{ width: '70px', height: '70px', marginLeft: '50px' }}
                src="/translationAssets/packages/logo2.png"
              />
              <View style={{ ...styles.cardData }}>
                <Text style={styles.heading}>
                  {language === "English" ? `Professional` : `Professional`}

                  </Text>
                <Text style={styles.paragraph}>
                {language === "English" ? `Translations carried out by a native speaker expert. Review by one of our specialized Project Managers. Use of the Translation Memory upon request included in the price. High quality translations for standard documents.` :
                 `Traduzioni effettuate da un esperto madrelingua. Revisione da parte di un nostro Project Manager specializzato. Utilizzo della Translation Memory su richiesta inclusa nel prezzo. Traduzioni di ottima qualità per documenti standard.`}

                </Text>
              </View>
            </View>
          </View>
          <View style={{ width: '250px', margin: '10px', display: 'flex' }}>
            <View style={styles.card1}>
              <Image
                style={{ width: '70px', height: '70px', marginLeft: '50px' }}
                src="/translationAssets/packages/logo3.png"
              />
              <View style={{ ...styles.cardData }}>
                <Text style={styles.heading}>
                  {language === "English" ? `Premium` : `Premium`}

                  </Text>
                <Text style={{...styles.paragraph, fontSize:"7px"}}>
                {language === "English" ? `Translations carried out by an expert native translator. Quality control by a second native translator. Review by one of our specialized Project Managers. Translations of high-quality content intended for rapid publication.` : 
                `Traduzioni effettuate da un traduttore madrelingua esperto. Controllo qualità da parte di un secondo traduttore madrelingua. Revisione da parte un nostro Project Manager specializzato. Traduzioni di contenuti di alta qualità destinati alla pubblicazione in tempi rapidi.`}

                </Text>
              </View>
            </View>
          </View>
        </View>
        <View style={{...styles.footer, backgroundColor:"#2DA0F0"}}>
          <View style={{...styles.packagesFooterCards, display:"inline-flex", flexDirection:"row"}}>
          <View style={{...styles.packagesFooterCard1,  marginTop:"70px"}}>
                <Image style={{width:"20px", height:"20px",  marginTop:"20px", marginLeft:"80px"}} src={icons.translationService}/>               
                <Text style={{marginTop:"5px", fontSize:"9px", color:"white", marginLeft:"45px"}}>   
                {language === "English" ? `Machine Translation` : `Traduzione Automatica`}
                </Text>
                <Image style={{width:"20px", height:"20px",marginLeft:"80px",  marginTop:"20px"}} src={icons.translationService}/>               
                <Text style={{ marginTop:"5px", fontSize:"9px", color:"white", marginLeft:"45px"}}>   
                {language === "English" ? `Machine Translation` : `Traduzione Automatica`}
                </Text>
                <View style={{...styles.offer.div}}>
                  <Text style={{ fontSize:"12px", fontWeight:"bold", fontStyle:"normal", marginLeft:"50px"}}>
                  {language === "English" ? `Delivery -` : `Consegna -`}
                    </Text>
                  <Text style={{ marginTop:"5px",fontWeight:"bold", fontStyle:"normal", fontSize:"12px", marginLeft:"50px"}}>
                  €{netTotal}*
                    </Text>

                </View>
</View>
<View style={{...styles.packagesFooterCard1,  marginTop:"60px"}}>
                <Image style={{width:"20px", height:"20px",  marginTop:"20px", marginLeft:"140px"}} 
                src={icons.person}/>               
                <Text style={{marginTop:"5px", fontSize:"9px", color:"white", marginLeft:"126px"}}>   
                {language === "English" ? `Translator` : `Traduttore`}

                </Text>
                <Image style={{width:"20px", height:"20px",marginLeft:"140px",  marginTop:"20px"}} 
                src={icons.quality}/>               
                <Text style={{ marginTop:"5px", fontSize:"9px", color:"white", marginLeft:"120px"}}>   
                {language === "English" ? `Quality check` : `Controllo qualità`}
                </Text>
                <View style={{...styles.offer.div}}>
                  <Text style={{ fontSize:"12px", fontWeight:"bold", fontStyle:"normal", marginLeft:"100px"}}>
                  {language === "English" ? `Delivery -` : `Consegna -`}
                    </Text>
                  <Text style={{ marginTop:"5px",fontWeight:"bold", fontStyle:"normal", fontSize:"12px", marginLeft:"100px"}}>
                  €{(Number(netTotalOptions[0])).toFixed(2)}*
                  </Text>

                </View>
</View>
<View style={{...styles.packagesFooterCard1,  marginTop:"70px"}}>
                <Image style={{width:"20px", height:"20px",  marginTop:"20px", marginLeft:"145px"}}
                 src={icons.person}/>               
                <Text style={{marginTop:"5px", fontSize:"9px", color:"white", marginLeft:"130px"}}>   
                {language === "English" ? `Translator` : `Traduttore`}
                </Text>
                <Image style={{width:"20px", height:"20px",marginLeft:"145px",  marginTop:"20px"}} 
                src={icons.behavior}/>               
                <Text style={{ marginTop:"5px", fontSize:"9px", color:"white", marginLeft:"110px"}}>   
                {language === "English" ? `Specialized Reviewer` : `Revisore Specializzato`}
                </Text>
                <View style={{...styles.offer.div}}>
                  <Text style={{ fontSize:"12px", fontWeight:"bold", fontStyle:"normal", marginLeft:"105px"}}>
                  {language === "English" ? `Delivery -` : `Consegna -`}
                    </Text>
                  <Text style={{ marginTop:"5px",fontWeight:"bold", fontStyle:"normal", fontSize:"12px", marginLeft:"105px"}}>
                  €{Number(netTotalOptions[1]).toFixed(2)}*
                    </Text>

                </View>
</View>
          </View>
          <Image style={{width:"100%", marginTop:"-295px", zIndex:"-99"}} src="/translationAssets/packages/footer-cards.png"/>
        </View>
      </Page>
    );
}

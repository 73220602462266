import React from 'react';
import { Document } from '@react-pdf/renderer';

import Home from './pages/Home.js';
import AboutUs1 from './pages/AboutUs1.js';
import Introduction from './pages/Introduction.js';
import AboutUs2 from './pages/AboutUs2.js';
import Testimonials from '../../../translationClientOutput/PDFDoc/pages/Testimonials.js';
import ContactUs from './pages/ContactUs.js';
import OurClients from '../../../translationClientOutput/PDFDoc/pages/OurClients.js';
import TechPortal from './pages/TechPortal.js';
import TechnologyPlateform from './pages/TechnologyPlateform.js';
import Quality from '../../../translationClientOutput/PDFDoc/pages/Quality.js';
import Privacy from './pages/Privacy.js';
import Memory from './pages/Memory.js';
import Interpreting from './pages/Interpreting.js';
import Academy from './pages/Academy.js';
import SectorClients from './pages/SectorsClients.js';

const PDFDoc = ({ language = 'Italian', details = {} }) => {
  const {
    companyNameAndId,
    accountManager,
    bookedBy,
    brochureNumber:orderNumber,
    subject,
    industry,
    clients,
    companyLogo,

  } = details;
  return (
    <>
    {details &&  <Document>
        <Home
          language={language}
          data={{ companyNameAndId, bookedBy, accountManager, orderNumber, subject, companyLogo, clients, industry }}
        />
          <Introduction
            language={language}
            bookedBy={bookedBy}
            accountManager={accountManager}
            companyNameAndId={companyNameAndId}
            industry={industry}
            clients={clients}
          />
           <SectorClients language={language} clients={clients} industry={industry}/>
          <AboutUs1 language={language} />
          <AboutUs2 language={language} />
          <Interpreting language={language} />
          <Quality language={language} />
          <Memory language={language} />
          <Privacy language={language} />
          <Academy language={language} />
          <TechPortal language={language} />
          <TechnologyPlateform language={language} />
          <OurClients language={language} />
          <Testimonials language={language} />
      <ContactUs language={language} />

    </Document>
    
    }
    </>
  );
};

export default PDFDoc;

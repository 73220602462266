import { Grid } from '@mui/material';
import styles from '../styles/AboutUs1.module.css';
import { useSelector } from 'react-redux';



const AboutUs1 = () => {

  const { language} = useSelector(state => state.proposal)

  if (language === 'English') {
    return (
      <div className={styles.desktop7}>
        {/* <div className={styles.div}>03</div> */}
        <div className={styles.aboutUs}>ABOUT US</div>
        <div className={styles.text3543Parent}>
          <img alt="avatar" src="/assets/desktop6/group-1000001719.svg" />{' '}
        </div>
        <div className={styles.desktop7Item} />

        <div className={styles.weProvideTheContainer}>
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
            Are you ready to create prestigious and successful events? Welcome to <b>Congressi Internazionali</b>, the leading brand in the organization of events, congresses, fairs, corporate meetings, and conferences globally.
            </span>
          </p>
         
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
            What sets us apart is the ability to offer tailor-made solutions for our customers. We are passionate about knowledge, innovation, and collaboration, and this is why we are committed to providing cutting-edge solutions, quality customer service and availability throughout Italy and abroad. Whether you want to organize medium-sized or large-scale events, our mission is the <b>success of our customers</b>.
            </span>
          </p>
         
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
            The Congressi Internazionali team covers the entire Italian peninsula, with technicians on site in Rome - for coverage of all of Central Italy, the South and the Islands - and Milan - for coverage of Northern Italy, carrying out the mission of creating the <b>best multilingual events</b>. With a deep-rooted presence from north to south, we serve the needs of those seeking to create successful multilingual events.
            </span>
          </p>
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
            With our nationwide coverage, we pride ourselves on offering unprecedented access to world-class conventions, conferences and events. Our <b>presence across the country</b> reflects our commitment to promoting innovation and knowledge sharing in every corner of our beautiful country.
            </span>
          </p>
        </div>

        <Grid container sx={{ marginTop: '40px', bottom: '0%' }} spacing={3} className={styles.desktop7Frame}>
        <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/ChiaraMoschitta.webp" />
            <div className={styles.proyek}>Chiara Moschitta</div>
            <div className={styles.accountManager}>Account Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/GiuliaCaravasso.webp" />
            <div className={styles.proyek}>Giulia Caravasso</div>
            <div className={styles.accountManager}>Account Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/GiuseppeGagliano.webp" />
            <div className={styles.proyek}>Giuseppe Gagliano</div>
            <div className={styles.accountManager}>Account Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/MariaSegreto.webp" />
            <div className={styles.proyek}>Maria Segreto</div>
            <div className={styles.accountManager}>Client Success Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/MiriamFratacci.webp" />
            <div className={styles.proyek}>Miriam Fratacci</div>
            <div className={styles.accountManager}>Event Planner</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/GiuseppePinelli.webp" />
            <div className={styles.proyek}>Giuseppe Pinelli</div>
            <div className={styles.accountManager}>Accountant</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/JimPuccio.webp" />
            <div className={styles.proyek}>Jim Puccio</div>
            <div className={styles.accountManager}>Interpretation Project Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/VeronicaGaudesi.webp" />
            <div className={styles.proyek}>Veronica Gaudesi</div>
            <div className={styles.accountManager}>Interpretation Project Manager</div>
          </Grid> 
         
        </Grid>
      </div>
    );
  } else {
    return (
      <div className={styles.desktop7}>
        {/* <div className={styles.div}>03</div> */}
        <div className={styles.aboutUs}>CHI SIAMO</div>
        <div className={styles.text3543Parent}>
          <img alt="avatar" src="/assets/desktop6/group-1000001719.svg" />{' '}
        </div>
        <div className={styles.desktop7Item} />

        <div className={styles.weProvideTheContainer}>
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
              Siete pronti a realizzare eventi prestigiosi e di successo? Benvenuti in <b>Congressi Internazionali</b>,
              il brand leader nell’organizzazione di eventi, congressi, fiere, riunioni aziendali e convegni a livello
              globale.
            </span>
          </p>
         
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
              Ciò che ci contraddistingue è l’abilità di offrire soluzioni su misura per i nostri clienti. Siamo
              appassionati di conoscenza, innovazione e collaborazione, ed è per questo che ci impegniamo a fornire
              soluzioni all’avanguardia, un customer service di qualità e disponibilità in tutta Italia e all’estero.
              Che vogliate organizzare eventi di media o di grande portata, la nostra missione è il{' '}
              <b>successo dei nostri clienti.</b>
            </span>
          </p>
         
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
              Il team di Congressi Internazionali copre l'intera penisola italiana, con tecnici in loco su Roma – per la
              copertura di tutto il Centro Italia, del Sud e delle Isole – e Milano – per la copertura del Nord Italia,
              portando avanti la missione di creare i <b>migliori eventi multilingue</b>. Con una presenza radicata da
              nord a sud, serviamo le esigenze di coloro che cercano di creare eventi multilingue di successo. 
            </span>
          </p>
          <p className={styles.weProvideTheExpertiseAndC}>
            <span>
              Grazie alla nostra copertura nazionale, siamo orgogliosi di offrire un accesso senza precedenti a
              congressi, conferenze e eventi di prim'ordine. La nostra <b>presenza in tutto il territorio</b> è un riflesso del
              nostro impegno a promuovere l'innovazione e la condivisione delle conoscenze in ogni angolo del nostro
              splendido paese.
            </span>
          </p>
        </div>
        <Grid container sx={{ marginTop: '40px', bottom: '0%' }} spacing={3} className={styles.desktop7Frame}>
        <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/ChiaraMoschitta.webp" />
            <div className={styles.proyek}>Chiara Moschitta</div>
            <div className={styles.accountManager}>Account Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/GiuliaCaravasso.webp" />
            <div className={styles.proyek}>Giulia Caravasso</div>
            <div className={styles.accountManager}>Account Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/GiuseppeGagliano.webp" />
            <div className={styles.proyek}>Giuseppe Gagliano</div>
            <div className={styles.accountManager}>Account Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/MariaSegreto.webp" />
            <div className={styles.proyek}>Maria Segreto</div>
            <div className={styles.accountManager}>Client Success Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/MiriamFratacci.webp" />
            <div className={styles.proyek}>Miriam Fratacci</div>
            <div className={styles.accountManager}>Event Planner</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/GiuseppePinelli.webp" />
            <div className={styles.proyek}>Giuseppe Pinelli</div>
            <div className={styles.accountManager}>Accountant</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/JimPuccio.webp" />
            <div className={styles.proyek}>Jim Puccio</div>
            <div className={styles.accountManager}>Interpretation Project Manager</div>
          </Grid>
          <Grid sx={{ flexDirection: 'column', textAlign: 'center' }} item xs={6} md={3}>
            <img className={styles.frameChild} alt="avatar" src="/assets/desktop7/VeronicaGaudesi.webp" />
            <div className={styles.proyek}>Veronica Gaudesi</div>
            <div className={styles.accountManager}>Interpretation Project Manager</div>
          </Grid> 
         
        </Grid>
      </div>
    );
  }
};

export default AboutUs1;
